import React from "react";
import OperatorOrders from "../../../reusableComponents/operatorOrders/OperatorOrders";

//TODO - jak będzie gotowy tracker, odkomentować realTime - dane będą pobierane z Verizona
const columns = [
    {
        id: "details",
        label: "",
        query: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "taxiDriver",
        label: "Kierowca",
        sorted: false,
        order: null,
        query: true,
        minWidth: 180,
        maxWidth: 180,
    },
    {
        id: "time",
        label: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "number",
        label: "ID",
        query: true,
        sorted: false,
        maxWidth: 65,
    },
    {
        id: "orderNo",
        label: "Nr. zlecenia",
        query: true,
        sorted: false,
        maxWidth: 130,
    },
    {
        id: "parts",
        label: "Relacja",
        sorted: false,
        order: null,
        query: false,
        minWidth: 250,
    },
    {
        id: "plannedTime",
        label: "Godz. zapl.",
        sorted: false,
        query: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "realTime",
        label: "Godz. real.",
        sorted: false,
        query: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        sorted: false,
        query: true,
        order: null,
        minWidth: 180,
        maxWidth: 180,
    },
    {
        id: "worker",
        label: "Pracownik",
        sorted: false,
        order: null,
        query: true,
        minWidth: 180,
        maxWidth: 180,
    },
    {
        id: "notes",
        label: "Uwagi",
        query: true,
        sorted: false,
        minWidth: 160,
        maxWidth: 160,
    },
];

export default function ArchiveDashboard() {
    return <OperatorOrders entity="operator" status="archive" columns={columns}/>;
}
