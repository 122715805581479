import React, {Fragment} from "react";
import TrasyRoute from "./trasy/TrasyRoute";
import KierowcyRoute from "./kierowcy/KierowcyRoute";
import TransportRoute from "./transport/edytuj/EdytujRoute";
import TransportRouteNew from "./transport/edytuj/EdytujRouteNew";
import DriversMapRoute from "./driversMap/DriversMapRoute";
import DodajRoute from "../dyspozytor/transport/dodaj/DodajRoute";
import DodajRouteNew from "../dyspozytor/transport/dodaj/DodajRouteNew";
import PracownicyRoute from "../dyspozytor/pracownicy/PracownicyRoute";

import ActiveDashboard from "./trasy/activeDashboard/ActiveDashboard";
import ArchiveDashboard from "./trasy/archiveDashboard/ArchiveDashboard";
import AdresyRoute from "./adresy/AdresyRoute";

export const OperatorRoute = () => {
    return (
        <Fragment>
            <DodajRouteNew app="operator" window="trasy" tab="dodaj" entity="operator"/>
            <PracownicyRoute app="operator"/>
            <TrasyRoute/>
            <KierowcyRoute/>
            <DriversMapRoute/>
            <TransportRouteNew/>
            <AdresyRoute />
        </Fragment>
    );
};

export default OperatorRoute;
