import React from "react";
import styles from "./workerDetails.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import SmallMap from "../maps/smallMap/SmallMap";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },

    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "10vh",
        width: "60vw",
        maxHeight: "80vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
}));

export default function WorkerDetails(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        props.switchShowModal(false);
    };
    
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["workerDetails__description"]}>
                            <h2 className={styles["workerDetails__description--title"]}>
                                Szczegóły pracownika
                            </h2>
                            <span
                                className={styles["workerDetails__description--close"]}
                                onClick={handleClose}
                            >X</span>
                        </div>
                        <SimpleBar style={{maxHeight: "75vh"}} className={classes.simplebar}>
                            <div className={styles["workerDetails"]}>
                                <div className={styles["workerDetails__inputs"]}>
                                    <div className={styles["workerDetails__inputs--item"]}>
                                        <div className={styles["workerDetails__inputs--item--wrapper"]}>
                                            <p className={styles["workerDetails__inputs--item--id"]}>Id pracownika</p>
                                            <p className={styles["workerDetails__inputs--item--idInfo"]}>
                                                {props.workerId}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles["workerDetails__inputs--item"]}>
                                        <h3 className={styles["workerDetails__inputs--item--title"]}>
                                            Pracownik
                                        </h3>
                                        <div className={styles["workerDetails__inputs--item--person"]}>
                                            <p className={styles["workerDetails__inputs--item--person--info"]}>
                                                Imię
                                            </p>
                                            <p className={styles["workerDetails__inputs--item--person--data"]}>
                                                {props.workerFirstname}
                                            </p>

                                            <p className={styles["workerDetails__inputs--item--person--info"]}>
                                                Nazwisko
                                            </p>
                                            <p className={styles["workerDetails__inputs--item--person--data"]}>
                                                {props.workerSurname}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles["workerDetails__inputs--item"]}>
                                        <h3 className={styles["workerDetails__inputs--item--title"]}>
                                            Kontakt
                                        </h3>
                                        <div className={styles["workerDetails__inputs--item--contact"]}>

                                            <p className={styles["workerDetails__inputs--item--contact--info"]}>
                                                Telefon służbowy
                                            </p>
                                            <p className={styles["workerDetails__inputs--item--contact--data"]}>
                                                {props.workerWorkPhone}
                                            </p>

                                            <p className={styles["workerDetails__inputs--item--contact--info"]}>
                                                Telefon prywatny
                                            </p>
                                            <p className={styles["workerDetails__inputs--item--contact--data"]}>
                                                {props.workerPrivatePhone}
                                            </p>

                                            <p className={styles["workerDetails__inputs--item--contact--info"]}>
                                                E-mail
                                            </p>
                                            <p className={styles["workerDetails__inputs--item--contact--data"]}>
                                                {props.workerEmail}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles["workerDetails__inputs--item"]}>
                                        <h3 className={styles["workerDetails__inputs--item--title"]}>
                                            Adresy
                                        </h3>
                                        <div
                                            className={styles["workerDetails__inputs--item--address"]}>
                                            {props.workerAddresses ? (
                                                props.workerAddresses.map((address, index) => {
                                                    return (
                                                        <div key={index}
                                                             className={styles["workerDetails__inputs--item--address--single"]}>
                                                            <p className={styles["workerDetails__inputs--item--address--single--info"]}>
                                                                Ulica
                                                            </p>
                                                            <p className={styles["workerDetails__inputs--item--address--single--data"]}>
                                                                {address.street ? address.street : "-"}
                                                            </p>

                                                            <p className={styles["workerDetails__inputs--item--address--single--info"]}>
                                                                Numer budynku
                                                            </p>
                                                            <p className={styles["workerDetails__inputs--item--address--single--data"]}>
                                                                {address.no ? address.no : "-"}
                                                            </p>

                                                            <p className={styles["workerDetails__inputs--item--address--single--info"]}>
                                                                Numer mieszkania
                                                            </p>
                                                            <p className={styles["workerDetails__inputs--item--address--single--data"]}>
                                                                {address.flat ? address.flat : "-"}
                                                            </p>
                                                            <p className={styles["workerDetails__inputs--item--address--single--info"]}>
                                                                Miasto
                                                            </p>
                                                            <p className={styles["workerDetails__inputs--item--address--single--data"]}>
                                                                {`${address.zipCode} ${address.city}`}
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className={styles["workerDetails__noData"]}>Brak danych</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["workerDetails__map"]}>
                                    <SmallMap addresses={props.workerAddresses}/>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
