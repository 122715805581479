import React from "react";
import styles from "./formularz.module.scss";
import axios from "axios";
import "moment/locale/pl";
import _ from "lodash";
import {addHours, getUnixTime, fromUnixTime, add} from "date-fns";
import {styled} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import InfoDialog from "../alerts/infoDialog/InfoDialog";
import ActionBlocker from "../alerts/actionBlocker/ActionBlocker";
import update from "immutability-helper";
import FormAlert from "../alerts/FormAlert";
import FormLine from "./formLine/FormLine";
import FormSummary from "./formSummary/FormSummary";
import {produce} from 'immer';
import {NotificationContext} from "../../../context";
import HttpClient from "../../../HttpClient";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const ConfirmButton = styled(Button)({
    backgroundColor: "#1DBBED",
    marginRight: "10px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const SimpleScroll = styled(SimpleBar)({
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
        backgroundColor: "#b4b4b4",
    },
});

const AddOrderButton = styled(Button)({
    backgroundColor: "#b4b4b4",
    marginRight: "10px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#777777",
    },
});

const INITIAL = 'INITIAL';
const NEW = 'NEW';
const DELETED = 'DELETED';

export default class KilometrowkaPanel extends React.Component {
    source = axios.CancelToken.source();
    static contextType = NotificationContext;
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.entity === 'pracownik' && this.getCurrentDriver();
        this.props.entity === 'dyspozytor' && this.getDataDrivers();
        this.getDataStations();
        this.getDataKontrahents();
        }

    componentWillUnmount() {
        this.source.cancel();
    }

    renameKey = (o, newKey, oldKey) => {
        delete Object.assign(o, {[newKey]: o[oldKey] })[oldKey];
    }

    buildExistingWorker = (workers) => {

        const _workers = [];

        workers.map(worker => {
            //musialem zmienic strukture obiektu bo to co dostaje z aktywnych kursow jest inne niz to co dostaje przy normalnym dodawaniu zlecenia
            const userId = worker.userId;
            const driverId = worker.id;

            worker['id'] = userId;
            worker['driverId'] = driverId;

            this.renameKey(worker, 'surName', 'surname');
            this.renameKey(worker, 'name', 'firstname');

            const address = {
                city: worker.city,
                flat: worker.flat,
                id: worker.addressId,
                lat: worker.lat,
                lon: worker.lon,
                no: worker.no,
                street: worker.street,
                zipCode: worker.zipCode
            }

            const emptyWorker = {
                status: INITIAL,
                person: {
                    value: worker,
                    empty: null,
                },
                address: {
                    value: address,
                    empty: null,
                }
            }

            _workers.push(emptyWorker);
        })

        return _workers;
    }

    buildEmptyWorker = (status = INITIAL) => ({
        status,
        person: {
            value: null,
            empty: null,
        },
        address: {
            value: null,
            empty: null,
        },
    });

    buildEmptyKontrahent = () => ({
        value: null,
        empty: null,
    });

    buildEmptyOrdersGroup = (
        isProposition = false,
        isReturn = false,
        isPropositionFromDestination = false
    ) => ({
        orders: [
            this.props.type === 'edit' ? this.buildExistingOrder(this.props.location.state.order) :
                this.buildEmptyOrder(
                    isProposition,
                    isReturn,
                    isPropositionFromDestination
                ),
        ],
    });

    buildExistingOrder = (order) => {
        return {
            isProposition: false,
            isReturn: false,
            isPropositionFromDestination: false,
            worker: this.buildExistingWorker(order.worker),
            driver: null,
            isWorkAddress: {
                value: order.isWorkAddress
            },
            destination: {
                value: order.direction ? order.to : order.from,
                empty: null,
            },
            direction: {
                value: order.direction,
            }, // dom -> praca
            timeWork: {
                value: order.isDestinationTime ? fromUnixTime(order.destinationTime) : null,
                empty: null,
                enabled: true,
            },
            timeHome: {
                value: order.isDestinationTime ? null : fromUnixTime(order.destinationTime),
                empty: null,
                enabled: true,
            },
            approximateTime: {
                value: null
            },
            trainId: {
                empty: null,
                value: order.trainId || ''
            },
            trainDestination: {
                value: null,
                empty: null,
                shouldBeEmpty: false,
            },
            note: {
                value: order.note || '',
            },
            orderNo: {
                value: order.orderNo || '',
            },
            wasEdited: false,
        }
    }

    buildEmptyOrder = (
        isProposition = false,
        isReturn = false,
        isPropositionFromDestination = false
    ) => ({
        isProposition: isProposition,
        isReturn: isReturn,
        isPropositionFromDestination: isPropositionFromDestination,
        worker: [this.buildEmptyWorker()],
        kontrahent: this.buildEmptyKontrahent(),
        driver: null,
        isWorkAddress: {
            value: false
        },
        destination: {
            value: null,
            empty: null,
        },
        direction: {
            value: true,
        }, // dom -> praca
        timeWork: {
            value: new Date(),
            empty: null,
            enabled: true,
        },
        timeHome: {
            value: null,
            empty: null,
            enabled: true,
        },
        approximateTime: {
            value: null
        },
        trainId: {
            empty: null,
            value: "",
        },
        trainDestination: {
            value: null,
            empty: null,
            shouldBeEmpty: false,
        },
        note: {
            value: "",
        },
        orderNo: {
            value: "",
            empty: null
        },
        mapKm: {
            value: ''
        },
        manualKm: {
            value: "",
            empty: null
        },
        cardNo: {
            value: '',
            empty: null
        },
        steps: {
            value: []
        },
        wasEdited: false,
    });

    async getDataKontrahents() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/dyspozytura/kontrahents', {cancelToken: this.source.token}, true);
            const {data} = response;
            this.setState({kontrahents: data});
        } catch (e) {}
    }

    async getCurrentDriver() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/pracownik-current', {cancelToken: this.source.token}, true);
            const {data} = response.data
            const drivers = [];
            drivers.push({...data.user, driverId: data.id})
            this.setState({drivers}, () => {
                this.state.ordersGroup.map((orderGroup, groupIndex) => {
                    orderGroup.orders.map((order, orderIndex) => {
                        let w = update(order.worker, {
                            [0]: {
                                person: {
                                    value: {$set: this.state.drivers[0]},
                                },
                            },
                        });
                        w = update(w, {
                            [0]: {
                                address: {
                                    value: {$set: this.state.drivers[0]?.adres[0] || null},
                                },
                            },
                        });
                        this.setOrderData(
                            groupIndex,
                            orderIndex,
                            "worker",
                            w,
                        );
                    });
                })
            });
        } catch (e) {}
    }

    async getDataDrivers() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/pracownik/list', {cancelToken: this.source.token}, true);
            const drivers = [];
            response.data.map((driver) =>
                drivers.push({...driver.user, driverId: driver.id})
            );
            // const label = {
            //   name: "Dodaj",
            //   surName: "pracownika",
            //   type: "add",
            // };
            // drivers.unshift(label);
            this.setState({drivers});
        } catch (e) {}
    }

    async getDataStations() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/addresses', {cancelToken: this.source.token}, true);
            this.setState({stations: response.data.data});
        } catch (e) {}
    }

    constructor(props) {
        super(props);
        this.state = {
            ordersGroup: [this.buildEmptyOrdersGroup(false)],
            showOrderSummary: false,
            sending: false,
            error: "",
            done: false,
            submitted: false,
            orderData: [],
            // alerty front
            showAlert: false,
            errorReturnOrder: false,
            errorAddAddress: false,
            errorTooManyWorkers: false,
            errorProposedOrder: false,
            errorWorker: false,
            showInfoDialog: false,
            showActionBlocker: false,
            stations: [],
            drivers: [],
            kontrahents: [],
            blockedDrivers: [],
        };

    }

    alertReset = (value) => {
        this.setState(() => {
            return {
                errorWorker: value,
                errorProposedOrder: value,
                showInfoDialog: value,
                errorTooManyWorkers: value,
                showActionBlocker: value,
                errorReturnOrder: value,
            };
        });
    };

    removeOrderGroup = (groupIndex, orderIndex) => {
        const ordersGroup = produce(this.state.ordersGroup, draftState => {
            if(orderIndex === 0) {
                draftState.splice(groupIndex, 1);
            } else {
                draftState[groupIndex].orders.splice(orderIndex, 1);
            }
        });
        this.setState({ordersGroup});
    };

    acceptOrderGroup = () => {
        const newOrdersGroup = this.buildEmptyOrdersGroup();
        if(this.props.entity === 'pracownik') {
            newOrdersGroup.orders[0].worker[0].person.value = this.state.drivers[0] || null;
            newOrdersGroup.orders[0].worker[0].address.value = this.state.drivers[0]?.adres[0] || null;
        }
        this.setState({
            ordersGroup: [...this.state.ordersGroup, newOrdersGroup],
            submitted: false,
        });
    };

    confirmOrder = (groupIndex, orderIndex) => {
        const ordersGroup = produce(this.state.ordersGroup, draftState => {
            draftState[groupIndex].orders[orderIndex].isProposition = false;
        });
        this.setState({ordersGroup});
    };

    addReturnOrder = (groupIndex) => {
        const orderGroup = this.state.ordersGroup[groupIndex];

        const order = _.cloneDeep(orderGroup.orders[orderGroup.orders.length - 1]);
        const timeKey = order.timeHome.value ? "timeHome" : "timeWork";
        const timeValue = order[timeKey].value;
        const destinationValue = order.trainDestination.value ? order.trainDestination.value : order.destination.value;

        order.worker.forEach((worker) => {
            worker.address.value = worker.person.value?.adres[0] || null;
        })

        order.isProposition = false;
        order.isReturn = true;
        order.destination.value = destinationValue;
        order.trainDestination.value = null;
        order.isWorkAddress.value = false;
        order.direction.value = false;
        order.timeHome.value = null;
        order.timeHome.enabled = !order.isWorkAddress;
        order.timeWork.value = addHours(timeValue, 12);
        order.orderNo.value = "";
        order.note.value = "";

        const ordersGroup = produce(this.state.ordersGroup, draftState => {
            draftState[groupIndex].orders.push(order);
        });
        this.setState({ordersGroup});
    };

    editExistingOrder = (field, order, value) => {
        let isProposition = false;
        if (field === "worker") {
            order.worker = _.cloneDeep(value);
        } else if (field === 'isWorkAddress') {
            const enabled = !!(value || order.worker.length === 1)
            order.timeHome.value = null;
            order.timeHome.enabled = enabled;
            order.isWorkAddress.value = value;
        } else if (field === "timeHome" && (order.worker.length < 2 || order.isWorkAddress.value)) {
            order.timeHome.value = value;
            order.timeHome.enabled = true;
            order.timeWork.value = null;
        } else if (field === "timeWork") {
            if (!order.isWorkAddress.value && order.worker.length > 1) {
                order.timeHome.value = null;
                order.timeHome.enabled = false;
                order.timeWork.value = value;
            } else if (order.isWorkAddress.value || order.worker.length === 1) {
                order.timeHome.value = null;
                order.timeHome.enabled = true;
                order.timeWork.value = value;
            }
        } else if (field === 'mapKm') {
            order.mapKm.value = value;
            order.manualKm.value = value;
            if(order.isProposition) {
                isProposition = true;
            }
        } else {
            order[field].value = value;
        }
        order.isProposition = isProposition;
        return order;
    };

    addKontrahent = (value, groupIndex, orderIndex) => {
        this.setState({kontrahents: [...this.state.kontrahents, value]}, () => {
            this.setOrderData(groupIndex, orderIndex, 'kontrahent', value, this.handleValidate);
        });
    }

    editProposedOrder = (orderIndex, field, order, mainOrder, value) => {
        if (orderIndex === 0) {
            if (order.isProposition) {
                if (field === "worker") {
                    order.worker =  _.cloneDeep(value);
                    if(mainOrder.isWorkAddress.value) {
                        order.worker.forEach((worker) => {
                            worker.address.value = worker.person.value?.adres[0] || null;
                        })
                    }
                } else if (field === "destination") {
                    order.destination.value = mainOrder && mainOrder.trainDestination.value ? mainOrder.trainDestination.value : value
                } else if (field === "timeWork") {
                    if (mainOrder && mainOrder.worker.length > 1) {
                        order.timeHome.value = null;
                        order.timeHome.enabled = false;
                        order.timeWork.value = addHours(value, 12);
                    } else if (mainOrder && mainOrder.worker.length === 1) {
                        order.timeHome.value = null;
                        order.timeHome.enabled = true;
                        order.timeWork.value = addHours(value, 12)
                    }
                } else if (field !== 'orderNo') {
                    order[field].value = value;
                } else if (field === 'approximateTime') {
                    order.approximateTime.value = value;
                }
            }
        }
        return order;
    };

    editOrders = (mappedIndex, orderIndex, order, ordersArray, field, value) => {
        if (mappedIndex === orderIndex) {
            ordersArray.push(this.editExistingOrder(field, order, value));
        } else if (order.isProposition) {
            ordersArray.push(
                this.editProposedOrder(orderIndex, field, order, ordersArray[0], value)
            );
        } else {
            ordersArray.push(order);
        }
    };

    addWorkAddress = (value, field, groupIndex, orderIndex) => {
        this.setState({stations: [...this.state.stations, value]}, () => {
            const order = this.state.ordersGroup[groupIndex].orders[orderIndex];
            if(order.isWorkAddress.value && field !== 'destination') {
                const nextWorker = produce(order.worker, draftState => {
                    draftState.forEach(worker => {
                        worker.address.value = value;
                    }) ;
                });
                this.setOrderData(groupIndex, orderIndex, 'worker', nextWorker);
            }
            else {
                this.setOrderData(groupIndex, orderIndex, 'destination', value);
            }
        });
    };

    addWorkerAddress = (value, field = null, groupIndex, orderIndex, workerIndex) => {
        const driverIndex = this.state.drivers.findIndex(driver => driver.driverId === value.driverId);
        const ordersGroup = produce(this.state.ordersGroup, draftState => {
            draftState.forEach(orderGroup => {
                orderGroup.orders.forEach(order => {
                    order.worker.forEach(worker => {
                        if(worker.person.value?.driverId === value.driverId) {
                            worker.person.value.adres.push(value);
                        }
                    });
                });
            });
            draftState[groupIndex].orders[orderIndex].worker[workerIndex].address.value = value;
        });

        const drivers = produce(this.state.drivers, draftState => {
            draftState[driverIndex].adres.push(value);
        });

        this.setState({ordersGroup, drivers},() => {
            let worker = this.state.ordersGroup[groupIndex].orders[orderIndex].worker;
            const _worker = produce(worker, draftState => {
                draftState[workerIndex].address.value = value;
            });
            this.setOrderData(groupIndex, orderIndex, 'worker', _worker);
        });

    };

    addNewWorker = (value) => {
        const driver = {...value.user, driverId: value.id};
        this.setState({drivers: [...this.state.drivers, driver]})
    };

    setOrderData = (groupIndex, orderIndex, field, value, cb) => {
        const ordersGroup = produce(this.state.ordersGroup, draftState => {
            const orderGroup = draftState[groupIndex];
            const orders = [];

            orderGroup.orders.forEach((mappedOrder, mappedIndex) =>
                this.editOrders(
                    mappedIndex,
                    orderIndex,
                    mappedOrder,
                    orders,
                    field,
                    value
                )
            );

            orderGroup.orders = orders;

        });

        this.setState({ordersGroup}, () => {
            if (cb) {
                cb();
            }
        })
    };

    handleValidate = () => {
        let emptyInputs = false;
        if (this.state.submitted) {
            const ordersGroup = produce(this.state.ordersGroup, draftState => {
                draftState.forEach(orderGroup => {
                    orderGroup.orders.forEach(item => {
                        if (!item.isProposition) {
                            if (item.kontrahent.value === null) {
                                item.kontrahent.empty = true;
                                emptyInputs = true;
                            } else {
                                item.kontrahent.empty = false;
                            }

                            if (item.cardNo.value.trim() === "") {
                                item.cardNo.empty = true;
                                emptyInputs = true;
                            } else {
                                item.cardNo.empty = false;
                            }

                            if (item.manualKm.value === "") {
                                item.manualKm.empty = true;
                                emptyInputs = true;
                            } else {
                                item.manualKm.empty = false;
                            }

                            item.worker.map((worker) => {
                                if (worker.person.value === null) {
                                    worker.person.empty = true;
                                    emptyInputs = true;
                                } else {
                                    worker.person.empty = false;
                                }
                                if (worker.address.value === null) {
                                    worker.address.empty = true;
                                    emptyInputs = true;
                                } else {
                                    worker.address.empty = false;
                                }
                            });
                            if (item.destination.value === null) {
                                item.destination.empty = true;
                                emptyInputs = true;
                            } else {
                                item.destination.empty = false;
                            }
                        }
                    });
                });
            });
            this.setState({ordersGroup});
        } else {
            emptyInputs = true;
        }
        if (emptyInputs) return false;
        emptyInputs = false;
        return true;
    };

    setStateOrderData = () => {
        //funkcja zbierająca wszystkie inputy i przerabiająca je na dane dla podsumowania i API
        var orderData = [];
        this.state.ordersGroup.map((orderGroup) => {
            orderGroup.orders.map((order) => {
                if (!order.isProposition) {
                    let workerTable = [];
                    let fullWorkerTable = [];
                    order.worker.map((item) => {
                        let fullSingleRecord = {
                            person: item.person.value,
                            address: item.address.value,
                        };

                        fullWorkerTable.push(fullSingleRecord);
                        let singleRecord = [
                            item.person.value.driverId,
                            item.address.value.id,
                            order.isWorkAddress.value,
                        ];
                        workerTable.push(singleRecord);
                    });

                    let time = order.timeHome.value
                        ? order.timeHome.value
                        : order.timeWork.value;

                    let isDestination = !!order.timeWork.value;

                    if (!order.direction.value) {
                        isDestination = !isDestination;
                    }

                    const hours = Math.floor(order.approximateTime.value / 3600);
                    const minutes = Math.floor((order.approximateTime.value - (hours * 3600)) / 60);
                    const durationObj = {
                        hours,
                        minutes
                    };
                    const approximateTime = add(time, durationObj);

                    orderData.push({
                        fullWorkerAddressData: fullWorkerTable,
                        part: workerTable,
                        worker: order.worker[0].person.value.driverId,
                        pickup: order.direction.value ? order.worker[0].address.value : order.destination.value,
                        direction: order.direction.value,
                        destination: order.direction.value ? order.destination.value : order.worker[0].address.value,
                        time,
                        approximateTime,
                        isDestination: isDestination,
                        note: order.note.value,
                        kontrahent: order.kontrahent.value,
                        cardNo: order.cardNo.value,
                        manualKm: order.manualKm.value,
                        mapKm: order.mapKm.value,
                        orderNo: order.orderNo.value,
                        isWorkAddress: order.isWorkAddress.value
                    });
                }
            });
        });
        this.setState(() => {
            return {
                orderData: orderData,
            };
        });
    };

    sendData = async () => {
        // Jeśli już się wysyła to nic nie rób.
        if (this.state.sending) return false;
        // A jak nie to zablokuj taką możliwość i wykonaj
        this.setState({sending: true});
        const postData = {
            data: this.state.orderData.map((order) => {
                return {
                    arrivalTime: getUnixTime(order.approximateTime),
                    worker: order.worker,
                    pickup: order.pickup?.id,
                    direction: order.direction,
                    kontrahent: order.kontrahent?.id,
                    note: order.note,
                    destination: order.destination?.id,
                    orderNo: order.orderNo ? order.orderNo : null,
                    time: order.time ? getUnixTime(order.time) : null,
                    cardNo: order.cardNo,
                    manualKm: order.manualKm,
                    mapKm: order.mapKm,
                    isWorkAddress: order.isWorkAddress       
                };
            }),
        };

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.post('api/kilometrowka', {...postData}, {cancelToken: this.source.token}, true);
            this.setState({sending: false});
            if (response.data.status) {
                this.setState({done: true});
                if(this.props.entity === 'dyspozytor') {
                    this.props.history.push(
                        "/app/dyspozytor/kilometrowki/zestawienia"
                    );
                } else if(this.props.entity === 'pracownik') {
                    this.props.history.push(
                        "/app/pracownik/kilometrowki/zestawienia"
                    );
                }
            } else {
                this.setState({error: response.data.error});
            }
        } catch (e) {}
    };

    switchShowInfoDialog = () => {
        let currentTime = getUnixTime(new Date());
        this.state.ordersGroup.map((orderGroup) => {
            orderGroup.orders.map((order) => {
                if (!order.isProposition) {
                    let time = order.timeHome
                        ? order.timeHome.value
                        : order.timeWork.value;
                    let orderTime = getUnixTime(time);
                    let result = orderTime - currentTime;
                    if (result < 7200) {
                        this.setState(() => {
                            return {
                                showInfoDialog: true,
                            };
                        });
                        return false;
                    }
                }
            });
        });
        if (!this.state.showInfoDialog) this.switchShowOrderSummary();
    };

    switchShowOrderSummary = () => {
        this.setState(() => {
            return {
                showOrderSummary: !this.state.showOrderSummary,
                showInfoDialog: false,
                showActionBlocker: false,
            };
        });
    };

    addNew = () => {
        this.setState({
            orders: [this.buildEmptyOrder(true)],
            inputs: [this.buildEmptyInput()],
            showModal: false,
            sending: false,
            error: false,
            done: false,
        });
    };

    checkDriversId = (e) => {
        if (e) if (e.preventDefault) e.preventDefault();
        this.setState({submitted: true}, () => {
            const isFormValid = this.handleValidate();
            if (isFormValid) {
                this.setStateOrderData();
                this.switchShowInfoDialog();
            }
        });
    };

    render() {
        return (
            <SimpleScroll style={{maxHeight: "85vh"}}>
                <div className={styles["formularz"]}>
                    {this.state.ordersGroup.map((group, groupIndex, arr) =>
                        group.orders.map((data, index, ordersArr) => {
                            return (
                                <FormLine
                                    key={index}
                                    index={index}
                                    groupIndex={groupIndex}
                                    data={data}
                                    type={this.props.type}
                                    entity={this.props.entity}
                                    groups={arr}
                                    orders={ordersArr}
                                    kontrahents={this.state.kontrahents}
                                    handleValidate={this.handleValidate}
                                    drivers={this.state.drivers}
                                    stations={this.state.stations}
                                    setOrderData={this.setOrderData}
                                    addWorkAddress={this.addWorkAddress}
                                    addWorkerAddress={this.addWorkerAddress}
                                    removeOrderGroup={this.removeOrderGroup}
                                    confirmOrder={this.confirmOrder}
                                    addKontrahent={this.addKontrahent}
                                    addProposedOrderLine={this.addProposedOrderLine}
                                    addNewWorker={this.addNewWorker}
                                    openDriverModal={this.openDriverModal}
                                    closeDriverModal={this.closeDriverModal}
                                    acceptOrderGroup={this.acceptOrderGroup}
                                    addReturnOrder={this.addReturnOrder}
                                    lastLine={this.state.ordersGroup.length - 1 === groupIndex}
                                    setWorkerData={this.setWorkerData}
                                    errorWorker={this.state.errorWorker}
                                    handleShowInfoDialog={this.handleShowInfoDialog}
                                    orderGroupLength={this.state.ordersGroup.length}
                                />
                            );
                        })
                    )}
                    <div className={styles["formularz__buttonWrapper"]}>
                        <ConfirmButton
                            variant="contained"
                            type="submit"
                            onClick={this.checkDriversId}
                        >
                            Przejdź do potwierdzenia
                        </ConfirmButton>
                        <AddOrderButton
                            variant="contained"
                            onClick={() => {
                                this.acceptOrderGroup();
                            }}
                        >
                            Dodaj nową kilometrówkę
                        </AddOrderButton>

                    </div>

                    {this.state.showInfoDialog ? (
                        <InfoDialog
                            alertReset={this.alertReset}
                            confirmAction={this.switchShowOrderSummary}
                            message="Krótki czas na realizację zlecenia! Sprawdź, czy data jest poprawna."
                        />
                    ) : null}
                    {this.state.errorReturnOrder && (
                        <FormAlert
                            showAlert={true}
                            severity="error"
                            message={"Możesz dodać tylko jeden kurs powrotny"}
                        />
                    )}
                    {this.state.errorTooManyWorkers && (
                        <FormAlert
                            showAlert={true}
                            severity="error"
                            message={"Możesz dodać maksymalnie czterech pracowników"}
                        />
                    )}
                    {this.state.errorProposedOrder && (
                        <FormAlert
                            showAlert={true}
                            severity="error"
                            message={"Możesz dodać tylko jedno odwiezienie poprzedniej zmiany"}
                        />
                    )}
                    {this.state.showOrderSummary ? (
                        <FormSummary
                            switchShowOrderSummary={this.switchShowOrderSummary}
                            orderData={this.state.orderData}
                            sending={this.state.sending}
                            error={this.state.error}
                            done={this.state.done}
                            sendData={this.sendData}
                        />
                    ) : null}
                    {this.state.showActionBlocker ? (
                        <ActionBlocker
                            alertReset={this.alertReset}
                            message="Ten kierowca ma już zaplanowaną trasę"
                            blockedDrivers={this.state.blockedDrivers}
                        />
                    ) : null}
                </div>
            </SimpleScroll>
        );
    }
}
