import React from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import settlementsPanelStyles from "./../settlementsPanelStyles";
import settlementsPanelSettlementHelper from "../common/settlementsPanelSettlement.helper";
import { format } from "date-fns";
import { pl } from "date-fns/locale";

const SettlementsPanelSum = (props) => {
    const styles = settlementsPanelStyles();

    return (
        <>
            <Box className={styles.backgroundDark}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <colgroup>
                            <col width="60px" />
                            <col width="60px" />
                            <col width="120px" />
                            <col width="120px" />
                            <col width="350px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                            <col width="60px" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.tableHeadCell}>Data</TableCell>
                                <TableCell className={styles.tableHeadCell}>ID zlec.</TableCell>
                                <TableCell className={styles.tableHeadCell}>Kontrahent</TableCell>
                                <TableCell className={styles.tableHeadCell}>Pracownik</TableCell>
                                <TableCell className={styles.tableHeadCell}>Trasa</TableCell>
                                <TableCell className={styles.tableHeadCell}>Model</TableCell>
                                <TableCell className={styles.tableHeadCell}>Stawka</TableCell>
                                <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                <TableCell className={styles.tableHeadCell}>Rabat</TableCell>
                                <TableCell className={styles.tableHeadCell}>Po rabacie</TableCell>
                                <TableCell className={styles.tableHeadCell}>Koszt KM</TableCell>
                                <TableCell className={styles.tableHeadCell}>Postój</TableCell>
                                <TableCell className={styles.tableHeadCell}>Koszt post.</TableCell>
                                <TableCell className={styles.tableHeadCell}>Autostr.</TableCell>
                                <TableCell className={styles.tableHeadCell}>Łącznie</TableCell>
                                <TableCell className={styles.tableHeadCell}>Oszcz.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow></TableRow>
                            {props.settlementCandidates.map((settlementCandidate, idx) => {
                                const overallSettlementCandidate = props.areConnectedOrdersJoin
                                    ? props.settlementCandidates[0]
                                    : settlementCandidate;

                                const totalDistance = overallSettlementCandidate.clientData.routesSettlements.reduce(
                                    (prev, current) => prev + current.distance,
                                    0
                                );
                                const totalHighwayCost = overallSettlementCandidate.clientData.highwayCost;

                                const newDiscount = overallSettlementCandidate.clientData.discountPercent
                                    ? overallSettlementCandidate.clientData.rate *
                                      (1 - overallSettlementCandidate.clientData.discountPercent / 100)
                                    : overallSettlementCandidate.clientData.rate;

                                const rateAfterDiscount = Math.round((newDiscount + Number.EPSILON) * 100) / 100;
                                const totalStopDuration =
                                    overallSettlementCandidate.clientData.routesSettlements.reduce(
                                        (prev, current) => prev + current.stopDuration,
                                        0
                                    );

                                const totalStopCost =
                                    Math.ceil(totalStopDuration / 60) * overallSettlementCandidate.clientData.stopCost;

                                const totalDistanceCostBeforeDiscount =
                                    overallSettlementCandidate.clientData.rate * totalDistance;
                                const totalDistanceCostAfterDiscount = rateAfterDiscount * totalDistance;

                                const totalCost = totalDistanceCostAfterDiscount + totalHighwayCost + totalStopCost;

                                const saving = totalDistanceCostBeforeDiscount - totalDistanceCostAfterDiscount;

                                const routeString = settlementsPanelSettlementHelper.getRouteNameString(
                                    overallSettlementCandidate.clientData.routesSettlements
                                );

                                const shouldBeZero = idx > 0 && props.isJoinedOrders;

                                return (
                                    <TableRow>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {format(settlementCandidate.date, "d.LL.yyyy", {
                                                    locale: pl,
                                                })}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {settlementCandidate.orderNumber}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {settlementCandidate.clientData.companyName}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                <div>
                                                    {settlementCandidate.workers.map((worker) => {
                                                        return <div>{`${worker.firstName} ${worker.lastName}`}</div>;
                                                    })}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>{routeString}</div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {overallSettlementCandidate.clientData.billingModel?.name ?? "-----"}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero
                                                    ? "-"
                                                    : `${overallSettlementCandidate.clientData.rate} zł`}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "-" : `${totalDistance} km`}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero
                                                    ? "-"
                                                    : `${overallSettlementCandidate.clientData.discountPercent} %`}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "-" : `${rateAfterDiscount.toFixed(2)} zł`}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "0.00" : totalDistanceCostAfterDiscount.toFixed(2)} zł
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "-" : `${totalStopDuration} min`}
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "0.00" : totalStopCost.toFixed(2)} zł
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "0.00" : totalHighwayCost.toFixed(2)} zł
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "0.00" : totalCost.toFixed(2)} zł
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {shouldBeZero ? "0.00" : saving.toFixed(2)} zł
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {props.isDispatcher && (
                <Box style={{ marginTop: 20 }} className={styles.backgroundDark}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="60px" />
                                <col width="60px" />
                                <col width="120px" />
                                <col width="120px" />
                                <col width="350px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                                <col width="60px" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>Data</TableCell>
                                    <TableCell className={styles.tableHeadCell}>ID zlec.</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Kierowca</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Pracownik</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Trasa</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Model</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Stawka</TableCell>
                                    <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Koszt KM</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Postój</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Koszt post.</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Autostr.</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Łącznie</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow></TableRow>
                                {props.settlementCandidates.map((settlementCandidate, idx) => {
                                    const billingGroupName = settlementCandidate.driverData.driver ? settlementCandidate.driverData.driver.billingGroup?.name ?? settlementCandidate.driverData.driver.billingGroup : settlementCandidate.driverData.billingGroup
                                    const displayRouteData = ((billingGroupName === 'CS' || billingGroupName === 'K27' || !billingGroupName) && !props.isContractorOperator) ||
                                    (billingGroupName !== 'CS' && billingGroupName !== 'K27' && props.isContractorOperator)
                                    const totalDistance = settlementCandidate.driverData.routesSettlements.reduce(
                                        (prev, current) => prev + current.distance,
                                        0
                                    );

                                    const totalHighwayCost = Number.isNaN(settlementCandidate.driverData.highwayCost)
                                        ? 0
                                        : settlementCandidate.driverData.highwayCost;

                                    const totalStopDuration = settlementCandidate.driverData.routesSettlements.reduce(
                                        (prev, current) => prev + current.stopDuration,
                                        0
                                    );

                                    const totalStopCost = settlementCandidate.driverData
                                        ? settlementsPanelSettlementHelper.checkIsNan(
                                              Math.ceil(totalStopDuration / 60) *
                                                  settlementCandidate.driverData.stopCost
                                          )
                                        : 0;

                                    const totalDistanceCost = settlementsPanelSettlementHelper.checkIsNan(
                                        settlementCandidate.driverData.rate * totalDistance
                                    );

                                    const totalCost = settlementsPanelSettlementHelper.checkIsNan(
                                        totalDistanceCost + totalHighwayCost + totalStopCost
                                    );

                                    const routeString = settlementsPanelSettlementHelper.getRouteNameString(
                                        settlementCandidate.driverData.routesSettlements
                                    );

                                    const shouldBeZero = idx > 0 && props.isJoinedOrders;

                                    if (!displayRouteData)
                                        return null;
                                    
                                    return (
                                        <TableRow>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {format(settlementCandidate.date, "d.LL.yyyy", {
                                                        locale: pl,
                                                    })}
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {settlementCandidate.orderNumber}
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {settlementCandidate.driverData.driver
                                                        ? `${settlementCandidate.driverData.driver.firstName} ${settlementCandidate.driverData.driver.lastName}`
                                                        : "-----"}
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <div>
                                                        {settlementCandidate.workers.map((worker) => {
                                                            return (
                                                                <div>{`${worker.firstName} ${worker.lastName}`}</div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>{routeString}</div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {settlementCandidate.driverData.billingModel?.name ?? "-----"}
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "0.00" : settlementCandidate.driverData.rate ?? 0}{" "}
                                                    zł
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "-" : `${totalDistance} km`}
                                                </div>
                                            </TableCell>

                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "0.00" : totalDistanceCost.toFixed(2)} zł
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "-" : `${totalStopDuration} min`}
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "0.00" : totalStopCost.toFixed(2)} zł
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "0.00" : totalHighwayCost.toFixed(2)} zł
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    {shouldBeZero ? "0.00" : totalCost.toFixed(2)} zł
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};

export default SettlementsPanelSum;
