import React from "react";
import AppRoute from "../../../../AppRoute";
import KilometrowkiZestawienie from "../../../reusableComponents/kilometrowkiZestawienie/KilometrowkiZestawienie";

export default function ZestawieniaRoute() {

    const columns = [
        {
            id: "status",
            label: "",
            query: false,
        },
        {
            id: "direction",
            label: "Kierunek",
            sorted: false,
            query: false,
            order: null,
            minWidth: 120,
        },
        {
            id: "time",
            label: "Data",
            sorted: true,
            order: "ASC",
            query: true,
            minWidth: 160,
        },
        {
            id: "worker",
            label: "Pracownik",
            sorted: false,
            order: null,
            query: true,
            minWidth: 200,
        },
        {
            id: "pickup",
            label: "Adres początkowy",
            sorted: false,
            order: null,
            query: true,
            minWidth: 200,
        },
        {
            id: "destination",
            label: "Adres docelowy",
            sorted: false,
            order: null,
            query: true,
            minWidth: 200,
        },
        {
            id: "manualKm",
            label: "KM",
            query: true,
            sorted: false,
            maxWidth: 100,
        },
        {
            id: "kontrahent",
            label: "Kontrahent",
            query: true,
            sorted: false,
            minWidth: 100,
        },
        {
            id: "cardNo",
            label: "Nr karty",
            query: true,
            sorted: false,
            maxWidth: 140
        },
        {
            id: "details",
            label: "",
            query: false,
        },
    ];

    const Kilometrowki = () => <KilometrowkiZestawienie columns={columns} entity="pracownik"/>

    return (
        <AppRoute
            app="pracownik"
            window="kilometrowki"
            tab="zestawienia"
            component={Kilometrowki}
        />
    );
};

