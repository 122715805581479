import {
  createMuiTheme,
  IconButton, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Input from "@material-ui/core/Input";
import { makeStyles, styled } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  endOfDay,
  endOfMonth,
  fromUnixTime,
  getDate,
  getDaysInMonth,
  getMonth,
  getUnixTime,
  getYear,
  isDate, isSameDay,
  startOfDay,
  startOfMonth,
} from "date-fns";
import * as qs from "qs";
import React, { Fragment, useEffect, useState, useContext } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import { MimicContext } from "../../../context";
import { convertNumberToArray } from "../../../helper/convertNumberToArray";
import HttpClient from "../../../HttpClient";
import DateTabs from "../../reusableComponents/orders/Tabs";
import ProgressLine from "../../reusableComponents/progressLine/ProgressLine";
import styles from "./styles.module.scss";
import { DownloadPdfButton } from "./DownloadPdfButton";
import {downloadFormatData} from "./downloadFormatData";
import { GeneratePdf } from "./GeneratePdf";
import InfoDialog from "../../reusableComponents/alerts/infoDialog/InfoDialog";
import {downloadCsv} from "./downloadCsv";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
});

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: "#313131",
  },
  tooltip: {
    backgroundColor: "#313131",
    color: "white",
    fontSize: "13px",
    letterSpacing: "1px",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles({
  simplebar: {
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
      backgroundColor: "#b4b4b4",
    },
  },

  pagination: {
    width: "fit-content",
    "& .MuiTablePagination-toolbar": {
      padding: "0 10px 5px 10px !important",
    },
  },
  root: {
    color: "#47484B",
    fontFamily: "'Open Sans', sans-serif",
    border: "none !important",
    "& .MuiTable-root": {
      width: "auto",
    },
    "& .MuiTableCell-head": {
      padding: "2px 15px 0 0",
      fontSize: "12px",
      fontFamily: "'Open Sans', sans-serif",
    },
    "& .MuiTableCell-body": {
      padding: "2px 15px 2px 0",
      fontSize: "12px",
      fontFamily: "'Open Sans', sans-serif",
    },
    "& .MuiTabs-fixed": {
      overflowX: "auto !important",
    },
  },
  icon: {
    color: "white",
  },
  pending: {
    color: "#1DBBED",
  },
  accepted: {
    color: "#66D01D",
  },
  deleted: {
    color: "#bbb",
  },
  rejected: {
    color: "red",
  },
  tableContainer: {
    height: "550px",
  },
  tableHead: {
    backgroundColor: "rgba(243, 243, 243, 0.7)",
    boxShadow: "none",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
      color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1DBBED",
      },
      "&:hover fieldset": {
        borderColor: "#1DBBED",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1DBBED",
      },
    },
  },
  tableSortLabel: {
    display: "flex",
    alignItems: "center",
  },
  headCell: {
    width: 180,
    padding: 0,
    margin: 0,
    "& $headCellArrow": {
      opacity: "50%",
      transition: "0.2s",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  headCellArrow: {
    opacity: "0%",
    transition: "0.2s",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  checkAllButton: {
    background: "#1DBBED",
    margin: "0 15px",
    cursor: "pointer",
    border: "none",
    fontSize: "16px",
    padding: "8px 14px",
    color: "#fff",
  },
  tableCell: {
    paddingBottom: "0",
    paddingTop: "0",
  },
  disabledCol: {
    color: "#777 !important",
    pointerEvents: 'none',
    "& div": {
      color: "#777 !important",

      "& p": {
        color: "#777 !important",
      },
    },
  },
  iconButton: {
    backgroundColor: "#1DBBED",
    margin: "2px",
    "&:hover": {
      backgroundColor: "#00A0E3",
    },
  },

  confirmButton: {
    backgroundColor: "#66D01D",
    margin: "3px",
    "&:hover": {
      backgroundColor: "#5AB71B",
    },
  },
  generateButton: {
    background: "#1DBBED",
    marginLeft: "auto",
    cursor: "pointer",
    border: "none",
    fontSize: "16px",
    padding: "8px 14px",
    color: "#fff",
    '&:disabled': {
      background: "#999",
      cursor: "default"
    }
  },
  downloadCheckboxContainer: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    border: "2px solid #1DBBED",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.2s ease",
  },
  downloadCheckboxContainerActive: {
    background: "#1DBBED",
  },
  downloadCheckboxIcon: {
    color: "#fff",
    width: "75%",
    height: "75%",
  },
  downloadCheckbox: {
    display: "none",
  },
  downloadButton: {
    background: "#1DBBED",
    marginLeft: "1rem",
    cursor: "pointer",
    border: "none",
    fontSize: "16px",
    padding: "8px 14px",
    color: "#fff",
    "&:disabled": {
      background: "#999",
      cursor: "default",
    },
  },
  deleteButton: {
    border: "none",
    outline: "none",
    background: "transparent",
    cursor: "pointer",
  },
  disabledRow: {
    filter: "grayScale(100%)",
    background: "#eee",
    "&:hover": {
      background: "#eee !important",
    },
  },
});

const SearchDatePicker = styled(DatePicker)({
  width: 160,
  fontFamily: "'Open Sans', sans-serif",
});

const months = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień",
];

const generateStartEndDate = (newDate) => ({
  start: getUnixTime(startOfMonth(newDate)),
  end: getUnixTime(endOfMonth(newDate)),
});

export const GeneratedTable = (props) => {
  const classes = useStyles();
  const now = new Date();
  const url = "api/delegations";
  const [downloadType, setDownloadType] = useState('pdf');
  const [page, setPage] = useState(0);
  const [columns, setColumns] = useState(props.columns);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDetails, setShowDetails] = useState(false);
  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const [index, setIndex] = useState(null);
  const [apiIds, setApiIds] = useState([]);
  const [invokeClearColumn, setClearColumn] = useState(false);
  const [searchValues, setSearchValues] = useState([]);
  const [id, setId] = useState(null);
  const [date, setDate] = useState(generateStartEndDate(now));
  const [api, setApi] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [count, setCount] = useState(0);
  const [sendRequest, setSendRequest] = useState(false);
  const [showInfoDialogCancel, setShowInfoDialogCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedIds, setCheckedIds] = useState([]);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [downloadedTempState, setDownloadedTempState] = useState([]);

  //DateTabs
  const [startDate, setStartDate] = useState(fromUnixTime(date.start));
  const [endDate, setEndDate] = useState(fromUnixTime(date.end));

  const handleDateChange = (type, value) => {
    const rangeDate = {...date};
    if(type === 'start') {
      value = startOfDay(value);
      setStartDate(value);
    }
    if(type === 'end') {
      value = endOfDay(value);
      setEndDate(value);
    }
    rangeDate[type] = getUnixTime(value);
    setDate(rangeDate);
  }

  const [year, setYear] = useState(getYear(now));
  const [month, setMonth] = useState(getMonth(now));
  const [day, setDay] = useState(null);
  const [daysInMonth, setDaysInMonth] = useState(
    convertNumberToArray(getDaysInMonth(new Date(year, month)))
  );

  const extraDownloadHandler = (id) => {
    setDownloadedTempState(prev => [...prev, id]);
  }

  const { roles, addFlash } = useContext(MimicContext);

  const history = useHistory();

  const hasSaveAccess = roles.includes("ROLE_DELEGACJE_ZAPIS");


  const handleOnEnterPress = (e) => {
    if(e.keyCode === 109 || e.keyCode === 189) {
      e.preventDefault();
      return;
    }
     if (e.key === "Enter") {
      getData();
       setCheckedIds([]);
       setAllSelected(false);
    }
  };
  const handleSetStartEndDate = () => {
    let rangeDate = {...date};
    if (day) {
      const thisDay = new Date(Date.UTC(year, month, day));
      rangeDate = {
        start: getUnixTime(startOfDay(thisDay)),
        end: getUnixTime(endOfDay(thisDay)),
      };
    } else {
      const thisMonth = new Date(Date.UTC(year, month));
      rangeDate = {
        start: getUnixTime(startOfMonth(thisMonth)),
        end: getUnixTime(endOfMonth(thisMonth)),
      };
    }
    setDate(rangeDate);
  };

  const handleDaysInMonthChange = (newYear, newMonth) => {
    setDaysInMonth(
      convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth)))
    );
  };

  const handleSwitchInfoDialog = (value) => {
    setShowInfoDialogCancel(value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    handleDateChange();
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    handleDateChange();
  };

  const handleCheckAll = () => {
    setAllSelected((prev) => !prev);
  };

  const handleDayChange = (event, newValue) => {
    if (newValue === day) {
      setDay(null);
    } else {
      setDay(newValue);
    }
    handleDateChange();
  };

  const handleDatePickerDateChange = (newDate) => {
    const day = getDate(newDate);
    const month = getMonth(newDate);
    const year = getYear(newDate);
    setDay(day);
    setMonth(month);
    setYear(year);
    handleDateChange();
  };

  const changeDate = (id, newDate) => {
    handleSearch(id, newDate);
    // uzywam tej funkcji tylko po to zeby wywolac getDate w useEffect
    setClearColumn(!invokeClearColumn);
  };

  const handleAddManuallyRedirect = () => {
    history.push("/app/administracja/dokumenty/dodaj-delegacje");
  };

  const getDatePickerValue = () => {
    return !isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day)) ? null : new Date(year, month, day);
  }

  const source = axios.CancelToken.source();

  const getParams = (s) => {
    if (s.value !== null) {
      if (isDate(s.value)) {
        s.value = getUnixTime(s.value);
      }
      return s.id + "=" + s.value + "&";
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const _searchParams = searchValues.map((a) => ({ ...a }));
    const getParam = _searchParams.map(getParams).join("");
    const sortField = columns.filter((item) => item.sorted === true);


    const httpRequest = HttpClient.createRequest();
    try {
      const response = await httpRequest.get(
        `${url}?${getParam}orderBy=${sortField[0]?.id ?? ""}&order=${
          sortField[0]?.order ?? ""
        }&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`,
        {
          cancelToken: source.token,
          params: {
            date,
            onlyAccepted: true,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        },
        true
      );
      setApi(response.data.data.delegations);
      setCount(response.data.data.count);
      setApiIds(response.data.data.ids);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const httpRequest = HttpClient.createRequest();
    try {
      const response = await httpRequest.delete(
        `/api/delegations/remove/${id}`,
        {},
        true
      );
      setApi((prevState) => prevState.map((item) => { 
        if(id !== item.id) return item;
        return {...item, deletedAt: 1} }));
    } catch {}
  };

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevState) => {
      const newState = !prevState.includes(id)
          ? [...prevState, id]
          : prevState.filter((idFromState) => idFromState !== id);
      return newState;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();

    return () => {
      source.cancel();
    };
  }, [invokeClearColumn, date, rowsPerPage, page, columns]);

  useEffect(() => {
    handleDaysInMonthChange(year, month);
    handleSetStartEndDate();
  }, [year, month, day]);

  useEffect(() => {
    setPage(0);
    setStartDate(fromUnixTime(date.start));
    setEndDate(fromUnixTime(date.end));
  }, [date]);

  useEffect(() => {
    setCheckedIds(apiIds.filter(apiId => checkedIds.includes(apiId)))
  }, [apiIds]);

  useEffect(() => {
    if (checkedIds.length >= 0 && checkedIds.length < apiIds.length) {
      setCheckedIds(apiIds);
    } else {
      setCheckedIds([]);
    }
  }, [allSelected]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const handleSearch = (field, query) => {
    setPage(0);
    const filterObject = searchValues.filter((s) => s.id !== field);
    setSearchValues([...filterObject, { id: field, value: query }]);
  };

  const handleClearColumn = (field) => {
    handleSearch(field, null);
    setClearColumn(!invokeClearColumn);
  };

  const clearDate = () => {
    setDay(null);
  };

  function createData(
    status,
    type,
    nr,
    worker,
    startDate,
    endDate,
    createdAt,
    downloadDate,
    totalCost,
    totalKm,
    download,
    del,
    isDisabled
  ) {
    return {
      status,
      type,
      nr,
      worker,
      startDate,
      endDate,
      createdAt,
      downloadDate,
      totalCost,
      totalKm,
      download,
      del,
      isDisabled,
    };
  }

  const switchShowModal = (value) => {
    setShowDetails(value);
    setIsMoreDetails(value);
  };


  const ShowArrow = ({ sorted, order, classes }) => {
    if (sorted) {
      if (order === "ASC") {
        return (
          <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />
        );
      } else if (order === "DESC") {
        return (
          <ArrowDownwardIcon
            fontSize="small"
            className={classes.headCellArrow}
          />
        );
      }
    } else {
      return (
        <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />
      );
    }
  };

  const createSortHandler = (_column) => {
    const mappedColumns = columns.map((column) => {
      column = {...column};
      if (column.id === _column.id) {
        if (_column.order === "ASC") {
          column.order = "DESC";
        } else {
          column.sorted = true;
          column.order = "ASC";
        }
      } else {
        column.sorted = false;
        column.order = null;
      }
      return column;
    });
    setColumns(mappedColumns);
    setPage(0);
  };

  const handleDownloadAll = async () => {
    addFlash('Trwa pobieranie delegacji', {
      variant: 'success',
      preventDuplicate: true,
      key: 'downloadDelegation'
    })
    setIsDownloadingAll(true);
    const downloadedData = await downloadFormatData(downloadType, checkedIds);
    if(downloadType === 'pdf') {
      for (const data of downloadedData) {
        let success = false;
        let tries = 1;
        let maxTries = 1000;
        while (!success && tries < maxTries) {
          try {
            const blob = await pdf(<GeneratePdf pdfData={data} />).toBlob();
            saveAs(blob, `${data.worker} ${data.delegationNumber}`);
            success = true;
          } catch (error) {
            tries += 1;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      }
    } else if(downloadType === 'csv') {
      downloadCsv(downloadedData, startDate, endDate);
    }
    setDownloadedTempState((prev) => [...prev, ...checkedIds])
    setIsDownloadingAll(false);
  };

  const rows = api
    ? api.map((data, index) => {
        const startDate = fromUnixTime(data.startDate);
        const endDate = fromUnixTime(data.endDate);
        const createdAt = fromUnixTime(data.createdAt);
        const downloadDate = data.downloadedAt
          ? fromUnixTime(data.downloadedAt)
          : null;
        const isDeleted = !!data.deletedAt;

        return createData(
          <FiberManualRecordIcon
            className={`${(!!downloadDate || downloadedTempState.includes(data.id)) ? classes.accepted : classes.pending}`}
          />,
          data.isManual ? 'Ręczna' : 'Auto',
          data.delegationNumber,
          data.worker ? (
            <div className={styles["zestawienie__table--item"]}>
              {data.worker}
            </div>
          ) : (
            "-"
          ),
          data.startDate ? (
            <div className={classes.time}>
              <p
                className={styles["zestawienie__table--item"]}
              >{`${startDate.getDate()}.${
                months[startDate.getMonth()]
              } ${startDate.getFullYear()}`}</p>
            </div>
          ) : (
            "-"
          ),
          data.endDate ? (
            <div className={classes.time}>
              <p
                className={styles["zestawienie__table--item"]}
              >{`${endDate.getDate()}.${
                months[endDate.getMonth()]
              } ${endDate.getFullYear()}`}</p>
            </div>
          ) : (
            "-"
          ),
          data.createdAt ? (
            <div className={classes.time}>
              <p
                className={styles["zestawienie__table--item"]}
              >{`${createdAt.getDate()}.${
                months[createdAt.getMonth()]
              } ${createdAt.getFullYear()}`}</p>
            </div>
          ) : (
            "-"
          ),
          downloadDate ? (
            <div className={classes.time}>
              <p
                className={styles["zestawienie__table--item"]}
              >{`${downloadDate.getDate()}.${
                months[downloadDate.getMonth()]
              } ${downloadDate.getFullYear()}`}</p>
            </div>
          ) : (
            "-"
          ),
          data.totalCost ? data.totalCost.toFixed(2) + " zł" : "-",
            data.totalKm ? data.totalKm : null,
          hasSaveAccess && !isDeleted ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <label
                  style={{marginRight: 5}}
                  htmlFor={data.id}
                  className={`${classes.downloadCheckboxContainer} ${
                      checkedIds.includes(data.id)
                          ? classes.downloadCheckboxContainerActive
                          : ""
                  }`}
              >
                {checkedIds.includes(data.id) && (
                    <CheckIcon className={classes.downloadCheckboxIcon} />
                )}
                <input
                    id={data.id}
                    name={data.id}
                    type="checkbox"
                    className={classes.downloadCheckbox}
                    checked={checkedIds.includes(data.id)}
                    onChange={() => {
                      handleCheckboxChange(data.id);
                    }}
                ></input>
              </label>
              <BootstrapTooltip title="Pobierz">
                <DownloadPdfButton
                    delegationId={data.id}
                    type={downloadType}
                    startDate={startDate}
                    endDate={endDate}
                    wasDownloaded={!!downloadDate || downloadedTempState.includes(data.id)}
                    isDownloadingAll={isDownloadingAll}
                    extraDownloadHandler={extraDownloadHandler}
                />
              </BootstrapTooltip>
            </div>
          ) : null,
          hasSaveAccess ? (
            <BootstrapTooltip style={{display: "block", width: "100%", height: "100%"}} title="Usuń">
            <button
              className={classes.deleteButton}
              onClick={() => {
                setId(data.id)
                handleSwitchInfoDialog(true)
              }}
              >
              <DeleteIcon color="error" />
            </button>
              </BootstrapTooltip>
          ) : null,
          isDeleted
        );
      })
    : null

  return (
    <div className={styles["zestawienie"]}>
      <Paper className={classes.root}>
        <DateTabs
            handleDayChange={handleDayChange}
            handleMonthChange={handleMonthChange}
            handleYearChange={handleYearChange}
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            day={day}
            month={month}
            year={year}
            daysInMonth={daysInMonth}
        />
        <div className={styles["zestawienie__description"]}>
          <div className={styles["zestawienie__description--box"]}>
            <h1 className={styles["zestawienie__description--title"]}>
              Zestawienie delegacji
            </h1>

            <div className={styles["zestawienie__description--legend"]}>
              <div
                  className={styles["zestawienie__description--legend--legendItem"]}
              >
                <FiberManualRecordIcon className={classes.pending} />
                <p
                    className={
                      styles[
                          "zestawienie__description--legend--legendItem--singleLegend"
                          ]
                    }
                >
                  Niepobrane
                </p>
              </div>
              <div
                  className={styles["zestawienie__description--legend--legendItem"]}
              >
                <FiberManualRecordIcon className={classes.accepted} />
                <p
                    className={
                      styles[
                          "zestawienie__description--legend--legendItem--singleLegend"
                          ]
                    }
                >
                  Pobrane
                </p>
              </div>
              <div
                  className={styles["zestawienie__description--legend--legendItem"]}
              >
                <FiberManualRecordIcon className={classes.deleted} />
                <p
                    className={
                      styles[
                          "zestawienie__description--legend--legendItem--singleLegend"
                          ]
                    }
                >
                  Usunięte
                </p>
              </div>
            </div>
          </div>
          {hasSaveAccess && (
              <div className={classes.buttons}>
                <button
                    onClick={handleAddManuallyRedirect}
                    className={classes.generateButton}
                >
                  Dodaj delegację ręcznie
                </button>
                <button onClick={handleCheckAll} className={classes.checkAllButton}>
                  Zaznacz wszystkie
                </button>
                <button
                    onClick={handleDownloadAll}
                    className={classes.generateButton}
                    disabled={checkedIds.length === 0}
                >
                  Pobierz
                </button>
              </div>
          )}
        </div>
        {isLoading ? (
          <ProgressLine />
        ) : (
          <TableContainer>
            <SimpleBar
              style={{ minHeight: "53vh", maxHeight: "53vh" }}
              className={classes.simplebar}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {columns.map((column, index) => (
                      <Fragment key={column.id}>
                        <TableCell
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {column.query ? (
                            <TableSortLabel
                              className={classes.tableSortLabel}
                              hideSortIcon={true}
                            >
                              {column.type === "time" ? (
                                <ThemeProvider theme={defaultMaterialTheme}>
                                  <Input
                                    className={classes.headCell}
                                    inputComponent={SearchDatePicker}
                                    inputProps={{
                                      style: { height: "unset" },
                                      onChange: (newValue) =>
                                        changeDate(column.id, newValue),
                                      value:
                                        searchValues.find(
                                          (v) => v.id === column.id
                                        )?.value ?? null,
                                      format: "d MMMM",
                                      disableToolbar:
                                        props.status === "archive",
                                      InputProps: {
                                        disableUnderline: true,
                                        placeholder: column.label,
                                      },
                                    }}
                                    endAdornment={
                                      <>
                                        <Tooltip title="Wyczyść">
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleClearColumn(column.id)
                                            }
                                            aria-label="visible columns"
                                          >
                                            <ClearIcon
                                              className={classes.headCellArrow}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Sortuj">
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              createSortHandler(
                                                column,
                                                index,
                                                rows
                                              )
                                            }
                                            aria-label="visible columns"
                                          >
                                            <ShowArrow
                                              sorted={column.sorted}
                                              order={column.order}
                                              classes={classes}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    }
                                  />
                                </ThemeProvider>
                              ) : (
                                <Input
                                  variant="outlined"
                                  type={column.type === 'number' ? 'number' : 'text'}
                                  className={classes.headCell}
                                  defaultValue={
                                    searchValues.find((v) => v.id === column.id)
                                      ?.value
                                  }
                                  onChange={(e) =>
                                    handleSearch(column.id, e.target.value)
                                  }
                                  onKeyDown={handleOnEnterPress}
                                  placeholder={column.label}
                                  endAdornment={
                                    <>
                                      <Tooltip title="Wyczyść">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleClearColumn(column.id)
                                          }
                                          aria-label="visible columns"
                                        >
                                          <ClearIcon
                                            className={classes.headCellArrow}
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      {column.sorted !== null && (
                                        <Tooltip title="Sortuj">
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              createSortHandler(
                                                column,
                                                index,
                                                rows
                                              )
                                            }
                                            aria-label="visible columns"
                                          >
                                            <ShowArrow
                                              sorted={column.sorted}
                                              order={column.order}
                                              classes={classes}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </TableSortLabel>
                          ) : (
                              column.id === 'download' && hasSaveAccess ? (
                                  <Select
                                      value={downloadType}
                                      onChange={(event) => setDownloadType(event.target.value)}
                                  >
                                    <MenuItem value="pdf">PDF</MenuItem>
                                    <MenuItem value="csv">CSV</MenuItem>
                                    {/*<MenuItem value="bankCsv">CSV do banku</MenuItem>*/}
                                  </Select>
                              ) : (
                                  column.label
                              )

                          )}
                        </TableCell>
                      </Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                {api && api.length > 0 ? (
                  <TableBody>
                    {rows &&
                      rows.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            key={row.id}
                            className={`${
                              row.isDisabled ? classes.disabledRow : ""
                            }`}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={`${classes.tableCell} ${
                                    row.isDisabled ? classes.disabledCol : ""
                                  }`}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <p className={styles["zestawienie__noData"]}>Brak danych</p>
                )}
              </Table>
            </SimpleBar>
            <TablePagination
              className={classes.pagination}
              labelRowsPerPage={"Liczba wierszy"}
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Paper>
      {showInfoDialogCancel ? (
          <InfoDialog
              alertReset={handleSwitchInfoDialog}
              confirmAction={() => handleDelete(id)}
              message={api.find(item => item.id === id)?.isManual ? "Czy na pewno chcesz usunąć tę delegację trwale?" : "Czy na pewno chcesz usunąć tę delegację?"}
          />
      ) : null}
      {/* {showDetails ? (
        <Confirmation
          details={api[index]}
          index={index}
          switchShowModal={switchShowModal}
          handleSendRequest={handleSendRequest}
          isMoreDetails={isMoreDetails}
          entity={props.entity}
          disallowEdit
        />
      ) : null} */}
    </div>
  );
};
