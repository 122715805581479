import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button, createMuiTheme, TextField, MenuItem } from "@material-ui/core";
import styles from "./contractorCardForm.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider } from "@material-ui/styles";
import { DateTimePicker } from "@material-ui/pickers";
import lightBlue from "@material-ui/core/colors/lightBlue";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import HttpClient from "../../../../HttpClient";
import { fromUnixTime } from "date-fns";

import Switch from "@material-ui/core/Switch";
import { LeakRemoveTwoTone } from "@material-ui/icons";
import { MimicContext } from "../../../../context";
import { phoneRegExp } from "../../../../helper";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.grey[500],
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[500]}`,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        borderColor: "#5AB71B",
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "#5AB71B",
    },
    checked: {},
}))(Switch);

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    dateInput: {
        width: "200px",
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#66D01D",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
}));

const schema = Yup.object().shape({
    //lewa strona
    id: Yup.string(),
    name: Yup.string().trim().required("Uzupełnij to pole").default(""),
    address: Yup.string().trim().nullable().required("Uzupełnij to pole").default(""),
    no: Yup.string().nullable().trim().required("Uzupełnij to pole").default(""),
    flat: Yup.string().trim().default(""),
    city: Yup.string().nullable().trim().required("Uzupełnij to pole").default(""),
    postCode: Yup.string()
        .matches(/^\d{2}-\d{3}$/, "Kod pocztowy musi być w formacie XX-XXX")
        .nullable()
        .trim()
        .required("Uzupełnij to pole")
        .default(""),
    nip: Yup.string()
        .matches(/^\d+$/, "Numer NIP musi się skladać tylko z cyfr")
        .nullable()
        .trim()
        .min(10, "Numer NIP musi się składać z 10 cyfr")
        .max(10, "Numer NIP musi się składać z 10 cyfr")
        .required("Uzupełnij to pole")
        .default(""),
    regon: Yup.string()
        .matches(/^\d+$/, "Numer REGON musi się składać tylko z cyfr")
        .nullable()
        .trim()
        .min(9, "Numer REGON musi się składać z 9 cyfr")
        .max(9, "Numer REGON musi się składać z 9 cyfr")
        .required("Uzupełnij to pole")
        .default(""),
    contactPerson: Yup.string()
        .nullable()
        .trim()
        .matches(/^\D+$/, "Pole nie przyjmuje cyfr")
        .required("Uzupełnij to pole")
        .default(""),
    contactPersonPhoneNumber: Yup.string()
        .matches(phoneRegExp, "Wpisz prawidłowy numer telefonu")
        .nullable()
        .trim()
        .required("Uzupełnij to pole")
        .default(""),
    dispatch: Yup.string().nullable().trim().required("Uzupełnij to pole").default(""),
    dispatchPhoneNumber: Yup.string()
        .matches(phoneRegExp, "Wpisz prawidłowy numer telefonu")
        .nullable()
        .trim()
        .required("Uzupełnij to pole")
        .default(""),
    startTime: Yup.date().nullable().required("Uzupełnij to pole").default(new Date()),
    endTime: Yup.date()
        .nullable()
        .required("Uzupełnij to pole")
        .default(new Date())
        .when("startTime", (st, schema) => {
            return Yup.date().min(st, "Data końca umowy musi być większa niż data jej początku");
        }),
    //prawa strona
    rate: Yup.number().required("Uzupełnij to pole"),
    discount: Yup.boolean().nullable().required("Uzupełnij to pole").default(true),
    discountLimit: Yup.number().required("Uzupełnij to pole"),
    stop: Yup.number().required("Uzupełnij to pole"),
    countedFrom: Yup.number().required("Uzupełnij to pole"),
    isHighwayPay: Yup.boolean().nullable().required("Uzupełnij to pole").default(true),
    highwayWithWorker: Yup.boolean().nullable().default(true),
    highwayWithoutWorker: Yup.boolean().nullable().default(true),
    billingModel: Yup.string().required(`Uzupełnij to pole`).default(""),
    penalty: Yup.number().required("Uzupełnij to pole"),
    others: Yup.number().required("Uzupełnij to pole"),
    notes: Yup.string().nullable(),
});

export default function ContractorCardForm(props) {
    const classes = useStyles();
    const [ignoreForm, setIgnoreForm] = useState(true);
    const [initialValues, setInitialValues] = useState(schema.cast());
    const [reload, setReload] = useState(false);
    const { roles } = useContext(MimicContext);
    const billingModels = useMemo(() => {
        return ["SABS", "ABA", "INNY"];
    }, []);

    const handleIgnoreForm = () => {};

    const handleNumberOnChange = (field, e, setFieldValue) => {
        let { value } = e.target;
        const regExp = /^\d{0,10}(\.\d{0,2})?$/;
        if (regExp.test(value) || value === "") setFieldValue(field, value.replace(",", "."));
    };

    const getData = async () => {
        const httpRequest = HttpClient.createRequest();
        const url = props.contractorId ? `api/card/${props.contractorId}` : "api/card";
        const { data } = await httpRequest.get(url, {}, true);
        const { contract } = data;

        const nextInitialValues = {
            id: data.id,
            name: data.name || "",
            address: contract.address?.street || "",
            no: contract.address?.no || "",
            flat: contract.address?.flat || "",
            city: contract.address?.city || "",
            postCode: contract.address?.zipCode || "",
            nip: contract.NIP || "",
            regon: contract.REGON || "",
            contactPerson: contract.contactPerson || "",
            contactPersonPhoneNumber: contract.contactPersonPhoneNumber || "",
            dispatch: contract.dispatches ? contract.dispatches[0]?.dispatch : "",
            dispatchPhoneNumber: contract.dispatches ? contract.dispatches[0]?.dispatchPhoneNumber : "",
            startTime: contract.startTime ? fromUnixTime(contract.startTime) : new Date(),
            endTime: contract.endTime ? fromUnixTime(contract.endTime) : new Date(),
            rate: contract.normalRate || 0,
            discount: contract.isDiscount === null ? true : !contract.isDiscount,
            discountLimit: contract.maxDiscount || 0,
            stop: contract.waitingRate || 0,
            countedFrom: contract.countedFrom || 0,
            isHighwayPay: contract.isHighwayPay === null ? true : !contract.isHighwayPay,
            highwayWithWorker: contract.highwayWithWorker === null ? false : !contract.highwayWithWorker,
            highwayWithoutWorker: contract.highwayWithoutWorker === null ? false : !contract.highwayWithoutWorker,
            billingModel: contract.billingModel ?? "",
            penalty: contract.contractualPenalties || 0,
            others: contract.other || 0,
            notes: contract.notes || "",
        };

        setInitialValues(nextInitialValues);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSwitchChange = (field, e, setFieldValue) => {
        setFieldValue(field, e.target.checked);
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={props.handleSendData}
            render={({ values, errors, touched, setFieldValue, setFieldError }) => (
                <Form className={styles["contractorCardForm"]}>
                    <div className={styles["contractorCardForm__form"]}>
                        <h1 className={styles["contractorCardForm__form--title"]}>Dane adresowe</h1>
                        <div className={styles["contractorCardForm__form--inputs"]}>
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="name"
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                label={"Nazwa firmy*"}
                                helperText={!!errors.name && touched.name ? errors.name : ""}
                                error={!!errors.name && touched.name}
                                variant="standard"
                                style={{ width: 535 }}
                                as={TextField}
                                margin="none"
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="address"
                                label={"Ulica*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.address && touched.address ? errors.address : ""}
                                error={!!errors.address && touched.address}
                                variant="standard"
                                style={{ width: 250 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="no"
                                label={"Nr. domu*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.no && touched.no ? errors.no : ""}
                                error={!!errors.no && touched.no}
                                variant="standard"
                                style={{ width: 110 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="flat"
                                label={"Nr. mieszkania"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.flat && touched.flat ? errors.flat : ""}
                                error={!!errors.flat && touched.flat}
                                variant="standard"
                                style={{ width: 110 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="city"
                                label={"Miasto*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.city && touched.city ? errors.city : ""}
                                error={!!errors.city && touched.city}
                                variant="standard"
                                style={{ width: 330 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="postCode"
                                label={"Kod pocztowy*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.postCode && touched.postCode ? errors.postCode : ""}
                                error={!!errors.postCode && touched.postCode}
                                variant="standard"
                                style={{ width: 170 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="nip"
                                label={"NIP*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.nip && touched.nip ? errors.nip : ""}
                                error={!!errors.nip && touched.nip}
                                variant="standard"
                                style={{ width: 250 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="regon"
                                label={"REGON*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.regon && touched.regon ? errors.regon : ""}
                                error={!!errors.regon && touched.regon}
                                variant="standard"
                                style={{ width: 250 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="contactPerson"
                                label={"Osoba kontaktowa*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.contactPerson && touched.contactPerson ? errors.contactPerson : ""}
                                error={!!errors.contactPerson && touched.contactPerson}
                                variant="standard"
                                style={{ width: 330 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="contactPersonPhoneNumber"
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                label={"Telefon*"}
                                helperText={
                                    !!errors.contactPersonPhoneNumber && touched.contactPersonPhoneNumber
                                        ? errors.contactPersonPhoneNumber
                                        : ""
                                }
                                error={!!errors.contactPersonPhoneNumber && touched.contactPersonPhoneNumber}
                                variant="standard"
                                style={{ width: 170 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="dispatch"
                                label={"Dyspozytura*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={!!errors.dispatch && touched.dispatch ? errors.dispatch : ""}
                                error={!!errors.dispatch && touched.dispatch}
                                variant="standard"
                                style={{ width: 330 }}
                                margin="none"
                                as={TextField}
                            />
                            <Field
                                onKeyUp={handleIgnoreForm}
                                className={classes.root}
                                name="dispatchPhoneNumber"
                                label={"Telefon*"}
                                disabled={!roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")}
                                helperText={
                                    !!errors.dispatchPhoneNumber && touched.dispatchPhoneNumber
                                        ? errors.dispatchPhoneNumber
                                        : ""
                                }
                                error={!!errors.dispatchPhoneNumber && touched.dispatchPhoneNumber}
                                variant="standard"
                                style={{ width: 170 }}
                                margin="none"
                                as={TextField}
                            />
                            <p className={styles["contractorCardForm__description"]}>Czas trwania umowy</p>
                            <div className={styles["contractorCardForm__dates"]}>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <div className={styles["contractorCardForm__dates--single"]}>
                                        <p className={styles["contractorCardForm__dates--single--description"]}>Od:</p>
                                        <DateTimePicker
                                            name="startTime"
                                            selected={values.startTime}
                                            disabled={
                                                !roles.includes("ROLE_OPERATOR") &&
                                                !roles.includes("ROLE_SUPER_OPERATOR")
                                            }
                                            value={values.startTime}
                                            onChange={(date) => {
                                                setFieldValue("startTime", date);
                                            }}
                                            autoOk={false}
                                            format={"d.LLLL yyyy, HH:mm"}
                                            ampm={false}
                                            className={classes.dateInput}
                                        />
                                    </div>
                                    <div className={styles["contractorCardForm__dates--single"]}>
                                        <p className={styles["contractorCardForm__dates--single--description"]}>Do:</p>
                                        <DateTimePicker
                                            name="endTime"
                                            selected={values.endTime}
                                            helperText={!!errors.endTime && touched.endTime ? errors.endTime : ""}
                                            error={!!errors.endTime && touched.endTime}
                                            value={values.endTime}
                                            disabled={
                                                !roles.includes("ROLE_OPERATOR") &&
                                                !roles.includes("ROLE_SUPER_OPERATOR")
                                            }
                                            onChange={(date) => setFieldValue("endTime", date)}
                                            autoOk={false}
                                            format={"d.LLLL yyyy, HH:mm"}
                                            ampm={false}
                                            className={classes.dateInput}
                                            margin="none"
                                        />
                                    </div>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                    <div className={styles["contractorCardForm__form"]}>
                        <h1 className={styles["contractorCardForm__form--title"]}>Dane do rozliczeń</h1>
                        <div className={styles["contractorCardForm__inputs"]}>
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>stawka*</p>
                                <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                    <Field
                                        onKeyUp={handleIgnoreForm}
                                        className={classes.root}
                                        name="rate"
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleNumberOnChange("rate", e, setFieldValue)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">km</InputAdornment>,
                                            endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                                        }}
                                        helperText={!!errors.rate && touched.rate ? errors.rate : ""}
                                        error={!!errors.rate && touched.rate}
                                        variant="standard"
                                        style={{ width: 150 }}
                                        margin="none"
                                        as={TextField}
                                    />
                                </div>
                            </div>
                            {(!values.discount || roles.includes("ROLE_OPERATOR")) && (
                                <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                    <p className={styles["contractorCardForm__inputs--wrapper--title"]}>rabat</p>
                                    <div className={styles["contractorCardForm__inputs--wrapper--switch"]}>
                                        <AntSwitch
                                            name="discount"
                                            disabled={
                                                !roles.includes("ROLE_OPERATOR") &&
                                                !roles.includes("ROLE_SUPER_OPERATOR")
                                            }
                                            onChange={(e) => handleSwitchChange("discount", e, setFieldValue)}
                                            checked={values.discount}
                                        />
                                        <p
                                            className={
                                                styles["contractorCardForm__inputs--wrapper--single--lastDescription"]
                                            }
                                        >
                                            TAK/NIE
                                        </p>
                                    </div>
                                </div>
                            )}
                            {roles.includes("ROLE_OPERATOR") && roles.includes("ROLE_SUPER_OPERATOR") && (
                                <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                    <p className={styles["contractorCardForm__inputs--wrapper--title"]}>
                                        limit rabatu*
                                    </p>
                                    <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                        <Field
                                            onKeyUp={handleIgnoreForm}
                                            className={classes.root}
                                            name="discountLimit"
                                            onChange={(e) => handleNumberOnChange("discountLimit", e, setFieldValue)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">max</InputAdornment>,
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            helperText={
                                                !!errors.discountLimit && touched.discountLimit
                                                    ? errors.discountLimit
                                                    : ""
                                            }
                                            error={!!errors.discountLimit && touched.discountLimit}
                                            variant="standard"
                                            style={{ width: 150 }}
                                            margin="none"
                                            as={TextField}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>postój*</p>
                                <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                    <Field
                                        onKeyUp={handleIgnoreForm}
                                        className={classes.root}
                                        name="stop"
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleNumberOnChange("stop", e, setFieldValue)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">wart.</InputAdornment>,
                                            endAdornment: <InputAdornment position="start">zł/godz.</InputAdornment>,
                                        }}
                                        helperText={!!errors.stop && touched.stop ? errors.stop : ""}
                                        error={!!errors.stop && touched.stop}
                                        variant="standard"
                                        style={{ width: 150 }}
                                        margin="none"
                                        as={TextField}
                                    />
                                </div>
                            </div>
                            {roles.includes("ROLE_OPERATOR") && roles.includes("ROLE_SUPER_OPERATOR") && (
                                <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                    <p className={styles["contractorCardForm__inputs--wrapper--title"]}>Liczony od*</p>
                                    <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                        <Field
                                            onKeyUp={handleIgnoreForm}
                                            className={classes.root}
                                            name="countedFrom"
                                            onChange={(e) => handleNumberOnChange("countedFrom", e, setFieldValue)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">min</InputAdornment>,
                                            }}
                                            helperText={
                                                !!errors.countedFrom && touched.countedFrom ? errors.countedFrom : ""
                                            }
                                            error={!!errors.countedFrom && touched.countedFrom}
                                            variant="standard"
                                            style={{ width: 150 }}
                                            margin="none"
                                            as={TextField}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>Autostrady</p>
                                <div className={styles["contractorCardForm__inputs--wrapper--switch"]}>
                                    <AntSwitch
                                        name="isHighwayPay"
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleSwitchChange("isHighwayPay", e, setFieldValue)}
                                        checked={values.isHighwayPay}
                                    />
                                    <p
                                        className={
                                            styles["contractorCardForm__inputs--wrapper--single--lastDescription"]
                                        }
                                    >
                                        TAK/NIE
                                    </p>
                                </div>
                            </div>
                            {roles.includes("ROLE_OPERATOR") && roles.includes("ROLE_SUPER_OPERATOR") && (
                                <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                    <p className={styles["contractorCardForm__inputs--wrapper--title"]}>kara umowna*</p>
                                    <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                        <Field
                                            onKeyUp={handleIgnoreForm}
                                            className={classes.root}
                                            name="penalty"
                                            onChange={(e) => handleNumberOnChange("penalty", e, setFieldValue)}
                                            disabled={
                                                !roles.includes("ROLE_OPERATOR") &&
                                                !roles.includes("ROLE_SUPER_OPERATOR")
                                            }
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">wart.</InputAdornment>,
                                                endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                                            }}
                                            helperText={!!errors.penalty && touched.penalty ? errors.penalty : ""}
                                            error={!!errors.penalty && touched.penalty}
                                            variant="standard"
                                            style={{ width: 150 }}
                                            margin="none"
                                            as={TextField}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>Inne*</p>
                                <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                    <Field
                                        onKeyUp={handleIgnoreForm}
                                        className={classes.root}
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleNumberOnChange("others", e, setFieldValue)}
                                        name="others"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">wart.</InputAdornment>,
                                            endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                                        }}
                                        helperText={!!errors.others && touched.others ? errors.others : ""}
                                        error={!!errors.others && touched.others}
                                        variant="standard"
                                        style={{ width: 150 }}
                                        margin="none"
                                        endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                        as={TextField}
                                    />
                                </div>
                            </div>
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>
                                    Autostrady z pracownikiem
                                </p>
                                <div className={styles["contractorCardForm__inputs--wrapper--switch"]}>
                                    <AntSwitch
                                        name="highwayWithWorker"
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleSwitchChange("highwayWithWorker", e, setFieldValue)}
                                        checked={values.highwayWithWorker}
                                    />
                                    <p
                                        className={
                                            styles["contractorCardForm__inputs--wrapper--single--lastDescription"]
                                        }
                                    >
                                        TAK/NIE
                                    </p>
                                </div>
                            </div>
                            <div className={styles["contractorCardForm__inputs--double-wrapper"]}>
                                <div className={styles["contractorCardForm__inputs--wrapper--single"]}>
                                    <Field
                                        onKeyUp={handleIgnoreForm}
                                        className={classes.root}
                                        name="billingModel"
                                        helperText={
                                            !!errors.billingModel && touched.billingModel ? errors.billingModel : ""
                                        }
                                        error={!!errors.billingModel && touched.billingModel}
                                        margin="none"
                                        fullWidth
                                        render={({ field }) => (
                                            <TextField {...field} value={values.billingModel} fullWidth select label="Grupa rozliczeniowa">
                                                {billingModels.map((billingModel) => (
                                                    <MenuItem key={billingModel} value={billingModel}>
                                                        {billingModel}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={styles["contractorCardForm__inputs--wrapper"]}>
                                <p className={styles["contractorCardForm__inputs--wrapper--title"]}>
                                    Autostrady bez pracownika
                                </p>
                                <div className={styles["contractorCardForm__inputs--wrapper--switch"]}>
                                    <AntSwitch
                                        name="highwayWithoutWorker"
                                        disabled={
                                            !roles.includes("ROLE_OPERATOR") && !roles.includes("ROLE_SUPER_OPERATOR")
                                        }
                                        onChange={(e) => handleSwitchChange("highwayWithoutWorker", e, setFieldValue)}
                                        checked={values.highwayWithoutWorker}
                                    />
                                    <p
                                        className={
                                            styles["contractorCardForm__inputs--wrapper--single--lastDescription"]
                                        }
                                    >
                                        TAK/NIE
                                    </p>
                                </div>
                            </div>
                            {(roles.includes("ROLE_OPERATOR") || roles.includes("ROLE_SUPER_OPERATOR")) && (
                                <div className={styles["contractorCardForm__inputs--notes"]}>
                                    <p className={styles["contractorCardForm__inputs--notes--title"]}>Uwagi</p>
                                    <div className={styles["contractorCardForm__inputs--notes--single"]}>
                                        <Field
                                            className={classes.root}
                                            disabled={
                                                !roles.includes("ROLE_OPERATOR") &&
                                                !roles.includes("ROLE_SUPER_OPERATOR")
                                            }
                                            onKeyUp={handleIgnoreForm}
                                            name="notes"
                                            variant="standard"
                                            style={{
                                                width: "100%",
                                                borderColor: "#d7d7d7",
                                                outlineColor: "#1DBBED",
                                                borderRadius: 0,
                                                resize: "vertical",
                                            }}
                                            margin="none"
                                            as={TextareaAutosize}
                                            rowsMin={6}
                                            rowsMax={7}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {(roles.includes("ROLE_OPERATOR") || roles.includes("ROLE_SUPER_OPERATOR")) && (
                        <div className={styles["contractorCardForm__buttonWrapper"]}>
                            <Button className={classes.confirmButton} variant="contained" type="submit">
                                Potwierdź
                            </Button>
                        </div>
                    )}
                </Form>
            )}
        />
    );
}
