import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import React from "react";
import styles from "../rozliczenia.module.scss";
import {createMuiTheme, TextField} from "@material-ui/core";
import {makeStyles, styled} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import _ from 'lodash';
import {ThemeProvider} from "@material-ui/styles";
import {DateTimePicker} from "@material-ui/pickers";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {getUnixTime} from 'date-fns'
import {Autocomplete} from "@material-ui/lab";
import {getFields} from "../getFields";

const SingleInput = styled(TextField)({
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        fontSize: 10,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
});

const SettlementSchema = Yup.object().shape({
    date: Yup.date().required("Uzupełnij to pole"),
    worker: Yup.string().required("Uzupełnij to pole"),
    contractor: Yup.string().required("Uzupełnij to pole"),
    route: Yup.string().required("Uzupełnij to pole"),
    sumKm: Yup.number().required("Uzupełnij to pole"),
    normalRate: Yup.number().required("Uzupełnij to pole"),
    discount: Yup.number().required("Uzupełnij to pole"),
    rateAfterDiscount: Yup.number().required("Uzupełnij to pole"),
    sumKmCost: Yup.number().required("Uzupełnij to pole"),
    countStop: Yup.number().required("Uzupełnij to pole"),
    stopCost: Yup.number().required("Uzupełnij to pole"),
    highwayCost: Yup.number().required("Uzupełnij to pole"),
    sumCost: Yup.number().required("Uzupełnij to pole"),
    saveMoney: Yup.number().required("Uzupełnij to pole"),
    comments: Yup.string()
});

const DriverSchema = Yup.object().shape({
    date: Yup.date().required("Uzupełnij to pole"),
    contractor: Yup.string().required("Uzupełnij to pole"),
    billingGroupName: Yup.string().required("Uzupełnij to pole"),
    worker: Yup.string().required("Uzupełnij to pole"),
    driverName: Yup.string().required("Uzupełnij to pole"),
    driverSurname: Yup.string().required("Uzupełnij to pole"),
    route: Yup.string().required("Uzupełnij to pole"),
    sumKm: Yup.number().required("Uzupełnij to pole"),
    normalRate: Yup.number().required("Uzupełnij to pole"),
    waitingRate: Yup.number().required("Uzupełnij to pole"),
    waitingCost: Yup.number().required("Uzupełnij to pole"),
    sumKmCost: Yup.number().required("Uzupełnij to pole"),
    highwayCost: Yup.number().required("Uzupełnij to pole"),
    sumCost: Yup.number().required("Uzupełnij to pole"),
});

const getSchema = (type) => {
    switch (type) {
        case 1: return SettlementSchema;
        case 3: return DriverSchema;
    }
}

const useStyle = makeStyles(() => ({
    submitButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    }
}));

const DestinationDate = styled(DateTimePicker)({
    marginBottom: "3px",
    fontFamily: "'Open Sans', sans-serif",
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

export default function RozliczeniaForm({sendData, contractors, billingGroups, type}) {
    const initialValues = getFields(type);
    const classes = useStyle();

    const handleEdit = (event, name, values, setValues) => {
        let {value} = event.target;

        value = ['.', ','].includes(value.charAt(0)) ? "" : value;
        if(initialValues[name].type === 'number') value = value.replace(',', '.');

        let finalValue;

        const regExp = /^\d{0,10}(\.\d{0,2})?$/;
        const condition = initialValues[name].type === 'number' && (regExp.test(value) || value === '');

        if(condition) {
            const tempValue = value;
            if(name !== 'discount' || (tempValue >= 0 && tempValue <= 100 && (values.contractor.contract?.maxDiscount === undefined || tempValue <= values.contractor.contract?.maxDiscount))) finalValue = tempValue;
        } else if(initialValues[name].type === 'text') {
            finalValue = value;
        }

        if(finalValue || finalValue === '') {
            const calculatedValues = {...values};
            calculatedValues[name] = finalValue === '0' ? 0 : finalValue;
            setValues(costCalculation(calculatedValues));
        }
    }

    const handleAutoCompleteChange = (name, value, setFieldValue) => {
        setFieldValue(name, value);
        if(name === 'billedOrder') setFieldValue('highwayCost', value.highwayCost ? value.highwayCost : 0);
    }

    const safeParseFloat = (value) => {
        value = value === '' ? 0 : value;
        const parsed = parseFloat(value);
        return !isNaN(parsed) ? parsed : value;
    }

    const disableTextField = (key, contract, isDisabled) => {
        const highwayCondition = key === 'highwayCost' && contract?.isHighwayPay === false;
        const discountCondition = key === 'discount' && contract?.isDiscount === false;
        const waitingCondition = (key === 'stopCount' || key === 'stopCost') && (!contract?.waitingRate || !contract?.countedFrom)

        return isDisabled || highwayCondition || discountCondition || waitingCondition;
    }

    const changeContractor = (newValue, values, setValues) => {
        const newValues = {...values};

        newValues.normalRate = newValue?.contract?.normalRate || 0;
        newValues.contractor = newValue;

        setValues(newValues);
    }

    const changeBillingGroup = (newValue, values, setValues) => {
        const newValues = {...values};

        newValues.billingGroupName = newValue;

        setValues(newValues);
    }

    const changeAutocomplete = (key, newValue, values, setValues) => {
        if (key === 'contractor') {
            return changeContractor(newValue, values, setValues)
        } else {
            return changeBillingGroup(newValue, values, setValues)
        }
    }


    const costCalculation = (values) => {
        const {contract} = values.contractor;

        if (type === 1) {
            values.rateAfterDiscount = _.round(values.normalRate - (values.normalRate * values.discount / 100), 2);
            values.sumKmCost = _.round(values.sumKm * values.rateAfterDiscount, 2);
            let stopCost = 0;
            const countedFrom = contract?.countedFrom;
            const waitingRate = contract?.waitingRate;
            if (!!countedFrom && !!waitingRate) {
                stopCost = values.stopCost = _.round(Math.ceil((values.countStop * 60 - countedFrom) / 60) * waitingRate, 2);
            } else {
                values.stopCost = 0;
            }
            values.sumCost = _.round(values.sumKmCost + stopCost + safeParseFloat(values.highwayCost), 2);
            values.saveMoney = _.round((values.sumKm * values.normalRate) - values.sumKmCost, 2);
        } else if (type === 3) {
            values.sumKmCost = _.round(values.sumKm * values.normalRate, 2);
            values.sumCost = _.round(values.sumKmCost + safeParseFloat(values.waitingCost) + safeParseFloat(values.highwayCost), 2);
        }
        return values
    }

    const transformInitialValues = (initialValues) => {
        const obj = {};
        Object.entries(initialValues).forEach(([key, value]) => {
            obj[key] = value.value;
        });

        return obj;
    }

    const transformValuesToSend = (values) => {
        const driver = values?.driverFullName?.split(' ');
        const newValues =  {
            ...values,
            //celowa literowka, na backu istnieje literowka i juz nie warto tego zmieniac
            dicount: values.discount,
            date: getUnixTime(values.date),
            contractor: values.contractor.name,
            //dla kierowcy
            accountDate: values.date,
            billingGroupName: values.billingGroupName?.name,
            status: 1,
        }

        if (type === 3) {
            newValues.driverUsername = `${values?.driverName?.toLowerCase()}.${values?.driverSurname?.toLowerCase()}`
        }
        return newValues
    }

    return (
        <Formik
            initialValues={transformInitialValues(initialValues)}
            validationSchema={getSchema(type)}
            onSubmit={(values, {resetForm}) => {
                if (!values) {
                    return;
                }
                sendData(transformValuesToSend(values), resetForm);
            }}
        >
            {({values, errors, touched, setFieldValue, setValues}) => (
                <Form style={{display: 'flex', flexWrap: 'wrap'}}>
                    {Object.entries(values).map(([key, value]) => (
                        <>
                            <div style={{width: initialValues[key]?.width, marginLeft: 10, marginBottom: 10}}>
                                {
                                    key === 'date' ? (
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <DestinationDate
                                                autoOk={false}
                                                format={"d.LLLL yyyy"}
                                                ampm={false}
                                                name={key}
                                                value={value}
                                                onChange={(newValue) => handleAutoCompleteChange(key, newValue, setFieldValue)}
                                                label="Wybierz datę"
                                                fullWidth
                                                helperText={
                                                    !!errors[key] && touched[key] ? errors[key] : ""
                                                }
                                                error={!!errors[key] && touched[key]}
                                            />
                                        </ThemeProvider>
                                    ) : (
                                        key === 'contractor' || key === "billingGroupName" ? (
                                            <Autocomplete
                                                options={ key === "contractor" ? contractors : billingGroups}
                                                onChange={(e, newValue) => changeAutocomplete(key, newValue, values, setValues)}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.name}
                                                value={value}
                                                renderInput={(params) => (
                                                    <SingleInput
                                                        {...params}
                                                        label={initialValues[key]?.name}
                                                        variant="standard"
                                                        helperText={
                                                            !!errors[key] && touched[key] ? errors[key] : ""
                                                        }
                                                        error={!!errors[key] && touched[key]}
                                                        margin="none"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Field
                                                name={key}
                                                fullWidth
                                                helperText={
                                                    !!errors[key] && touched[key] ? errors[key] : ""
                                                }
                                                error={!!errors[key] && touched[key]}
                                                disabled={disableTextField(key, values.contractor?.contract, initialValues[key]?.disabled)}
                                                label={initialValues[key]?.name}
                                                InputProps={{ onChange: (e) => handleEdit(e, key, values, setValues) }}
                                                variant="standard"
                                                as={TextField}
                                            />
                                        )
                                    )
                                }
                            </div>
                            {key === 'saveMoney' && <div className="flex-breakLine"></div>}
                        </>
                    ))}
                    <div className={styles["rozliczenia__wrapper--inputs--singleLine--buttonWrapper"]}>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={classes.submitButton}
                        >
                            Dodaj rozliczenie
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
