import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const publicUrl = process.env.PUBLIC_URL;

// Font.register({
//   family: "Roboto",
//   fonts: [
//     { src: `${publicUrl}/Roboto-Regular.ttf` },
//     { src: `${publicUrl}/Roboto-Bold.ttf` },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  textVerySmall: {
    fontSize: 8,
  },
  textSmall: {
    fontSize: 10,
  },
  textMedium: {
    fontSize: 14,
  },
  textBig: {
    fontSize: 16,
  },
  mt1: {
    marginTop: 10,
  },
  mt2: {
    marginTop: 20,
  },
  mt3: {
    marginTop: 30,
  },
  mtAuto: {
    marginTop: "auto",
  },
  mr1: {
    marginRight: 10,
  },
  mr2: {
    marginRight: 20,
  },
  mb1: {
    marginBottom: 10,
  },
  mb2: {
    marginBottom: 20,
  },
  container: {
    width: "95%",
    height: "100%",
    marginHorizontal: "auto",
    display: "flex",
    flexDirection: "column",
  },
  textCenter: {
    textAlign: "center",
  },
  textUpperCase: {
    textTransform: "uppercase",
  },
  borderTop: {
    borderTop: true,
  },
  borderBottom: {
    borderBottom: true,
  },
  borderRight: {
    borderRight: true,
  },
  p1: {
    padding: 5,
    textAlign: "center"
  },
  pt1: {
    paddingTop: 10,
  },
  py1: {
    paddingVertical: 2,
  },
  px1: {
    paddingHorizontal: 10,
  },
  pb1: {
    paddingBottom: 10,
  },
  pb2: {
    paddingBottom: 20,
  },
  pb3: {
    paddingBottom: 30,
  },
  pb4: {
    paddingBottom: 40,
  },
  pb5: {
    paddingBottom: 50,
  },
  pl1: {
    paddingLeft: 10,
  },
  textBold: {
    fontWeight: "bold",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
});

const getFullNameOfVehicle = ({ name, capacity, regNo }) => {
  return `${name}${capacity ? ", " + capacity : ""}${
    regNo ? ", " + regNo : ""
  }`;
};

export const GeneratePdf = ({ pdfData }) => {
  const typesOfVehicles = [
    ...new Set([
      ...pdfData.statements.map(({ typeOfVehicle }) =>
        getFullNameOfVehicle(typeOfVehicle)
      ),
    ]),
  ];

  Font.register({
    family: "Roboto",
    fonts: [
      { src: `${publicUrl}/Roboto-Regular.ttf` },
      { src: `${publicUrl}/Roboto-Bold.ttf` },
    ],
  });

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page }}>
        <View style={{ ...styles.container }}>
          <View style={{ ...styles.textCenter }}>
            <Text style={{ ...styles.textSmall }}>
              <Text>{pdfData.contractorName} {pdfData.contractorAddress}</Text>
            </Text>
          </View>
          <View style={{ ...styles.textCenter, ...styles.mt3 }}>
            <Text
              style={{
                ...styles.textUpperCase,
                ...styles.textBig,
                ...styles.textBold,
              }}
            >
              Polecenie wyjazdu służbowego
            </Text>
          </View>
          <View
            style={{
              ...styles.borderTop,
              ...styles.mt1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                ...styles.borderRight,
                ...styles.pt1,
                ...styles.pb1,
                flexGrow: 2,
                flexShrink: 0,
              }}
            >
              <View style={{ ...styles.row }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>
                  Numer:
                </Text>
                <Text>{pdfData.delegationNumber}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.mr1, ...styles.textBold }}>
                  Z dnia:
                </Text>
                <Text>{pdfData.createdAt}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>Dla:</Text>
                <Text>{pdfData.worker}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>
                  Stanowisko:
                </Text>
                <Text>{pdfData.position}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>
                  Na czas od:
                </Text>
                <Text>{pdfData.dateFrom}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>
                  Na czas do:
                </Text>
                <Text>{pdfData.dateTo}</Text>
              </View>
              <View style={{ ...styles.row, ...styles.mt1 }}>
                <Text style={{ ...styles.textBold, ...styles.mr1 }}>
                  Kontrahent:
                </Text>
              </View>
              {pdfData.clients.map((client) => (
                <View style={{ ...styles.row, ...styles.mt1 }}>
                  <Text style={{ ...styles.mr1 }}>
                    {client.name} {client.totalCost}zł
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={{
                ...styles.pt1,
                ...styles.pl1,
                flexGrow: 6,
                flexShrink: 1,
              }}
            >
              <Text style={{ ...styles.textBold, ...styles.textMedium }}>
                Potwierdzenie pobytu oraz uwagi dot. kosztów:
              </Text>
            </View>
          </View>
          <View style={{ ...styles.mt3 }}>
            <Text style={{ ...styles.textBold }}>
              Środek lokomocji (w przypadku pojazdów numer rejestracyjny i
              pojemność silnika):
            </Text>
            {typesOfVehicles.map((vehicle) => (
              <Text style={{ ...styles.mt1, ...styles.borderBottom }}>
                {vehicle}
              </Text>
            ))}
          </View>
          <View style={{ ...styles.mt3 }}>
            <Text>Zatwierdzam:</Text>
            <View
              style={{ ...styles.mt1, display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  ...styles.borderBottom,
                  ...styles.mr2,
                  position: "relative",
                }}
              >
                <Text>{pdfData.adminName}</Text>
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  imię i nazwisko
                </Text>
              </View>
              <View
                style={{
                  ...styles.borderBottom,
                  width: "100px",
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  podpis
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.mt3, ...styles.pb1 }}>
            <Text>
              Niniejszym kwituję odbiór zaliczki na pokrycie kosztów w kwocie:
            </Text>
            <View
              style={{ ...styles.mt1, display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  ...styles.borderBottom,
                  ...styles.mr2,
                  position: "relative",
                }}
              >
                <Text style={{ width: "100px", minHeight: "15px" }}>
                 {pdfData.paymentOnAccount && `${pdfData.paymentOnAccount} zł`}
                </Text>
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  kwota
                </Text>
              </View>
              <View
                style={{
                  ...styles.borderBottom,
                  width: "100px",
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  podpis
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.textVerySmall, ...styles.mtAuto }}>
            <Text style={{ ...styles.textBold }}>
              Wyciąg z rozporządzenia MPiPS w sprawie podróży służbowych od
              1.03.2013
            </Text>
            <Text style={{ ...styles.mt1 }}>
              Dieta w czasie podróży krajowej jest przeznaczona na pokrycie
              zwiększonych kosztów wyżywienia i wynosi 30 zł za dobę podróży.
              Należność z tytułu diet oblicza się za czas od rozpoczęcia podróży
              krajowej (wyjazdu) do powrotu (przyjazdu) po wykonaniu zadania
              służbowego w następujący sposób:
            </Text>
            <Text>1. jeżeli podróz trwa nie dłużej niż dobę i wynosi:</Text>
            <Text style={{ ...styles.pl1 }}>
              a&#41; mniej niż 8 godzin - dieta nie przysługuje,
            </Text>
            <Text style={{ ...styles.pl1 }}>
              b&#41; od 8 do 12 godzin - przysługuje 50% diety,
            </Text>
            <Text style={{ ...styles.pl1 }}>
              c&#41; ponad 12 godzin - przysługuje dieta w pełnej wysokości;
            </Text>
            <Text>
              2. jeżeli podróz trwa dłużej niż dobę, za każdą dobę przysługuje
              dieta w pełnej wysokości, a za niepełną, ale rozpoczętą dobę:
            </Text>
            <Text style={{ ...styles.pl1 }}>
              a&#41; do 8 godzin - przysługuje 50% diety,
            </Text>
            <Text style={{ ...styles.pl1 }}>
              b&#41; ponad 8 godzin - przysługuje dieta w pełnej wysokości;
            </Text>
            <Text>
              3. Dieta nie przysługuje za czas delegowania do miejscowości
              pobytu stałego lub czasowego pracownika oraz w przypadkach gdy
              pracownik co 10 dni na koszt pracodawcy odwiedza miejsce
              zamieszkania, a także jeżeli pracownikowi zapewniono bezpłatne
              całodzienne wyżywienie.
            </Text>
            <Text style={{ ...styles.mt1 }}>
              Kwotę diety zmniejsza się o koszt zapewnionego bezpłatnego
              wyżywienia, przyjmując, że każdy posiłek stanowi odpowiednio:
              śniadanie - 25% diety; obiad - 50% diety; kolacja - 25% diety.
              &#40;Niniejszy kalkulator nie uwzględnia takiej możliwości&#41;. W
              przypadku korzystania przez pracownika z usługi hotelarskiej, w
              ramach której zapewniono wyżywienie, diety obniża się w taki sam
              sposób.
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={{ ...styles.page }}>
        <View style={{ ...styles.container }}>
          <Text
            style={{
              ...styles.textCenter,
              ...styles.textMedium,
              ...styles.textBold,
              ...styles.textUpperCase,
            }}
          >
            Rachunek kosztów podróży (zestawienie)
          </Text>
          <View style={{ ...styles.mt2 }}>
            <Table>
              <TableHeader
                includeBottomBorder={false}
                textAlign="center"
                fontSize="8"
              >
                <TableCell
                  weighting="0.222"
                />
                <TableCell style={{ ...styles.textBold}} weighting="0.222">
                  Wyjazd
                </TableCell>
                <TableCell style={{ ...styles.textBold}} weighting="0.222">
                  Przyjazd
                </TableCell>
                <TableCell weighting="0.334" />
              </TableHeader>
            </Table>
            <Table data={pdfData.statements}>
              <TableHeader textAlign="center" fontSize="8">
                <TableCell style={{ ...styles.textBold }}>
                  Typ (np. PKS, PKP, pojazd własny)
                </TableCell>
                <TableCell style={{ ...styles.textBold }}>
                  Kontrahent
                </TableCell>
                <TableCell style={{ ...styles.textBold }}>
                  Miejscowość
                </TableCell>
                <TableCell style={{ ...styles.textBold }}>Data</TableCell>
                <TableCell style={{ ...styles.textBold }}>
                  Miejscowość
                </TableCell>
                <TableCell style={{ ...styles.textBold }}>Data</TableCell>
                <TableCell style={{ ...styles.textBold }}>Za 1 KM</TableCell>
                <TableCell style={{ ...styles.textBold }}>KM</TableCell>
                <TableCell style={{ ...styles.textBold }}>
                  Koszt przejazdu
                </TableCell>
              </TableHeader>
              <TableBody textAlign="center" fontSize="8">
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' :  row.typeOfVehicle.name}
                />
                <DataTableCell
                    style={{ ...styles.py1 }}
                    getContent={(row) => row.totalKm === 0 ? '-' : row.client}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : row.departureCity}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : row.departureDate}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : row.arrivalCity}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : row.arrivalDate}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : `${row.pricePerKm} zł`}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : row.totalKm}
                />
                <DataTableCell
                  style={{ ...styles.py1 }}
                  getContent={(row) => row.totalKm === 0 ? '-' : `${row.totalPrice} zł`}
                />
              </TableBody>
            </Table>
          </View>
          <View style={{ ...styles.mt1, width: "90%", marginLeft: "auto" }}>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.8">
                  <Text style={{ ...styles.p1 }}>Razem koszty przejazdu:</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text
                    style={{ ...styles.p1 }}
                  >{`${pdfData.statementsTotalPrice} zł`}</Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.45">
                  <Text style={{ ...styles.p1 }}>
                    Ryczałt za przejazdy lokalne
                  </Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.startedFor6} zł
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>rozp. dób x 6,00zł</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.startedFor6} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.1">
                  <Text style={{ ...styles.p1 }}>Diety</Text>
                </TableCell>
                <TableCell weighting="0.35">
                  <Text style={{ ...styles.p1 }}>Delegacja jednodniowa:</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.oneDayDelegation} zł
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.oneDayDelegation} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.1">
                  <Text style={{ ...styles.p1 }}>Diety</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>Delegacja dłuższa:</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>Całych dób:</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.longerDelegationFull} zł
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.longerDelegationFull} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.1">
                  <Text style={{ ...styles.p1 }}>Diety</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>Delegacja dłuższa:</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>Rozpoczęta:</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.longerDelegationStarted} zł
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.longerDelegationStarted} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.8">
                  <Text style={{ ...styles.p1 }}>Noclegi wg rachunków:</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                      {pdfData.extraData.accomodationPrice} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.45">
                  <Text style={{ ...styles.p1 }}>Noclegi - ryczałt</Text>
                </TableCell>
                <TableCell weighting="0.15">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.extraData.accomodationLumpSum} zł
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                      {pdfData.extraData.accomodationLumpSum} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.8">
                  <Text style={{ ...styles.p1 }}>
                    Inne wydatki udokumentowane fakturami, rachunkami,
                    powkitowaniami:
                  </Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                     {pdfData.extraData.otherCosts} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.6">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>Razem:</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>{`${pdfData.toPayBefore ?? 0} zł`}</Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={false}>
                <TableCell weighting="0.6">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>Zaliczka:</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                      {pdfData.paymentOnAccount} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
            <Table>
              <TableHeader fontSize="8" includeBottomBorder={true}>
                <TableCell weighting="0.6">
                  <Text style={{ ...styles.p1 }}></Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>Do wypłaty:</Text>
                </TableCell>
                <TableCell weighting="0.2">
                  <Text style={{ ...styles.p1 }}>
                    {pdfData.toPay} zł
                  </Text>
                </TableCell>
              </TableHeader>
            </Table>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ ...styles.textBold }}>Słownie: </Text>
            <Text>{pdfData.toPayInWords}</Text>
          </View>
          <View style={{ ...styles.mt3 }}>
            <Text>Niniejszy rachunek przedkładam w dniu:</Text>
            <View
              style={{ ...styles.mt1, display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  ...styles.borderBottom,
                  ...styles.mr2,
                  position: "relative",
                }}
              >
                <Text>{pdfData.createdAt}</Text>
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  data
                </Text>
              </View>
              <View
                style={{
                  ...styles.borderBottom,
                  width: "100px",
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  podpis
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.mt3 }}>
            <Text>Niniejszy rachunek zatwierdzam w dniu:</Text>
            <View
              style={{ ...styles.mt1, display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  ...styles.borderBottom,
                  ...styles.mr2,
                  position: "relative",
                }}
              >
                <Text style={{ minHeight: "30px", minWidth: "100px" }}></Text>
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  data
                </Text>
              </View>
              <View
                style={{
                  ...styles.borderBottom,
                  width: "100px",
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  podpis
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.mt3, ...styles.mb2 }}>
            <Text>Kwituję odbiór w dniu:</Text>
            <View
              style={{ ...styles.mt1, display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  ...styles.borderBottom,
                  ...styles.mr2,
                  position: "relative",
                }}
              >
                <Text style={{ minHeight: "30px", minWidth: "100px" }}></Text>
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  data
                </Text>
              </View>
              <View
                style={{
                  ...styles.borderBottom,
                  width: "100px",
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    ...styles.textSmall,
                    position: "absolute",
                    bottom: "-15px",
                  }}
                >
                  podpis
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.textVerySmall, ...styles.mtAuto }}>
            <Text>
              Za nocleg podczas podróży krajowej w obiekcie świadczącym usługi
              hotelarskie pracownikowi przysługuje zwrot kosztów w wysokości
              stwierdzonej rachunkiem, jednak nie wyższej za jedną dobę hotelową
              niż dwudziestokrotność stawki diety. W uzasadnionych przypadkach
              pracodawca może wyrazić zgodę na zwrot kosztów noclegu
              stwierdzonych rachunkiem w wysokości przekraczającej ten limit.
              Pracownikowi, któremu nie zapewniono bezpłatnego noclegu i który
              nie przedłożył rachunku za nocleg przysługuje ryczałt za każdy
              nocleg w wysokości 150% diety. Ryczałt za nocleg przysługuje,
              jeżeli nocleg trwa co najmniej 6 godzin pomiędzy godzinami 21 i 7.
              Zwrot kosztów noclegu lub ryczałt za nocleg nie przysługuje za
              czas przejazdu, a także jeżeli pracodawca uzna, że pracownik ma
              możliwość codziennego powrotu do miejscowości stałego lub
              czasowego pobytu. Za każdą rozpoczętą dobę pobytu w podróży
              pracownikowi przysługuje ryczałt na pokrycie kosztów dojazdu
              środkami komunikacji miejscowej w wysokości 20% diety. Ryczałt nie
              przysługuje, jeżeli pracownik nie ponosi kosztów dojazdów, oraz
              gdy pracodawca wyrazi zgodę na pokrycie udokumentowanych kosztów
              dojazdów środkami komunikacji miejscowej. Pracownikowi
              przebywającemu w podróży krajowej trwającej co najmniej 10 dni
              przysługuje zwrot kosztów przejazdu w dniu wolnym od pracy,
              środkiem transportu określonym przez pracodawcę, do miejscowości
              pobytu stałego lub czasowego i z powrotem.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
