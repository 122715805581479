import React, { useEffect, useState } from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TextField,
    MenuItem,
    Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TimelineItem, TimelineDot, TimelineSeparator, TimelineConnector, TimelineContent } from "@material-ui/lab";
import settlementsPanelSettlementHelper from "../common/api/settlementsPanelSettlement.helper";
import settlementsPanelStyles from "./../settlementsPanelStyles";
import settlementsApiService from "../common/api/settlementsApi.service";
import SettlementsPanelPriceInput from "../common/settlementsPanelPriceInput";
import SettlementsPanelDistanceInput from "../common/settlementsPanelDistanceInput";
import { Add, Delete, FileCopy } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { DateTimePicker } from "@material-ui/pickers";

const SettlementsPanelDriverSettlement = (props) => {
    const styles = settlementsPanelStyles();
    const [billingModels, setBillingModels] = useState([]);
    const [placeAddresses, setPlaceAddresses] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        settlementsApiService.fetchBillingModels().then((response) => {
            const newBillingModels = response.data.data.map((responseBillingModel) => ({
                id: responseBillingModel.id,
                name: responseBillingModel.name,
            }));

            setBillingModels(newBillingModels);
        });
    }, []);

    useEffect(() => {
        if (!props.orderId) {
            return;
        }

        settlementsApiService.fetchPlaceAddresses({ orderId: props.orderId }).then((response) => {
            const newPlaceAddresses = response.data.data;

            setPlaceAddresses(newPlaceAddresses);
        });
    }, [props.orderId]);

    useEffect(() => {
        settlementsApiService.fetchDrivers().then((response) => {
            const newDrivers = response.data.pracownicy.map((driver) => {
                return {
                    id: driver.user.id,
                    username: driver.user.username,
                    firstName: driver.user.name,
                    lastName: driver.user.surName,
                    billingModel: driver.user.billingModel,
                    billingGroup: driver.user.billingGroup,
                    rate: driver.user.normalRate,
                    waitingRate: driver.user.waitingRate,
                    startAddress: driver.user.adres[0],
                    addresses: driver.user.adres,
                };
            });

            setDrivers(newDrivers);
        });
    }, []);

    const { driverData } = props.selectedSettlement;

    const onBillingModelChange = (value) => {
        const billingModel = billingModels.find((billingModel) => billingModel.id === value);
        props.onBillingModelChange(billingModel);
    };

    const computeTotalTime = (routesSettlements) => {
        if (!routesSettlements?.length) {
            return undefined;
        }

        if (!routesSettlements.every((item) => item.date)) {
            return undefined;
        }

        const lastStageIndex = routesSettlements.length - 1;
        const startTime = routesSettlements[0].date.setSeconds(0);
        const endTime = routesSettlements[lastStageIndex].date.getTime() + (routesSettlements[lastStageIndex].stopDuration * 60 * 1000);

        const routeDurationTime = endTime - startTime;

        if (routeDurationTime < 0) {
            return undefined;
        }

        return routeDurationTime;
    };

    const computeRoutesCompletedTotalTime = (completedRoutes) => {
        if (!completedRoutes?.length) {
            return undefined;
        }

        if (!completedRoutes.every((item) => item.date)) {
            return undefined;
        }

        const lastStageIndex = completedRoutes.length - 1;
        // const startTime = completedRoutes[0].date.getTime();
        // const endTime = completedRoutes[lastStageIndex].date.getTime();
        const startTime = new Date(completedRoutes[0].date.setSeconds(0,0)).getTime();
        const endTime = new Date(completedRoutes[lastStageIndex].date.setSeconds(0,0)).getTime();
        const routeDurationTime = endTime - startTime;

        if (routeDurationTime < 0) {
            return undefined;
        }

        return routeDurationTime;
    };

    const getFormattedTotalTime = (duration) => {
        const withZero = (number) => {
            return number < 10 ? `0${number}` : number;
        };

        const seconds = duration / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;

        const formattedHours = Math.floor(hours);
        const formattedMinutes = withZero(Math.floor(minutes % 60));

        return `${formattedHours}:${formattedMinutes} h`;
    };

    const totalRoutesCompletedTime = computeRoutesCompletedTotalTime(driverData.completedRoutes);

    const formattedRoutesCompletedTotalTime = totalRoutesCompletedTime
        ? getFormattedTotalTime(totalRoutesCompletedTime)
        : "-----";

    const sumOfAllCompletedRoutesDistance = Math.ceil(
        driverData.completedRoutes.reduce((prev, current) => prev + current.distance, 0)
    );
    const driverDataWithStopTime = settlementsPanelSettlementHelper.getCalculatedTimeWithStop(driverData.routesSettlements)

    const totalRoutesSettlementTime = computeTotalTime(driverDataWithStopTime);

    const formattedRoutesSettlementTotalTime = totalRoutesSettlementTime
        ? getFormattedTotalTime(totalRoutesSettlementTime)
        : "-----";

    const totalRoutesSettlementStopDuration = driverData.routesSettlements.reduce(
        (prev, current) => prev + current.stopDuration,
        0
    );

    const sumOfAllRoutesSettlementDistance = driverData.routesSettlements.reduce(
        (prev, current) => prev + current.distance,
        0
    );

    const onStageStopDurationChange = (value, index) => {
        const priceValidationPattern = /^\d{0,8}$/;

        if (priceValidationPattern.test(value)) {
            props.onStageStopDurationChange(value, index);
        }
    };

    const onAddButtonClick = () => {
        props.addNewStage();
    };

    const onDeleteButtonClick = (index) => {
        props.deleteStage(index);
    };

    const driverAdresses = drivers.find((driver) => driver.id === driverData?.driver?.id)?.addresses ?? [];

    const onStartAddressChange = () => {};
    
    const isFieldsDisabled = props.isDispatcher || (props.isContractorOperator && props.selectedSettlement.partner !== null)

    const billingGroupName = props.selectedSettlement.driverData.driver ? props.selectedSettlement.driverData.driver.billingGroup?.name ?? props.selectedSettlement.driverData.driver.billingGroup : props.selectedSettlement.driverData.billingGroup
    const displayRouteData = ((billingGroupName === 'CS' || billingGroupName === 'K27') && !props.isContractorOperator) ||
        (billingGroupName !== 'CS' && billingGroupName !== 'K27' && props.isContractorOperator) || !billingGroupName

    if (!displayRouteData)
        return null
    
    return (
        <Box position={"relative"}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="70%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>
                                        {!!driverData.completedRoutes.length && (
                                            <IconButton
                                                size="small"
                                                style={{
                                                    background: props.areRoutesCopied ? "#66D01D" : "#1DBBED",
                                                    color: "#fff",
                                                    width: 20,
                                                    height: 20,
                                                    marginRight: 5,
                                                }}
                                                disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                onClick={() => props.onCopyRoutesButtonClick()}
                                            >
                                                <Tooltip title="Kopiuj">
                                                    <FileCopy
                                                        fontSize="small"
                                                        style={{ color: "white", fontSize: `0.65rem` }}
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                        Zrealizowana trasa
                                    </TableCell>
                                    <TableCell className={styles.tableHeadCell}>Godz</TableCell>
                                    <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {driverData.completedRoutes.map((completedRoute, index) => {
                                    const stageNo = index + 1;
                                    const address = settlementsPanelSettlementHelper.getFormattedAddress(
                                        completedRoute.address
                                    );
                                    const orderedTime = completedRoute.date
                                        ? settlementsPanelSettlementHelper.getFormattedTime(completedRoute.date)
                                        : "-----";

                                    const isLastPath = !driverData.completedRoutes[index + 1];

                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellRouteAddress}>
                                                    <div className={styles.tableCellText}>{stageNo}</div>
                                                    <TimelineItem className={styles.timelineItem}>
                                                        <TimelineSeparator>
                                                            <TimelineDot className={styles.timelineDot} />
                                                            {!isLastPath && <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        <TimelineContent className={styles.timelineContent}>
                                                            <div className={styles.tableCellText}>{address}</div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>{orderedTime}</div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>{completedRoute.distance} km</div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                <TableRow>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>{formattedRoutesCompletedTotalTime}</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {Math.ceil(sumOfAllCompletedRoutesDistance)} km
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="45%" />
                                <col width="55%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>Dane kierowcy</TableCell>
                                    <TableCell className={styles.tableHeadCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Nazwa</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <Autocomplete
                                                disableClearable
                                                fullWidth
                                                options={drivers}
                                                noOptionsText="Nie znaleziono kierowców"
                                                renderOption={(option) =>
                                                    settlementsPanelSettlementHelper.getFormattedDriver(option)
                                                }
                                                getOptionLabel={(option) =>
                                                    settlementsPanelSettlementHelper.getFormattedDriver(option)
                                                }
                                                value={driverData.driver ?? {}}
                                                onChange={(_event, value)  => {
                                                    props.onDriverChange(value);
                                                }}
                                                disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                className: styles.input,
                                                            }}
                                                        />
                                                    );
                                                }}
                                                ListboxProps={{ style: { fontSize: 12 } }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Model rozliczeniowy</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {driverData.driver ? (
                                                <TextField
                                                    fullWidth
                                                    select
                                                    disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                    value={driverData.billingModel?.id ?? null}
                                                    onChange={(e) => onBillingModelChange(e.target.value)}
                                                    inputProps={{ className: styles.input }}
                                                >
                                                    {billingModels.map((billingModel) => (
                                                        <MenuItem key={billingModel.id} value={billingModel.id}>
                                                            {billingModel.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                "-----"
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Stawka</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {driverData.driver ? (
                                                <>
                                                    <SettlementsPanelPriceInput
                                                        fullWidth
                                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                        value={driverData.rate}
                                                        onChange={props.onRateChange}
                                                        inputProps={{ className: styles.input }}
                                                    />
                                                    zł
                                                </>
                                            ) : (
                                                "-----"
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Adres startowy</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {driverData.driver ? (
                                                  <Autocomplete
                                                  disableClearable
                                                  fullWidth
                                                  disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                  options={driverAdresses}
                                                  noOptionsText="Nie znaleziono adresów"
                                                  renderOption={(option) =>
                                                      settlementsPanelSettlementHelper.getFormattedAddress(option)
                                                  }
                                                  getOptionLabel={(option) =>
                                                      settlementsPanelSettlementHelper.getFormattedAddress(option)
                                                  }
                                                  value={driverData.startAddress ?? null}
                                                  onChange={(_event, value) => {
                                                    onStartAddressChange(value);
                                                  }}
                                                  renderInput={(params) => {
                                                      return (
                                                          <TextField
                                                              {...params}
                                                              inputProps={{
                                                                  ...params.inputProps,
                                                                  className: styles.input,
                                                              }}
                                                          />
                                                      );
                                                  }}
                                                  ListboxProps={{ style: { fontSize: 12 } }}
                                              />
                                            
                                            ) : (
                                                "-----"
                                            )}
                                      
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Koszt postoju</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {driverData.driver ? (
                                                <>
                                                    <SettlementsPanelPriceInput
                                                        fullWidth
                                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                        value={driverData.stopCost}
                                                        onChange={props.onStopCostChange}
                                                        inputProps={{ className: styles.input }}
                                                    />
                                                    zł
                                                </>
                                            ) : (
                                                "-----"
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Uwagi</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {" "}
                                            {driverData.driver && driverData.notes ? driverData.notes : "-----"}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={5}>
                    <IconButton
                        size="small"
                        style={{
                            background: "#1DBBED",
                            color: "#fff",
                            width: 26,
                            height: 26,
                            right: 10,
                            top: -30,
                            position: "absolute",
                        }}
                        onClick={() => props.onCopySettlementsButtonClick()}
                    >
                        <Tooltip title="Kopiuj">
                            <FileCopy fontSize="small" style={{ color: "white", fontSize: `0.75rem` }} />
                        </Tooltip>
                    </IconButton>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="48%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>Rozliczenie kierowcy</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Godz.</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Postój</TableCell>
                                    <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Autostrada</TableCell>
                                    <TableCell className={styles.tableHeadCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {driverDataWithStopTime.map((routesSettlement, index) => {
                                    const stageNo = index + 1;
                                    
                                    const isLastPath = !driverDataWithStopTime[index + 1];

                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellRouteAddress}>
                                                    <div className={styles.tableCellText}>{stageNo}</div>
                                                    <TimelineItem
                                                        className={styles.timelineItem}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <TimelineSeparator>
                                                            <TimelineDot className={styles.timelineDot} />
                                                            {!isLastPath && <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        <TimelineContent
                                                            className={styles.timelineContent}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className={styles.tableCellText}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    fullWidth
                                                                    options={placeAddresses}
                                                                    noOptionsText="Nie znaleziono adresów"
                                                                    renderOption={(option) =>
                                                                        settlementsPanelSettlementHelper.getFormattedAddress(
                                                                            option
                                                                        )
                                                                    }
                                                                    getOptionLabel={(option) =>
                                                                        settlementsPanelSettlementHelper.getFormattedAddress(
                                                                            option
                                                                        )
                                                                    }
                                                                    disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                                    key={routesSettlement.address?.id}
                                                                    value={routesSettlement.address}
                                                                    onChange={(_event, value) => {
                                                                        props.onStageAddressChange(value, index);
                                                                    }}
                                                                    renderInput={(params) => {
                                                                        return (
                                                                            <TextField
                                                                                {...params}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    onChange: (event) =>  {
                                                                                        let e = event;
                                                                                        e.target.value = event.target.value.trimStart()
                                                                                        params.inputProps.onChange(e)
                                                                                    },
                                                                                    className: styles.input,
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                    ListboxProps={{ style: { fontSize: 12 } }}
                                                                />
                                                            </div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <DateTimePicker
                                                        autoOk={false}
                                                        format={"HH:mm"}
                                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                        ampm={false}
                                                        value={routesSettlement.date ?? null}
                                                        inputProps={{ className: styles.input }}
                                                        onChange={(value) => props.onStageTimeChange(new Date(value.getTime() - routesSettlement.addedTime), index)}
                                                        fullWidth
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <TextField
                                                        fullWidth
                                                        value={routesSettlement.stopDuration}
                                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                        onChange={(e) =>
                                                            onStageStopDurationChange(Number(e.target.value), index)
                                                        }
                                                        inputProps={{ className: styles.input }}
                                                    />
                                                    min
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <SettlementsPanelDistanceInput
                                                        value={routesSettlement.distance}
                                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                        onChange={(value) => props.onStageDistanceChange(value, index)}
                                                        inputProps={{ className: styles.input }}
                                                        fullWidth
                                                    />
                                                    km
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}></TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <IconButton
                                                    size="small"
                                                    style={{
                                                        background: "#bdbdbd",
                                                        width: 16,
                                                        height: 16,
                                                        marginTop: 5,
                                                    }}
                                                    disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                    onClick={() => onDeleteButtonClick(index)}
                                                >
                                                    <Delete
                                                        fontSize="small"
                                                        style={{ color: "white", fontSize: `0.75rem` }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <div style={{ marginLeft: 20 }}>
                                    {!driverData.routesSettlements.length && (
                                        <span
                                            style={{
                                                marginRight: 5,
                                                paddingTop: 1,
                                                display: "block",
                                                float: "left",
                                            }}
                                        >
                                            Dodaj
                                        </span>
                                    )}
                                    <IconButton
                                        size="small"
                                        style={{ background: "#bdbdbd", width: 12, height: 12 }}
                                        onClick={onAddButtonClick}
                                        disabled={props.isFieldsDisabled || isFieldsDisabled}
                                    >
                                        <Add fontSize="small" style={{ color: "white", fontSize: `0.75rem` }} />
                                    </IconButton>
                                </div>

                                <TableRow>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>{formattedRoutesSettlementTotalTime}</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {totalRoutesSettlementStopDuration} min
                                        </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {sumOfAllRoutesSettlementDistance} km
                                        </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <SettlementsPanelPriceInput
                                                fullWidth
                                                disabled={props.isFieldsDisabled || isFieldsDisabled}
                                                value={driverData.highwayCost}
                                                onChange={(value) => props.onHighwayCostChange(value)}
                                                inputProps={{ className: styles.input }}
                                            />{" "}
                                            zł
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettlementsPanelDriverSettlement;
