import styles from "./formCompanyInfo.module.scss";
import React from "react";
import {Popper, TextField} from "@material-ui/core";
import {styled, withStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import AddKontrahentForm from "./addKontrahent/AddKontrahentForm";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Cookie from "js-cookie";
import config from "../../../../../config";

const SingleInput = styled(TextField)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormCompanyInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.cardNo !== nextProps.cardNo ||
            this.props.isProposition !== nextProps.isProposition ||
            this.props.kontrahents.length !== nextProps.kontrahents.length ||
            this.props.kontrahent !== nextProps.kontrahent ||
            this.state !== nextState
    }

    handleToggleModal = () => {
        this.setState({showModal: !this.state.showModal});
    }

    render() {
        const filter = createFilterOptions();
        return (
            <div className={styles["formCompanyInfo"]}>
                <div className={styles["formCompanyInfo__input"]}>
                    <SingleInput
                        label="Nr karty"
                        fullWidth
                        error={
                            this.props.cardNo.empty && !this.props.isProposition
                        }
                        helperText={
                            (this.props.cardNo.empty && !this.props.isProposition) ? "Uzupełnij dane" : false
                        }
                        required={
                            !this.props.isProposition
                        }
                        value={this.props.cardNo.value}
                        variant="standard"
                        onChange={(e) => {
                            this.props.setOrderData(
                                this.props.groupIndex,
                                this.props.index,
                                "cardNo",
                                e.target.value,
                                this.props.handleValidate
                            );
                        }}
                    />
                </div>
                <div className={styles["formCompanyInfo__input"]}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const user = JSON.parse(Cookie.get("loggedUser"));
                            if(!config.disableAddingContractor.includes(user.companyName)) {
                                filtered.unshift({
                                    inputValue: params.inputValue,
                                    name: `+ DODAJ FIRMĘ`,
                                });
                            }
                            return filtered;
                        }}
                        options={this.props.kontrahents}
                        getOptionLabel={(kontrahent) => kontrahent.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={this.props.kontrahent.value}
                        onChange={(e, newValue) => {
                            if (
                                newValue?.name === "+ DODAJ FIRMĘ") {
                                this.handleToggleModal();
                            } else {
                                this.props.setOrderData(this.props.groupIndex, this.props.index, "kontrahent", newValue, this.props.handleValidate);
                            }
                        }}
                        renderInput={(params) => (
                            <SingleInput
                                {...params}
                                id="standard-error-helper-text"
                                label="Kontrahent"
                                variant="standard"
                                margin="normal"
                                error={
                                    this.props.kontrahent.empty && !this.props.isProposition
                                }
                                helperText={
                                    (this.props.kontrahent.empty && !this.props.isProposition) ? "Uzupełnij dane" : false
                                }
                                required={
                                    !this.props.isProposition
                                }
                                fullWidth
                            />
                        )}
                    />
                </div>
                {this.state.showModal && <AddKontrahentForm
                    groupIndex={this.props.groupIndex}
                    orderIndex={this.props.index}
                    handleToggleModal={this.handleToggleModal}
                    addKontrahent={this.props.addKontrahent}
                />}
            </div>
        );
    }
}
