import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import {format, fromUnixTime} from "date-fns";
import {pl} from "date-fns/locale";

const useStyles = makeStyles(() => ({
    wrapper: {
        padding: "10px 16px",
    },
    dateWrapper: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 0 10px",
    },
    date: {
        minWidth: "220px",
        margin: "5px 0",
    },
    info: {
        margin: 0,
        color: "#1dbbed",
        fontSize: "11px",
        fontWeight: "bold"
    }
    ,
    data: {
        margin: 0,
        fontSize: "13px",
        fontWeight: "bold",
        color: "#47484b"
    }
}));

export default function SingleCourseDetailsPopover(props) {
    const {anchor, course, dayOff, start, meta} = props;
    const classes = useStyles();
    const id = props.openPopup ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={props.openPopup}
            anchorEl={anchor}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.wrapper}>
                < div className={classes.dateWrapper}
                      style={dayOff ? {borderBottom: "none"} : {borderBottom: "1px solid #e6e6e6"}}>
                    < div
                        className={classes.date}>
                        <p className={classes.info}>
                            {dayOff ? "Początek urlopu: " : "Start kursu: "}
                        </p>
                        <p className={classes.data}>
                            {start ? start : "-"}
                        </p>
                    </div>
                    < div
                        className={classes.date}>
                        <p className={classes.info}>
                            {dayOff ? "Koniec urlopu: " : "Koniec kursu: "}
                        </p>
                        <p className={classes.data}>
                            {meta ? meta : "-"}
                        </p>
                    </div>
                </div>

                {!dayOff && course ? course.path.map((item, index2) => {
                        const partTime = format(fromUnixTime(item.time), "HH:mm", {locale: pl,})
                        return (
                            < div key={index2} className={classes.etap}>
                                <p className={classes.info}>
                                    {partTime}:
                                    <span className={classes.data}>
                                        {`${item.adres.street ? ` ul. ${item.adres.street} ` : ""} ${
                                            item.adres.no ? item.adres.no : ""
                                        } ${item.adres.flat ? `/${item.adres.flat}` : ""}   ${item.adres.zipCode ? item.adres.zipCode : ""} ${
                                            item.adres.city ? item.adres.city : "-"}`}
                                    </span>
                                </p>
                            </div>
                        )
                    })
                    : dayOff ? null : <p
                        className={classes.data}>
                        Brak etapów przejazdu</p>}
            </div>
        </Popover>


    )
}
