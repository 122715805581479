import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import styles from "./operatorOrders.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    createMuiTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
    endOfDay,
    endOfMonth,
    format,
    fromUnixTime,
    getUnixTime,
    isDate,
    startOfMonth,
    getDate,
    getMonth,
    getDaysInMonth,
    getYear,
    startOfDay, isSameDay
} from "date-fns";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MoreDetails from "./operatorMoreDetails/MoreDetails";
import ProgressLine from "../progressLine/ProgressLine";
import {useHistory} from 'react-router-dom';
import DateTabs from "../orders/Tabs";
import * as qs from "qs";
import {pl} from "date-fns/locale";
import HttpClient from "../../../HttpClient";
import {ThemeProvider} from "@material-ui/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {convertNumberToArray} from "../../../helper/convertNumberToArray";
import {DatePicker} from "@material-ui/pickers";
import styled from "@material-ui/core/styles/styled";
import ClearIcon from "@material-ui/icons/Clear";
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';

const lightBlueTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            color: "#b4b4b4",
            padding: "2px 15px 0 0",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            textTransform: "uppercase",
            fontWeight: "bold",
        },
        "& .MuiTableCell-body": {
            padding: "2px 15px 2px 0",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            borderBottom: "10px solid #FAFAFA"
        },
    },
    content: {
        maxWidth: "160px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: 'break-word',
    },

    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "left",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px"
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    changeViewButton: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    },
    timeRoot: {
        padding: "5px 0",
        margin: 0,
        "& .MuiTimelineItem-root": {
            minHeight: "40px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        padding: "2px",
        backgroundColor: "#B4B4B4",
    },
    timeConnector: {
        backgroundColor: "#B4B4B4",
    },
    timeDelayTime: {
        backgroundColor: "#ffdb24",
    },
    timeDelayPlace: {
        backgroundColor: "#ff8e24",
    },
    timeEverythingOk: {
        backgroundColor: "#66D01D",
    },
    timeEverythingWrong: {
        backgroundColor: "#ED1D1D",
    },
    icon: {
        color: "white",
    },
    noData: {
        color: "#B4B4B4",
    },
    delayTime: {
        color: "#ffdb24",
        fontWeight: "bold"
    },
    delayPlace: {
      color: "#ff8e24",
      fontWeight: "bold"
    },
    everythingOk: {
        color: "#66D01D",
        fontWeight: "bold"
    },
    everythingWrong: {
        color: "#ED1D1D",
        fontWeight: "bold"
    },
    invisible: {
        backgroundColor: "transparent",
    },
    iconButton: {
        backgroundColor: "#b4b4b4",
        "&:hover": {
            backgroundColor: "#777777",
        }
    }
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

const SearchDatePicker = styled(DatePicker)({
    width: 120,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const generateStartEndDate = (newDate) => ({
    start: getUnixTime(startOfMonth(newDate)),
    end: getUnixTime(endOfMonth(newDate))
});

export default function OperatorOrders(props) {
    const classes = useStyles();
    const url =
        props.status === "active"
            ? "api/zlecenia/active"
            : "api/zlecenia/archive";

    const history = useHistory();
    const now = new Date();
    const [page, setPage] = useState(0);
    const [columns, setColumns] = useState(props.columns);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showDetails, setShowDetails] = useState(false);
    const [index, setIndex] = useState(null);
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [searchValues, setSearchValues] = useState([{id: 'time', value: null}]);
    const [api, setApi] = useState([]);
    const [date, setDate] = useState(generateStartEndDate(now));
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [updateDriver, setUpdateDriver] = useState(false);
    const [driverId, setDriverId] = useState(null);

    const [year, setYear] = useState(getYear(now));
    const [month, setMonth] = useState(getMonth(now));
    const [day, setDay] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState(convertNumberToArray(getDaysInMonth(new Date(year, month))));

    //DateTabs
    const [startDate, setStartDate] = useState(fromUnixTime(date.start));
    const [endDate, setEndDate] = useState(fromUnixTime(date.end));

    const handleDateChange = (type, value) => {
        const rangeDate = {...date};
        if(type === 'start') {
            value = startOfDay(value);
            setStartDate(value);
        }
        if(type === 'end') {
            value = endOfDay(value);
            setEndDate(value);
        }
        rangeDate[type] = getUnixTime(value);
        setDate(rangeDate);
    }

    const handleOnEnterPress = (e) => {
        if (e.key === 'Enter') {
            getData();
        }
    }

    const handleSetStartEndDate = () => {
        let rangeDate = {...date};
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay))
            }
        } else {
            const thisMonth = new Date(Date.UTC(year, month));
            rangeDate = {
                start: getUnixTime(startOfMonth(thisMonth)),
                end: getUnixTime(endOfMonth(thisMonth))
            }
        }
        setDate(rangeDate);
    }

    const handleDaysInMonthChange = (newYear, newMonth) => {
        setDaysInMonth(convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth))));
    }

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        handleDateChange();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        handleDateChange();
    };

    const handleDayChange = (event, newValue) => {
        if (newValue === day) {
            setDay(null);
        } else {
            setDay(newValue);
        }
        handleDateChange();
    }

    const handleDatePickerDateChange = (newDate) => {
        const day = getDate(newDate);
        const month = getMonth(newDate);
        const year = getYear(newDate);
        setDay(day);
        setMonth(month);
        setYear(year);
        handleDateChange();
    }

    const getDatePickerValue = () => {
        return !isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day)) ? null : new Date(year, month, day);
    }

    useEffect(() => {
        handleDaysInMonthChange(year, month);
        handleSetStartEndDate();
    }, [year, month, day]);

    useEffect(() => {
        setPage(0);
        setStartDate(fromUnixTime(date.start));
        setEndDate(fromUnixTime(date.end));
    }, [date]);

    const source = axios.CancelToken.source();

    const updateData = (value) => {
        setUpdateDriver(value);
    }

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const _searchParams = searchValues.map((a) => ({...a}));
        const getParam = _searchParams.map(getParams).join("");

        const httpRequest = HttpClient.createRequest();
        try {

            const response = await httpRequest.get(`${url}?${getParam}orderBy=idDriver&order=ASC&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`, /*&filled=true*/
                {
                    cancelToken: source.token,
                    params: {
                        date
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                }, true);

            setApi(response.data.items);
            setCount(response.data.count);
            setIsLoading(false);

        } catch (e) {
        }
    };

    const {value} = searchValues.find(value => value.id === 'time');
    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        setUpdateDriver(false)

        return () => {
            source.cancel();
        };
    }, [invokeClearColumn, date, value, rowsPerPage, page, updateDriver]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        const newState = [...filterObject, {id: field, value: query}];
        setSearchValues(newState);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    };

    const chooseStatusColor = (status, isTimeline) => {
        if(isTimeline) {
            switch (status) {
                case 0:
                    return classes.noData;
                case 1:
                    return classes.timeEverythingOk;
                case 2:
                    return classes.timeDelayTime;
                case 3:
                    return classes.timeDelayPlace;
                case 4:
                    return classes.timeEverythingWrong
                default:
                    return classes.noData;
            }
        } else {
            switch (status) {
                case 0:
                    return classes.noData;
                case 1:
                    return classes.everythingOk;
                case 2:
                    return classes.delayTime;
                case 3:
                    return classes.delayPlace;
                case 4:
                    return classes.everythingWrong
                default:
                    return classes.noData;
            }
        }
    }


    //TODO - jak będzie gotowy tracker, odkomentować realTime - dane będą pobierane z Verizona
    function createData(
        details,
        taxiDriver,
        time,
        number,
        orderNo,
        parts,
        plannedTime,
        realTime,
        contractor,
        worker,
        notes
    ) {
        return {
            details,
            taxiDriver,
            time,
            number,
            orderNo,
            parts,
            plannedTime,
            realTime,
            contractor,
            worker,
            notes
        };
    }

    const switchShowModal = () => {
        setShowDetails(false);
    };

    const clearDate = () => {
        setYear(getYear(now));
        setMonth(getMonth(now));
        setDay(null);

    }

    const rows = api ? api.map((data, apiIndex) => {
        let pickupTime = data.pickupTime ? fromUnixTime(parseInt(data.pickupTime)) : null;
        let targetTime = data.targetTime ? fromUnixTime(parseInt(data.targetTime)) : null;
        let courseStartDay = pickupTime ? format(pickupTime, "d.LL.yyyy", {
            locale: pl,
        }) : targetTime ? format(targetTime, "d.LL.yyyy", {
            locale: pl,
        }) : "___";
        let endDay = data.path && data.path.length > 1 ? fromUnixTime(data.path[data.path.length - 1].time) : null;
        let courseEndDay = endDay ? format(endDay, "d.LL.yyyy", {
            locale: pl,
        }) : "___";

        const personData = data.worker.map((item, index) => {
            return (
                <span key={index}>
                        {item.firstname} {item.surname}
                    </span>
            );
        });

        return createData(
            <div className={styles["active__buttonContainer"]}>
                {data.driver ?
                    <BootstrapTooltip title="Szczegóły trasy">
                        <IconButton
                            size="small"
                            className={classes.iconButton}
                            onClick={() => {
                                setIndex(apiIndex);
                                setDriverId(data.driver.id)
                                setShowDetails(!showDetails);
                            }}
                        >
                            <MoreHorizIcon className={classes.icon}/>
                        </IconButton>
                    </BootstrapTooltip> : '___'
                }
            </div>,
            data.driver ? `${data.driver.firstname ? data.driver.firstname : ""} ${data.driver.surname ? data.driver.surname : ''}` : "___",
            <div className={styles["active__table--item"]}>
                <span>Start: {courseStartDay}</span>
                <span>Koniec: {courseEndDay}</span>
            </div>,
            data.number ? `${data.number}` : "___",
            data.orderNo ? data.orderNo : '___',
            <div className={styles["active__table--item"]}>
                <Timeline align="alternate" className={classes.timeRoot}>
                    {data.path ? data.path.map((item, pathIndex) => {
                        let lastIndex = data.path.length - 1;

                        const statusBackground = chooseStatusColor(item.confirmationStatus, true);
                        return (
                            <div key={pathIndex} className={styles["active__table--item--single"]}>
                                {pathIndex === lastIndex ?
                                    item.adres.id === data.driver?.addresses.id ?
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={classes.invisible}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div className={styles["active__table--item--single--home"]}></div>
                                        </> :
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div
                                                className={styles["active__table--item--single--middle"]}></div>
                                        </>
                                    : pathIndex === 0 ?
                                        item.adres.id === data.driver?.addresses.id ?
                                            <>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector
                                                            className={classes.invisible}/>
                                                        <TimelineDot
                                                            className={`${classes.timeDot} ${statusBackground}`}/>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    </TimelineSeparator>
                                                </TimelineItem>
                                                <div className={styles["active__table--item--single--home"]}></div>
                                            </> :
                                            <>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground}`}/>
                                                        <TimelineDot
                                                            className={`${classes.timeDot} ${statusBackground}`}/>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    </TimelineSeparator>
                                                </TimelineItem>
                                                <div
                                                    className={styles["active__table--item--single--middle"]}></div>
                                            </> :
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div
                                                className={styles["active__table--item--single--middle"]}></div>
                                        </>
                                }
                                <span className={styles["active__table--item--single--data"]}>
                                {`${item.adres.street ? item.adres.street : ""} ${item.adres.no ? item.adres.no : ""}${
                                    item.adres.flat ? `/${item.adres.flat}` : ""
                                } ${item.adres.zipCode ? item.adres.zipCode : ""} ${
                                    item.adres.city ? item.adres.city : ""
                                }`} </span>
                            </div>
                        )
                    }) : <span className={styles["active__table--item--single"]}> Brak kursu</span>}
                </Timeline>
            </div>,
            <div className={styles["active__table--item"]}>
                {data.path ? data.path.map((item, index) => {
                    return (
                        <span
                            key={index}
                            className={styles["active__table--item--single"]}>{format(fromUnixTime(item.time), "HH:mm", {
                            locale: pl,
                        })}</span>
                    )
                }) : <span className={styles["active__table--item--single"]}> ___</span>}
            </div>,
            <div className={styles["active__table--item"]}>
                {data.path ? data.path.map((item, index) => {
                    const statusColor = chooseStatusColor(item.confirmationStatus, false);

                    return (
                        <span
                            key={index}
                            className={`${styles["active__table--item--single"]} ${statusColor}`}>{item.arrivedAt ? (format(fromUnixTime(item.arrivedAt), "HH:mm", {
                            locale: pl,
                        })) : ""}</span>

                    )
                }) : <span className={styles["active__table--item--single"]}> ___</span>}
            </div>,
            data.kontrahent ? data.kontrahent.name : "-",
            data.worker ?
                <div className={styles["active__table--item"]}>{personData}</div>
                : "___",
            data.notes ? <div className={classes.content}>{data.notes}</div> : "-",
        );
    }) : null;

    return (
        <div className={styles["active"]}>
            <Paper className={classes.root}>
                {props.status === 'archive' && <DateTabs
                    handleDayChange={handleDayChange}
                    handleMonthChange={handleMonthChange}
                    handleYearChange={handleYearChange}
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    day={day}
                    month={month}
                    year={year}
                    daysInMonth={daysInMonth}
                />}
                <div className={styles["active__description"]}>
                    <h1 className={styles["active__description--title"]}>
                        {props.status === "active" ? "AKTYWNE KURSY" : "ZAKOŃCZONE KURSY"}
                    </h1>
                    <div
                        className={styles["active__description--button"]}>
                        <Button
                            className={classes.changeViewButton}
                            variant="outlined"
                            onClick={() => history.push(`/app/operator/trasy/${props.status === 'archive' ? 'archiwum' : 'aktywne'}`)}>Przełącz
                            widok</Button>
                    </div>
                    <div className={styles["active__description--legend"]}>
                        <div className={styles["active__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.everythingOk}/>
                            <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                Zgodnie z planem
                            </p>
                        </div>
                        <div className={styles["active__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.delayTime}/>
                            <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                Czas jest błędny
                            </p>
                        </div>
                        <div className={styles["active__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.delayPlace}/>
                            <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                Miejsce jest błędne
                            </p>
                        </div>
                        <div className={styles["active__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.everythingWrong}/>
                            <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                Wszystkie dane są błędne
                            </p>
                        </div>
                        <div className={styles["active__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.noData}/>
                            <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                Brak danych z GPS
                            </p>
                        </div>
                    </div>
                </div>

                {isLoading ? <ProgressLine/> :
                    <TableContainer>
                        <SimpleBar style={{
                            minHeight: props.status === "active" ? "60vh" : "55vh",
                            maxHeight: props.status === "active" ? "60vh" : "55vh"
                        }}
                                   className={classes.simplebar}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <Fragment key={column.id}>
                                                <TableCell
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                    }}
                                                >
                                                    {column.query ? (
                                                        <ThemeProvider theme={lightBlueTheme}>
                                                            <TableSortLabel
                                                                className={classes.tableSortLabel}
                                                                hideSortIcon={true}
                                                            >
                                                                {column.id === 'time' ? (
                                                                    <Input
                                                                        className={classes.headCell}
                                                                        inputComponent={SearchDatePicker}
                                                                        inputProps={{
                                                                            style: {height: 'unset'},
                                                                            onChange: (newValue) => handleDatePickerDateChange(newValue),
                                                                            value: getDatePickerValue(),
                                                                            format: "d MMMM",
                                                                            disablePast: props.status === 'active',
                                                                            disableToolbar: props.status === 'archive',
                                                                            InputProps: {
                                                                                disableUnderline: true,
                                                                                placeholder: column.label,
                                                                            },
                                                                        }}
                                                                        endAdornment={
                                                                            <>
                                                                                <Tooltip title="Wyczyść">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => clearDate()}
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ClearIcon
                                                                                            className={classes.headCellArrow}
                                                                                            fontSize="small"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Input
                                                                        variant="outlined"
                                                                        className={classes.headCell}
                                                                        value={searchValues.find((v) => v.id === column.id)?.value ?? ''}
                                                                        onChange={(e) => handleSearch(column.id, e.target.value)}
                                                                        onKeyDown={handleOnEnterPress}
                                                                        endAdornment={
                                                                            <Tooltip title="Wyczyść">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => handleClearColumn(column.id)}
                                                                                    aria-label="visible columns"
                                                                                >
                                                                                    <ClearIcon
                                                                                        className={classes.headCellArrow}
                                                                                        fontSize="small"/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                        placeholder={column.label}
                                                                    />
                                                                )}
                                                            </TableSortLabel>
                                                        </ThemeProvider>
                                                    ) : column.label}
                                                </TableCell>
                                            </Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {api && api.length > 0 ? (
                                    <TableBody>
                                        {rows &&
                                            rows.map((row, index) => {
                                                return (
                                                    <TableRow hover role="checkbox" key={index}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                           className={classes.tableCell}>
                                                                    {column.format && typeof value === "number"
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                ) : <p className={styles["active__noData"]}>Brak danych</p>
                                }
                            </Table>
                        </SimpleBar>
                        <TablePagination
                            className={classes.pagination}
                            labelRowsPerPage={"Liczba wierszy"}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                }
            </Paper>
            {showDetails ? (
                <MoreDetails
                    switchShowModal={switchShowModal}
                    driverId={driverId}
                    courses={api}
                    updateData={updateData}
                    entity={props.entity}
                    listStatus={props.status}
                    pickedDate={date}
                />
            ) : null}
        </div>
    );
}