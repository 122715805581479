import React from "react";
import TableList from "../../../reusableComponents/tableList/TableList";

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Imię",
        checked: true,
        sorted: true,
        order: "ASC",
    },
    {
        id: "surName",
        numeric: false,
        disablePadding: false,
        label: "Nazwisko",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "contractor",
        numeric: false,
        disablePadding: false,
        label: "Kontrahent",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "workPhoneNumber",
        numeric: false,
        disablePadding: false,
        label: "Telefon służbowy",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "city",
        numeric: false,
        disablePadding: false,
        label: "Miasto",
        checked: true,
        sorted: false,
        order: null,
    },
];

const ShowWorkers = () => {
    return <TableList headCells={headCells} url="/api/pracownik" label="Lista pracowników" mode="worker" />;
};

export default ShowWorkers;
