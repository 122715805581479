import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";

const SettlementsPanelDistanceInput = (props) => {
    const [value, setValue] = useState(String(props.value));

    const onChange = (value) => {
        if (value === "") {
            setValue("");
            props.onChange(0);
            return;
        }

        const priceValidationPattern = /^\d{0,8}$/;

        if (priceValidationPattern.test(value)) {
            setValue(value);
            props.onChange(Number(value));
        }
    };

    useEffect(() => {
        if (props.value === Number(value)) {
            return;
        }
        setValue(String(props.value));
    }, [props.value]);

    return <TextField {...props} value={value} onChange={(e) => onChange(e.target.value)} />;
};

export default SettlementsPanelDistanceInput;
