import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, styled} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import RailyLogo from "../../../img/Raily_siwe_poziom.png";
import Step from "./Step";
import HttpClient from "../../../HttpClient";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://www.sptech.pl/">
                SP Tech Solutions
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({

    paper: {
        outline: "0 !important",
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    field: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    formTitle: {
        textTransform: "uppercase",
        fontWeight: "600",
        marginBottom: "3px",
    },
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        color: "e5e5e5",
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(4, 0, 2),
        backgroundColor: "#1DBBED",
        marginRight: "10px",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },

    },
}));

const initialValues = {
    step: 1,
    username: '',
    token: '',
    newPassword: ''
}

export default function ResetPassword() {

    const ChangePasswordSchema = Yup.object().shape({
      step: Yup.number(),
      username: Yup.string().when("step", {
        is: 1,
        then: Yup.string().required("Uzupełnij to pole"),
        otherwise: Yup.string(),
      }),
      token: Yup.string().when("step", {
        is: 2,
        then: Yup.string().required("Uzupełnij to pole"),
        otherwise: Yup.string(),
      }),
      newPassword: Yup.string().when("step", {
        is: 2,
        then: Yup.string().required("Uzupełnij to pole"),
        otherwise: Yup.string(),
      }),
      newPasswordRepeat: Yup.string().when("step", {
        is: 2,
        then: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Hasła muszą być takie same")
          .required("Uzupełnij to pole"),
        otherwise: Yup.string(),
      }),
    });

    const classes = useStyles();
    const history = useHistory();

    const sendData = (values, setFieldValue) => {
        if(values.step === 1) sendResetPassword(values, setFieldValue);
        if(values.step === 2) changePassword(values);
    }

    const sendResetPassword = async (values, setFieldValue) => {
        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.post('reset-password', values, {});
            setFieldValue('step', 2);
        } catch (e) {}
    }

    const changePassword = async (values) => {
        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.post('password-change', values, {});
            history.replace('/');
        } catch (e) {}
    }

    return (
      <div>
        <Container maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img width="100%" src={RailyLogo} alt="logo"></img>
              </div>
            </div>
            <Formik
              enableReinitialize
              validationSchema={ChangePasswordSchema}
              va
              initialValues={{ ...initialValues }}
              validateOnChange={false}
              onSubmit={(values, { setFieldValue }) => {
                sendData(values, setFieldValue);
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form className={classes.form} noValidate>
                  <p className={classes.formTitle}>Odzyskiwanie hasła</p>
                  <Step step={1} renderStep={values.step}>
                    <Field
                      className={classes.field}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      helperText={
                        !!errors.username &&
                        errors.username !== " " &&
                        touched.username
                          ? errors.username
                          : ""
                      }
                      error={!!errors.username && touched.username}
                      label="Nazwa użytkownika"
                      name="username"
                      as={TextField}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Wyślij kod weryfikacyjny
                    </Button>
                  </Step>
                  <Step step={2} renderStep={values.step}>
                    <Field
                      className={classes.field}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      helperText={
                        !!errors.token && errors.token !== " " && touched.token
                          ? errors.token
                          : ""
                      }
                      error={!!errors.token && touched.token}
                      label="Kod SMS"
                      name="token"
                      as={TextField}
                    />
                    <Field
                      className={classes.field}
                      variant="outlined"
                      margin="normal"
                      required
                      type="password"
                      fullWidth
                      helperText={
                        !!errors.newPassword &&
                        errors.newPassword !== " " &&
                        touched.newPassword
                          ? errors.newPassword
                          : ""
                      }
                      error={!!errors.newPassword && touched.newPassword}
                      label="Nowe hasło"
                      name="newPassword"
                      as={TextField}
                    />
                    <Field
                      className={classes.field}
                      variant="outlined"
                      margin="normal"
                      required
                      type="password"
                      fullWidth
                      helperText={
                        !!errors.newPasswordRepeat &&
                        errors.newPasswordRepeat !== " " &&
                        touched.newPasswordRepeat
                          ? errors.newPasswordRepeat
                          : ""
                      }
                      error={
                        !!errors.newPasswordRepeat && touched.newPasswordRepeat
                      }
                      label="Powtórz nowe hasło"
                      name="newPasswordRepeat"
                      as={TextField}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Zmień hasło
                    </Button>
                  </Step>
                  <Grid container>
                    <Grid item xs>
                      <RouterLink to="/">Wróć do strony logowania</RouterLink>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
}
