import {useState, useEffect} from 'react'
import Cookie from "js-cookie";
export function usePersistedState(key, defaultValue) {
    const [state, setState] = useState(
        () => JSON.parse(Cookie.get(key) ?? null) || defaultValue
    );
    useEffect(() => {
        Cookie.set(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
