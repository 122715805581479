import React from "react";
import EditPersonForm from "./EditPersonForm";
import axios from "axios";
import { NotificationContext } from "../../../context";
import HttpClient from "../../../HttpClient";

export default class EditPerson extends React.Component {
    source = axios.CancelToken.source();
    static contextType = NotificationContext;
    buildEmptyAddress = (status) => ({
        id: null,
        status: status,
        active: true,
        zipCode: "",
        city: "",
        street: "",
        no: "",
        flat: "",
        type: "HOME",
        lat: null,
        lon: null,
    });

    buildEmptyProfile = () => ({
        name: "",
        surName: "",
        address: [this.buildEmptyAddress("initial")],
        privatePhoneNumber: "",
        regNo: "",
        delegationRate: 0,
        engineCapacity: 0,
        isTaxiDriver: false,
        position: "",
        workEmail: "",
        workPhoneNumber: "",
        email: "",
        rate: 0,
        notes: ''
    });
    state = {
        profile: this.buildEmptyProfile(),
        isLoading: false,
    };

    getUserData = async () => {
        const id = this.props.match.params.id ? this.props.match.params.id : "";
        this.setState({ isLoading: true });
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`${this.props.url}/${id}`, { cancelToken: this.source.token }, true);
            const data = response.data;
            const profile = { ...this.state.profile };

            profile.regNo = "";
            if (data.worker.user.regNo) {
                profile.regNo = data.worker.user.regNo;
            }
            if (data.worker.regNo) {
                profile.regNo = data.worker.regNo;
            }
            profile.delegationRate = data.worker.delegationRate || 0;
            profile.engineCapacity = data.worker.engineCapacity || 0;
            profile.position = data.worker.position || "";

            profile.isTaxiDriver = data.worker.user.isTaxiDriver || false;

            profile.name = data.worker.user.name === null ? "" : data.worker.user.name;
            profile.surName = data.worker.user.surName === null ? "" : data.worker.user.surName;
            profile.email = data.worker.user.email === null ? "" : data.worker.user.email;
            profile.workEmail = data.worker.user.workEmail === null ? "" : data.worker.user.workEmail;

            profile.privatePhoneNumber =
                data.worker.user.privatePhoneNumber === null ? "" : data.worker.user.privatePhoneNumber;
            profile.workPhoneNumber = data.worker.user.workPhoneNumber === null ? "" : data.worker.user.workPhoneNumber;

            profile.isHired = data.worker.user.isHired ?? false;
            profile.normalRate = data.worker.user.normalRate ?? undefined;
            profile.rate = data.worker.user.rate ?? undefined;
            profile.waitingRate = data.worker.user.waitingRate ?? undefined;
            profile.region = data.worker.user.region?.id ?? undefined;
            profile.billingGroup = data.worker.user.billingGroup?.id ?? undefined;
            profile.billingModel = data.worker.user.billingModel?.id ?? undefined;
            profile.notes = data.worker.user.notes

            data.locked = data.locked.map((address) => ({
                ...address,
                active: false,
                status: "locked",
                locked: true,
            }));
            data.active = data.active.map((address) => ({
                ...address,
                active: true,
                status: "existed",
            }));

            const address = data.locked.concat(data.active);

            if (address && address.length > 0) {
                profile.address = address;
            }

            this.setState({ profile, engineCapacity: data.worker.engineCapacity });
        } catch (e) {
        } finally {
            this.setState({ isLoading: false });
        }
    };

    sendUserData = async (postData, setFieldError, handleClose) => {
        const { delegationRate, engineCapacity, position, regNo } = postData;
        const _postData = {
            ...postData,
            delegationRate: delegationRate || null,
            engineCapacity: engineCapacity || null,
            position: position || null,
            regNo: regNo || null,
            adres: postData.address,
        };

        delete _postData.address;
        const id = this.props.match.params.id;

        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.put(
                `${this.props.url}/${id}`,
                _postData,
                {
                    cancelToken: this.source.token,
                },
                true
            );
            this.props.history.push(this.props.redirectURL);
        } catch (e) {
            if (!axios.isCancel(e)) {
                if (e.response) {
                    if (e.response.data.type === "validationError") {
                        const err = e.response.data.errors;
                        for (let key in err) {
                            setFieldError(key, err[key][0]);
                        }
                    }
                }
            }
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserData();
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    render() {
        return (
            <>
                <EditPersonForm
                    defaultEngineCapacity={this.state.engineCapacity}
                    profile={this.state.profile}
                    buildEmptyAddress={this.buildEmptyAddress}
                    sendUserData={this.sendUserData}
                    label={this.props.label}
                    isLoading={this.state.isLoading}
                    entity={this.props.entity}
                />
            </>
        );
    }
}
