import { format } from "date-fns";

const isTimesDifferent = (location) =>
    location.startEstimatedDate &&
    format(location.startEstimatedDate, "HHmm") !== format(location.startOrderedDate, "HHmm")
        ? true
        : false;

const className = (isFirst, isLast) =>
    `${isFirst ? "summary-location-circle-bottom" : "summary-location-circle-top"} ${
        isLast ? "summary-location-circle-top" : "summary-location-circle-bottom"
    }`;

export { className, isTimesDifferent };