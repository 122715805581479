import React, {useState} from "react";
import styles from "./paidCourse.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import FormAlert from "../../../alerts/FormAlert";
import HttpClient from "../../../../../HttpClient";

const useStyles = makeStyles(() => ({
    root: {
        width: 200,
        margin: "7px 10px 7px 0",
        padding: "1px",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "7px",
        width: "160px",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    },
}));

export default function PaidCourse({orderId, highwayCost}) {
    const classes = useStyles();
    const [value, setValue] = useState(highwayCost || '');
    const [emptyInput, setEmptyInput] = useState(false);

    const sendData = async () => {
        if (value.trim()) {
            const httpRequest = HttpClient.createRequest();
            try {
                await httpRequest.put(`/api/order-highwayCost/${orderId}`, {highwayCost: value}, {}, true)
            } catch (e) {}

        } else {
            setEmptyInput(true);
            setTimeout(alertReset, 3000);
        }
    };

    const alertReset = () => {
        setEmptyInput(false);
    };

    const handleOnChange = (e) => {
      let parsedValue = e.target.value;

      if (parsedValue.startsWith("00")) parsedValue = "0";

      if (parsedValue.includes(",")) {
        parsedValue = parsedValue.replace(",", ".");
      }

      if (parsedValue.includes(".")) {
        const splittedInput = parsedValue.split(".");
        const [integerPart, floatPart] = splittedInput;

        let parsedFloatPart = "";

        if (floatPart) {
          const digitsOfFloatPart = floatPart.split("");
          parsedFloatPart = digitsOfFloatPart.slice(0, 2).join("");
          if (parsedFloatPart.length === 2 && parsedFloatPart.endsWith("0")) {
            parsedFloatPart = parsedFloatPart[0];
          }
        }
        parsedValue = `${integerPart}.${parsedFloatPart}`;
      }

      const inputRegex = /^\d+\.?\d{0,2}$/;
      const inputIsValid =
        !parsedValue.includes("-") &&
        inputRegex.test(parsedValue) &&
        parsedValue >= 0;
      if (inputIsValid || parsedValue === "") setValue(parsedValue);
    };

    return (
        <div className={styles["paidCourse"]}>
            <div className={styles["paidCourse__item"]}>
                <TextField
                    label="Wprowadź kwotę (w zł)"
                    className={classes.root}
                    type="number"
                    value={value}
                    variant="standard"
                    onChange={handleOnChange}
                    type="text"
                />
            </div>
            <div className={styles["paidCourse__item"]}>
                <Button
                    className={classes.confirmButton}
                    variant="outlined"
                    disabled={parseFloat(value) === parseFloat(highwayCost)}
                    onClick={sendData}>
                    Potwierdź kwotę
                </Button>
            </div>
            {emptyInput ? (
                <FormAlert
                    showAlert={true}
                    severity="error"
                    message={"Wprowadź kwotę"}
                />
            ) : null}

        </div>
    )
}
