import React from "react";
import styles from "./contractorCard.module.scss";
import SimpleBar from "simplebar-react";
import { makeStyles } from "@material-ui/core/styles";
import ContractorCardForm from "./contractorCardForm/ContractorCardForm";
import { getUnixTime } from "date-fns";
import HttpClient from "../../../HttpClient";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
});

export default function ContractorCard() {
    const classes = useStyles();
    const source = axios.CancelToken.source();
    const { id } = useParams();

    const handleSendData = async (values) => {
        const data = {
            companyName: values.name,
            address: {
                street: values.address,
                city: values.city,
                postCode: values.postCode,
                no: values.no,
                flat: values.flat,
            },
            NIP: values.nip,
            REGON: values.regon,
            contactPerson: values.contactPerson,
            phoneNumber: values.contactPersonPhoneNumber,
            dispatches: [
                {
                    dispatch: values.dispatch,
                    dispatchPhoneNumber: values.dispatchPhoneNumber,
                },
            ],
            startTime: getUnixTime(values.startTime),
            endTime: getUnixTime(values.endTime),
            normalRate: parseFloat(values.rate),
            isDiscount: !values.discount,
            maxDiscount: parseFloat(values.discountLimit),
            waitingRate: parseFloat(values.stop),
            countedFrom: parseFloat(values.countedFrom),
            isHighwayPay: !values.isHighwayPay,
            highwayWithWorker: !values.highwayWithWorker,
            highwayWithoutWorker: !values.highwayWithoutWorker,
            billingModel: values.billingModel,
            contractualPenalties: parseFloat(values.penalty),
            other: parseFloat(values.others),
            notes: values.notes,
        };

        const httpRequest = HttpClient.createRequest();
        const url = `api/card/${values.id}`;

        try {
            await httpRequest.put(url, data, { cancelToken: source.token }, true);
        } catch (e) {}
    };

    return (
        <SimpleBar style={{ maxHeight: "75vh", minWidth: 700 }} className={classes.simplebar}>
            <div className={styles["contractorCard"]}>
                <h1 className={styles["contractorCard__title"]}>Karta kontrahenta</h1>
                <ContractorCardForm contractorId={id} handleSendData={handleSendData} />
            </div>
        </SimpleBar>
    );
}
