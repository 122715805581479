import React, {useRef} from "react";
import EditAddressForm from "./EditAddressForm";
import axios from "axios";
import { NotificationContext } from "../../../../context";
import HttpClient from "../../../../HttpClient";
import InfoDialog from "../../../reusableComponents/alerts/infoDialog/InfoDialog";

export default class EditAddress extends React.Component {
    static contextType = NotificationContext;
    source = axios.CancelToken.source();

    emptyAddress = {
        zipCode: "",
        city: "",
        street: "",
        no: "",
        flat: "",
        type: "",
        lat: null,
        lon: null,
    };

    state = {
        editID: this.props.match.params.id,
        address: null,
        openDialog: false,
        putData: null,
    };

    getEditAddress = async (id) => {
        const httpRequest = HttpClient.createRequest()
        try {
            const body = { id }
            const { data } = await httpRequest.get(
                "/api/addresses/" + id,
                {
                    cancelToken: this.source.token,
                    params: body,
                },
                true
            );
            if (data.status === 200 && data.data.id) {
                const address = {
                    city: data.data.city ?? '',
                    zipCode: data.data.zipCode ?? '',
                    street: data.data.street ?? '',
                    no: data.data.no ?? '',
                    flat: data.data.flat ?? '',
                    type: data.data.type ?? '',
                    lat: data.data.lat ?? '',
                    lon: data.data.lon ?? '',
                }
                this.setState({...this.state, address: address})
            } else if (data.status === 200 && data.data) {
                return false
            }
        } catch (e) { }
    }

    checkIfAddressExists = async (city, zipCode, street, flat, no) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const body = { city, zipCode, street, flat, no }
            const { data } = await httpRequest.get(
                "api/addresses/is-exists",
                {
                    cancelToken: this.source.token,
                    params: body,
                },
                true
            );
            if (data.status === 200 && !data.data) {
                return false;
            } else if (data.status === 200 && data.data) {
                this.setState({ openDialog: true });
                return true
            }
        } catch (e) { }
    }


    sendAddressData = async (putData, accept = false) => {
        // debugger
        this.setState({ ...this.state, putData: putData });
        if (!accept) {
            const isExists = await this.checkIfAddressExists(putData.city, putData.zipCode,
                putData.street, putData.flat, putData.no);
            if (isExists) return;
        }
        putData.status = 'enabled'
        delete putData.alone;
        delete putData.address;

        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.put("api/addresses/" + this.state.editID, putData, { cancelToken: this.source.token }, true);
            setTimeout(() => window.location.reload(), 1500);
        } catch (e) {}
    };

    componentDidMount() {
        // const ref = useRef(null);
        // console.log(ref)
        window.scrollTo(0, 0);
        this.getEditAddress(this.state.editID)
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    handleCloseModal = () => {
        this.setState({ openDialog: false });
    };

    handleAccept = () => {
        this.setState({ openModal: false }, () => {
            this.sendAddressData(this.state.putData, true);
        });
    };


    render() {
        return (
            <>
                {this.state.address &&
                    <EditAddressForm
                    address = {this.state.address}
                    sendAddressData = {this.sendAddressData}
                    />
                }
                {this.state.openDialog && (
                    <InfoDialog
                        confirmAction={this.handleAccept}
                        alertReset={this.handleCloseModal}
                        message="Podany adres już istnieje, chcesz dodać taki sam adres?"
                    />
                )}
            </>
        );
    }
}
