import React from "react";
import styles from "./workInProgress.module.scss";

export default function WorkInProgress() {
  return (
    <div className={styles["workInProgress"]}>
      <h1 className={styles["workInProgress__title"]}>Moduł w budowie</h1>
      <div className={styles["workInProgress__icon"]}></div>
    </div>
  );
}
