export const transformSortFieldsForDriver = (column, type) => {
    if (type === 3) {
        const newColumn = {...column}
        const transformColumn = transformFieldForDriver(newColumn);

        transformColumn("time", "accountDate")
        transformColumn("number", "orderNo")
        transformColumn("driverFullName", "driverSurname")
        transformColumn("driverSumKm", "sumKm")
        transformColumn("driverNormalRate", "normalRate")
        transformColumn("driverWaitingRate", "waitingRate")
        transformColumn("driverWaitingCost", "waitingCost")
        transformColumn("driverSumCost", "sumCost")
        transformColumn("driverHighwayCost", "highwayCost")
        transformColumn("driverBillingGroupName", "billingGroupName")
        return newColumn;
    }
    return column
}

const transformFieldForDriver = (column) => (name, newName) => {
    if (column.id === name) {
        column.id = newName
    }
}

export const transformSearchParamsForDriver = (column, type) => {
    if (type === 3) {
        const newColumn = {...column}
        const transformColumn = transformFieldForDriver(newColumn);

        // transformColumn("number", "order_no")
        transformColumn("driverFullName", "driver")
        transformColumn("driverSumKm", "sum_km")
        transformColumn("driverNormalRate", "normal_rate")
        transformColumn("driverWaitingRate", "waiting_rate")
        transformColumn("driverWaitingCost", "waiting_cost")
        transformColumn("driverSumCost", "sum_cost")
        transformColumn("driverHighwayCost", "highway_cost")
        transformColumn("driverBillingGroupName", "billing_group_name")
        return newColumn;
    }
    return column
}