import React from "react";
import AddDriverForm from "./AddDriverForm";
import axios from "axios";
import { NotificationContext } from "../../../../context";
import HttpClient from "../../../../HttpClient";
import InfoDialog from "../../../reusableComponents/alerts/infoDialog/InfoDialog";

export default class AddDriver extends React.Component {
    static contextType = NotificationContext;
    source = axios.CancelToken.source();

    buildEmptyAddress = () => ({
        zipCode: "",
        city: "",
        street: "",
        no: "",
        flat: "",
        type: "HOME",
        lat: null,
        lon: null,
    });

    buildEmptyWorker = () => ({
        name: "",
        surName: "",
        address: [this.buildEmptyAddress()],
        isTaxiDriver: false,
        workPhoneNumber: "",
        workEmail: "",
        regNo: "",
        rate: 0,
        normalRate: "",
        waitingRate: "",
        isHired: false,
        region: "",
        billingGroup: "",
        billingModel: "",
    });
    state = {
        worker: this.buildEmptyWorker(),
        openDialog: false,
        postDate: null,
    };

    checkIfWorkerExists = async (name, surName, contractorId) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const body = { name, surName, contractorId };
            const { data } = await httpRequest.get(
                "/api/driver/check_if_exists",
                {
                    cancelToken: this.source.token,
                    params: { ...body },
                },
                true
            );
            if (data.isExists) {
                this.setState({ openDialog: true });
                return data.isExists;
            }
        } catch (e) {}
    };

    sendWorkerData = async (postData, accept = false) => {
        this.setState({ postData });
        if (!accept) {
            const isExists = await this.checkIfWorkerExists(postData.name, postData.surName, postData.kontrahent?.id);
            if (isExists) return;
        }
        delete postData.alone;

        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.post("api/kierowca", postData, { cancelToken: this.source.token }, true);
            setTimeout(() => window.location.reload(), 1500);
        } catch (e) {}
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    handleCloseModal = () => {
        this.setState({ openDialog: false });
    };

    handleAccept = () => {
        this.setState({ openModal: false }, () => {
            this.sendWorkerData(this.state.postData, true);
        });
    };

    render() {
        return (
            <>
                <AddDriverForm
                    worker={this.state.worker}
                    buildEmptyAddress={this.buildEmptyAddress}
                    sendWorkerData={this.sendWorkerData}
                />
                {this.state.openDialog && (
                    <InfoDialog
                        confirmAction={this.handleAccept}
                        alertReset={this.handleCloseModal}
                        message="Użytkownik o podanym imieniu i nazwisku został znaleziony w bazie, czy jesteś pewny, że chcesz stworzyć nowego użytkownika?"
                    />
                )}
            </>
        );
    }
}
