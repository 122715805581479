import React, {memo, useState} from "react";
import styles from "./orderHome.module.scss";
import {IconButton, TextField} from "@material-ui/core";
import {StyledAutocomplete} from "../OrderLine";
import {styled} from "@material-ui/core/styles";
import {DateTimePicker} from "@material-ui/pickers";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import FormAlert from "../../../alerts/FormAlert";
import AddAddressInOrder from "../../../addAddressInOrder/AddAddressInOrder";
import produce from "immer";
import {isEqual, isDate} from 'date-fns';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Undo as UndoIcon} from "@material-ui/icons";
import {DELETED, INITIAL, NEW} from "../../OrderPanel";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import clearSeconds from "../../../../../helper/clearSeconds";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const HomeInput = styled(TextField)({
    width: 220,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const HomeDate = styled(DateTimePicker)({
    width: 220,
    fontFamily: "'Open Sans', sans-serif",
});

const HomeToMemo = (props) => {

        const [open, setOpen] = useState(false);
        let shouldOpen = true;

        const showDefault = (isAddress) => {
            if(props.type === 'edit') {
                if(isAddress) {
                    return (props.data.address.value?.id !== props.data.address.default?.id || props.data.status === DELETED) && props.data.status !== NEW;
                }
                return isDate(props.order.timeHome.default) && (!isEqual(clearSeconds(props.order.timeHome.value, true), clearSeconds(props.order.timeHome.default, true))) && props.data.status !== NEW;
            }
            return false;
        }
        let statusClass = '';
        if(props.data.status !== INITIAL && props.type === 'edit') {
            statusClass = props.data.status === DELETED ? '__deleted' : '__new';
        } else {
            statusClass = showDefault(true) || showDefault(false) ? '__touched' : statusClass;
        }

        const disableSameAddress = (option, destination) => {
            return !(option.street === "DODAJ" && option.city === "ADRES") && (option?.id === destination?.id || (option?.lat === destination?.lat && option?.lon === destination?.lon));
        }

    

        return (
            <div className={styles["orderHome__singleLine" + statusClass]}>
                <div className={styles["orderHome__singleLine--input"]}>
                    <StyledAutocomplete
                        disableClearable={props.data.status === DELETED}
                        filterOptions={(options, params) => {
                            const filtered = props.filter(options, params);
                            filtered.unshift({
                                inputValue: params.inputValue,
                                street: `DODAJ`,
                                city: "ADRES"
                            });
                            return filtered;
                        }}
                        options={props.changeOptions(props.data)}
                        getOptionLabel={(address) => `${address.street} ${address.city}`}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionDisabled={option => props.data.status === DELETED || disableSameAddress(option, props.order.destination.value)}
                        value={props.data.address.value}
                        onChange={(e, newValue) => {
                            if (
                                newValue?.street === "DODAJ" && newValue?.city === "ADRES"
                            ) {
                                if (e) if (e.preventDefault) e.preventDefault();
                                props.handleErrorMessage(props.index, props.data);
                            } else {
                                props.updateAddress(props.index, newValue, props.handleValidate);
                            }
                        }}
                        ListboxProps={{
                            "data-id": `workerAddressSelectList-groupIndex_${props.groupIndex}`,
                        }}
                        renderInput={(params) => (
                            <HomeInput
                                {...params}
                                error={
                                    props.data.address.empty && !props.order.isProposition
                                }
                                helperText={
                                    (props.data.address.empty && !props.order.isProposition) ? "Uzupełnij dane" : false
                                }
                                required={
                                    !props.order.isProposition
                                }
                                InputProps={{
                                    ...params.InputProps,
                                    'data-id': `workerAddressSelect-groupIndex_${props.groupIndex}`,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {params.InputProps.endAdornment.props.children.map(item => item)}
                                            {showDefault(true) && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => props.undoChanges('address', props.groupIndex, props.orderIndex, props.index, () => {
                                                        props.undoChanges('isWorkAddress', props.groupIndex, props.orderIndex, props.index)
                                                    })}
                                                >
                                                    <UndoIcon
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                                label={props.label}
                                variant="standard"
                                margin="normal"
                                fullWidth
                            />
                        )}
                    />
                    <p
                        className={styles["orderHome__singleLine--link"]}
                        onClick={props.switchAddressType}
                    >
                        {props.legend}
                    </p>
                </div>
                <div className={styles["orderHome__singleLine--input"]}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <HomeDate
                            autoOk={false}
                            onOpen={() => {
                                shouldOpen ? setOpen(true) : shouldOpen = true;
                            }}
                            onClose={() => setOpen(false)}
                            format={"d.LLLL yyyy, HH:mm"}
                            ampm={false}
                            open={open}
                            // disabled={props.data.status === DELETED}
                            disabled={props.label === "Dom" || props.data.status === DELETED}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {props.order.timeHome.enabled && showDefault(false) && (
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    shouldOpen = false;
                                                    props.undoChanges('timeHome', props.groupIndex, props.orderIndex, props.index);
                                                }}
                                            >
                                                <UndoIcon
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                                'data-id': `workerDateSelect-groupIndex_${props.groupIndex}`,
                            }}
                            value={props.order.timeHome.value}
                            onChange={(newValue) => {
                                props.setOrderData(
                                    props.groupIndex,
                                    props.orderIndex,
                                    "timeHome",
                                    newValue,
                                    props.handleValidate
                                );
                            }}
                            label="Wybierz datę"
                            error={
                                (props.order.timeHome.empty || props.order.timeHome.busy) && !props.order.isProposition
                            }
                            helperText={
                                ((props.order.timeHome.empty || props.order.timeHome.busy) && !props.order.isProposition) ? "Nieprawidłowa data, wybierz nową datę" : false
                            }
                            required={
                                !props.order.isProposition
                            }
                            fullWidth
                        />
                    </ThemeProvider>
                </div>
            </div>
        );
}

const Home = memo(HomeToMemo, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

export default class OrderHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            errorAddAddress: false,
            isWorkAddress: false,
            workerIndex: null
        };
        this.modalIndex = React.createRef();
    }

    alertReset = (value) => {
        this.setState(() => {
            return {
                errorAddAddress: value,
            };
        });
    };

    showAddAddressForm = (workerIndex) => {
        this.setState(() => {
            return {showModal: !this.state.showModal, workerIndex};
        });
    };

    updateAddress = (index, newAddress, cb) => {
        const worker = produce(this.props.data.worker, draftState => {
            if(this.props.data.isWorkAddress.value) {
                draftState.forEach(worker => {
                   worker.address.value = newAddress;
                });
            } else {
                draftState[index].address.value = newAddress;
            }
        });

        this.props.setOrderData(this.props.groupIndex, this.props.index, "worker", worker, cb);
    };

    switchAddressType = () => {
        const isWorkAddress = this.props.data.isWorkAddress.value;
        
        this.props.setOrderData(this.props.groupIndex, this.props.index, "isWorkAddress", !isWorkAddress, () => {
            

            const worker = produce(this.props.data.worker, draftState => {
               draftState.forEach(worker => {
                   if(isWorkAddress) {
                       worker.address.value = worker.person.value?.adres[0] || null;
                   } else {
                       worker.address.value = null;
                   }
               }) ;
            });

            this.props.setOrderData(this.props.groupIndex, this.props.index, "worker", worker, () => {
                this.props.setOrderData(this.props.groupIndex, this.props.index, "timeHome", null)
            });
          
        });
    };

    changeOptions = (data) => {
        if (this.props.data.isWorkAddress.value) {
            return this.props.stations;
        } else {
            return data.person.value ? data.person.value.adres : []
        }
    }

    handleErrorMessage = (index, data) => {
        if (!data.person.value) {
            this.setState(() => {
                return {errorAddAddress: true};
            });
            setTimeout(this.alertReset, 3000);
            return;
        }
        this.showAddAddressForm(index);
        this.modalIndex.current = index;
    }

    render() {
        const filter = createFilterOptions();
        const legend = this.props.data.isWorkAddress.value ? "Zamień adres na Dom" : "Zamień adres na Praca";
        const label = this.props.data.isWorkAddress.value ? 'Praca' : 'Dom';
        return (
            <div className={styles["orderHome"]}>
                {this.props.data.worker.map((data, index) => {
                    if(!this.props.data.isWorkAddress.value || index === 0) {
                        return <Home
                            key={index}
                            undoChanges={this.props.undoChanges}
                            filter={filter}
                            switchAddressType={this.switchAddressType}
                            label={label}
                            legend={legend}
                            stations={this.props.stations}
                            order={this.props.data}
                            type={this.props.type}
                            setOrderData={this.props.setOrderData}
                            index={index}
                            groupIndex={this.props.groupIndex}
                            orderIndex={this.props.index}
                            handleValidate={this.props.handleValidate}
                            handleErrorMessage={this.handleErrorMessage}
                            updateAddress={this.updateAddress}
                            data={data}
                            changeOptions={this.changeOptions}
                        />
                    }
                })}

                {this.state.showModal ? (
                    <AddAddressInOrder
                        formIndex={this.state.workerIndex}
                        groupIndex={this.props.groupIndex}
                        field="worker"
                        addToPropertyInOrder={this.props.data.isWorkAddress.value ? this.props.addWorkAddress : this.props.addWorkerAddress}
                        showAddAddressForm={this.showAddAddressForm}
                        url={this.props.data.isWorkAddress.value ? '/api/addresses' : '/api/pracownik/address'}
                        for={this.props.data.isWorkAddress.value ? 'work' : 'worker'}
                        pracownik={this.props.data.worker[this.modalIndex.current].person.value}
                        addWorkerAddress={this.props.addWorkerAddress}
                        handleErrorAlert={this.handleErrorAlert}
                        index={this.props.index}
                    />
                ) : null}

                {this.state.errorAddAddress && (
                    <FormAlert
                        showAlert
                        severity="error"
                        message="Uzupełnij pole Pracownik"
                    />
                )}
            </div>
        );
    }
}
