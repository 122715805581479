import React, { useState, useEffect } from "react";
import HttpClient from "../../../../HttpClient";
import MessageColumn from "../../../reusableComponents/orders/communicationPanel/subcomponents/MessageColumn";
import styles from "../../../reusableComponents/orders/communicationPanel/subcomponents/css/messagePanel.module.scss";

const SettlementsPanelChat = (props) => {
    const messageTypes = ["dispatch", "driver", "notes"];
    const [readMessageTypes, setReadMessageTypes] = useState({ dispatch: 1, driver: 1, notes: 1 });
    const [user, setUser] = useState(undefined);
    const orderID = props.order.courseDetails.id;

    const getUserData = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/account", {}, true, false);
            setUser(response.data.user);
        } catch (e) {}
    };

    useEffect(() => {
        getUserData().then(() => {});
    }, []);

    const ordersWithUnreadedMessages = async () => {
        const httpRequest = HttpClient.createRequest();
        httpRequest
            .get(
                `api/order/comms/has-unreaded-messages`,
                {
                    params: {
                        zlecenie: orderID,
                    },
                },
                true,
                true,
                true
            )
            .then((response) => {
                if (Object.keys(response.data.data).length) {
                    const data = response.data.data[orderID];
                    const newReadMessages = {
                        dispatch: data.messageType.dispatch,
                        driver: data.messageType.driver,
                        notes: data.messageType.notes,
                    };
                    if (newReadMessages.dispatch === 0 || newReadMessages.driver === 0 || newReadMessages.notes === 0) {
                        setReadMessageTypes(newReadMessages);
                    }
                }
            });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            ordersWithUnreadedMessages().then(() => {});
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const updateRead = () => {
        const newReadMessages = {
            dispatch: 1,
            driver: 1,
            notes: 1,
        };
        setReadMessageTypes(newReadMessages);
        const httpRequest = HttpClient.createRequest();
        httpRequest
            .put(
                `api/order/comms/set-readied`,
                {
                    zlecenie: orderID,
                },
                true,
                true,
                true
            )
            .then(() => {});
    };

    if (!user?.roles) return null;

    return (
        <div className={styles.messagePanel}>
            {messageTypes.map((messageType) => {
                return (
                    <MessageColumn
                        messageType={messageType}
                        userRoles={user.roles}
                        messagesRead={readMessageTypes[messageType]}
                        onUpdateRead={updateRead}
                        partner={props.order.partner}
                        isContractorOperator={props.isContractorOperator}
                        entity={props.isDispatcher ? 'dyspozytor' : 'operator'}
                        courseDetails={props.order.courseDetails}
                    />
                );
            })}
        </div>
    );
};

export default SettlementsPanelChat;
