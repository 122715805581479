import React from "react";
import styles from "./orderWork.module.scss";
import { IconButton, TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { styled } from "@material-ui/core/styles";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AddAddressInOrder from "../../../addAddressInOrder/AddAddressInOrder";
import { isDate, isEqual } from "date-fns";
import { StyledAutocomplete } from "../OrderLine";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Undo as UndoIcon } from "@material-ui/icons";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import clearSeconds from "../../../../../helper/clearSeconds";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const WorkInput = styled(TextField)({
    width: 220,
    margin: "16px 0 0 0",
    padding: "1px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const WorkDate = styled(DateTimePicker)({
    width: 220,
    marginBottom: "3px",
    fontFamily: "'Open Sans', sans-serif",
});

export default class OrderWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showModal: false,
            isWorkAddress: true,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props !== nextProps || this.state !== nextState;
    }

    showAddAddressForm = () => {
        this.setState(() => {
            return { showModal: !this.state.showModal };
        });
    };

    disableSameAddress = (option, workers) => {
        return (
            !(option.street === "DODAJ" && option.city === "ADRES") &&
            workers.some(
                (worker) =>
                    option?.id === worker.address.value?.id ||
                    (option?.lat === worker.address.value?.lat && option?.lon === worker.address.value?.lon)
            )
        );
    };

    render() {
        let shouldOpen = true;
        const showDefault = (isAddress) => {
            if (this.props.type === "edit") {
                if (isAddress) {
                    return this.props.destination.value?.id !== this.props.destination.default?.id;
                }
                return (
                    isDate(this.props.timeWork.default) &&
                    !isEqual(
                        clearSeconds(this.props.timeWork.value, true),
                        clearSeconds(this.props.timeWork.default, true)
                    )
                );
            }
            return false;
        };
        const statusClass = showDefault(true) || showDefault(false) ? "--touched" : "";

        const filter = createFilterOptions();
        return (
            <div className={styles["orderWork"]}>
                <div className={styles["orderWork__singleLine" + statusClass]}>
                    <div className={styles["orderWork__input"]}>
                        <StyledAutocomplete
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                filtered.unshift({
                                    inputValue: params.inputValue,
                                    street: "DODAJ",
                                    city: "ADRES",
                                });
                                return filtered;
                            }}
                            getOptionSelected={(option, value) => option.id === value.id}
                            options={this.props.stations}
                            getOptionLabel={(address) => `${address.street} ${address.city}`}
                            getOptionDisabled={(option) => this.disableSameAddress(option, this.props.data.worker)}
                            value={this.props.destination.value}
                            onChange={(event, newValue) => {
                                if (newValue?.street === "DODAJ" && newValue?.city === "ADRES") {
                                    this.showAddAddressForm();
                                } else {
                                    this.props.setOrderData(
                                        this.props.groupIndex,
                                        this.props.index,
                                        "destination",
                                        newValue,
                                        this.props.handleValidate
                                    );
                                }
                            }}
                            ListboxProps={{
                                "data-id": `workAddressSelectList-groupIndex_${this.props.groupIndex}`,
                            }}
                            renderInput={(params) => (
                                <WorkInput
                                    {...params}
                                    error={this.props.destination.empty && !this.props.data.isProposition}
                                    helperText={
                                        this.props.destination.empty && !this.props.data.isProposition
                                            ? "Uzupełnij dane"
                                            : false
                                    }
                                    required={!this.props.data.isProposition}
                                    InputProps={{
                                        ...params.InputProps,
                                        "data-id": `workAddressSelect-groupIndex_${this.props.groupIndex}`,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {params.InputProps.endAdornment.props.children.map((item) => item)}
                                                {showDefault(true) && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            this.props.undoChanges(
                                                                "destination",
                                                                this.props.groupIndex,
                                                                this.props.index
                                                            )
                                                        }
                                                    >
                                                        <UndoIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Praca"
                                    variant="standard"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                    <div className={styles["orderWork__input"]}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <WorkDate
                                autoOk={false}
                                format={"d.LLLL yyyy, HH:mm"}
                                ampm={false}
                                open={this.state.open}
                                onOpen={() => {
                                    shouldOpen ? this.setState({ open: true }) : (shouldOpen = true);
                                }}
                                onClose={() => this.setState({ open: false })}
                                InputProps={{
                                    "data-id": "workDateSelect",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showDefault(false) && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        shouldOpen = false;
                                                        this.props.undoChanges(
                                                            "timeWork",
                                                            this.props.groupIndex,
                                                            this.props.index
                                                        );
                                                    }}
                                                >
                                                    <UndoIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                                value={this.props.timeWork.value}
                                onChange={(newValue) => {
                                    this.props.setOrderData(
                                        this.props.groupIndex,
                                        this.props.index,
                                        "timeWork",
                                        newValue,
                                        this.props.handleValidate
                                    );
                                }}
                                label="Wybierz datę"
                                error={
                                    (this.props.timeWork.empty || this.props.data.timeWork.busy) &&
                                    !this.props.data.isProposition
                                }
                                helperText={
                                    (this.props.timeWork.empty || this.props.data.timeWork.busy) &&
                                    !this.props.data.isProposition
                                        ? "Nieprawidłowa data, wybierz nową datę"
                                        : false
                                }
                                required={!this.props.data.isProposition}
                                fullWidth
                            />
                        </ThemeProvider>
                    </div>

                    {this.state.showModal ? (
                        <AddAddressInOrder
                            type="STATION"
                            index={this.props.index}
                            groupIndex={this.props.groupIndex}
                            for="work"
                            field="destination"
                            addToPropertyInOrder={this.props.addWorkAddress}
                            url="/api/addresses"
                            showAddAddressForm={this.showAddAddressForm}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
