import React, { useContext } from "react";
import RozliczeniaRoute from "./rozliczenia/RozliczeniaRoute";
import ContractorListRoute from "./contractorList/ContractorLIstRoute";
import AdministrationContractorCardRoute from "./contractorCard/ContractorCardRoute";
import {
  AddDelegationRoute,
  StatementsRoute,
  GeneratedRoute,
} from "../delegation";
import { MimicContext } from "../../context";

export default function AdministracjaRoute() {
  const { roles } = useContext(MimicContext);

  const rozliczenia =
    roles.includes("ROLE_SUPER_ADMIN") ||
    roles.includes("ROLE_ADMINISTRACJA") ||
    roles.includes("ROLE_OPERATOR");
  const card =
    roles.includes("ROLE_SUPER_ADMIN") ||
    roles.includes("ROLE_ADMINISTRACJA") ||
    roles.includes("ROLE_OPERATOR");
  const list =
    roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_OPERATOR");
  const delegation =
    roles.includes("ROLE_DELEGACJE_ZAPIS") ||
    roles.includes("ROLE_DELEGACJE") ||
    roles.includes("ROLE_SUPER_ADMIN");

  return (
    <>
      {rozliczenia && <RozliczeniaRoute />}
      {card && <AdministrationContractorCardRoute />}
      {list && <ContractorListRoute />}
      {delegation && <StatementsRoute />}
      {delegation && <GeneratedRoute />}
      {delegation && <AddDelegationRoute />}
    </>
  );
}
