import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import styles from "./rozliczeniaTable.module.scss";
import { makeStyles, styled, ThemeProvider } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getUnixTime, fromUnixTime, isDate, format } from "date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { DatePicker } from "@material-ui/pickers";
import { pl } from "date-fns/locale";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import RozliczeniaDetails from "./rozliczeniaDetails/RozliczeniaDetails";
import HttpClient from "../../../HttpClient";
import produce from "immer";
import NotesIcon from "@material-ui/icons/Notes";
import { v4 as uuid } from "uuid";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import SimpleBar from "simplebar-react";
import _ from "lodash";
import ProgressLine from "../progressLine/ProgressLine";
import lightBlue from "@material-ui/core/colors/lightBlue";
import * as qs from "qs";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ConversationModal from "./conversationModal/ConversationModal";
import InfoDialog from "../alerts/infoDialog/InfoDialog";
import { getColumns } from "./getColumns";
import { mapDriver, mapDriverForDriverSettlements } from "./driverMapper";
import { transformSearchParamsForDriver, transformSortFieldsForDriver } from "./transformer";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import SettlementsPanel from "../../settlements/panel/settlementsPanel";

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal": {
            height: "30px",
            background: "#fff",
            "& .simplebar-scrollbar": {
                height: "100%",
            },

            "& .simplebar-scrollbar:before": {
                backgroundColor: "#47484B",
                height: "20px",
            },
        },

        "& .simplebar-track.simplebar-vertical": {
            width: "30px",
            background: "#fff",
            "& .simplebar-scrollbar": {
                height: "100%",
            },

            "& .simplebar-scrollbar:before": {
                backgroundColor: "#47484B",
                width: "20px",
            },
        },
    },

    tooltipWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    tooltipIcon: {
        opacity: "0.5",
        height: "16px",
        width: "16px",
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 0 5px !important",
        },
    },

    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 5px 0 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "bold",
            lineHeight: "revert",
        },
        "& .MuiTableCell-body": {
            padding: "2px 5px 2px 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
    },
    content: {
        maxWidth: "180px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        display: "flex",
        flexDirection: "column",
        textTransform: "capitalize",
    },
    icon: {
        color: "white",
    },
    zaakceptowany: {
        color: "#66D01D",
    },
    uwagi: {
        color: "#faba1f",
    },
    odpowiedz: {
        color: "#1DBBED",
    },
    nierozliczony: {
        color: "#ED1D1D",
    },
    przerwany: {
        color: "black",
    },
    pending: {
        color: "#b4b4b4",
    },
    accepted: {
        color: "#66D01D",
    },
    rejected: {
        color: "red",
    },

    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        },
    },

    uwagiButton: {
        backgroundColor: "#faba1f",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#faba1f",
        },
    },
    iconButtonAccept: {
        backgroundColor: "#66D01D",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
    iconPushedAccept: {
        backgroundColor: "#E5E5E5",
        margin: "2px",
    },
    iconButtonCancel: {
        backgroundColor: "#ED1D1D",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        padding: 0,
        margin: 0,
        fontSize: 12,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
    },

    sum: {
        width: 200,
        padding: "7px 5px 7px 5px !important",
        fontSize: "13px !important",
        color: "#1dbbed",
        fontWeight: "bold",
        borderBottom: "4px solid rgb(224,224,224)",
    },
    wRealizacji: {
        color: "#66D01D",
    },
    przypisany: {
        color: "#faba1f",
    },
    zaplanowany: {
        color: "#1DBBED",
    },
    przetwarzany: {
        color: "#b4b4b4",
    },
    odrzucony: {
        color: "#ED1D1D",
    },
    anulowany: {
        color: "black",
    },
    zakonczony: {
        color: "#d7d7d7",
    },
    timeRoot: {
        padding: 0,
        margin: 0,

        "& .MuiTimelineItem-root": {
            minHeight: "42px !important",
        },

        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -1,
        padding: "5px 6px",
    },
    timeLinewRealizacji: {
        backgroundColor: "#66D01D",
    },
    timeLinePrzypisany: {
        backgroundColor: "#faba1f",
    },
    timeLineZaplanowany: {
        backgroundColor: "#1DBBED",
    },
    timeLinePrzetwarzany: {
        backgroundColor: "#b4b4b4",
    },
    timeLineOdrzucony: {
        backgroundColor: "#ED1D1D",
    },
    timeLineAnulowany: {
        backgroundColor: "black",
    },
    timeLineZakonczony: {
        backgroundColor: "#d7d7d7",
    },
    timeLinePrzedStartem: {
        animation: `$myEffect2 2000ms infinite`,
    }
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const SearchDatePicker = styled(DatePicker)({
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const getUrl = (type) => {
    switch (type) {
        case 1:
            return "/api/front-settlement";
        case 2:
            return "/api/front-notsettlement";
        case 3:
            return "/api/driver-billings";
    }
};

const getId = (type, row) => {
    switch (type) {
        case 1:
        case 3:
            return row?.items?.id;
        case 2:
            return row?.items?.orderId;
    }
};

export default function RozliczeniaTable({
    date,
    isPostedData,
    type,
    handleDatePickerDateChange,
    getDatePickerValue,
    clearDate,
    roles,
    contractor,
    setContractor,
    setParentSearchValues,
    setRowsLength,
    setParentColumns,
}) {
    const classes = useStyles();
    const url = getUrl(type);

    const hasPermissionToEdit =
        roles.includes("ROLE_SUPER_ADMIN") ||
        roles.includes("ROLE_OPERATOR") ||
        roles.includes("ROLE_ADMINISTRACJA_EDYCJA");

    const isDispatcher =  (roles.includes('ROLE_DYSPOZYTOR') && !roles.includes('ROLE_OPERATOR_CONTRACTOR'))
    const isContractorOperator = roles.includes('ROLE_OPERATOR_CONTRACTOR')

    const [page, setPage] = useState(0);
    const [id, setId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showDetails, setShowDetails] = useState(false);
    const [showConversation, setShowConversation] = useState(false);
    const [showInfoDialogCancel, setShowInfoDialogCancel] = useState(false);
    const [index, setIndex] = useState(null);
    const [searchValues, setSearchValues] = useState([]);
    const [api, setApi] = useState([]);
    const [sum, setSum] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [count, setCount] = useState(0);
    const [isDataLoading, setIsEditLoading] = useState(false);
    const [driverOptions, setDriverOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [isSettlementPanelOpen, setIsSettlementPanelOpen] = useState(false);
    const [selectedSettlementOrderId, setSelectedSettlementOrderId] = useState(undefined);
    const [tooLongInputValue, setTooLongInputValue] = useState({ route: false, driverRoute: false });
    const [isRelatedOrdersError, setIsRelatedOrdersError] = useState(false);

    const validateRelatedOrders = (value) => {
        if (value === "") {
            setIsRelatedOrdersError(false);
            return;
        }

        const validationPattern = /^([0-9]+(\[0-9]+)*,*)+$/;
        const validationResult = !validationPattern.test(value);

        setIsRelatedOrdersError(validationResult);
    };

    useEffect(() => {
        if (!roles.includes("ROLE_SUPER_OPERATOR") && !roles.includes("ROLE_OPERATOR")) {
            return;
        }
        const httpRequest = HttpClient.createRequest();

        httpRequest.get("api/kierowca", {}, {}, true).then((response) => {
            const driverOptions = response.data.pracownicy?.map((item) => {
                return {
                    username: item.user.username,
                    fullName: `${item.user.name} ${item.user.surName}`,
                    billingGroupName: item.user.billingGroup.name,
                };
            });
            setDriverOptions(driverOptions);
        });

        httpRequest.get("api/app/employees", {}, {}, true).then((response) => {
            const employeeOptions = response.data.data.map((item) => item.firstName);

            setEmployeeOptions(employeeOptions);
        });
    }, [type]);

    const source = axios.CancelToken.source();

    const disableAll = () => {
        if (!roles.includes("ROLE_SUPER_OPERATOR") && !roles.includes("ROLE_OPERATOR")) {
            const nextState = produce(columns, (draftState) => {
                draftState.forEach((column) => {
                    column.disabled = true;
                });
                const orderNo = draftState.find((column) => column.id === "orderNo");
                if (orderNo) {
                    orderNo.disabled = false;
                }
            });
            setColumns(nextState);
        }
    };

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const handleOnEnterPress = (e, shouldGetData = true, api) => {
        if (e.key === "Enter") {
            if (shouldGetData) {
                getData();
            } else {
                handleAcceptOrCancel(getId(type, api), "accept");
            }
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const _searchParams = searchValues.map((item) => transformSearchParamsForDriver(item, type));
        const getParam = _searchParams.map(getParams).join("");
        const sortField = columns
            .map((item) => transformSortFieldsForDriver(item, type))
            .filter((item) => item.sorted === true);

        const httpRequest = HttpClient.createRequest();
        try {
            const { data } = await httpRequest.get(
                `${url}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}&limit=${
                    rowsPerPage ? rowsPerPage : 5
                }&page=${page ? page + 1 : 1}`,
                {
                    cancelToken: source.token,
                    params: {
                        date,
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                },
                true
            );

            let items;
            if (type === 3) {
                items = data.data.map(mapDriverForDriverSettlements).map((item) => ({
                    items: item,
                    editedItems: item,
                    edit: false,
                    tempId: uuid(),
                }));
            } else {
                items = data.items
                    .map((item) => ({
                        ...item,
                        ...mapDriver(item.driverBilling),
                        connectedOrders: item.connectedOrders ?? item.courseDetails?.connectedOrders?.join(", ") ?? "",
                        status: item.status,
                    }))
                    .map((item) => ({
                        items: item,
                        editedItems: item,
                        edit: false,
                        tempId: uuid(),
                    }));
            }

            setSum(data.sum);
            setApi(items);
            setCount(data.count);
            setIsLoading(false);
        } catch (e) {}
    };

    const sendData = async (id) => {
        setIsEditLoading(true);
        const { editedItems } =
            type === 1 || type === 3
                ? api.find((item) => item.items.id === id)
                : api.find((item) => item.items.orderId === id);

        let postData = {
            connectedOrders: editedItems.connectedOrders,
            worker: editedItems.worker,
            route: editedItems.route,
            contractor: editedItems.contractor,
            billedOrder: editedItems.orderId,
            orderNo: editedItems.orderNo,
            sumKm: parseFloat(editedItems.km) || 0,
            normalRate: parseFloat(editedItems.rate) || 0,
            dicount: parseFloat(editedItems.discount) || 0,
            rateAfterDiscount: parseFloat(editedItems.rateAfterDiscount) || 0,
            sumKmCost: parseFloat(editedItems.kmCost) || 0,
            countStop: parseFloat(editedItems.stopCount) || 0,
            stopCost: parseFloat(editedItems.stopCost) || 0,
            highwayCost: parseFloat(editedItems.highway) || 0,
            sumCost: parseFloat(editedItems.sumCost) || 0,
            saveMoney: parseFloat(editedItems.saveMoney) || 0,
            comments: editedItems.comments,
        };

        const httpRequest = HttpClient.createRequest();

        const url = type === 1 ? `api/editSettlement/${id}` : `api/manual-settlement`;
        const method = type === 1 ? "put" : "post";

        try {
            let response, driverResponse;
            if (type === 1 || type === 2) {
                response = await httpRequest.builder(method, url, postData, {}, true);
            }
            if (type === 2 || type === 3) {
                const driverInfo = editedItems?.driverFullName?.split(" ") ?? [];

                postData = {
                    connectedOrders: editedItems.connectedOrders,
                    orderNo: editedItems.orderNo,
                    route: editedItems.route,
                    status: 1,
                    worker: editedItems.worker,
                    driverName: driverInfo[0],
                    driverSurname: driverInfo[1],
                    driverUsername:
                        editedItems.driverUsername ?? `${driverInfo[0]?.toLowerCase()}.${driverInfo[1]?.toLowerCase()}`,
                    contractor: editedItems.contractor,
                    billingGroupName: editedItems.driverBillingGroupName,
                    sumKm: parseFloat(editedItems.driverSumKm) || 0,
                    normalRate: parseFloat(editedItems.driverNormalRate) || 0,
                    waitingRate: parseFloat(editedItems.driverWaitingRate) || 0,
                    waitingCost: parseFloat(editedItems.driverWaitingCost) || 0,
                    highwayCost: parseFloat(editedItems.driverHighwayCost) || 0,
                    sumCost: parseFloat(editedItems.driverSumCost) || 0,
                    accountDate:
                        editedItems.accountDate ??
                        fromUnixTime(editedItems?.courseDetails?.destinationTime).toISOString(),
                    costKm: parseFloat(editedItems.driverKmCost),
                    waitingTime: parseFloat(editedItems.driverWaitingTime) || 0,
                    number: type === 2 ? editedItems.number : null,
                    billingModelName: editedItems.billingModelName
                };

                if (type === 3) {
                    const billingsUrl = `api/driver-billings/${editedItems.driverBillingId}`;
                    driverResponse = await httpRequest.put(billingsUrl, postData, {}, true);
                } else if (type === 2) {
                    const billingsUrl = "api/driver-billings";
                    driverResponse = await httpRequest.post(billingsUrl, postData, {}, true);
                }
            }
            // if (type === 1) {
            //     const { data } = await httpRequest.get("api/front-settlementOnlySum", {}, true);
            //     setSum(data);
            // }
            setTooLongInputValue({ route: false, driverRoute: false });
            return response ?? driverResponse;
        } catch (e) {
            throw new Error();
        }
    };

    const deleteData = async (id) => {
        const { items } =
            type === 1 || type === 3
                ? api.find((item) => item.items.id === id)
                : api.find((item) => item.items.orderId === id);

        let url;
        switch (type) {
            case 1:
                url = `/api/deleteSettlement/${items.id}`;
                break;
            case 2:
                url = `/api/order/cancel/${items.orderId}`;
                break;
            case 3:
                url = `/api/driver-billings/${items.id}`;
        }

        const method = type === 1 || type === 3 ? `delete` : `post`;

        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.builder(method, url, { remove: true }, {}, true);
            const nextApi =
                type === 1 || type === 3
                    ? api.filter((item) => item.items.id !== id)
                    : api.filter((item) => item.items.orderId !== id);
            setApi(nextApi);
            if (type === 1) {
                const { data } = await httpRequest.get("api/front-settlementOnlySum", {}, true);
                setSum(data);
            }
            setTooLongInputValue({ route: false, driverRoute: false });
        } catch (e) {}
    };

    useEffect(() => {
        disableAll();
    }, []);

    useEffect(() => {
        setPage(0);
    }, [type, date]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const newColumns = getColumns(type, roles);

        if (roles.includes("ROLE_OPERATOR") || roles.includes("ROLE_SUPER_ADMIN")) {
            setColumns(newColumns);
        } else {
            setColumns(
                produce(newColumns, (draftState) => {
                    const discount = draftState.find((item) => item.id === "discount");
                    const rateAfterDiscount = draftState.find((item) => item.id === "rateAfterDiscount");
                    if (discount) {
                        discount.hide = true;
                    }
                    if (rateAfterDiscount) {
                        rateAfterDiscount.hide = true;
                    }
                })
            );
        }

        getData();

        return () => {
            source.cancel();
        };
    }, [date, invokeClearColumn, refresh, type, isPostedData, rowsPerPage, page]);

    useEffect(() => {
        handleSearch("contractor", contractor?.name, false);
        setRefresh(!refresh);
    }, [contractor]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSearch = (field, query = null, writtenByUser = true) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        if (field === "contractor" && query === null && writtenByUser) {
            setContractor(null);
        }
        setSearchValues([...filterObject, { id: field, value: query }]);
    };

    const settlementStatusDictionary = (status) => {
        switch (status) {
            case 0: return '-'
            case 1: return 'Z'
            case 2: return 'O'
            default: return status
        }
    }

    const settlementStatusStringObject = (status) => {
        let backgroundColor;
        let fontColor = '#fff';

        switch (status) {
            case 0: backgroundColor = 'transparent'; fontColor='inherit'; break;
            case 1: backgroundColor = '#2bc000'; break;
            case 2: backgroundColor = '#da0027'; break;
            default: backgroundColor = 'transparent';
        }
        return <span style={{
            fontWeight: "bold", color: fontColor, backgroundColor: backgroundColor,
            padding:"8px 1em", borderRadius: 8
        }}>{settlementStatusDictionary(status)}</span>
    }

    useEffect(() => {
        setParentSearchValues(searchValues);
    }, [searchValues]);

    function createData(
        status,
        time,
        billingModel,
        billingModelName,
        number,
        worker,
        route,
        km,
        rate,
        discount,
        rateAfterDiscount,
        kmCost,
        costKm,
        stopCount,
        stopCost,
        highway,
        sumCost,
        saveMoney,
        orderNo,
        contractor,
        driverFullName,
        driverSumKm,
        driverNormalRate,
        driverKmCost,
        driverWaitingRate,
        driverWaitingTime,
        driverWaitingCost,
        driverHighwayCost,
        driverSumCost,
        driverBillingGroupName,
        branch,
        actions,
        connectedOrders,
        consortiumMember,
        notes,
        partner,
        settlementStatus
    ) {
        return {
            status,
            time,
            billingModel,
            billingModelName,
            number,
            worker,
            route,
            km,
            rate,
            discount,
            rateAfterDiscount,
            kmCost,
            costKm,
            stopCount,
            stopCost,
            highway,
            sumCost,
            saveMoney,
            orderNo,
            contractor,
            driverFullName,
            driverSumKm,
            driverNormalRate,
            driverKmCost,
            driverWaitingRate,
            driverWaitingTime,
            driverWaitingCost,
            driverHighwayCost,
            driverSumCost,
            driverBillingGroupName,
            branch,
            actions,
            connectedOrders,
            consortiumMember,
            notes,
            partner,
            settlementStatus
        };
    }

    const switchShowModal = () => {
        setShowDetails(false);
        setShowConversation(false);
    };

    const ShowArrow = ({ sorted, order }) => {
        if (sorted) {
            if (order === "ASC") {
                return <ArrowUpwardIcon style={{ opacity: "0.5", height: "16px", weight: "16px" }} />;
            } else if (order === "DESC") {
                return <ArrowDownwardIcon style={{ opacity: "0.5", height: "16px", weight: "16px" }} />;
            }
        } else {
            return <ArrowUpwardIcon style={{ opacity: "0.5", height: "16px", weight: "16px" }} />;
        }
    };

    const createSortHandler = (_column) => {
        columns.map((column) => {
            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
        });
        setColumns(columns);
        handleSort();
    };

    const handleSort = () => {
        setPage(0);
        getData();
    };

    const handleEdit = (id) => {
        let nextApi = produce(api, (draftState) => {
            const editedItem =
                type === 1 || type === 3
                    ? draftState.find((item) => item.items.id === id)
                    : draftState.find((item) => item.items.orderId === id);
            editedItem.edit = !editedItem.edit;
        });

        nextApi = produce(nextApi, (draftState) => {
            const { items } =
                type === 1 || type === 3
                    ? draftState.find((item) => item.items.id === id)
                    : draftState.find((item) => item.items.orderId === id);
            items.km = items.km || 0;
            items.rate = items.rate || 0;
            items.discount = items.discount || 0;
            items.rateAfterDiscount = items.rateAfterDiscount || 0;
            items.sumCost = items.sumCost || 0;
            items.kmCost = items.kmCost || 0;
            items.stopCount = items.stopCount || 0;
            items.stopCost = items.stopCost || 0;
            items.highway = items.highway || 0;
            items.saveMoney = items.saveMoney || 0;
            items.costKm = items.costKm || 0;
        });

        setApi(nextApi);
    };

    const onSettlementPanelClick = (selectedOrderId) => {
        setSelectedSettlementOrderId(selectedOrderId);
        setIsSettlementPanelOpen(true);
    };

    const handleAcceptOrCancel = async (id, action) => {
        let base = api;
        try {
            if (action === "accept") {
                if (isRelatedOrdersError) {
                    return;
                }

                const { editedItems } =
                    type === 1 || type === 3
                        ? api.find((item) => item.items.id === id)
                        : api.find((item) => item.items.orderId === id);

                if (type === 2) {
                    if (!editedItems.driverFullName || editedItems.driverFullName === "-") {
                        const httpRequest = HttpClient.createRequest();

                        httpRequest.addFlash("Nie można dokonać rozliczenia bez wybrania kierowcy", {
                            variant: "error",
                            preventDuplicate: true,
                            key: "internalServerError",
                        });
                        return;
                    }
                }

                const { data } = await sendData(id);
                if (type === 1 || type === 3) {
                    base = produce(base, (draftState) => {
                        const editedItem = draftState.find((item) => item.items.id === id);
                        editedItem.items =
                            type === 3
                                ? { ...data.data, ...mapDriver(data.data) }
                                : { ...data.data, ...mapDriver(data.data.driverBilling) };
                        editedItem.edit = !editedItem.edit;
                    });
                } else {
                    base = api.filter((item) => item.items.orderId !== id);
                }
                setIsRelatedOrdersError(false);
            } else {
                setIsRelatedOrdersError(false);
                base = produce(base, (draftState) => {
                    const editedItem =
                        type === 1 || type === 3
                            ? draftState.find((item) => item.items.id === id)
                            : draftState.find((item) => item.items.orderId === id);
                    editedItem.edit = !editedItem.edit;
                });
            }
            setApi(base);
            setTooLongInputValue({ route: false, driverRoute: false });
        } catch (e) {
        } finally {
            setIsEditLoading(false);
        }
    };

    const getEditedValue = (tempId, columnId) => {
        const { editedItems } = api.find((item) => item.tempId === tempId);
        if (editedItems.hasOwnProperty(columnId)) return editedItems[columnId] ?? "";
        return null;
    };

    const safeParseFloat = (value) => {
        if (value == null || value === "") return 0;
        const parsed = parseFloat(value);
        return !isNaN(parsed) ? parsed : value;
    };

    const costCalculation = (values) => {
        values.rateAfterDiscount = _.round(values.rate - (values.rate * values.discount) / 100, 2);
        values.kmCost = _.round(values.km * values.rateAfterDiscount, 2);
        let stopCost = 0;
        const countedFrom = values.contract?.countedFrom;
        const waitingRate = values.contract?.waitingRate;
        if (!!countedFrom && !!waitingRate) {
            stopCost = values.stopCost = _.round(
                Math.ceil((values.stopCount * 60 - countedFrom) / 60) * waitingRate,
                2
            );
        } else {
            values.stopCost = 0;
        }
        values.sumCost = _.round(values.kmCost + stopCost + safeParseFloat(values.highway), 2);
        values.saveMoney = _.round(values.km * values.rate - values.kmCost, 2);

        values.driverKmCost = _.round(safeParseFloat(values.driverNormalRate) * safeParseFloat(values.driverSumKm), 2);

        values.costKm = _.round(safeParseFloat(values.driverNormalRate) * safeParseFloat(values.driverSumKm), 2);

        values.driverWaitingCost =
            type === 2
                ? _.round(Math.ceil((values.driverWaitingTime * 60 - countedFrom) / 60) * values.driverWaitingRate, 2)
                : _.round(safeParseFloat(values.driverWaitingTime) * safeParseFloat(values.driverWaitingRate), 2);

        const driverSumKmCost = safeParseFloat(values.driverSumKm) * safeParseFloat(values.driverNormalRate);
        values.driverSumCost = _.round(
            driverSumKmCost + safeParseFloat(values.driverWaitingCost) + safeParseFloat(values.driverHighwayCost),
            2
        );

        return values;
    };

    const handleDriverEdit = (tempId, value) => {
        const nextApi = produce(api, (draftState) => {
            const { editedItems } = draftState.find((item) => item.tempId === tempId);
            editedItems["driverFullName"] = value?.fullName ?? "";
            editedItems["driverUsername"] = value?.username ?? "";
            editedItems["driverBillingGroupName"] = value?.billingGroupName ?? "";
        });
        setApi(nextApi);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    };

    const handleChangeEdit = (tempId, columnId, columnType, value = 0) => {
        if (columnType === "number" && value.startsWith("00")) return;

        value = [".", ","].includes(value?.charAt(0)) ? "" : value;
        if (columnType === "number") value = value.replace(",", ".");

        let finalValue;

        const regExp = /^\d{0,10}(\.\d{0,2})?$/;

        const condition = columnType === "number" && (regExp.test(value) || value === "");

        if (condition) {
            let parsed = ["."].includes(value.slice(-1)) || ["0"].includes(value.slice(-1)) ? value : parseFloat(value);
            const tempValue = parsed ? parsed : value;
            const { editedItems } = api.find((item) => item.tempId === tempId);
            if (
                columnId !== "discount" ||
                (tempValue >= 0 &&
                    tempValue <= 100 &&
                    (editedItems.contract?.maxDiscount === undefined || tempValue <= editedItems.contract?.maxDiscount))
            )
                finalValue = tempValue;
        } else if (columnType === "text") {
            if (columnId === "orderNo" && value.length > 255) {
                HttpClient.addFlash("Została osiągnięta maksymalna długość pola Numer Zlecenia", {
                    variant: "info",
                    preventDuplicate: false,
                    key: "internalServerError",
                });

                return;
            }

            finalValue = value;
        }

        if (finalValue || finalValue === "") {
            const nextApi = produce(api, (draftState) => {
                const { editedItems } = draftState.find((item) => item.tempId === tempId);
                editedItems[columnId] = finalValue === "0" ? 0 : finalValue;
                costCalculation(editedItems);
            });
            setApi(nextApi);
        }
    };

    const refreshTable = () => {
        setRefresh(!refresh);
    };

    const handleSwitchInfoDialog = (value) => {
        setShowInfoDialogCancel(value);
    };

    const rows = api
        ? api.map((row, index) => {
              const data = row.items;
              let formatDate;
              if (type === 3) {
                  formatDate = data.accountDate ? format(new Date(data.accountDate), "d.LL yy", { locale: pl }) : "-";
              } else {
                  formatDate = data.date ? format(fromUnixTime(data.date), "d.LL yy", { locale: pl }) : "-";
              }

              const connectedOrders = data.connectedOrders ?? data.courseDetails?.connectedOrders?.join(", ") ?? "";

              const checkIsOffsetOrderHasCurrentInConnected = (offset) => {
                  return api[index + offset]?.["items"].connectedOrders?.indexOf(data.number) >= 0 ? 1 : 0;
              };

              const getOperatorClasses = () => {
                  if (data.status === 1)
                      return { timeLine: classes.timeLinewRealizacji, singleDot: classes.wRealizacji };
                  if (data.courseDetails.cancel)
                      return { timeLine: classes.timeLineAnulowany, singleDot: classes.anulowany };
                  if (data.courseDetails.status === "frozen")
                      return { timeLine: classes.timeLineOdrzucony, singleDot: classes.odrzucony };

                  return { timeLine: classes.timeLinePrzetwarzany, singleDot: classes.przetwarzany }
              };

              const getStatusClass = () => {
                  return getOperatorClasses();
              };

              return createData(
                  data.connectedOrders && type !== 3 ? (
                      <TimelineItem style={{ minHeight: 28 }}>
                          <TimelineSeparator>
                              {checkIsOffsetOrderHasCurrentInConnected(-1) ? (
                                  <TimelineConnector className={getStatusClass().timeLine} />
                              ) : (
                                  ""
                              )}
                              <TimelineDot className={`${classes.timeDot} ${getStatusClass().timeLine}`} />
                              {checkIsOffsetOrderHasCurrentInConnected(1) ? (
                                  <TimelineConnector className={getStatusClass().timeLine} />
                              ) : (
                                  ""
                              )}
                          </TimelineSeparator>
                      </TimelineItem>
                  ) : (
                      <FiberManualRecordIcon className={getStatusClass().singleDot} />
                  ),
                  formatDate,
                  data.contract?.billingModel ?? "-",
                  data.driverBilling?.billingModelName
                      ? data.driverBilling.billingModelName
                      : data.billingModelName ?? "-",
                  data.number ?? "-",
                  data.worker !== null ? <div className={classes.content}>{data.worker}</div> : "-",
                  data.route !== null ? <div className={classes.content}>{data.route}</div> : "-",
                  data.km !== null && data.km !== undefined ? (data.km === 0 ? "-" : data.km + " km") : "-",
                  data.driverBilling?.rate
                      ? data.driverBilling.rate?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : data.rate
                      ? data.rate?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.discount ? data.discount?.toLocaleString(undefined, { minimumFractionDigits: 2 }) + "%" : "-",
                  data.rateAfterDiscount ?? (data.discount && data.rate)
                      ? (data.rate - data.rate * (data.discount / 100))?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })
                      : "-",
                  !(data.kmCost === null || data.kmCost === undefined)
                      ? data.kmCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  !(data.costKm === null || data.costKm === undefined)
                      ? data.costKm?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.stopCount !== null && data.stopCount !== undefined
                      ? type === 2 && data.stopCount === 0
                          ? "-"
                          : data.stopCount
                          ? data.stopCount + " godz."
                          : "-"
                      : "-",
                  !(data.stopCost === null || data.stopCost === undefined)
                      ? data.stopCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  !(data.highway === null || data.highway === undefined)
                      ? type === 2 && data.highway === 0
                          ? "-"
                          : data.highway?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  !(data.sumCost === null || data.sumCost === undefined)
                      ? data.sumCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  !(data.saveMoney === null || data.saveMoney === undefined) ? data.saveMoney : "-",
                  data.orderNo !== null ? data.orderNo : "-",
                  data.contractor !== null ? <div className={classes.content}>{data.contractor}</div> : "-",
                  data.driverFullName ?? "-",
                  data.driverSumKm ? `${_.round(data.driverSumKm, 2)}km` : "-",
                  data.driverNormalRate
                      ? data.driverNormalRate?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.kmCost ? data.kmCost?.toLocaleString(undefined, { minimumFractionDigits: 2 }) : "-",
                  data.waitingRate ? data.waitingRate?.toLocaleString(undefined, { minimumFractionDigits: 2 }) : "-",
                  data.driverWaitingTime
                      ? data.driverWaitingTime?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.driverWaitingCost
                      ? data.driverWaitingCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.driverHighwayCost
                      ? data.driverHighwayCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.driverSumCost
                      ? data.driverSumCost?.toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : "-",
                  data.driverBillingGroupName ?? "-",
                  data.courseDetails?.author?.branch ?? "-",
                  <div className={styles["rozliczenia__buttonContainer"]}>
                      {row.edit ? (
                          <>
                              <BootstrapTooltip title="Zaakceptuj">
                                  <IconButton
                                      size="small"
                                      disabled={isDataLoading}
                                      className={classes.iconButtonAccept}
                                      onClick={() =>
                                          isDataLoading ? null : handleAcceptOrCancel(getId(type, row), "accept")
                                      }
                                  >
                                      <CheckIcon className={classes.icon} />
                                  </IconButton>
                              </BootstrapTooltip>
                              <BootstrapTooltip title="Odrzuć">
                                  <IconButton
                                      size="small"
                                      className={classes.iconButtonCancel}
                                      onClick={() => handleAcceptOrCancel(getId(type, row))}
                                  >
                                      <ClearIcon className={classes.icon} />
                                  </IconButton>
                              </BootstrapTooltip>
                          </>
                      ) : (
                          <>
                              {hasPermissionToEdit && type === 1 && (
                                  <BootstrapTooltip title="Edycja">
                                      <span>
                                          <IconButton
                                              size="small"
                                              className={classes.iconButton}
                                              onClick={() => handleEdit(getId(type, row))}
                                          >
                                              <EditIcon className={classes.icon} />
                                          </IconButton>
                                      </span>
                                  </BootstrapTooltip>
                              )}
                              {data.orderId && <BootstrapTooltip title="Panel rozliczeń">
                                  <span>
                                      <IconButton
                                          size="small"
                                          className={classes.iconButton}
                                          onClick={() => onSettlementPanelClick(data.orderId)}
                                      >
                                          <MonetizationOnRoundedIcon className={classes.icon} />
                                      </IconButton>
                                  </span>
                              </BootstrapTooltip>}
                              {data.courseDetails ? (
                                  <BootstrapTooltip title="Szczegóły">
                                      <span>
                                          <IconButton
                                              size="small"
                                              className={data.status === 2 ? classes.uwagiButton : classes.iconButton}
                                              disabled={!data.orderId}
                                              onClick={() => {
                                                  setIndex(index);
                                                  setShowDetails(!showDetails);
                                              }}
                                          >
                                              <MoreHorizIcon className={classes.icon} />
                                          </IconButton>
                                      </span>
                                  </BootstrapTooltip>
                              ) : (
                                  type !== 3 && (
                                      <BootstrapTooltip title="Uwagi">
                                          <span>
                                              <IconButton
                                                  size="small"
                                                  className={classes.uwagiButton}
                                                  onClick={() => {
                                                      setIndex(index);
                                                      setShowConversation(!showConversation);
                                                  }}
                                              >
                                                  <NotesIcon className={classes.icon} />
                                              </IconButton>
                                          </span>
                                      </BootstrapTooltip>
                                  )
                              )}
                              {roles.includes("ROLE_SUPER_OPERATOR") && type !== 3 && (
                                  <BootstrapTooltip title="Usuń rozliczenie">
                                      <IconButton
                                          size="small"
                                          className={classes.iconButtonCancel}
                                          onClick={() => {
                                              setId(getId(type, row));
                                              handleSwitchInfoDialog(true);
                                          }}
                                      >
                                          <ClearIcon className={classes.icon} />
                                      </IconButton>
                                  </BootstrapTooltip>
                              )}
                          </>
                      )}
                  </div>,
                  <div>{connectedOrders.length ? connectedOrders : "-"}</div>,
                  data?.consortiumMember,
                  data.driverBilling?.notes,
                  data.partner ?? '-',
                  settlementStatusStringObject(data.settlementStatus)
              );
          })
        : null;

    useEffect(() => {
        setRowsLength(rows.length);
    }, [rows]);

    useEffect(() => {
        setParentColumns(columns);
    }, [columns]);

    return (
        <div className={styles["rozliczenia"]}>
            {isLoading ? (
                <ProgressLine />
            ) : (
                <Paper className={classes.root}>
                    <TableContainer>
                        <SimpleBar style={{ minHeight: "55vh", maxHeight: "55vh" }} className={classes.simplebar}>
                            <Table
                                stickyHeader
                                aria-label="sticky table"
                                style={{ paddingBottom: "30px", paddingRight: "50px" }}
                            >
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <Fragment key={index}>
                                                {!column.hide && (
                                                    <TableCell
                                                        align={column.align}
                                                        style={{
                                                            backgroundColor:
                                                                type !== 3 && column.driver ? "#e9e9e9" : "default",
                                                            minWidth: column.minWidth,
                                                            maxWidth: column.maxWidth,
                                                            fontSize: 10,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {column.query ? (
                                                            <TableSortLabel
                                                                className={classes.tableSortLabel}
                                                                hideSortIcon={true}
                                                            >
                                                                {column.id === "time" ? (
                                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "stretch",
                                                                                alignItems: "stretch",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <div className={classes.tooltipWrapper}>
                                                                                <Tooltip title="Wyczyść">
                                                                                    <span>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            style={{ padding: 0 }}
                                                                                            onClick={() => clearDate()}
                                                                                            aria-label="visible columns"
                                                                                        >
                                                                                            <ClearIcon
                                                                                                fontSize="small"
                                                                                                className={
                                                                                                    classes.tooltipIcon
                                                                                                }
                                                                                            />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Sortuj">
                                                                                    <span>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            style={{ padding: 0 }}
                                                                                            onClick={() =>
                                                                                                createSortHandler(
                                                                                                    column,
                                                                                                    index,
                                                                                                    rows
                                                                                                )
                                                                                            }
                                                                                            aria-label="visible columns"
                                                                                        >
                                                                                            <ShowArrow
                                                                                                sorted={column.sorted}
                                                                                                order={column.order}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <Tooltip title={column.tooltip}>
                                                                                <span>
                                                                                    <Input
                                                                                        fullWidth
                                                                                        className={classes.headCell}
                                                                                        inputComponent={
                                                                                            SearchDatePicker
                                                                                        }
                                                                                        inputProps={{
                                                                                            style: {
                                                                                                height: "unset",
                                                                                                width: "100%",
                                                                                            },
                                                                                            onChange: (newValue) =>
                                                                                                handleDatePickerDateChange(
                                                                                                    newValue
                                                                                                ),
                                                                                            value: getDatePickerValue(),
                                                                                            format: "d MMMM",
                                                                                            InputProps: {
                                                                                                style: { fontSize: 12 },
                                                                                                disableUnderline: true,
                                                                                                placeholder:
                                                                                                    column.label,
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </ThemeProvider>
                                                                ) : (
                                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "stretch",
                                                                                alignItems: "stretch",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <div className={classes.tooltipWrapper}>
                                                                                <Tooltip title="Wyczyść">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        style={{ padding: 0 }}
                                                                                        onClick={() =>
                                                                                            handleClearColumn(column.id)
                                                                                        }
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ClearIcon
                                                                                            fontSize="small"
                                                                                            className={
                                                                                                classes.tooltipIcon
                                                                                            }
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                {column.id !== "billingModelName" ? (
                                                                                    <Tooltip title="Sortuj">
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            style={{ padding: 0 }}
                                                                                            onClick={() =>
                                                                                                createSortHandler(
                                                                                                    column,
                                                                                                    index,
                                                                                                    rows
                                                                                                )
                                                                                            }
                                                                                            aria-label="visible columns"
                                                                                        >
                                                                                            <ShowArrow
                                                                                                fontSize="small"
                                                                                                sorted={column.sorted}
                                                                                                order={column.order}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                            <Tooltip title={column.tooltip}>
                                                                                <span>
                                                                                    <Input
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        className={classes.headCell}
                                                                                        type={
                                                                                            column.type === "number"
                                                                                                ? "number"
                                                                                                : "text"
                                                                                        }
                                                                                        disabled={
                                                                                            column.id ===
                                                                                                "contractor" &&
                                                                                            !!contractor
                                                                                        }
                                                                                        defaultValue={
                                                                                            searchValues.find(
                                                                                                (v) =>
                                                                                                    v.id === column.id
                                                                                            )?.value
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleSearch(
                                                                                                column.id,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={handleOnEnterPress}
                                                                                        placeholder={column.label}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </ThemeProvider>
                                                                )}
                                                            </TableSortLabel>
                                                        ) : (
                                                            column.label
                                                        )}
                                                    </TableCell>
                                                )}
                                            </Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {sum && rows && rows.length > 0 && (
                                    <TableBody>
                                        <TableRow>
                                            {type === 1 ? (
                                                <>
                                                    <TableCell className={classes.sum} colSpan={3}></TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        SUMA ZA WYBRANY OKRES
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.sumKm?.toLocaleString("pl-PL", {
                                                            maximumFractionDigits: 0,
                                                        })}
                                                         km
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.normalRate?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.discount?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        %
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.rateAfterDiscount?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.kmCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.stopCount?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         godz.
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.stopCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.highway?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.sumCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.saveMoney?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell className={classes.sum} colSpan={7}></TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        SUMA ZA WYBRANY OKRES
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.normalRate?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.sumKm?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         km
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.costKm?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.waitingTime?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         godz.
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.waitingCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.highwayCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left">
                                                        {sum.sumCost?.toLocaleString("pl-PL", {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                         zł
                                                    </TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                    <TableCell className={classes.sum} align="left"></TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    </TableBody>
                                )}
                                <TableBody>
                                    {rows && rows.length > 0 ? (
                                        rows.map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" key={row.id}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        const { edit } = api[index];
                                                        const highwayCondition =
                                                            column.id === "highway" &&
                                                            api[index].items.contract?.isHighwayPay === false;
                                                        const discountCondition =
                                                            column.id === "discount" &&
                                                            api[index].items.contract?.isDiscount === false;
                                                        const waitingCondition =
                                                            (column.id === "stopCount" || column.id === "stopCost") &&
                                                            (!api[index].items.contract?.waitingRate ||
                                                                !api[index].items.contract?.countedFrom);

                                                        const disabled =
                                                            column.disabled ||
                                                            highwayCondition ||
                                                            discountCondition ||
                                                            waitingCondition;

                                                        return (
                                                            !column.hide && (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.edit && edit ? (
                                                                        column.id === "worker" ? (
                                                                            <Autocomplete
                                                                                options={employeeOptions}
                                                                                noOptionsText="Nie znaleziono pracowników"
                                                                                filterOptions={(options, state) => {
                                                                                    return options.filter((option) =>
                                                                                        option
                                                                                            .toLowerCase()
                                                                                            .trim()
                                                                                            .includes(
                                                                                                state.inputValue
                                                                                                    .toLowerCase()
                                                                                                    .trim()
                                                                                            )
                                                                                    );
                                                                                }}
                                                                                value={getEditedValue(
                                                                                    api[index]?.tempId,
                                                                                    column.id
                                                                                )}
                                                                                onChange={(event, value) =>
                                                                                    handleChangeEdit(
                                                                                        api[index].tempId,
                                                                                        column.id,
                                                                                        column.type,
                                                                                        value
                                                                                    )
                                                                                }
                                                                                renderInput={(params) => {
                                                                                    return <TextField {...params} />;
                                                                                }}
                                                                            />
                                                                        ) : column.id === "driverFullName" ? (
                                                                            <Autocomplete
                                                                                options={driverOptions}
                                                                                noOptionsText="Nie znaleziono kierowców"
                                                                                filterOptions={(options, state) => {
                                                                                    return options.filter((option) =>
                                                                                        option.fullName
                                                                                            .toLowerCase()
                                                                                            .trim()
                                                                                            .includes(
                                                                                                state.inputValue
                                                                                                    .toLowerCase()
                                                                                                    .trim()
                                                                                            )
                                                                                    );
                                                                                }}
                                                                                renderOption={(option) =>
                                                                                    option.fullName
                                                                                }
                                                                                value={getEditedValue(
                                                                                    api[index]?.tempId,
                                                                                    column.id
                                                                                )}
                                                                                onChange={(_event, value) => {
                                                                                    handleDriverEdit(
                                                                                        api[index].tempId,
                                                                                        value
                                                                                    );
                                                                                }}
                                                                                renderInput={(params) => {
                                                                                    return <TextField {...params} />;
                                                                                }}
                                                                            />
                                                                        ) : column.id === "connectedOrders" ? (
                                                                            <TextField
                                                                                onKeyDown={(e) =>
                                                                                    handleOnEnterPress(
                                                                                        e,
                                                                                        false,
                                                                                        api[index]
                                                                                    )
                                                                                }
                                                                                onChange={(event) => {
                                                                                    validateRelatedOrders(
                                                                                        event.target.value
                                                                                    );
                                                                                    handleChangeEdit(
                                                                                        api[index].tempId,
                                                                                        column.id,
                                                                                        column.type,
                                                                                        event.target.value
                                                                                    );
                                                                                }}
                                                                                disabled={disabled}
                                                                                value={getEditedValue(
                                                                                    api[index]?.tempId,
                                                                                    column.id
                                                                                )}
                                                                                size="small"
                                                                                error={isRelatedOrdersError}
                                                                            />
                                                                        ) : (
                                                                            <TextField
                                                                                onKeyDown={(e) =>
                                                                                    handleOnEnterPress(
                                                                                        e,
                                                                                        false,
                                                                                        api[index]
                                                                                    )
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleChangeEdit(
                                                                                        api[index].tempId,
                                                                                        column.id,
                                                                                        column.type,
                                                                                        event.target.value
                                                                                    )
                                                                                }
                                                                                placeholder={
                                                                                    column.type === "number" ? "0" : "-"
                                                                                }
                                                                                data-field-name={`${column.id}-${index}`}
                                                                                disabled={disabled}
                                                                                value={getEditedValue(
                                                                                    api[index]?.tempId,
                                                                                    column.id
                                                                                )}
                                                                                size="small"
                                                                                as
                                                                            />
                                                                        )
                                                                    ) : column.subtype === "currency" &&
                                                                      value !== "-" ? (
                                                                        `${value} zł`
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <div className={styles["rozliczenia__noData"]}>Brak danych</div>
                                    )}
                                </TableBody>
                            </Table>
                        </SimpleBar>
                        <TablePagination
                            className={classes.pagination}
                            labelRowsPerPage={"Liczba wierszy"}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            )}
            {showDetails ? (
                <RozliczeniaDetails
                    switchShowModal={switchShowModal}
                    refreshTable={refreshTable}
                    settlementId={api[index].items.id}
                    status={api[index].items.status}
                    courseDetails={api[index].items.courseDetails}
                    courseHistory={api[index].items.courseDetails.history}
                    type={type}
                />
            ) : null}
            {isSettlementPanelOpen && selectedSettlementOrderId && (
                <SettlementsPanel
                    isOpen={isSettlementPanelOpen}
                    isDispatcher={isDispatcher}
                    isContractorOperator={isContractorOperator}
                    onCloseClick={() => setIsSettlementPanelOpen(false)}
                    orderId={selectedSettlementOrderId}
                    onSettlementSaveSuccess={() => {
                        setIsSettlementPanelOpen(false);
                        refreshTable();
                    }}
                />
            )}
            {showConversation ? (
                <ConversationModal
                    switchShowModal={switchShowModal}
                    refreshTable={refreshTable}
                    settlementId={api[index].items.id}
                    status={api[index].items.status}
                />
            ) : null}
            {showInfoDialogCancel ? (
                <InfoDialog
                    alertReset={handleSwitchInfoDialog}
                    confirmAction={() => deleteData(id)}
                    message={
                        type === 1
                            ? "Czy na pewno chcesz usunąć to rozliczenie?"
                            : "Czy na pewno chcesz anulować to zlecenie?"
                    }
                />
            ) : null}
        </div>
    );
}
