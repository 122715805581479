export const mapDriver = (data) => {
  return {
    driverBillingId: data?.id,
    driverUsername: data?.driverUsername,
    driverFullName:
      data?.driverName || data?.driverSurname
        ? `${data?.driverName} ${data?.driverSurname}`
        : "-",
    driverOrderNo: data?.orderNo,
    driverSumKm: data?.sumKm,
    driverNormalRate: data?.normalRate,
    driverWaitingRate: data?.waitingRate,
    driverWaitingCost: data?.waitingCost,
    driverHighwayCost: data?.highwayCost,
    driverSumCost: data?.sumCost,
    driverBillingGroupName: data?.billingGroupName,
    driverWaitingTime: data?.waitingTime,
  };
};

export const mapDriverForDriverSettlements = (data) => {
  return {
    ...data,
    driverBillingId: data?.id,
    driverUsername: data?.driverUsername,
    driverFullName:
      data?.driverName || data?.driverSurname
        ? `${data?.driverName} ${data?.driverSurname}`
        : "-",
    driverOrderNo: data?.orderNo,
    driverSumKm: data?.sumKm,
    driverNormalRate: data?.normalRate,
    driverWaitingRate: data?.waitingRate,
    driverWaitingCost: data?.waitingCost,
    driverHighwayCost: data?.highwayCost,
    driverSumCost: data?.sumCost,
    driverBillingGroupName: data?.billingGroupName,
    driverWaitingTime: data?.waitingTime,
  };
};
