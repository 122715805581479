import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import MousePosition from "ol/control/MousePosition"
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, Style} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import whiteMarker from "../../../../img/marker_white.png"

export default class OsmMap extends React.Component {
    constructor(props) {
        super(props);

        this.width =
            typeof this.props.width !== "undefined" ? this.props.width : "100%";
        this.height =
            typeof this.props.height !== "undefined" ? this.props.height : "100%";
        this.center = [this.props.pickedLon, this.props.pickedLat];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 15;
        this.marker = whiteMarker;
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
            controls: [
                new MousePosition({
                    projection: 'EPSG:4326',
                })
            ],
        });

        let singleColor = [29, 187, 237, 0.8];
        this.createMarker(this.center, "location", singleColor);
    }

    componentDidUpdate() {
        let singleColor = [29, 187, 237, 0.8];
        if (this.props.ignoreForm === false) {
            this.moveMarker([this.props.pickedLon, this.props.pickedLat], "location", singleColor);
        }
    }

    search() {
    }

    createMarker(coords, markerId = "location", singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        feature.setId(markerId);

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    moveMarker(coords, markerId = "location", singleColor) {
        let feature = this.map
            .getLayers()
            .item(1)
            .getSource()
            .getFeatureById(markerId);

        if (feature !== null) {
            feature.setGeometry(
                new Point(transform(coords, "EPSG:4326", "EPSG:3857"))
            );
        } else {
            this.createMarker(coords, markerId, singleColor);
        }

        let view = this.map.getView();
        view.animate({
            center: transform(coords, "EPSG:4326", "EPSG:3857"),
            duration: 500,
        });
        view.animate({zoom: 15});
    }

    render() {
        return (<>
                <div
                    onDoubleClick={() => {
                        this.props.onClickOnMap(document.querySelector('.ol-mouse-position').textContent)
                    }}
                    id={"osm-map"}
                    style={{
                        width: this.width,
                        height: this.height,
                    }}
                ></div>
            </>
        );
    }
}
