import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function OrderDispatcher({ groupIndex, orderIndex, order, dispatchers, setOrderData }) {
    return (
        <Autocomplete
            options={dispatchers}
            onChange={(e, newValue) => setOrderData(groupIndex, orderIndex, "dispatcher", newValue)}
            getOptionLabel={(option) => `${option.user.name} ${option.user.surName}`}
            value={order.dispatcher.value}
            style={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        "data-id": `dispatcherSelect-orderIndex_${orderIndex}-groupIndex_${groupIndex}`,
                    }}
                    label="Dyspozytor"
                    variant="standard"
                    margin="none"
                    fullWidth
                />
            )}
        />
    );
}
