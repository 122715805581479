import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import RailyLogo from "../../../src/img/Raily_siwe_poziom.png";
import HttpClient from '../../HttpClient';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://www.sptech.pl/">
                SP Tech Solutions
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({

    paper: {
        outline: "0 !important",
        marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    field: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    formTitle: {
        textTransform: "uppercase",
        fontWeight: "600",
        marginBottom: "3px",
    },
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        color: "e5e5e5",
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(4, 0, 2),
        backgroundColor: "#1DBBED",
        marginRight: "10px",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
    container: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    routerLink: {
        color: "#47484b",
    }
}));

const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Uzupełnij to pole"),
    password: Yup.string().required("Uzupełnij to pole"),
});


export default function Login({loginRequest, resetPassword}) {
    const classes = useStyles();

    const setInvalidCredentials = (setFieldError) => {
        setFieldError('username', ' ');
        setFieldError('password', 'Nieprawidłowy login lub hasło');
    }

    useEffect(()=>{
        HttpClient.createRequest().logout();
    },[])

    return (
        <div>
            <Container maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <div className={classes.header}>
                        <div className={classes.logo}>
                            <img width="100%" src={RailyLogo} alt="logo"></img>
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            username: "",
                            password: ""
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={(values, {setFieldError}) => {
                            loginRequest(values, () => setInvalidCredentials(setFieldError));
                        }}
                    >
                        {({values, errors, touched, setFieldValue}) => (
                            <Form className={classes.form} noValidate>
                                <p className={classes.formTitle}>Logowanie</p>
                                <Field
                                    className={classes.field}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    helperText={
                                        (!!errors.username && errors.username !== " ") && touched.username ? errors.username : ""
                                    }
                                    error={!!errors.username && touched.username}
                                    label="Nazwa użytkownika"
                                    name="username"
                                    as={TextField}
                                />

                                <Field
                                    className={classes.field}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    helperText={
                                        !!errors.password && touched.password ? errors.password : ""
                                    }
                                    error={!!errors.password && touched.password}
                                    fullWidth
                                    name="password"
                                    label="Hasło"
                                    type="password"
                                    id="password"
                                    as={TextField}
                                />


                                <div className={classes.container}>
                                    <RouterLink to="/reset-password" className={classes.routerLink}>
                                        Przypomnij hasło
                                    </RouterLink>
                                </div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Zaloguj
                                </Button>

                            </Form>
                        )}
                    </Formik>

                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
