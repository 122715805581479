import React, {memo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {pl} from 'date-fns/locale';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getDate, isSameDay} from 'date-fns';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangeIcon from '@material-ui/icons/DateRange';
import CustomModal from "../customModal/CustomModal";
import { Typography } from '@material-ui/core';
import {format} from 'date-fns';
import {DatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";

const months = [];
for (let i = 0; i < 12; i++) {
    months.push(i);
}

const years = [];
for (let i = 2015; i < 2030; i++) {
    years.push(i);
}


const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pickersContainer: {
        display: 'flex',
        outline: 'none',
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    pickers: {
        display: 'flex',
        outline: 'none',
        backgroundColor: 'white',
        padding: "5px 0"
    },
    pickerHeader: {
        padding: "10px 0",
        fontWeight: 'bold'
    },
    dateRange: {
        padding: "5px 10px",
        fontSize: '15px'
    },
    root: {
        backgroundColor: '#e8e8e8',
        color: 'black',
        boxShadow: 'none',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    currentDay: {
        color: '#1DBBED'
    },
    selected: {
        backgroundColor: '#fff',
        border: 'none'
    },
    yearTab: {
        width: 100,
        minWidth: 100
    },
    monthsTab: {
        display: 'flex',
        alignItems: 'center',
    },
    tabs: {
        width: 40,
        minWidth: 40,
    },
    indicator: {
        backgroundColor: 'transparent'
    },
    maxHeight: {
        height: '100%'
    },
    wrapper: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    notchedOutline: {
        border: 0
    }
}));

function DateTabsToMemo({startDate, endDate, handleDateChange, handleMonthChange, handleYearChange, handleDayChange, daysInMonth, day, month, year}) {
    const classes = useStyles();
    const currentDay = getDate(new Date());
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showRangeBetweenDates = () => {
        if(isSameDay(startDate, endDate)) {
            return `${format(startDate, "d LLLL yyyy", {locale: pl})}`;
        }
        return `${format(startDate, "d LLLL yyyy", {locale: pl})} - ${format(endDate, "d LLLL yyyy", {locale: pl})}`;
    }

    return (
        <div>
            <CustomModal
                classes={classes.modal}
                open={open}
                onClose={handleClose}
            >
                <div className={classes.pickersContainer}>
                    <div className={classes.pickers}>
                        <div>
                            <Typography className={classes.pickerHeader} align="center">Początek zakresu</Typography>
                            <DatePicker
                                disableToolbar
                                autoOk
                                variant="static"
                                openTo="date"
                                value={startDate}
                                onChange={(date) => handleDateChange("start", date)}
                            />
                        </div>
                        <div>
                            <Typography className={classes.pickerHeader} align="center">Koniec zakresu</Typography>
                            <DatePicker
                                disableToolbar
                                autoOk
                                variant="static"
                                openTo="date"
                                value={endDate}
                                onChange={(date) => handleDateChange("end", date)}
                            />
                        </div>
                    </div>
                    <Button onClick={handleClose}>Zamknij</Button>
                </div>
            </CustomModal>
            <AppBar className={classes.root} position="static">
                <Tooltip
                    title="Szczegóły"
                    onClick={handleOpen}
                >
                <span>
                    <IconButton
                    >
                        <DateRangeIcon />
                    </IconButton>
                </span>
                </Tooltip>
                <FormControl className={classes.yearTab}>
                    <Select
                        value={year}
                        input={<OutlinedInput classes={{notchedOutline: classes.notchedOutline}} notched/>}
                        onChange={handleYearChange}
                    >
                        {years.map((year, index) => <MenuItem value={year} key={index}>{year}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl className={classes.yearTab}>
                    <Select
                        value={month}
                        input={<OutlinedInput classes={{notchedOutline: classes.notchedOutline}} notched/>}
                        onChange={handleMonthChange}
                    >
                        {months.map((mappedMonth, index) => <MenuItem value={mappedMonth}
                                                                      key={index}>{pl.localize.month(mappedMonth)}</MenuItem>)}
                    </Select>
                </FormControl>


                <Tabs className={classes.monthsTab} classes={{
                    scroller: classes.maxHeight,
                    indicator: classes.indicator,
                    flexContainer: classes.maxHeight
                }} value={!isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day)) ? null : day} onChange={handleDayChange}>
                    {daysInMonth.map((mappedDay, index) => <Tab
                        className={mappedDay === currentDay ? classes.currentDay : ''}
                        classes={{wrapper: classes.wrapper, root: classes.tabs, selected: classes.selected}}
                        value={mappedDay} key={index} label={mappedDay}/>)}
                </Tabs>
            </AppBar>
            <Typography className={classes.dateRange}>
                Wybrany zakres dat: {" "}
                <b>
                    {showRangeBetweenDates()}
                </b>
            </Typography>
        </div>
    );
}

export default memo(DateTabsToMemo, (prevProps, nextProps) => {
    return prevProps.year === nextProps.year &&
        prevProps.month === nextProps.month &&
        prevProps.day === nextProps.day &&
        prevProps.startDate === nextProps.startDate &&
        prevProps.endDate === nextProps.endDate &&
        prevProps.daysInMonth.length === nextProps.daysInMonth.length;
})
