import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, Style} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import whiteMarker from "../../../../../img/marker_white.png"

export default class DriversDetailsMap extends React.Component {
    constructor(props) {
        super(props);
        this.center = [19.479744, 52.068813];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6;
        this.marker = whiteMarker;
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.addresses && prevProps.addresses !== this.props.addresses) {
            let coords = [this.props.addresses.lon, this.props.addresses.lat];
            this.createMarker(coords, "location", [29, 187, 237, 0.8]);
        }
    }

    createMarker(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        feature.setId(markerId);

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    render() {
        return (
            <div
                id={"osm-map"}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
