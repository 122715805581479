import React, {Fragment} from "react";
import styles from "./formWorker.module.scss";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {styled} from "@material-ui/core/styles";
import AddWorkerForm from "./AddWorkerForm/AddWorkerForm";
import {DateTimePicker} from "@material-ui/pickers";
import {isWithinInterval, startOfMonth, endOfMonth, eachDayOfInterval, subMonths} from 'date-fns';
import produce from "immer";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const WorkerInput = styled(TextField)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const WorkDate = styled(DateTimePicker)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormWorker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            minDate: this.timeInterval().min,
            maxDate: this.timeInterval().max
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.data.worker !== nextProps.data.worker ||
            this.props.timeWork !== nextProps.timeWork ||
            this.props.drivers.length !== nextProps.drivers.length ||
            this.props.data.isProposition !== nextProps.data.isProposition;
    }

    timeInterval = () => {
        const now = new Date();
        const firstDay = startOfMonth(now);
        const lastDay = endOfMonth(now);
        const ninthDay = eachDayOfInterval({start: firstDay, end: lastDay})[9];
        const result = isWithinInterval(now,{start: firstDay, end: ninthDay});

        const dates = {
            min: null,
            max: null
        }

        dates.max = lastDay;

        if(result) {
            dates.min = subMonths(firstDay, 1);
        } else {
            dates.min = firstDay;
        }

        return dates;

    }


    updateWorker = (index, newWorker, cb) => {
        const worker = produce(this.props.data.worker, draftState => {
            draftState[index].person.value = newWorker;

            if (!this.props.data.isWorkAddress.value) {
                if (newWorker && newWorker.adres.length >= 1) {
                    draftState[index].address.value = newWorker.adres[0];
                } else {
                    draftState[index].address.value = null;
                }
            }
        });

        this.props.setOrderData(
            this.props.groupIndex,
            this.props.index,
            "worker",
            worker,
            cb
        );
    };

    addDriverFromForm = (driver) => {
        this.props.addNewWorker(driver);
    };

    openDriverModal = () => {
        this.setState({open: true});
    };

    closeDriverModal = () => {
        this.setState({open: false});
    };

    render() {
        return (
            <div className={styles["formWorker"]}>
                {this.props.data.worker.map((data, index) => {
                    return (
                        <Fragment key={index}>
                            <div className={styles["formWorker__input"]}>
                                <Autocomplete
                                    disabled={this.props.entity === 'pracownik'}
                                    options={this.props.drivers}
                                    getOptionLabel={(driver) =>
                                        `${driver.name} ${driver.surName}`
                                    }
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={data.person.value}
                                    onChange={(e, newValue) => {
                                        if (
                                            newValue !== null &&
                                            newValue.hasOwnProperty("type") &&
                                            newValue.type === "add"
                                        ) {
                                            this.openDriverModal();
                                        } else {
                                            this.updateWorker(index, newValue, this.props.handleValidate);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <WorkerInput
                                            {...params}
                                            error={
                                                data.person.empty && !this.props.data.isProposition
                                            }
                                            helperText={
                                                (data.person.empty && !this.props.data.isProposition) ? "Uzupełnij dane" : false
                                            }
                                            required={
                                                !this.props.data.isProposition
                                            }
                                            id="standard-error-helper-text"
                                            label="Pracownik"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles["formWorker__input"]}>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <WorkDate
                                        autoOk={false}
                                        format={"d.LLLL yyyy, HH:mm"}
                                        ampm={false}
                                        // minDate={this.state.minDate}
                                        // maxDate={this.state.maxDate}
                                        value={this.props.timeWork.value}
                                        onChange={(newValue) => {
                                            this.props.setOrderData(
                                                this.props.groupIndex,
                                                this.props.index,
                                                "timeWork",
                                                newValue,
                                                this.props.handleValidate
                                            );
                                        }}
                                        label="Wybierz datę"
                                        error={this.props.timeWork.empty && !this.props.data.isProposition}
                                        helperText={
                                            this.props.timeWork.empty && !this.props.data.isProposition
                                                ? "Nieprawidłowa data, wybierz nową datę"
                                                : false
                                        }
                                        required={!this.props.data.isProposition}
                                        fullWidth
                                    />
                                </ThemeProvider>
                            </div>

                        </Fragment>

                    );
                })}
                <AddWorkerForm
                    addDriverFromForm={this.addDriverFromForm}
                    closeDriverModal={this.closeDriverModal}
                    open={this.state.open}
                />
            </div>
        );
    }
}
