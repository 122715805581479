import React from "react";
import styles from "./orderPanel.module.scss";
import OrderLine from "./orderLine/OrderLine";
import OrderSummary from "./orderSummary/OrderSummary";
import axios from "axios";
import "moment/locale/pl";
import _ from "lodash";
import { addHours, addMinutes, getUnixTime, fromUnixTime, isWithinInterval, add } from "date-fns";
import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import InfoDialog from "../alerts/infoDialog/InfoDialog";
import ActionBlocker from "../alerts/actionBlocker/ActionBlocker";
import FormAlert from "../alerts/FormAlert";
import { v1 as uuidv1 } from "uuid";
import * as qs from "qs";
import produce from "immer";
import { MimicContext } from "../../../context";
import loadingScreen from "../../../img/orders_loading.gif";
import HttpClient from "../../../HttpClient";
import { checkConnectedOrders } from "../../../helper/checkConnectedOrders";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import generateOrderTimes from "../../../helper/generateOrderTimes";

const ConfirmButton = styled(Button)({
    backgroundColor: "#1DBBED",
    marginRight: "10px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const SimpleScroll = styled(SimpleBar)({
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
        {
            backgroundColor: "#b4b4b4",
        },
});

const AddOrderButton = styled(Button)({
    backgroundColor: "#b4b4b4",
    marginRight: "10px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#777777",
    },
});

export const INITIAL = "INITIAL";
export const NEW = "NEW";
export const DELETED = "DELETED";

export default class OrderPanel extends React.Component {
    static contextType = MimicContext;
    source = axios.CancelToken.source();

    constructor(props, context) {
        super(props, context);
        this.state = {
            ordersGroup: [this.buildEmptyOrdersGroup(false)],
            showOrderSummary: false,
            sending: false,
            error: "",
            done: false,
            submitted: false,
            orderData: [],
            contractors: [],
            // alerty front
            showAlert: false,
            isConnectedOrdersLoading: this.props.type === "edit",
            errorReturnOrder: false,
            errorAddAddress: false,
            errorTooManyWorkers: false,
            errorProposedOrder: false,
            errorWorker: false,
            emptyWorkerError: false,
            orderTimeError: false,
            showInfoDialog: false,
            showActionBlocker: false,
            stations: [],
            drivers: [],
            blockedDrivers: [],
            requestPending: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getDataDrivers();
        this.getDataStations();
        this.getDataContractors();
        this.props.type === "edit" && this.getConnectedOrders();
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    renameKey = (o, newKey, oldKey) => {
        delete Object.assign(o, { [newKey]: o[oldKey] })[oldKey];
    };

    cancelEdition = () => {
        this.props.history.push(
            "/app/" +
                this.props.entity +
                "/" +
                (this.props.entity === "dyspozytor" ? "transport" : "trasy") +
                "/aktywne"
        );
    };

    buildExistingWorker = (workers) => {
        const _workers = [];

        workers.map((worker) => {
            const _worker = produce(worker, (draftState) => {
                const userId = worker.userId;
                const driverId = worker.id;

                delete draftState.id;
                delete draftState.driverId;
                draftState.id = userId;
                draftState.driverId = driverId;

                this.renameKey(draftState, "surName", "surname");
                this.renameKey(draftState, "name", "firstname");
            });

            const address = {
                city: worker.city,
                flat: worker.flat,
                id: _worker.addressId,
                lat: worker.lat,
                lon: worker.lon,
                no: worker.no,
                street: worker.street,
                zipCode: worker.zipCode,
            };

            const emptyWorker = {
                status: INITIAL,
                person: {
                    value: _worker,
                    empty: null,
                    default: _worker,
                },
                address: {
                    value: address,
                    empty: null,
                    default: address,
                },
            };

            _workers.push(emptyWorker);
        });

        return _workers;
    };

    buildEmptyWorker = (status = INITIAL) => ({
        status,
        person: {
            value: null,
            empty: null,
            busy: false,
        },
        address: {
            value: null,
            empty: null,
        },
    });

    buildEmptyOrdersGroup = (isProposition = false, isReturn = false, isPropositionFromDestination = false) => ({
        orders: [
            this.props.type === "edit"
                ? this.buildExistingOrder(this.props.location.state.order)
                : this.buildEmptyOrder(isProposition, isReturn, isPropositionFromDestination),
        ],
    });

    buildExistingOrder = (
        order,
        isProposition = false,
        isReturn = false,
        isPropositionFromDestination = false,
        isMain = false
    ) => {
        return {
            id: order.id,
            isProposition,
            isReturn,
            isMain,
            isPropositionFromDestination,
            uuid: uuidv1(),
            worker: this.buildExistingWorker(order.worker),
            driver: null,
            busy: false,
            checkedOrders: [],
            contractor: {
                value: null,
            },
            dispatchers: [],
            dispatcher: {
                value: null,
            },
            isWorkAddress: {
                value: order.isWorkAddress,
                default: order.isWorkAddress,
            },
            destination: {
                value: order.direction ? order.to : order.from,
                default: order.direction ? order.to : order.from,
                empty: null,
            },
            direction: {
                value: order.direction,
                default: order.direction,
            },
            // dom -> praca
            timeWork: {
                value:
                    (order.direction && order.isDestinationTime) || (!order.direction && !order.isDestinationTime)
                        ? fromUnixTime(order.destinationTime)
                        : null,
                default:
                    (order.direction && order.isDestinationTime) || (!order.direction && !order.isDestinationTime)
                        ? fromUnixTime(order.destinationTime)
                        : null,
                empty: null,
                enabled: true,
            },
            timeHome: {
                value:
                    (order.direction && order.isDestinationTime) || (!order.direction && !order.isDestinationTime)
                        ? null
                        : fromUnixTime(order.destinationTime),
                default:
                    (order.direction && order.isDestinationTime) || (!order.direction && !order.isDestinationTime)
                        ? null
                        : fromUnixTime(order.destinationTime),
                empty: null,
                enabled: order.worker.length <= 1,
            },
            approximateTime: {
                value: null,
            },
            trainId: {
                empty: null,
                value: order.trainId || "",
                default: order.trainId || "",
            },
            trainDestination: {
                value: null,
                default: null,
                empty: null,
                shouldBeEmpty: false,
            },
            notes: {
                value: order.notes || "",
                default: order.notes || "",
            },
            orderNo: {
                value: order.orderNo || "",
                default: order.orderNo || "",
            },
            wasEdited: false,
            isConnected: !!(order.connectedOrders.length),
        };
    };

    buildEmptyOrder = (isProposition = false, isReturn = false, isPropositionFromDestination = false) => ({
        uuid: uuidv1(),
        isProposition: isProposition,
        isReturn: isReturn,
        isPropositionFromDestination: isPropositionFromDestination,
        worker: [this.buildEmptyWorker()],
        driver: null,
        busy: false,
        checkedOrders: [],
        dispatchers: [],
        dispatcher: {
            value: null,
        },
        contractor: {
            value: null,
        },
        isWorkAddress: {
            value: false,
        },
        destination: {
            value: null,
            empty: null,
        },
        direction: {
            value: true,
        },
        // dom -> praca
        timeWork: {
            value: null,
            empty: null,
            enabled: true,
            busy: false,
        },
        timeHome: {
            // value: new Date(),
            value: null,
            empty: null,
            enabled: true,
            busy: false,
        },
        approximateTime: {
            value: null,
        },
        trainId: {
            empty: null,
            value: "",
        },
        trainDestination: {
            value: null,
            empty: null,
            shouldBeEmpty: false,
        },
        notes: {
            value: "",
        },
        orderNo: {
            value: "",
        },
        wasEdited: false,
    });

    checkIsDefault = () => {
        return !this.state.ordersGroup.some((orderGroup) => {
            return orderGroup.orders.some((order) => {
                return this.checkIfOrderIsDefault(order);
            });
        });
    };

    checkIfOrderIsDefault = (order) => {
        return Object.keys(order).some((key) => {
            if (key === "worker") {
                return order[key].find(
                    (worker) =>
                        worker.person.default?.userId !== worker.person.value?.userId ||
                        worker.address.default?.id !== worker.address.value?.id ||
                        worker.status !== INITIAL
                );
            } else {
                if (key === "destination" || key === "trainDestination") {
                    return order[key].default?.id !== order[key].value?.id;
                } else if (order[key] !== null && order[key].hasOwnProperty("default")) {
                    if (order[key].default instanceof Date)
                        return order[key].default?.getTime() !== order[key].value?.getTime();
                    return order[key].default !== order[key].value;
                }
            }
        });
    };

    async getDataDrivers() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/pracownik/list", { cancelToken: this.source.token }, true);
            const drivers = [];
            response.data.map((driver) =>
                drivers.push({
                    ...driver.user,
                    driverId: driver.id,
                    companyName: driver.companyName,
                    companyId: driver.companyId,
                })
            );
            this.setState({ drivers });
        } catch (e) {}
    }

    getDataContractors = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/allKontrahents", { cancelToken: this.source.token }, true);
            const { data } = response;
            this.setState({ contractors: data });
        } catch (e) {}
    };

    getDataDispatchers = async (groupIndex, orderIndex) => {
        const httpRequest = HttpClient.createRequest();
        const ordersGroup = this.state.ordersGroup;
        try {
            const order = ordersGroup[groupIndex].orders[orderIndex];
            const { id } = order.contractor.value;
            const { data } = await httpRequest.get(`/api/dyspozytor/${id}`, { cancelToken: this.source.token }, true);
            const nextOrdersGroup = produce(ordersGroup, (draftState) => {
                draftState[groupIndex].orders[orderIndex].dispatchers = data;
            });
            this.setState({ ordersGroup: nextOrdersGroup });

            const worker = produce(order.worker, (draftState) => {
                draftState.forEach((worker) => {
                    worker.timeHome = { default: null, empty: null, enabled: true, value: null };
                    worker.timeWork = { default: null, empty: null, enabled: true, value: null };
                    worker.person = { busy: false, empty: null, value: null };
                    worker.address = { empty: null, value: null };
                    return worker;
                });
            });
            this.setOrderData(groupIndex, orderIndex, "worker", worker);
        } catch (e) {
            const nextOrdersGroup = produce(ordersGroup, (draftState) => {
                draftState[groupIndex].orders[orderIndex].dispatchers = [];
            });
            this.setState({ ordersGroup: nextOrdersGroup });

            const order = ordersGroup[groupIndex].orders[orderIndex];
            const worker = produce(order.worker, (draftState) => {
                draftState.forEach((worker) => {
                    worker.timeHome = { default: null, empty: null, enabled: true, value: null };
                    worker.timeWork = { default: null, empty: null, enabled: true, value: null };
                    worker.person = { busy: false, empty: null, value: null };
                    worker.address = { empty: null, value: null };
                    return worker;
                });
            });
            this.setOrderData(groupIndex, orderIndex, "worker", worker);
        }
    };

    createConnectedOrders(data) {
        const defaultOrder = this.buildExistingOrder(this.props.location.state.order);
        const connectedOrders =
            data.map((order) => {
                return this.buildExistingOrder(order);
            }) || [];

        const orders = [defaultOrder, ...connectedOrders];

        const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
            draftState[0].orders = orders;
        });

        this.setState({ ordersGroup, isConnectedOrdersLoading: false });
    }

    async getConnectedOrders() {
        const httpRequest = HttpClient.createRequest();
        try {
            const { id } = this.props.location.state.order;
            const response = await httpRequest.get(
                `api/order/find-connect/${id}`,
                { cancelToken: this.source.token },
                true
            );
            this.createConnectedOrders(response.data);
        } catch (e) {
            this.setState({ isConnectedOrdersLoading: false });
        }
    }

    async getDataStations() {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/addresses", { cancelToken: this.source.token }, true);
            this.setState({ stations: response.data.data });
        } catch (e) {}
    }

    alertReset = (value) => {
        this.setState(() => {
            return {
                errorWorker: value,
                emptyWorkerError: value,
                errorProposedOrder: value,
                showInfoDialog: value,
                errorTooManyWorkers: value,
                showActionBlocker: value,
                errorReturnOrder: value,
            };
        });
    };

    undoChanges = (field, groupIndex, orderIndex, workerIndex, cb) => {
        let value;
        const order = this.state.ordersGroup[groupIndex].orders[orderIndex];
        const worker = order.worker;
        if (field === "worker") {
            value = produce(worker, (draftState) => {
                draftState[workerIndex].status = INITIAL;
                draftState[workerIndex].person.value = draftState[workerIndex].person.default;
                draftState[workerIndex].address.value = draftState[workerIndex].address.default;
            });
        } else if (field === "address") {
            field = "worker";
            value = produce(worker, (draftState) => {
                draftState[workerIndex].status = INITIAL;
                draftState[workerIndex].person.value = draftState[workerIndex].person.default;
                draftState[workerIndex].address.value = draftState[workerIndex].address.default;
            });
        } else {
            value = order[field].default;
        }
        this.setOrderData(groupIndex, orderIndex, field, value, () => {
            this.handleValidate();
            cb && cb();
        });
    };

    removeOrderGroup = (groupIndex, orderIndex) => {
        const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
            if (orderIndex === 0) {
                draftState.splice(groupIndex, 1);
            } else {
                draftState[groupIndex].orders.splice(orderIndex, 1);
            }
        });
        this.setState({ ordersGroup }, () => {
            this.handleValidate();
        });
    };

    acceptOrderGroup = () => {
        const newOrdersGroup = this.buildEmptyOrdersGroup();
        this.setState({
            ordersGroup: [...this.state.ordersGroup, newOrdersGroup],
            submitted: false,
        });
    };

    removePerson = (groupIndex, orderIndex, workerIndex) => {
        let orderGroup = this.state.ordersGroup[groupIndex];
        let order = orderGroup.orders[orderIndex];

        if (this.props.type === "edit") {
            const worker = order.worker[workerIndex];
            if (worker.status === DELETED) {
                setTimeout(this.alertReset, 3000);
                this.setState({ errorWorker: true });
                return;
            }

            let count = 1;
            order.worker.forEach((worker) => worker.status === DELETED && count++);
            if (count === order.worker.length) {
                setTimeout(this.alertReset, 3000);
                this.setState({ errorWorker: true });
                return;
            }
        }

        if (order.worker.length === 1) {
            setTimeout(this.alertReset, 3000);
            this.setState({ errorWorker: true });
            return;
        }

        const nextWorker = produce(order.worker, (draftState) => {
            if (this.props.type !== "edit" || draftState[workerIndex].status === NEW) {
                draftState.splice(workerIndex, 1);
            } else {
                draftState[workerIndex].status = DELETED;
            }
        });

        const time = order.timeHome.value ? order.timeHome.value : order.timeWork.value;

        this.setOrderData(groupIndex, orderIndex, "worker", nextWorker, () => {
            if (!order.isWorkAddress.value) {
                this.setOrderData(groupIndex, orderIndex, "timeWork", time);
            }
        });
    };

    addPerson = (groupIndex, orderIndex) => {
        let orderGroup = this.state.ordersGroup[groupIndex];
        let order = orderGroup.orders[orderIndex];
        let newWorker = this.buildEmptyWorker(NEW);

        if (order.isWorkAddress.value) {
            newWorker.address.value = order.worker[0].address.value;
        }

        const nextWorker = produce(order.worker, (draftState) => {
            draftState.push(newWorker);
        });

        const time = order.timeHome.value ? order.timeHome.value : order.timeWork.value;

        if (order.worker.length > 3) {
            setTimeout(this.alertReset, 3000);
            this.setState({ errorTooManyWorkers: true });
        } else {
            this.setOrderData(groupIndex, orderIndex, "worker", nextWorker, () => {
                if (!order.isWorkAddress.value) {
                    this.setOrderData(groupIndex, orderIndex, "timeWork", time, () => {
                        this.handleValidate();
                    });
                }
            });
        }
    };

    confirmOrder = (groupIndex, orderIndex) => {
        const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
            draftState[groupIndex].orders[orderIndex].isProposition = false;
        });
        this.setState({ ordersGroup });
    };

    addReturnOrder = (groupIndex) => {
        const orderGroup = this.state.ordersGroup[groupIndex];
        const shouldAdd = orderGroup.orders.find((order) => order.isReturn);

        if (!shouldAdd) {
            const order = _.cloneDeep(orderGroup.orders[0]);
            const timeKey = order.timeHome.value ? "timeHome" : "timeWork";
            const timeValue = order[timeKey].value;
            const destinationValue = order.trainDestination.value
                ? order.trainDestination.value
                : order.destination.value;

            order.worker.forEach((worker) => {
                worker.address.value = worker.person.value?.adres[0] || null;
            });

            order.uuid = uuidv1();
            order.checkedOrders = [];
            order.isProposition = true;
            order.isReturn = true;
            order.destination.value = destinationValue;
            order.trainDestination.value = null;
            order.isWorkAddress.value = false;
            order.direction.value = false;
            order.timeHome.value = null;
            order.timeHome.enabled = !order.isWorkAddress;
            order.timeWork.value = addHours(timeValue, 12);
            order.notes.value = "";

            const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
                draftState[groupIndex].orders.splice(1, 0, order);
            });
            this.setState({ ordersGroup });
        } else {
            setTimeout(this.alertReset, 3000);
            this.setState({ errorReturnOrder: true });
        }
    };

    editExistingOrder = (field, order, value) => {
        if (field === "contractor") {
            order.contractor.value = value;
            order.dispatcher.value = null;
        }
        if (field === "dispatcher") {
            order.dispatcher.value = value;
        }
        if (field === "worker") {
            order.worker = _.cloneDeep(value);
        } else if (field === "isWorkAddress") {
            const enabled = !!(value || order.worker.length === 1);
            order.timeHome.enabled = enabled;
            order.timeWork.value = !enabled && order.timeHome.value ? order.timeHome.value : order.timeWork.value;
            order.timeHome.value = enabled && order.timeHome.value ? order.timeHome.value : null;
            order.isWorkAddress.value = value;
        } else if (field === "timeHome" && (order.worker.length < 2 || order.isWorkAddress.value)) {
            order.timeHome.value = value;
            order.timeHome.enabled = true;
            order.timeWork.value = null;
        } else if (field === "timeWork") {
            if (!order.isWorkAddress.value && order.worker.length > 1) {
                order.timeHome.value = null;
                order.timeHome.enabled = false;
                order.timeWork.busy = order.timeHome.busy;
                order.timeHome.busy = false;
                order.timeWork.value = value;
            } else if (order.isWorkAddress.value || order.worker.length === 1) {
                order.timeHome.value = null;
                order.timeHome.enabled = true;
                order.timeWork.value = value;
            }
        } else {
            order[field].value = value;
        }
        order.isProposition = false;
        return order;
    };

    editProposedOrder = (orderIndex, field, order, mainOrder, value) => {
        if (orderIndex === 0) {
            if (order.isProposition && order.isReturn) {
                if (field === "worker") {
                    order.worker = _.cloneDeep(value);
                    if (mainOrder.isWorkAddress.value) {
                        order.worker.forEach((worker) => {
                            worker.address.value = worker.person.value?.adres[0] || null;
                        });
                    }
                } else if (field === "trainDestination") {
                    if (value === null) {
                        // nie jestem pewny
                        order.destination.value = mainOrder.destination.value;
                    } else {
                        order.destination.value = value;
                    }
                } else if (field === "destination") {
                    order.destination.value =
                        mainOrder && mainOrder.trainDestination.value ? mainOrder.trainDestination.value : value;
                } else if (field === "timeHome") {
                    if (mainOrder && mainOrder.worker.length < 2) {
                        order.timeHome.value = addHours(value, 12);
                        order.timeHome.enabled = true;
                        order.timeWork.value = null;
                    } else if (mainOrder && mainOrder.isWorkAddress.value) {
                        order.timeHome.value = null;
                        order.timeHome.enabled = false;
                        order.timeWork.value = addHours(value, 12);
                    }
                } else if (field === "timeWork") {
                    if (mainOrder && mainOrder.worker.length > 1) {
                        order.timeHome.value = null;
                        order.timeHome.enabled = false;
                        order.timeWork.value = addHours(value, 12);
                    } else if (mainOrder && mainOrder.worker.length === 1) {
                        order.timeHome.value = null;
                        order.timeHome.enabled = true;
                        order.timeWork.value = addHours(value, 12);
                    }
                } else if (field === "orderNo") {
                    order.orderNo.value = value;
                } else if (field === "approximateTime") {
                    order.approximateTime.value = value;
                }
            } else if (order.isProposition && order.isPropositionFromDestination) {
                if (field === "destination") {
                    order.destination.value = value;
                } else if (field === "direction") {
                    order.direction.value = !value;
                    const time = order.timeHome.value ? order.timeHome.value : order.timeWork.value;
                    const timeKey = order.timeHome.value ? "timeHome" : "timeWork";
                    const reverseTimeKey = order.timeHome.value ? "timeWork" : "timeHome";
                    order[reverseTimeKey].value = time;
                    order[timeKey].value = null;
                } else if (field === "timeHome" && order.worker.length < 2) {
                    if (order.direction.value) {
                        order.timeHome.value = addMinutes(value, 5);
                        order.timeHome.enabled = true;
                        order.timeWork.value = null;
                    } else {
                        order.timeWork.value = null;
                        order.timeWork.enabled = true;
                        order.timeHome.value = null;
                    }
                } else if (field === "timeWork") {
                    if (order.direction.value) {
                        order.timeWork.value = null;
                        order.timeWork.enabled = order.worker.length === 1;
                        order.timeHome.value = null;
                    } else {
                        order.timeHome.value = null;
                        order.timeHome.enabled = order.worker.length === 1;
                        order.timeWork.value = addMinutes(value, 5);
                    }
                } else if (field === "orderNo") {
                    order.orderNo.value = value;
                } else if (field === "approximateTime") {
                    order.approximateTime.value = value;
                }
            }
        }
        return order;
    };

    addProposedOrder = (orderIndex, orderGroup, field) => {
        if (
            orderIndex === 0 &&
            field === "destination" &&
            this.props.type !== "edit" &&
            orderGroup.orders.length < 3 &&
            !orderGroup.orders[orderGroup.orders.length - 1].isPropositionFromDestination
        ) {
            const order = _.cloneDeep(orderGroup.orders[0]);

            const time = order.direction.value
                ? order.timeWork.value
                    ? "timeWork"
                    : "timeHome"
                : order.timeWork.value
                ? "timeHome"
                : "timeWork";
            const inverseTime = order.direction.value
                ? order.timeWork.value
                    ? "timeHome"
                    : "timeWork"
                : order.timeWork.value
                ? "timeWork"
                : "timeHome";
            order[time].value = order.timeWork.value ? addMinutes(order.timeWork.value, 5) : null;
            order[inverseTime].value = null;
            order.uuid = uuidv1();
            order.isProposition = true;
            order.checkDrivers = [];
            order.isPropositionFromDestination = true;
            order.worker = [this.buildEmptyWorker()];
            order.trainDestination.value = null;
            order.direction.value = !order.direction.value;
            if (order.isWorkAddress.value && order.direction.value) {
                order.direction.value = false;
            }
            order.isWorkAddress.value = false;
            order.notes.value = "";
            orderGroup.orders.push(order);
            return orderGroup.orders;
        }
        return orderGroup.orders;
    }; // dodawanie trzeciej propozycji na klikniecie pracy

    addProposedOrderLine = (groupIndex) => {
        const orderGroup = this.state.ordersGroup[groupIndex];
        const shouldAdd = orderGroup.orders.find((order) => order.isPropositionFromDestination);

        if (!shouldAdd) {
            const order = _.cloneDeep(orderGroup.orders[0]);
            const time = order.direction.value
                ? order.timeWork.value
                    ? "timeWork"
                    : "timeHome"
                : order.timeWork.value
                ? "timeHome"
                : "timeWork";
            const inverseTime = order.direction.value
                ? order.timeWork.value
                    ? "timeHome"
                    : "timeWork"
                : order.timeWork.value
                ? "timeWork"
                : "timeHome";
            order[time].value = order.timeWork.value ? addMinutes(order.timeWork.value, 5) : null;
            order[inverseTime].value = null;
            order.uuid = uuidv1();
            order.isProposition = true;
            order.checkDrivers = [];
            order.isPropositionFromDestination = true;
            order.worker = [this.buildEmptyWorker()];
            order.trainDestination.value = null;
            order.direction.value = !order.direction.value;
            if (order.isWorkAddress.value && order.direction.value) {
                order.direction.value = false;
            }
            order.isWorkAddress.value = false;
            order.notes.value = "";

            const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
                draftState[groupIndex].orders.push(order);
            });

            this.setState({ ordersGroup });
        } else {
            setTimeout(this.alertReset, 3000);
            this.setState({ errorProposedOrder: true });
        }
    };

    editOrders = (mappedIndex, orderIndex, order, ordersArray, field, value) => {
        if (mappedIndex === orderIndex) {
            ordersArray.push(this.editExistingOrder(field, order, value));
        } else if (order.isProposition) {
            ordersArray.push(this.editProposedOrder(orderIndex, field, order, ordersArray[0], value));
        } else {
            ordersArray.push(order);
        }
    };

    addWorkAddress = (value, field, groupIndex, orderIndex) => {
        this.setState({ stations: [...this.state.stations, value] }, () => {
            const order = this.state.ordersGroup[groupIndex].orders[orderIndex];
            if (order.isWorkAddress.value && field !== "destination") {
                const nextWorker = produce(order.worker, (draftState) => {
                    draftState.forEach((worker) => {
                        worker.address.value = value;
                    });
                });
                this.setOrderData(groupIndex, orderIndex, "worker", nextWorker);
            } else {
                this.setOrderData(groupIndex, orderIndex, "destination", value);
            }
        });
    };

    addWorkerAddress = (value, field = null, groupIndex, orderIndex, workerIndex) => {
        const driverIndex = this.state.drivers.findIndex((driver) => driver.driverId === value.driverId);
        const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
            draftState.forEach((orderGroup) => {
                orderGroup.orders.forEach((order) => {
                    order.worker.forEach((worker) => {
                        if (worker.person.value?.driverId === value.driverId) {
                            worker.person.value.adres.push(value);
                        }
                    });
                });
            });
            draftState[groupIndex].orders[orderIndex].worker[workerIndex].address.value = value;
        });

        const drivers = produce(this.state.drivers, (draftState) => {
            draftState[driverIndex].adres.push(value);
        });

        this.setState({ ordersGroup, drivers }, () => {
            let worker = this.state.ordersGroup[groupIndex].orders[orderIndex].worker;
            const _worker = produce(worker, (draftState) => {
                draftState[workerIndex].address.value = value;
            });
            this.setOrderData(groupIndex, orderIndex, "worker", _worker);
        });
    };

    addNewWorker = (groupIndex, orderIndex, workerIndex, value) => {
        const driver = {
            ...value.user,
            driverId: value.id,
            companyId: value.companyId,
            companyName: value.companyName,
        };
        this.setState({ drivers: [...this.state.drivers, driver] }, () => {
            const { worker } = this.state.ordersGroup[groupIndex].orders[orderIndex];
            const nextWorker = produce(worker, (draftState) => {
                draftState[workerIndex].person.value = driver;
                draftState[workerIndex].address.value = driver.adres[0] || [];
            });
            this.setOrderData(groupIndex, orderIndex, "worker", nextWorker, this.handleValidate);
        });
    };

    setOrderData = (groupIndex, orderIndex, field, value, cb) => {
        const ordersGroup = produce(this.state.ordersGroup, (draftState) => {
            const orderGroup = draftState[groupIndex];
            const orders = [];

            orderGroup.orders = this.addProposedOrder(orderIndex, orderGroup, field);

            orderGroup.orders.forEach((mappedOrder, mappedIndex) =>
                this.editOrders(mappedIndex, orderIndex, mappedOrder, orders, field, value)
            );

            orderGroup.orders = orders;
        });

        this.setState({ ordersGroup }, () => {
            if (cb) {
                cb();
            }
        });
    };

    disableWorkers = (order, option) =>
        order.worker.some((worker) => option.driverId === worker.person.value?.driverId);

    validateWorkers = (order, worker) => {
        let busy = false;
        const timeKey = order.timeHome.value ? "timeHome" : "timeWork";

        this.state.blockedDrivers.some((disabledWorker) => {
            const times = generateOrderTimes(order);
            if (
                order.id !== disabledWorker.orderId &&
                disabledWorker.userId === worker.person.value?.id &&
                order.uuid === disabledWorker.checkedOrderId &&
                (isWithinInterval(fromUnixTime(disabledWorker.startTime), {
                    start: times.startTime,
                    end: times.endTime,
                }) ||
                    isWithinInterval(fromUnixTime(disabledWorker.endTime), {
                        start: times.startTime,
                        end: times.endTime,
                    }) ||
                    (disabledWorker.startTime <= getUnixTime(times.startTime) &&
                        disabledWorker.endTime >= getUnixTime(times.endTime)))
            ) {
                busy = true;
                worker.person.busy = busy;
                order[timeKey].busy = busy;
            }
            return busy;
        });

        return busy;
    };

    resetBusyValues = () => {
        return produce(this.state.ordersGroup, (draftState) => {
            draftState.forEach((orderGroup) => {
                orderGroup.orders.forEach((order) => {
                    order.timeHome.busy = false;
                    order.timeWork.busy = false;
                    order.worker.forEach((worker) => {
                        worker.person.busy = false;
                    });
                });
            });
        });
    };

    isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    };

    validateDate = (order, worker, ordersGroup) => {
        let emptyInput = false;
        ordersGroup.forEach((orderGroup, _groupIndex) => {
            let busy = false;
            orderGroup.orders.forEach((_order, _orderIndex) => {
                if (order.uuid !== _order.uuid && !_order.checkedOrders.some((id) => id === order.uuid)) {
                    _order.worker.forEach((_worker) => {
                        const firstOrderTime = order.timeWork.value ? order.timeWork : order.timeHome;
                        const secondOrderTime = _order.timeWork.value ? _order.timeWork : _order.timeHome;

                        const hours = Math.floor(order.approximateTime?.value / 3600);
                        const minutes = Math.floor((order.approximateTime?.value - hours * 3600) / 60);

                        const durationObj = { hours, minutes };

                        if (
                            firstOrderTime.value &&
                            secondOrderTime.value &&
                            this.isValidDate(firstOrderTime) &&
                            this.isValidDate(secondOrderTime)
                        ) {
                            if (
                                isWithinInterval(firstOrderTime.value, {
                                    start: secondOrderTime.value,
                                    end: add(secondOrderTime.value, durationObj),
                                }) ||
                                isWithinInterval(secondOrderTime.value, {
                                    start: firstOrderTime.value,
                                    end: add(firstOrderTime.value, durationObj),
                                })
                            ) {
                                if (checkConnectedOrders(order, _order, worker, _worker)) {
                                    busy = true;
                                    firstOrderTime.busy = busy;
                                    secondOrderTime.busy = busy;
                                    worker.person.busy = busy;
                                    _worker.person.busy = busy;
                                    emptyInput = true;
                                }
                            }
                        }
                    });
                    order.checkedOrders.push(_order.uuid);
                }
            });
        });

        return emptyInput;
    };

    handleValidate = () => {
        let emptyInputs = false;
        const { roles } = this.context;

        if (this.state.submitted) {
            const ordersGroup = produce(this.resetBusyValues(), (draftState) => {
                draftState.forEach((orderGroup) => {
                    orderGroup.orders.forEach((item) => {
                        if (item.approximateTime.value === 0) {
                            this.context.addFlash(
                                "Wprowadzono nieprawidłowy adres, liczba kilometrów nie może być równa 0",
                                {
                                    variant: "error",
                                    preventDuplicate: true,
                                    key: "orderPanelWrongAddress",
                                }
                            );
                            emptyInputs = true;
                        }

                        item.checkedOrders = [];
                        if (!item.isProposition) {
                            const time = item.timeHome.value ?? item.timeWork.value;
                            const defaultTime = item.timeHome.default ?? item.timeWork.default;
                            const dateCondition =
                                this.props.type === "edit" && time.getTime() === defaultTime.getTime();
                            if (item.timeHome.value === null && item.timeWork.value === null) {
                                item.timeHome.empty = true;
                                item.timeWork.empty = true;
                                emptyInputs = true;
                            } else {
                                if (
                                    !dateCondition &&
                                    item.timeHome.value < new Date() &&
                                    !roles.includes("ROLE_SUPER_OPERATOR") &&
                                    item.timeHome.value !== null
                                ) {
                                    item.timeHome.empty = true;
                                    emptyInputs = true;
                                } else {
                                    item.timeHome.empty = false;
                                }

                                if (
                                    !dateCondition &&
                                    item.timeWork.value < new Date() &&
                                    !roles.includes("ROLE_SUPER_OPERATOR") &&
                                    item.timeWork.value !== null
                                ) {
                                    item.timeWork.empty = true;
                                    emptyInputs = true;
                                } else {
                                    item.timeWork.empty = false;
                                }
                            }

                            let busy = { status: false };

                            item.worker.forEach((worker) => {
                                if (worker.status !== DELETED) {
                                    if (worker.person.value === null) {
                                        worker.person.empty = true;
                                        emptyInputs = true;
                                    } else {
                                        worker.person.empty = false;
                                    }
                                    if (worker.address.value === null) {
                                        worker.address.empty = true;
                                        emptyInputs = true;
                                    } else {
                                        worker.address.empty = false;
                                    }
                                    if (
                                        this.validateDate(item, worker, draftState) ||
                                        this.validateWorkers(item, worker)
                                    ) {
                                        emptyInputs = true;
                                    }
                                }
                            });

                            if (item.destination.value === null) {
                                item.destination.empty = true;
                                emptyInputs = true;
                            } else {
                                item.destination.empty = false;
                            }
                        }
                    });
                });
            });
            this.setState({ ordersGroup });
        } else {
            emptyInputs = true;
        }
        if (emptyInputs) return false;
        emptyInputs = false;
        return true;
    };

    setStateOrderData = () => {
        //funkcja zbierająca wszystkie inputy i przerabiająca je na dane dla podsumowania i API
        const orderData = [];

        this.state.ordersGroup.map((orderGroup) => {
            orderGroup.orders.map((order) => {
                if (!order.isProposition) {
                    const workerTable = [];
                    const fullWorkerTable = [];
                    order.worker.map((item) => {
                        if (item.status !== DELETED) {
                            const fullSingleRecord = {
                                person: item.person.value,
                                address: item.address.value,
                            };

                            fullWorkerTable.push(fullSingleRecord);
                            const singleRecord = [
                                item.person.value.driverId,
                                item.address.value.id,
                                order.isWorkAddress.value,
                            ];
                            workerTable.push(singleRecord);
                        }
                    });

                    const time = order.timeHome.value ? order.timeHome.value : order.timeWork.value;

                    let isDestination = !!order.timeWork.value;

                    if (!order.direction.value) {
                        isDestination = !isDestination;
                    }

                    orderData.push({
                        fullWorkerAddressData: fullWorkerTable,
                        part: workerTable,
                        id: order.id,
                        dispatcher: order.dispatcher.value,
                        contractor: order.contractor.value,
                        worker: order.worker,
                        isWorkAddress: order.isWorkAddress.value,
                        pickup: order.direction.value ? order.worker[0].address.value : order.destination.value,
                        direction: order.direction.value,
                        destination: order.direction.value ? order.destination.value : order.worker[0].address.value,
                        time,
                        isDestination,
                        trainId: order.trainId.value,
                        trainDestination: order.trainDestination.value,
                        notes: order.notes.value,
                        orderNo: order.orderNo.value,
                        isConnected: order.isConnected
                    });
                }
            });
        });
        this.setState(() => {
            return {
                orderData,
            };
        });
    };

    sendData = async (isConnected = false) => {
        // Jeśli już się wysyła to nic nie rób.
        if (this.state.sending) return false;
        // A jak nie to zablokuj taką możliwość i wykonajz
        this.setState({ sending: true });
        const postData = {
            isConnected: isConnected,
            data: this.state.orderData.map((order) => {
                const newWorkers = [];
                const editedWorkers = [];
                const removedWorkers = [];

                if (this.props.type === "edit") {
                    order.worker.map((worker) => {
                        if (worker.status === NEW) {
                            newWorkers.push({ address: worker.address.value.id, worker: worker.person.value.driverId, isWorkAddress: order.isWorkAddress });
                        }
                        if (worker.status === DELETED) {
                            removedWorkers.push(worker.person.value.driverId);
                        }
                        if (
                            worker.status === INITIAL &&
                            (worker.person.value.id !== worker.person.default.id ||
                                worker.address.value.id !== worker.address.default.id)
                        ) {
                            editedWorkers.push({
                                address: worker.address.value.id,
                                isWorkAddress: order.isWorkAddress,
                                oldWorker: worker.person.default.driverId,
                                newWorker: worker.person.value.driverId,
                            });
                        }
                    });
                }

                return {
                    id: order.id,
                    part: order.part ? order.part : null,
                    newWorkers,
                    editedWorkers,
                    removedWorkers,
                    dispatcher: order.dispatcher?.id,
                    contractor: order.contractor?.id,
                    pickup: order.pickup ? order.pickup.id : null,
                    direction: order.direction ? order.direction : null,
                    destination: order.destination ? order.destination.id : null,
                    trainDestination: order.trainDestination ? order.trainDestination.id : null,
                    trainId: order.trainId,
                    isWorkAddress: order.isWorkAddress,
                    orderNo: order.orderNo ? order.orderNo : null,
                    isDestination: order.isDestination,
                    time: order.time ? order.time : null,
                    notes: order.notes,
                };
            }),
        };

        let url;
        let method;
        if (this.props.type === "edit") {
            url = "api/zlecenie-edit";
            method = "put";
        } else {
            url = "api/dyspozytor/transport/dodaj";
            method = "post";
        }

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.builder(
                method,
                url,
                { postData },
                { cancelToken: this.source.token },
                true
            );
            if (response.status) {
                this.setState({ done: true });
                this.props.history.push(
                    "/app/" +
                        this.props.entity +
                        "/" +
                        (this.props.entity === "dyspozytor" ? "transport" : "trasy") +
                        "/aktywne"
                );
            } else {
                this.setState({ error: response.error });
            }
        } catch (e) {
        } finally {
            this.setState({ sending: false });
        }
    };

    switchShowInfoDialog = () => {
        let currentTime = getUnixTime(new Date());
        const showInfoDialog = this.state.ordersGroup.some((orderGroup) => {
            return orderGroup.orders.some((order) => {
                if (!order.isProposition) {
                    let time = order.timeHome.value ? order.timeHome.value : order.timeWork.value;
                    let orderTime = getUnixTime(time);
                    let result = orderTime - currentTime;
                    return result < 7200;
                }
            });
        });
        this.setState({ showInfoDialog }, () => {
            if (!this.state.showInfoDialog) this.switchShowOrderSummary();
        });
    };

    switchShowOrderSummary = () => {
        this.setState(() => {
            return {
                showOrderSummary: !this.state.showOrderSummary,
                showInfoDialog: false,
                showActionBlocker: false,
            };
        });
    };

    addNew = () => {
        this.setState({
            orders: [this.buildEmptyOrder(true)],
            inputs: [this.buildEmptyInput()],
            showModal: false,
            sending: false,
            error: false,
            done: false,
        });
    };

    onSummaryValidationError = (data) => {
        this.setState({
            showOrderSummary: false,
        });

        this.setState({ blockedDrivers: data }, () => {
            if (this.handleValidate()) {
                this.setStateOrderData();
                this.switchShowInfoDialog();
            }
        });
    };

    checkDrivers = async () => {
        const { ordersGroup } = this.state;

        if (ordersGroup[0].orders.some((order) => !order.worker[0].person.value)) {
            setTimeout(this.alertReset, 2000);
            this.setState({ emptyWorkerError: true });
            return;
        }

        if (ordersGroup[0].orders.some((order) => !order.timeHome.value && !order.timeWork.value)) {
            setTimeout(this.alertReset, 2000);
            this.setState({ orderTimeError: true });
            return;
        }

        if (this.state.requestPending) {
            return;
        }
        let prefix = "api/isFree?";

        let orders = [];

        ordersGroup.forEach((orderGroup) => {
            orderGroup.orders
                .filter((order) => !order.isProposition)
                .forEach((order) => {
                    order.worker
                        .filter((worker) => worker.person.value?.id)
                        .forEach((worker) => {
                            const times = generateOrderTimes(order);

                            orders.push({
                                startTime: getUnixTime(times.startTime),
                                endTime: getUnixTime(times.endTime),
                                checkedOrderId: order.uuid,
                                worker: worker.person.value.id,
                            });
                        });
                });
        });

        this.setState({
            requestPending: true,
        });

        const httpRequest = HttpClient.createRequest();

        try {
            const response = await httpRequest.get(
                prefix,
                {
                    params: {
                        orders,
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                },
                true
            );

            this.setState({ blockedDrivers: response.data }, () => {
                if (this.handleValidate()) {
                    this.setStateOrderData();
                    this.switchShowInfoDialog();
                }
            });
            this.setState({
                requestPending: false,
            });
        } catch (e) {
            this.setState({
                requestPending: false,
            });
        }
    };

    handleSummary = (e) => {
        if (e) if (e.preventDefault) e.preventDefault();
        this.setState({ submitted: true }, () => {
            if (this.handleValidate()) {
                this.checkDrivers();
            }
        });
    };

    render() {
        let isConnected = this.state.isConnectedOrdersLoading ? "--loading" : "";
        return (
            <SimpleScroll style={{ maxHeight: "85vh" }}>
                <div className={styles["orderPanel__wrapper" + isConnected]}>
                    {this.state.isConnectedOrdersLoading ? (
                        <img height="80%" width="80%" src={loadingScreen} alt="Loading..." />
                    ) : (
                        <>
                            {this.state.ordersGroup.map((group, groupIndex, arr) =>
                                group.orders.map((data, index) => {
                                    return (
                                        <OrderLine
                                            key={index}
                                            index={index}
                                            contractors={this.state.contractors}
                                            dispatchers={data.dispatchers}
                                            entity={this.props.entity}
                                            groupIndex={groupIndex}
                                            data={data}
                                            type={this.props.type}
                                            undoChanges={this.undoChanges}
                                            groups={arr}
                                            handleValidate={this.handleValidate}
                                            drivers={this.state.drivers}
                                            disableWorkers={this.disableWorkers}
                                            validateDate={this.validateDate}
                                            stations={this.state.stations}
                                            setOrderData={this.setOrderData}
                                            addWorkAddress={this.addWorkAddress}
                                            addWorkerAddress={this.addWorkerAddress}
                                            removeOrderGroup={this.removeOrderGroup}
                                            confirmOrder={this.confirmOrder}
                                            addProposedOrderLine={this.addProposedOrderLine}
                                            getDataDispatchers={this.getDataDispatchers}
                                            addNewWorker={this.addNewWorker}
                                            openDriverModal={this.openDriverModal}
                                            closeDriverModal={this.closeDriverModal}
                                            acceptOrderGroup={this.acceptOrderGroup}
                                            addReturnOrder={this.addReturnOrder}
                                            lastLine={this.state.ordersGroup.length - 1 === groupIndex}
                                            setWorkerData={this.setWorkerData}
                                            removePerson={this.removePerson}
                                            addPerson={this.addPerson}
                                            errorWorker={this.state.errorWorker}
                                            orderGroupLength={this.state.ordersGroup.length}
                                        />
                                    );
                                })
                            )}
                            <div className={styles["orderPanel__buttonWrapper"]}>
                                <ConfirmButton
                                    variant="contained"
                                    type="submit"
                                    disabled={this.props.type === "edit" && this.checkIsDefault()}
                                    onClick={this.handleSummary}
                                    data-id="confirmationButton"
                                >
                                    Przejdź do potwierdzenia
                                </ConfirmButton>
                                {this.props.type === "edit" ? (
                                    <AddOrderButton variant="contained" onClick={this.cancelEdition}>
                                        Anuluj edycję
                                    </AddOrderButton>
                                ) : (
                                    <AddOrderButton
                                        variant="contained"
                                        onClick={() => {
                                            this.acceptOrderGroup();
                                        }}
                                        data-id="addNewOrderButton"
                                    >
                                        Dodaj nowe zamówienie
                                    </AddOrderButton>
                                )}
                            </div>
                            {this.state.showInfoDialog ? (
                                <InfoDialog
                                    alertReset={this.alertReset}
                                    confirmAction={this.switchShowOrderSummary}
                                    message="Krótki czas na realizację zlecenia! Sprawdź, czy data jest poprawna."
                                />
                            ) : null}
                            {this.state.errorReturnOrder && (
                                <FormAlert
                                    showAlert={true}
                                    severity="error"
                                    message={"Możesz dodać tylko jeden kurs powrotny"}
                                />
                            )}
                            {this.state.errorTooManyWorkers && (
                                <FormAlert
                                    showAlert={true}
                                    severity="error"
                                    message={"Możesz dodać maksymalnie czterech pracowników"}
                                />
                            )}
                            {this.state.errorProposedOrder && (
                                <FormAlert
                                    showAlert={true}
                                    severity="error"
                                    message={"Możesz dodać tylko jedno odwiezienie poprzedniej zmiany"}
                                />
                            )}
                            {this.state.errorWorker && (
                                <FormAlert
                                    showAlert
                                    severity="error"
                                    message="Nie można usunąć wszystkich pracowników"
                                />
                            )}
                            {this.state.emptyWorkerError && (
                                <FormAlert showAlert severity="error" message="Dodaj pracownika" />
                            )}
                            {this.state.orderTimeError && (
                                <FormAlert showAlert severity="error" message="Nie można dodawać zleceń bez daty" />
                            )}
                            {this.state.showOrderSummary ? (
                                <OrderSummary
                                    switchShowOrderSummary={this.switchShowOrderSummary}
                                    orderData={this.state.orderData}
                                    type={this.props.type}
                                    checkIsDefault={this.checkIsDefault}
                                    sending={this.state.sending}
                                    error={this.state.error}
                                    done={this.state.done}
                                    sendData={this.sendData}
                                    ordersGroup={this.state.ordersGroup}
                                    onValidationError={this.onSummaryValidationError}
                                />
                            ) : null}
                            {this.state.showActionBlocker ? (
                                <ActionBlocker
                                    alertReset={this.alertReset}
                                    message="Ten kierowca ma już zaplanowaną trasę"
                                    blockedDrivers={this.state.blockedDrivers}
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </SimpleScroll>
        );
    }
}
