import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

const SettlementsPanelPriceInput = (props) => {
    const [value, setValue] = useState(props.value ? String(props.value) : "");

    const onChange = (value) => {
        if (value === "") {
            setValue("");
            props.onChange(0);
            return;
        }

        if (value.charAt(value.length - 1) === "," && !value.includes(".")) {
            const newValue = value.replace(",", ".");
            setValue(newValue);
            return;
        }

        const priceValidationPattern = /^\d{0,8}(\.\d{0,2})?$/;

        if (priceValidationPattern.test(value)) {
            setValue(value);
            props.onChange(Number(value));
        }
    };

    useEffect(() => {
        if (props.value === Number(value)) {
            return;
        }
        setValue(props.value ? String(props.value) : "");
    }, [props.value]);

    return <TextField {...props} value={value} onChange={(e) => onChange(e.target.value)} />;
};

export default SettlementsPanelPriceInput;
