import React, {useContext, useEffect, useState} from 'react';
import HttpClient from "../../../HttpClient";
import { makeStyles } from "@material-ui/core/styles";
import Cookie from 'js-cookie';
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {usePersistedState} from "../../../usePersistedState";
import {MimicContext} from "../../../context";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    collapse: {
        marginRight: 10
    },
    tableContainer: {
        margin: 10,
        maxHeight: '80vh',
        overflowY: 'auto'
    }
});

function createData(type, users) {
    return {
        type,
        users: users?.map(user => ({name: user.name, surName: user.surName, id: user.id, contractorName: user.contractorName})) || []
    };
}

function Row(props) {
    const { row, mimicUser, classes } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell component="th" scope="row">
                    <IconButton
                        className={classes.collapse}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {row.type}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nazwa</TableCell>
                                        <TableCell>Nazwa kontrahenta</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.users.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {user.name} {user.surName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {user.contractorName || '-'}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {user.id || '-'}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => mimicUser(user.id)} color="primary">Podszyj się</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}



function MimicTable({users, mimicUser}) {

    const classes = useRowStyles();

    const rows = [
        createData('Kilometrówki', users.kilometrowkas),
        createData('Delegacje', users.delegations),
        createData("Administracja", users.administrators),
        createData("Dyspozytorzy", users.dispatchers),
        createData("Operatorzy", users.operators),
        createData("Kierowcy", users.drivers),
        createData("Pracownicy", users.workers)
    ];

    return (
        <div className={classes.tableContainer}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row classes={classes} mimicUser={mimicUser} key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}



const Mimic = () => {
    const {setOldToken} = useContext(MimicContext);
    const [users, setUsers] = useState([]);

    const getData = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/get_user_mimic', {}, true);
            setUsers(response.data);
        } catch (e) {}
    }

    const mimicUser = async (id) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`api/user_mimic/${id}`, {}, true);
            changeUser(response.data.token);
        } catch (e) {}
    }

    const changeUser = (token) => {
        Cookie.set('oldToken', Cookie.get('token'));
        setOldToken(Cookie.get('token'));
        Cookie.set('token', token);
        window.location.href = "/app";
    }

    useEffect(() => {
        getData();
    }, []);

    return <MimicTable mimicUser={mimicUser} users={users} />;
}

export default Mimic;
