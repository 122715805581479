export const getFields = (type) => {
    switch (type) {
        case 1: return settlementFields
        case 3: return driverFields
    }
}

const settlementFields = {
    date: {
        name: 'Data',
        width: 140,
        type: 'date',
        value: new Date()
    },
    contractor: {
        name: 'Kontrahent',
        width: 150,
        type: 'text',
        value: ""
    },
    worker: {
        name: 'Pracownik',
        width: 130,
        type: 'text',
        value: ""
    },
    route: {
        name: 'Trasa',
        width: 130,
        type: 'text',
        value: ""
    },
    orderNo: {
        name: 'Nr. zlecenia',
        width: 130,
        type: 'text',
        value: ""
    },
    number: {
      name: 'ID zlecenia',
      width: 130,
      type: 'number',
      value: ''
    },
    sumKm: {
        name: 'KM',
        width: 65,
        type: 'number',
        value: 0
    },
    normalRate: {
        name: 'Stawka',
        width: 65,
        type: 'number',
        value: 0
    },
    discount: {
        name: 'Rabat',
        width: 65,
        type: 'number',
        value: 0
    },
    rateAfterDiscount: {
        name: 'St. po rabacie',
        width: 110,
        disabled: true,
        type: 'number',
        value: 0
    },
    sumKmCost: {
        name: 'Koszt km',
        width: 100,
        type: 'number',
        disabled: true,
        value: 0
    },
    countStop: {
        name: 'Postój',
        width: 65,
        type: 'number',
        value: 0
    },
    stopCost: {
        name: 'Koszty postoju',
        width: 120,
        type: 'number',
        disabled: true,
        value: 0
    },
    highwayCost: {
        name: 'Autostrady',
        width: 85,
        type: 'number',
        value: 0
    },
    sumCost: {
        name: 'Cena trasy',
        width: 85,
        type: 'number',
        disabled: true,
        value: 0
    },
    saveMoney: {
        name: 'Oszczędność',
        width: 90,
        disabled: true,
        type: 'number',
        value: 0
    },
    comments: {
        name: 'Uwagi',
        width: 500,
        type: 'text',
        value: ""
    }
}

const driverFields = {
    date: {
        name: 'Data',
        width: 140,
        type: 'date',
        value: new Date()
    },
    contractor: {
        name: 'Kontrahent',
        width: 150,
        type: 'text',
        value: ""
    },
    billingGroupName: {
        name: 'Grupa rozliczeniowa',
        width: 200,
        type: 'text',
        value: ""
    },
    worker: {
        name: 'Pracownik',
        width: 130,
        type: 'text',
        value: ""
    },
    driverName: {
        name: 'Imię kierowcy',
        width: 130,
        type: 'text',
        value: ""
    },
    driverSurname: {
        name: 'Nazwisko kierowcy',
        width: 150,
        type: 'text',
        value: ""
    },
    route: {
        name: 'Trasa',
        width: 130,
        type: 'text',
        value: ""
    },
    number: {
        name: 'ID zlecenia',
        width: 130,
        type: 'number',
        value: ""
    },
    sumKm: {
        name: 'KM',
        width: 65,
        type: 'number',
        value: 0
    },
    normalRate: {
        name: 'Stawka',
        width: 65,
        type: 'number',
        value: 0
    },
    waitingRate: {
        name: 'Stawka/1h postoju',
        width: 200,
        type: 'number',
        value: 0
    },
    waitingCost: {
        name: 'Całkowity koszt postoju',
        width: 200,
        type: 'number',
        value: 0
    },
    sumKmCost: {
        name: 'Koszt km',
        width: 100,
        type: 'number',
        disabled: true,
        value: 0
    },
    highwayCost: {
        name: 'Autostrady',
        width: 85,
        type: 'number',
        value: 0
    },
    sumCost: {
        name: 'Cena trasy',
        width: 85,
        type: 'number',
        disabled: true,
        value: 0
    },
}