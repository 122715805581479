import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
    Backdrop,
    Modal,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField,
    Divider,
    Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import styles from "./combinedCourses.module.scss";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect } from "react";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 10,
        minWidth: 800,
        maxWidth: 800,
        minHeight: 300,
        margin: 5,
    },
    addButton: {
        backgroundColor: "#66D01D",
        padding: 6,
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
        "&:disabled": {
            backgroundColor: "#66D01D",
            opacity: 0.5,
        },
    },
    addButtonIcon: {
        color: "white",
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        padding: 6,
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    deleteButtonIcon: {
        color: "white",
    },
    selectWrapper: {
        display: "flex",
        padding: `0 16px`,
    },
    select: {
        width: `100%`,
        marginRight: 10,
    },
    listText: {
        width: `100%`,
        marginRight: 10,
    },
    addMenuItem: {
        display: `block`,
    },
    addMenuItemSubtitle: {
        fontSize: `0.875rem`,
        color: `rgba(0, 0, 0, 0.54)`,
    },
    divider: {
        margin: `35px 0`,
    },
    sectionTitle: {
        fontSize: 14,
        margin: `10px 16px 0 16px`,
    },
    acceptButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        padding: `0 16px`,
        marginTop: 16,
    },
    acceptButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    },
});

export const CombinedCourseModal = (props) => {
    const classes = useStyles();

    const [selectedCoursesToMerge, setSelectedCoursesToMerge] = useState([]);

    const [courseOptions, setCourseOptions] = useState([]);

    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);

    useEffect(() => {
        if (!props.isOpen) {
            return;
        }
        setSelectedCoursesToMerge([]);
        setSelectedCourse(null);
        setSelectedDriver(null);
    }, [props.isOpen]);

    const filteredSelectOptions = courseOptions.filter(
        (course) => !selectedCoursesToMerge.find((selectedCourse) => selectedCourse.orderId === course.orderId)
    );

    const getFormattedCourseInfo = (course) => {
        const formattedPickupDate = format(new Date(course.pickupDate), "dd.LL.yyyy, HH:mm", {
            locale: pl,
        });

        const formattedPickup = `Odbiór: ${course.pickup}, ${formattedPickupDate}`;

        const formattedDestinationDate = format(new Date(course.destinationDate), "dd.LL.yyyy, HH:mm", {
            locale: pl,
        });

        const formattedDestination = `Cel: ${course.destination}, ${formattedDestinationDate}`;

        return `ID: ${course.number}; ${formattedPickup}; ${formattedDestination}`;
    };

    const getFormattedCourseAdditionalInfo = (course) => {
        const driverInfo = course.driver ? `Kierowca: ${course.driver}; ` : ``;
        const contractorInfo = course.contractor ? `Kontrahent: ${course.contractor}` : ``;

        return `${driverInfo}${contractorInfo}`;
    };

    const generateDriverLabel = (driver, showAddress = false) => {
        const address =
            driver.user.adres &&
            !!driver.user.adres[0] &&
            `${driver.user.adres[0].street} ${driver.user.adres[0].city} ${driver.user.adres[0].zipCode}`;

        if (showAddress) {
            return (
                <div className={styles["unfillCourses__autoComplete"]}>
                    <span>
                        {driver.name ? driver.name : driver.user.name ? driver.user.name : "-"}{" "}
                        {driver.surName ? driver.surName : driver.user.surName ? driver.user.surName : "-"}
                    </span>
                    <span className={styles["unfillCourses__autoComplete--description"]}>
                        Stamina: {driver.user.stamina ? `${driver.user.stamina} godz.` : "-"}
                    </span>
                    <span className={styles["unfillCourses__autoComplete--description"]}>
                        {!!address ? address : "Brak adresu"}
                    </span>
                </div>
            );
        }
        return `${driver.name ? driver.name : driver.user.name ? driver.user.name : "-"} ${
            driver.surName ? driver.surName : driver.user.surName ? driver.user.surName : "-"
        }`;
    };

    const isSelectDisabled = !filteredSelectOptions.length;

    const onCourseSelect = (value) => {
        setSelectedCourse(value);
    };

    const onDeleteCourseButtonClick = (course) => {
        const filteredSelectedCoursesToMerge = selectedCoursesToMerge.filter((item) => item.orderId !== course.orderId);

        setSelectedCoursesToMerge(filteredSelectedCoursesToMerge);
    };

    const addCourseToSelectedToMerge = () => {
        setSelectedCoursesToMerge((curr) => [...curr, selectedCourse]);
        setSelectedCourse(null);
    };

    const onSelectAddButtonClick = addCourseToSelectedToMerge;

    const isAddButtonDisabled = !selectedCourse;

    const isSelectDriverSectionVisible = !!selectedCoursesToMerge.length;

    const driverOptions = props.drivers;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isOpen}
            onClose={props.onCloseClick}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <div className={classes.paper}>
                <div className={styles["unfillCourses__modal__description"]}>
                    <h2 className={styles["unfillCourses__modal__description--title"]}>Edycja połączeń kursów</h2>
                    <span className={styles["unfillCourses__modal__description--close"]} onClick={props.onCloseClick}>
                        X
                    </span>
                </div>
                <h2 className={classes.sectionTitle}>Lista powiązanych kursów</h2>

                <List>
                    {selectedCoursesToMerge.map((selectedCourse) => {
                        const title = getFormattedCourseInfo(selectedCourse);
                        const subtitle = getFormattedCourseAdditionalInfo(selectedCourse);

                        return (
                            <ListItem key={selectedCourse.orderId}>
                                <ListItemText className={classes.listText} primary={title} secondary={subtitle} />
                                <Tooltip title="Usuń">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        className={classes.deleteButton}
                                        onClick={() => onDeleteCourseButtonClick(selectedCourse)}
                                    >
                                        <DeleteIcon className={classes.deleteButtonIcon} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
                <div className={classes.selectWrapper}>
                    <Autocomplete
                        options={filteredSelectOptions}
                        fullWidth
                        getOptionLabel={(course) => {
                            const formattedCourseInfo = getFormattedCourseInfo(course);
                            const formattedCourseAdditionalInfo = getFormattedCourseAdditionalInfo(course);

                            return `${formattedCourseInfo} ${formattedCourseAdditionalInfo}`;
                        }}
                        renderOption={(option) => {
                            const title = getFormattedCourseInfo(option);
                            const subtitle = getFormattedCourseAdditionalInfo(option);

                            return (
                                <div>
                                    <div>{title}</div>
                                    <div className={classes.addMenuItemSubtitle}>{subtitle}</div>
                                </div>
                            );
                        }}
                        className={classes.select}
                        noOptionsText="Nie znaleziono kursów"
                        disabled={isSelectDisabled}
                        value={selectedCourse}
                        filterOptions={(options, params) => {
                            const filterOption = (option) => {
                                const title = getFormattedCourseInfo(option);
                                const subtitle = getFormattedCourseAdditionalInfo(option);

                                const trimmedInputValue = params.inputValue.trim();

                                const isFoundInTitle = title.toLowerCase().includes(trimmedInputValue);
                                const isFoundInSubtitle = subtitle.toLowerCase().includes(trimmedInputValue);

                                return isFoundInTitle || isFoundInSubtitle;
                            };

                            return options.filter(filterOption);
                        }}
                        onChange={(_event, selectedValue) => {
                            onCourseSelect(selectedValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={!!filteredSelectOptions.length ? `Wybierz kurs` : `Brak kursów`}
                            />
                        )}
                    />

                    <Tooltip title="Dodaj">
                        <IconButton
                            disabled={isAddButtonDisabled}
                            edge="end"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={onSelectAddButtonClick}
                        >
                            <AddIcon className={classes.addButtonIcon} />
                        </IconButton>
                    </Tooltip>
                </div>
                {isSelectDriverSectionVisible && (
                    <>
                        <Divider className={classes.divider} />
                        <h2 className={classes.sectionTitle}>Obsadź</h2>
                        <div className={classes.selectWrapper}>
                            <Autocomplete
                                options={driverOptions}
                                fullWidth
                                className={classes.select}
                                getOptionLabel={generateDriverLabel}
                                renderOption={(option) => generateDriverLabel(option, true)}
                                value={selectedDriver}
                                onChange={(_event, selectedValue) => setSelectedDriver(selectedValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={!!driverOptions.length ? `Wybierz kierowcę` : `Brak kierowców`}
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.acceptButtonWrapper}>
                            <Button size="small" className={classes.acceptButton} variant="outlined" onClick={() => {}}>
                                {"Akceptuj"}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
