import React, { memo } from "react";
import styles from "./orderWorker.module.scss";
import { IconButton, TextField } from "@material-ui/core";
import { StyledAutocomplete } from "../OrderLine";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/core/styles";
import AddWorkerModal from "./AddWorkerModal/AddWorkerModal";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import produce from "immer";
import { Undo as UndoIcon } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DELETED, INITIAL, NEW } from "../../OrderPanel";
import Cookie from "js-cookie";
import config from "../../../../../config";

const WorkerInput = styled(TextField)({
    width: 200,
    marginBottom: 0,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const WorkerToMemo = (props) => {
    let statusClass = "";
    const showDefault =
        props.type === "edit" &&
        (props.worker.person.value?.id !== props.worker.person.default?.id || props.worker.status === DELETED) &&
        props.worker.status !== NEW;

    if (props.worker.status !== INITIAL && props.type === "edit") {
        statusClass = props.worker.status === DELETED ? "__deleted" : "__new";
    } else {
        statusClass = showDefault ? "__touched" : statusClass;
    }

    return (
        <div className={styles["orderWorker__singleLine" + statusClass]}>
            <div className={styles["orderWorker__singleLine--input"]}>
                <StyledAutocomplete
                    disableClearable={props.worker.status === DELETED}
                    options={[...props.drivers].sort((a, b) => -b.companyName.localeCompare(a.companyName))}
                    groupBy={(option) => option.companyName}
                    filterOptions={(options, params) => {
                        let filtered = props.filter(options, params);
                        if (props.data.contractor.value)
                            filtered = filtered.filter((driver) => driver.companyId === props.data.contractor.value.id);
                        const user = JSON.parse(Cookie.get("loggedUser"));
                        if (!config.disableAddingWorker.includes(user.companyName)) {
                            filtered.unshift({
                                inputValue: params.inputValue,
                                name: `DODAJ`,
                                add: true,
                                surName: "PRACOWNIKA",
                            });
                        }
                        return filtered;
                    }}
                    getOptionLabel={(driver) => `${driver.name} ${driver.surName}`}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionDisabled={(option) =>
                        props.worker.status === DELETED ||
                        (option.add ? false : props.disableWorkers(props.data, option))
                    }
                    value={props.worker.person.value}
                    onChange={(e, newValue) => {
                        if (newValue?.name === "DODAJ" && newValue?.surName === "PRACOWNIKA") {
                            props.openDriverModal();
                        } else {
                            props.updateWorker(props.index, newValue, props.handleValidate);
                        }
                    }}
                    ListboxProps={{
                        "data-id": `workerSelectList-orderIndex_${props.orderIndex}-groupIndex_${props.groupIndex}-workerIndex_${props.index}`,
                    }}
                    renderInput={(params) => (
                        <WorkerInput
                            {...params}
                            error={(props.worker.person.empty || props.worker.person.busy) && !props.data.isProposition}
                            helperText={props.helperText(props.worker)}
                            required={!props.data.isProposition}
                            InputProps={{
                                ...params.InputProps,
                                "data-id": `workerSelect-orderIndex_${props.orderIndex}-groupIndex_${props.groupIndex}-workerIndex_${props.index}`,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {params.InputProps.endAdornment.props.children.map((item) => item)}
                                        {showDefault && (
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    props.undoChanges(
                                                        "worker",
                                                        props.groupIndex,
                                                        props.orderIndex,
                                                        props.index
                                                    )
                                                }
                                            >
                                                <UndoIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            id="standard-error-helper-text"
                            label="Pracownik"
                            variant="standard"
                            margin="normal"
                            fullWidth
                        />
                    )}
                />
            </div>
            <div className={styles["orderWorker__singleLine--buttonWrapper"]}>
                <button
                    className={styles["orderWorker__singleLine--buttonWrapper--addButton"]}
                    onClick={() => {
                        props.addPerson(props.groupIndex, props.orderIndex);
                    }}
                >
                    <AddIcon />
                </button>
                <button
                    disabled={
                        (props.worker.status === INITIAL && !props.worker.person.value) ||
                        props.worker.status === DELETED
                    }
                    className={styles["orderWorker__singleLine--buttonWrapper--removeButton"]}
                    onClick={() => {
                        props.removePerson(props.groupIndex, props.orderIndex, props.index);
                    }}
                >
                    <RemoveIcon />
                </button>
                <p className={styles["orderWorker__singleLine--buttonWrapper--description"]}>Dodaj/usuń pracownika</p>
            </div>
        </div>
    );
};

const Worker = memo(WorkerToMemo, (prevProps, nextProps) => {
    return prevProps === nextProps;
});

export default class OrderWorker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            workerIndex: null,
        };
    }

    updateWorker = (index, newWorker, cb) => {
        const worker = produce(this.props.data.worker, (draftState) => {
            draftState[index].person.value = newWorker;

            if (!this.props.data.isWorkAddress.value) {
                if (newWorker && newWorker.adres?.length >= 1) {
                    draftState[index].address.value = newWorker.adres[0];
                } else {
                    draftState[index].address.value = null;
                }
            }
        });

        this.props.setOrderData(this.props.groupIndex, this.props.index, "worker", worker, cb);
    };

    addDriverFromForm = (driver) => {
        this.props.addNewWorker(this.props.groupIndex, this.props.index, this.state.workerIndex, driver);
    };

    openDriverModal = (workerIndex) => {
        this.setState({ open: true, workerIndex });
    };

    closeDriverModal = () => {
        this.setState({ open: false });
    };

    helperText = (data) => {
        if (!this.props.data.isProposition) {
            if (data.person.empty) return "Uzupełnij dane";
            if (data.person.busy) return "Pracownik jest zajęty";
            return false;
        }
        return false;
    };

    render() {
        const filter = createFilterOptions();
        return (
            <div className={styles["orderWorker"]}>
                {this.props.data.worker.map((worker, index, workers) => (
                    <Worker
                        removePerson={this.props.removePerson}
                        handleValidate={this.props.handleValidate}
                        openDriverModal={() => this.openDriverModal(index)}
                        data={this.props.data}
                        disableWorkers={this.props.disableWorkers}
                        filter={filter}
                        type={this.props.type}
                        orderIndex={this.props.index}
                        updateWorker={this.updateWorker}
                        drivers={this.props.drivers}
                        undoChanges={this.props.undoChanges}
                        key={index}
                        groupIndex={this.props.groupIndex}
                        addPerson={this.props.addPerson}
                        helperText={this.helperText}
                        worker={worker}
                        index={index}
                        workers={workers}
                    />
                ))}
                <AddWorkerModal
                    addDriverFromForm={this.addDriverFromForm}
                    closeDriverModal={this.closeDriverModal}
                    contractor={this.props.data.contractor.value}
                    open={this.state.open}
                />
            </div>
        );
    }
}
