import React from "react";
import AppRoute from "../../../../AppRoute";
import KilometrowkiPanel from "../../../reusableComponents/kilometrowkaPanel/Formularz";

const FormularzRoute = () => {

    const Kilometrowki = (props) => <KilometrowkiPanel history={props.history} entity="pracownik" />

    return (
        <AppRoute
            app="pracownik"
            window="kilometrowki"
            tab="formularz"
            component={Kilometrowki}
        />
    );
};

export default FormularzRoute;
