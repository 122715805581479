import React from 'react';
import AppRoute from "../../../AppRoute";
import Mimic from "./Mimic";

const MimicRoute = () => {
    return <AppRoute
        app="admin"
        window="mimic"
        tab="change"
        component={Mimic}
    />
}

export default MimicRoute;
