import React from 'react';
import EditPerson from "../../../reusableComponents/editPerson/EditPerson";

const EditDriver = (props) => {
    return <EditPerson
      url="/api/kierowca"
      redirectURL="/app/operator/kierowcy/pokaz"
      label="Edytuj profil kierowcy"
      entity="Kierowca"
      {...props}
    />;
}

export default EditDriver;
