import React, { Fragment } from "react";
import FormularzRoute from "./formularz/FormularzRoute";
import ZestawieniaRoute from "./zestawienia/ZestawieniaRoute";

const KilometrowkiRoute = () => {
    return (
        <Fragment >
            <FormularzRoute />
            <ZestawieniaRoute />
        </Fragment>
    );
};

export default KilometrowkiRoute;
