import React, {useEffect, useState} from "react";
import styles from "./urlopy.module.scss";
import {makeStyles, styled} from "@material-ui/core/styles";
import {Button, createMuiTheme, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {DateTimePicker} from "@material-ui/pickers";
import axios from "axios";
import HttpClient from "../../../HttpClient";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {ThemeProvider} from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from '@material-ui/icons/Check';
import UrlopyTable from "./urlopyTable/UrlopyTable";
import {getUnixTime} from "date-fns";
import FormAlert from "../alerts/FormAlert";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const DriverField = styled(TextField)({
    width: 200,
    padding: 0,
    margin: "0 5px 15px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    singleInput: {
        marginRight: "30px",
    },
    addIcon: {
        backgroundColor: "#66D01D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        }
    },
    icon: {
        color: "white",
    },
    addNewDayOff: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    }
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}


const Urlopy = () => {
    const classes = useStyles();
    const [drivers, setDrivers] = useState([]);
    const [driverValue, setDriverValue] = useState(null);
    const [isPostedData, setIsPostedData] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [emptyDriver, setEmptyDriver] = useState(false);
    const [wrongDates, setWrongDates] = useState(false);
    const [showAddNewDayOff, setShowAddNewDayOff] = useState(false)
    const source = axios.CancelToken.source();

    const getData = async () => {
        window.scrollTo(0, 0);
        setIsPostedData(false);

        const apiDrivers = "api/kierowca";
        const requestDrivers = HttpClient.createRequest();

        try {
            const driversResponse = await requestDrivers.get(apiDrivers, {}, true);
            const driversTable = driversResponse.data.pracownicy;
            for (let i = 0; i < driversTable.length; i++) {
                driversTable.value = ''
            }
            setDrivers(driversTable);
        } catch (e) {
        }
    };

    const sendData = async () => {
        const postData = {
            userId: driverValue ? driverValue.user.id : null,
            startAt: getUnixTime(startDate),
            finishAt: getUnixTime(endDate),
        }

        if (postData.userId && postData.finishAt > postData.startAt) {
            const httpRequest = HttpClient.createRequest();
            try {
                await httpRequest.post('/api/day-off', postData, {}, true);
                setDriverValue(null);
                setStartDate(new Date());
                setEndDate(new Date());
                setIsPostedData(true);
            } catch (e) {
            }
        } else {
            !postData.userId ? setEmptyDriver(true) : setWrongDates(true)
            setTimeout(alertReset, 3000);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        return () => {
            source.cancel();
        };
    }, [isPostedData]);

    const alertReset = () => {
        setEmptyDriver(false);
        setWrongDates(false)
    };

    return (
        <>
            <SimpleBar style={{maxHeight: "85vh"}} className={classes.simplebar}>
                <div className={styles["urlopy"]}>
                    <div className={styles["urlopy__description"]}>
                        <h1 className={styles["urlopy__description--title"]}>
                            Zarządzaj urlopami kierowców
                        </h1>
                        <p className={styles["urlopy__description--subTitle"]}>
                            Podgląd, dodawanie i usuwanie dni urlopowych
                        </p>
                        <div
                            className={styles["urlopy__description--button"]}>
                            <Button
                                className={classes.addNewDayOff}
                                variant="outlined"
                                onClick={() => setShowAddNewDayOff(!showAddNewDayOff)}>{showAddNewDayOff ? "Ukryj panel" : "Dodaj nowy urlop"}</Button>
                        </div>
                    </div>

                    <div className={styles["urlopy__wrapper"]}>
                        {showAddNewDayOff ?
                            <div className={styles["urlopy__wrapper--inputs"]}>
                                <div className={styles["urlopy__wrapper--inputs--singleLine"]}>
                                    <Autocomplete
                                        value={driverValue}
                                        options={drivers}
                                        getOptionLabel={(option) => `${option.name ? option.name : option.user.name ? option.user.name : "-"} ${option.surName ? option.surName : option.user.surName ? option.user.surName : "-"}`}
                                        renderInput={(params) =>
                                            <DriverField
                                                {...params}
                                                label="Wybierz kierowcę"
                                                variant="standard"/>}
                                        onChange={(e, newValue) => {
                                            setDriverValue(newValue);
                                        }}
                                        className={classes.singleInput}
                                    />
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <DateTimePicker
                                            autoOk={false}
                                            format={"d.LLLL yyyy, HH:mm"}
                                            ampm={false}
                                            disablePast
                                            value={startDate}
                                            onChange={setStartDate}
                                            label="Start urlopu"
                                            className={classes.singleInput}
                                        />
                                        <DateTimePicker
                                            autoOk={false}
                                            format={"d.LLLL yyyy, HH:mm"}
                                            ampm={false}
                                            disablePast
                                            value={endDate}
                                            onChange={setEndDate}
                                            label="Koniec urlopu"
                                            className={classes.singleInput}
                                        />
                                    </ThemeProvider>
                                    < div className={styles["urlopy__wrapper--inputs--singleLine--buttonWrapper"]}>
                                        <BootstrapTooltip title="Zaakceptuj">
                                            <IconButton
                                                size="small"
                                                className={classes.addIcon}
                                                onClick={sendData}
                                            >
                                                <CheckIcon className={classes.icon}/>
                                            </IconButton>
                                        </BootstrapTooltip>
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div className={styles["urlopy__wrapper--dayList"]}>
                            <p className={styles["urlopy__wrapper--dayList--description"]}>
                                Zaplanowane urlopy
                            </p>
                            <UrlopyTable isPostedData={isPostedData}/>
                        </div>
                    </div>
                </div>
            </SimpleBar>
            {emptyDriver || wrongDates ? (
                <FormAlert
                    showAlert={true}
                    severity="error"
                    message={emptyDriver ? "Wybierz kierowcę" : "Data początku jest późniejsza niż końca urlopu"}
                />
            ) : null}
        </>
    );
}

export default Urlopy;