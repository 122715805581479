import React, {useState, useEffect} from "react";
import MessageInfo from "./MessageInfo";
import styles from './css/messageGroup.module.scss';
import Message from "./Message";

function MessageGroup(props) {
    const message = props.message

    const engRolesToPol = (engRole) => {
        switch (engRole) {
            case 'operator': return 'OPERATOR'; break;
            case 'operator_partner': return 'OPERATOR_PARTNER'; break;
            case 'operator_contractor': return 'OPERATOR_CONTRACTOR'; break;
            case 'dispatch':
            case 'dispatcher': return 'DYSPOZYTOR'; break;
            case 'driver': return 'KIEROWCA'; break;
        }
    }

    const isViewerHasSameMessageUserGroup = () => {
        let userRoles = []

        if (props.isRoleType('OPERATOR_CONTRACTOR'))
            userRoles = props.userRoles.filter((el) => el !== 'ROLE_DYSPOZYTOR')
        else userRoles = props.userRoles

        userRoles = userRoles.map((el) =>
            el === 'ROLE_ADMINISTRACJA' ? 'ROLE_DYSPOZYTOR' : el
        )
        return userRoles.find((el) => el === `ROLE_${engRolesToPol(message.authorGroup)}`)
    }
    const printSide = () => {
        if (isViewerHasSameMessageUserGroup())
            return 0
        return 1
    }
    const systemMessageType = () => {
        switch (message.authorGroup) {
            case 'system': return 'success'
            case 'system-error': return 'error'
            case 'system-warning': return 'warning'
            case 'system-info': return 'info'
            default: return false
        }
    }

    const isSystemMessage = () => {
        return (message.authorGroup.indexOf('system') > -1)
    }

    const messageGroupClass = `${styles.messageGroup} ${(isViewerHasSameMessageUserGroup() ? styles.myGroup: styles.otherGroup)}`

    if (!message)
        return null

    return (
        <div className={messageGroupClass} >
            { (props.newUser || isSystemMessage()) &&
                <>
                <div className={styles.divider} />
                <MessageInfo
                    data={{author: message.author, createTime: message.createTime}}
                    systemMessage={isSystemMessage() ? message.message : null}
                    systemMessageType={systemMessageType()}/>
                </>

            }
            {!isSystemMessage() &&
                <Message side={printSide()} message={message.message} />
            }

        </div>
    )

}

export default MessageGroup