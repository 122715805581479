import React, {useContext} from "react";
import styles from "./addKontrahentForm.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Modal, Button, Backdrop, Fade, TextField} from "@material-ui/core";
import {Formik, Form, Field, useField} from "formik";
import {NotificationContext} from "../../../../../../context";
import HttpClient from "../../../../../../HttpClient";

const useStyles = makeStyles((theme) => ({
    modal: {display: "flex", alignItems: "center", justifyContent: "center"},
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    paper: {
        outline: "0 !important",
        position: "absolute",
        width: "500px",
        height: "auto-fit",
        top: "95px",
        backgroundColor: "#ffffff",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    confirmButton: {
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
}));

const AddKontrahentForm = (props) => {
    //modal
    const {setNotification} = useContext(NotificationContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const timer = React.useRef();
    const handleClose = () => {
        props.handleToggleModal();
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const sendData = async (postData) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.post('api/dyspozytura-add/kontrahent', postData, {}, true);
            props.addKontrahent(response.data, props.groupIndex, props.orderIndex);
            props.handleToggleModal();
        } catch (e) {}
    };

    const handleButtonClick = async (postData) => {
        await sendData(postData);
    };

    const MyField = ({label, ...props}) => {
        const {params, name} = props;
        const [field, meta] = useField(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        if (name === "type") {
            return (
                <Field
                    {...params}
                    label={label}
                    {...field}
                    required={props.required}
                    className={classes.root}
                    helperText={errorText}
                    error={!!errorText}
                    variant="standard"
                    as={TextField}
                    margin="normal"
                    fullWidth
                />
            );
        } else {
            return (
                <Field
                    label={label}
                    {...field}
                    required={props.required}
                    className={classes.root}
                    helperText={errorText}
                    error={!!errorText}
                    variant="standard"
                    as={TextField}
                    margin="normal"
                    fullWidth
                />
            );
        }
    };

    return (
        <div className={styles["addKontrahentForm"]}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["addKontrahentForm__description"]}>
                            <h2 className={styles["addKontrahentForm__description--title"]}>Dodawanie nowego
                                kontrahenta</h2>
                            <span
                                className={styles["addKontrahentForm__description--close"]}
                                onClick={handleClose}
                            >
                {" "}
                                X
              </span>
                        </div>
                        <Formik
                            validateOnChange
                            initialValues={{
                                name: "",
                                nip: "",
                                regon: "",
                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Uzupełnij pole";
                                }
                                return errors;
                            }}
                            onSubmit={(data, {setSubmitting, resetForm}) => {
                                setSubmitting(true);
                                const postData = {
                                    name: data.name,
                                };
                                handleButtonClick(postData);
                                setSubmitting(false);
                                resetForm();
                            }}
                        >
                            {({values, isSubmitting, setFieldValue}) => (
                                <Form className={styles["addKontrahentForm"]} noValidate>
                                    <div className={styles["addKontrahentForm__inputs"]}>
                                        <div className={styles["addKontrahentForm__inputs--fieldWrapper"]}>
                                            <MyField
                                                label="Nazwa"
                                                name="name"
                                                required
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className={classes.confirmButton}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Potwierdź
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default AddKontrahentForm;
