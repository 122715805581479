import React, {useContext, useEffect, useState} from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";
import AppEntity from "./tabs/AppEntity/AppEntity";
import HttpClient from "../HttpClient";
import {MimicContext} from "../context";

export default function RenderRow(props) {
    const { classes } = props.data;
    const [apps, setApps] = useState([]);
    const {setLoggedUser} = useContext(MimicContext);

    const [open, setOpen] = useState(true);
    const toggleOpen = () => {
        setOpen(!open);
    };

    const fetchTabs = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/account', {}, true, false);
            setApps(Object.entries(response.data.apps));
            setLoggedUser(response.data.user);
        } catch (e) {}
    }

    useEffect(() => {
        fetchTabs();
    }, []);


    return (
        <div>
            <List
                style={{ padding: 0 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                onClick={toggleOpen}
            >
                {apps.map(([app, data]) => {
                    return (
                        <AppEntity
                            key={app}
                            app={app}
                            open={props.data.open}
                            alone={apps.length == 1}
                            data={data}
                            classes={classes}
                        />
                    );
                })}
                <ListItem
                    button={true}
                    onClick={toggleOpen}
                    className={classes.listItem}
                >
                    <ListItemText />
                </ListItem>
                <div className={classes.emptySpace}></div>
            </List>
        </div>
    );
}
