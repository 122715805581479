import React, {useEffect, useState} from "react";
import styles from "./orderDistance.module.scss";
import {Backdrop, Fab, Fade, Modal, IconButton} from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import {makeStyles, styled} from "@material-ui/core/styles";
import OrderMap from "./orderMap/OrderMap";
import HttpClient from "../../../../../HttpClient";
import Tooltip from "@material-ui/core/Tooltip";
import {getUnixTime} from "date-fns";
import config from "../../../../../config";

const ShowMapFab = styled(IconButton)({
    backgroundColor: "#b4b4b4",
    color: "white",
    transition: "0.3s",
    marginLeft: "20px",
    "&:hover": {
        backgroundColor: "#777777",
    },
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles(() => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        position: "fixed",
        top: "6vh",
        width: "80vw",
        height: "87vh",
        overflowX: "auto",
        overflowY: "auto",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
}));
export default function OrderDistance(props) {
    const classes = useStyles();
    const {data} = props;
    const [showMap, setShowMap] = useState(false);
    const [open, setOpen] = useState(true);
    const [totalTime, setTotalTime] = useState(null);
    const [totalKm, setTotalKm] = useState(null);
    const [routeData, setRouteData] = useState(null);
    const [shortTime, setShortTime] = useState("")

    const switchShowModal = () => {
        setShowMap(false);
    };

    const handleCounters = (km, time) => {
        const distance = `${Math.round(km / 1000)} km`;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const duration = `${hours === 0 ? "" : hours + " h"} ${minutes < 10 ? `0${minutes}` : minutes} min`;

        setTotalKm(distance);
        setTotalTime(duration);

        const unixCurrentTime = getUnixTime(new Date());
        const unixTimeWork = getUnixTime(data.timeWork.value);
        let difference = (unixCurrentTime + time) - unixTimeWork;
        if (data.direction.value && difference > 0) {
            setShortTime("--shortTime")
        } else setShortTime("");
    }

    const getRoutes = async () => {
        if (data.destination.value && data.worker[0].address.value) {
            const start = [data.destination.value.lon, data.destination.value.lat];
            let coordsPack = "";
            for (let i = 0; i < data.worker.length; i++) {
                let prevCoords = coordsPack;
                let coords = `;${data.worker[i].address.value?.lon},${data.worker[i].address.value?.lat}`;
                coordsPack = `${prevCoords}${coords}`;
            }

            const url = `${config.osrmURL}/route/v1/driving/${start[0]},${start[1]}${coordsPack}?steps=true&geometries=geojson&overview=full`;
            const httpRequest = HttpClient.createRequest();
            try {
                const response = await httpRequest.get(url);
                setRouteData(response.data);
                props.setOrderData(props.groupIndex, props.orderIndex, 'approximateTime', response.data.routes[0].duration);
                handleCounters(response.data.routes[0].distance, response.data.routes[0].duration)
            } catch (e) {
            }
        } else {
            setTotalKm(null);
            setTotalTime(null);
            setRouteData(null);
            setShortTime("");
        }
    }

    useEffect(() => {
        getRoutes();
    }, [data.worker, data.destination, data.direction, data.timeWork]);

    return (
        <div className={styles["orderDistance"]}>
            <div className={styles["orderDistance__buttonWrapper"]}>
                <p className={styles["orderDistance__buttonWrapper--title"]}>Dystans: <span
                    className={styles["orderDistance__buttonWrapper--data"]}>{totalKm ? totalKm : "-"}</span></p>
                <p className={styles["orderDistance__buttonWrapper--title"]}>Czas przejazdu: <span
                    className={styles["orderDistance__buttonWrapper--data" + shortTime]}>{totalTime ? totalTime : "-"} </span>
                </p>
            </div>
            <div className={styles["orderDistance__buttonWrapper"]}>
                <BootstrapTooltip title="Pokaż na mapie">
                    <ShowMapFab size="medium" onClick={() => {
                        setShowMap(!showMap);
                    }}><RoomIcon/></ShowMapFab>
                </BootstrapTooltip>
            </div>

            {showMap ? (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={switchShowModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{timeout: 500}}
                >
                    <Fade in={open}>
                        <div className={styles["orderDistance__map"]}>
                            <span className={styles["orderDistance__map--close"]} onClick={switchShowModal}>X</span>
                            <OrderMap
                                data={props.data}
                                routeData={routeData}
                            />
                        </div>
                    </Fade>
                </Modal>
            ) : null}
        </div>
    );
};
