import React, {memo} from "react";
import styles from "./orderNotes.module.scss";
import {IconButton, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Undo as UndoIcon} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
        width: 280,
        margin: "16px 0 0 0",
        padding: "1px",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
}));
const OrderNotes = (props) => {
    const classes = useStyles();

    const updateNotes = (e) => {
        props.setOrderData(props.groupIndex, props.index, "notes", e.target.value);
    };

    const updateOrderNo = (e) => {
        props.setOrderData(props.groupIndex, props.index, "orderNo", e.target.value);
    }

    const showDefault = (field) => {
        return props.type === 'edit' && props[field].value !== props[field].default;
    }

    const statusClass = showDefault('notes') || showDefault('orderNo') ? '__touched' : '';

    return (
        <div className={styles["orderNotes" + statusClass]}>
            <div className={styles["orderNotes__input"]}>
                <TextField
                    label="Nr zlecenia"
                    className={classes.root}
                    value={props.orderNo.value}
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showDefault('orderNo') && (
                                    <IconButton
                                        size="small"
                                        onClick={() => props.undoChanges('orderNo', props.groupIndex, props.index)}
                                    >
                                        <UndoIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    onChange={updateOrderNo}
                />
            </div>
            <div className={styles["orderNotes__input2"]}>
                <TextField
                    label="Uwagi"
                    className={classes.root}
                    value={props.notes.value}
                    disabled={props.type === 'edit'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showDefault('notes') && (
                                    <IconButton
                                        size="small"
                                        onClick={() => props.undoChanges('notes', props.groupIndex, props.index)}
                                    >
                                        <UndoIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    onChange={updateNotes}
                />
            </div>
        </div>
    );
};


export default memo(OrderNotes, (prevProps, nextProps) => {
    return prevProps.notes.value === nextProps.notes.value && prevProps.orderNo.value === nextProps.orderNo.value
});
