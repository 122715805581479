import React, { Fragment } from "react";
import DriversRoute from "./grafikKierowców/DriversRoute";
import AddDriverRoute from "./dodaj/AddDriverRoute";
import PokazRoute from "./pokaz/PokazRoute";
import EdytujRoute from "./edytuj/EdytujRoute";
import UrlopyRoute from "./urlopy/UrlopyRoute";

const KierowcyRoute = () => {
    return (
        <Fragment>
            <DriversRoute/>
            <PokazRoute/>
            <EdytujRoute/>
            <AddDriverRoute/>
            <UrlopyRoute/>
        </Fragment>
    );
};

export default KierowcyRoute;
