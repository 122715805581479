import React, {useEffect, useState} from "react";
import styles from "./singleDriverSchedule.module.scss";
import {format, fromUnixTime} from "date-fns";
import HttpClient from "../../../HttpClient";
import {pl} from "date-fns/locale";
import SingleCourseDetailsPopover from "./singleCourseDetailsPopover/SingleCourseDetailsPopover";
import axios from "axios";

export default function SingleDriverSchedule(props) {
    const {driver, pickupTime, getCoursesSchedule} = props;
    const source = axios.CancelToken.source();

    const [api, setApi] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopup, setOpenPopup] = useState(null);
    const [singleCourse, setSingleCourse] = useState(null);
    const [singleCourseId, setSingleCourseId] = useState(null);
    const [singleStart, setSingleStart] = useState(null);
    const [singleMeta, setSingleMeta] = useState(null);
    const [singleDayOff, setSingleDayOff] = useState(null);

    useEffect(() => {
        getData();
        return () => {
            source.cancel();
        };

    }, [driver, pickupTime]);

    useEffect(() => {
        singleCourseId && getSingleCourse();
    }, [singleCourseId]);

    const getSingleCourse = async () => {
        const url = `api/zlecenie/details/${singleCourseId}`
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {cancelToken: source.token}, true);
            setSingleCourse(response.data);
            setSingleDayOff(null);
        } catch (e) {
        }
    }
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopup(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getData = async () => {
        const startTime = pickupTime - 43200;
        const endTime = pickupTime + 43200;

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`api/zajeci-kierowcy?from=${startTime}&to=${endTime}&id=${driver}`, {}, true);
            setApi(response.data);
            if (getCoursesSchedule) {
                getCoursesSchedule(response.data[0].courses);
            }
        } catch (e) {
        }
    }

    //wyliczanie prawidłowych dat pod grafikiem
    const firstTime = props.pickupTime;
    const currentTime = fromUnixTime(firstTime);
    const previousTime = fromUnixTime(firstTime - 43200);
    const nextTime = fromUnixTime(firstTime - (-43200));

    return (
        <div className={styles["singleDriverSchedule"]}>
            {props.entity === "dyspozytor" &&
            <div className={styles["singleDriverSchedule__description"]}>
                <div className={styles["singleDriverSchedule__description--legend"]}>
                    <div className={styles["singleDriverSchedule__description--legend--legendItem"]}>
                        <div
                            className={styles["singleDriverSchedule__description--legend--legendItem--singleLegend--icon2"]}></div>
                        <p className={styles["singleDriverSchedule__description--legend--legendItem--singleLegend"]}>
                            W trasie
                        </p>
                    </div>
                    <div className={styles["singleDriverSchedule__description--legend--legendItem"]}>
                        <div
                            className={styles["singleDriverSchedule__description--legend--legendItem--singleLegend--icon"]}></div>
                        <p className={styles["singleDriverSchedule__description--legend--legendItem--singleLegend"]}>
                            Odpoczynek
                        </p>
                    </div>
                </div>
            </div>
            }
            <div className={styles["singleDriverSchedule__scheduleWrapper"]}>
                <div
                    className={styles["singleDriverSchedule__scheduleWrapper--hours--item"]}
                    style={{
                        left: `0`
                    }}
                >
                    {`${
                        (previousTime.getHours() < 10 ? "0" : "") +
                        previousTime.getHours()
                    }:${
                        (previousTime.getMinutes() < 10 ? "0" : "") +
                        previousTime.getMinutes()
                    }`}
                </div>
                <div
                    className={styles["singleDriverSchedule__scheduleWrapper--hours--item"]}
                    style={{
                        left: `50%`
                    }}
                >
                    {`${
                        (currentTime.getHours() < 10 ? "0" : "") +
                        currentTime.getHours()
                    }:${
                        (currentTime.getMinutes() < 10 ? "0" : "") +
                        currentTime.getMinutes()
                    }`}
                </div>
                <div
                    className={styles["singleDriverSchedule__scheduleWrapper--hours--item"]}
                    style={{
                        left: `100%`
                    }}
                >
                    {`${
                        (nextTime.getHours() < 10 ? "0" : "") +
                        nextTime.getHours()
                    }:${
                        (nextTime.getMinutes() < 10 ? "0" : "") +
                        nextTime.getMinutes()
                    }`}
                </div>


                <div className={styles["singleDriverSchedule__scheduleWrapper--schedule"]}>

                    {api && api[0].courses.map((item, index) => {
                        let start = item.startTime;
                        let end = item.endTime;
                        let routeWidth = (end - start) / 864
                        const startPosition = (start - (firstTime - 43200)) / 864;

                        return (
                            <>
                                <div
                                    id={`onRoute${index}`}
                                    className={styles["singleDriverSchedule__scheduleWrapper--schedule--onRoute"]}
                                    key={index}
                                    aria-describedby={id}
                                    onClick={(event) => {
                                        const startFormat = format(fromUnixTime(item.startTime), "d LLLL yyyy, HH:mm", {locale: pl,});
                                        const metaFormat = format(fromUnixTime(item.endTime), "d LLLL yyyy, HH:mm", {locale: pl,});
                                        setOpenPopup(!openPopup);
                                        setSingleStart(startFormat);
                                        setSingleMeta(metaFormat);
                                        setSingleCourseId(item.orderId);
                                        setSingleDayOff(null);

                                        handleClick(event);
                                    }}

                                    style={{
                                        left: startPosition + "%",
                                        width: routeWidth + "%",
                                    }}
                                ></div>
                            </>
                        )
                    })}
                    {props.entity !== "dyspozytor" && api && api[0].dayOff.map((item, index) => {
                        let start = item.startAt;
                        let end = item.finishAt;
                        let routeWidth = (end - start) / 864
                        const startPosition = (start - (firstTime - 43200)) / 864;
                        const startFormat = format(fromUnixTime(item.startAt), "d LLLL yyyy, HH:mm", {locale: pl,});
                        const metaFormat = format(fromUnixTime(item.finishAt), "d LLLL yyyy, HH:mm", {locale: pl,});

                        return (
                            <>
                                <div
                                    id={`dayOff${index}`}
                                    className={styles["singleDriverSchedule__scheduleWrapper--schedule--dayOff"]}
                                    key={index}
                                    aria-describedby={id}
                                    onClick={(event) => {
                                        setOpenPopup(!openPopup);
                                        setSingleDayOff(item);
                                        setSingleCourse(null);
                                        setSingleStart(startFormat);
                                        setSingleMeta(metaFormat);
                                        handleClick(event);
                                    }}
                                    style={{
                                        left: startPosition + "%",
                                        width: routeWidth + "%",
                                    }}
                                ></div>
                            </>
                        )
                    })}
                    {openPopup && props.entity !== "dyspozytor" &&
                    <SingleCourseDetailsPopover
                        entity={props.entity}
                        anchor={anchorEl}
                        handleClose={handleClose}
                        openPopup={openPopup}
                        course={singleCourse}
                        dayOff={singleDayOff}
                        start={singleStart}
                        meta={singleMeta}/>
                    }
                </div>
            </div>
        </div>
    )
}