import React, { useState, useContext, createContext } from "react";

import { getIn, useFormikContext } from "formik";

import HttpClient from "HttpClient";
import config from "config";

import {
    formatOrder,
    sortAddresses,
    sortAddressesReduce,
    formatDataForBuilder,
    statusType,
} from "app/reusableComponents/newOrderPanel/helpers";

const useOrderData = () => {
    const [orders, setOrders] = useState([]);
    const [joinedRoute, setJoinedRoute] = useState();
    const formik = useFormikContext();
    const [status, setStatus] = useState(statusType.IDLE)
    const isConnected = getIn(formik.values, "isConnected");

    const joinOrders = async (ordersRaw) => {
        setStatus(statusType.LOADING)
        const httpRequest = HttpClient.createRequest();
        // setJoinedRoute();
        if (ordersRaw.length <= 1) {
            formik.setFieldValue("IsConnected", false);
            setStatus(statusType.ERROR)
            return false;
        } //Display info that you cant join one order or smth

        const allAddresses = ordersRaw.reduce((acc, currentOrder) => {
            return [
                ...acc,
                ...currentOrder.workers.reduce(sortAddressesReduce, []),
                { ...currentOrder.destination, timeOrigin: currentOrder.timeDestination },
            ];
        }, []);

        if(!allAddresses.find((address) => address.timeOrigin)) return  setStatus(statusType.ERROR)

        const dataForBuilder = ordersRaw.reduce((acc, currentOrder) => {
            if (currentOrder.destination === null) return acc;
            const formattedAddresses = sortAddresses(currentOrder);
            const indexOfBaseTime = formattedAddresses.findIndex((elem) => !!elem.timeOrigin);

            const workersAddresses = currentOrder.workers.reduce(sortAddressesReduce, []);
            const dividedAddresses = {
                workers: workersAddresses,
                station: { ...currentOrder.destination, timeOrigin: currentOrder.timeDestination },
            };

            const postData = postBuilderData(
                dividedAddresses,
                currentOrder.isChangedDirection,
                formattedAddresses[indexOfBaseTime]?.timeOrigin ?? new Date()
            );
            return [...acc, postData];
        }, []);

        if (dataForBuilder.length === 0) {
            setStatus(statusType.ERROR)    
            return false;
        }
        const { data: stagedAddresses } = await httpRequest.post(
            `${config.stageBuilder}/build_from_wishes`,
            dataForBuilder,
            {},
            true
        );
        const formattedData = await formatOrder(allAddresses, stagedAddresses);
            //console.log(allAddresses)
            //console.log(stagedAddresses)
        setJoinedRoute({
            address: formattedData,
        });
        setStatus(statusType.IDLE)
        return formattedData ? true : false;
    };

    const postBuilderData = (dividedAddresses, isChangedDirection, targetTime) => {
        const { reducedWork, reducedWorker } = formatDataForBuilder(dividedAddresses);
        if (reducedWorker.length === 0) return;
        const preparedData = {
            dst: isChangedDirection ? reducedWorker : reducedWork,
            src: !isChangedDirection ? reducedWorker : reducedWork,
        };

        const targetIndexDst = preparedData.dst.findIndex((address) => !!address.time);
        const targetIndexSrc = preparedData.src.findIndex((address) => !!address.time);

        const postData = {
            target_time: new Date(targetTime).getTime(),
            ...preparedData,
            target_index: targetIndexDst === -1 ? (targetIndexSrc === -1 ? 0 : targetIndexSrc) : targetIndexDst,
            direction: targetIndexDst === -1 ? 0 : 1, //WARNING 0 - src, 1 - dst
        };

        return postData;
    };

    //targetTime picked data by user
    const fetchStageBuilderRoute = async (indexOfBaseTime, isChangedDirection, sortedAddress, dividedAddresses) => {
        const httpRequest = HttpClient.createRequest();
        const timeOrigin = sortedAddress[indexOfBaseTime]?.timeOrigin;

        if (!timeOrigin) return null;

        const postData = postBuilderData(dividedAddresses, isChangedDirection, timeOrigin);

        const res = await httpRequest.post(`${config.stageBuilder}/build_from_wishes`, [postData], {}, true);
        return res.data;
    };

    const setOrderRoute = async (sortedAddress, dividedAddresses, orderIndex, isChangedDirection) => {
        setStatus(statusType.LOADING)
        if (!dividedAddresses.station?.id) return setStatus(statusType.ERROR);

        const indexOfBaseTime = sortedAddress.findIndex((elem) => !!elem.timeOrigin);
        const stagedAddresses = await fetchStageBuilderRoute(
            indexOfBaseTime,
            isChangedDirection,
            sortedAddress,
            dividedAddresses
        );

        const formattedData = await formatOrder(sortedAddress, stagedAddresses);
        if (!formattedData) return setStatus(statusType.ERROR);

        setOrders((prev) => {
            prev[orderIndex] = {
                address: formattedData,
            };
            
            return [...prev];
        });
        setStatus(statusType.IDLE)
    };

    const removeOrderRoute = (orderIndex) => setOrders((prev) => prev.filter((_, index) => index !== orderIndex));

    const routeDate = isConnected
        ? joinedRoute?.address.routeData
        : orders.reduce((acc, order) => [...acc, { ...order?.address?.routeData }], []);

    const routeWaypoints = isConnected
        ? joinedRoute?.address.locations
        : orders.reduce((acc, order) => {
              if (!order?.address) return acc;
              return [...acc, [...order?.address?.locations]];
          }, []);

    return {
        status,
        orders,
        routeWaypoints,
        routeDate,
        joinedRoute,
        joinOrders,
        removeOrderRoute,
        setOrderRoute,
    };
};

const OrderDataContext = createContext({});

export const useOrderDataContext = () => {
    const ctx = useContext(OrderDataContext);
    if (!ctx) throw new Error("useOrderDataContext must be used within OrderDataProvider");
    return ctx;
};

export const OrderDataProvider = ({ children }) => {
    return <OrderDataContext.Provider value={useOrderData()}>{children}</OrderDataContext.Provider>;
};
