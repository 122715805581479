import React, { useContext } from "react";
import FormularzRoute from "./formularz/FormularzRoute";
import ZestawieniaRoute from "./zestawienia/ZestawieniaRoute";
import { MimicContext } from "../../../context";

const KilometrowkiRoute = () => {
  const { roles } = useContext(MimicContext);
  const kilometrowkiZapisRole = ["ROLE_KILOMETROWKA_ZAPIS"];
  const kilometrowkiRole = [...kilometrowkiZapisRole, "ROLE_KILOMETROWKA"];

  const hasReadAccess = roles.some((role) => kilometrowkiRole.includes(role));
  const hasSaveAccess = roles.some((role) =>
    kilometrowkiZapisRole.includes(role)
  );

  return (
    <>
      {hasSaveAccess && <FormularzRoute /> }
      {hasReadAccess && <ZestawieniaRoute /> }
    </>
  );
};

export default KilometrowkiRoute;
