import {
  createMuiTheme,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Input from "@material-ui/core/Input";
import { makeStyles, styled } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import {
  endOfDay,
  endOfMonth,
  fromUnixTime,
  getDate,
  getDaysInMonth,
  getMonth,
  getUnixTime,
  getYear,
  isDate, isSameDay,
  startOfDay,
  startOfMonth,
} from "date-fns";
import produce from "immer";
import * as qs from "qs";
import React, { Fragment, useEffect, useState, useContext } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { downloadCsv } from "./downloadCsv";
import { convertNumberToArray } from "../../../helper/convertNumberToArray";
import HttpClient from "../../../HttpClient";
import Confirmation from "../../reusableComponents/kilometrowkiZestawienie/confirmation/Confirmation";
import DateTabs from "../../reusableComponents/orders/Tabs";
import ProgressLine from "../../reusableComponents/progressLine/ProgressLine";
import styles from "./styles.module.scss";
import { MimicContext } from "../../../context";
import {chunkCallable} from "../../../helper/chunk";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
});

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: "#313131",
  },
  tooltip: {
    backgroundColor: "#313131",
    color: "white",
    fontSize: "13px",
    letterSpacing: "1px",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles({
  simplebar: {
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
      backgroundColor: "#b4b4b4",
    },
  },

  pagination: {
    width: "fit-content",
    "& .MuiTablePagination-toolbar": {
      padding: "0 10px 5px 10px !important",
    },
  },
  root: {
    color: "#47484B",
    fontFamily: "'Open Sans', sans-serif",
    border: "none !important",
    "& .MuiTable-root": {
      width: "auto",
    },
    "& .MuiTableCell-head": {
      padding: "2px 15px 0 0",
      fontSize: "12px",
      fontFamily: "'Open Sans', sans-serif",
    },
    "& .MuiTableCell-body": {
      padding: "2px 15px 2px 0",
      fontSize: "12px",
      fontFamily: "'Open Sans', sans-serif",
    },
    "& .MuiTabs-fixed": {
      overflowX: "auto !important",
    },
  },
  icon: {
    color: "white",
  },
  pending: {
    color: "#b4b4b4",
  },
  accepted: {
    color: "#66D01D",
  },
  rejected: {
    color: "red",
  },
  tableContainer: {
    height: "550px",
  },
  tableHead: {
    backgroundColor: "rgba(243, 243, 243, 0.7)",
    boxShadow: "none",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
      color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1DBBED",
      },
      "&:hover fieldset": {
        borderColor: "#1DBBED",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1DBBED",
      },
    },
  },
  tableSortLabel: {
    display: "flex",
    alignItems: "center",
  },
  headCell: {
    width: 180,
    padding: 0,
    margin: 0,
    "& $headCellArrow": {
      opacity: "50%",
      transition: "0.2s",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  headCellArrow: {
    opacity: "0%",
    transition: "0.2s",
  },
  tableCell: {
    paddingBottom: "0",
    paddingTop: "0",
  },
  iconButton: {
    backgroundColor: "#1DBBED",
    margin: "2px",
    "&:hover": {
      backgroundColor: "#00A0E3",
    },
  },
  downloadCheckboxContainer: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    border: "2px solid #1DBBED",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.2s ease",
  },
  downloadCheckboxContainerActive: {
    background: "#1DBBED",
  },
  downloadCheckboxIcon: {
    color: "#fff",
    width: "75%",
    height: "75%",
  },
  downloadCheckbox: {
    display: "none",
  },
  confirmButton: {
    backgroundColor: "#66D01D",
    margin: "3px",
    "&:hover": {
      backgroundColor: "#5AB71B",
    },
  },
  generateButton: {
    background: "#1DBBED",
    marginLeft: "auto",
    cursor: "pointer",
    border: "none",
    fontSize: "16px",
    padding: "8px 14px",
    color: "#fff",
    width: "180px",
    '&:disabled': {
      background: "#999",
      cursor: "default"
    }
  },
  changeViewButton: {
    background: "transparent",
    cursor: "pointer",
    border: "1px solid #1DBBED",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "8px 14px",
    color: "#1DBBED",
    "&:hover": {
      background: "#1DBBED",
      color: "#fff",
    },
    "&:focus": {
      outline: "none",
    },
  },
  checkAllButton: {
    width: "180px",
    background: "#1DBBED",
    cursor: "pointer",
    padding: "8px 14px",
    color: "#fff",
    border: "none",
    outline: "none",
    display: "flex",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
  },
});

const SearchDatePicker = styled(DatePicker)({
  width: 160,
  fontFamily: "'Open Sans', sans-serif",
});

const months = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień",
];

const generateStartEndDate = (newDate) => ({
  start: getUnixTime(startOfMonth(newDate)),
  end: getUnixTime(endOfMonth(newDate)),
});

export const StatementsTable = (props) => {
  const classes = useStyles();
  const now = new Date();
  const url = "api/kilometrowka";
  const [page, setPage] = useState(0);
  const [columns, setColumns] = useState(props.columns);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDetails, setShowDetails] = useState(false);
  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const [index, setIndex] = useState(null);
  const [invokeClearColumn, setClearColumn] = useState(false);
  const [searchValues, setSearchValues] = useState([
    { id: "time", value: null },
  ]);
  const [date, setDate] = useState(generateStartEndDate(now));
  const [api, setApi] = useState([]);
  const [apiIds, setApiIds] = useState([]);
  const [count, setCount] = useState(0);
  const [sendRequest, setSendRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedIds, setCheckedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  const { roles, addFlash } = useContext(MimicContext);

  const hasSaveAccess = roles.includes("ROLE_DELEGACJE_ZAPIS");

  const [year, setYear] = useState(getYear(now));
  const [month, setMonth] = useState(getMonth(now));
  const [day, setDay] = useState(null);

  //DateTabs
  const [startDate, setStartDate] = useState(fromUnixTime(date.start));
  const [endDate, setEndDate] = useState(fromUnixTime(date.end));

  const handleDateChange = (type, value) => {
    const rangeDate = {...date};
    if(type === 'start') {
      value = startOfDay(value);
      setStartDate(value);
    }
    if(type === 'end') {
      value = endOfDay(value);
      setEndDate(value);
    }
    rangeDate[type] = getUnixTime(value);
    setDate(rangeDate);
  }

  const [daysInMonth, setDaysInMonth] = useState(
    convertNumberToArray(getDaysInMonth(new Date(year, month)))
  );

  const [noDelegationView, setNoDelegationView] = useState(true);

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevState) => {
      const newState = !prevState.includes(id)
        ? [...prevState, id]
        : prevState.filter((idFromState) => idFromState !== id);
      return newState;
    });
  };

  const handleOnEnterPress = (e) => {
    if (e.key === "Enter") {
      getData(noDelegationView);
      setCheckedIds([]);
      setAllSelected(false);
    }
  };

  const handleGenerateDelegation = async () => {
    const httpRequest = HttpClient.createRequest();
    const response = await httpRequest.post(
      "api/delegations",
      {
        ids: checkedIds,
      },
      {},
      true
    );
    if (response.status === 200) {
      setApi((prevState) =>
        prevState.filter(({ id }) => !checkedIds.includes(id))
      );
      setCheckedIds([]);
    }
  };

  const handleGenerateCsv = async () => {
    const getCsv = async (ids) => {
      const httpRequest = HttpClient.createRequest();
      const response = await httpRequest.get(
          "api/kilometrowka/csv",
          {
            params: {
              ids
            }
          },
          true
      )
      return response.data.data;
    }
    addFlash('Trwa pobieranie kilometrówek', {
      variant: 'success',
      preventDuplicate: true,
      key: 'downloadDelegation'
    })
    setIsDownloadingAll(true);
    const data = await chunkCallable(checkedIds, 100, getCsv)
    downloadCsv(data, startDate, endDate);
    setIsDownloadingAll(false);
  };

  const handleSetStartEndDate = () => {
    let rangeDate = {...date};
    if (day) {
      const thisDay = new Date(Date.UTC(year, month, day));
      rangeDate = {
        start: getUnixTime(startOfDay(thisDay)),
        end: getUnixTime(endOfDay(thisDay)),
      };
    } else {
      const thisMonth = new Date(Date.UTC(year, month));
      rangeDate = {
        start: getUnixTime(startOfMonth(thisMonth)),
        end: getUnixTime(endOfMonth(thisMonth)),
      };
    }
    setDate(rangeDate);
  };

  const handleDaysInMonthChange = (newYear, newMonth) => {
    setDaysInMonth(
      convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth)))
    );
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    handleDateChange();
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    handleDateChange();
  };

  const handleDayChange = (event, newValue) => {
    if (newValue === day) {
      setDay(null);
    } else {
      setDay(newValue);
    }
    handleDateChange();
  };

  const handleDatePickerDateChange = (newDate) => {
    const day = getDate(newDate);
    const month = getMonth(newDate);
    const year = getYear(newDate);
    setDay(day);
    setMonth(month);
    setYear(year);
    handleDateChange();
  };

  const getDatePickerValue = () => {
    return !isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day)) ? null : new Date(year, month, day);
  }

  const source = axios.CancelToken.source();

  const handleSendRequest = async (index, status, reason, manualKm) => {
    const httpRequest = HttpClient.createRequest();
    try {
      const { data } = await httpRequest.put(
        `api/kilometrowka/status/${api[index].id}`,
        {
          status,
          reason,
          manualKm,
        },
        { cancelToken: source.token },
        true
      );
      const nextApi = produce(api, (draftState) => {
        draftState[index] = data.data;
      });
      setApi(nextApi);
    } catch (e) {
    } finally {
      setIsLoading(false);
      setSendRequest(true);
    }
  };

  const getParams = (s) => {
    if (s.value !== null) {
      if (isDate(s.value)) {
        s.value = getUnixTime(s.value);
      }
      return s.id + "=" + s.value + "&";
    }
  };

  const getData = async (hasNoDelegation = true) => {
    setIsLoading(true);
    const _searchParams = searchValues.map((a) => ({ ...a }));
    const getParam = _searchParams.map(getParams).join("");
    const sortField = [...columns].filter((item) => item.sorted === true);

    const httpRequest = HttpClient.createRequest();
    try {
      const response = await httpRequest.get(
        `${url}?${getParam}orderBy=${sortField[0].id}&order=${
          sortField[0].order
        }&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`,
        {
          cancelToken: source.token,
          params: {
            date,
            onlyAccepted: true,
            hasNoDelegation: hasNoDelegation || null,
            forCsv: !hasNoDelegation || null,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        },
        true
      );
      setApi(response.data.items);
      setCount(response.data.count);
      setApiIds(response.data.ids);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const { value } = searchValues.find((value) => value.id === "time");
  useEffect(() => {
    window.scrollTo(0, 0);
    getData(noDelegationView);

    return () => {
      source.cancel();
    };
  }, [invokeClearColumn, date, value, rowsPerPage, page]);

  useEffect(() => {
    handleDaysInMonthChange(year, month);
    handleSetStartEndDate();
  }, [year, month, day]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeView = () => {
    setNoDelegationView((prev) => !prev);
    setCheckedIds([]);
  };

  useEffect(() => {
    getData(noDelegationView);
  }, [noDelegationView]);

  useEffect(() => {
    setPage(0);
    setStartDate(fromUnixTime(date.start));
    setEndDate(fromUnixTime(date.end));
  }, [date]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    setCheckedIds([]);
  };

  const handleSearch = (field, query) => {
    setPage(0);
    const filterObject = searchValues.filter((s) => s.id !== field);
    setSearchValues([...filterObject, { id: field, value: query }]);
  };

  const handleClearColumn = (field) => {
    handleSearch(field, null);
    setClearColumn(!invokeClearColumn);
  };

  const clearDate = () => {
    setDay(null);
  };

  function createData(
    id,
    direction,
    time,
    worker,
    pickup,
    destination,
    manualKm,
    kontrahent,
    cardNo,
    check,
    details
  ) {
    return {
      id,
      direction,
      time,
      worker,
      pickup,
      destination,
      manualKm,
      kontrahent,
      cardNo,
      check,
      details,
    };
  }

  const switchShowModal = (value) => {
    setShowDetails(value);
    setIsMoreDetails(value);
  };

  const ShowArrow = ({ sorted, order, classes }) => {
    if (sorted) {
      if (order === "ASC") {
        return (
          <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />
        );
      } else if (order === "DESC") {
        return (
          <ArrowDownwardIcon
            fontSize="small"
            className={classes.headCellArrow}
          />
        );
      }
    } else {
      return (
        <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />
      );
    }
  };

  const createSortHandler = (_column) => {
    columns.map((column) => {
      if (column.id === _column.id) {
        if (_column.order === "ASC") {
          column.order = "DESC";
        } else {
          column.sorted = true;
          column.order = "ASC";
        }
      } else {
        column.sorted = false;
        column.order = null;
      }
    });
    setColumns(columns);
    handleSort();
  };

  const handleSort = () => {
    setPage(0);
    getData(noDelegationView);
  };

  const handleCheckAll = () => {
    setAllSelected((prev) => !prev);
  };

  useEffect(() => {
    setCheckedIds(apiIds.filter(apiId => checkedIds.includes(apiId)))
  }, [apiIds]);

  useEffect(() => {
    if (checkedIds.length >= 0 && checkedIds.length < apiIds.length) {
      setCheckedIds(apiIds);
    } else {
      setCheckedIds([]);
    }
  }, [allSelected]);

  const rows = api
    ? api.map((data, index) => {
        let destinationTime = fromUnixTime(data.destinationTime);

        return createData(
          data.id,
          <div className={styles["zestawienie__table--item--direction"]}>
            {(data.direction && data.isWorkAddress) || !data.direction ? (
              <div
                className={
                  styles["zestawienie__table--item--direction--toWork"]
                }
              ></div>
            ) : (
              <div
                className={
                  styles["zestawienie__table--item--direction--toHome"]
                }
              ></div>
            )}

            <div
              className={styles["zestawienie__table--item--direction--icon"]}
            >
              <ArrowRightAltIcon />
            </div>
            {data.direction ? (
              <div
                className={
                  styles["zestawienie__table--item--direction--toWork"]
                }
              ></div>
            ) : (
              <div
                className={
                  styles["zestawienie__table--item--direction--toHome"]
                }
              ></div>
            )}
          </div>,
          data.destinationTime ? (
            <div className={classes.time}>
              <p
                className={styles["zestawienie__table--item"]}
              >{`${destinationTime.getDate()}.${
                months[destinationTime.getMonth()]
              } ${destinationTime.getFullYear()}`}</p>
              <p className={styles["zestawienie__table--item"]}>{`godz. ${
                (destinationTime.getHours() < 10 ? "0" : "") +
                destinationTime.getHours()
              }:${
                (destinationTime.getMinutes() < 10 ? "0" : "") +
                destinationTime.getMinutes()
              }`}</p>
            </div>
          ) : (
            "-"
          ),
          data.worker ? (
            <div className={styles["zestawienie__table--item"]}>
              {data.worker.user.name} {data.worker.user.surName}
            </div>
          ) : (
            "-"
          ),
          <div className={styles["zestawienie__table--item"]}>
            {data.from ? `${data.from.street} ${data.from.city}` : null}
          </div>,
          <div className={styles["zestawienie__table--item"]}>
            {data.to ? `${data.to.street} ${data.to.city}` : null}
          </div>,
          data.chooseKm ? (
            <div className={styles["zestawienie__table--item"]}>
              {data.chooseKm}
            </div>
          ) : data.manualKm ? (
            <div className={styles["zestawienie__table--item"]}>
              {data.manualKm}
            </div>
          ) : (
            "-"
          ),
          data.kontrahent.name ? `${data.kontrahent.name}` : "brak",
          data.cardNo ? `${data.cardNo}` : "brak",
          hasSaveAccess ? (
            <label
              htmlFor={data.id}
              className={`${classes.downloadCheckboxContainer} ${
                checkedIds.includes(data.id)
                  ? classes.downloadCheckboxContainerActive
                  : ""
              }`}
            >
              {checkedIds.includes(data.id) && (
                <CheckIcon className={classes.downloadCheckboxIcon} />
              )}
              <input
                id={data.id}
                name={data.id}
                type="checkbox"
                className={classes.downloadCheckbox}
                checked={checkedIds.includes(data.id)}
                onChange={() => {
                  handleCheckboxChange(data.id);
                }}
              ></input>
            </label>
          ) : null,
          <div className={styles["zestawienie__buttonContainer"]}>
            <BootstrapTooltip title="Szczegóły">
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={() => {
                  setIndex(index);
                  setShowDetails(true);
                  setIsMoreDetails(true);
                }}
              >
                <MoreHorizIcon className={classes.icon} />
              </IconButton>
            </BootstrapTooltip>
          </div>
        );
      })
    : null;

  return (
    <div className={styles["zestawienie"]}>
      <Paper className={classes.root}>
        <DateTabs
          handleDayChange={handleDayChange}
          handleMonthChange={handleMonthChange}
          handleYearChange={handleYearChange}
          handleDateChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          day={day}
          month={month}
          year={year}
          daysInMonth={daysInMonth}
        />

        <div
          className={styles["zestawienie__description"]}
          style={{ paddingBottom: 0 }}
        >
          <h1 className={styles["zestawienie__description--title"]}>
            Zestawienie zaakceptowanych kilometrówek {noDelegationView ? '(bez wygenerowanych delegacji)' : '(wszystkich)'}
          </h1>
        </div>
        <div
          className={styles["zestawienie__description"]}
          style={{ paddingBottom: 0 }}
        >
          <button
            onClick={handleChangeView}
            className={classes.changeViewButton}
          >
            Przełącz widok na{" "}
            {noDelegationView ? "generowanie csv" : "generowanie delegacji do pdf"}
          </button>
          {noDelegationView && hasSaveAccess && (
            <button
              onClick={handleGenerateDelegation}
              className={classes.generateButton}
              disabled={checkedIds.length === 0}
            >
              Generuj delegację
            </button>
          )}
          {!noDelegationView && hasSaveAccess && (
            <button
              onClick={handleGenerateCsv}
              className={classes.generateButton}
              disabled={checkedIds.length === 0 || isDownloadingAll}
            >
              Generuj CSV
            </button>
          )}
        </div>
        {hasSaveAccess && (
          <div className={styles["zestawienie__description"]}>
            <button onClick={handleCheckAll} className={classes.checkAllButton}>
              Zaznacz wszystkie
            </button>
          </div>
        )}
        {isLoading ? (
          <ProgressLine />
        ) : (
          <TableContainer>
            <SimpleBar
              style={{ minHeight: "53vh", maxHeight: "53vh" }}
              className={classes.simplebar}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {columns.map((column, index) => (
                      <Fragment key={column.id}>
                        <TableCell
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {column.query ? (
                            <TableSortLabel
                              className={classes.tableSortLabel}
                              hideSortIcon={true}
                            >
                              {column.id === "time" ? (
                                <ThemeProvider theme={defaultMaterialTheme}>
                                  <Input
                                    className={classes.headCell}
                                    inputComponent={SearchDatePicker}
                                    inputProps={{
                                      style: { height: "unset" },
                                      onChange: (newValue) =>
                                        handleDatePickerDateChange(newValue),
                                      value: getDatePickerValue(),
                                      format: "d MMMM",
                                      // minDate: props.status === "archive" ? startOfMonth(fromUnixTime(date?.start)) : new Date("1900-01-01"),
                                      // maxDate: props.status === "archive" ? endOfMonth(fromUnixTime(date?.end)) : new Date("2100-01-01"),
                                      disableToolbar:
                                        props.status === "archive",
                                      InputProps: {
                                        disableUnderline: true,
                                        placeholder: column.label,
                                      },
                                    }}
                                    endAdornment={
                                      <>
                                        <Tooltip title="Wyczyść">
                                          <IconButton
                                            size="small"
                                            onClick={() => clearDate()}
                                            aria-label="visible columns"
                                          >
                                            <ClearIcon
                                              className={classes.headCellArrow}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Sortuj">
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              createSortHandler(
                                                column,
                                                index,
                                                rows
                                              )
                                            }
                                            aria-label="visible columns"
                                          >
                                            <ShowArrow
                                              sorted={column.sorted}
                                              order={column.order}
                                              classes={classes}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    }
                                  />
                                </ThemeProvider>
                              ) : (
                                <Input
                                  variant="outlined"
                                  className={classes.headCell}
                                  defaultValue={
                                    searchValues.find((v) => v.id === column.id)
                                      ?.value
                                  }
                                  onChange={(e) =>
                                    handleSearch(column.id, e.target.value)
                                  }
                                  onKeyDown={handleOnEnterPress}
                                  placeholder={column.label}
                                  endAdornment={
                                    <>
                                      <Tooltip title="Wyczyść">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleClearColumn(column.id)
                                          }
                                          aria-label="visible columns"
                                        >
                                          <ClearIcon
                                            className={classes.headCellArrow}
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      {column.sorted !== null && (
                                        <Tooltip title="Sortuj">
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              createSortHandler(
                                                column,
                                                index,
                                                rows
                                              )
                                            }
                                            aria-label="visible columns"
                                          >
                                            <ShowArrow
                                              sorted={column.sorted}
                                              order={column.order}
                                              classes={classes}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </TableSortLabel>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      </Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                {api && api.length > 0 ? (
                  <TableBody>
                    {rows &&
                      rows.map((row) => {
                        return (
                          <TableRow hover role="checkbox" key={row.id}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={classes.tableCell}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <p className={styles["zestawienie__noData"]}>Brak danych</p>
                )}
              </Table>
            </SimpleBar>
            <TablePagination
              className={classes.pagination}
              labelRowsPerPage={"Liczba wierszy"}
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Paper>
      {showDetails ? (
        <Confirmation
          details={api[index]}
          index={index}
          switchShowModal={switchShowModal}
          handleSendRequest={handleSendRequest}
          isMoreDetails={isMoreDetails}
          entity={props.entity}
          disallowEdit
        />
      ) : null}
    </div>
  );
};
