import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Icon, Stroke, Style, Fill} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import LineString from "ol/geom/LineString";
import axios from "axios";
import {redirect} from "../../../../../redirect";
import {NotificationContext} from "../../../../../context";
import whiteMarker from "../../../../../img/marker_white.png"
import HttpClient from "../../../../../HttpClient";
import config from "../../../../../config";

const colors = [[23, 166, 210, 0.6], [20, 224, 44, 0.6], [56, 50, 219, 0.6], [242, 92, 180, 0.6], [242, 44, 137, 0.6], [56, 174, 242, 0.6], [235, 226, 63, 0.6], [99, 235, 221, 0.6], [88, 177, 232, 0.6], [108, 99, 235, 0.6],];

export default class SummaryCourseMap extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.center = this.props.courseDetails[0].pickup
            ? [
                this.props.courseDetails[0].pickup.lon,
                this.props.courseDetails[0].pickup.lat,
            ]
            : [
                this.props.courseDetails[0].destination.lon,
                this.props.courseDetails[0].destination.lat,
            ];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6;
        this.marker = whiteMarker;
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
        });

        const {courseDetails} = this.props;
        let startCoords = null;
        let endCoords = null;
        let counter = 0;

        for (let i = 0; i < courseDetails.length; i++) {
            let singleColor = colors[counter];
            startCoords = [courseDetails[i].pickup.lon, courseDetails[i].pickup.lat];
            endCoords = [courseDetails[i].destination.lon, courseDetails[i].destination.lat];
            this.getRoutes(startCoords, endCoords, singleColor);
            counter < 10 ? counter++ : counter = 0;
        }
    }

    getRoutes = async (startCoords, endCoords, color) => {
        const START_POINT = "startPoint";
        const END_POINT = "endPoint"

        const httpRequest = HttpClient.createRequest();

        try {
            const response = await httpRequest.get(`${config.osrmURL}/route/v1/driving/${startCoords[0]},${startCoords[1]};${endCoords[0]},${endCoords[1]}?steps=true&geometries=geojson&overview=full`);
            this.route = response.data;
            this.createMarker(startCoords, START_POINT, color);
            this.createMarker(endCoords, END_POINT, color);
            this.createRoute(this.route, color);
        } catch (e) {
            redirect(e);
        }
    }

    createMarker(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }


    createRoute(route, color) {
        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]];
                    this.createLine(prevStep, singleStep, color);
                }
            }
        }
    }

    createLine(prevStep, singleStep, color) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: color,
                width: 4,
            }),
            fill: new Fill({
                color: color
            })
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);
    }


    render() {
        return (
            <div
                id={"osm-map"}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
