import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import HttpClient from "../../../HttpClient";
import { disableEnterSubmit } from "./disableEnterSubmit";
import { GridInput } from "./GridInput";
import { parseValues } from "./parseValues";
import { validationSchema } from "./validationSchema";

const InputsGroup = ({ children, title }) => (
  <Box mb={3}>
    <Paper elevation={5}>
      <Box p={2}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginBottom: "0.8rem" }}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Paper>
  </Box>
);

const initialValues = {
  worker: "",
  position: "",
  createdAt: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  paymentOnAccount: 0,
  statements: [
    {
      vehicleType: "Pojazd własny",
      capacity: "",
      regNo: "",
      name: "",
      pricePerKm: "",
      totalKm: "",
      arrivalCity: "",
      client: "",
      arrivalDateTime: new Date(),
      departureCity: "",
      departureDateTime: new Date(),
    },
  ],
  startedFor6: 0,
  oneDayDelegation: 0,
  longerDelegationFull: 0,
  longerDelegationStarted: 0,
  accomodationPrice: 0,
  accomodationLumpSum: 0,
  otherCosts: 0,
};

export const AddDelegation = () => {
  const [workers, setWorkers] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [formWasTouched, setFormWasTouched] = useState(false);
  const history = useHistory();

  const addContractor = (contractor) => {
    setContractors([...contractors, contractor]);
  };

  const addWorker = (worker) => {
    setWorkers([...workers, worker]);
  };

  const getWorkersData = async () => {
    const httpRequest = HttpClient.createRequest();
    try {
      const { data } = await httpRequest.get("api/pracownik/list", {}, true);
      setWorkers(data);
    } catch (e) {}
  };

  const getContractorsData = async () => {
    const httpRequest = HttpClient.createRequest();
    try {
      const { data } = await httpRequest.get(
        "api/dyspozytura/kontrahents",
        {},
        true
      );
      setContractors(data);
    } catch (e) {}
  };

  useEffect(() => {
    getWorkersData();
    getContractorsData();
  }, []);

  return (
    <SimpleBar style={{ maxHeight: "80vh", minWidth: 520 }}>
      <Box maxWidth="70%" mt={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const body = parseValues(values);
            const httpRequest = HttpClient.createRequest();
            await httpRequest.post(
              "/api/delegations/manual",
              { json: JSON.stringify(body) },
              {},
              true
            );
            history.push("/app/administracja/dokumenty/delegacje");
          }}
          render={({
            values,
            setFieldValue,
            handleChange,
            errors,
            touched,
          }) => {
            return (
              <Form onKeyDown={disableEnterSubmit}>
                <InputsGroup title="Dane pracownika">
                  <Grid container spacing={2}>
                    <GridInput
                      idAndName="worker"
                      workers={workers}
                      label="Pracownik"
                      statementsLength={values.statements.length}
                      error={errors.worker}
                      addWorker={addWorker}
                      displayError={touched.worker && !!errors.worker}
                      setFieldValue={setFieldValue}
                      setFormWasTouched={setFormWasTouched}
                      handleChange={handleChange}
                    />
                    <GridInput
                      idAndName="position"
                      defaultValue={values.position}
                      setFormWasTouched={setFormWasTouched}
                      label="Stanowisko"
                      error={errors.position}
                      displayError={touched.position && !!errors.position}
                      handleChange={handleChange}
                    />
                  </Grid>
                </InputsGroup>
                <InputsGroup title="Dane dokumentu">
                  <Grid container spacing={2}>
                    <GridInput
                      idAndName="createdAt"
                      label="Data dokumentu"
                      type="date"
                      setFormWasTouched={setFormWasTouched}
                      handleChange={setFieldValue}
                      defaultValue={new Date()}
                      error={errors.createdAt}
                      displayError={touched.createdAt && !!errors.createdAt}
                    />
                    <GridInput
                      idAndName="startDate"
                      label="Data od"
                      setFormWasTouched={setFormWasTouched}
                      type="date"
                      defaultValue={new Date()}
                      handleChange={setFieldValue}
                      error={errors.startDate}
                      displayError={touched.startDate && !!errors.startDate}
                    />
                    <GridInput
                      idAndName="endDate"
                      label="Data do"
                      type="date"
                      minDate={values.startDate}
                      defaultValue={new Date()}
                      setFormWasTouched={setFormWasTouched}
                      handleChange={setFieldValue}
                      error={errors.endDate}
                      displayError={touched.endDate && !!errors.endDate}
                    />
                  </Grid>
                </InputsGroup>
                <FieldArray
                  name="statements"
                  render={({ push, remove }) => {
                    return (
                      <>
                        {values.statements.length > 0 &&
                          values.statements.map((statement, index) => (
                            <InputsGroup title="Dane przejazdu" key={index}>
                              <Box mb={1}>
                                <Box mb={4}>
                                  <Grid container spacing={2}>
                                    <GridInput
                                      idAndName={`statements.${index}.vehicleType`}
                                      setFormWasTouched={setFormWasTouched}
                                      label="Typ pojazdu"
                                      handleChange={setFieldValue}
                                      defaultValue={statement.vehicleType}
                                      type="select"
                                      selectOptions={["Pojazd własny", "Inne"]}
                                    />
                                    {statement.vehicleType ===
                                      "Pojazd własny" && (
                                      <>
                                        <GridInput
                                          idAndName={`statements.${index}.capacity`}
                                          label="Pojemność"
                                          defaultValue={statement.capacity}
                                          handleChange={setFieldValue}
                                          type="select"
                                          setFormWasTouched={setFormWasTouched}
                                          displayError={
                                            touched.statements &&
                                            touched.statements[index] &&
                                            touched.statements[index].capacity
                                          }
                                          error={
                                            errors.statements &&
                                            errors.statements[index] &&
                                            errors.statements[index].capacity
                                          }
                                          selectOptions={[
                                            "Powyżej 900cm3",
                                            "Poniżej 900cm3",
                                          ]}
                                        />
                                        <GridInput
                                          idAndName={`statements.${index}.regNo`}
                                          label="Numer rejestracyjny"
                                          index={index}
                                          setFormWasTouched={setFormWasTouched}
                                          displayError={
                                            touched.statements &&
                                            touched.statements[index] &&
                                            touched.statements[index].regNo
                                          }
                                          error={
                                            errors.statements &&
                                            errors.statements[index] &&
                                            errors.statements[index].regNo
                                          }
                                          defaultValue={statement.regNo}
                                          handleChange={handleChange}
                                        />
                                      </>
                                    )}
                                    {statement.vehicleType === "Inne" && (
                                      <>
                                        <GridInput
                                          idAndName={`statements.${index}.name`}
                                          label="Nazwa"
                                          handleChange={handleChange}
                                          setFormWasTouched={setFormWasTouched}
                                          displayError={
                                            touched.statements &&
                                            touched.statements[index] &&
                                            touched.statements[index].name
                                          }
                                          error={
                                            errors.statements &&
                                            errors.statements[index] &&
                                            errors.statements[index].name
                                          }
                                        />
                                      </>
                                    )}
                                  </Grid>
                                </Box>
                                <Box mb={4}>
                                  <Grid container spacing={2}>
                                    <GridInput
                                      type="number"
                                      step="0.0001"
                                      setFormWasTouched={setFormWasTouched}
                                      displayError={
                                        touched.statements &&
                                        touched.statements[index] &&
                                        touched.statements[index].pricePerKm
                                      }
                                      error={
                                        errors.statements &&
                                        errors.statements[index] &&
                                        errors.statements[index].pricePerKm
                                      }
                                      idAndName={`statements.${index}.pricePerKm`}
                                      defaultValue={statement.pricePerKm}
                                      label="Stawka za 1 KM"
                                      handleChange={handleChange}
                                    />
                                    <GridInput
                                      type="number"
                                      setFormWasTouched={setFormWasTouched}
                                      displayError={
                                        touched.statements &&
                                        touched.statements[index] &&
                                        touched.statements[index].totalKm
                                      }
                                      error={
                                        errors.statements &&
                                        errors.statements[index] &&
                                        errors.statements[index].totalKm
                                      }
                                      idAndName={`statements.${index}.totalKm`}
                                      label="Razem KM"
                                      handleChange={handleChange}
                                    />
                                    <GridInput
                                      setFieldValue={setFieldValue}
                                      addContractor={addContractor}
                                      setFormWasTouched={setFormWasTouched}
                                      contractors={contractors}
                                      index={index}
                                      idAndName={`statements.${index}.client`}
                                      displayError={
                                        touched.statements &&
                                        touched.statements[index] &&
                                        touched.statements[index].client
                                      }
                                      error={
                                        errors.statements &&
                                        errors.statements[index] &&
                                        errors.statements[index].client
                                      }
                                      label="Kontrahent"
                                      handleChange={handleChange}
                                    />
                                  </Grid>
                                </Box>
                                <Box mb={4}>
                                  <Typography variant="body1">
                                    Wyjazd
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <GridInput
                                      idAndName={`statements.${index}.departureCity`}
                                      setFormWasTouched={setFormWasTouched}
                                      label="Miejscowość"
                                      handleChange={handleChange}
                                      displayError={
                                        touched.statements &&
                                        touched.statements[index] &&
                                        touched.statements[index].departureCity
                                      }
                                      error={
                                        errors.statements &&
                                        errors.statements[index] &&
                                        errors.statements[index].departureCity
                                      }
                                    />
                                    <GridInput
                                      idAndName={`statements.${index}.departureDateTime`}
                                      setFormWasTouched={setFormWasTouched}
                                      displayError={
                                        touched.statements &&
                                        touched.statements[index] &&
                                        touched.statements[index]
                                          .departureDateTime
                                      }
                                      error={
                                        errors.statements &&
                                        errors.statements[index] &&
                                        errors.statements[index]
                                          .departureDateTime
                                      }
                                      label="Data i godzina"
                                      type="datetime-local"
                                      defaultValue={new Date()}
                                      handleChange={setFieldValue}
                                    />
                                  </Grid>
                                </Box>
                              </Box>
                              <Box mb={4}>
                                <Typography variant="body1">
                                  Przyjazd
                                </Typography>
                                <Grid container spacing={2}>
                                  <GridInput
                                    idAndName={`statements.${index}.arrivalCity`}
                                    setFormWasTouched={setFormWasTouched}
                                    displayError={
                                      touched.statements &&
                                      touched.statements[index] &&
                                      touched.statements[index].arrivalCity
                                    }
                                    error={
                                      errors.statements &&
                                      errors.statements[index] &&
                                      errors.statements[index].arrivalCity
                                    }
                                    label="Miejscowość"
                                    handleChange={handleChange}
                                  />
                                  <GridInput
                                    idAndName={`statements.${index}.arrivalDateTime`}
                                    setFormWasTouched={setFormWasTouched}
                                    displayError={
                                      touched.statements &&
                                      touched.statements[index] &&
                                      touched.statements[index].arrivalDateTime
                                    }
                                    error={
                                      errors.statements &&
                                      errors.statements[index] &&
                                      errors.statements[index].arrivalDateTime
                                    }
                                    label="Data i godzina"
                                    type="datetime-local"
                                    defaultValue={new Date()}
                                    handleChange={setFieldValue}
                                  />
                                </Grid>
                              </Box>
                              {values.statements.length > 1 && (
                                <Box>
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Usuń przejazd
                                  </Button>
                                </Box>
                              )}
                              {index === values.statements.length - 1 && (
                                <Box>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      push({
                                        vehicleType: "Pojazd własny",
                                        capacity: values.worker?.capacity ?? "",
                                        regNo: values.worker?.regNo ?? "",
                                        name: "",
                                        pricePerKm:
                                          values.worker?.delegationRate ?? "",
                                        totalKm: "",
                                        arrivalCity: "",
                                        client: "",
                                        arrivalDateTime: new Date(),
                                        departureCity: "",
                                        departureDateTime: new Date(),
                                      });
                                    }}
                                  >
                                    Dodaj kolejny przejazd
                                  </Button>
                                </Box>
                              )}
                            </InputsGroup>
                          ))}
                        <InputsGroup title="Dodatkowe dane">
                          <Box mb={1}>
                            <Box mb={4} mt={4}>
                              <Typography variant="body1">Zaliczka</Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="paymentOnAccount"
                                  displayError={
                                    touched.paymentOnAccount &&
                                    errors.paymentOnAccount
                                  }
                                  error={errors.paymentOnAccount}
                                  defaultValue={initialValues.paymentOnAccount}
                                  step="0.01"
                                  label="Kwota"
                                  type="number"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                            <Box mb={4} mt={4}>
                              <Typography variant="body1">
                                Ryczałt za przejazdy lokalne
                              </Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="startedFor6"
                                  displayError={
                                    touched.startedFor6 && errors.startedFor6
                                  }
                                  error={errors.startedFor6}
                                  defaultValue={initialValues.startedFor6}
                                  label="Rozpoczętych dób(kwota)"
                                  type="number"
                                  step="0.01"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                            <Box mb={4}>
                              <Typography variant="body1">
                                Diety - delegacja jednodniowa
                              </Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="oneDayDelegation"
                                  defaultValue={initialValues.oneDayDelegation}
                                  displayError={
                                    touched.oneDayDelegation &&
                                    errors.oneDayDelegation
                                  }
                                  error={errors.oneDayDelegation}
                                  label="Kwota"
                                  step="0.01"
                                  type="number"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                            <Box mb={4}>
                              <Typography variant="body1">
                                Diety - delegacja dłuższa
                              </Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="longerDelegationFull"
                                  label="Całych dób (kwota)"
                                  displayError={
                                    touched.longerDelegationFull &&
                                    errors.longerDelegationFull
                                  }
                                  error={errors.longerDelegationFull}
                                  defaultValue={
                                    initialValues.longerDelegationFull
                                  }
                                  step="0.01"
                                  type="number"
                                  handleChange={handleChange}
                                />
                                <GridInput
                                  idAndName="longerDelegationStarted"
                                  setFormWasTouched={setFormWasTouched}
                                  label="Rozp. dób (kwota)"
                                  displayError={
                                    touched.longerDelegationStarted &&
                                    errors.longerDelegationStarted
                                  }
                                  error={errors.longerDelegationStarted}
                                  defaultValue={
                                    initialValues.longerDelegationStarted
                                  }
                                  step="0.01"
                                  type="number"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                            <Box mb={4}>
                              <Typography variant="body1">Noclegi</Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="accomodationPrice"
                                  label="Kwota wg rachunków"
                                  displayError={
                                    touched.accomodationPrice &&
                                    errors.accomodationPrice
                                  }
                                  error={errors.accomodationPrice}
                                  defaultValue={initialValues.accomodationPrice}
                                  type="number"
                                  step="0.01"
                                  handleChange={handleChange}
                                />
                                <GridInput
                                  idAndName="accomodationLumpSum"
                                  setFormWasTouched={setFormWasTouched}
                                  label="Ryczałt (kwota)"
                                  displayError={
                                    touched.accomodationLumpSum &&
                                    errors.accomodationLumpSum
                                  }
                                  error={errors.accomodationLumpSum}
                                  defaultValue={
                                    initialValues.accomodationLumpSum
                                  }
                                  type="number"
                                  step="0.01"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                            <Box mb={4}>
                              <Typography variant="body1">
                                Inne wydatki
                              </Typography>
                              <Grid container spacing={2}>
                                <GridInput
                                  setFormWasTouched={setFormWasTouched}
                                  idAndName="otherCosts"
                                  label="Kwota"
                                  displayError={
                                    touched.otherCosts && errors.otherCosts
                                  }
                                  step="0.01"
                                  error={errors.otherCosts}
                                  defaultValue={initialValues.otherCosts}
                                  type="number"
                                  handleChange={handleChange}
                                />
                              </Grid>
                            </Box>
                          </Box>
                        </InputsGroup>
                      </>
                    );
                  }}
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box mr={0}>
                    <Button
                      size="large"
                      variant="text"
                      color="secondary"
                      type="button"
                      onClick={async () => {
                        history.push("/app/administracja/dokumenty/delegacje");
                      }}
                    >
                      Anuluj
                    </Button>
                  </Box>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!formWasTouched}
                  >
                    Dodaj
                  </Button>
                </Box>
              </Form>
            );
          }}
        />
      </Box>
    </SimpleBar>
  );
};
