import React, { Fragment } from "react";
import DayOff from "./DayOff";
import AppRoute from "../../../AppRoute";

const DniWolneRoute = () => {
  return (
    <Fragment>
      <AppRoute
        app="kierowca"
        window="grafik"
        tab="dniWolne"
        component={DayOff}
      />
    </Fragment>
  );
};

export default DniWolneRoute;
