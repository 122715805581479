import React, {useEffect, useState} from "react";
import styles from "./singleSchedule.module.scss";
import {getUnixTime, fromUnixTime, format, startOfDay, endOfDay} from "date-fns";
import {styled} from "@material-ui/core/styles";
import {DatePicker} from "@material-ui/pickers";
import HttpClient from "../../../../../HttpClient";
import {createMuiTheme} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {ThemeProvider} from "@material-ui/styles";
import SingleCourseDetailsPopover
    from "../../../singleDriverSchedule/singleCourseDetailsPopover/SingleCourseDetailsPopover";
import axios from "axios";
import {pl} from "date-fns/locale";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const DateSwitcher = styled(DatePicker)({
    width: 160,
    fontFamily: "'Open Sans', sans-serif",
});

const hours = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
];

const SingleSchedule = (props) => {
    const source = axios.CancelToken.source();
    const [currentDate, setCurrentDate] = useState(props.pickedDate ? fromUnixTime(props.pickedDate) : new Date());
    const [api, setApi] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [singleCourse, setSingleCourse] = useState(null);
    const [singleStart, setSingleStart] = useState(null);
    const [singleMeta, setSingleMeta] = useState(null);
    const [singleCourseId, setSingleCourseId] = useState(null);
    const [singleDayOff, setSingleDayOff] = useState(null);

    const getBusyDrivers = async () => {
        let currentTime = currentDate;
        let startTime = getUnixTime(startOfDay(currentTime));
        let endTime = getUnixTime(endOfDay(currentTime));
        const httpRequest = HttpClient.createRequest();

        try {
            const response = await httpRequest.get(`/api/zajeci-kierowcy?from=${startTime}&to=${endTime}&id=${props.driverId}`, {cancelToken: source.token}, true);
            setApi(response.data);
        } catch (e) {
        }
    }

    const getSingleCourse = async () => {
        const url = `api/zlecenie/details/${singleCourseId}`
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {}, true);
            setSingleCourse(response.data);
            setSingleDayOff(null);
        } catch (e) {
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusyDrivers();
        return () => {
            source.cancel();
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusyDrivers();
    }, [currentDate]);


    useEffect(() => {
        singleCourseId && getSingleCourse();
    }, [singleCourseId]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };


    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopup(false)
    };

    const handleDateSwitch = (value) => {
        let newCurrentDate = getUnixTime(currentDate);
        let newPickedDate = getUnixTime(value);
        let result = newPickedDate - newCurrentDate;
        let newDate = newCurrentDate + result;
        setCurrentDate(fromUnixTime(newDate))
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={styles["singleSchedule"]}>
            {api && api.length > 0 && api.map((item, index) => {
                const hourDistance = 4.166;
                return (
                    <div className={styles["singleSchedule__singleLine"]} key={index}>
                        <div className={styles["singleSchedule__singleLine--description"]}>
                            <div className={styles["singleSchedule__dateSwitcher"]}>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <DateSwitcher
                                        autoOk={false}
                                        format={"d.LLLL yyyy"}
                                        ampm={false}
                                        value={currentDate}
                                        onChange={(newValue) => {
                                            handleDateSwitch(newValue);
                                        }}
                                        label="Wybierz datę"
                                        fullWidth
                                    ></DateSwitcher>
                                </ThemeProvider>
                            </div>
                        </div>

                        <div className={styles["singleSchedule__singleLine--schedule"]}>
                            <div className={styles["singleSchedule__singleLine--schedule--hours"]}>
                                {hours.map((hour, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={styles["singleSchedule__singleLine--schedule--hours--item"]}
                                            style={{left: `${hourDistance * hour}%`}}
                                        >
                                            {hour}:00
                                        </div>
                                    );
                                })}
                            </div>
                            {item.courses.map((course, index) => {
                                let start = fromUnixTime(course.startTime);
                                let end = fromUnixTime(course.endTime);
                                let startDay = start.getDate();
                                let endDay = end.getDate();
                                let currentDay = currentDate.getDate();
                                let startHours = start.getHours();
                                let startMinutes = start.getMinutes();
                                let endHours = end.getHours();
                                let endMinutes = end.getMinutes();
                                const day = 1440;
                                let startPosition;
                                let endPosition;
                                if (startDay < currentDay) {
                                    startPosition = 0;
                                } else if (startDay > currentDay) {
                                    startPosition = 100;
                                } else {
                                    startPosition =
                                        ((startHours * 60 + startMinutes) / day) * 100;
                                }
                                if (endDay > currentDay) {
                                    endPosition = 100;
                                } else if (endDay < currentDay) {
                                    endPosition = 0;
                                } else {
                                    endPosition = ((endHours * 60 + endMinutes) / day) * 100;
                                }

                                let routeWidth = endPosition - startPosition;

                                return (
                                    <div
                                        className={styles["singleSchedule__singleLine--schedule--onRoute"]}
                                        key={index}
                                        id={`onRoute${index}`}
                                        aria-describedby={id}
                                        onClick={(event) => {
                                            const startFormat = format(fromUnixTime(course.startTime), "d LLLL yyyy, HH:mm", {locale: pl,});
                                            const metaFormat = format(fromUnixTime(course.endTime), "d LLLL yyyy, HH:mm", {locale: pl,});

                                            setOpenPopup(!openPopup);
                                            setSingleStart(startFormat);
                                            setSingleMeta(metaFormat);
                                            setSingleCourseId(course.orderId);
                                            setSingleDayOff(null);

                                            handleClick(event);
                                        }}

                                        style={{
                                            left: startPosition + "%",
                                            width: routeWidth + "%",
                                        }}
                                    ></div>
                                );
                            })}

                            {/*DNI WOLNE*/}
                            {item.dayOff && item.dayOff.map((item, index) => {
                                let start = fromUnixTime(item.startAt);
                                let end = fromUnixTime(item.finishAt);
                                let startDay = start.getDate();
                                let endDay = end.getDate();
                                let currentDay = currentDate.getDate();
                                let startHours = start.getHours();
                                let startMinutes = start.getMinutes();
                                let endHours = end.getHours();
                                let endMinutes = end.getMinutes();
                                const day = 1440;
                                let startPosition;
                                let endPosition;

                                if (startDay < currentDay) {
                                    startPosition = 0;
                                } else if (startDay > currentDay) {
                                    startPosition = 100;
                                } else {
                                    startPosition =
                                        ((startHours * 60 + startMinutes) / day) * 100;
                                }
                                if (endDay > currentDay) {
                                    endPosition = 100;
                                } else if (endDay < currentDay) {
                                    endPosition = 0;
                                } else {
                                    endPosition = ((endHours * 60 + endMinutes) / day) * 100;
                                }

                                let routeWidth = endPosition - startPosition;

                                return (
                                    <div
                                        className={styles["singleSchedule__singleLine--schedule--dayOff"]}
                                        key={index}
                                        id={`dayOff${index}`}
                                        aria-describedby={id}
                                        style={{
                                            left: startPosition + "%",
                                            width: routeWidth + "%",
                                        }}
                                        onClick={(event) => {
                                            const startFormat = format(fromUnixTime(item.startAt), "d LLLL yyyy, HH:mm", {locale: pl,});
                                            const metaFormat = format(fromUnixTime(item.finishAt), "d LLLL yyyy, HH:mm", {locale: pl,});
                                            setOpenPopup(!openPopup);
                                            setSingleStart(startFormat);
                                            setSingleMeta(metaFormat);
                                            setSingleDayOff(item);
                                            handleClick(event);
                                        }}
                                    ></div>
                                );
                            })}
                        </div>

                        {openPopup ?
                            <SingleCourseDetailsPopover
                                anchor={anchorEl}
                                handleClose={handleClose}
                                openPopup={openPopup}
                                course={singleCourse}
                                dayOff={singleDayOff}
                                start={singleStart}
                                meta={singleMeta}
                            /> : null}
                    </div>
                );
            })}
        </div>
    );
}

export default SingleSchedule;