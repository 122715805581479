const filtersStorageKey = 'ordersFilters'
const dateFilterStorageKey = 'ordersDateFilter'
const orderStorageLotosIDKey = 'ordersLotosID'
const orderStorageExcludeLotosKey = 'ordersExcludeLotosID'
const importantOrdersKey = 'importantOrders'
const unsettledOrdersOnlyKey = 'unsettledOrdersOnly'

const setFilterInputs = (filters = []) => {
    localStorage.setItem(filtersStorageKey, JSON.stringify(filters))
}

const setFilterInputDate = (date) => {
    const value = date ? date.toJSON() : ''
    localStorage.setItem(dateFilterStorageKey, value)
}

const setLotosID = (id) => {
    localStorage.setItem(orderStorageLotosIDKey, JSON.stringify(id))
}

const setExcludeLotos = (check) => {
    localStorage.setItem(orderStorageExcludeLotosKey, JSON.stringify(check))
}

const setImportantOrdersFilter = (check) => {
    localStorage.setItem(importantOrdersKey, JSON.stringify(check))
}
const setUnfilledOrdersOnly = (check) => {
    localStorage.setItem(unsettledOrdersOnlyKey, JSON.stringify(check))
}

const getFilterInputDate = () => {
    const dateValue = localStorage.getItem(dateFilterStorageKey)

    if (!dateValue)
        return undefined

    return new Date(dateValue)
}

const getFilterInputs = () => {
    const inputsValue = localStorage.getItem(filtersStorageKey)

    if (!inputsValue)
        return undefined

    return JSON.parse(inputsValue)
}

const getLotosID = () => {
    const dataValues = localStorage.getItem(orderStorageLotosIDKey)

    if (!dataValues)
        return undefined

    return JSON.parse(dataValues)
}

const getLotosExcluded = () => {
    const dataValues = localStorage.getItem(orderStorageExcludeLotosKey)

    if (!dataValues)
        return undefined

    return JSON.parse(dataValues)
}

const getImportantOrdersFilter = () => {
    const dataValues = localStorage.getItem(importantOrdersKey)

    if (!dataValues)
        return undefined

    return JSON.parse(dataValues)
}

const getUnfilledOrdersOnly = () => {
    const dataValues = localStorage.getItem(unsettledOrdersOnlyKey)

    if (!dataValues)
        return undefined

    return JSON.parse(dataValues)
}

const clearFilter = () => {
    setFilterInputs([{ id: "time", value: null }])
}

const orderDao = {
    setFilterInputDate, setFilterInputs, setLotosID, setExcludeLotos, setImportantOrdersFilter, setUnfilledOrdersOnly,
    getFilterInputDate, getFilterInputs, getLotosID, getLotosExcluded, getImportantOrdersFilter, getUnfilledOrdersOnly,
    clearFilter
}

export default orderDao