import React from "react";
import AppRoute from "../../../../AppRoute";
import EditDriver from './EditDriver';

const EdytujRoute = () => {
  return <AppRoute
    app="operator"
    window="kierowcy"
    tab="edytuj/:id"
    component={EditDriver}
  />
};

export default EdytujRoute;
