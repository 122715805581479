import styles from "./orderTrain.module.scss";
import React, {memo} from "react";
import {IconButton, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {styled} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Undo as UndoIcon} from "@material-ui/icons";

const TrainInput = styled(TextField)({
    width: 200,
    margin: "16px 0 0 0",
    padding: "1px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const OrderTrain = (props) => {

    const showDefault = props.type === 'edit' && props.trainId.value !== props.trainId.default;
    const statusClass = showDefault ? '__touched' : '';

    return (
        <div className={styles["orderTrain" + statusClass]}>
            <div className={styles["orderTrain__input"]}>
                <TrainInput
                    label="Lokomotywa"
                    fullWidth
                    value={props.trainId.value}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showDefault && (
                                    <IconButton
                                        size="small"
                                        onClick={() => props.undoChanges('trainId', props.groupIndex, props.index)}
                                    >
                                        <UndoIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    onChange={(e) => {
                        props.setOrderData(
                            props.groupIndex,
                            props.index,
                            "trainId",
                            e.target.value
                        );
                    }}
                />
            </div>
            <div className="orderTrain__input">
                <Autocomplete
                    options={props.stations}
                    getOptionLabel={(address) => `${address.street} ${address.city}`}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={props.trainDestination.value}
                    onChange={(event, newValue) => {
                        props.setOrderData(
                            props.groupIndex,
                            props.index,
                            "trainDestination",
                            newValue
                        );
                    }}
                    renderInput={(params) => (
                        <TrainInput
                            {...params}
                            label="Trasa"
                            variant="standard"
                            fullWidth
                        />
                    )}
                />
            </div>
        </div>
    );
}

export default memo(OrderTrain, (prevProps, nextProps) => {
    return prevProps.trainId.value === nextProps.trainId.value &&
        prevProps.stations.length === nextProps.stations.length &&
        prevProps.trainDestination.value === nextProps.trainDestination.value
});
