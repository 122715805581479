import React from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Icon, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import HttpClient from "../../../../../HttpClient";
import LineString from "ol/geom/LineString";
import carMarker from "../../../../../img/car (1).png";
import homeMarker from "../../../../../img/Group 1032.svg";
import whiteMarker from "../../../../../img/marker_white.png"
import SocketConnection from "../../../../../socketIo";
import config from "../../../../../config";

const socket = new SocketConnection(config.socket_io_url + ':' + config.socket_io_port);
const middleColor = [29, 187, 237, 0.8]; //niebieski
const driverColor = [255, 255, 255, 0.3]

export default class CourseMap extends React.Component {
    constructor(props) {
        super(props);
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6;
        this.marker = whiteMarker;
        this.carMarker = carMarker;
        this.homeMarker = homeMarker;
        this.center = [19.479744, 52.068813];
        this.startCoords = null;
        this.endCoords = null;
        this.startPoint = null;
        this.endPoint = null;
        this.driverPoint = null;
        this.lineLayer = null;
        this.route = null;
        this.distance = null;
        this.state = {
            index: null,
            singleId: null,
            driverPosition: null,
        };
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
                maxZoom: 15
            }),
        });

        // //pobieranie aktualnej pozycji kierowców
        // socket.on('connect', () => {
        // })
        // socket.on('positions', (data) => {
        //     this.setState({driverPosition: JSON.parse(data)});
        // })

        const {courses} = this.props;

        if (courses) {
            // this.createMarker([courses[0].driver.addresses.lon, courses[0].driver.addresses.lat], `driverHome`, driverColor);

            // if (courses[0].driver.regNo) {
            //     this.createMarker([courses[0].driver.addresses.lon, courses[0].driver.addresses.lat], `${courses[0].driver.regNo}`, driverColor)
            // }

            for (let i = 0; i < courses.length; i++) {
                let startCoords;
                let coordsPack = "";
                if (courses[i].from) {
                    startCoords = [courses[i].from.lon, courses[i].from.lat];
                    this.createMarker(startCoords, `startPoint${i}`, middleColor, i);
                } else if (courses[i].worker) {
                    for (let j = 0; j < courses[i].worker.length; j++) {
                        let prevCoords = coordsPack;
                        let workerCoords = [courses[i].worker[j].lon, courses[i].worker[j].lat];
                        let coords = `;${courses[i].worker[j].lon},${courses[i].worker[j].lat}`;
                        coordsPack = `${prevCoords}${coords}`;
                        this.createMarker(workerCoords, `workerPoint${i}`, middleColor);
                    }
                }

                if (courses[i].to) {
                    startCoords = [courses[i].to.lon, courses[i].to.lat];
                    this.createMarker(startCoords, `endPoint${i}`, middleColor);
                } else if (courses[i].worker) {
                    for (let j = 0; j < courses[i].worker.length; j++) {
                        let prevCoords = coordsPack;
                        let workerCoords = [courses[i].worker[j].lon, courses[i].worker[j].lat];
                        let coords = `;${courses[i].worker[j].lon},${courses[i].worker[j].lat}`;
                        coordsPack = `${prevCoords}${coords}`;
                        this.createMarker(workerCoords, `workerPoint${i}`, middleColor);
                    }
                }

                if (startCoords && coordsPack) {
                    this.getRoutes(startCoords, coordsPack, `workerPoint${i}`, middleColor);
                }
            }
        }
    }

    // componentDidUpdate(prevProps, prevState, snapShot) {
    //     const {courses} = this.props;
    //     const {driverPosition} = this.state;
    //
    //     if (prevState.driverPosition !== driverPosition) {
    //         if (driverPosition && courses[0].driver.regNo === driverPosition.regNo) {
    //             this.moveMarker([driverPosition.lon, driverPosition.lat], `${courses.driver.reqNo}`, driverColor)
    //         }
    //     }
    // }
    //
    // componentWillUnmount() {
    //     socket.disconnect();
    // }


    getRoutes = async (startCoords, coordsPack, markerId, singleColor) => {
        const httpRequest = HttpClient.createRequest();
        const url = `${config.osrmURL}/route/v1/driving/${startCoords[0]},${startCoords[1]}${coordsPack}?steps=true&geometries=geojson&overview=full`;
        try {
            const response = await httpRequest.get(url);
            this.route = response.data;
            this.createRoute(this.route, markerId, singleColor);
        } catch (e) {
        }
    }

    createMarker = (coords, markerId, singleColor) => {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: markerId.includes("worker") || markerId.includes("start") || markerId.includes("end") ? this.marker : markerId.includes("driverHome") ? this.homeMarker : this.carMarker,
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                color: singleColor,
                opacity: 0.8,
                scale: 0.9,
            }),
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this[markerId] = this.map.getLayers().getLength();
        this.map.addLayer(iconVectorLayer);
    }

    createLine(prevStep, singleStep, markerId, singleColor) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        feature.setId(markerId);

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            })
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);
    }

    moveMarker(coords, markerId, singleColor) {
        if (!this[markerId]) {
            this.createMarker(coords, markerId, singleColor);
        }

        let feature = this.map
            .getLayers()
            .item(this[markerId])
            .getSource()
            .getFeatureById(markerId)

        if ((this.state.driverPosition && feature !== null && markerId === this.state.driverPosition.regNo) ||
            (feature !== null && markerId === "startPoint") ||
            (feature !== null && markerId === "endPoint")) {
            feature.setGeometry(
                new Point(transform(coords, "EPSG:4326", "EPSG:3857"))
            );
        }
    }

    createRoute(route, markerId, singleColor) {
        if (this.lineLayer) {
            this.map.removeLayer(this.map.getLayers().item(this.lineLayer));
        }

        let features = [];

        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]]
                    this.createLine(prevStep, singleStep, markerId, singleColor);
                }
            }
        }
        let source = new VectorSource({
            features: features,
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            }),
        });

        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.lineLayer = this.map.getLayers().getLength();
        this.map.addLayer(vector);
    }

    render() {
        return (
            <div style={{
                width: "100%",
                height: "100%",
            }}>
                <div
                    id="osm-map"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            </div>
        );
    }
}
