import HttpClient from "../../../../../HttpClient";

const fetchSettlementsDetails = (params) => {
    const httpRequest = HttpClient.createRequest();

    return httpRequest.get(`api/settlements/${params.orderId}`, undefined, true);
};

const fetchBillingModels = () => {
    const httpRequest = HttpClient.createRequest();

    return httpRequest.get(`api/billing-models`, undefined, true);
};

const fetchPlaceAddresses = (params) => {
    const httpRequest = HttpClient.createRequest();

    return httpRequest.get(`api/settlements/${params.orderId}/addresses`, undefined, true);
};

const fetchDrivers = () => {
    const httpRequest = HttpClient.createRequest();

    return httpRequest.get(`api/kierowca`, undefined, true);
};

const saveSettlement = (request) => {
    const httpRequest = HttpClient.createRequest();

    return httpRequest.post(`api/settlements`, request, undefined, true);
};

const settlementsApiService = {
    fetchDrivers,
    fetchSettlementsDetails,
    fetchBillingModels,
    fetchPlaceAddresses,
    saveSettlement,
};

export default settlementsApiService;
