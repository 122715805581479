import { getUnixTime } from "date-fns";
import Cookie from "js-cookie";

const loggedUser = Cookie.get('loggedUser')

const getDateTimeStrings = (dateObj) => {
  const dd = ("" + dateObj.getDate()).padStart(2, "0");
  const mm = ("" + (dateObj.getMonth() + 1)).padStart(2, "0");
  const yyyy = "" + dateObj.getFullYear();
  const date = `${dd}.${mm}.${yyyy}`;

  const hour = "" + dateObj.getHours();
  const minutes = ( "" + dateObj.getMinutes() ).padStart(2, "0");
  const time = `${hour}:${minutes}`;

  return [date, time];
};

const getDateString = (dateObj) => {
  const dd = ("" + dateObj.getDate()).padStart(2, "0");
  const mm = ("" + (dateObj.getMonth() + 1)).padStart(2, "0");
  const yyyy = "" + dateObj.getFullYear();
  const date = `${dd}.${mm}.${yyyy}`;
  return date;
};

const getStatementsTotalPrice = (statements) =>
  statements.reduce((prev, curr) => prev + curr.pricePerKm * curr.totalKm, 0);

const getExtrasTotalPrice = (extraData) => Object
    .entries(extraData)
    .map(([key, value]) => {
      if(key !== "paymentOnAccount") return value;
    })
    .reduce((prev, curr) => prev + curr, 0);

const getStatementTotalPrice = (statement) => {
  return statement.pricePerKm * statement.totalKm;
};

const parseStatements = (statements) =>
  statements.map((statement) => {
    const [arrivalDate, arrivalHour] = getDateTimeStrings(
      statement.arrivalDateTime
    );

    const [departureDate, departureHour] = getDateTimeStrings(statement.departureDateTime);

    const getTypeOfVehicle = () => {
      if(statement.vehicleType === 'Pojazd własny'){
        return {
          name: statement.vehicleType,
          regNo: statement.regNo,
          capacity: statement.capacity
        }
      } else {
        return {
          name: statement.name,
          regNo: '',
          capacity: ''
        }
      }
    }

    return {
      typeOfVehicle: getTypeOfVehicle(),
      departureCity: statement.departureCity ?? "",
      departureDate: departureDate ?? "",
      departureHour: departureHour ?? "",
      arrivalCity: statement.arrivalCity ?? "",
      arrivalDate: arrivalDate ?? "",
      arrivalHour: arrivalHour ?? "",
      client: statement?.client?.name ?? "",
      pricePerKm: statement.pricePerKm ? statement.pricePerKm : 0,
      totalKm: statement.totalKm ? statement.totalKm : 0,
      totalPrice: toFixed(getStatementTotalPrice(statement), 2) ?? 0,
    };
  });

const getClients = (statements) => {
  const doubledClients = statements.map((statement) => ({
    name: statement?.client?.name ?? "",
    totalCost: getStatementTotalPrice(statement) ?? 0,
  }));
  let clients = [];
  doubledClients.forEach((doubledClient) => {
    const indexOfGivenClient = clients.findIndex(
      (client) => client.name === doubledClient.name
    );
    if (indexOfGivenClient >= 0) {
      clients[indexOfGivenClient].cost += doubledClient.cost;
    } else {
      clients.push(doubledClient);
    }
  });
  return clients;
};

const toFixed = (number, fractionDigits) => {
  const parsed = parseFloat(number);
  if(parsed) {
    return parseFloat(parsed.toFixed(fractionDigits));
  }
  return number;
}

export const parseValues = (values) => {
  const extraData = {
    startedFor6: toFixed(values.startedFor6 ?? 0, 2),
    oneDayDelegation: toFixed(values.oneDayDelegation ?? 0, 2),
    longerDelegationStarted: toFixed(values.longerDelegationStarted ?? 0, 2),
    longerDelegationFull: toFixed(values.longerDelegationFull ?? 0, 2),
    accomodationPrice: toFixed(values.accomodationPrice ?? 0, 2),
    accomodationLumpSum: toFixed(values.accomodationLumpSum ?? 0, 2),
    otherCosts: toFixed(values.otherCosts ?? 0, 2)
  };

  const statementsTotalPrice = toFixed(getStatementsTotalPrice(values.statements) ?? 0, 2);
  const extrasTotalPrice = toFixed(getExtrasTotalPrice(extraData) ?? 0, 2);

  const toPay = toFixed((statementsTotalPrice + extrasTotalPrice) ?? 0, 2);

  const adminName = loggedUser.name + loggedUser.surName;

  const statements = parseStatements(values.statements);

  return {
    worker: `${values.worker.user.name} ${values.worker.user.surName}`,
    workerId: values.worker.id,
    position: values.position,
    dateFrom: getDateString(values.startDate),
    dateTo: getDateString(values.endDate),
    dateFromTimestamp: getUnixTime(values.startDate),
    dateToTimestamp: getUnixTime(values.endDate),
    createdAt: getDateString(values.createdAt),
    clients: getClients(values.statements),
    adminName,
    paymentOnAccount: toFixed(values.paymentOnAccount, 2),
    statementsTotalPrice : toFixed(statementsTotalPrice, 2),
    toPayInWords: "",
    toPayBefore: toFixed(toPay, 2),
    toPay: toFixed(toPay - values.paymentOnAccount, 2),
    extraData,
    statements,
  };
};
