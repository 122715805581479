import React from "react";
import styles from "./driversMapComponent.module.scss";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Fill, Icon, RegularShape, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import HttpClient from "../../../../HttpClient";
import LineString from "ol/geom/LineString";
import Overlay from "ol/Overlay.js";
import MapPopup from "./mapPopup/MapPopup";
import carMarker from "../../../../img/car (1).png";
import whiteMarker from "../../../../img/marker_white.png"
import SocketConnection from "../../../../socketIo";
import config from "../../../../config";

const socket = new SocketConnection(config.socket_io_url + ':' + config.socket_io_port);

export default class DriversMapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6.5;
        this.marker = whiteMarker;
        this.carMarker = carMarker;
        this.center = [19.479744, 52.068813];
        this.startCoords = null;
        this.endCoords = null;
        this.startPoint = null;
        this.endPoint = null;
        this.driverPoint = null;
        this.lineLayer = null;
        this.route = null;
        this.distance = null;
        this.state = {
            index: null,
            singleId: null,
            driverPosition: null,
        };
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
                maxZoom: 15
            }),
        });

        //pobieranie aktualnej pozycji kierowców
        // socket.on('connect', () => {
        // })
        // socket.on('positions', (data) => {
        //     this.setState({driverPosition: JSON.parse(data)});
        // })

    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        const {courses, drivers} = this.props;
        const {driverPosition} = this.state;
        const startColor = [102, 208, 29, 0.8]; //zielony
        const middleColor = [29, 187, 237, 0.8]; //niebieski
        const endColor = [29, 187, 237, 1];
        const driverColor = [255, 255, 255, 0.3]

        if (prevProps.drivers !== drivers) {
            for (let i = 0; i < drivers.length; i++) {
                this.createMarker([drivers[i].address[0].lon, drivers[i].address[0].lat], `${drivers[i].regNo ? drivers[i].regNo : `driver-${i}`}`, driverColor);
            }
        }

        // if (prevState.driverPosition !== driverPosition) {
        //     for (let i = 0; i < drivers.length; i++) {
        //         if (driverPosition && drivers[i].reqNo === driverPosition.regNo) {
        //             this.moveMarker([driverPosition.lon, driverPosition.lat], `${drivers[i].reqNo}`, driverColor)
        //         }
        //     }
        // }

        if (prevProps.courses !== courses ||
            prevProps.courses.worker !== courses.worker) {
            for (let i = 0; i < courses.length; i++) {
                let startCoords;
                let coordsPack = "";
                if (courses[i].from) {
                    startCoords = [courses[i].from.lon, courses[i].from.lat];
                    this.createMarker(startCoords, `startPoint${i}`, startColor, i);
                } else if (courses[i].worker) {
                    for (let j = 0; j < courses[i].worker.length; j++) {
                        let prevCoords = coordsPack;
                        let workerCoords = [courses[i].worker[j].lon, courses[i].worker[j].lat];
                        let coords = `;${courses[i].worker[j].lon},${courses[i].worker[j].lat}`;
                        coordsPack = `${prevCoords}${coords}`;
                        if (j === 0) {
                            this.createMarker(workerCoords, `workerPoint${i}`, startColor);
                        } else this.createPoint(workerCoords, `workerPoint${i}`, middleColor);
                    }
                }

                if (courses[i].to) {
                    startCoords = [courses[i].to.lon, courses[i].to.lat];
                    this.createMarker(startCoords, `endPoint${i}`, endColor);
                } else if (courses[i].worker) {
                    for (let j = 0; j < courses[i].worker.length; j++) {
                        let prevCoords = coordsPack;
                        let workerCoords = [courses[i].worker[j].lon, courses[i].worker[j].lat];
                        let coords = `;${courses[i].worker[j].lon},${courses[i].worker[j].lat}`;
                        coordsPack = `${prevCoords}${coords}`;
                        if (j === courses[i].worker.length - 1) {
                            this.createMarker(workerCoords, `endPoint${i}`, endColor, i);
                        } else this.createPoint(workerCoords, `workerPoint${i}`, middleColor);
                    }
                }

                if (startCoords && coordsPack) {
                    this.getRoutes(startCoords, coordsPack, `workerPoint${i}`, middleColor);
                }
            }

            // Popup
            let obPopup = document.getElementById('popup');
            let popup = new Overlay({
                element: obPopup,
                positioning: 'top-center',
                stopEvent: false,
                offset: [0, -50]
            });

            this.map.addOverlay(popup);

            this.map.on('click', (evt) => {
                    let feature = this.map.forEachFeatureAtPixel(evt.pixel,
                        function (feature) {
                            return feature;
                        }
                    );

                    if (feature) {
                        let coordinates = feature.getGeometry().getCoordinates();
                        popup.setPosition(coordinates);
                        this.map.addOverlay(popup);
                        let id = feature.id_;
                        let index;
                        if (id.includes("driverPoint") || id.includes("workerPoint")) {
                            index = parseInt(id.slice(11));
                            this.setState({index, singleId: id});
                        } else if (id.includes("startPoint")) {
                            index = parseInt(id.slice(10));
                            this.setState({index, singleId: id});
                        } else if (id.includes("endPoint")) {
                            index = parseInt(id.slice(8));
                            this.setState({index, singleId: id});
                        } else {
                            this.setState({singleId: id});
                        }
                    }
                }
            );

            let closer = document.getElementById('closer')
            closer.onclick = function () {
                popup.setPosition(undefined);
                closer.blur();
                return false;
            };

            this.map.on("pointermove", function (evt) {
                let hit = this.forEachFeatureAtPixel(evt.pixel, function () {
                    return true;
                });
                if (hit) {
                    this.getTargetElement().style.cursor = 'pointer';
                } else {
                    this.getTargetElement().style.cursor = '';
                }
            });
        }
    }

    // componentWillUnmount() {
    //     socket.disconnect();
    // }


    getRoutes = async (startCoords, coordsPack, markerId, singleColor) => {
        const httpRequest = HttpClient.createRequest();
        const url = `${config.osrmURL}/route/v1/driving/${startCoords[0]},${startCoords[1]}${coordsPack}?steps=true&geometries=geojson&overview=full`;
        try {
            const response = await httpRequest.get(url);
            this.route = response.data;
            this.createRoute(this.route, markerId, singleColor);
        } catch (e) {
        }
    }

    createMarker = (coords, markerId, singleColor) => {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: markerId.includes("worker") || markerId.includes("start") || markerId.includes("end") ? this.marker : this.carMarker,
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                color: singleColor,
                opacity: 0.8,
                scale: 0.9,
            }),
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this[markerId] = this.map.getLayers().getLength();
        this.map.addLayer(iconVectorLayer);
    }

    createPoint(coords, markerId, singleColor) {

        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        const strokeStyle = new Stroke({
            color: singleColor,
            width: 1.2
        })

        const regularShape = new RegularShape({
            fill: new Fill({
                color: singleColor,
            }),
            stroke: strokeStyle,
            points: 20,
            radius: 10
        })


        let style = new Style({
            image: regularShape
        })

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    moveMarker(coords, markerId, singleColor) {
        if (!this[markerId]) {
            this.createMarker(coords, markerId, singleColor);
        }

        let feature = this.map
            .getLayers()
            .item(this[markerId])
            .getSource()
            .getFeatureById(markerId)

        if ((this.state.driverPosition && feature !== null && markerId === this.state.driverPosition.regNo) ||
            (feature !== null && markerId === "startPoint") ||
            (feature !== null && markerId === "endPoint")) {
            feature.setGeometry(
                new Point(transform(coords, "EPSG:4326", "EPSG:3857"))
            );
        }
    }

    createLine(prevStep, singleStep, markerId, singleColor) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        feature.setId(markerId);

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            })
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);

    }

    createRoute(route, markerId, singleColor) {

        if (this.lineLayer) {
            this.map.removeLayer(this.map.getLayers().item(this.lineLayer));
        }

        let features = [];

        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]]
                    this.createLine(prevStep, singleStep, markerId, singleColor);
                }
            }
        }
        let source = new VectorSource({
            features: features,
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            }),
        });

        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.lineLayer = this.map.getLayers().getLength();
        this.map.addLayer(vector);
    }

    render() {
        return (
            <div style={{
                width: "100%",
                height: "100%",
            }}>
                <div
                    id="osm-map"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
                <div className={styles["driversMapComponent"]} id="popup" title="Welcome to OpenLayers">
                    <span className={styles["driversMapComponent__closer"]} id="closer">X</span>
                    <MapPopup
                        course={this.props.courses ? this.props.courses[this.state.index] : ''}
                        drivers={this.props.drivers ? this.props.drivers : ''}
                        ol_id={this.state.singleId ? this.state.singleId : ''}
                        index={this.state.index}
                    />
                </div>
            </div>
        );
    }
}
