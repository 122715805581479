import React from "react";
import AppRoute from "../../../../AppRoute";
import ImportOrders from "./ImportOrders";

const ImportOrdersRoute = () => {
    return (
        <AppRoute
            app="dyspozytor"
            window="transport"
            tab="importorders"
            component={ImportOrders}
        />
    );
};

export default ImportOrdersRoute;
