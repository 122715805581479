import React, {useState} from "react";
import styles from "./conversationModal.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import 'simplebar/dist/simplebar.min.css';
import Conversation from "../rozliczeniaDetails/conversation/Conversation";

const useStyles = makeStyles(() => ({
        root: {
            "& .MuiOutlinedInput-root": {
                borderRadius: 0
            }
        },
        dispatchWrapper: {
            width: "310px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        operatorWrapper: {
            width: "310px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        simplebar: {
            "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
                backgroundColor: "#b4b4b4",
            },
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            outline: "0 !important",
            position: "fixed",
            top: "6vh",
            width: "350px",
            height: "88vh",
            backgroundColor: "#FFFFFF",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
            padding: 15,
        },
        changeDriverButton: {
            color: "#1DBBED",
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "#1DBBED",
            borderRadius: "0",
            transition: "0.3s",
            marginBottom: "10px",
            width: "150px",
            "&:hover": {
                color: "white",
                backgroundColor: "#00a0e3",
            },
        },
        confirmButton: {
            color: "#66D01D",
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "#66D01D",
            borderRadius: "0",
            transition: "0.3s",
            margin: "10px 5px",
            width: "150px",
            "&:hover": {
                color: "white",
                backgroundColor: "#5AB71B",
            },
        },
        answearButton: {
            color: "#faba1f",
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "#faba1f",
            borderRadius: "0",
            transition: "0.3s",
            margin: "10px 5px",
            width: "150px",
            "&:hover": {
                color: "white",
                backgroundColor: "#E19205",
            },
        },

        operatorButton: {
            color: "#1DBBED",
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "#1DBBED",
            borderRadius: "0",
            transition: "0.3s",
            margin: "10px 5px",
            width: "150px",
            "&:hover": {
                color: "white",
                backgroundColor: "#00A0E3",
            },
        },
        conversationButton: {
            color: "#faba1f",
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "#faba1f",
            borderRadius: "0",
            transition: "0.3s",
            marginBottom: "10px",
            width: "150px",
            "&:hover": {
                color: "white",
                backgroundColor: "#E19205",
            },
        },
    }))
;

export default function ConversationModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        props.switchShowModal(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className={styles["conversationModal__description"]}>
                        <h2 className={styles["conversationModal__description--title"]}>
                            Konwersacja dla rozliczenia wprowadzonego ręcznie
                        </h2>
                        <span
                            className={styles["conversationModal__description--close"]}
                            onClick={handleClose}
                        >X</span>
                    </div>
                    <div className={styles["conversationModal__wrapper"]}>
                        <Conversation refreshTable={props.refreshTable} settlementId={props.settlementId}
                                      classes={classes}/>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
