import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {makeStyles} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

const useTableHeadStyles = makeStyles({
    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        padding: 0,
        width: 200,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px"
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
});

const ShowArrow = (props) => {
    const {sorted, order, classes} = props;
    if (sorted) {
        if (order === "ASC") {
            return <ArrowUpwardIcon fontSize={props.fontSize}/>;
        } else if (order === "DESC") {
            return <ArrowDownwardIcon fontSize={props.fontSize}/>;
        }
    } else {
        return <ArrowUpwardIcon fontSize={props.fontSize} className={classes.headCellArrow}/>;
    }
};

export default function EnhancedTableHeadToMemo(props) {
    const {
        headCells,
        rows,
        sortById,
        searchHandler,
        searchValues,
    } = props;
    const classes = useTableHeadStyles();



    const createSortHandler = (rows, headCell, cellIndex) => (event) => {
        const cells = [...headCells];
        cells.map((cell, index) => {
            if (headCell.id === cell.id) {
                if (headCell.order === "ASC") {
                    cell.order = "DESC";
                } else {
                    cell.sorted = true;
                    cell.order = "ASC";
                }
            } else {
                cell.sorted = false;
                cell.order = null;
            }

            return cell;
        });
        sortById();
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell, index) => {
                    return (
                        headCell.checked && (
                            <TableCell key={headCell.id}>
                                <TableSortLabel
                                    className={classes.tableSortLabel}
                                    hideSortIcon={true}
                                >
                                    <Input
                                        variant="outlined"
                                        disabled={headCell.id === 'distance'}
                                        className={classes.headCell}
                                        defaultValue={searchValues.find((v) => v.id === headCell.id)?.value}
                                        size="small"
                                        onChange={(e) => searchHandler(headCell.id, e.target.value)}
                                        onKeyDown={props.handleOnEnterPress}
                                        placeholder={headCell.label}
                                        endAdornment={
                                            <>
                                                <Tooltip title="Wyczyść">
                                                    <IconButton
                                                        disabled={headCell.id === 'distance'}
                                                        size="small"
                                                        onClick={() => props.handleClearColumn(headCell.id)}
                                                        aria-label="visible columns"
                                                    >
                                                        <ClearIcon
                                                            className={classes.headCellArrow}
                                                            fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Sortuj">
                                                    <IconButton
                                                        size="small"
                                                        onClick={createSortHandler(rows, headCell, index)}
                                                        aria-label="visible columns"
                                                    >
                                                        <ShowArrow
                                                            fontSize="small"
                                                            sorted={headCell.sorted}
                                                            order={headCell.order}
                                                            classes={classes}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                </TableSortLabel>
                            </TableCell>
                        )
                    );
                })}
                <TableCell/>
            </TableRow>
        </TableHead>
    );
}

