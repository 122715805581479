import React, {useContext, useState} from "react";

import { getIn, useFormikContext } from "formik";
import { TextField } from "@material-ui/core";

import { StyledAutocomplete } from "app/reusableComponents/orderPanel/orderLine/OrderLine";
import { FlexRow, ContractorWrapper } from "app/reusableComponents/newOrderPanel/styled";
import HttpClient from "HttpClient";
import { useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";
import {MimicContext} from "../../../../context";

const SelectKontrahent = ({ baseName, contractorID }) => {
    const [dispatchers, setDispatchers] = useState([]);
    const { setFieldValue, values } = useFormikContext();
    const currentOrder = getIn(values, baseName);
    const { contractors } = useOrderContext();
    const { isRoleType } = useContext(MimicContext)

    const fetchDispatcher = async (contractorId) => {
        if (contractorId) {
            setFieldValue(`${baseName}.dispatcherId`, null);
            setDispatchers([]);
            const httpRequest = HttpClient.createRequest();
            const { data } = await httpRequest.get(`api/dyspozytor/${contractorId}`, {}, true);

            const formatDispatchers = data.reduce(
                (acc, dispatcher) => [
                    ...acc,
                    { id: dispatcher.id, name: dispatcher.user.name, surName: dispatcher.user.surName },
                ],
                []
            );
            setDispatchers(formatDispatchers);
        } else setDispatchers([])
    };

    if (contractors.length === 0 || isRoleType('OPERATOR_CONTRACTOR')) return null;

    return (
        <FlexRow gap=".5rem" margin="0 0 10px 0">
            <ContractorWrapper>
                <StyledAutocomplete
                    options={contractors}
                    noOptionsText="Brak kontrahentów"
                    onChange={(_, newValue) => {
                        fetchDispatcher(newValue?.id);
                        setFieldValue(`${baseName}.contractor`, newValue);
                        contractorID(newValue?.id)
                    }}
                    getOptionSelected={(kontrahent, value) => kontrahent?.id === value?.id}
                    getOptionLabel={(kontrahent) => kontrahent.name}
                    value={currentOrder.contractor}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Kontrahent" variant="standard" margin="none" fullWidth />
                    )}
                />
            </ContractorWrapper>
            <ContractorWrapper>
                <StyledAutocomplete
                    noOptionsText="Brak dyspozytorów"
                    options={dispatchers}
                    onChange={(_, newValue) => {
                        setFieldValue(`${baseName}.dispatcher`, newValue);
                    }}
                    getOptionSelected={(dispatcher, value) => dispatcher?.id === value?.id}
                    getOptionLabel={(dispatcher) => `${dispatcher.name} ${dispatcher.surName}`}
                    sx={{ width: 300 }}
                    value={currentOrder.dispatcher}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Dyspozytor" variant="standard" margin="none" fullWidth />
                    )}
                />
            </ContractorWrapper>
        </FlexRow>
    );
};

export default SelectKontrahent;
