import React, { Fragment, useState } from "react";
import {ListItemIcon, ListItemText, Collapse, List, ListItem} from "@material-ui/core";
import AppWindowEntity from "../AppWindowEntity/AppWindowEntity";
import Dyspozytor_icon from "../../../img/Group 186.png";
import Operator_icon from "../../../img/Group 139.png";
import Pracownik_icon from "../../../img/Group 191.png";
import Administracja_icon from "../../../img/administracja_icon.svg";

const AppEntity = (props) => {
  const { app, data, classes } = props;
  const wins = Object.entries(data.windows);
  const [open, setOpen] = useState(data.defaultOpen);
  const toggleOpen = () => {
    setOpen(!open);
  };

  let appIcon;
  switch (app) {
      case 'dyspozytor':
          appIcon = Dyspozytor_icon;
          break;
      case 'operator':
          appIcon = Operator_icon;
          break
      case 'pracownik':
          appIcon = Pracownik_icon;
          break
      case 'administracja':
          appIcon = Administracja_icon;
          break
      default:
          appIcon = Operator_icon;
  }

  const items = wins.map(([win, data]) => {
    return (
      <AppWindowEntity
        key={win}
        app={app}
        alone={wins.length === 1}
        win={win}
        data={data}
        classes={classes}
      />
    );
  });

  return (
    <Fragment>
      <ListItem
        button={true}
        onClick={toggleOpen}
        className={open && props.open ? classes.appEntityOpen :  classes.appEntityClose}
      >
        <ListItemIcon>
          <img className={classes.appIcon} src={appIcon} alt="ikona menu"></img>
        </ListItemIcon>
        <ListItemText primary={data.label} className={classes.title} />
      </ListItem>
      <Collapse in={open && props.open ? open : false} timeout="auto" unmountOnExit>
        <List>{items}</List>
      </Collapse>
    </Fragment>
  );
};

export default AppEntity;
