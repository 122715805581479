import React from "react";
import styles from "./formHome.module.scss";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {styled} from "@material-ui/core/styles";
import AddAddressInOrder from "../../../addAddressInOrder/AddAddressInOrder";
import FormAlert from "../../../alerts/FormAlert";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import produce from "immer";

const SingleInput = styled(TextField)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isWorkAddress: false,
            workerIndex: null,
            errorAddAddress: false,
        };
        this.modalIndex = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props !== nextProps || this.state !== nextState;
    }

    alertReset = (value) => {
        this.setState(() => {
            return {
                errorAddAddress: value,
            };
        });
    };

    showAddAddressForm = (workerIndex) => {
        this.setState(() => {
            return {showModal: !this.state.showModal, workerIndex};
        });
    };

    updateAddress = (index, newAddress, cb) => {
        const worker = produce(this.props.data.worker, draftState => {
            if(this.props.data.isWorkAddress.value) {
                draftState.forEach(worker => {
                    worker.address.value = newAddress;
                });
            } else {
                draftState[index].address.value = newAddress;
            }
        });

        this.props.setOrderData(this.props.groupIndex, this.props.index, "worker", worker, cb);
    };

    changeOptions = (data) => {
        if (this.props.data.isWorkAddress.value) {
            return this.props.stations;
        } else {
            return data.person.value ? data.person.value.adres : []
        }
    }

    switchAddressType = () => {
        const isWorkAddress = this.props.data.isWorkAddress.value;
        this.props.setOrderData(this.props.groupIndex, this.props.index, "isWorkAddress", !isWorkAddress, () => {


            const worker = produce(this.props.data.worker, draftState => {
                draftState.forEach(worker => {
                    if(isWorkAddress) {
                        worker.address.value = worker.person.value?.adres[0] || null;
                    } else {
                        worker.address.value = null;
                    }
                }) ;
            });

            this.props.setOrderData(this.props.groupIndex, this.props.index, "worker", worker);
        });
    };

    handleErrorMessage = (index, data) => {
        if (!data.person.value) {
            this.setState(() => {
                return {errorAddAddress: true};
            });
            setTimeout(this.alertReset, 3000);
            return;
        }
        this.showAddAddressForm(index);
        this.modalIndex.current = index;
    }

    render() {
        const filter = createFilterOptions();
        const legend = this.props.data.isWorkAddress.value ? "Zamień adres na Dom" : "Zamień adres na Praca";
        const label = this.props.data.isWorkAddress.value ? 'Praca' : 'Dom';
        return (
            <div className={styles["formHome"]}>
                {this.props.data.worker.map((data, index) => {
                    return (
                        <div key={index} className={styles["formHome__input"]}>
                            <Autocomplete
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    filtered.unshift({
                                        inputValue: params.inputValue,
                                        street: `DODAJ`,
                                        city: "ADRES"
                                    });
                                    return filtered;
                                }}
                                options={this.changeOptions(data)}
                                getOptionLabel={(address) => `${address.street} ${address.city}`}
                                getOptionSelected={(option, value) => option.id === value.id}
                                style={{width: 200}}
                                value={data.address.value}
                                onChange={(e, newValue) => {
                                    if (
                                        newValue?.street === "DODAJ" && newValue?.city === "ADRES"
                                    ) {
                                        if (e) if (e.preventDefault) e.preventDefault();
                                        this.handleErrorMessage(index, data)
                                    } else {
                                        this.updateAddress(index, newValue, this.props.handleValidate);
                                    }
                                }}
                                renderInput={(params) => (
                                    <SingleInput
                                        {...params}
                                        error={
                                            data.address.empty && !this.props.data.isProposition
                                        }
                                        helperText={
                                            (data.address.empty && !this.props.data.isProposition) ? "Uzupełnij dane" : false
                                        }
                                        required={
                                            !this.props.data.isProposition
                                        }
                                        label={label}
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                            />
                            <p
                                className={styles["formHome__input--link"]}
                                onClick={this.switchAddressType}
                            >
                                {legend}
                            </p>
                        </div>
                    );
                })}

                {this.state.showModal && (
                    <AddAddressInOrder
                        formIndex={this.state.workerIndex}
                        groupIndex={this.props.groupIndex}
                        field="worker"
                        addToPropertyInOrder={this.props.data.isWorkAddress.value ? this.props.addWorkAddress : this.props.addWorkerAddress}
                        showAddAddressForm={this.showAddAddressForm}
                        url={this.props.data.isWorkAddress.value ? '/api/addresses' : '/api/pracownik/address'}
                        for={this.props.data.isWorkAddress.value ? 'work' : 'worker'}
                        pracownik={this.props.data.worker[this.modalIndex.current].person.value}
                        addWorkerAddress={this.props.addWorkerAddress}
                        handleErrorAlert={this.handleErrorAlert}
                        index={this.props.index}
                    />
                )}

                {this.state.errorAddAddress && (
                    <FormAlert
                        showAlert={true}
                        severity="error"
                        message={
                            this.state.errorAddAddress
                                ? "Uzupełnij pole Pracownik"
                                : "Nie można usunąć wszystkich pracowników"
                        }
                    />
                )}
            </div>
        );
    }
}
