import React, {memo, Suspense, useContext, useEffect, useReducer, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import DyspozytorRoute from "./dyspozytor/DyspozytorRoute";
import PracownikRoute from "./pracownik/PracownikRoute";
import {OperatorRoute} from "./operator/OperatorRoute";
import KierowcaRoute from "./kierowca/KierowcaRoute";
import UserRoute from "./user/UserRoute";
import "moment/locale/pl";
import {format} from "date-fns";
import {pl} from "date-fns/locale";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {Redirect} from "react-router-dom";
import AdministracjaRoute from "./administracja/AdministracjaRoute";
import Login from "./auth/Login";
import AsideNavigation from "../asideNavigation/AsideNavigation";
import HttpClient from "../HttpClient";
import {usePersistedState} from "../usePersistedState";
import TopNav from "../topNavigation/TopNav";
import {MimicContext, NotificationContext} from "../context";
import AdminRoute from "./admin/AdminRoute";
import Zlecenie from "./kierowca/zlecenie/Zlecenie";
import ResetPassword from "./reusableComponents/resetPassword/ResetPassword";
import Cookie from "js-cookie";


moment.locale("pl");

const localeMap = {
    pl: pl
};

class PLLocalizedUtilis extends DateFnsUtils {
    getCalendarHeaderText(date) {
        return format(date, "LLLL yyyy", {locale: this.locale});
    }

    getDatePickerHeaderText(date) {
        return format(date, "dd MMMM", {locale: this.locale});
    }
}

const AccessGrantedToMemo = () => {

    const {setRoles} = useContext(MimicContext);
    const {addFlash} = useContext(NotificationContext);
    const history = useHistory();

    const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
    const ROLE_USER = 'ROLE_USER';
    const ROLE_DYSPOZYTOR = 'ROLE_DYSPOZYTOR';
    const ROLE_PRACOWNIK = 'ROLE_PRACOWNIK';
    const ROLE_OPERATOR = 'ROLE_OPERATOR';
    const ROLE_OPERATOR_PARTNER = 'ROLE_OPERATOR_PARTNER';
    const ROLE_TMP = 'ROLE_TMP';
    const ROLE_KIEROWCA = 'ROLE_KIEROWCA';
    const ROLE_SUPER_OPERATOR = 'ROLE_SUPER_OPERATOR';
    const ROLE_ADMINISTRACJA = 'ROLE_ADMINISTRACJA';
    const ROLE_DYSPOZYTOR_ADMINISTRACJA = 'ROLE_DYSPOZYTOR_ADMINISTRACJA';
    const ROLE_KILOMETROWKA ='ROLE_KILOMETROWKA';
    const ROLE_DELEGACJE = 'ROLE_DELEGACJE';

    const redirects = {
        ROLE_SUPER_ADMIN: '/app/dyspozytor/transport/dodaj',
        ROLE_DYSPOZYTOR: '/app/dyspozytor/transport/dodaj',
        ROLE_PRACOWNIK: '/app/pracownik/kilometrowki/formularz',
        ROLE_OPERATOR: '/app/operator/trasy/aktywne',
        ROLE_OPERATOR_PARTNER: '/app/operator/trasy/aktywne',
        ROLE_TMP: '/app/operator/trasy/aktywne',
        ROLE_SUPER_OPERATOR: '/app/operator/trasy/aktywne',
        ROLE_KIEROWCA: '/app/kierowca/trasy/aktywne',
        ROLE_ADMINISTRACJA: '/app/administracja/rozliczenia/rozliczenia',
        ROLE_DYSPOZYTOR_ADMINISTRACJA: '/app/dyspozytor/transport/dodaj'
    }

    const [auth, setAuth] = useState({
        isGranted: false,
        roles: {},
    });

    const getRole = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/getRole', {}, true, false);
            const { roles, isGranted } = response.data;
            const rolesObj = {};
            roles.map(role => rolesObj[role] = role);
            setRoles(roles);
            if(roles?.length > 0 && isGranted) {
                setAuth({isGranted: true, roles: rolesObj});
            }
            // przekierowanie na zmiane hasla
            // if(passwordChange) {
            //     addFlash('Zostałeś przekierowany w celu zmiany hasła', {variant: 'info'});
            //     history.push('/app/user/account/profile');
            // }

        } catch (e) {}
    }

    useEffect(() => {
        getRole();
    }, []);

    const ProtectedRoute = ({routeRoles, route}) => {
        const { roles, isGranted } = auth;
        if(isGranted) {
            let createdRoute = null;
            routeRoles.some(role => {
                if(roles[role]) {
                    return createdRoute = route;
                }
            });
            return createdRoute;
        }
        return null;
    }

    const DefaultRedirect = () => {
        const { roles, isGranted } = auth;
        if(isGranted) {
            let redirectRoute = null;
            Object.keys(roles).some(role => {
                if(roles[role]) {
                    return redirectRoute = <Redirect from="/app" to={redirects[role]} />;
                }
            });
            return redirectRoute;
        }
        return null;
    }


    return (
        <MuiPickersUtilsProvider
            utils={PLLocalizedUtilis}
            libInstance={moment}
            locale={localeMap.pl}
        >
            <Route exact path="/">
                <DefaultRedirect />
            </Route>
            <Route exact path="/app">
                <DefaultRedirect />
            </Route>
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN]} route={<AdminRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_USER]} route={<UserRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_DYSPOZYTOR, ROLE_KILOMETROWKA]} route={<DyspozytorRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_OPERATOR, ROLE_ADMINISTRACJA, ROLE_DELEGACJE, ROLE_TMP]} route={<AdministracjaRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_OPERATOR, ROLE_OPERATOR_PARTNER]} route={<OperatorRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_PRACOWNIK]} route={<PracownikRoute />} />
            <ProtectedRoute routeRoles={[ROLE_SUPER_ADMIN, ROLE_KIEROWCA]} route={<KierowcaRoute />} />
            {/*<Route path="/app/notFound" component={() => 'Nieprawidłowy adres'} />*/}
            {/*<Redirect to="/app/notFound" />*/}
        </MuiPickersUtilsProvider>
    );
}

export const AccessGranted = memo(AccessGrantedToMemo, (prevProps, nextProps) => true);

const AppRouter = () => {
    const history = useHistory();
    const [authState, setAuthState] = usePersistedState('authState', false);

    HttpClient.overrideHistory(history);
    HttpClient.overrideSetAuthState(setAuthState);

    const loginRequest = async (data, setInvalidCredentials) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.post('api/login_check', data, {}, false, false);
            if (response.data.token) {
                Cookie.set('token', response.data.token);
                setAuthState(true);
            }
        } catch (e) {
            setInvalidCredentials();
        }
    }


    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path="/zlecenie/:orderId">
                    <Zlecenie/>
                </Route>
                <Route exact path="/reset-password">
                    <ResetPassword/>
                </Route>
                {authState && Cookie.get('token') ? (
                    <>
                        <TopNav />
                        <AsideNavigation />
                    </>
                ) : (
                    <>
                        <Route exact path="/">
                            <Login loginRequest={loginRequest} />
                        </Route>
                        <Route>
                            <Redirect to="/" />
                        </Route>
                    </>
                )}
            </Switch>
        </Suspense>
    );
};

export default AppRouter;
