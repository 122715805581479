import React, {useEffect, useState} from "react";
import styles from "./drivers.module.scss";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {getUnixTime, fromUnixTime, format, startOfDay, endOfDay} from "date-fns";
import {makeStyles, styled} from "@material-ui/core/styles";
import DriverDetails from "./driverDetails/DriverDetails";
import {DatePicker} from "@material-ui/pickers";
import Tooltip from '@material-ui/core/Tooltip';
import HttpClient from "../../../../HttpClient";
import {createMuiTheme, IconButton} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {ThemeProvider} from "@material-ui/styles";
import {pl} from "date-fns/locale";
import SingleCourseDetailsPopover
    from "./singleCourseDetailsPopover/SingleCourseDetailsPopover"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import axios from "axios";
import ProgressLine from "../../../reusableComponents/progressLine/ProgressLine";

const SimpleScroll = styled(SimpleBar)({
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
        backgroundColor: "#b4b4b4",
    },
});

const MoreButton = styled(IconButton)({
    backgroundColor: "#1DBBED",
    margin: "2px",
    "&:hover": {
        backgroundColor: "#00A0E3",
    }
});

const MoreIcon = styled(MoreHorizIcon)({
    color: "white"
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OnRouteIcon = styled(FiberManualRecordIcon)({
    color: "#1DBBED",
});

const DayOffIcon = styled(FiberManualRecordIcon)({
    color: "#d7d7d7",
});

const DateSwitcher = styled(DatePicker)({
    width: 180,
    fontFamily: "'Open Sans', sans-serif",
});

const hours = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
];

const Drivers = () => {
    const source = axios.CancelToken.source();
    const [isLoading, setIsLoading] = useState(false);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [api, setApi] = useState([]);
    const [singleDriver, setSingleDriver] = useState(null);
    const [singleCourse, setSingleCourse] = useState(null);
    const [singleCourseId, setSingleCourseId] = useState(null);
    const [singleStart, setSingleStart] = useState(null);
    const [singleMeta, setSingleMeta] = useState(null);
    const [singleDayOff, setSingleDayOff] = useState(null);

    const [showDriverDetails, setShowDriverDetails] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getBusyDrivers = async () => {
        let currentTime = currentDate;
        let startTime = getUnixTime(startOfDay(currentTime));
        let endTime = getUnixTime(endOfDay(currentTime));
        const httpRequest = HttpClient.createRequest();
        setIsLoading(true);

        try {
            const response = await httpRequest.get(`/api/zajeci-kierowcy?from=${startTime}&to=${endTime}`, {cancelToken: source.token}, true);
            setApi(response.data);
            setIsLoading(false);
        } catch (e) {
        }
    }

    const getSingleCourse = async () => {
        const url = `api/zlecenie/details/${singleCourseId}`
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {cancelToken: source.token}, true);
            setSingleCourse(response.data);
            setSingleDayOff(null);
        } catch (e) {
        }
    }

    const getSingleDriver = async (value) => {
        const url = `/api/kierowca/single/${value}`
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {cancelToken: source.token}, true);
            setSingleDriver(response.data.data);
        } catch (e) {
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusyDrivers();
        return () => {
            source.cancel();
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusyDrivers();
    }, [currentDate]);


    useEffect(() => {
        singleCourseId && getSingleCourse();
    }, [singleCourseId]);

    useEffect(() => {
        singleDriver && getSingleDriver()
    }, [showDriverDetails]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopup(false);
    };

    const switchShowModal = (value) => {
        return setShowDriverDetails(value);
    };

    return (
        <div className={styles["drivers"]}>
            <div className={styles["driversWrapper"]}>
                <div className={styles["drivers__description"]}>
                    <h1 className={styles["drivers__description--title"]}>
                        Harmonogram pracy kierowców
                    </h1>
                    <div className={styles["drivers__description--legend"]}>
                        <div className={styles["drivers__description--legend--legendItem"]}>
                            <OnRouteIcon/>
                            <p className={styles["drivers__description--legend--legendItem--singleLegend"]}>
                                W trasie
                            </p>
                        </div>
                        <div className={styles["drivers__description--legend--legendItem"]}>
                            <div
                                className={styles["drivers__description--legend--legendItem--singleLegend--icon"]}></div>
                            <p className={styles["drivers__description--legend--legendItem--singleLegend"]}>
                                Odpoczynek
                            </p>
                        </div>
                        <div className={styles["drivers__description--legend--legendItem"]}>
                            <DayOffIcon/>
                            <p className={styles["drivers__description--legend--legendItem--singleLegend"]}>
                                Urlop
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles["drivers__dateSwitcher"]}>
                    <div className={styles["drivers__dateSwitcher--calendarIcon"]}></div>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <DateSwitcher
                            autoOk={false}
                            format={"d.LLLL yyyy"}
                            ampm={false}
                            value={currentDate}
                            onChange={(newValue) => {
                                setCurrentDate(newValue)
                            }}
                            label="Wybierz datę"
                            fullWidth
                        ></DateSwitcher>
                    </ThemeProvider>
                </div>
                <SimpleScroll style={{maxHeight: "65vh"}}>
                    {isLoading ? <ProgressLine/> :
                        api && api.length > 0 && api.map((item, index) => {
                            let driverName = `${item.name ? item.name : "________"} ${
                                item.surname ? item.surname : "________"
                            }`;
                            const hourDistance = 4.166;
                            return (
                                <div className={styles["drivers__singleLine"]} key={index}>
                                    <div className={styles["drivers__singleLine--description"]}>
                                        <p className={styles["drivers__singleLine--description--name"]}>
                                            {driverName}
                                        </p>
                                    </div>

                                    <div className={styles["drivers__singleLine--schedule"]}>
                                        <div className={styles["drivers__singleLine--schedule--hours"]}>
                                            {hours.map((hour, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={styles["drivers__singleLine--schedule--hours--item"]}
                                                        style={{left: `${hourDistance * hour}%`}}
                                                    >
                                                        {hour}:00
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {item.courses.map((course, index) => {
                                            let start = fromUnixTime(course.startTime);
                                            let end = fromUnixTime(course.endTime);
                                            let startDay = start.getDate();
                                            let endDay = end.getDate();
                                            let currentDay = currentDate.getDate();
                                            let startHours = start.getHours();
                                            let startMinutes = start.getMinutes();
                                            let endHours = end.getHours();
                                            let endMinutes = end.getMinutes();
                                            const day = 1440;
                                            let startPosition;
                                            let endPosition;
                                            if (startDay < currentDay) {
                                                startPosition = 0;
                                            } else if (startDay > currentDay) {
                                                startPosition = 100;
                                            } else {
                                                startPosition =
                                                    ((startHours * 60 + startMinutes) / day) * 100;
                                            }
                                            if (endDay > currentDay) {
                                                endPosition = 100;
                                            } else if (endDay < currentDay) {
                                                endPosition = 0;
                                            } else {
                                                endPosition = ((endHours * 60 + endMinutes) / day) * 100;
                                            }

                                            let routeWidth = endPosition - startPosition;

                                            return (
                                                <div
                                                    className={styles["drivers__singleLine--schedule--onRoute"]}
                                                    key={index}
                                                    id={`onRoute${index}`}
                                                    aria-describedby={id}
                                                    onClick={(event) => {
                                                        const startFormat = format(fromUnixTime(course.startTime), "d LLLL yyyy, HH:mm", {locale: pl,});
                                                        const metaFormat = format(fromUnixTime(course.endTime), "d LLLL yyyy, HH:mm", {locale: pl,});
                                                        setOpenPopup(!openPopup);
                                                        setSingleStart(startFormat);
                                                        setSingleMeta(metaFormat);
                                                        setSingleCourseId(course.orderId);
                                                        setSingleDayOff(null);

                                                        handleClick(event);
                                                    }}
                                                    style={{
                                                        left: startPosition + "%",
                                                        width: routeWidth + "%",
                                                    }}
                                                ></div>
                                            );
                                        })}
                                        {item.dayOff && item.dayOff.map((item, index) => {
                                            let start = fromUnixTime(item.startAt);
                                            let end = fromUnixTime(item.finishAt);
                                            let startDay = start.getDate();
                                            let endDay = end.getDate();
                                            let currentDay = currentDate.getDate();
                                            let startHours = start.getHours();
                                            let startMinutes = start.getMinutes();
                                            let endHours = end.getHours();
                                            let endMinutes = end.getMinutes();
                                            const day = 1440;
                                            let startPosition;
                                            let endPosition;

                                            if (startDay < currentDay) {
                                                startPosition = 0;
                                            } else if (startDay > currentDay) {
                                                startPosition = 100;
                                            } else {
                                                startPosition =
                                                    ((startHours * 60 + startMinutes) / day) * 100;
                                            }
                                            if (endDay > currentDay) {
                                                endPosition = 100;
                                            } else if (endDay < currentDay) {
                                                endPosition = 0;
                                            } else {
                                                endPosition = ((endHours * 60 + endMinutes) / day) * 100;
                                            }

                                            let routeWidth = endPosition - startPosition;

                                            return (
                                                <div
                                                    className={styles["drivers__singleLine--schedule--dayOff"]}
                                                    key={index}
                                                    style={{
                                                        left: startPosition + "%",
                                                        width: routeWidth + "%",
                                                    }}
                                                    onClick={(event) => {
                                                        const startFormat = format(fromUnixTime(item.startAt), "d LLLL yyyy, HH:mm", {locale: pl,});
                                                        const metaFormat = format(fromUnixTime(item.finishAt), "d LLLL yyyy, HH:mm", {locale: pl,})
                                                        setOpenPopup(!openPopup);
                                                        setSingleStart(startFormat);
                                                        setSingleMeta(metaFormat);
                                                        setSingleDayOff(item);
                                                        handleClick(event);
                                                    }}
                                                ></div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles["drivers__singleLine--buttonContainer"]}>
                                        <BootstrapTooltip title="Szczegóły">
                                            <MoreButton
                                                size="small"
                                                onClick={() => {
                                                    setShowDriverDetails(!showDriverDetails);
                                                    getSingleDriver(item.id)
                                                }}
                                            >
                                                <MoreIcon/>
                                            </MoreButton>
                                        </BootstrapTooltip>
                                    </div>
                                </div>
                            );
                        })}
                </SimpleScroll>

                {showDriverDetails &&
                <DriverDetails
                    switchShowModal={switchShowModal}
                    api={singleDriver}
                />}

                {openPopup &&
                <SingleCourseDetailsPopover
                    anchor={anchorEl}
                    handleClose={handleClose}
                    openPopup={openPopup}
                    course={singleCourse}
                    dayOff={singleDayOff}
                    start={singleStart}
                    meta={singleMeta}
                />}
            </div>
        </div>
    );
}

export default Drivers;
