import { saveAs } from "file-saver";
import {format, fromUnixTime} from "date-fns";

const headers = [
  "Data",
  "Pracownik",
  "Trasa",
  "Km",
  "Stawka",
  "Postój",
  "Koszt postoju",
  "Koszt Km",
  "Suma",
  "Kontrahent",
];

export const downloadCsv = (data, startDate, endDate) => {
  let csv = headers.join(";");
  let allKmSum = 0;
  let allKmCostSum = 0;
  data.sort((a, b) => (a.date > b.date) ? 1 : -1)
  data.forEach((singleData) => {
    allKmSum += singleData.km;
    allKmCostSum += singleData.sum;

    const date = format(fromUnixTime(singleData.date), 'dd.MM.yyyy');

    singleData.km = singleData.km.toString().replaceAll('.', ',');
    singleData.rate = singleData.rate.toString().replaceAll('.', ',');
    singleData.stopCount = singleData.stopCount.toString().replaceAll('.', ',');
    singleData.stopCost = singleData.stopCost.toString().replaceAll('.', ',');
    singleData.sum = singleData.sum.toString().replaceAll('.', ',');

    const singleDataParsed = {
      ...singleData,
      date,
    };
    const dataValues = Object.values(singleDataParsed);

    dataValues.splice(dataValues.length - 1, 0, singleData.sum.toString().replaceAll('.', ','));
    const row = "\n" + dataValues.join(";");
    csv += row;
  });
  let BOM = "\uFEFF";
  csv += `\n;;Suma:;${allKmSum.toString().replaceAll('.', ',')};;0;0;${allKmCostSum.toFixed(2).toString().replaceAll('.', ',')};${allKmCostSum.toFixed(2).toString().replaceAll('.', ',')};`;
  csv = BOM + csv;
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

  startDate = format(startDate, 'dd.MM.yyyy');
  endDate = format(endDate, 'dd.MM.yyyy');

  saveAs(blob, `Zestawienie kilometrówek ${startDate}-${endDate}.csv`);
};
