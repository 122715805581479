import React, {useEffect, useState} from "react";
import styles from "./driverDetails.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Modal, Backdrop, Fade} from "@material-ui/core";
import DriversDetailsMap from "./DriversDetailsMap";

const useStyles = makeStyles(() => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "10vh",
        width: "80vw",
        maxHeight: "80vh",
        overflowX: "auto",
        overflowY: "auto",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
}));

export default function DriverDetails({api, switchShowModal}) {
    //modal
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [addressTable, setAddressTable] = useState(null);

    const handleClose = () => {
        switchShowModal(false);
        setAddressTable(null)
    };

    useEffect(() => {
        if (api) {
            setAddressTable(api.addresses);
        }
    }, [api]);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["driverDetails__data--description"]}>
                            <h2 className={styles["driverDetails__data--description--title"]}>
                                Szczegóły kierowcy
                            </h2>
                            <span
                                className={styles["driverDetails__data--description--close"]}
                                onClick={handleClose}
                            >X</span>
                        </div>
                        <div className={styles["driverDetails"]}>
                            {api ?
                                <div className={styles["driverDetails__data"]}>
                                    <div className={styles["driverDetails__data--item"]}>
                                        <div className={styles["driverDetails__data--item--wrapper"]}>
                                            <p className={styles["driverDetails__data--item--id"]}>
                                                Id pracownika
                                            </p>
                                            <p className={styles["driverDetails__data--item--idInfo"]}>
                                                {api.id ? api.id : "-"}
                                            </p>
                                            <p className={styles["driverDetails__data--item--id"]}>
                                                Tablice rejestracyjne
                                            </p>
                                            <p className={styles["driverDetails__data--item--idInfo"]}>
                                                {api.regNo ? api.regNo : "-"}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles["driverDetails__data--item"]}>
                                        <div className={styles["driverDetails__data--item--wrapper--person"]}>
                                            <h3 className={styles["driverDetails__data--item--wrapper--title"]}>
                                                Dane kontaktowe
                                            </h3>
                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Imię
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.firstname ? api.firstname : "-"}
                                            </p>

                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Nazwisko
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.surname ? api.surname : "-"}
                                            </p>
                                        </div>

                                        <div className={styles["driverDetails__data--item--wrapper--contact"]}>
                                            <h3 className={styles["driverDetails__data--item--wrapper--title"]}>
                                                Kontakt
                                            </h3>
                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Telefon służbowy:
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.workPhoneNumber
                                                    ? api.workPhoneNumber
                                                    : "-"}
                                            </p>

                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Telefon prywatny
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.privatePhoneNumber
                                                    ? api.privatePhoneNumber
                                                    : "-"}
                                            </p>

                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                E-mail
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.email ? api.email : "-"}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles["driverDetails__data--item"]}>
                                        <div className={styles["driverDetails__data--item--wrapper--address"]}>
                                            <h3 className={styles["driverDetails__data--item--wrapper--title"]}>
                                                Adres
                                            </h3>
                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Ulica
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.addresses.street
                                                    ? api.addresses.street
                                                    : "-"}
                                            </p>

                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Numer budynku
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.addresses.no ? api.addresses.no : "-"}
                                            </p>

                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Numer mieszkania
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {api.addresses.flat
                                                    ? api.addresses.flat
                                                    : "-"}
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--singleData"]}>
                                                Miasto
                                            </p>
                                            <p className={styles["driverDetails__data--item--wrapper--info"]}>
                                                {`${api.zipCode ? api.zipCode : ""} ${
                                                    api.addresses.city
                                                        ? api.addresses.city
                                                        : "-"
                                                }`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : <div>brak danych</div>}
                            <div className={styles["driverDetails__map"]}>
                                <DriversDetailsMap addresses={addressTable}/>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
