import React, {useState, useEffect} from "react";
import styles from './css/message.module.scss'

function Message(props) {
    const message = props.message
    const messageClassName = `${styles.messages} ` + (props.side ? styles.otherMessage : styles.myMessage)

    return (
        <div className={messageClassName}>
            {message}
        </div>
    )
}

export default Message