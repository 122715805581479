import React, {useContext} from "react";
import { Formik, Form, FieldArray } from "formik";
import { Order } from "app/reusableComponents/newOrderPanel/order";
import { ButtonCore, FlexRow, pallet, SimpleScroll, Loader } from "app/reusableComponents/newOrderPanel/styled";
import { Summary } from "app/reusableComponents/newOrderPanel/summary";
import { OrderSchema, OrderSchemaSetRole, baseOrder } from "app/reusableComponents/newOrderPanel/helpers";
import { OrderProvider, useOrderContext, OrderDataProvider } from "app/reusableComponents/newOrderPanel/hooks";
import loader from "img/orders_loading.gif";
import { v4 as uuid } from "uuid";
import {MimicContext} from "../../../context";


const OrderPanel = () => {
    const {roles} = useContext(MimicContext);
    const { status, defaultOrder, handleSubmit, isEditView, pushActiveRoute } = useOrderContext();
    OrderSchemaSetRole(roles)

    if (status === "loading") return <Loader src={loader} alt="loading" />;

    if (status === "error") return <p style={{ color: "black" }}>ERROR</p>;
    return (
        <SimpleScroll style={{maxHeight: "calc(100vh - 10px - 48px - 64px)", paddingBottom: "2em"}}>
            <FlexRow>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={defaultOrder}
                    onSubmit={handleSubmit}
                    validationSchema={OrderSchema}
                >
                    {({ values, errors }) => (
                        <OrderDataProvider>
                            <Form>
                                <FlexRow align="flexStart" gap="1rem">
                                    <div>
                                        <FieldArray name="orders">
                                            {({ push, remove, insert }) => (
                                                <>
                                                    {values.orders.map((order, orderIndex) => (
                                                        <Order
                                                            key={`${order}-${orderIndex}`}
                                                            removeOrder={remove}
                                                            insertOrder={insert}
                                                            orderIndex={orderIndex}
                                                        />
                                                    ))}
                                                    {isEditView ? (
                                                        <ButtonCore
                                                            bg_color={pallet.gray}
                                                            bg_hover_color={pallet.darkGray}
                                                            type="button"
                                                            onClick={pushActiveRoute}
                                                        >
                                                            Anuluj edycję
                                                        </ButtonCore>
                                                    ) : (
                                                        <ButtonCore
                                                            bg_color={pallet.gray}
                                                            bg_hover_color={pallet.darkGray}
                                                            type="button"
                                                            onClick={() => {
                                                                push({...baseOrder, uuid: uuid()});
                                                            }}
                                                        >
                                                            Dodaj nowe zamówienie
                                                        </ButtonCore>
                                                    )}
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                    <Summary />
                                </FlexRow>
                            </Form>
                        </OrderDataProvider>
                    )}
                </Formik>
            </FlexRow>
        </SimpleScroll>
    );
};

const OrderPanelWrapper = (props) => {
    return (
        <OrderProvider {...props}>
            <OrderPanel />
        </OrderProvider>
    );
};

export default OrderPanelWrapper;
