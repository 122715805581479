import React from "react";
import AppRoute from "../../../../AppRoute";
import EditAddress from "./EditAddress"

const EditAddressRoute = () => {
    return <AppRoute
        app="operator"
        window="adresy"
        tab="edytuj/:id"
        component={EditAddress}
    />
};

export default EditAddressRoute;
