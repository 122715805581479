import React, {useEffect, useState} from "react";

import { useFormikContext, FieldArray, ErrorMessage, getIn } from "formik";
import { TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { get } from "lodash";

import { StyledAutocomplete } from "app/reusableComponents/orderPanel/orderLine/OrderLine";
import { baseWorker, filterOptionTypes, customFilterOptions } from "app/reusableComponents/newOrderPanel/helpers";
import { ButtonIcon, ButtonText, FlexRow, WarningText, WorkersWrapper } from "app/reusableComponents/newOrderPanel/styled";
import AddWorkerModal from "app/reusableComponents/orderPanel/orderLine/orderWorker/AddWorkerModal/AddWorkerModal";
import { useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";
import {computeAttributesStride} from "ol/webgl/Helper";

export const OrderWorker = ({ orderIndex, baseName, contractorID, isEditView }) => {
    const { values, setFieldValue, errors, setErrors, setFieldTouched } = useFormikContext();
    const workersArray = get(values, baseName);
    const [openModal, setOpenModal] = useState(false);
    const [baseWorkers, setBaseWorkers] = useState(workersArray);
    const contractor = getIn(values, `orders[${orderIndex}].contractor`);
    const { addNewWorker, workers } = useOrderContext();
    let filteredWorkers;

    if (contractorID) {
        filteredWorkers = workers.filter((worker) => {
            return worker.companyId === contractorID
        })
    }

    useEffect(() => {
        workersArray.forEach((el, i) => {
            if (el.person?.companyId === contractorID || contractorID === undefined) return true

            setFieldValue(`${baseName}[${i}].person`, null);
            setFieldValue(`${baseName}[${i}].homeAddress`, null);
        })
    }, [contractorID])

    return (
        <FieldArray name={`orders[${orderIndex}].workers`}>
            {({ remove: innerRemove, insert }) => (
                <div className="order-worker">
                    {workersArray.map((_, workerIndex) => {
                        const isError = get(errors, `${baseName}[${workerIndex}].person`);
                        return (
                            <WorkersWrapper key={workerIndex}>
                                <div style={{ display: "flex", gap: "1rem" }}>
                                    <StyledAutocomplete
                                        options={filteredWorkers ?? workers}
                                        filterOptions={(opt, params) =>
                                            customFilterOptions(opt, params, filterOptionTypes.WORKER)
                                        }
                                        groupBy={(option) => option.companyName}
                                        onChange={(_, newValue) => {
                                            if (newValue?.add) {
                                                setOpenModal(true);
                                                return;
                                            }

                                            if (newValue?.id) {
                                                setFieldValue(`${baseName}[${workerIndex}].person`, newValue);
                                                setFieldValue(
                                                    `${baseName}[${workerIndex}].homeAddress`,
                                                    newValue?.adres[0]
                                                );
                                            }
                                            setFieldTouched(`${baseName}[${workerIndex}].person`, false, false)
                                            if(!!isError) {
                                                const newErrors = errors.orders.map((order, idx) => {
                                                    if(idx !== orderIndex) return order;

                                                    return  order.workers.map((worker, index) => {
                                                        if(index !== workerIndex) return worker;
                                                    }).filter((elem) => elem !== undefined)

                                                })
                                                setErrors({
                                                    orders: newErrors
                                                })
                                            }
                                        }}
                                        getOptionDisabled={(option) =>
                                            option.add
                                                ? false
                                                : workersArray.some((worker) => option.id === worker.person?.id) //TODO this move to addresses
                                        }
                                        getOptionSelected={(worker, value) =>
                                            value?.id ? worker.id === value.id : false
                                        }
                                        getOptionLabel={(worker) =>
                                            worker.name ? `${worker?.name} ${worker?.surName}` : ""
                                        }
                                        fullWidth
                                        value={workersArray[workerIndex]?.person}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    {...params}
                                                    error={!!isError}
                                                    label="Pracownik*"
                                                    variant="standard"
                                                    margin="none"
                                                    fullWidth
                                                />
                                                <ErrorMessage
                                                    name={`${baseName}[${workerIndex}].person`}
                                                    render={(message) => <WarningText>{message}</WarningText>}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <FlexRow>
                                    <ButtonIcon
                                        type="button"
                                        onClick={() => {
                                            insert(workerIndex + 1, baseWorker);
                                        }}
                                        disabled={workersArray.length >= 4}
                                    >
                                        <AddIcon />
                                    </ButtonIcon>

                                    <ButtonIcon
                                        type="button"
                                        onClick={() => {
                                            innerRemove(workerIndex);
                                        }}
                                        disabled={workersArray.length === 1}
                                    >
                                        <RemoveIcon />
                                    </ButtonIcon>
                                    <ButtonText>Dodaj/usuń pracownika</ButtonText>
                                </FlexRow>
                                <AddWorkerModal
                                    contractor={contractor}
                                    addDriverFromForm={(worker) => {
                                        const newWorker = {
                                            ...worker.user,
                                            id: worker.id,
                                            companyName: worker.companyName,
                                            companyId: worker.companyId,
                                        };
                                        setFieldValue(`${baseName}[${workerIndex}].homeAddress`, newWorker.adres[0]);
                                        setFieldValue(`${baseName}[${workerIndex}].person`, newWorker);
                                        addNewWorker(newWorker);
                                    }}
                                    closeDriverModal={() => setOpenModal(false)}
                                    open={openModal}
                                />
                            </WorkersWrapper>
                        );
                    })}
                </div>
            )}
        </FieldArray>
    );
};
