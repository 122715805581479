import React, {useState} from "react";
import styles from "./profileForm.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Button} from "@material-ui/core";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import PasswordChange from "./PasswordChange";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { phoneRegExp } from "../../../helper";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
    cancelButton: {
        minWidth: "200px",
        backgroundColor: "#b4b4b4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
}));

export default function ProfileForm(props) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const handleOpen = () => {
    setEdit(true);
  };

  const handleClose = (cb) => {
    if (cb) cb();
    setEdit(false);
  };

  const WorkerSchema = Yup.object().shape({
    name: Yup.string().trim().required("Uzupełnij to pole"),
    surName: Yup.string().trim().required("Uzupełnij to pole"),
    email: Yup.string()
      .trim()
      .required("Uzupełnij to pole")
      .email("Nieprawidłowy format adresu email"),
    privatePhoneNumber: Yup.string()
      .trim()
      .required("Uzupełnij to pole")
      .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
    workEmail: Yup.string()
      .trim()
      .required("Uzupełnij to pole")
      .email("Nieprawidłowy format adresu email"),
    workPhoneNumber: Yup.string().required("Uzupełnij to pole"),
  });

  return (
    <div className={styles["profile"]}>
      <div className={styles["profile__description"]}>
        <h1 className={styles["profile__description--title"]}>Edytuj profil</h1>
        <p className={styles["profile__description--subTitle"]}>
          Wypełnij wszystkie pola i zatwierdź
        </p>
      </div>
      <SimpleBar
        style={{ maxHeight: "70vh", minWidth: 580 }}
        className={classes.simplebar}
      >
        <Formik
          initialValues={props.profile}
          validationSchema={WorkerSchema}
          enableReinitialize
          onSubmit={(values, { setFieldError }) => {
            props.sendUserData(values, setFieldError, handleClose);
          }}
        >
          {({ values, errors, touched, resetForm }) => (
            <Form className={styles["profile__form"]}>
              <div className={styles["profile__form--personal"]}>
                <p className={styles["profile__form--personal--description"]}>
                  Dane osobowe
                </p>
                <div className={styles["profile__form--personal--inputs"]}>
                  <div
                    className={styles["profile__form--personal--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="name"
                      helperText={
                        !!errors.name && touched.name ? errors.name : ""
                      }
                      error={!!errors.name && touched.name}
                      label="Imię*"
                      disabled={!edit}
                      variant="standard"
                      style={{ width: 240 }}
                      margin="none"
                      fullWidth
                      as={TextField}
                    />
                  </div>
                  <div
                    className={styles["profile__form--personal--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="surName"
                      helperText={
                        !!errors.surName && touched.surName
                          ? errors.surName
                          : ""
                      }
                      error={!!errors.surName && touched.surName}
                      label="Nazwisko*"
                      variant="standard"
                      disabled={!edit}
                      style={{ width: 240 }}
                      margin="none"
                      fullWidth
                      as={TextField}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["profile__form--contact"]}>
                <p className={styles["profile__form--contact--description"]}>
                  Dane kontaktowe
                </p>
                <div className={styles["profile__form--contact--inputs"]}>
                  <div
                    className={styles["profile__form--contact--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="privatePhoneNumber"
                      helperText={
                        !!errors.privatePhoneNumber &&
                        touched.privatePhoneNumber
                          ? errors.privatePhoneNumber
                          : ""
                      }
                      error={
                        !!errors.privatePhoneNumber &&
                        touched.privatePhoneNumber
                      }
                      label="Telefon*"
                      variant="standard"
                      disabled={!edit}
                      style={{ width: 240 }}
                      margin="none"
                      fullWidth
                      as={TextField}
                    />
                  </div>
                  <div
                    className={styles["profile__form--contact--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="email"
                      value={values.email}
                      helperText={
                        !!errors.email && touched.email ? errors.email : ""
                      }
                      error={!!errors.email && touched.email}
                      label="E-mail*"
                      variant="standard"
                      style={{ width: 240 }}
                      margin="none"
                      disabled={!edit}
                      fullWidth
                      as={TextField}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["profile__form--contact"]}>
                <p className={styles["profile__form--contact--description"]}>
                  Dane kontaktowe służbowe
                </p>
                <div className={styles["profile__form--contact--inputs"]}>
                  <div
                    className={styles["profile__form--contact--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="workPhoneNumber"
                      helperText={
                        !!errors.workPhoneNumber && touched.workPhoneNumber
                          ? errors.workPhoneNumber
                          : ""
                      }
                      error={
                        !!errors.workPhoneNumber && touched.workPhoneNumber
                      }
                      label="Telefon służbowy*"
                      variant="standard"
                      style={{ width: 240 }}
                      margin="none"
                      disabled={!edit}
                      fullWidth
                      as={TextField}
                    />
                  </div>
                  <div
                    className={styles["profile__form--contact--inputs--field"]}
                  >
                    <Field
                      className={classes.root}
                      name="workEmail"
                      helperText={
                        !!errors.workEmail && touched.workEmail
                          ? errors.workEmail
                          : ""
                      }
                      error={!!errors.workEmail && touched.workEmail}
                      label="E-mail służbowy*"
                      variant="standard"
                      style={{ width: 240 }}
                      margin="none"
                      disabled={!edit}
                      fullWidth
                      as={TextField}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["aprofile__form--buttonWrapper"]}>
                {edit ? (
                  <div>
                    <Button
                      className={classes.confirmButton}
                      variant="contained"
                      type="submit"
                    >
                      Potwierdź
                    </Button>
                    <Button
                      onClick={() => handleClose(resetForm)}
                      style={{ marginLeft: 15 }}
                      className={classes.cancelButton}
                      variant="contained"
                    >
                      Anuluj edycję
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={handleOpen}
                    className={classes.confirmButton}
                    variant="contained"
                  >
                    Edytuj
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <PasswordChange classes={classes} />
      </SimpleBar>
    </div>
  );
}
