import React, { useState, useContext, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Cookie from "js-cookie";
import HttpClient from "../HttpClient";
import { useHistory } from "react-router-dom";
import { MimicContext } from "../context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#fff",
        boxShadow: "0px 0px 5px 0px rgb(181, 179, 179)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    toolbarGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toolbarItem: {
        fontSize: 14,
        color: "#1DBBED",
        fontWeight: "bold",
        margin: "0 10px",
        display: "flex",
        alignItems: "center",
    },
    username: {
        maxWidth: 200,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    iconButton: {
        color: "#575B55",
    },
}));

export default function TopNav() {
    const { oldToken, setOldToken, roles } = useContext(MimicContext);
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isInitialMount = useRef(true);
    const [user, setUser] = useState(undefined);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleRedirect = (url) => {
        history.push(url);
        handleClose();
    };

    const handleLogout = () => {
        const logout = HttpClient.createRequest();
        logout.logout();
    };

    useEffect(async () => {
        const httpRequest = HttpClient.createRequest();

        httpRequest.get("api/account", {}, true, false).then((response) => {
            setUser(response.data.user);
        });
    }, []);

    const stopMimic = () => {
        Cookie.set("token", oldToken);
        setOldToken(null);
        window.location.reload();
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarGroup}>
                        {oldToken && (
                            <Button color="secondary" onClick={stopMimic} className={classes.toolbarItem}>
                                Przestań się podszywać
                            </Button>
                        )}
                        {roles.find((role) => role === "ROLE_SUPER_ADMIN") && (
                            <Button
                                onClick={() => handleRedirect("/app/admin/mimic/change")}
                                className={classes.toolbarItem}
                            >
                                Przełącz użytkownika
                            </Button>
                        )}
                        <div className={classes.toolbarItem}>
                            {user && <div className={classes.username}>{`${user.name} ${user.surName}`}</div>}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle className={classes.iconButton} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleRedirect("/app/user/account/profile")}>Profil</MenuItem>
                                <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
