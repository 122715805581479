import React, { Fragment } from "react";
import AddAddressRoute from "./dodaj/AddAddressRoute";
import AddressListRoute from "./lista/AddressListRoute";
import EditAddressRoute from "./edytuj/EditAddressRoute";
import DeleteAddressRoute from "./usun/DeleteAddressRoute";

const AdresyRoute = () => {
    return (
        <Fragment>
            <AddAddressRoute />
            <AddressListRoute />
            <EditAddressRoute />
            <DeleteAddressRoute />
        </Fragment>
    );
};

export default AdresyRoute;
