import React, {Fragment} from 'react';
import AppRoute from "../../../../AppRoute";
import AddDriver from "./AddDriver";

const AddDriverRoute = () => {
    return (
        <Fragment>
            <AppRoute
                app="operator"
                window="kierowcy"
                tab="dodaj"
                component={AddDriver}
            />
        </Fragment>
    );
}

export default AddDriverRoute;