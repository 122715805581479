import React from "react";
import TableList from "../../../reusableComponents/tableList/TableList";

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Imię",
        checked: true,
        sorted: true,
        order: "ASC",
    },
    {
        id: "surName",
        numeric: false,
        disablePadding: false,
        label: "Nazwisko",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "workPhoneNumber",
        numeric: false,
        disablePadding: false,
        label: "Telefon służbowy",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "privatePhoneNumber",
        numeric: false,
        disablePadding: false,
        label: "Telefon prywatny",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "city",
        numeric: false,
        disablePadding: false,
        label: "Miasto",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "stamina",
        numeric: false,
        disablePadding: false,
        label: "Stamina",
        checked: true,
        sorted: false,
        order: null,
    },
    {
        id: "distance",
        numeric: true,
        disablePadding: false,
        label: "Odległość km.",
        checked: true,
        sorted: false,
        order: null,
    },
];

const ShowDrivers = () => {
    return <TableList headCells={headCells} url="/api/kierowca" label="Lista kierowców" mode="driver" />;
};

export default ShowDrivers;
