import { fromUnixTime } from "date-fns";
import { saveAs } from "file-saver";

const contractorHeaders = [
    "Data",
    "Pracownik",
    "Trasa",
    "Km",
    "Stawka",
    "Rabat",
    "Po rabacie",
    "Koszt km",
    "Postój",
    "Koszt postoju",
    "Autostrada",
    "Łącznie",
    "Oszczędności",
    "Id zlecenia",
    "Nr. zlecenia",
    "Połączone zlecenia",
    "Kontrahent",
    "Oddział"
];

const driverHeaders = [
    "Data",
    "Id zlecenia",
    "Zlecenia połączone",
    "Trasa",
    "Pracownik",
    "Kontrahent",
    "Model",
    "Stawka/km",
    "Km",
    "Koszt/km",
    "Postój",
    "Koszt postoju",
    "Autostrada",
    "Łącznie",
    "Kierowca",
    "Grupa rozliczeniowa",
    "Partner",
];

export const generateCsvForContractor = ({ items, sum }) => {
    let csv = contractorHeaders.join(";");
    items.forEach((singleData) => {
        const dateObj = fromUnixTime(singleData.date);
        const day = ("" + dateObj.getDate()).padStart(2, "0");
        const month = ("" + (dateObj.getMonth() + 1)).padStart(2, "0");
        const year = "" + dateObj.getFullYear();
        const dateString = `${day}.${month}.${year}`;
        const connectedOrders = singleData.connectedOrders.filter((el) => {
            return el !== singleData.number
        })
        let row = "\n";
        row += (singleData.date ? dateString : "-") + ";";
        row += (singleData.worker ?? "-") + ";";
        row += (singleData.route ?? "-") + ";";
        row += (singleData.km.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.rate.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.discount.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.rateAfterDiscount.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.kmCost.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.stopCount.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.stopCost.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.highway.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.sumCost.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.saveMoney.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.number ?? "-") + ";";
        row += singleData.orderNo ? `${singleData.orderNo};` : "-;";
        row += (connectedOrders ?? "-") + ";";
        row += (singleData.contractor ?? "-") + ";";
        row += (singleData.branch ?? "-") + ";";
        csv += row;
    });
    let sumRow = "\n;;Suma;";
    sumRow += sum.sumKm?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.normalRate?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.discount?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.rateAfterDiscount?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.kmCost?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.stopCount?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.stopCost?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.highway?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.sumCost?.toString().replace(".", ",") + ';' ?? "0" + ";";
    sumRow += sum.saveMoney?.toString().replace(".", ",") + ';' ?? "0" + ";";
    csv += sumRow;

    let BOM = "\uFEFF";
    csv = BOM + csv;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Rozliczenia.csv");
};

export const generateCsvForDriver = ({ data, sum }) => {
    let csv = driverHeaders.join(";");
    console.log(data)
    data.forEach((singleData) => {
        const dateObj = new Date(singleData.accountDate);
        const day = ("" + dateObj.getDate()).padStart(2, "0");
        const month = ("" + (dateObj.getMonth() + 1)).padStart(2, "0");
        const year = "" + dateObj.getFullYear();
        const dateString = `${day}.${month}.${year}`;
        let row = "\n";
        row += (singleData.accountDate ? dateString : "-") + ";";
        row += singleData.number ? `${singleData.number};` : ';';
        row += singleData.connectedOrders ? `${singleData.connectedOrders};` : '-;';
        row += (singleData.route ?? "-") + ";";
        row += (singleData.worker ?? "-") + ";";
        row += (singleData.contractor ?? "-") + ";";
        row += (singleData.driverBilling?.billingModelName ? singleData.driverBilling.billingModelName : singleData.billingModelName ?? "-") + ";";
        row += (singleData.normalRate.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.sumKm.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.costKm.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.waitingTime.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.waitingRate.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.highwayCost.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.sumCost.toString().replace(".", ",") ?? "-") + ";";
        row +=
            (singleData?.driverName || singleData?.driverSurname
                ? `${singleData?.driverName} ${singleData?.driverSurname}`
                : "-") + ";";
        row += (singleData.billingGroupName.toString().replace(".", ",") ?? "-") + ";";
        row += (singleData.consortiumMember ?? '-') + ';';
        csv += row;
    });
    let sumRow = "\n;;;;;;;Suma;";
    sumRow += sum.normalRate.toString().replace(".", ",") + ";";
    sumRow += sum.sumKm.toString().replace(".", ",") + ";";
    sumRow += sum.costKm.toString().replace(".", ",") + ";";
    sumRow += sum.waitingTime.toString().replace(".", ",") + ";";
    sumRow += sum.waitingRate.toString().replace(".", ",") + ";";
    sumRow += sum.highwayCost.toString().replace(".", ",") + ";";
    sumRow += sum.sumCost.toString().replace(".", ",") + ";";
    csv += sumRow;

    let BOM = "\uFEFF";
    csv = BOM + csv;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Rozliczenia.csv");
};
