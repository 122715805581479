//propsy do użycia:
//message
//alertReset = do wysłania false (zamknięcie modala), używane też przy powrocie pracownika

import React from "react";
import styles from "./actionBlocker.module.scss";
import { Dialog, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { fromUnixTime, format } from "date-fns";
import { pl } from "date-fns/locale";

const useStyles = makeStyles({
  title: {
    padding: 10,
    margin: 0,
  },
  backButton: {
    padding: "5px 15px",
    backgroundColor: "#777777",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
});

export default function ActionBlocker(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.alertReset(false);
  };

    return (
        <div className={styles["actionBlocker"]}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
          <span className={styles["actionBlocker__close"]} onClick={handleClose}>
            X
          </span>
        </DialogContent>
        <div className={styles["actionBlocker__content"]}>
          <p className={styles["actionBlocker__content--description"]}>{props.message}</p>

          {props.blockedDrivers.map((item, index) => {
            let fromUnixCreateTime = fromUnixTime(item.destinationTime);
            let createDate = format(fromUnixCreateTime, "d LLLL yyyy, HH:mm", {
              locale: pl,
            });
            return (
              <DialogContent key={index}>
                <div className={styles["actionBlocker__content--item"]}>
                  <p className={styles["actionBlocker__content--item--singleData"]}>
                    {`Imię i nazwisko: ${item.name} ${item.surName}`}
                  </p>
                  <p className={styles["actionBlocker__content--item--singleData"]}>
                    {`Adres odbioru: ${
                      item.pickupStreet ? item.pickupStreet : ""
                    } ${item.pickupNo ? item.pickupNo : ""} ${
                      item.pickupFlat ? `m. ${item.pickupFlat}` : ""
                    }, ${item.pickupZipCode ? item.pickupZipCode : ""} ${
                      item.pickupCity ? item.pickupCity : ""
                    }`}
                  </p>
                  <p className={styles["actionBlocker__content--item--singleData"]}>
                    {`Adres docelowy: ${
                      item.destinationStreet ? item.destinationStreet : ""
                    } ${item.destinationNo ? item.destinationNo : ""} ${
                      item.destinationFlat ? `m. ${item.destinationFlat}` : ""
                    }, ${
                      item.destinationZipCode ? item.destinationZipCode : ""
                    } ${item.destinationCity ? item.destinationCity : ""}`}
                  </p>
                  <p className={styles["actionBlocker__content--item--singleData"]}>
                    {`Data: ${createDate}`}
                  </p>
                </div>
              </DialogContent>
            );
          })}
          <DialogActions>
            <div className={styles["actionBlocker__content--buttonWrapper"]}>
              <Button
                className={classes.backButton}
                margin="normal"
                onClick={handleClose}
              >
                Popraw
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
