import React from "react";
import { Route } from "react-router-dom";

const AppRoute = ({ app, window, tab, component }) => {

  const target = `/app/${app}/${window}/${tab}`;

  return <Route path={target} exact component={component} />;
};

export default AppRoute;
