import React from 'react';
import MimicRoute from "./mimic/MimicRoute";

const AdminRoute = () => {
    return <>
        <MimicRoute />
    </>;
}

export default AdminRoute;
