import React from "react";
import ArchiveDashboard from "./ArchiveDashboard";
import AppRoute from "../../../../AppRoute";

const ArchiveDashboardRoute = () => {
    return (
        <AppRoute app="operator" window="trasy" tab="archiveDashboard" component={ArchiveDashboard}/>
    );
};

export default ArchiveDashboardRoute;
