import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function OrderContractor({
    order,
    groupIndex,
    orderIndex,
    contractors,
    setOrderData,
    getDataDispatchers,
}) {
    const updateValue = (e, newValue) => {
        setOrderData(groupIndex, orderIndex, "contractor", newValue, () => {
            getDataDispatchers(groupIndex, orderIndex);
        });
    };

    return (
        <Autocomplete
            options={contractors}
            onChange={updateValue}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            value={order.contractor.value}
            style={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        "data-id": `contractorSelect-orderIndex_${orderIndex}-groupIndex_${groupIndex}`,
                    }}
                    label="Kontrahent"
                    variant="standard"
                    margin="none"
                    fullWidth
                />
            )}
        />
    );
}
