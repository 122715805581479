import React, { useState } from "react";
import styles from "./setRoute.module.scss";
import { TextField, Button, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import { styled } from "@material-ui/core/styles";
import axios from "axios";
import { debounce } from "../../../../../debounce";
import HttpClient from "../../../../../HttpClient";
import config from "../../../../../config";
import MapIcon from "@material-ui/icons/Map";

const RailyCheckbox = withStyles({
    root: {
        color: "#1dbbed",
        "&$checked": {
            color: "#1dbbed",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ConfirmButton = styled(Button)({
    backgroundColor: "#1DBBED",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
    margin: `0 10px`,
});

const SetRoute = (props) => {
    const [sourceAddress, setSourceAddress] = useState(null);
    const [destinationAddress, setDestinationAddress] = useState(null);
    const [isAvoidHighwaysChecked, setIsAvoidHighwaysChecked] = useState(false);
    const [isAvoidTollRoadsChecked, setIsAvoidTollRoadsChecked] = useState(false);
    const [sourceAddressSearchOptions, setSourceAddressSearchOptions] = useState([]);
    const [destinationAddressSearchOptions, setDestinationAddressSearchOptions] = useState([]);
    const [distance, setDistance] = useState(null);
    const [isMinimalized, setIsMinimalized] = useState(true);
    const [validationError, setValidationError] = useState({
        sourceAddress: "",
        destinationAddress: "",
    });

    let actualAxiosRequest = null;

    const handleSourceAddressChange = async (value) => {
        // setIgnoreForm(true);
        if (actualAxiosRequest) {
            actualAxiosRequest.cancel();
        }
        actualAxiosRequest = axios.CancelToken.source();
        const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {
                cancelToken: actualAxiosRequest.token,
            });
            const { data } = response;
            const _addressSearch = [...sourceAddressSearchOptions];
            const options = [];

            console.log(data);

            data.map((item) => {
                options.push({
                    id: item.place_id,
                    name: item.display_name,
                    address: item.address,
                    lat: item.lat,
                    lon: item.lon,
                });
            });

            setSourceAddressSearchOptions(options);
        } catch (e) {
            if (axios.isCancel(e)) {
                actualAxiosRequest = null;
            }
        }
    };

    const handleDestinationAddressChange = async (value) => {
        // setIgnoreForm(true);
        if (actualAxiosRequest) {
            actualAxiosRequest.cancel();
        }
        actualAxiosRequest = axios.CancelToken.source();
        const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {
                cancelToken: actualAxiosRequest.token,
            });
            const { data } = response;
            const options = [];

            data.map((item) => {
                options.push({
                    id: item.place_id,
                    name: item.display_name,
                    address: item.address,
                    lat: item.lat,
                    lon: item.lon,
                });
            });

            setDestinationAddressSearchOptions(options);
        } catch (e) {
            if (axios.isCancel(e)) {
                actualAxiosRequest = null;
            }
        }
    };

    const searchSourceAddresses = debounce((value) => {
        handleSourceAddressChange(value);
    }, 2000);

    const searchDestinationAddresses = debounce((value) => {
        handleDestinationAddressChange(value);
    }, 2000);

    const fetchRoute = () => {
        if (!validateForm()) {
            return;
        }

        let coordsPack = `${sourceAddress.lon},${sourceAddress.lat};${destinationAddress.lon},${destinationAddress.lat}`;

        const toExclude = [];

        if (isAvoidTollRoadsChecked) {
            toExclude.push(`toll`);
        }
        if (isAvoidHighwaysChecked) {
            toExclude.push(`motorway`);
        }

        const params = {
            steps: true,
            geometries: "geojson",
            overview: "full"
        };
        if (toExclude.length) {
            params["exclude"] = toExclude.join(",");
        }

        const urlParams = new URLSearchParams(params).toString();

        const url = `${config.osrmURL}/route/v1/driving/${coordsPack}?${urlParams}`;
        const httpRequest = HttpClient.createRequest();

        httpRequest.get(url).then((response) => {
            setDistance(response.data.routes[0].distance / 1000);
            props.onSelectRoute(response.data.routes[0].geometry.coordinates);
        });
    };

    const validateSourceAddress = () => {
        if (!sourceAddress) {
            setValidationError({
                ...validationError,
                sourceAddress: "Uzupełnij to pole",
            });
            return false;
        }
        setValidationError({
            ...validationError,
            sourceAddress: "",
        });
        return true;
    };

    const validateDestinationAddress = () => {
        if (!destinationAddress) {
            setValidationError({
                ...validationError,
                destinationAddress: "Uzupełnij to pole",
            });

            return false;
        }
        setValidationError({
            ...validationError,
            destinationAddress: "",
        });
        return true;
    };

    const validateForm = () => {
        const isSourceAddressValid = validateSourceAddress();
        const isDestinationAddressValid = validateDestinationAddress();

        return isSourceAddressValid && isDestinationAddressValid;
    };

    if (isMinimalized) {
        return (
            <IconButton
                size="small"
                type="button"
                onClick={() => {
                    setIsMinimalized(false);
                }}
                className={props.parent ? styles["setRoute__maximalize--buttonSettlements"] : styles["setRoute__maximalize--button"]}
            >
                <MapIcon className={styles["setRoute__maximalize--icon"]} />
            </IconButton>
        );
    }

    return (
        <div className={props.parent ? styles["setRouteSettlements"] : styles["setRoute"]}>
            <div className={styles["setRoute__header"]}>
                <h2 className={styles["setRoute__header--title"]}>Wyznacz trasę</h2>
                <button className={styles["setRoute__header--close"]} onClick={() => setIsMinimalized(true)}>
                    X
                </button>
            </div>
            <div className={styles["setRoute__form"]}>
                <Autocomplete
                    options={sourceAddressSearchOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    noOptionsText="Nie znaleziono podanego adresu"
                    filterOptions={(options) => options}
                    onChange={(_e, value) => {
                        setSourceAddress(value);
                    }}
                    onBlur={validateSourceAddress}
                    onInputChange={(e, newValue) => {
                        if (newValue.trim()) searchSourceAddresses(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={styles["setRoute__input"]}
                            label="Punkt A"
                            variant="filled"
                            margin="none"
                            name="sourceAddress"
                            fullWidth
                            error={validationError.sourceAddress}
                        />
                    )}
                />
                <Autocomplete
                    options={destinationAddressSearchOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    noOptionsText="Nie znaleziono podanego adresu"
                    filterOptions={(options) => options}
                    onChange={(_e, value) => {
                        setDestinationAddress(value);
                    }}
                    onBlur={validateDestinationAddress}
                    onInputChange={(_e, newValue) => {
                        if (newValue.trim()) searchDestinationAddresses(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={styles["setRoute__input"]}
                            label="Punkt B"
                            variant="filled"
                            margin="none"
                            name="sourceAddress"
                            fullWidth
                            error={validationError.destinationAddress}
                        />
                    )}
                />
                <div className={styles["setRoute__checkbox"]}>
                    <FormControlLabel
                        className={styles["setRoute__control"]}
                        control={
                            <RailyCheckbox
                                checked={isAvoidHighwaysChecked}
                                onChange={() => setIsAvoidHighwaysChecked((curr) => !curr)}
                                name="isAvoidHighwaysChecked"
                                color="primary"
                            />
                        }
                        label="Unikaj autostrad"
                    />
                </div>
                <div className={styles["setRoute__checkbox"]}>
                    <FormControlLabel
                        className={styles["setRoute__control"]}
                        control={
                            <RailyCheckbox
                                checked={isAvoidTollRoadsChecked}
                                onChange={() => setIsAvoidTollRoadsChecked((curr) => !curr)}
                                color="primary"
                            />
                        }
                        label={"Unikaj dróg płatnych"}
                    />
                </div>

                <ConfirmButton variant="contained" type="submit" onClick={fetchRoute}>
                    Wyznacz
                </ConfirmButton>

                {distance && <div className={styles["setRoute__info"]}>Dystans: {distance.toFixed(2)}km</div>}
            </div>
        </div>
    );
};

export default SetRoute;
