/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import HttpClient from "HttpClient";
import {
    baseOrder,
    entityType,
    orderViewType,
    routeType,
    statusType,
    formatEditOrders,
    prepareOrders,
} from "app/reusableComponents/newOrderPanel/helpers";
import Axios from "axios";

const useOrders = (type, orderID, entity) => {
    const [status, setStatus] = useState(statusType.IDLE);
    const [workers, setWorkers] = useState([]);
    const [workAddresses, setWorkAddresses] = useState([]);
    const [contractors, setContractors] = useState([]);
    const [defaultOrder, setDefaultOrder] = useState({
        orders: [{ ...baseOrder, uuid: uuid() }],
        isConnected: false,
    });
    const isEditView = type === orderViewType.EDIT;
    const history = useHistory();
    const { id } = useParams();

    const handleSubmit = async (values) => {
        setStatus(statusType.SENDING);
        const endpoint = {
            url: type === orderViewType.EDIT ? "api/zlecenie-edit" : "api/dyspozytor/transport/dodaj",
            method: type === orderViewType.EDIT ? "PUT" : "POST",
        };

        const postData = values.orders.reduce(prepareOrders, {
            isConnected: values.isConnected,
            data: [],
        });

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.builder(endpoint.method, endpoint.url, { postData }, null, true);
            setStatus(statusType.IDLE);
            if (response.status === 200) {
                pushActiveRoute();
            }
        } catch (e) {
            console.log(e);
        }

        console.log(postData);
    };

    const addNewWorker = useCallback((worker) => {
        setWorkers((allWorkers) => [...allWorkers, worker]);
    }, []);

    const addNewWorkAddress = useCallback((address) => {
        setWorkAddresses((addresses) => [...addresses, address]);
    }, []);

    const setDefaultOrderData = (ordersData, workAddresses) => {
        const orders = ordersData.reduce(formatEditOrders(workAddresses), []);
        setDefaultOrder({
            isConnected: orders.length > 1,
            orders,
        });
    };

    useEffect(() => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const httpRequest = HttpClient.createRequest();
        const getWorkersData = () => {
            setStatus(statusType.LOADING);
            const workersList = httpRequest.get("api/pracownik/list", { cancelToken: source.token }, true);
            const addressesList = httpRequest.get("api/addresses", { cancelToken: source.token }, true);
            const order = orderID ? httpRequest.get(`api/zlecenie-edit/${orderID}`, { cancelToken: source.token }, true) : null;
            const connectedOrders = createConnectedOrdersRequest(source.token);
            const allKontrahent = createKontrahentRequest(source.token);

            Promise.all([workersList, addressesList, connectedOrders, allKontrahent, order])
                .then(([workersList, addressesList, connectedOrders, allKontrahent,
                           order]) => {
                    const workAddresses = addressesList.data.data;

                    if (allKontrahent?.status === 200) {
                        const formattedContractors = allKontrahent.data.reduce(
                            (acc, kontrahent) => [...acc, { id: kontrahent.id, name: kontrahent.name }],
                            []
                        );
                        setContractors(formattedContractors);
                    }

                    if (connectedOrders?.status === 200) {
                        const helper = [order.data];
                        if (connectedOrders.data.length > 0) {
                            connectedOrders.data.map((connectedOrder) => helper.push(connectedOrder));
                        }
                        setDefaultOrderData(helper, workAddresses);
                    }
                    const mappedWorkers = workersList.data
                        .reduce(
                            (acc, curr) => [
                                ...acc,
                                {
                                    ...curr.user,
                                    id: curr.id,
                                    userId: curr.user.id,
                                    companyName: curr.companyName,
                                    companyId: curr.companyId,
                                },
                            ],
                            []
                        )
                        .sort((a, b) => -b.companyName.localeCompare(a.companyName));
                    setWorkAddresses(workAddresses);
                    setWorkers(mappedWorkers);
                    setStatus(statusType.LOADED);
                })
                .catch((err) => {
                    console.log(err);
                    setStatus(statusType.ERROR);
                });
        };

        getWorkersData();

        return () => source.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createConnectedOrdersRequest = (cancelToken) => {
        if (!id && !isEditView) return new Promise((resolve) => resolve(false));

        const httpRequest = HttpClient.createRequest();

        return httpRequest.get(`api/order/find-connect/${id}`, { cancelToken }, true);
    };

    const createKontrahentRequest = () => {
        if (entity !== entityType.OPERATOR) return new Promise((resolve) => resolve(true));

        const httpRequest = HttpClient.createRequest();
        return httpRequest.get(`api/allKontrahents`, {}, true);
    };

    const pushActiveRoute = () => {
        history.push(
            `/app/${entity}/${entity === entityType.DYSPOZYTOR ? routeType.TRANSPORT : routeType.TRASY}/aktywne`
        );
    };

    return {
        status,
        defaultOrder,
        handleSubmit,
        workAddresses,
        isEditView,
        workers,
        addNewWorker,
        pushActiveRoute,
        addNewWorkAddress,
        contractors,
    };
};

const OrderContext = createContext({});

export const useOrderContext = () => {
    const ctx = useContext(OrderContext);
    if (!ctx) throw new Error("useOrderContext must be used within OrderContextProvider");
    return ctx;
};

export const OrderProvider = ({ type, location, entity, children }) => {
    return (
        <OrderContext.Provider value={useOrders(type, location.state?.order.id, entity)}>{children}</OrderContext.Provider>
    );
};
