import React from "react";
import Archive from "./Archive";
import AppRoute from "../../../../AppRoute";

const ArchiveRoute = () => {
  return (
    <AppRoute
        app="dyspozytor"
        window="transport"
        tab="archiwum"
        component={Archive}
    />
  );
};

export default ArchiveRoute;
