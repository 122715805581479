import React from "react";
import OrderPanelNew from "../../../reusableComponents/newOrderPanel/OrderPanel";
import AppRoute from "../../../../AppRoute";

const EdytujRouteNew = () => {
    const EditOrderPanel = (props) => <OrderPanelNew entity="dyspozytor" {...props} type="edit" />;
    return <AppRoute app="dyspozytor" window="transport" tab="edytuj/:id" component={EditOrderPanel} />;
};

export default EdytujRouteNew;
