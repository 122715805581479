import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Icon, Stroke, Style, RegularShape, Fill } from "ol/style";
import OSM from "ol/source/OSM";
import { fromLonLat, transform } from "ol/proj";
import LineString from "ol/geom/LineString";
import { NotificationContext } from "../../../../../context";
import HttpClient from "../../../../../HttpClient";
import carMarker from "../../../../../img/car (1).png";
import whiteMarker from "../../../../../img/marker_white.png";
import config from "../../../../../config";

export default class CourseMap extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.center = [19.479744, 52.068813];
        this.start = this.props.courseDetails.from
            ? [this.props.courseDetails.from.lon, this.props.courseDetails.from.lat]
            : [this.props.courseDetails.to.lon, this.props.courseDetails.to.lat];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6.5;
        this.marker = whiteMarker;
        this.carMarker = carMarker;
        this.route = null;
        this.state = {
            driverPosition: null,
        };
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
        });

        let startColor = [102, 208, 29, 0.8];
        let middleColor = [237, 29, 29, 0.8];
        let endColor = [237, 29, 29, 1];

        const { courseDetails } = this.props;
        this.getRoutes();
        this.createMarker(this.start, "point", courseDetails.direction === 1 ? endColor : startColor);

        if (courseDetails.driver) {
            this.createMarker(
                [courseDetails.driver.addresses.lon, courseDetails.driver.addresses.lat],
                `${courseDetails.driver.regNo ? courseDetails.driver.regNo : "driverMarker"}`,
                [256, 256, 256, 0]
            );
        }

        for (let i = 0; i < courseDetails.worker.length; i++) {
            let coords = [courseDetails.worker[i].lon, courseDetails.worker[i].lat];
            if (i === 0) {
                if (courseDetails.direction === 1) {
                    this.createMarker(coords, "point", startColor);
                } else if (courseDetails.worker.length > 1) {
                    this.createPoint(coords, "point", middleColor);
                } else this.createMarker(coords, "point", endColor);
            } else if (i === courseDetails.worker.length - 1) {
                if (courseDetails.direction === 1) {
                    this.createPoint(coords, "point", middleColor);
                } else {
                    this.createMarker(coords, "point", endColor);
                }
            } else {
                this.createPoint(coords, "point", middleColor);
            }
        }
    }

    getRoutes = async () => {
        const { courseDetails } = this.props;
        let coordsPack = "";
        for (let i = 0; i < courseDetails.path.length; i++) {
            let prevCoords = coordsPack;
            let coords = `${i === 0 ? "" : ";"}${courseDetails.path[i].adres.lon},${courseDetails.path[i].adres.lat}`;
            coordsPack = `${prevCoords}${coords}`;
        }

        const url = `${config.osrmURL}/route/v1/driving/${coordsPack}?steps=true&geometries=geojson&overview=full`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url);
            this.route = response.data;
            this.props.handleCounters(response.data.routes[0].distance, response.data.routes[0].duration);
            this.createRoute(this.route);
            this.createCustomWayRoute(this.props.customRouteWaypoints);
            
        } catch (e) {}
    };

    createMarker(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: markerId === "point" ? this.marker : this.carMarker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            }),
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    createPoint(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        const strokeStyle = new Stroke({
            color: singleColor,
            width: 1.2,
        });

        const regularShape = new RegularShape({
            fill: new Fill({
                color: singleColor,
            }),
            stroke: strokeStyle,
            points: 20,
            radius: 10,
        });

        let style = new Style({
            image: regularShape,
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    moveMarker(coords, markerId, singleColor) {
        if (!this[markerId]) {
            this.createMarker(coords, markerId, singleColor);
        }

        let feature = this.map.getLayers().item(this[markerId]).getSource().getFeatureById(markerId);

        if (this.state.driverPosition && feature !== null && markerId === this.state.driverPosition.regNo) {
            feature.setGeometry(new Point(transform(coords, "EPSG:4326", "EPSG:3857")));
        }
    }

    createLine(prevStep, singleStep, lineStyle, isDeclutter) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
            declutter: isDeclutter ?? false
        });
        this.map.addLayer(vector);
    }

    createRoute(route) {
        let prevStep = this.startCoords;

        const lineStyle = new Style({
            stroke: new Stroke({
                color: [237, 29, 29, 1],
                width: 5,
            }),
            zIndex: 1,
        });

        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [
                        route.routes[i].geometry.coordinates[j - 1][0],
                        route.routes[i].geometry.coordinates[j - 1][1],
                    ];
                    let singleStep = [
                        route.routes[i].geometry.coordinates[j][0],
                        route.routes[i].geometry.coordinates[j][1],
                    ];
                    this.createLine(prevStep, singleStep, lineStyle, true);
                }
            }
        }
    }

    createCustomWayRoute(waypoints) {
        const lineStyle = new Style({
            stroke: new Stroke({
                color: [42, 178, 42, 0.8],
                width: 2.5,
            }),
            zIndex: 23243243,
        });
        let prevStep = this.startCoords;
        for (let j in waypoints) {
            if (j > 0) {
                prevStep = [waypoints[j - 1][0], waypoints[j - 1][1]];
                let singleStep = [waypoints[j][0], waypoints[j][1]];

                this.createLine(prevStep, singleStep, lineStyle);
            }
        }
    }

    render() {
        return (
            <div
                id={"osm-map"}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
