import React, {Fragment} from "react";
import AppRoute from "../../../AppRoute";
import Rozliczenia from "./Rozliczenia";

export default function RozliczeniaRoute() {
    return (
        <Fragment>
            <AppRoute
                app="administracja"
                window="dokumenty"
                tab="rozliczenia"
                component={Rozliczenia}
            />
        </Fragment>
    );
};