import React, {useState, useEffect, Fragment} from "react";
import styles from "./contractorList.module.scss"
import {makeStyles, styled} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {
    fromUnixTime,
    format,
    getUnixTime,
    startOfMonth,
    endOfMonth,
    getYear,
    getMonth,
    getDaysInMonth,
    startOfDay, endOfDay, getDate, isDate
} from "date-fns";
import ProgressLine from "../../reusableComponents/progressLine/ProgressLine";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreDetails from "./moreDetails/MoreDetails";
import {pl} from "date-fns/locale";
import HttpClient from "../../../HttpClient";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {createMuiTheme, TablePagination} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {DatePicker} from "@material-ui/pickers";
import {useHistory} from "react-router-dom";
import {convertNumberToArray} from "../../../helper/convertNumberToArray";
import * as qs from "qs";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import DateTabs from "../../reusableComponents/orders/Tabs";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ThemeProvider} from "@material-ui/styles";
import Input from "@material-ui/core/Input";

const tableColumns = [
    {
        id: "companyName",
        label: "Nazwa firmy",
        query: true,
        sorted: true,
        order: 'ASC',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "address",
        label: "Adres",
        query: true,
        sorted: false,
        order: null,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "nip",
        label: "NIP",
        query: true,
        sorted: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "regon",
        label: "REGON",
        query: true,
        sorted: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "startTime",
        label: "Początek umowy",
        query: false,
        sorted: false,
        order: null,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "endTime",
        label: "Koniec umowy",
        query: false,
        sorted: false,
        order: null,
        minWidth: 140,
        maxWidth: 140,
    },
    {
        id: "contactPerson",
        label: "Osoba kontaktowa",
        query: true,
        sorted: false,
        order: null,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "dispatch",
        label: "Dyspozytura",
        query: true,
        sorted: false,
        order: null,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "notes",
        label: "Uwagi",
        query: true,
        sorted: false,
        order: null,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "details",
        label: "",
        minWidth: 80,
        maxWidth: 80,
    },


];

function createData(companyName, address, nip, regon, startTime, endTime, contactPerson, dispatch, notes, details) {
    return {companyName, address, nip, regon, startTime, endTime, contactPerson, dispatch, notes, details};
}

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        padding: "0 10px",
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 15px 0 0",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTableCell-body": {
            padding: "2px 15px 2px 2px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTabs-fixed": {
            overflowX: "auto !important",
        },
    },
    content: {
        maxWidth: "180px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: 'break-word',
        display: "flex",
        flexDirection: "column"
    },
    button: {
        backgroundColor: '#ED1D1D',
        '&:hover': {
            backgroundColor: "#C01313",
        },
    },
    icon: {
        color: "white",
    },
    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px"
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    changeViewButton: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        }
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#C01313",
        }
    }
});

const SearchDatePicker = styled(DatePicker)({
    width: 120,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}

const generateStartEndDate = (newDate) => ({
    start: getUnixTime(startOfMonth(newDate)),
    end: getUnixTime(endOfMonth(newDate))
});

export default function Orders(props) {
    const classes = useStyles();
    const url = "api/contractors_list";

    const history = useHistory();
    const now = new Date();
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [page, setPage] = useState(0);
    const [columns, setColumns] = useState(tableColumns);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [showDetails, setShowDetails] = useState(false);
    const [searchValues, setSearchValues] = useState([{id: 'time', value: null}]);
    const [api, setApi] = useState([]);
    const [date, setDate] = useState(generateStartEndDate(now));
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [updateDriver, setUpdateDriver] = useState(false);
    const [year, setYear] = useState(getYear(now));
    const [month, setMonth] = useState(getMonth(now));
    const [day, setDay] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState(convertNumberToArray(getDaysInMonth(new Date(year, month))));
    const [contractorDetails, setContractorDetails] = useState(null);

    const handleOnEnterPress = (e) => {
        if (e.key === 'Enter') {
            getData();
        }
    }

    const handleSetStartEndDate = () => {
        let rangeDate = null;
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay))
            }
        } else {
            if (props.status === 'archive') {
                const thisMonth = new Date(Date.UTC(year, month));
                rangeDate = {
                    start: getUnixTime(startOfMonth(thisMonth)),
                    end: getUnixTime(endOfMonth(thisMonth))
                }
            }
        }
        setDate(rangeDate);
    }

    const handleDaysInMonthChange = (newYear, newMonth) => {
        setDaysInMonth(convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth))));
    }

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleDayChange = (event, newValue) => {
        setDay(newValue);
    }

    const handleDatePickerDateChange = (newDate) => {
        const day = getDate(newDate);
        const month = getMonth(newDate);
        const year = getYear(newDate);
        setDay(day);
        setMonth(month);
        setYear(year);
    }

    const getDatePickerValue = () => {
        return day ? new Date(year, month, day) : null;
    }

    useEffect(() => {
        handleDaysInMonthChange(year, month);
        handleSetStartEndDate();
    }, [year, month, day]);


    const source = axios.CancelToken.source();

    const alertReset = () => {
        setShowDetails(false);
    };

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const _searchParams = searchValues.map((a) => ({...a}));
        const getParam = _searchParams.map(getParams).join("");
        const sortField = [...columns].filter((item) => item.sorted === true);

        const httpRequest = HttpClient.createRequest();
            const response = await httpRequest.get(`${url}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`,
                {
                    cancelToken: source.token,
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                }, true);

            setApi(response.data.data);
            setCount(response.data.count);
            setIsLoading(false);
    };


    const {value} = searchValues.find(value => value.id === 'time');
    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        setUpdateDriver(false)

        return () => {
            source.cancel();
        };
    }, [invokeClearColumn, date, value, rowsPerPage, page, updateDriver]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        setSearchValues([...filterObject, {id: field, value: query}]);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    };

    const clearDate = () => {
        setDay(null);
    }

    const ShowArrow = ({sorted, order, classes}) => {
        if (sorted) {
            if (order === "ASC") {
                return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
            } else if (order === "DESC") {
                return <ArrowDownwardIcon fontSize="small" className={classes.headCellArrow}/>;
            }
        } else {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
        }
    };

    const createSortHandler = (_column) => {
        columns.map((column) => {
            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
        });
        setColumns(columns);
        handleSort();
    };

    const handleSort = () => {
        setPage(0);
        getData();
    };

    const rows = api
        ? api.map((data) => {
            const {contract} = data;
            const dispatchData = contract.dispatches
                ? contract.dispatches.map((item) => {
                    return (
                        <>
                            <span>{item.dispatch ? item.dispatch : ""}</span>
                            <span>{`tel. ${item.dispatchPhoneNumber ? item.dispatchPhoneNumber : ""}`}</span>

                        </>
                    );
                })
                : null;

            const address =
                `${contract.address?.street ? contract.address?.street : ""}${contract.address?.postCode ? `, ${contract.address?.postCode} ` : ""} ${
                    contract.address?.city ? contract.address?.city : "-"
                }`;

            let startTime = contract.startTime ? format(fromUnixTime(contract.startTime), "d.LL.yyyy, HH:mm", {
                locale: pl,
            }) : '-';
            let endTime = contract.startTime ? format(fromUnixTime(contract.endTime), "d.LL.yyyy, HH:mm", {
                locale: pl,
            }) : '-';

            return createData(
                data.name ? <div className={classes.content}>{data.name}</div> : "-",
                contract.address ? <div className={classes.content}>{address}</div> : "-",
                contract.NIP ? contract.NIP : '-',
                contract.REGON ? contract.REGON : '-',
                startTime,
                endTime,
                contract.contactPerson ?
                    <div className={classes.content}>
                        <span>{contract.contactPerson ? contract.contactPerson : ""}</span>
                        <span>{`tel. ${contract.contactPersonPhoneNumber ? contract.contactPersonPhoneNumber : ""}`}</span>
                    </div> : "-",
                contract.dispatches ? <div className={classes.content}>{dispatchData}</div> : "-",
                data.notes ? <div className={classes.content}>{`${data.notes.length >= 20 ?  data.notes.substring(0,20) + '...' : data.notes}`}</div> : "-",
                <div className={styles["contractorList__table--buttonWrapper"]}>
                    <BootstrapTooltip title="Szczegóły">
                        <span>
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                type="button"
                                onClick={() => {
                                    setContractorDetails(data);
                                    setShowDetails(!showDetails);
                                }}
                            >
                                <MoreHorizIcon className={classes.icon}/>
                            </IconButton>
                        </span>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Edytuj">
                        <span>
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                type="button"
                                onClick={() => {
                                    history.push(`/app/administracja/dokumenty/kartakontrahenta/${data.id}`);
                                }}
                            >
                                   <EditIcon className={classes.icon}/>
                            </IconButton>
                        </span>
                    </BootstrapTooltip>
                </div>,
            )
        })
        : null;


    return (
        <div className={styles["contractorList"]}>
            <Paper className={classes.root}>
                {props.status === 'archive' &&
                <DateTabs
                    handleDayChange={handleDayChange}
                    handleMonthChange={handleMonthChange}
                    handleYearChange={handleYearChange}
                    day={day}
                    month={month}
                    year={year}
                    daysInMonth={daysInMonth}
                />
                }
                <div className={styles["contractorList__description"]}>
                    <h1 className={styles["contractorList__description--title"]}>
                        Karty kontrahentów
                    </h1>
                    <p className={styles["contractorList__description--subTitle"]}>
                        Lista wszystkich kart kontrahentów
                    </p>
                </div>
                {isLoading ? <ProgressLine/> :
                <TableContainer>
                    <SimpleBar style={{
                        minHeight: props.status === "active" ? "65vh" : "60vh",
                        maxHeight: props.status === "active" ? "65vh" : "60vh"
                    }}
                               className={classes.simplebar}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className={classes.head}>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <Fragment key={column.id}>
                                            <TableCell
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                }}
                                            >
                                                {column.query ? (
                                                    <TableSortLabel
                                                        className={classes.tableSortLabel}
                                                        hideSortIcon={true}
                                                    >
                                                        {column.id === "time" ? (
                                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                                <Input
                                                                    className={classes.headCell}
                                                                    inputComponent={SearchDatePicker}
                                                                    inputProps={{
                                                                        style: {height: 'unset'},
                                                                        onChange: (newValue) => handleDatePickerDateChange(newValue),
                                                                        value: getDatePickerValue(),
                                                                        disablePast: props.status === 'active',
                                                                        format: "d MMMM",
                                                                        disableToolbar: props.status === 'archive',
                                                                        InputProps: {
                                                                            disableUnderline: true,
                                                                            placeholder: column.label,
                                                                        },
                                                                    }}
                                                                    endAdornment={
                                                                        <>
                                                                            <Tooltip title="Wyczyść">
                                                                                    <span>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => clearDate()}
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ClearIcon
                                                                                            className={classes.headCellArrow}
                                                                                            fontSize="small"/>
                                                                                    </IconButton>
                                                                                        </span>
                                                                            </Tooltip>
                                                                            <Tooltip title="Sortuj">
                                                                                    <span>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() =>
                                                                                            createSortHandler(column, index, rows)
                                                                                        }
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ShowArrow
                                                                                            sorted={column.sorted}
                                                                                            order={column.order}
                                                                                            classes={classes}
                                                                                        />
                                                                                    </IconButton>
                                                                                        </span>
                                                                            </Tooltip>
                                                                        </>
                                                                    }
                                                                />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <Input
                                                                variant="outlined"
                                                                className={classes.headCell}
                                                                defaultValue={
                                                                    searchValues.find((v) => v.id === column.id)
                                                                        ?.value
                                                                }
                                                                onChange={(e) => handleSearch(column.id, e.target.value)}
                                                                onKeyDown={handleOnEnterPress}
                                                                placeholder={column.label}
                                                                endAdornment={
                                                                    <>
                                                                        <Tooltip title="Wyczyść">
                                                                                <span>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => handleClearColumn(column.id)}
                                                                                    aria-label="visible columns"
                                                                                >
                                                                                    <ClearIcon
                                                                                        className={classes.headCellArrow}
                                                                                        fontSize="small"/>
                                                                                </IconButton>
                                                                                    </span>
                                                                        </Tooltip>
                                                                        {column.sorted !== null &&
                                                                        <Tooltip title="Sortuj">
                                                                                    <span>
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            createSortHandler(column, index, rows)
                                                                                        }
                                                                                        size="small"
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ShowArrow
                                                                                            sorted={column.sorted}
                                                                                            order={column.order}
                                                                                            classes={classes}
                                                                                        />
                                                                                    </IconButton>
                                                                                        </span>
                                                                        </Tooltip>}
                                                                    </>
                                                                }
                                                            />
                                                        )}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.label
                                                )}
                                            </TableCell>
                                        </Fragment>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {api && api.length > 0 ? (
                                <TableBody>
                                    {rows &&
                                    rows.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" key={row.id}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}
                                                                   className={classes.tableCell}>
                                                            {column.format && typeof value === "number"
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : <div className={styles["contractorList__noData"]}>Brak danych</div>
                            }
                        </Table>
                    </SimpleBar>
                    <TablePagination
                        className={classes.pagination}
                        labelRowsPerPage={"Liczba wierszy"}
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>}
            </Paper>
            {showDetails ? (
                <MoreDetails
                    switchShowModal={alertReset}
                    contractorDetails={contractorDetails}
                />
            ) : null}

        </div>
    );
}
