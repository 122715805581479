import React, { useContext, useEffect, useState } from "react";
import styles from "./orderSummary.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import SummaryCourseMap from "./courseMap/SummaryCourseMap";
import {
    Backdrop,
    Button,
    Fade,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import FormAlert from "../../alerts/FormAlert";
import { MimicContext } from "../../../../context";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import LinkIcon from "@material-ui/icons/Link";
import { getUnixTime } from "date-fns";
import HttpClient from "../../../../HttpClient";
import generateOrderTimes from "../../../../helper/generateOrderTimes";
import * as qs from "qs";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        top: "6vh",
        width: "85vw",
        maxWidth: "1600px",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        fontFamily: "'Open Sans', sans-serif",
        "& .MuiTableCell-head": {
            padding: "5px 3px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "bold",
        },
        "& .MuiTableCell-body": {
            padding: "3px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
    },
    backButton: {
        minWidth: "150px",
        marginLeft: 5,
        backgroundColor: "#b4b4b4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
    confirmButton: {
        minWidth: "150px",
        backgroundColor: "#66D01D",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
    content: {
        maxWidth: "160px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
    },
    workerDataWrapper: {
        display: "flex",
    },
    connectIconWrapper: {
        display: "flex",
        marginTop: 4,
        marginRight: 4,
    },
    connectIcon: {
        width: 16,
        height: 16,
        color: "green",
    },
}));

// Tabela z podsumowaniem zamówienia
const columns = [
    { id: "driver", label: "Pracownik", minWidth: 180, align: "left" },
    { id: "pickup", label: "Adres odbioru", minWidth: 200, align: "left" },
    {
        id: "destination",
        label: "Adres docelowy",
        minWidth: 200,
        align: "left",
    },
    {
        id: "time",
        label: "Data",
        minWidth: 180,
        align: "left",
    },
    {
        id: "trainId",
        label: "Lokomotywa",
        minWidth: 140,
        align: "left",
    },
    {
        id: "trainDestination",
        label: "Trasa",
        minWidth: 200,
        align: "left",
    },
    {
        id: "orderNo",
        label: "Nr zlecenia",
        minWidth: 140,
        align: "left",
    },
    {
        id: "notes",
        label: "Uwagi",
        minWidth: 180,
        align: "left",
    },
];

function StickyHeadTable(props) {
    const classes = useStyles();

    function createData(driver, pickup, destination, time, trainId, trainDestination, orderNo, notes) {
        return {
            driver,
            pickup,
            destination,
            time,
            trainId,
            trainDestination,
            orderNo,
            notes,
        };
    }

    const rows = props.orderData.map((data) => {
        let time = format(data.time, "d.LLLL yyyy, HH:mm", { locale: pl });
        const personData = data.fullWorkerAddressData.map((item, index) => {
            return (
                <div key={index} className={styles["orderSummary__singleData"]}>
                    {item.person.name} {item.person.surName}
                </div>
            );
        });

        const personAddress = data.fullWorkerAddressData.map((item, index) => (
            <div className={styles["orderSummary__singleData"]} key={index}>
                {`${item.address.city ? item.address.city : ""} ${item.address.street ? item.address.street : ""} ${
                    item.address.no ? item.address.no : ""
                }${item.address.flat ? `/${item.address.flat}` : ""} ${
                    item.address.zipCode ? item.address.zipCode : ""
                }`}
            </div>
        ));

        return createData(
            <div className={classes.workerDataWrapper}>
                {props.areOrdersConnected && (
                    <div className={classes.connectIconWrapper}>
                        <LinkIcon className={classes.connectIcon} />
                    </div>
                )}
                <div>{personData}</div>
            </div>,
            (data.direction && data.isWorkAddress) || !data.direction
                ? `${data.pickup.street} ${data.pickup.city}`
                : personAddress,
            (!data.direction && data.isWorkAddress) || data.direction
                ? `${data.destination.street} ${data.destination.city}`
                : personAddress,
            `${time}`,
            data.trainId ? <div className={classes.content}>{data.trainId}</div> : "-",
            data.trainDestination ? data.trainDestination.street + " " + data.trainDestination.city : "-",

            data.orderNo ? <div className={classes.content}>{data.orderNo}</div> : "-",
            data.notes ? (
                <div className={classes.content}>{`${
                    data.notes.length >= 20 ? data.notes.substring(0, 20) + "..." : data.notes
                }`}</div>
            ) : (
                "-"
            )
        );
    });

    return (
        <Paper className={classes.root}>
            <TableContainer>
                <SimpleBar className={classes.simplebar}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    className={classes.tableCell}
                                                >
                                                    {column.format && typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </SimpleBar>
            </TableContainer>
        </Paper>
    );
}

// Główna funkcja podsumowująca zamówienie
const OrderSummary = (props) => {
    console.log(props);
    const context = useContext(MimicContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showTimeAlert, setShowTimeAlert] = React.useState(false);
    const timer = React.useRef();
    const [areOrdersConnected, setAreOrdersConnected] = useState(false);

    const [isValidationPending, setIsValidationPending] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        setAreOrdersConnected(props.orderData[0].isConnected);
    }, [open]);
    const validateWorkers = async () => {
        if (isValidationPending) {
            return;
        }

       setIsValidationPending(true);

        const { ordersGroup } = props;

        let orders = [];

        ordersGroup.forEach((orderGroup) => {
            orderGroup.orders
                .filter((order) => !order.isProposition)
                .forEach((order) => {
                    order.worker
                        .filter((worker) => worker.person.value?.id)
                        .forEach((worker) => {
                            const times = generateOrderTimes(order);

                            orders.push({
                                startTime: getUnixTime(times.startTime),
                                endTime: getUnixTime(times.endTime),
                                checkedOrderId: order.uuid,
                                worker: worker.person.value.id,
                            });
                        });
                });
        });

        const httpRequest = HttpClient.createRequest();

        try {
            const response = await httpRequest.get(
                `api/isFree?`,
                {
                    params: {
                        orders,
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                },
                true
            );

            const editingOrderIds = props.orderData.map((orderData) => orderData.id);

            console.log(response.data);
            console.log(editingOrderIds);

            const data = response.data.filter((item) => !editingOrderIds.includes(item.orderId));

            console.log(data);
            setIsValidationPending(false);

            if (data?.length) {
                props.onValidationError(data);
                return false;
            }
            return true;
        } catch (e) {
            setIsValidationPending(false);
            return false;
        }
    };

    const handleButtonClick = async () => {
        const isValid = await validateWorkers();

        if (!isValid) {
            return;
        }

        const today = new Date();
        // let hasError = props.orderData.some((order) => order.time < today);
        // if (hasError) {
        //     console.log("Has error");
        //     setShowTimeAlert(true);
        //     setTimeout(()=>{setShowTimeAlert(false)}, 5000)
        //     return;
        // }
        props.sendData(areOrdersConnected);
    };

    const handleClose = () => {
        props.switchShowOrderSummary(false);
    };

    const errorMessage = props.error ? (
        <div style={{ background: "red" }}>
            Błąd:
            <br />
            {React.createElement("div", {
                dangerouslySetInnerHTML: { __html: props.error },
            })}
            <br />
            Może za chwilę :)
        </div>
    ) : (
        ""
    );

    const RailyCheckbox = withStyles({
        root: {
            color: "#1dbbed",
            "&$checked": {
                color: "#1dbbed",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {showTimeAlert && (
                            <FormAlert
                                showAlert={true}
                                severity="error"
                                message="Nie można dodać zlecenia z datą wstecz"
                            />
                        )}
                        <SimpleBar style={{ maxHeight: "88vh" }} className={classes.simplebar}>
                            <div className={styles["orderSummary"]}>
                                <div className={styles["orderSummary__description"]}>
                                    <h1 className={styles["orderSummary__description--title"]}>
                                        Podsumowanie {props.type === "edit" && "zeedytowanych zleceń"}
                                    </h1>
                                    <p className={styles["orderSummary__description--subtitle"]}>
                                        Przeczytaj uważnie i potwierdź wysłanie zamówienia
                                    </p>
                                    <span className={styles["orderSummary__description--close"]} onClick={handleClose}>
                                        X
                                    </span>
                                </div>
                                {errorMessage}
                                {props.type !== "edit" && props.orderData.length > 1 && (
                                    <div>
                                        Połącz zamówienia
                                        <RailyCheckbox
                                            checked={areOrdersConnected}
                                            onChange={(_ev, isChecked) => {
                                                setAreOrdersConnected(isChecked);
                                            }}
                                            name="isTaxiDriver"
                                            color="primary"
                                            data-id="connectOrdersCheckbox"
                                        />
                                    </div>
                                )}
                                <StickyHeadTable orderData={props.orderData} areOrdersConnected={areOrdersConnected} />
                                <div className={styles["orderSummary__map"]}>
                                    <SummaryCourseMap courseDetails={props.orderData} />
                                </div>

                                <div className={styles["orderSummary__buttonWrapper"]}>
                                    <Button
                                        className={classes.confirmButton}
                                        onClick={handleButtonClick}
                                        variant="contained"
                                        type="submit"
                                        data-id="orderConfirmButton"
                                    >
                                        Potwierdź
                                    </Button>
                                    <Button
                                        className={classes.backButton}
                                        variant="contained"
                                        onClick={handleClose}
                                        data-id="orderCorrectButton"
                                    >
                                        Popraw
                                    </Button>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default OrderSummary;
