import React, { useEffect, useState } from "react";
import styles from "./changeDriverPanel.module.scss";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import FormAlert from "../alerts/FormAlert";
import HttpClient from "../../../HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
    changeDriverButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "7px",
        width: "160px",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    },
}));

const DriverField = styled(TextField)({
    width: 200,
    fontFamily: "'Open Sans', sans-serif",
    marginRight: "10px",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const RealDriverInput = ({ value, onChange }) => {
    return (
        <DriverField
            value={value}
            onChange={(ev) => onChange(ev.target.value)}
            label="Wybierz kierowcę"
            variant="standard"
            margin="normal"
            fullWidth
        />
    );
};

export default function ChangeRealDriverPanel(props) {
    const classes = useStyles();
    const [emptyDriverAlert, setEmptyDriverAlert] = useState(false);
    const [isSendRequestPending, setIsSendRequestPending] = useState(false);
    const [realDriverInputValue, setRealInputDriverValue] = useState(props.currentValue);

    const alertReset = () => {
        setEmptyDriverAlert(false);
    };

    const sendData = async () => {
        if (realDriverInputValue) {
            if (isSendRequestPending) {
                return;
            }

            const postData = {
                real_driver: realDriverInputValue,
            };

            setIsSendRequestPending(true);

            try {
                const requestUrl = `api/orders/${props.orderId}/set/real-driver`;
                const httpRequest = HttpClient.createRequest();
                await httpRequest.put(requestUrl, postData, {}, true);
                props.onDriverChange();
                props.switchDriver(true);
                setIsSendRequestPending(false);
            } catch (e) {
                setIsSendRequestPending(false);
            }
        } else {
            setEmptyDriverAlert(true);
            setTimeout(alertReset, 3000);
        }
    };

    return (
        <div className={styles["changeDriverPanel"]}>
            <div className={styles["changeDriverPanel__input"]}>
                <RealDriverInput value={realDriverInputValue} onChange={setRealInputDriverValue} />
            </div>
            <div className={styles["changeDriverPanel__button"]}>
                <Button
                    className={classes.changeDriverButton}
                    variant="outlined"
                    onClick={(e, newValue) => sendData(newValue)}
                >
                    Potwierdź zmianę
                </Button>
            </div>
            {emptyDriverAlert ? (
                <FormAlert showAlert={true} severity="error" message={"Zanim zatwierdzisz, wybierz kierowcę"} />
            ) : null}
        </div>
    );
}
