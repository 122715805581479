import { useRef, useEffect } from "react";

const useIsComponentMounted = () => {
  const isMountRef = useRef(false);
  useEffect(() => {
    isMountRef.current = true;

    return () => {
      isMountRef.current = false;
    };
  }, []);
  return isMountRef.current;
};

export default useIsComponentMounted;
