const formatDistance = (km) => (km !== null && km !== 0 && km !== undefined ? `${Math.round(km / 1000)} km` : "-"); //km is in meters

const formatTime = (time) => {
    //times is in seconds
    if (time === null || time === 0) return "-";

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const duration = `${hours === 0 ? "" : hours + " h"} ${minutes < 10 ? `0${minutes}` : minutes} min`;

    return duration;
};

const getAddressesCords = (arrOfAddresses) =>
    arrOfAddresses.map((address) => `${address.lon},${address.lat}`).join(";");

export { formatDistance, formatTime, getAddressesCords };
