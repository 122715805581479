import React, { useContext, useState } from "react";
import {
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import styles from "./addWorkerForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import OsmMap from "../../../reusableComponents/maps/osmMap/OsmMap";
import axios from "axios";
import { debounce } from "../../../../debounce";
import HttpClient from "../../../../HttpClient";
import SimpleBar from "simplebar-react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import "simplebar/dist/simplebar.min.css";
import CustomModal from "../../../reusableComponents/customModal/CustomModal";
import AddKontrahentStructure from "../../../reusableComponents/addNewKontrahentStructure/AddKontrahentStructure";
import { MimicContext } from "../../../../context";
import { phoneRegExp } from "../../../../helper";
import Cookie from "js-cookie";
import config from "../../../../config";

const useStyles = makeStyles(() => ({
  simplebar: {
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
      backgroundColor: "#b4b4b4",
    },
  },
  root: {
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
      color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1DBBED",
      },
      "&:hover fieldset": {
        borderColor: "#1DBBED",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1DBBED",
      },
    },
  },
  confirmButton: {
    minWidth: "200px",
    backgroundColor: "#1DBBED",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#00a0e3",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const addressTypes = ["Domowy", "Hotel", "Inny", "Gniazdo"];

const clientsWithDelegations = ["Cargo Master", "Master Inwestycje"];

export default function AddWorkerForm(props) {
  const createAddressField = () => ({
    addresses: [],
  });

  let actualAxiosRequest = null;
  const filter = createFilterOptions();
  const classes = useStyles();
  const { roles, isRoleType } = useContext(MimicContext);
  const [newLat, setNewLat] = useState(52.215933);
  const [newLon, setNewLon] = useState(19.134422);
  const [open, setOpen] = useState(false);
  const [addressSearch, setAddressSearch] = useState([createAddressField()]);
  const [ignoreForm, setIgnoreForm] = useState(true);
  const zipCodeRegExp = /([0-9]{2}\-[0-9]{3})|([0-9-]{1,8})/;

  const loggedUser = Cookie.get("loggedUser");

  const hasDelegationAccess = clientsWithDelegations.includes(
    loggedUser.companyName
  );

  const handleIgnoreForm = () => {
    if (!ignoreForm) {
      setIgnoreForm(true);
    } else return;
  };

  const handleCreateAddress = () => {
    setAddressSearch([...addressSearch, createAddressField()]);
  };

  const handleRemoveAddress = () => {
    const _addressSearch = [...addressSearch];
    _addressSearch.pop();
    setAddressSearch(_addressSearch);
  };

  const fillAddressFields = (value, setFieldValue, index) => {
    setIgnoreForm(false);
    const { address } = value;
    const addressField = {
      zipCode: address.postcode || "",
      city: address.city || address.village || address.town || address.hamlet || "",
      get street() {
        return address.road || this.city || "";
      },
      no: address.house_number || "",
      lat: value.lat,
      lon: value.lon,
    };

    for (const key in addressField) {
      setFieldValue(`address.${index}.${key}`, addressField[key]);
    }
    setNewLat(value.lat);
    setNewLon(value.lon);
  };

  const handleAddressChange = async (value, fromMapClick = false, formikFields = null, index = 0) => {
    setIgnoreForm(true);
    if (actualAxiosRequest) {
      actualAxiosRequest.cancel();
    }
    actualAxiosRequest = axios.CancelToken.source();
    const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;
    const httpRequest = HttpClient.createRequest();
    try {
      const response = await httpRequest.get(url, {
        cancelToken: actualAxiosRequest.token,
      });
      const {data} = response;
      if (!fromMapClick) {
        const _addressSearch = [...addressSearch];
        const options = [];

        data.map((item) => {
          options.push({
            id: item.place_id,
            name: item.display_name,
            address: item.address,
            lat: item.lat,
            lon: item.lon,
          });
        });
        _addressSearch[index].addresses = options;
        setAddressSearch(_addressSearch);
        fillAddressFields(_addressSearch, formikFields, index)
      } else {
        const address = {
          id: data[0].place_id,
          name: data[0].display_name,
          address: data[0].address,
          lat: data[0].lat,
          lon: data[0].lon
        }
        fillAddressFields(address, formikFields, index)
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        actualAxiosRequest = null;
      }
    }
  };

  const moveMarker = (value) => {
    if (value.lat !== null || value.lon !== null) {
      setIgnoreForm(false);
      setNewLat(value.lat);
      setNewLon(value.lon);
    }
  };

  const search = debounce((value, index = 0) => {
    handleAddressChange(value, false, null, index);
  }, 2000);

  const handleToggleModal = () => setOpen(!open);

  const getWorkerSchema = () => {
    if (hasDelegationAccess) {
      return Yup.object().shape({
        name: Yup.string()
          .trim()
          .matches(/^\D+$/, "Imię może zawierać tylko litery")
          .required("Uzupełnij to pole"),
        surName: Yup.string()
          .trim()
          .matches(/^\D+$/, "Nazwisko może zawierać tylko litery")
          .required("Uzupełnij to pole"),
        workPhoneNumber: Yup.string()
          .trim()
          .required("Uzupełnij to pole")
          .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
        workEmail: Yup.string()
          .trim()
          .email("Nieprawidłowy format adresu email"),
        kontrahent: Yup.object().nullable(),
        address: Yup.array().of(
          Yup.object().shape({
            // addressSearch: Yup.string(),
            zipCode: Yup.string()
              .trim()
              .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego")
              .required("Uzupełnij to pole"),
            city: Yup.string().trim().required("Uzupełnij to pole"),
            street: Yup.string().trim().required("Uzupełnij to pole"),
            no: Yup.string().trim().required("Uzupełnij to pole"),
            flat: Yup.string(),
            type: Yup.string().trim().required("Uzupełnij to pole"),
          })
        ),
        delegationRate: Yup.number()
          .min(0.0001, "Stawka za 1km musi być większa niż 0")
          .required("Te pole jest wymagane"),
        position: Yup.string().trim().required("Te pole jest wymagane"),
        regNo: Yup.string().trim().required("Te pole jest wymagane"),
        engineCapacity: Yup.string().trim().required("Te pole jest wymagane"),
      });
    } else {
      return Yup.object().shape({
        name: Yup.string()
          .trim()
          .matches(/[a-zA-Z]+/, "Imię może zawierać tylko litery")
          .required("Uzupełnij to pole"),
        surName: Yup.string()
          .trim()
          .matches(/[a-zA-Z]+/, "Nazwisko może zawierać tylko litery")
          .required("Uzupełnij to pole"),
        workPhoneNumber: Yup.string()
          .trim()
          .required("Uzupełnij to pole")
          .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
        workEmail: Yup.string()
          .trim()
          .email("Nieprawidłowy format adresu email"),
        kontrahent: Yup.object().nullable(),
        address: Yup.array().of(
          Yup.object().shape({
            // addressSearch: Yup.string(),
            zipCode: Yup.string()
              .trim()
              .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego")
              .required("Uzupełnij to pole"),
            city: Yup.string().trim().required("Uzupełnij to pole"),
            street: Yup.string().trim().required("Uzupełnij to pole"),
            no: Yup.string().trim().required("Uzupełnij to pole"),
            flat: Yup.string(),
            type: Yup.string().trim().required("Uzupełnij to pole"),
          })
        ),
      });
    }
  };

  const mapClicked = async (lonlat, setFieldValue, index) => {
    const value = lonlat.split(',')
    handleAddressChange((value[1] + ',' + value[0]).toString(), true, setFieldValue, index)
  }

  return (
    <div className={styles["addWorker"]}>
      <Formik
        initialValues={props.worker}
        validationSchema={() => getWorkerSchema()}
        onSubmit={(values) => {
          props.sendWorkerData(values, false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className={styles["addWorkerForm"]}>
            <>
              <SimpleBar
                style={{ maxHeight: "70vh", minWidth: 520 }}
                className={classes.simplebar}
              >
                <div className={styles["addWorkerForm__description"]}>
                  <h1 className={styles["addWorkerForm__description--title"]}>
                    Dodaj pracownika
                  </h1>
                  <p className={styles["addWorkerForm__description--subTitle"]}>
                    Wypełnij wszystkie pola i zatwierdź
                  </p>
                </div>
                <div className={styles["addWorkerForm__personal"]}>
                  <div
                    className={styles["addWorkerForm__personal--description"]}
                  >
                    <p
                      className={styles["addWorkerForm__personal--description"]}
                    >
                      Dane osobowe
                    </p>
                  </div>
                  <div className={styles["addWorkerForm__personal--inputs"]}>
                    <div
                      className={
                        styles["addWorkerForm__personal--inputs--field"]
                      }
                    >
                      <Field
                        onKeyUp={handleIgnoreForm}
                        className={classes.root}
                        name="name"
                        helperText={
                          !!errors.name && touched.name ? errors.name : ""
                        }
                        error={!!errors.name && touched.name}
                        label="Imię*"
                        variant="standard"
                        style={{ width: 240 }}
                        margin="none"
                        fullWidth
                        as={TextField}
                      />
                    </div>
                    <div
                      className={
                        styles["addWorkerForm__personal--inputs--field"]
                      }
                    >
                      <Field
                        onKeyUp={handleIgnoreForm}
                        className={classes.root}
                        name="surName"
                        helperText={
                          !!errors.surName && touched.surName
                            ? errors.surName
                            : ""
                        }
                        error={!!errors.surName && touched.surName}
                        label="Nazwisko*"
                        variant="standard"
                        style={{ width: 240 }}
                        margin="none"
                        fullWidth
                        as={TextField}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles["addWorkerForm__address"]}>
                  <div
                    className={styles["addWorkerForm__address--description"]}
                  >
                    <p
                      className={styles["addWorkerForm__address--description"]}
                    >
                      Dane adresowe
                    </p>
                  </div>
                  <FieldArray
                    name="address"
                    render={({ push, remove }) => (
                      <>
                        {values.address.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                styles["addWorkerForm__address--inputs"]
                              }
                            >
                              <Autocomplete
                                options={addressSearch[index]?.addresses ?? []}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                noOptionsText="Nie znaleziono podanego adresu"
                                filterOptions={(options, state) => options}
                                onOpen={() => moveMarker(value)}
                                onChange={(e, value) => {
                                  if (value)
                                    fillAddressFields(
                                      value,
                                      setFieldValue,
                                        index
                                    );
                                }}
                                onInputChange={(e, newValue) => {
                                  if (newValue.trim()) search(newValue, index);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    {...params}
                                    className={classes.root}
                                    label="Wyszukaj adres"
                                    variant="filled"
                                    margin="none"
                                    name="alone"
                                    fullWidth
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                    as={TextField}
                                  />
                                )}
                              />
                              <div
                                className={
                                  styles[
                                    "addWorkerForm__address--inputs--fieldGroup"
                                  ]
                                }
                              >
                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Field
                                    disabled
                                    className={classes.root}
                                    name={`address.${index}.street`}
                                    error={
                                      !!getIn(
                                        touched,
                                        `address.${index}.street`
                                      ) &&
                                      !!getIn(errors, `address.${index}.street`)
                                    }
                                    helperText={
                                                                        !!getIn(touched, `address.${index}.street`) &&
                                      !!getIn(errors, `address.${index}.street`)
                                        ? getIn(
                                            errors,
                                            `address.${index}.street`
                                          )
                                        : ""
                                    }
                                    label="Ulica*"
                                    variant="standard"
                                    style={{ width: 240 }}
                                    margin="none"
                                    fullWidth
                                    as={TextField}
                                  />
                                </div>
                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Field
                                    className={classes.root}
                                    name={`address.${index}.no`}
                                    error={
                                      !!getIn(touched, `address.${index}.no`) &&
                                      !!getIn(errors, `address.${index}.no`)
                                    }
                                    helperText={
                                      !!getIn(touched, `address.${index}.no`) &&
                                      !!getIn(errors, `address.${index}.no`)
                                        ? getIn(errors, `address.${index}.no`)
                                        : ""
                                    }
                                    label="Nr domu*"
                                    variant="standard"
                                    style={{ width: 115 }}
                                    margin="none"
                                    fullWidth
                                    as={TextField}
                                  />
                                </div>
                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Field
                                    className={classes.root}
                                    name={`address.${index}.flat`}
                                    error={
                                      !!getIn(
                                        touched,
                                        `address.${index}.flat`
                                      ) &&
                                      !!getIn(errors, `address.${index}.flat`)
                                    }
                                    helperText={
                                      !!getIn(
                                        touched,
                                        `address.${index}.flat`
                                      ) &&
                                      !!getIn(errors, `address.${index}.flat`)
                                        ? getIn(errors, `address.${index}.flat`)
                                        : ""
                                    }
                                    label="Nr mieszkania"
                                    variant="standard"
                                    style={{ width: 115 }}
                                    margin="none"
                                    fullWidth
                                    as={TextField}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  styles[
                                    "addWorkerForm__address--inputs--fieldGroup"
                                  ]
                                }
                              >
                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Field
                                    className={classes.root}
                                    name={`address.${index}.city`}
                                    error={
                                      !!getIn(
                                        touched,
                                        `address.${index}.city`
                                      ) &&
                                      !!getIn(errors, `address.${index}.city`)
                                    }
                                    helperText={
                                      !!getIn(
                                        touched,
                                        `address.${index}.city`
                                      ) &&
                                      !!getIn(errors, `address.${index}.city`)
                                        ? getIn(errors, `address.${index}.city`)
                                        : ""
                                    }
                                    label="Miasto*"
                                    variant="standard"
                                    style={{ width: 240 }}
                                    margin="none"
                                    fullWidth
                                    as={TextField}
                                  />
                                </div>
                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Field
                                    // disabled
                                    className={classes.root}
                                    name={`address.${index}.zipCode`}
                                    error={
                                      !!getIn(
                                        touched,
                                        `address.${index}.zipCode`
                                      ) &&
                                      !!getIn(
                                        errors,
                                        `address.${index}.zipCode`
                                      )
                                    }
                                    helperText={
                                      !!getIn(
                                        touched,
                                        `address.${index}.zipCode`
                                      ) &&
                                      !!getIn(
                                        errors,
                                        `address.${index}.zipCode`
                                      )
                                        ? getIn(
                                            errors,
                                            `address.${index}.zipCode`
                                          )
                                        : ""
                                    }
                                    label="Kod pocztowy*"
                                    variant="standard"
                                    style={{ width: 115 }}
                                    margin="none"
                                    fullWidth
                                    as={TextField}
                                  />
                                </div>

                                <div
                                  className={
                                    styles[
                                      "addWorkerForm__address--inputs--field"
                                    ]
                                  }
                                >
                                  <Autocomplete
                                    onKeyUp={() => handleIgnoreForm}
                                    autoSelect
                                    options={addressTypes}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 115 }}
                                    onChange={(e, newValue) => {
                                      let dataType = "HOME";
                                      switch (newValue) {
                                        case "Hotel":
                                          dataType = "HOTEL";
                                          break;
                                        case "Gniazdo":
                                          dataType = "NEST";
                                          break;
                                        case "Inny":
                                          dataType = "OTHER";
                                          break;
                                      }
                                      setFieldValue(
                                        `address.${index}.type`,
                                        dataType
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        className={classes.root}
                                        {...params}
                                        name={`address.${index}.type`}
                                        error={
                                          !!getIn(
                                            touched,
                                            `address.${index}.type`
                                          ) &&
                                                                                !!getIn(errors, `address.${index}.type`)
                                        }
                                        helperText={
                                          !!getIn(
                                            touched,
                                            `address.${index}.type`
                                          ) &&
                                                                                !!getIn(errors, `address.${index}.type`)
                                                                                    ? getIn(errors, `address.${index}.type`)
                                            : ""
                                        }
                                        label="Typ adresu*"
                                        variant="standard"
                                        margin="none"
                                        fullWidth
                                        as={TextField}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div
                          className={
                            styles["addWorkerForm__address--buttonWrapper"]
                          }
                        >
                          <IconButton
                            className={
                              styles[
                                "addWorkerForm__address--buttonWrapper--addAddressButton"
                              ]
                            }
                            type="button"
                            color="default"
                            margin="normal"
                            size="small"
                            onClick={() => {
                              handleCreateAddress();
                              push(props.buildEmptyAddress());
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {values.address.length > 1 ? (
                            <button
                              className={
                                styles[
                                  "addWorkerForm__address--buttonWrapper--removeAddressButton"
                                ]
                              }
                              type="button"
                              color="default"
                              margin="normal"
                              size="small"
                              onClick={() => {
                                handleRemoveAddress();
                                remove(values.address.length - 1);
                              }}
                            >
                              <RemoveIcon />
                            </button>
                          ) : null}

                          <p
                            className={
                              styles[
                                "addWorkerForm__address--buttonWrapper--description"
                              ]
                            }
                          >
                            Dodaj/usuń adres
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>

                <div className={styles["addWorkerForm__contact"]}>
                  <div
                    className={styles["addWorkerForm__contact--description"]}
                  >
                    <p
                      className={styles["addWorkerForm__contact--description"]}
                    >
                      Dane kontaktowe
                    </p>
                  </div>
                  <div className={styles["addWorkerForm__contact--inputs"]}>
                    <div
                      className={
                        styles["addWorkerForm__contact--inputs--field"]
                      }
                    >
                      <Field
                        onKeyUp={handleIgnoreForm}
                        className={classes.root}
                        name="workPhoneNumber"
                        helperText={
                          !!errors.workPhoneNumber && touched.workPhoneNumber
                            ? errors.workPhoneNumber
                            : ""
                        }
                        error={
                          !!errors.workPhoneNumber && touched.workPhoneNumber
                        }
                        label="Telefon*"
                        variant="standard"
                        style={{ width: 240 }}
                        margin="none"
                        fullWidth
                        as={TextField}
                      />
                    </div>
                    <div
                      className={
                        styles["addWorkerForm__contact--inputs--field"]
                      }
                    >
                      <Field
                        onKeyUp={handleIgnoreForm}
                        className={classes.root}
                        name="workEmail"
                        helperText={
                          !!errors.workEmail && touched.workEmail
                            ? errors.workEmail
                            : ""
                        }
                        error={!!errors.workEmail && touched.workEmail}
                        label="E-mail"
                        variant="standard"
                        style={{ width: 240 }}
                        margin="none"
                        fullWidth
                        as={TextField}
                      />
                    </div>
                  </div>
                </div>
                {hasDelegationAccess && (
                  <div className={styles["addWorkerForm__contact"]}>
                    <div
                      className={styles["addWorkerForm__contact--description"]}
                    >
                      <p
                        className={
                          styles["addWorkerForm__contact--description"]
                        }
                      >
                        Dane do delegacji
                      </p>
                    </div>
                    <div className={styles["addWorkerForm__contact--inputs"]}>
                      <div
                        className={
                          styles["addWorkerForm__contact--inputs--field"]
                        }
                      >
                        <Field
                          onKeyUp={handleIgnoreForm}
                          className={classes.root}
                          name="delegationRate"
                          helperText={errors.delegationRate}
                          error={errors.delegationRate}
                          label="Stawka za 1 KM"
                          variant="standard"
                          type="number"
                          style={{ width: 240 }}
                          margin="none"
                          fullWidth
                          as={TextField}
                        />
                      </div>
                      <div
                        className={
                          styles["addWorkerForm__contact--inputs--field"]
                        }
                        style={{ width: "100%" }}
                      >
                        <FormControl fullWidth error={errors.engineCapacity}>
                          <InputLabel
                            style={{ fontSize: "1rem" }}
                            id="engineCapacity"
                          >
                            Pojemność silnika
                          </InputLabel>
                          <Select
                            id="engineCapacity"
                            fullWidth
                            defaultValue="Powyżej 900cm3"
                            name="engineCapacity"
                            onChange={(e) => {
                              setFieldValue("engineCapacity", e.target.value);
                            }}
                          >
                            <MenuItem value="Poniżej 900cm3">
                              Poniżej 900cm3
                            </MenuItem>
                            <MenuItem value="Powyżej 900cm3">
                              Powyżej 900cm3
                            </MenuItem>
                          </Select>
                          <FormHelperText>{errors.engineCapacity}</FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className={styles["addWorkerForm__contact--inputs"]}>
                      <div
                        className={
                          styles["addWorkerForm__contact--inputs--field"]
                        }
                      >
                        <Field
                          onKeyUp={handleIgnoreForm}
                          className={classes.root}
                          name="position"
                          helperText={errors.position}
                          error={errors.position}
                          label="Stanowisko"
                          variant="standard"
                          style={{ width: 240 }}
                          margin="none"
                          fullWidth
                          as={TextField}
                        />
                      </div>
                      <div
                        className={
                          styles["addWorkerForm__contact--inputs--field"]
                        }
                      >
                        <Field
                          onKeyUp={handleIgnoreForm}
                          className={classes.root}
                          name="regNo"
                          helperText={errors.regNo}
                          error={errors.regNo}
                          label="Numer rejestracyjny"
                          variant="standard"
                          style={{ width: 240 }}
                          margin="none"
                          fullWidth
                          as={TextField}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {isRoleType("OPERATOR") && !isRoleType("OPERATOR_CONTRACTOR") && (
                  <div className={styles["addWorkerForm__contractor"]}>
                    <Autocomplete
                      onKeyUp={() => handleIgnoreForm}
                      options={props.kontrahents}
                      disabled={!!props.contractor}
                      value={values.kontrahent}
                      filterOptions={filter}
                      getOptionSelected={(option) =>
                        option.id === values.kontrahent.id
                      }
                      onChange={(e, newValue) => {
                        let contractorValue = newValue;
                        if (newValue !== null && newValue.add) {
                          setOpen(true);
                          contractorValue = null;
                        }
                        setFieldValue("kontrahent", contractorValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <Field
                          className={classes.root}
                          {...params}
                          name="kontrahent"
                          label="Kontrahent"
                          variant="standard"
                          margin="none"
                          fullWidth
                          as={TextField}
                        />
                      )}
                    />
                  </div>
                )}
                <div className={styles["addWorkerForm__buttonWrapper"]}>
                  <Button
                    className={classes.confirmButton}
                    variant="contained"
                    type="submit"
                  >
                    Potwierdź
                  </Button>
                </div>
              </SimpleBar>
              <CustomModal
                open={open}
                classes={classes.modal}
                onClose={handleToggleModal}
              >
                <AddKontrahentStructure
                  handleToggleModal={handleToggleModal}
                  setFieldValue={setFieldValue}
                  sendDataCallback={props.addKontrahent}
                />
              </CustomModal>
            </>
            <div className={styles["addWorkerForm"]}>
              <div className={styles["addWorkerForm__map"]}>
                <div className={styles["addWorkerForm__description"]}>
                  <h1 className={styles["addWorkerForm__description--title"]}>
                    Mapa poglądowa
                  </h1>
                  <p className={styles["addWorkerForm__description--subTitle"]}>
                    Śledź znacznik aktualnie wpisywanego adresu
                  </p>
                </div>
                <OsmMap width="100%" height="70vh" pickedLat={newLat} pickedLon={newLon} onClickOnMap={(lonlat) => mapClicked(lonlat, setFieldValue, (values.address.length - 1))} ignoreForm={ignoreForm}/>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
