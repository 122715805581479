import React, {useEffect, useState} from "react";
import styles from "./moreDetails.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {format, fromUnixTime} from "date-fns";
import {pl} from "date-fns/locale";
import CourseMap from "./courseMap/CourseMap";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import SingleSchedule from "./singleSchedule/SingleSchedule";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const columns = [
    {
        id: "number",
        label: "ID",
        query: true,
        sorted: false,
        maxWidth: 65,
    },
    {
        id: "parts",
        label: "Relacja",
        sorted: false,
        order: null,
        query: false,
        minWidth: 250,
    },
    {
        id: "plannedTime",
        label: "Godz. zapl.",
        sorted: false,
        query: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "realTime",
        label: "Godz. real.",
        sorted: false,
        query: false,
        order: null,
        minWidth: 110,
        maxWidth: 110,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        sorted: false,
        query: true,
        order: null,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "worker",
        label: "Pracownik",
        sorted: false,
        order: null,
        query: true,
        minWidth: 180,
        maxWidth: 180,
    },
];


const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        width: "85vw",
        maxWidth: "1400px",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "5px 5px 40px 5px",
    },
    map: {
        minWidth: "500px",
        height: "500px",
    },
    root: {
        "& .MuiTable-root": {
            width: "100%",
        },
    },
    tableHeadRow: {
        color: "#1DBBED",
        padding: "3px 20px 3px 3px",
        fontSize: "12px",
        fontFamily: "'Open Sans', sans-serif",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    tableBodyRow: {
        color: "#47484B",
        padding: "3px 20px 3px 3px",
        fontSize: "12px",
        fontFamily: "'Open Sans', sans-serif",
        borderBottom: "none",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    timeRoot: {
        padding: "5px 0",
        margin: 0,
        "& .MuiTimelineItem-root": {
            minHeight: "40px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        padding: "2px",
        backgroundColor: "#B4B4B4",
    },
    timeConnector: {
        backgroundColor: "#B4B4B4",
    },

    timeDelay: {
        backgroundColor: "#faba1f",
    },
    timeEverythingOk: {
        backgroundColor: "#66D01D",
    },
    timeEverythingWrong: {
        backgroundColor: "#ED1D1D",
    },
    icon: {
        color: "white",
    },
    noData: {
        color: "#B4B4B4",
    },
    delay: {
        color: "#faba1f",
        fontWeight: "bold"
    },
    everythingOk: {
        color: "#66D01D",
        fontWeight: "bold"
    },
    everythingWrong: {
        color: "#ED1D1D",
        fontWeight: "bold"
    },
    invisible: {
        backgroundColor: "transparent",
    },
    iconButton: {
        backgroundColor: "#b4b4b4",
        "&:hover": {
            backgroundColor: "#777777",
        }
    }
}));

export default function MoreDetails(props) {
    const classes = useStyles();
    const {courses, driverId} = props;
    const [open, setOpen] = useState(true);
    const [singleData, setSingleData] = useState(null);

    useEffect(() => {
        const singleDriver = [];
        for (let i = 0; i < courses.length; i++) {
            if (courses[i].driver && driverId === courses[i].driver.id) {
                singleDriver.push(courses[i]);
            }
        }
        setSingleData(singleDriver);
    }, []);

    const handleClose = () => {
        props.switchShowModal(false);
    };

    function createData(
        number,
        parts,
        plannedTime,
        realTime,
        contractor,
        worker
    ) {
        return {
            number,
            parts,
            plannedTime,
            realTime,
            contractor,
            worker
        };
    }

    const rows = singleData ? singleData.map((data, apiIndex) => {
        // dane kierowcy/pracownika
        const personData = data.worker.map((item, index) => {
            return (
                <span key={index}>
                        {item.firstname} {item.surname}
                    </span>
            );
        });

        return createData(
            data.number ? `${data.number}` : "___",
            <div className={styles["moreDetails__table--item"]} key={apiIndex}>
                <Timeline align="alternate" className={classes.timeRoot}>
                    {data.path ? data.path.map((item, pathIndex) => {
                        let lastIndex = data.path.length - 1;
                        const statusBackground = item.confirmationStatus === 2 || item.confirmationStatus === 3 ? classes.timeDelay : item.confirmationStatus === 1 ? classes.timeEverythingOk : item.confirmationStatus === 4 ? classes.timeEverythingWrong : classes.noData


                        return (
                            <div key={pathIndex} className={styles["moreDetails__table--item--single"]}>
                                {pathIndex === lastIndex ?
                                    item.adres.id === data.driver.addresses.id ?
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={classes.invisible}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div className={styles["moreDetails__table--item--single--home"]}></div>
                                        </> :
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div
                                                className={styles["moreDetails__table--item--single--middle"]}></div>
                                        </>
                                    : pathIndex === 0 ?
                                        item.adres.id === data.driver.addresses.id ?
                                            <>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector
                                                            className={classes.invisible}/>
                                                        <TimelineDot
                                                            className={`${classes.timeDot} ${statusBackground}`}/>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    </TimelineSeparator>
                                                </TimelineItem>
                                                <div className={styles["moreDetails__table--item--single--home"]}></div>
                                            </> :
                                            <>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground}`}/>
                                                        <TimelineDot
                                                            className={`${classes.timeDot} ${statusBackground}`}/>
                                                        <TimelineConnector
                                                            className={`${classes.timeConnector} ${statusBackground} `}/>
                                                    </TimelineSeparator>
                                                </TimelineItem>
                                                <div
                                                    className={styles["moreDetails__table--item--single--middle"]}></div>
                                            </> :
                                        <>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                    <TimelineDot
                                                        className={`${classes.timeDot} ${statusBackground}`}/>
                                                    <TimelineConnector
                                                        className={`${classes.timeConnector} ${statusBackground}`}/>
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            <div
                                                className={styles["moreDetails__table--item--single--middle"]}></div>
                                        </>
                                }
                                <span className={styles["moreDetails__table--item--single--data"]}>
                                {`${item.adres.street ? item.adres.street : ""} ${item.adres.no ? item.adres.no : ""}${
                                    item.adres.flat ? `/${item.adres.flat}` : ""
                                } ${item.adres.zipCode ? item.adres.zipCode : ""} ${
                                    item.adres.city ? item.adres.city : ""
                                }`} </span>
                            </div>
                        )
                    }) : <span className={styles["moreDetails__table--item--single"]}> Brak kursu</span>}
                </Timeline>
            </div>,
            <div className={styles["moreDetails__table--item"]}>
                {data.path ? data.path.map((item, index) => {
                    return (
                        <span
                            key={index}
                            className={styles["moreDetails__table--item--single"]}>{format(fromUnixTime(item.time), "HH:mm", {
                            locale: pl,
                        })}</span>
                    )
                }) : <span className={styles["moreDetails__table--item--single"]}> ___</span>}
            </div>,
            <div className={styles["moreDetails__table--item"]}>
                {data.path ? data.path.map((item, index) => {
                    const statusColor = item.confirmationStatus === 2 || item.confirmationStatus === 3 ? classes.delay : item.confirmationStatus === 1 ? classes.everythingOk : item.confirmationStatus === 4 ? classes.everythingWrong : classes.noData
                    return (
                        <span
                            key={index}
                            className={`${styles["moreDetails__table--item--single"]} ${statusColor}`}>{item.arrivedAt ? (format(fromUnixTime(item.arrivedAt), "HH:mm", {
                            locale: pl,
                        })) : ""}</span>
                    )
                }) : <span className={styles["moreDetails__table--item--single"]}> ___</span>}
            </div>,
            data.kontrahent ? data.kontrahent.name : "-",
            data.worker ?
                <div className={styles["moreDetails__table--item"]}>{personData}</div>
                : "___"
        );
    }) : null;

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["moreDetails"]}>
                            <div className={styles["moreDetails__wrapper"]}>
                                <div className={styles["moreDetails__wrapper--map"]}>
                                    <h2 className={styles["moreDetails__wrapper--map--description"]}>
                                        {singleData ? `${singleData[0].driver.firstname ? singleData[0].driver.firstname : ""} ${singleData[0].driver.surname ? singleData[0].driver.surname : ""}` : "-"}
                                    </h2>
                                    <div className={classes.map}>
                                        <CourseMap
                                            courses={singleData}/>
                                    </div>
                                </div>
                                <div className={styles["moreDetails__wrapper--inputs"]}>
                                    <h2 className={styles["moreDetails__wrapper--inputs--description"]}>
                                        Aktywne trasy kierowcy
                                    </h2>
                                    <span
                                        className={styles["moreDetails__wrapper--inputs--close"]}
                                        onClick={handleClose}
                                    >X</span>
                                    <div className={styles["moreDetails__wrapper--inputs--data"]}>
                                        {courses && courses.length > 0 ? (
                                            <TableContainer className={classes.root}>
                                                <SimpleBar style={{maxHeight: "500px", minWidth: "500px"}}
                                                           className={classes.simplebar}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead className={classes.tableHead}>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{minWidth: column.minWidth}}
                                                                        className={classes.tableHeadRow}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows &&
                                                            rows.map((row, index) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" key={index}>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id}
                                                                                           align={column.align}
                                                                                           className={classes.tableBodyRow}>
                                                                                    {column.format && typeof value === "number"
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </SimpleBar>
                                            </TableContainer>
                                        ) : (
                                            <div className={styles["unfillCourses__noData"]}>Brak danych</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles["moreDetails__schedule"]}>
                            <h2 className={styles["moreDetails__schedule--description"]}>
                                Grafik kierowcy
                            </h2>
                            <SingleSchedule driverId={driverId}
                                            pickedDate={singleData ? singleData[0].destinationTime : null}/>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
