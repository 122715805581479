import React, {Fragment} from "react";
import ActiveRoute from "./aktywne/ActiveRoute";
import ArchiveRoute from "./archiwum/ArchiveRoute";
import UnfillCoursesRoute from "./nieobsadzone/UnfillCoursesRoute";
import ActiveDashboardRoute from "./activeDashboard/ActiveDashboardRoute";
import ArchiveDashboardRoute from "./archiveDashboard/ArchiveDashboardRoute";

const TrasyRoute = () => {
    return (
        <Fragment>
            <UnfillCoursesRoute/>
            <ActiveRoute/>
            <ArchiveRoute/>
            <ActiveDashboardRoute/>
            <ArchiveDashboardRoute/>
        </Fragment>
    );
};

export default TrasyRoute;
