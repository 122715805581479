import React, {useRef} from "react";
import axios from "axios";
import { NotificationContext } from "../../../../context";
import HttpClient from "../../../../HttpClient";
import InfoDialog from "../../../reusableComponents/alerts/infoDialog/InfoDialog";
import {useHistory} from "react-router-dom";

export default class DeleteAddress extends React.Component {
    static contextType = NotificationContext;
    source = axios.CancelToken.source();

    state = {
        deleteID: this.props.match.params.id,
    };

    deleteAddress = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.delete("api/addresses/" + this.state.deleteID, {}, { cancelToken: this.source.token }, true);
        } catch (e) {}
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.deleteAddress().then(r => {
            const target = window.location.pathname
            window.location.assign('http://localhost:3000/app/operator/adresy/lista');
        })
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    render() {
        return <></>
    }
}
