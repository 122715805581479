import { Button, TextField, Select, InputLabel, MenuItem, FormHelperText, FormControl } from "@material-ui/core";
import Cookie from "js-cookie";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import UndoIcon from "@material-ui/icons/Undo";
import { AlertTitle, Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useState, useMemo } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import * as Yup from "yup";
import {MimicContext, NotificationContext} from "../../../context";
import { debounce } from "../../../debounce";
import HttpClient from "../../../HttpClient";
import OsmMap from "../maps/osmMap/OsmMap";
import ProgressLine from "../progressLine/ProgressLine";
import styles from "./editPersonForm.module.scss";
import "simplebar/dist/simplebar.min.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import config from "../../../config";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
    cancelButton: {
        minWidth: "200px",
        backgroundColor: "#b4b4b4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
}));

const addressTypes = ["Domowy", "Hotel", "Inny"];

function Alert(props) {
    return <MuiAlert elevation={1} variant="filled" {...props} />;
}

const RailyCheckbox = withStyles({
    root: {
        color: "#1dbbed",
        "&$checked": {
            color: "#1dbbed",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function EditPersonForm(props) {
    const classes = useStyles();

    const createAddressField = () => ({
        addresses: [],
    });
    const { setNotification } = useContext(NotificationContext);
    const { isRoleType } = useContext(MimicContext)
    let actualAxiosRequest = null;
    const [edit, setEdit] = useState(false);
    const [newLat, setNewLat] = useState(53.0275681);
    const [newLon, setNewLon] = useState(18.5759393);
    const [addressSearch, setAddressSearch] = useState([createAddressField()]);
    const [ignoreForm, setIgnoreForm] = useState(true);

    const setCoordinates = (address) => {
        setIgnoreForm(false);
        setNewLat(address.lat);
        setNewLon(address.lon);
    };

    const [billingGroups, setBillingGroups] = useState([]);
    const [billingModels, setBillingModels] = useState([]);
    const [regions, setRegions] = useState([]);

    const loggedUser = Cookie.get("loggedUser");

    useEffect(() => {
        if (props.entity === "Kierowca") {
            const httpRequest = HttpClient.createRequest();

            httpRequest.get(`api/billing-groups`, undefined, loggedUser).then((response) => {
                const newBillingGroups = response.data.data.map((responseRegion) => ({
                    id: responseRegion.id,
                    name: responseRegion.name,
                }));
                setBillingGroups(newBillingGroups);
            });

            httpRequest.get(`api/billing-models`, undefined, loggedUser).then((response) => {
                const newBillingModels = response.data.data.map((responseBillingModel) => ({
                    id: responseBillingModel.id,
                    name: responseBillingModel.name,
                }));

                setBillingModels(newBillingModels);
            });

            httpRequest.get(`api/regions`, undefined, loggedUser).then((response) => {
                const emptyOption = {
                    id: "",
                    name: "brak",
                };

                const mappedRegionOptions = response.data.data.map((responseRegion) => ({
                    id: responseRegion.id,
                    name: responseRegion.name,
                }));

                const newRegionOptions = [emptyOption, ...mappedRegionOptions];
                setRegions(newRegionOptions);
            });
        }
    }, [props.entity]);

    useEffect(() => {
        const addresses = props.profile.address.map((address) => createAddressField());
        if (props.profile.address[0].lat)
            setCoordinates(props.profile.address[0]);
        setAddressSearch(addresses);
    }, [props.profile]);
    const handleIgnoreForm = () => {
        if (!ignoreForm) {
            setIgnoreForm(true);
        } else return;
    };

    const handleCreateAddress = () => {
        setAddressSearch([...addressSearch, createAddressField()]);
    };

    const handleRemoveAddress = (index) => {
        const _addressSearch = [...addressSearch];
        _addressSearch.splice(index, 1);
        setAddressSearch(_addressSearch);
    };

    const fillAddressFields = (value, index, setFieldValue) => {
        setIgnoreForm(false);
        const { address } = value;
        const addressField = {
            zipCode: address.postcode || "",
            city: address.city || address.village || address.town || address.hamlet || "",
            get street() {
                return address.road || this.city || "";
            },
            no: address.house_number || "",
            lat: value.lat,
            lon: value.lon,
        };

        for (const key in addressField) {
            setFieldValue(`address.${index}.${key}`, addressField[key]);
        }
        setNewLat(value.lat);
        setNewLon(value.lon);
    };

    const mapClicked = async (lonlat, setFieldValue, length) => {
        const value = lonlat.split(',')
        handleAddressChange((value[1] + ',' + value[0]).toString(), true, setFieldValue, length)
    }

    const handleAddressChange = async (value, fromMapClick = false, formikFields = null, length, index = 0) => {
        const lengthToIndex = length - 1
        setIgnoreForm(true);
        if (actualAxiosRequest) {
            actualAxiosRequest.cancel();
        }
        actualAxiosRequest = axios.CancelToken.source();
        const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {
                cancelToken: actualAxiosRequest.token,
            });
            const {data} = response;
            if (!fromMapClick) {
                const options = [];

                data.map((item) => {
                    options.push({
                        id: item.place_id,
                        name: item.display_name,
                        address: item.address,
                        lat: item.lat,
                        lon: item.lon,
                    });
                });
                setAddressSearch(options);
            } else {
                const address = {
                    id: data[0].place_id,
                    name: data[0].display_name,
                    address: data[0].address,
                    lat: data[0].lat,
                    lon: data[0].lon
                }
                fillAddressFields(address, lengthToIndex, formikFields)
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                actualAxiosRequest = null;
            }
        }
    };

    const moveMarker = (value) => {
        if (value.lat !== null || value.lon !== null) {
            setIgnoreForm(false);
            setNewLat(value.lat);
            setNewLon(value.lon);
        }
    };

    const search = debounce((value, index) => {
        handleAddressChange(value, false, null, addressSearch.length, index);
    }, 2000);

    const handleOpen = () => {
        setEdit(true);
    };

    const handleClose = (cb) => {
        if (cb) cb();
        setEdit(false);
    };

    const deleteAddress = (values, value, index, setFieldValue, remove) => {
        const deletedAddresses = values.address.filter(address => address.status === 'deleted');
        if (value.status === "new") {
            remove(index);
            handleRemoveAddress(index);
        } else if (value.status === "existed" || value.status === "locked") {
            if (!deletedAddresses)
                setFieldValue(`address.${index}.status`, "deleted");
            else if (deletedAddresses.length < values.address.length - 1)
                setFieldValue(`address.${index}.status`, "deleted");
        } else if (value.status === "deleted") {
            const status = value.locked ? "locked" : "existed";
            setFieldValue(`address.${index}.status`, status);
        }
    };

    const defaultType = (value) => {
        let dataType = "Domowy";
        switch (value.type) {
            case "HOTEL":
                dataType = "Hotel";
                break;
            case "NEST":
                dataType = "Gniazdo";
                break;
            case "OTHER":
                dataType = "Inny";
                break;
        }
        return dataType;
    };

    const phoneRegExp = /^([0-9-\s]{1,18})$/;
    const zipCodeRegExp = /([0-9]{2})(-?)([0-9]{3})/;

    const clientsWithDelegations = ["Cargo Master", "Master Inwestycje"];

    const hasDelegationAccess = clientsWithDelegations.includes(loggedUser.companyName);

    const getDriverSchema = () => {
        if (hasDelegationAccess) {
            return Yup.object().shape({
                name: Yup.string().trim().required("Uzupełnij to pole"),
                surName: Yup.string().trim().required("Uzupełnij to pole"),
                privatePhoneNumber: Yup.string()
                    .trim()
                    .required("Uzupełnij to pole")
                    .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                email: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workEmail: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workPhoneNumber: Yup.string().trim().matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                address: Yup.array().of(
                    Yup.object().shape({
                        zipCode: Yup.string()
                            .matches(/\d{2}-?\d{3}/, "Kod pocztowy musi być w formacie XX-XXX")
                            .trim()
                            .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego"),
                        city: Yup.string().trim(),
                        street: Yup.string().trim(),
                        no: Yup.string().trim(),
                        flat: Yup.string()  ,
                        type: props.entity === "Kierowca" ? Yup.string() : Yup.string().trim(),
                        status: Yup.string(),
                        active: Yup.boolean(),
                    })
                ),
                delegationRate: Yup.number()
                    .min(0.0001, "Stawka za 1km musi być większa niż 0")
                    .required("Te pole jest wymagane"),
                position: Yup.string().trim().required("Te pole jest wymagane"),
                regNo: Yup.string().trim().required("Te pole jest wymagane"),
                engineCapacity: Yup.string().trim().required("Te pole jest wymagane"),
                isHired: Yup.bool().required(`Te pole jest wymagane`),
                normalRate: Yup.number(),
                waitingRate: Yup.number(),
                region: Yup.string(),
                billingGroup: Yup.string().required(`Te pole jest wymagane`),
                billingModel: Yup.string().required(`Te pole jest wymagane`),
                notes: Yup.string(),
            });
        } else {
            return Yup.object().shape({
                name: Yup.string().trim().required("Uzupełnij to pole"),
                surName: Yup.string().trim().required("Uzupełnij to pole"),
                privatePhoneNumber: Yup.string()
                    .trim()
                    .required("Uzupełnij to pole")
                    .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                email: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workEmail: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                isTaxiDriver: Yup.boolean(),
                workPhoneNumber: Yup.string().trim().matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                isHired: Yup.bool().required(`Te pole jest wymagane`),
                normalRate: Yup.number(),
                waitingRate: Yup.number(),
                region: Yup.string(),
                billingGroup: Yup.string().required(`Te pole jest wymagane`),
                billingModel: Yup.string().required(`Te pole jest wymagane`),
                notes: Yup.string(),
                address: Yup.array().of(
                    Yup.object().shape({
                        zipCode: Yup.string()
                            .matches(/\d{2}-?\d{3}/, "Kod pocztowy musi być w formacie XX-XXX")
                            .trim()
                            .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego"),
                        city: Yup.string().trim(),
                        street: Yup.string().trim(),
                        no: Yup.string().trim(),
                        flat: Yup.string(),
                        type: props.entity === "Kierowca" ? Yup.string() : Yup.string().trim(),
                        status: Yup.string(),
                        active: Yup.boolean(),
                    })
                ),
            });
        }
    };

    const getWorkerSchema = () => {
        if (hasDelegationAccess) {
            return Yup.object().shape({
                name: Yup.string().trim().required("Uzupełnij to pole"),
                surName: Yup.string().trim().required("Uzupełnij to pole"),
                privatePhoneNumber: Yup.string()
                    .trim()
                    .required("Uzupełnij to pole")
                    .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                email: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workEmail: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workPhoneNumber: Yup.string().trim().matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                address: Yup.array().of(
                    Yup.object().shape({
                        zipCode: Yup.string()
                            .matches(/\d{2}-?\d{3}/, "Kod pocztowy musi być w formacie XX-XXX")
                            .trim()
                            .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego").required("Uzupełnij to pole"),
                        city: Yup.string().trim().required(),
                        street: Yup.string().trim().required(),
                        no: Yup.string().trim().required(),
                        flat: Yup.string(),
                        type: props.entity === "Kierowca" ? Yup.string() : Yup.string().trim().required(),
                        status: Yup.string(),
                        active: Yup.boolean(),
                    })
                ),
                delegationRate: Yup.number()
                    .min(0.0001, "Stawka za 1km musi być większa niż 0")
                    .required("Te pole jest wymagane"),
                position: Yup.string().trim().required("Te pole jest wymagane"),
                regNo: Yup.string().trim().required("Te pole jest wymagane"),
                engineCapacity: Yup.string().trim().required("Te pole jest wymagane"),
                isHired: Yup.bool().required(`Te pole jest wymagane`),

                rate: Yup.number().required("Uzupełnij to pole"),
            });
        } else {
            return Yup.object().shape({
                name: Yup.string().trim().required("Uzupełnij to pole"),
                surName: Yup.string().trim().required("Uzupełnij to pole"),
                privatePhoneNumber: Yup.string()
                    .trim()
                    .required("Uzupełnij to pole")
                    .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                email: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                workEmail: Yup.string().trim().email("Nieprawidłowy format adresu email"),
                isTaxiDriver: Yup.boolean(),
                workPhoneNumber: Yup.string().trim().matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
                isHired: Yup.bool().required(`Te pole jest wymagane`),
                address: Yup.array().of(
                    Yup.object().shape({
                        zipCode: Yup.string()
                            .matches(/\d{2}-?\d{3}/, "Kod pocztowy musi być w formacie XX-XXX")
                            .trim()
                            .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego").required("Uzupełnij to pole"),
                        city: Yup.string().trim().required("Uzupełnij to pole"),
                        street: Yup.string().trim().required("Uzupełnij to pole"),
                        no: Yup.string().trim().required("Uzupełnij to pole"),
                        flat: Yup.string(),
                        type: props.entity === "Kierowca" ? Yup.string() : Yup.string().trim().required("Uzupełnij to pole"),
                        status: Yup.string(),
                        active: Yup.boolean(),
                    })
                ),
            });
        }
    };

    const getValidationSchema = () => {
        if (props.entity === "Pracownik") {
            return getWorkerSchema();
        }

        if (props.entity === "Kierowca") {
            return getDriverSchema();
        }
    };

    return (
        <div className={styles["editPerson"]}>
            <Formik
                initialValues={props.profile}
                validationSchema={() => getValidationSchema()}
                enableReinitialize
                onSubmit={(values, { setFieldError }) => {
                    values = {
                        ...values,
                        address: values.address.filter((item, index) => !isEqual(item, props.profile.address[index])),
                    };
                    props.sendUserData(values, setFieldError, handleClose);
                }}
            >
                {({ values, errors, touched, resetForm, setFieldValue }) => (
                    <Form className={styles["editPersonForm"]}>

                        {props.isLoading ? (
                            <ProgressLine />
                        ) : (
                            <>
                                <SimpleBar style={{ maxHeight: "70vh", minWidth: 520 }} className={classes.simplebar}>
                                    <div className={styles["editPersonForm__description"]}>
                                        <h1 className={styles["editPersonForm__description--title"]}>{props.label}</h1>
                                        <p className={styles["editPersonForm__description--subTitle"]}>
                                            Wypełnij wszystkie pola i zatwierdź
                                        </p>
                                    </div>
                                    <div className={styles["editPersonForm__personal"]}>
                                        <div className={styles["editPersonForm__personal--description"]}>
                                            <p className={styles["editPersonForm__personal--description--title"]}>
                                                Dane osobowe
                                            </p>
                                        </div>
                                        <div className={styles["editPersonForm__personal--inputs"]}>
                                            <div className={styles["editPersonForm__personal--inputs--field"]}>
                                                <Field
                                                    onKeyUp={handleIgnoreForm}
                                                    className={classes.root}
                                                    name="name"
                                                    helperText={!!errors.name && touched.name ? errors.name : ""}
                                                    error={!!errors.name && touched.name}
                                                    label="Imię*"
                                                    disabled={!edit}
                                                    variant="standard"
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                            <div className={styles["editPersonForm__personal--inputs--field"]}>
                                                <Field
                                                    onKeyUp={handleIgnoreForm}
                                                    className={classes.root}
                                                    name="surName"
                                                    helperText={
                                                        !!errors.surName && touched.surName ? errors.surName : ""
                                                    }
                                                    error={!!errors.surName && touched.surName}
                                                    label="Nazwisko*"
                                                    variant="standard"
                                                    disabled={!edit}
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["editPersonForm__address"]}>
                                        <div className={styles["editPersonForm__address--description"]}>
                                            <p className={styles["editPersonForm__address--description--title"]}>
                                                Dane adresowe
                                            </p>
                                        </div>
                                        <FieldArray
                                            name="address"
                                            render={({ push, remove }) => (
                                                <>
                                                    {values.address.map((value, index) => (
                                                        <div
                                                            key={index}
                                                            className={styles["editPersonForm__address--inputs"]}
                                                        >
                                                            <Autocomplete
                                                                disabled={!edit}
                                                                options={addressSearch ?? []}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) =>
                                                                    option.id === value.id
                                                                }
                                                                filterOptions={(options, state) => options}
                                                                noOptionsText="Nie znaleziono podanego adresu"
                                                                onOpen={() => moveMarker(value)}
                                                                onChange={(e, value) => {
                                                                    if (value)
                                                                        fillAddressFields(value, index, setFieldValue);
                                                                }}
                                                                onInputChange={(e, newValue) => {
                                                                    if (newValue.trim()) search(newValue, index);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <Field
                                                                        {...params}
                                                                        className={classes.root}
                                                                        label="Wyszukaj adres"
                                                                        variant="filled"
                                                                        margin="none"
                                                                        name="alone"
                                                                        fullWidth
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: "new-password",
                                                                        }}
                                                                        as={TextField}
                                                                    />
                                                                )}
                                                            />
                                                            <div
                                                                className={
                                                                    styles["editPersonForm__address--inputs--warning"]
                                                                }
                                                            >
                                                                {!value.active && (
                                                                    <Alert
                                                                        severity="warning"
                                                                        action={
                                                                            edit &&
                                                                            value.status === "locked" && (
                                                                                <IconButton
                                                                                    aria-label="close"
                                                                                    color="inherit"
                                                                                    size="small"
                                                                                    onClick={() => {
                                                                                        deleteAddress(
                                                                                            values,
                                                                                            value,
                                                                                            index,
                                                                                            setFieldValue,
                                                                                            remove
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <DeleteForeverIcon fontSize="inherit" />
                                                                                </IconButton>
                                                                            )
                                                                        }
                                                                    >
                                                                        Ten adres jest używany w innym zleceniu.
                                                                    </Alert>
                                                                )}
                                                                {edit && value.status === "new" && (
                                                                    <Alert
                                                                        variant="standard"
                                                                        action={
                                                                            <IconButton
                                                                                aria-label="close"
                                                                                color="inherit"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    deleteAddress(values,
                                                                                        value,
                                                                                        index,
                                                                                        setFieldValue,
                                                                                        remove
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <AlertTitle>Dodano nowy adres</AlertTitle>
                                                                        Kliknij usuń aby usunąć adres z listy
                                                                    </Alert>
                                                                )}

                                                                {edit && value.status === "deleted" && (
                                                                    <Alert
                                                                        variant="standard"
                                                                        severity="error"
                                                                        action={
                                                                            <IconButton
                                                                                aria-label="close"
                                                                                color="inherit"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    deleteAddress(values,
                                                                                        value,
                                                                                        index,
                                                                                        setFieldValue,
                                                                                        remove
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <UndoIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <AlertTitle>Usunięto adres</AlertTitle>
                                                                        Kliknij cofnij aby przywrócić adres
                                                                    </Alert>
                                                                )}

                                                                {edit && value.status === "initial" && (
                                                                    <Alert variant="standard">
                                                                        <AlertTitle>Domyślny adres</AlertTitle>
                                                                        Edytuj adres lub dodaj nowy do listy
                                                                    </Alert>
                                                                )}

                                                                {edit && value.status === "existed" && !value.locked && (
                                                                    <Alert
                                                                        variant="standard"
                                                                        severity="info"
                                                                        action={
                                                                            <IconButton
                                                                                aria-label="close"
                                                                                color="inherit"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    deleteAddress(values,
                                                                                        value,
                                                                                        index,
                                                                                        setFieldValue,
                                                                                        remove
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <AlertTitle>Istniejący adres</AlertTitle>
                                                                        Edytuj lub usuń adres z listy
                                                                    </Alert>
                                                                )}

                                                                {edit && value.status === "edited" && (
                                                                    <Alert
                                                                        variant="standard"
                                                                        severity="info"
                                                                        action={
                                                                            <IconButton
                                                                                aria-label="close"
                                                                                color="inherit"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    deleteAddress(values,
                                                                                        value,
                                                                                        index,
                                                                                        setFieldValue,
                                                                                        remove
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <AlertTitle>Zedytowany adres</AlertTitle>
                                                                        Edytuj lub usuń adres z listy
                                                                    </Alert>
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "editPersonForm__address--inputs--fieldGroup"
                                                                        ]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        onKeyUp={handleIgnoreForm}
                                                                        disabled
                                                                        className={classes.root}
                                                                        name={`address.${index}.street`}
                                                                        error={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.street`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.street`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.street`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.street`)
                                                                                ? getIn(
                                                                                    errors,
                                                                                    `address.${index}.street`
                                                                                )
                                                                                : ""
                                                                        }
                                                                        label="Ulica*"
                                                                        variant="standard"
                                                                        style={{ width: 240 }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        onKeyUp={handleIgnoreForm}
                                                                        disabled={!edit}
                                                                        className={classes.root}
                                                                        name={`address.${index}.no`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.no`) &&
                                                                            !!getIn(errors, `address.${index}.no`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.no`) &&
                                                                            !!getIn(errors, `address.${index}.no`)
                                                                                ? getIn(errors, `address.${index}.no`)
                                                                                : ""
                                                                        }
                                                                        label="Nr domu*"
                                                                        variant="standard"
                                                                        style={{ width: 115 }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        onKeyUp={handleIgnoreForm}
                                                                        disabled={!edit}
                                                                        className={classes.root}
                                                                        name={`address.${index}.flat`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.flat`) &&
                                                                            !!getIn(errors, `address.${index}.flat`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.flat`) &&
                                                                            !!getIn(errors, `address.${index}.flat`)
                                                                                ? getIn(errors, `address.${index}.flat`)
                                                                                : ""
                                                                        }
                                                                        label="Nr mieszkania"
                                                                        variant="standard"
                                                                        style={{ width: 115 }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{ justifyContent: "flex-start" }}
                                                                className={
                                                                    styles[
                                                                        "editPersonForm__address--inputs--fieldGroup"
                                                                        ]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        onKeyUp={handleIgnoreForm}
                                                                        disabled={!edit}
                                                                        className={classes.root}
                                                                        name={`address.${index}.city`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.city`) &&
                                                                            !!getIn(errors, `address.${index}.city`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.city`) &&
                                                                            !!getIn(errors, `address.${index}.city`)
                                                                                ? getIn(errors, `address.${index}.city`)
                                                                                : ""
                                                                        }
                                                                        label="Miasto*"
                                                                        variant="standard"
                                                                        style={{ width: 240 }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        onKeyUp={handleIgnoreForm}
                                                                        // disabled
                                                                        disabled={!edit}
                                                                        className={classes.root}
                                                                        name={`address.${index}.zipCode`}
                                                                        error={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.zipCode`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.zipCode`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.zipCode`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.zipCode`)
                                                                                ? getIn(
                                                                                    errors,
                                                                                    `address.${index}.zipCode`
                                                                                )
                                                                                : ""
                                                                        }
                                                                        label="Kod pocztowy*"
                                                                        variant="standard"
                                                                        style={{ width: 115 }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["editPersonForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    {props.entity !== "Kierowca" && (
                                                                        <Autocomplete
                                                                            onKeyUp={handleIgnoreForm}
                                                                            disabled={!edit}
                                                                            autoSelect
                                                                            options={addressTypes}
                                                                            value={() => defaultType(value)}
                                                                            getOptionLabel={(option) =>
                                                                                typeof option === "string"
                                                                                    ? option
                                                                                    : option()
                                                                            }
                                                                            getOptionSelected={(option, value) =>
                                                                                (typeof option === "string"
                                                                                    ? option
                                                                                    : option()) ===
                                                                                (typeof value === "string"
                                                                                    ? value
                                                                                    : value())
                                                                            }
                                                                            style={{ width: 115 }}
                                                                            onChange={(e, newValue) => {
                                                                                let dataType = "";
                                                                                switch (newValue) {
                                                                                    case "Domowy":
                                                                                        dataType = "HOME";
                                                                                        break;
                                                                                    case "Hotel":
                                                                                        dataType = "HOTEL";
                                                                                        break;
                                                                                    case "Gniazdo":
                                                                                        dataType = "NEST";
                                                                                        break;
                                                                                    case "Inny":
                                                                                        dataType = "OTHER";
                                                                                        break;
                                                                                }
                                                                                setFieldValue(
                                                                                    `address.${index}.type`,
                                                                                    dataType
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Field
                                                                                    className={classes.root}
                                                                                    {...params}
                                                                                    name={`address.${index}.type`}
                                                                                    error={
                                                                                        !!getIn(
                                                                                            touched,
                                                                                            `address.${index}.type`
                                                                                        ) &&
                                                                                        !!getIn(
                                                                                            errors,
                                                                                            `address.${index}.type`
                                                                                        )
                                                                                    }
                                                                                    helperText={
                                                                                        !!getIn(
                                                                                            touched,
                                                                                            `address.${index}.type`
                                                                                        ) &&
                                                                                        !!getIn(
                                                                                            errors,
                                                                                            `address.${index}.type`
                                                                                        )
                                                                                            ? getIn(
                                                                                                errors,
                                                                                                `address.${index}.type`
                                                                                            )
                                                                                            : ""
                                                                                    }
                                                                                    label="Typ adresu*"
                                                                                    variant="standard"
                                                                                    margin="none"
                                                                                    fullWidth
                                                                                    as={TextField}
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {edit && props.entity === "Pracownik" && (
                                                        <div
                                                            className={styles["editPersonForm__address--buttonWrapper"]}
                                                        >
                                                            <button
                                                                className={
                                                                    styles[
                                                                        "editPersonForm__address--buttonWrapper--addAddressButton"
                                                                        ]
                                                                }
                                                                type="button"
                                                                color="default"
                                                                margin="normal"
                                                                size="small"
                                                                onClick={() => {
                                                                    push(props.buildEmptyAddress("new"));
                                                                    handleCreateAddress();
                                                                }}
                                                            >
                                                                <AddIcon />
                                                            </button>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "editPersonForm__address--buttonWrapper--description"
                                                                        ]
                                                                }
                                                            >
                                                                Dodaj adres
                                                            </p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className={styles["editPersonForm__contact"]}>
                                        <div className={styles["editPersonForm__contact--description"]}>
                                            <p className={styles["editPersonForm__contact--description--title"]}>
                                                Dane kontaktowe
                                            </p>
                                        </div>
                                        <div className={styles["editPersonForm__contact--inputs"]}>
                                            <div className="editPersonForm__contact--inputs--field">
                                                <Field
                                                    className={classes.root}
                                                    name="privatePhoneNumber"
                                                    helperText={
                                                        !!errors.privatePhoneNumber && touched.privatePhoneNumber
                                                            ? errors.privatePhoneNumber
                                                            : ""
                                                    }
                                                    error={!!errors.privatePhoneNumber && touched.privatePhoneNumber}
                                                    label="Telefon*"
                                                    variant="standard"
                                                    disabled={!edit}
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                            <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                <Field
                                                    className={classes.root}
                                                    name="email"
                                                    value={values.email}
                                                    helperText={!!errors.email && touched.email ? errors.email : ""}
                                                    error={!!errors.email && touched.email}
                                                    label="E-mail"
                                                    variant="standard"
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    disabled={!edit}
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["editPersonForm__contact"]}>
                                        <div className={styles["editPersonForm__contact--description"]}>
                                            <p className={styles["editPersonForm__contact--description--title"]}>
                                                {" "}
                                                Dane kontaktowe służbowe
                                            </p>
                                        </div>
                                        <div className={styles["editPersonForm__contact--inputs"]}>
                                            <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                <Field
                                                    className={classes.root}
                                                    name="workPhoneNumber"
                                                    helperText={
                                                        !!errors.workPhoneNumber && touched.workPhoneNumber
                                                            ? errors.workPhoneNumber
                                                            : ""
                                                    }
                                                    error={!!errors.workPhoneNumber && touched.workPhoneNumber}
                                                    label="Telefon służbowy"
                                                    variant="standard"
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    disabled={!edit}
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                            <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                <Field
                                                    className={classes.root}
                                                    name="workEmail"
                                                    helperText={
                                                        !!errors.workEmail && touched.workEmail ? errors.workEmail : ""
                                                    }
                                                    error={!!errors.workEmail && touched.workEmail}
                                                    label="E-mail służbowy"
                                                    variant="standard"
                                                    style={{ width: 240 }}
                                                    margin="none"
                                                    disabled={!edit}
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                        </div>
                                        {props.entity === "Kierowca" && (
                                            <div className={styles["editPersonForm__contact--inputs"]}>
                                                <div
                                                    className={
                                                        styles["editPersonForm__contact--inputs--field--checkbox"]
                                                    }
                                                >
                                                    <Field
                                                        name="isTaxiDriver"
                                                        defaultChecked={props.profile.isTaxiDriver}
                                                        color="primary"
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                        render={({ field }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <RailyCheckbox
                                                                        {...field}
                                                                        disabled={!edit}
                                                                        checked={field.value}
                                                                        name="isTaxiDriver"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Kierowca należy do podwykonawcy"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {props.entity === "Kierowca" && (
                                        <div className={styles["editPersonForm__about"]}>
                                            <div className={styles["editPersonForm__about--description"]}>
                                                <p className={styles["editPersonForm__about--description--title"]}>
                                                    O kierowcy
                                                </p>
                                            </div>

                                            <div className={styles["editPersonForm__about--inputs--field"]}>
                                                <Field
                                                    name="isHired"
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label": "secondary checkbox",
                                                    }}
                                                    fullWidth
                                                    margin="none"
                                                    className={classes.root}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <RailyCheckbox
                                                                    {...field}
                                                                    checked={field.value}
                                                                    name="isHired"
                                                                    color="primary"
                                                                    disabled={!edit}
                                                                />
                                                            }
                                                            fullWidth
                                                            label="Kierowca zatrudniony"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className={styles["editPersonForm__about--inputs"]}>
                                                <div className={styles["editPersonForm__about--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="normalRate"
                                                        type="number"
                                                        helperText={
                                                            !!errors.normalRate && touched.normalRate
                                                                ? errors.normalRate
                                                                : ""
                                                        }
                                                        error={!!errors.normalRate && touched.normalRate}
                                                        label="Stawka normalna*"
                                                        variant="standard"
                                                        margin="none"
                                                        fullWidth
                                                        disabled={!edit}
                                                        as={TextField}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles["editPersonForm__about--inputs"]}>
                                                <div className={styles["editPersonForm__about--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="waitingRate"
                                                        type="number"
                                                        disabled={!edit}
                                                        helperText={
                                                            !!errors.waitingRate && touched.waitingRate
                                                                ? errors.waitingRate
                                                                : ""
                                                        }
                                                        error={!!errors.waitingRate && touched.waitingRate}
                                                        label="Stawka za postój*"
                                                        variant="standard"
                                                        margin="none"
                                                        fullWidth
                                                        as={TextField}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles["editPersonForm__about--inputs"]}>
                                                <div className={styles["editPersonForm__about--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="region"
                                                        disabled={!edit}
                                                        helperText={
                                                            !!errors.region && touched.region ? errors.region : ""
                                                        }
                                                        error={!!errors.region && touched.region}
                                                        margin="none"
                                                        fullWidth
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                select
                                                                label="Region"
                                                                disabled={!edit}
                                                            >
                                                                {regions.map((region) => (
                                                                    <MenuItem key={region.id} value={region.id}>
                                                                        {region.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles["editPersonForm__about--inputs"]}>
                                                <div className={styles["editPersonForm__about--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="billingGroup"
                                                        disabled={!edit}
                                                        helperText={
                                                            !!errors.billingGroup && touched.billingGroup
                                                                ? errors.billingGroup
                                                                : ""
                                                        }
                                                        error={!!errors.billingGroup && touched.billingGroup}
                                                        fullWidth
                                                        as={(field) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                select
                                                                label="Grupa rozliczeniowa*"
                                                                disabled={!edit}
                                                            >
                                                                {billingGroups.map((billingGroup) => (
                                                                    <MenuItem
                                                                        key={billingGroup.id}
                                                                        value={billingGroup.id}
                                                                    >
                                                                        {billingGroup.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles["editPersonForm__about--inputs"]}>
                                                <div className={styles["editPersonForm__about--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="billingModel"
                                                        disabled={!edit}
                                                        helperText={
                                                            !!errors.billingModel && touched.billingModel
                                                                ? errors.billingModel
                                                                : ""
                                                        }
                                                        error={!!errors.billingModel && touched.billingModel}
                                                        margin="none"
                                                        fullWidth
                                                        as={(field) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                select
                                                                disabled={!edit}
                                                                label="Model rozliczeniowy*"
                                                            >
                                                                {billingModels.map((billingModel) => (
                                                                    <MenuItem
                                                                        key={billingModel.id}
                                                                        value={billingModel.id}
                                                                    >
                                                                        {billingModel.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {hasDelegationAccess && (
                                        <div className={styles["editPersonForm__contact"]}>
                                            <div className={styles["editPersonForm__contact--description"]}>
                                                <p className={styles["editPersonForm__contact--description--title"]}>
                                                    {" "}
                                                    Dane do delegacji
                                                </p>
                                            </div>
                                            <div className={styles["editPersonForm__contact--inputs"]}>
                                                <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        name="delegationRate"
                                                        disabled={!edit}
                                                        helperText={
                                                            !!errors.delegationRate && touched.delegationRate
                                                                ? errors.delegationRate
                                                                : ""
                                                        }
                                                        error={!!errors.delegationRate && touched.delegationRate}
                                                        label="Stawka za 1 KM"
                                                        variant="standard"
                                                        type="number"
                                                        style={{ width: 240 }}
                                                        margin="none"
                                                        fullWidth
                                                        as={TextField}
                                                    />
                                                </div>
                                                <div
                                                    className={styles["editPersonForm__contact--inputs--field"]}
                                                    style={{ width: "100%" }}
                                                >
                                                    <FormControl error={errors.engineCapacity} fullWidth>
                                                        <InputLabel style={{ fontSize: "1rem" }} id="engineCapacity">
                                                            Pojemność silnika
                                                        </InputLabel>
                                                        <Select
                                                            id="engineCapacity"
                                                            disabled={!edit}
                                                            disabled={!edit}
                                                            fullWidth
                                                            defaultValue={
                                                                props.defaultEngineCapacity || "Powyżej 900cm3"
                                                            }
                                                            name="engineCapacity"
                                                            onChange={(e) => {
                                                                setFieldValue("engineCapacity", e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="Poniżej 900cm3">Poniżej 900cm3</MenuItem>
                                                            <MenuItem value="Powyżej 900cm3">Powyżej 900cm3</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errors.engineCapacity}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className={styles["editPersonForm__contact--inputs"]}>
                                                <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        disabled={!edit}
                                                        name="position"
                                                        helperText={
                                                            !!errors.position && touched.position ? errors.position : ""
                                                        }
                                                        error={!!errors.position && touched.position}
                                                        label="Stanowisko"
                                                        variant="standard"
                                                        style={{ width: 240 }}
                                                        margin="none"
                                                        fullWidth
                                                        as={TextField}
                                                    />
                                                </div>
                                                <div className={styles["editPersonForm__contact--inputs--field"]}>
                                                    <Field
                                                        onKeyUp={handleIgnoreForm}
                                                        className={classes.root}
                                                        disabled={!edit}
                                                        name="regNo"
                                                        helperText={!!errors.regNo && touched.regNo ? errors.regNo : ""}
                                                        error={!!errors.regNo && touched.regNo}
                                                        label="Numer rejestracyjny"
                                                        variant="standard"
                                                        style={{ width: 240 }}
                                                        margin="none"
                                                        fullWidth
                                                        as={TextField}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {props.entity === "Kierowca" && !isRoleType('OPERATOR_CONTRACTOR') && (
                                        <>
                                            <div className={styles["editPersonForm__regNo"]}>
                                                <div className={styles["editPersonForm__regNo--description"]}>
                                                    <p className={styles["editPersonForm__regNo--description--title"]}>
                                                        Dane pojazdu
                                                    </p>
                                                </div>
                                                <div className={styles["editPersonForm__regNo--inputs"]}>
                                                    <div className={styles["editPersonForm__regNo--inputs--field"]}>
                                                        <Field
                                                            onKeyUp={handleIgnoreForm}
                                                            className={classes.root}
                                                            name="regNo"
                                                            disabled={!edit}
                                                            helperText={
                                                                !!errors.regNo && touched.regNo ? errors.regNo : ""
                                                            }
                                                            error={!!errors.regNo && touched.regNo}
                                                            label="Numer rejestracyjny"
                                                            variant="standard"
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                </div>
                                            </div>{" "}
                                            { !isRoleType('OPERATOR_CONTRACTOR') &&
                                                <div className={styles["editPersonForm__rate"]}>
                                                    <div className={styles["editPersonForm__rate--description"]}>
                                                        <p className={styles["editPersonForm__rate--description--title"]}>
                                                            Rozliczenia
                                                        </p>
                                                    </div>

                                                    <div className={styles["editPersonForm__rate--inputs"]}>
                                                        <div className={styles["editPersonForm__rate--inputs--field"]}>
                                                            <Field
                                                                onKeyUp={handleIgnoreForm}
                                                                className={classes.root}
                                                                disabled={!edit}
                                                                name="notes"
                                                                helperText={
                                                                    !!errors.notes && touched.notes ? errors.notes : ""
                                                                }
                                                                error={!!errors.notes && touched.notes}
                                                                label="Model-Stawka NIE LOTOS"
                                                                variant="standard"
                                                                margin="none"
                                                                fullWidth
                                                                as={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )}

                                    <div className={styles["editPersonForm__buttonWrapper"]}>
                                        {edit ? (
                                            <div>
                                                <Button
                                                    className={classes.confirmButton}
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={
                                                        values.address.filter(address => address.status === 'deleted').length === values.address.length
                                                    }
                                                >
                                                    Potwierdź
                                                </Button>
                                                <Button
                                                    onClick={() => handleClose(resetForm)}
                                                    style={{ marginLeft: 15 }}
                                                    className={classes.cancelButton}
                                                    variant="contained"
                                                >
                                                    Anuluj edycję
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button
                                                onClick={handleOpen}
                                                className={classes.confirmButton}
                                                variant="contained"
                                            >
                                                Edytuj
                                            </Button>
                                        )}
                                    </div>
                                </SimpleBar>
                            </>
                        )}
                        <div className={styles["editPersonForm"]}>
                            {newLon && newLat && (
                                <div className={styles["editPersonForm__map"]}>
                                    <div className={styles["editPersonForm__description"]}>
                                        <h1 className={styles["editPersonForm__description--title"]}>Mapa poglądowa</h1>
                                        <p className={styles["editPersonForm__description--subTitle"]}>
                                            Śledź znacznik aktualnie wpisywanego adresu
                                        </p>
                                    </div>
                                    <OsmMap
                                        width="600px"
                                        height="70vh"
                                        pickedLat={newLat}
                                        pickedLon={newLon}
                                        ignoreForm={ignoreForm}
                                        onClickOnMap={(lonlat) => mapClicked(lonlat, setFieldValue, values['address'].length)}
                                    />
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
