import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, RegularShape, Stroke, Style} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import LineString from "ol/geom/LineString";
import whiteMarker from "../../../../../img/marker_white.png"

import HttpClient from "../../../../../HttpClient";
import config from "../../../../../config";

const colors = [[23, 166, 210, 1], [20, 224, 44, 1], [56, 50, 219, 1], [242, 92, 180, 1], [242, 44, 137, 1], [56, 174, 242, 1], [235, 226, 63, 1], [99, 235, 221, 1], [88, 177, 232, 1], [108, 99, 235, 1],];

export default class SummaryCourseMap extends React.Component {
    constructor(props) {
        super(props);
        this.center = [19.479744, 52.068813];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6;
        this.marker = whiteMarker;
    }


    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "osm-map",
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
        });
        this.getRoutes();
    }


    getRoutes = () => {
        const {courseDetails} = this.props;
        let counter = 0;

        for (let i = 0; i < courseDetails.length; i++) {
            let singleColor = colors[counter];
            let coordsPack = "";
            const startCoords = [courseDetails[i].pickup.lon, courseDetails[i].pickup.lat];
            const endCoords = [courseDetails[i].destination.lon, courseDetails[i].destination.lat];
            let routeEndCoords = `;${courseDetails[i].destination.lon},${courseDetails[i].destination.lat}`;

            this.createMarker(startCoords, "startPoint", singleColor);
            this.createMarker(endCoords, "startPoint", singleColor);

            for (let j = 0; j < courseDetails[i].fullWorkerAddressData.length; j++) {

                let prevCoords = coordsPack;
                let workerCoords = [courseDetails[i].fullWorkerAddressData[j].address.lon, courseDetails[i].fullWorkerAddressData[j].address.lat];
                let coords = `;${courseDetails[i].fullWorkerAddressData[j].address.lon},${courseDetails[i].fullWorkerAddressData[j].address.lat}`;

                coordsPack = `${prevCoords}${coords}`;

                if (j > 0 && courseDetails[i].direction === true) {
                    this.createPoint(workerCoords, "point", singleColor);
                } else if (j < courseDetails[i].fullWorkerAddressData.length - 1 && courseDetails[i].direction === false) {
                    this.createPoint(workerCoords, "point", singleColor)
                }
            }
            const url = `${config.osrmURL}/route/v1/driving/${startCoords[0]},${startCoords[1]}${coordsPack}${routeEndCoords}?steps=true&geometries=geojson&overview=full`;
            this.getAxios(url, singleColor)

            counter < 10 ? counter++ : counter = 0;
        }
    }

    getAxios = async (url, singleColor) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url);
            this.route = response.data;
            this.createRoute(this.route, singleColor);
        } catch (e) {
        }
    }

    createMarker(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    createPoint(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        const strokeStyle = new Stroke({
            color: singleColor,
            width: 1.2
        })

        const regularShape = new RegularShape({
            fill: new Fill({
                color: singleColor,
            }),
            stroke: strokeStyle,
            points: 20,
            radius: 10
        })


        let style = new Style({
            image: regularShape
        })

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    createLine(prevStep, singleStep, singleColor) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            }),
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);
    }

    createRoute(route, singleColor) {
        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]]
                    this.createLine(prevStep, singleStep, singleColor);
                }
            }
        }
    }
    
    render() {
        return (
            <div
                id={"osm-map"}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
