import React from "react";
import MainRozliczenia from "../../reusableComponents/rozliczenia/Rozliczenia";

export default function Rozliczenia() {
  return (
    <div>
      <MainRozliczenia />
    </div>
  );
}
