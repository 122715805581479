import React, { Fragment } from "react";
import ProfileRoute from "./profile/ProfileRoute";

const UserRoute = () => {
    return (
        <Fragment>
            <ProfileRoute />
        </Fragment>
    );
};

export default UserRoute;
