import React, { useState, useEffect } from "react";

export const OrdersTimer = (props) => {
    const getTimeFromStartDate = () => {
        return new Date().getTime() - props.startDate.getTime();
    };
    const [timeFromStart, setTimeFromStart] = useState(getTimeFromStartDate);
    const [hasReachedCriticalLimit, setHasReachedCriticalLimit] = useState(false);

    const critialLimitTime = 1000 * 60 * 15;

    const recalculateState = () => {
        const newTimeFromStart = getTimeFromStartDate();
        setTimeFromStart(newTimeFromStart);

        const hasReachedCriticalMoment = newTimeFromStart >= critialLimitTime;
        setHasReachedCriticalLimit(hasReachedCriticalMoment);
    };

    useEffect(() => {
        recalculateState();
        const timerInterval = setInterval(recalculateState, 1000);

        return () => clearInterval(timerInterval);
    }, [props.startDate]);

    const hours = Math.floor(timeFromStart / (1000 * 60 * 60));
    const minutes = Math.floor((timeFromStart / (1000 * 60)) % 60);
    const seconds = Math.floor((timeFromStart / 1000) % 60);

    return (
        <span style={hasReachedCriticalLimit ? { color: "#ED1D1D" } : {}}>
            {hours <= 9 ? "0" + hours : hours}:{minutes <= 9 ? "0" + minutes : minutes}:
            {seconds <= 9 ? "0" + seconds : seconds}
        </span>
    );
};
