import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import styles from "./urlopyTable.module.scss";
import {makeStyles, styled} from "@material-ui/core/styles";
import {
    createMuiTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";
import {
    format,
    endOfDay,
    endOfMonth,
    getUnixTime,
    fromUnixTime,
    isDate,
    startOfMonth,
    getDate,
    getMonth,
    getDaysInMonth,
    getYear,
    startOfDay
} from "date-fns";
import {pl} from "date-fns/locale";
import ClearIcon from "@material-ui/icons/Clear";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {DatePicker} from "@material-ui/pickers";
import HttpClient from "../../../../HttpClient";
import {ThemeProvider} from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {convertNumberToArray} from "../../../../helper/convertNumberToArray";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ProgressLine from "../../progressLine/ProgressLine";

const primaryColumns = [
    {
        id: "driver",
        label: "Kierowca",
        sorted: false,
        query: true,
        order: null,
        minWidth: 230,
        maxWidth: 230,
    },
    {
        id: "startAt",
        label: "Początek urlopu",
        query: true,
        order: 'ASC',
        sorted: true,
        minWidth: 230,
        maxWidth: 230,
    },
    {
        id: "finishAt",
        label: "Koniec urlopu",
        query: true,
        order: null,
        sorted: false,
        minWidth: 230,
        maxWidth: 230,
    },
    {
        id: "details",
        label: "",
        minWidth: 60,
        maxWidth: 60,
    },
];
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 25px 0 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTableCell-body": {
            padding: "2px 25px 2px 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTabs-fixed": {
            overflowX: "auto !important",
        },
    },

    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        width: 240,
        padding: 0,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px"
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    removeButton: {
        backgroundColor: "#ED1D1D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#C01313",
        }
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        }
    },
    icon: {
        color: "white",
    },
});

const SearchDatePicker = styled(DatePicker)({
    width: 160,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}

const generateStartEndDate = (newDate) => ({
    start: getUnixTime(startOfMonth(newDate)),
    end: getUnixTime(endOfMonth(newDate))
});

export default function UrlopyTable(props) {
    const classes = useStyles();
    const now = new Date();
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [page, setPage] = useState(0);
    const [columns, setColumns] = useState(primaryColumns);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchValues, setSearchValues] = useState([{id: 'startAt', value: null}, {id: 'finishAt', value: null}]);
    const [date, setDate] = useState(generateStartEndDate(now));
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [updateDriver, setUpdateDriver] = useState(false);
    const [year, setYear] = useState(getYear(now));
    const [month, setMonth] = useState(getMonth(now));
    const [day, setDay] = useState(null);
    const [api, setApi] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState(convertNumberToArray(getDaysInMonth(new Date(year, month))));

    const handleOnEnterPress = (e) => {
        if (e.key === 'Enter') {
            getData();
        }
    }

    const handleSetStartEndDate = () => {
        let rangeDate = null;
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay))
            }
        } else {
            if (props.status === 'archive') {
                const thisMonth = new Date(Date.UTC(year, month));
                rangeDate = {
                    start: getUnixTime(startOfMonth(thisMonth)),
                    end: getUnixTime(endOfMonth(thisMonth))
                }
            }
        }
        setDate(rangeDate);
    }

    const handleDaysInMonthChange = (newYear, newMonth) => {
        setDaysInMonth(convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth))));
    }


    // const handleDatePickerDateChange = (newDate) => {
    //     const day = getDate(newDate);
    //     const month = getMonth(newDate);
    //     const year = getYear(newDate);
    //     setDay(day);
    //     setMonth(month);
    //     setYear(year);
    // }


    // const getDatePickerValue = () => {
    //     return day ? new Date(year, month, day) : null;
    // }

    useEffect(() => {
        handleDaysInMonthChange(year, month);
        handleSetStartEndDate();
    }, [year, month, day]);


    const source = axios.CancelToken.source();

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const setEdgeOfDate = (item) => {
        if (item.id === 'startAt' && item.value) return {...item, value: startOfDay(item.value)}
        if (item.id === 'finishAt' && item.value) return {...item, value: endOfDay(item.value)}
        return item;
    }

    const getData = async () => {
        window.scrollTo(0, 0);
        const _searchParams = searchValues
            .map(setEdgeOfDate)
            .map((a) => ({...a}));

        const sortField = [...columns].filter((item) => item.sorted === true);

        const getParam = _searchParams.map(getParams).join("");
        setIsLoading(true);
        const apiDayOff = `/api/driver/day-off?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`
        const requestDayOff = HttpClient.createRequest();

        try {
            const {data} = await requestDayOff.get(apiDayOff, {}, true);
            setApi(data.data);
            setCount(data.count);
            setIsLoading(false);
        } catch (e) {
        }
    };

    const removeDayOff = async (index) => {
        const dayOff = api[index];
        const dayOffId = api[index].id;
        const httpRequest = HttpClient.createRequest();

        try {
            await httpRequest.delete(`api/day-off?id=${dayOffId}`, {cancelToken: source.token}, true);
            const newDays = api.filter((item) => {
                return item !== dayOff;
            });
            setApi(newDays);
        } catch (e) {
        }
    };

    const startAt = searchValues.find(value => value.id === 'startAt')?.value;
    const finishAt = searchValues.find(value => value.id === 'finishAt')?.value;

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        setUpdateDriver(false)

        return () => {
            source.cancel();
        };
    }, [invokeClearColumn, startAt, finishAt, date, rowsPerPage, page, updateDriver, props.isPostedData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        setSearchValues([...filterObject, {id: field, value: query}]);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    };

    const clearDate = () => {
        setDay(null);
    }

    function createData(
        driver,
        startAt,
        finishAt,
        details
    ) {
        return {
            driver,
            startAt,
            finishAt,
            details
        };
    }

    const ShowArrow = ({sorted, order, classes}) => {
        if (sorted) {
            if (order === "ASC") {
                return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
            } else if (order === "DESC") {
                return <ArrowDownwardIcon fontSize="small" className={classes.headCellArrow}/>;
            }
        } else {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
        }
    };

    const createSortHandler = (_column) => {
        columns.map((column) => {
            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
        });
        setColumns(columns);
        handleSort();
    };

    const handleSort = () => {
        setPage(0);
        getData();
    };

    const rows = api
        ? api.map((data, index) => {
            const start = data.startAt ? format(fromUnixTime(data.startAt), "d.LL.yyyy, HH:mm", {
                locale: pl,
            }) : '';

            const finish = data.finishAt ? format(fromUnixTime(data.finishAt), "d.LL.yyyy, HH:mm", {
                locale: pl,
            }) : '';

            return createData(
                `${data.user.name ? data.user.name : ""}
            ${
                    data.user.surName ? data.user.surName : ''
                }`
                ,
                start,
                finish,
                < div className={styles["urlopyTable__buttonContainer"]}>
                    <BootstrapTooltip title="Usuń urlop">
                        <IconButton
                            size="small"
                            className={classes.removeButton}
                            onClick={() => {
                                removeDayOff(index);
                            }}
                        >
                            <ClearIcon className={classes.icon}/>
                        </IconButton>
                    </BootstrapTooltip>
                </div>
            )
        }) : null;

    return (
        <>
            <Paper className={classes.root}>
                {isLoading ? <ProgressLine/> :
                    <TableContainer>
                        <SimpleBar style={{
                            minHeight: "55vh",
                            maxHeight: "55vh",
                        }}
                                   className={classes.simplebar}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <Fragment key={column.id}>
                                                <TableCell
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                    }}
                                                >
                                                    {column.query ? (
                                                        <TableSortLabel
                                                            className={classes.tableSortLabel}
                                                            hideSortIcon={true}
                                                        >
                                                            {column.id === "startAt" || column.id === "finishAt" ? (
                                                                <ThemeProvider theme={defaultMaterialTheme}>
                                                                    <Input
                                                                        className={classes.headCell}
                                                                        inputComponent={SearchDatePicker}
                                                                        inputProps={{
                                                                            style: {height: 'unset'},
                                                                            onChange: (newValue) => handleSearch(column.id, newValue),
                                                                            value: searchValues.find((v) => v.id === column.id)
                                                                                ?.value,
                                                                            disablePast: props.status === 'active',
                                                                            format: "d MMMM",
                                                                            disableToolbar: props.status === 'archive',
                                                                            InputProps: {
                                                                                disableUnderline: true,
                                                                                placeholder: column.label,
                                                                            },
                                                                        }}
                                                                        endAdornment={
                                                                            <>
                                                                                <Tooltip title="Wyczyść">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => handleClearColumn(column.id)}
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ClearIcon
                                                                                            className={classes.headCellArrow}
                                                                                            fontSize="small"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Sortuj">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() =>
                                                                                            createSortHandler(column, index, rows)
                                                                                        }
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ShowArrow
                                                                                            sorted={column.sorted}
                                                                                            order={column.order}
                                                                                            classes={classes}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                    />
                                                                </ThemeProvider>
                                                            ) : (
                                                                <Input
                                                                    variant="outlined"
                                                                    className={classes.headCell}
                                                                    defaultValue={
                                                                        searchValues.find((v) => v.id === column.id)
                                                                            ?.value
                                                                    }
                                                                    onChange={(e) => handleSearch(column.id, e.target.value)}
                                                                    onKeyDown={handleOnEnterPress}
                                                                    placeholder={column.label}
                                                                    endAdornment={
                                                                        <>
                                                                            <Tooltip title="Wyczyść">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => handleClearColumn(column.id)}
                                                                                    aria-label="visible columns"
                                                                                >
                                                                                    <ClearIcon
                                                                                        className={classes.headCellArrow}
                                                                                        fontSize="small"/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {column.sorted !== null &&
                                                                            <Tooltip title="Sortuj">
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        createSortHandler(column, index, rows)
                                                                                    }
                                                                                    size="small"
                                                                                    aria-label="visible columns"
                                                                                >
                                                                                    <ShowArrow
                                                                                        sorted={column.sorted}
                                                                                        order={column.order}
                                                                                        classes={classes}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>}
                                                                        </>
                                                                    }
                                                                />
                                                            )}
                                                        </TableSortLabel>
                                                    ) : (
                                                        column.label
                                                    )}
                                                </TableCell>
                                            </Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {api && api.length > 0 ? (
                                    <TableBody>
                                        {rows &&
                                        rows.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" key={row.id}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}
                                                                       className={classes.tableCell}>
                                                                {column.format && typeof value === "number"
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                ) : <div className={styles["urlopyTable__noData"]}>Brak danych</div>
                                }
                            </Table>
                        </SimpleBar>
                        <TablePagination
                            className={classes.pagination}
                            labelRowsPerPage={"Liczba wierszy"}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                }
            </Paper>
        </>
    );
}
