import React, {useContext, useEffect, useState} from 'react';
import SimpleBar from "simplebar-react";
import {Button, TextField} from "@material-ui/core";
import styles from './conversation.module.scss';
import HttpClient from "../../../../../HttpClient";
import {format, fromUnixTime} from 'date-fns';
import {pl} from 'date-fns/locale';
import ProgressLine from "../../../progressLine/ProgressLine";
import {MimicContext} from "../../../../../context";
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import {styled} from "@material-ui/core/styles";

const schema = Yup.object().shape({
    note: Yup.string().required('Uzupełnij to pole'),
});

const DispatchInput = styled(TextField)({
    width: "100%",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#faba1f",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#faba1f",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#faba1f",
        },
        "&:hover fieldset": {
            borderColor: "#faba1f",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faba1f",
        },
    },
});

const OperatorInput = styled(TextField)({
    width: "100%",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default function Conversation({refreshTable, classes, settlementId}) {
    const [conversation, setConversation] = useState([]);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [isAnswear, setIsAnswear] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {roles} = useContext(MimicContext);

    const sortByDate = (a, b) => {
        return a.createdAt > b.createdAt ? 1 : -1;
    }

    const getData = async () => {
        setIsLoading(true);
        const httpRequest = HttpClient.createRequest();
        try {
            const {data} = await httpRequest.get(`api/conversation/${settlementId}`, {}, true);
            setConversation(data.sort(sortByDate));
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    const sendMessage = async (value) => {
        setIsLoading(true);
        const httpRequest = HttpClient.createRequest();
        const message = value;
        try {
            await httpRequest.post(`api/conversation/${settlementId}`, {message}, {}, true);
            setShouldUpdate(!shouldUpdate);
            setIsAnswear(false);
            refreshTable();
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    const acceptSettlement = async () => {
        setIsLoading(true);
        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.put(`api/settlement/accept/${settlementId}`, {}, {}, true);
            setShouldUpdate(!shouldUpdate);
            setIsAnswear(false);
            refreshTable();
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [shouldUpdate]);

    return (
        <div className={styles["conversation__inputs"]}>
            <SimpleBar style={isAnswear ? {maxHeight: "55vh", minWidth: 320} : {maxHeight: "70vh", minWidth: 320}}
                       className={classes.simplebar}>
                <div className={styles["conversation__inputs--conversation"]}>
                    {!isLoading ? conversation.map((item, index) => {
                        const status = item.status === 1 ? "operator"
                            : item.status === 2 ? "dispatch"
                                : "accepted";
                        return (
                            <div
                                key={index} className={styles["conversation__inputs--conversation--" + status]}>
                                <div
                                    className={styles["conversation__inputs--conversation--" + status + "--info"]}>
                                    {item.author}, {format(fromUnixTime(item.createdAt), "d LLLL y 'g.' HH:mm", {locale: pl})}
                                </div>
                                <div
                                    className={styles["conversation__inputs--conversation--" + status + "--content"]}>
                                    {item.message}
                                </div>
                            </div>
                        )
                    }) : (
                        <ProgressLine/>
                    )}
                </div>
            </SimpleBar>
            {/*panel odpowiedzi*/}
            {!isLoading && (
                <SimpleBar style={{maxHeight: "25vh", minWidth: 320}} className={classes.simplebar}>
                    <div
                        className={styles["conversation__inputs--conversation--buttonWrapper"]}>
                        <Button
                            size="small"
                            className={roles.includes('ROLE_DYSPOZYTOR') ? classes.answearButton : classes.operatorButton}
                            style={roles.includes('ROLE_DYSPOZYTOR') ? {order: 1} : {order: 2}}
                            variant="outlined"
                            onClick={() => setIsAnswear(!isAnswear)}>
                            {isAnswear ? "Ukryj panel" : "Odpowiedz"}
                        </Button>
                        <Button
                            size="small"
                            disabled={conversation[conversation.length - 1]?.status === 3}
                            className={classes.confirmButton}
                            style={roles.includes('ROLE_DYSPOZYTOR') ? {order: 2} : {order: 1}}
                            variant="outlined"
                            onClick={() => {
                                acceptSettlement()
                            }
                            }
                        >
                            Akceptacja
                        </Button>
                    </div>
                    {isAnswear &&
                    <div
                        className={styles["conversation__inputs--conversation--answearPanel"]}>
                        <Formik
                            initialValues={{
                                note: ""
                            }}
                            enableReinitialize
                            validationSchema={schema}
                            onSubmit={(values, {resetForm}) => {
                                sendMessage(values.note, resetForm);
                            }}
                            render={({values, errors, touched, setFieldValue, setFieldError}) => (
                                <Form>
                                    <Field
                                        className={classes.root}
                                        name="note"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        margin="none"
                                        as={roles.includes('ROLE_DYSPOZYTOR') ? DispatchInput : OperatorInput}
                                        helperText={
                                            !!errors.note && touched.note ? errors.note : ""
                                        }
                                        error={!!errors.note && touched.note}
                                    />
                                    <div
                                        className={roles.includes('ROLE_DYSPOZYTOR') ? classes.dispatchWrapper : classes.operatorWrapper}>
                                        <Button
                                            size="small"
                                            className={roles.includes('ROLE_DYSPOZYTOR') ? classes.answearButton : classes.operatorButton}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Wyślij
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        />
                    </div>
                    }
                </SimpleBar>
            )}
        </div>
    );
}
