import React from "react";
import Orders from "../../../reusableComponents/orders/Orders";

const columns = [
    {
        id: "status",
        label: "",
        query: false,
        maxWidth: 20,
    },
    {
        id: "toStart",
        label: "",
        sorted: false,
        query: false,
        order: null,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "number",
        label: "ID",
        query: true,
        sorted: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "edited",
        label: "!",
        minWidth: 50,
        maxWidth: 85,
    },
    {
        id: "relatedOrders",
        label: "Połączone zlecenia",
        type: "text",
        edit: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "time",
        label: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        minWidth: 180,
        maxWidth: 180,
    },
    {
        id: "worker",
        label: "Pracownik",
        sorted: false,
        order: null,
        query: true,
        minWidth: 170,
        maxWidth: 170,
    },
    {
        id: "pickup",
        label: "Adres odbioru",
        sorted: false,
        order: null,
        query: true,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "destination",
        label: "Adres docelowy",
        sorted: false,
        order: null,
        query: true,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "orderNo",
        label: "Nr zlecenia",
        query: true,
        sorted: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "notes",
        label: "Uwagi",
        query: true,
        sorted: false,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "branch",
        label: "Oddział",
        query: true,
        sorted: null,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "details",
        label: "",
        query: false,
        minWidth: 100,
        maxWidth: 100,
    },
];

export default function Active() {
    return <Orders entity="dyspozytor" status="active" columns={columns} />;
}
