import { saveAs } from "file-saver";
import {format, fromUnixTime} from "date-fns";

const headers = [
    "Nr",
    "Data od",
    "Data do",
    "Data utworzenia",
    "Pracownik",
    "Koszt",
    "Suma km"
];

export const downloadCsv = (data, startDate, endDate) => {
    let csv = headers.join(";");
    data.sort((a, b) => {
        let aa = a.delegationNumber.split('/'),
            bb = b.delegationNumber.split('/');
        return aa[0] - bb[0] || aa[1] - bb[1] || aa[2] - bb[2];
    });
    data.forEach((singleData) => {
        const startDate = format(fromUnixTime(singleData.startDate), 'dd.MM.yyyy');
        const endDate = format(fromUnixTime(singleData.endDate), 'dd.MM.yyyy');
        const createdAt = format(fromUnixTime(singleData.createdAt), 'dd.MM.yyyy');
        const singleDataParsed = {
            delegationNumber: singleData.delegationNumber,
            startDate,
            endDate,
            createdAt,
            worker: singleData.worker,
            totalCost: singleData.totalCost,
            totalKm: singleData.totalKm,
        };
        const dataValues = Object.values(singleDataParsed);
        const row = "\n" + dataValues.join(";");
        csv += row;
    });
    let BOM = "\uFEFF";
    csv = BOM + csv;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    startDate = format(startDate, 'dd.MM.yyyy');
    endDate = format(endDate, 'dd.MM.yyyy');
    saveAs(blob, `Delegacja ${startDate}-${endDate}.csv`);
};
