/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";

import {getIn, useFormikContext} from "formik";
import Axios from "axios";
import { Backdrop, Fade, Modal, TextField } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import RepeatIcon from "@material-ui/icons/Repeat";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import RoomIcon from "@material-ui/icons/Room";
import LinkOn from "@material-ui/icons/Link";

import { SelectAddress, OrderWorker } from "app/reusableComponents/newOrderPanel/orderWorker";
import {
    ButtonIcon,
    ButtonIconThemed,
    OrderWrapper,
    OrderTitle,
    ButtonText,
    FlexRow,
    WorkersWrapper,
    BoldText,
    WarningText,
    MapWrapper,
    CloseMapButton,
    LinkIconSpan,
} from "app/reusableComponents/newOrderPanel/styled";
import config from "config";
import HttpClient from "HttpClient";
import { OrderMap } from "app/reusableComponents/newOrderPanel/map";
import { useOrderDataContext, useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";
import {
    sortAddressesReduce,
    getAddressesCords,
    formatDistance,
    formatTime,
    orderType,
    orderReturn,
    orderShiftReturn,
    sortAddresses,
} from "app/reusableComponents/newOrderPanel/helpers";
import SelectContractor from "app/reusableComponents/newOrderPanel/contractor/SelectContractor";
import {MimicContext} from "../../../../context";

export const Order = ({ removeOrder, insertOrder, orderIndex }) => {
    const { touched, errors, values, setFieldValue, handleBlur, handleChange } = useFormikContext();
    const { setOrderRoute, removeOrderRoute } = useOrderDataContext();
    const { isEditView } = useOrderContext();
    const { userDispatch } = useContext(MimicContext)

    const [showMap, setShowMap] = useState(false);
    const [routeData, setRouteData] = useState(null);
    const [routeWaypoints, setRouteWaypoints] = useState(null);
    const baseName = `orders[${orderIndex}]`;

    const touchedFiled = getIn(touched, `${baseName}.orderNo`);
    const errorFirstName = getIn(errors, `${baseName}.orderNo`);
    const isChangedDirection = getIn(values, `${baseName}.isChangedDirection`);
    const currentOrder = getIn(values, `${baseName}`);
    const isWorkAddress = !!currentOrder.workers.find((worker) => worker.isWorkAddress)
    const [contractorID, setContractorID] = useState(undefined)

    const removeReturnOrders = (parentOrderIndex) => {
        const orders = getIn(values, 'orders')
        const parentOrder = getIn(values, `orders[${parentOrderIndex}]`)
        const workerIsWorkAddress = parentOrder.workers.map((worker) => worker.isWorkAddress)

        if (workerIsWorkAddress) {
            orders.forEach((order) => {
                if (
                    (order.type === 'orderShiftReturn' || order.type === 'orderReturn') &&
                    order.relatedOrderName === `orders[${parentOrderIndex}]`
                ) {
                    removeOrder(parentOrderIndex+1)
                    removeOrderRoute(parentOrderIndex+1)
                }
            })
        }

        parentOrder.hasReturnOrder = false;
        parentOrder.hasReturnOrderShift = false;
    }

    useEffect(() => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const formattedAddresses = sortAddresses(currentOrder);
        const workersAddresses = currentOrder.workers.reduce(sortAddressesReduce, []);
        const dividedAddresses = {
            workers: workersAddresses,
            station: { ...currentOrder.destination, timeOrigin: currentOrder.timeDestination },
        };

        setRouteWaypoints(formattedAddresses);

        const urlWithCords = getAddressesCords(formattedAddresses);

        if (!urlWithCords.includes(";")) {
            removeOrderRoute(orderIndex);
            setRouteWaypoints(null);
            setFieldValue(`${baseName}.distance`, 0);
            setFieldValue(`${baseName}.duration`, 0);
            setRouteData(null);
            return;
        }

        const url = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson&overview=full`;
        const httpRequest = HttpClient.createRequest();

        const fetchRoute = async () => {
            try {
                const { data } = await httpRequest.get(url, {cancelToken: source.token});
                setFieldValue(`${baseName}.distance`, data.routes[0].distance);
                setFieldValue(`${baseName}.duration`, data.routes[0].duration);
                setOrderRoute(formattedAddresses, dividedAddresses, orderIndex, currentOrder.isChangedDirection);

                setRouteData(data.routes[0]);
            } catch (e) {}
        };

        fetchRoute();

        return () => source.cancel() 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentOrder.origin,
        currentOrder.destination,
        currentOrder.workers,
        currentOrder.isChangedDirection,
        currentOrder.timeDestination,
    ]);

    return (
        <OrderWrapper>
            <fieldset style={{ all: "unset" }}>
                <OrderTitle>
                    {`Zamówienie - ${orderIndex + 1}. `}
                    {currentOrder.type !== orderType.ORDER ? `${currentOrder.orderName}` : null}
                    {values.isConnected && (
                        <LinkIconSpan>
                            <LinkOn />
                        </LinkIconSpan>
                    )}
                </OrderTitle>
                <SelectContractor contractorID={(contractorID) => {
                    setContractorID(contractorID)
                }} baseName={baseName}  />
                <FlexRow gap=".5rem">
                    <OrderWorker baseName={`${baseName}.workers`} orderIndex={orderIndex}
                                 contractorID={contractorID ?? currentOrder.contractor?.id} isEditView={isEditView} />
                    <FlexRow gap=".5rem" direction={currentOrder.isChangedDirection ? "row-reverse" : "row"}>
                        <SelectAddress baseName={`${baseName}.origin`} name={`${baseName}`} orderIndex={orderIndex}
                                       removeReturnOrder={() => removeReturnOrders(orderIndex)} userDispatch={userDispatch} />
                        <ButtonIconThemed
                            type="button"
                            disabled={currentOrder.type !== orderType.ORDER}
                            onClick={() => setFieldValue(`${baseName}.isChangedDirection`, !isChangedDirection)}
                        >
                            <SwapHorizIcon />
                        </ButtonIconThemed>
                        <SelectAddress
                            baseName={`${baseName}.destination`}
                            name={`${baseName}`}
                            orderIndex={orderIndex}
                            userDispatch={userDispatch}
                        />
                    </FlexRow>
                    <WorkersWrapper>
                        <TextField
                            name={`${baseName}.orderNo`}
                            id={`${baseName}.orderNo`}
                            label="Nr zlecenia"
                            helperText={touchedFiled && errorFirstName ? errorFirstName : ""}
                            value={currentOrder.orderNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextField
                            name={`${baseName}.notes`}
                            id={`${baseName}.notes`}
                            label="Uwagi"
                            helperText={touchedFiled && errorFirstName ? errorFirstName : ""}
                            value={currentOrder.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </WorkersWrapper>
                </FlexRow>
                <FlexRow justify={isEditView ? "flex-end" : "space-between"} gap=".75rem">
                    {!isEditView && (
                        <FlexRow gap=".75rem">
                            {currentOrder.type === orderType.ORDER && (
                                <>
                                    <FlexRow gap=".5rem">
                                        <ButtonIconThemed
                                            type="button"
                                            disabled={currentOrder.hasReturnOrder || isWorkAddress }
                                            onClick={() => {
                                                insertOrder(
                                                    orderIndex + 1,
                                                    orderReturn(currentOrder, baseName, orderIndex + 1)
                                                );
                                                setFieldValue(`${baseName}.hasReturnOrder`, true);
                                            }}
                                        >
                                            <SwapHorizIcon />
                                        </ButtonIconThemed>
                                        <ButtonText>Zamów kurs powrotny</ButtonText>
                                    </FlexRow>
                                    <FlexRow gap=".5rem">
                                        <ButtonIcon
                                            type="button"
                                            disabled={currentOrder.hasReturnOrderShift || isWorkAddress}
                                            onClick={() => {
                                                insertOrder(
                                                    orderIndex + 1,
                                                    orderShiftReturn(currentOrder, baseName, orderIndex + 1)
                                                );
                                                setFieldValue(`${baseName}.hasReturnOrderShift`, true);
                                            }}
                                        >
                                            <RepeatIcon />
                                        </ButtonIcon>
                                        <ButtonText>Zamów odwiezienie Zmiany</ButtonText>
                                    </FlexRow>
                                </>
                            )}
                            <ButtonIcon
                                clear_bg="true"
                                type="button"
                                onClick={() => {
                                    if (currentOrder.relatedOrderName) {
                                        setFieldValue(
                                            `${currentOrder.relatedOrderName}.${
                                                currentOrder.type === orderType.ORDER_RETURN
                                                    ? "hasReturnOrder"
                                                    : "hasReturnOrderShift"
                                            }`,
                                            false
                                        );
                                    }
                                    removeOrder(orderIndex);
                                    removeOrderRoute(orderIndex);
                                }}
                                disabled={
                                    values.orders.length === 1 ||
                                    currentOrder.hasReturnOrderShift ||
                                    currentOrder.hasReturnOrder
                                }
                            >
                                <ClearIcon />
                            </ButtonIcon>
                            <ButtonText>Usuń Zamówienie</ButtonText>
                        </FlexRow>
                    )}
                    <FlexRow gap=".75rem">
                        <BoldText>Dystans: {formatDistance(currentOrder?.distance)}</BoldText>
                        <BoldText>
                            Czas Przejazdu: <WarningText>{formatTime(currentOrder?.duration)}</WarningText>
                        </BoldText>
                        <Tooltip arrow title="Pokaż na mapie">
                            <ButtonIcon onClick={() => setShowMap(true)}>
                                <RoomIcon />
                            </ButtonIcon>
                        </Tooltip>
                    </FlexRow>
                </FlexRow>
            </fieldset>
            {showMap && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    onClose={() => setShowMap(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={true}>
                        <MapWrapper>
                            <CloseMapButton onClick={() => setShowMap(false)}>X</CloseMapButton>
                            <OrderMap mapName="big-map" routeWaypoints={routeWaypoints} routeData={routeData} />
                        </MapWrapper>
                    </Fade>
                </Modal>
            )}
        </OrderWrapper>
    );
};
