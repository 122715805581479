import { baseOrder, orderType } from ".";
import {returnOrUpdate} from "ol/extent";

const formatEditOrders = (workAddresses)=> {
    return  (newOrders, order) => [
        ...newOrders,
        {
            ...baseOrder,
            uuid: order.uuid,
            id: order.id,
            type: orderType.ORDER,
            driver: order.driver,
            orderName: "Zamówienie",
            orderNo: order.orderNo || "",
            notes: order.notes || "",
            isChangedDirection: !order.direction,
            destination: !order.direction ? order.from : order.to,
            timeDestination:
                (order.direction && order.isDestinationTime) || (!order.direction && !order.isDestinationTime)
                    ? new Date(order.destinationTime * 1000).toISOString()
                    : null,
            workers: order.worker.reduce((workers, worker) => {
                const findAddress = worker.adres.find((adres) => worker.addressId === adres.id ?? null); //if undef
                const workAddress = workAddresses.find((workAddress) => workAddress.id === worker.addressId )
                const newWorker = {
                    timeOrigin: worker.timeOrigin ? new Date(order.destinationTime * 1000).toISOString() : null,
                    isWorkAddress: !findAddress,
                    person: {
                        adres: worker.adres,
                        id: worker.id,
                        name: worker.firstname,
                        surName: worker.surname,
                    },
                    homeAddress: findAddress ? findAddress : null,
                    workAddress: !findAddress ? workAddress : null
                };
                return [...workers, newWorker];
            }, [])
        },
    ];
}

const prepareOrders = (acc, curr) => {
    const timeDestinationNew = curr.timeDestination ?? curr.workers.find((elem) => elem.timeOrigin).timeOrigin;

    const workerWithTimeOrigin = curr.workers.find((elem) => elem.timeOrigin);

    const part = curr.workers.map((worker) => [
        worker.person.id, // worker id
        worker.isWorkAddress ? worker.workAddress.id : worker.homeAddress.id, // address id
        worker.isWorkAddress, // determines if it is work address
        worker.timeOrigin
    ]);

    const getDestination = () => {
        if (curr.timeDestination && !curr.isChangedDirection) {
            return true;
        }
        if (curr.timeDestination && curr.isChangedDirection) {
            return false;
        }
        if (workerWithTimeOrigin && !curr.isChangedDirection) {
            return false;
        }
        if (workerWithTimeOrigin && curr.isChangedDirection) {
            return true;
        }
    };

    // const getDirection = () => {
    //     if(curr.timeDestination && !curr.isChangedDirection) {
    //         if(part[0][2] === true){
    //             return false
    //         }
    //         return true;
    //     }
    //     if (curr.timeDestination && curr.isChangedDirection) {
    //         if(part[0][2] === true){
    //             return true
    //         }
    //         return false;
    //     }
    //     if(workerWithTimeOrigin && !curr.isChangedDirection){
    //         return true;
    //     }
    //     if(workerWithTimeOrigin && curr.isChangedDirection){
    //         return false;
    //     }
    // };

    const rest = {
        pickup: curr.isChangedDirection
            ? curr.destination.id
            : workerWithTimeOrigin
            ? workerWithTimeOrigin.workAddress.id
            : part[0][1],
        direction: !curr.isChangedDirection,
        destination: curr.isChangedDirection
            ? workerWithTimeOrigin
                ? workerWithTimeOrigin.workAddress.id
                : part[part.length - 1][1]
            : curr.destination.id,
        trainDestination: null,
        trainId: null,
        isWorkAddress: null,
        orderNo: curr.orderNo,
        isDestination: getDestination(),
        notes: curr.notes,
        time: timeDestinationNew,
        removedWorkers: [],
        editedWorkers: [],
        newWorkers: [],
        dispatcher: curr?.dispatcher ? curr.dispatcher.id : null,
        contractor: curr?.contractor ? curr.contractor.id : null,
        id: curr.id || null,
        isNewPanel: true
    };

    return {
        ...acc,
        data: [
            ...acc.data,
            {
                part,
                ...rest,

            },
        ],
    };
};

export { formatEditOrders, prepareOrders };
