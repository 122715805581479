import React from "react";
import AppRoute from "../../../AppRoute";
import { StatementsTable } from "./StatementsTable";

export const StatementsRoute = () => {
  const columns = [

    {
      id: "direction",
      label: "",
      sorted: false,
      query: false,
      order: null,
      minWidth: 120,
    },
    {
      id: "time",
      label: "Data",
      sorted: true,
      order: "ASC",
      query: true,
      minWidth: 160,
    },
    {
      id: "worker",
      label: "Pracownik",
      sorted: false,
      order: null,
      query: true,
      minWidth: 200,
    },
    {
      id: "pickup",
      label: "Adres początkowy",
      sorted: false,
      order: null,
      query: true,
      minWidth: 200,
    },
    {
      id: "destination",
      label: "Adres docelowy",
      sorted: false,
      order: null,
      query: true,
      minWidth: 200,
    },
    {
      id: "manualKm",
      label: "KM",
      query: true,
      sorted: false,
      maxWidth: 100,
    },
    {
      id: "kontrahent",
      label: "Kontrahent",
      query: true,
      sorted: false,
      minWidth: 100,
    },
    {
      id: "cardNo",
      label: "Nr karty",
      query: true,
      sorted: false,
      maxWidth: 140,
    },
    {
      id: "check",
      label: "",
      query: false,
      maxWidth: 150,
    },
    {
      id: "details",
      label: "",
      query: false,
    },
  ];

  const Statements = () => (
    <StatementsTable columns={columns} entity="dyspozytor" />
  );

  return (
    <AppRoute
      app="administracja"
      window="dokumenty"
      tab="zestawienia"
      component={Statements}
    />
  );
};

