import React, {Fragment} from "react";
import KilometrowkiRoute from "./kilometrowki/KilometrowkiRoute";

const PracownikRoute = () => {
    return (
        <Fragment>
            <KilometrowkiRoute/>
        </Fragment>
    );
};

export default PracownikRoute;