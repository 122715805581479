import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

const breadcrumbNameMap = {
    '/app': undefined,
    '/app/dyspozytor': 'Dyspozytor',
    '/app/dyspozytor/transport': 'Transport',
    '/app/dyspozytor/transport/dodaj': 'Złóż zlecenie',
    '/app/dyspozytor/transport/edytuj': 'Edytuj zlecenie',
    '/app/dyspozytor/transport/aktywne': 'Aktywne kursy',
    '/app/dyspozytor/transport/archiwum': 'Zakończone kursy',
    '/app/dyspozytor/pracownik': 'Pracownicy',
    '/app/dyspozytor/pracownik/dodaj': 'Dodaj pracownika',
    '/app/dyspozytor/pracownik/pokaz': 'Lista pracowników',
    '/app/dyspozytor/pracownik/edytuj': 'Edytuj pracownika',
    '/app/dyspozytor/pracownik/dokumenty': 'Dokumenty okresowe',
    '/app/dyspozytor/kilometrowki': 'Kilometrówki',
    '/app/dyspozytor/kilometrowki/formularz': 'Wpisz kilometrówkę',
    '/app/dyspozytor/kilometrowki/zestawienia': 'Zestawienia',
    '/app/pracownik': 'Pracownik',
    '/app/pracownik/kilometrowki': 'Kilometrówki',
    '/app/pracownik/kilometrowki/formularz': 'Wpisz kilometrówkę',
    '/app/pracownik/kilometrowki/zestawienia': 'Zestawienia',
    '/app/administracja': 'Administracja',
    '/app/administracja/rozliczenia/rozliczenia': 'Rozliczenia',
    '/app/administracja/dokumenty/kartakontrahenta': 'Karta kontrahenta',
    '/app/administracja/dokumenty/listakontrahentow': 'Lista kontrahentów',
    '/app/operator': 'Operator',
    '/app/operator/trasy': 'Kursy',
    '/app/operator/trasy/dopotwierdzenia': 'Obsadź kurs',
    '/app/operator/trasy/aktywne': 'Aktywne kursy',
    '/app/operator/trasy/archiwum': 'Zakończone kursy',
    '/app/operator/transport': 'Transport',
    '/app/operator/transport/edytuj': 'Edytuj zlecenie',
    '/app/operator/kierowcy': 'Kierowcy',
    '/app/operator/kierowcy/dodaj': 'Dodaj kierowcę',
    '/app/operator/kierowcy/pokaz': 'Lista kierowców',
    '/app/operator/kierowcy/kierowcy': 'Grafik kierowców',
    '/app/operator/grafik': 'Grafik',
    '/app/operator/grafik/dniWolne': 'Dni wolne',
    '/app/kierowca': 'Kierowca',
    '/app/kierowca/grafik': 'Grafik',
    '/app/kierowca/grafik/dniWolne': 'Dni wolne',
    '/app/kierowca/trasy': 'Kursy',
    '/app/kierowca/trasy/aktywne': 'Aktywne kursy',
    '/app/kierowca/trasy/archiwum': 'Zakończone kursy',
    '/app/user': 'Użytkownik',
    '/app/user/account': 'Informacje',
    '/app/user/account/profile': 'Profil',
};

export default function ({classes}) {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter(x => x);
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {pathnames.map((value, index) => {

                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                    return breadcrumbNameMap[to] !== undefined &&
                        <Typography className={classes.breadcrumbs} color="textPrimary" key={to}>
                            {breadcrumbNameMap[to]}
                        </Typography>
                    // );
                })}
            </Breadcrumbs>
        </div>
    );
}
