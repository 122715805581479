import React from "react";
import { LinearProgress } from "@material-ui/core";
import styles from "./progressLine.module.scss";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            marginLeft: 0,
            padding: 0,
        },
        backgroundColor: "#1dbbed",
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#d4d4d4"
        }
    },
}));

export default function ProgressLine() {
    const classes = useStyles();

    return (
    <div className={styles["progressLine__description"]}>
        <p>Pobieranie danych</p>
      <LinearProgress className={classes.root} />
    </div>
  );
}
