import React, { Fragment } from "react";
import DniWolneRoute from "./dniWolne/DniWolneRoute";
import ActiveRoute from "./trasy/aktywne/ActiveRoute";
import ArchiveRoute from "./trasy/archiwum/ArchiveRoute";
const KierowcaRoute = () => {
  return (
    <Fragment>
      <ActiveRoute />
      <ArchiveRoute />
      <DniWolneRoute />
    </Fragment>
  );
};

export default KierowcaRoute;
