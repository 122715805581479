import axios, {AxiosRequestConfig, AxiosError, AxiosResponse} from 'axios';
import Cookie from 'js-cookie';
import axiosConfig from './config';

/**
 * @param {string} url
 * @return {string|*}
 */
const transformURL = (url) => {
    if(url.substring(0, 4) === 'http') {
        return url;
    } else {
        if(url.charAt(0) === '/') url = url.substring(1);
    }
    return axiosConfig.apiURL + '' + url;
}

const createOptions = (config, isLogged) => {
    let options = {
        ...config,
    }

    if(isLogged) {
        const token = Cookie.get('token')
        if(!token) {
            throw new Error('Token is missing');
        }
        options = {
            ...options,
            headers: { Authorization: `Bearer ${token}` }
        }
    }
    return options;
}

/**
 * @param {HttpRequest} httpRequest
 */
const httpFlashNotification = (httpRequest) => {
    const response = httpRequest.response || httpRequest.error?.response;
    const flashes = response?.data?.flashes;
    if(flashes) flashes.forEach(flash => HttpClient.addFlash(flash.message, {variant: flash.severity}));
}

/**
 * @param {HttpRequest} httpRequest
 */
const handleError = (httpRequest) => {
    if (httpRequest.error.response) {
        const INVALID = httpRequest.error?.response?.data?.message === 'Invalid JWT Token';
        const EXPIRED = httpRequest.error?.response?.data?.message === 'Expired JWT Token';
        const status = httpRequest.error?.response.status;
        // show flash message when you've been logged out automatically
        if(httpRequest.isLogged && status === 401 && (INVALID || EXPIRED)) {
           if(EXPIRED) HttpClient.addFlash('Zostałeś automatycznie wylogowany', {variant: 'info', preventDuplicate: true, key: 'logoutInfo'});
           window.socketConnection = null;
            httpRequest.logout();

        }
        // show flash message when server returns status within range of 500
        if(status >= 500 && status <= 511) {
            HttpClient.addFlash('Wystąpił wewnętrzny błąd serwera', {variant: 'error', preventDuplicate: true, key: 'internalServerError'});
        }
    } else if (httpRequest.error.request) {
        HttpClient.connectionError();
    }
}

/**
 * @description HttpClient is responsible for proper requests processing serves as a container for axios
 */
export default class HttpClient {
    static connectionError;
    static httpNotification;
    static history;
    static setAuthState;
    static addFlash;
    static setRoles;
    static setOldToken;

    static overrideConnectionError(connectionError) {
        HttpClient.connectionError = connectionError;
    }

    static overrideHttpNotification(httpNotification) {
        HttpClient.httpNotification = httpNotification;
    }

    static overrideHistory(history) {
        HttpClient.history = history;
    }

    static overrideSetAuthState(setAuthState) {
        HttpClient.setAuthState = setAuthState;
    }

    static overrideAddFlash(addFlash) {
        HttpClient.addFlash = addFlash;
    }

    static overrideSetRoles(setRoles) {
        HttpClient.setRoles = setRoles;
    }

    static overrideSetOldToken(setOldToken) {
        HttpClient.setOldToken = setOldToken;
    }

    static createRequest() {
        return new HttpRequest(HttpClient.connectionError, HttpClient.history, HttpClient.setAuthState, HttpClient.addFlash, HttpClient.httpNotification, HttpClient.setRoles, HttpClient.setOldToken);
    }

}

/**
 * @description HttpRequest creates request and automatically catch error. Request's config is saved as properties of class
 */
class HttpRequest {

    /**
     * @var {AxiosResponse<any> | null} response
     */
    response;

    /**
     * @var {AxiosError | null} error
     */
    error;

    /**
     * @var {string | null} url
     */
    url;

    /**
     * @var {AxiosRequestConfig | null} config
     */
    config;

    /**
     * @var {object | null} data
     */
    data;

    /**
     * @var {boolean} isLogged
     */
    isLogged;

    /**
     * @description Shows a message if the connection cannot be established
     * @returns void
     */
    connectionError(){};

    /**
     * @description Downloads new notification
     * @returns void
     */
    httpNotification(){};

    history;

    setAuthState(){}
    addFlash(){}
    setRoles(){}
    setOldToken(){}

    constructor(connectionError, history, setAuthState, addFlash, httpNotification, setRoles, setOldToken) {
        this.connectionError = connectionError;
        this.history = history;
        this.setAuthState = setAuthState;
        this.addFlash = addFlash;
        this.setRoles = setRoles;
        this.setOldToken = setOldToken;
        // this.httpNotification = httpNotification;
    }

    /**
     * @param {string} url Should not contain "/" as a first character, you can also use full path like "http://example"
     * @param {AxiosRequestConfig?} config
     * @param {boolean} isLogged Use this parameter if your request requires a token, the token will be automatically added. If token is not present inside localStorage, it will throw error
     * @param {boolean} showNotification
     * @param {boolean} shouldHandleError
     * @returns {Promise<AxiosResponse<any>}
     */
    get(url, config, isLogged = false, showNotification = true, shouldHandleError = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(transformURL(this.url = url), {...createOptions(this.config = config, this.isLogged = isLogged)});
                resolve(this.response = response);
            } catch (error) {
                this.error = error;
                if(shouldHandleError) handleError(this);
                reject(this.error);
            } finally {
                if(showNotification) httpFlashNotification(this);
            }
        })
    }

    /**
     * @param {string} url Should not contain "/" as a first character, you can also use full path like "http://example"
     * @param {object} data Data to send
     * @param {AxiosRequestConfig?} config
     * @param {boolean} isLogged Use this parameter if your request requires a token, the token will be automatically added. If token is not present inside localStorage, it will throw error
     * @param {boolean} showNotification
     * @param {boolean} shouldHandleError
     * @returns {Promise<AxiosResponse<any>}
     */
    post(url, data, config, isLogged = false, showNotification = true, shouldHandleError = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(transformURL(this.url = url), this.data = data, {...createOptions(this.config = config, this.isLogged = isLogged)})
                resolve(this.response = response);
            } catch (error) {
                this.error = error;
                if(shouldHandleError) handleError(this);
                reject(this.error);
            } finally {
                if(showNotification) httpFlashNotification(this);
            }
        })
    }

    /**
     * @param {string} url Should not contain "/" as a first character, you can also use full path like "http://example"
     * @param {object} data Data to send
     * @param {AxiosRequestConfig?} config
     * @param {boolean} isLogged Use this parameter if your request requires a token, the token will be automatically added. If token is not present inside localStorage, it will throw error
     * @param {boolean} showNotification
     * @param {boolean} shouldHandleError
     * @returns {Promise<AxiosResponse<any>}
     */
    put(url, data, config, isLogged = false, showNotification = true, shouldHandleError = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.put(transformURL(this.url = url), this.data = data, {...createOptions(this.config = config, this.isLogged = isLogged)})
                resolve(this.response = response);
            } catch (error) {
                this.error = error;
                if(shouldHandleError) handleError(this);
                reject(this.error);
            } finally {
                if(showNotification) httpFlashNotification(this);
            }
        })
    }

    /**
     * @param {string} url Should not contain "/" as a first character, you can also use full path like "http://example"
     * @param {AxiosRequestConfig?} config
     * @param {boolean} isLogged Use this parameter if your request requires a token, the token will be automatically added. If token is not present inside localStorage, it will throw error
     * @param {boolean} showNotification
     * @param {boolean} shouldHandleError
     * @returns {Promise<AxiosResponse<any>}
     */
    delete(url, config, isLogged = false, showNotification = true, shouldHandleError = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.delete(transformURL(this.url = url), {...createOptions(this.config = config, this.isLogged = isLogged)});
                resolve(this.response = response);
            } catch (error) {
                this.error = error;
                if(shouldHandleError) handleError(this);
                reject(this.error);
            } finally {
                if(showNotification) httpFlashNotification(this);
            }
        })
    }

    /**
     * @param {string} method
     * @param {object} data Data to send
     * @param {string} url Should not contain "/" as a first character, you can also use full path like "http://example"
     * @param {AxiosRequestConfig?} config
     * @param {boolean} isLogged Use this parameter if your request requires a token, the token will be automatically added. If token is not present inside localStorage, it will throw error
     * @param {boolean} showNotification
     * @param {boolean} shouldHandleError
     * @returns {Promise<AxiosResponse<any>}
     */
    builder(method, url, data,  config, isLogged = false, showNotification = true, shouldHandleError = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({method, data, url: transformURL(this.url = url), ...createOptions(this.config = config, this.isLogged = isLogged)});
                resolve(this.response = response);
            } catch (error) {
                this.error = error;
                if(shouldHandleError) handleError(this);
                reject(this.error);
            } finally {
                if(showNotification) httpFlashNotification(this);
            }
        })
    }

    /**
     * @description Returns a promise, if promise is fulfilled it means server returns an error otherwise it means that nothing was returned, possible connection error
     * @returns {Promise<unknown>}
     */
    handleCustomError() {
        return new Promise((resolve, reject) => {
            if (this.error.response) {
                resolve(this.error.response);
            } else if (this.error.request) {
                reject(this.error.request)
            }
        });
    }

    /**
     * @description Clear localStorage and redirect user to login page
     */
    logout() {
        Cookie.remove('token');
        Cookie.remove('loggedUser');
        this.setRoles([]);
        this.setOldToken(null);
        this.setAuthState(false);
    }
}
