import React from "react";
import AppRoute from "../../../AppRoute";
import { AddDelegation } from "./AddDelegation";


export const AddDelegationRoute = () => {
  return (
    <>
      <AppRoute
        app="administracja"
        window="dokumenty"
        tab="dodaj-delegacje"
        component={AddDelegation}
      />
    </>
  );
};
