import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { fromUnixTime } from "date-fns";
import config from "../../../../config";
import HttpClient from "../../../../HttpClient";

const SettlementsPanelDetails = (props) => {
    const { driver, realDriver, from, to, worker, status, cancel } = props.order.courseDetails;

    const shouldDisplayChangeDriverButton = cancel === false;
    const shouldDisplayCourseConfirmButton =
        (cancel === false && driver && status === "frozen") ||
        (cancel === false && driver && status === "pending") ||
        (cancel === false && status === "deny");

    const getRequestDates = () => {
        const destinationDate = fromUnixTime(props.order.courseDetails.destinationTime);
        const startDate = new Date(destinationDate.getTime() - 1000 * 60 * 60 * 24).toJSON();
        const formattedStartDate = startDate.slice(0, startDate.length - 2);
        const endDate = new Date(destinationDate.getTime() + 1000 * 60 * 60 * 24).toJSON();
        const formattedEndDate = endDate.slice(0, startDate.length - 2);

        return { startDate: formattedStartDate, endDate: formattedEndDate };
    };

    const getRealWaypoints = (stages) => {
        const { startDate, endDate } = getRequestDates();

        const fetchWaypointsPromises = stages.map((stage) => {
            const requestUrl = `${config.gpsBaseURL}/positions/by_stage?order_id=${props.order.courseDetails.id}&stage_no=${stage}&start=${startDate}&end=${endDate}`;
            const httpRequest = HttpClient.createRequest();

            return httpRequest.get(requestUrl, {}, true, false, false);
        });

        Promise.all(fetchWaypointsPromises).then((responses) => {
            let waypointNodes = [];

            for (const response of responses) {
                const newWaypointNodes = response.data.nodes;

                waypointNodes = [...waypointNodes, ...newWaypointNodes];
            }

            waypointNodes.sort((a, b) => new Date(b.time) - new Date(a.time));

            const waypointCoordinates = waypointNodes.map((node) => [node.lon, node.lat]);
            props.handleUpdateWaypoints(waypointCoordinates);
        });
    };

    useEffect(() => {
        const { startDate, endDate } = getRequestDates();

        const requestUrl = `${config.gpsBaseURL}/order/${props.order.courseDetails.id}/info?include_address=true&start=${startDate}&end=${endDate}`;
        const httpRequest = HttpClient.createRequest();

        httpRequest.get(requestUrl, {}, true, false, false).then((response) => {
            if (!response.data?.length) {
                return;
            }

            const waypointStages = response.data.map((item) => item.stage_no);

            getRealWaypoints(waypointStages);
        });
    }, [props.order.courseDetails.id]);

    return (
        <Box style={{ marginTop: 23, minHeight: '650px' }}>
            <div
                style={{
                    position: "relative",
                    margin: "5px 0 10px",
                    padding: "5px",
                    display: "flex",
                    border: "1px solid #d7d7d7",
                    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.07)",
                }}
            >
                <div style={{ width: "70%" }}>
                    <p
                        style={{
                            color: "#47484b",
                            textTransform: "uppercase",
                            fontWeight: 600,
                            marginBottom: 2,
                            marginTop: 2,
                        }}
                    >
                        Kierowca:
                    </p>
                    {driver ? (
                        <>
                            <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                {" "}
                                Kierowca:
                            </p>
                            <p
                                style={{ fontSize: 12, margin: 0, fontWeight: 600 }}
                            >{`${driver.firstname} ${driver.surname}`}</p>
                            <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>Telefon:</p>
                            <p style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                {driver.workPhoneNumber ? driver.workPhoneNumber : "-"}
                            </p>
                            <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                Tablice rejestracyjne:
                            </p>
                            <p style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                {driver.regNo ? driver.regNo : "-"}
                            </p>
                        </>
                    ) : (
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>Nie przypisano kierowcy</div>
                    )}
                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                        Kierowca referencyjny:
                    </p>
                    {realDriver ? (
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>{realDriver}</div>
                    ) : (
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>Nie przypisano kierowcy</div>
                    )}
                </div>
                <div style={{ width: "30%" }}>
                    {/* {shouldDisplayChangeDriverButton && (
                        <Button
                            size="small"
                            variant="outlined"
                            style={{
                                width: "100%",
                                color: "#1DBBED",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderColor: "#1DBBED",
                                borderRadius: "0",
                                transition: "0.3s",
                                marginBottom: "10px",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#00a0e3",
                                },
                            }}
                        >
                            Zmień kierowcę
                        </Button>
                    )}
                    {shouldDisplayChangeDriverButton && (
                        <Button
                            size="small"
                            variant="outlined"
                            style={{
                                width: "100%",
                                color: "#1DBBED",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderColor: "#1DBBED",
                                borderRadius: "0",
                                transition: "0.3s",
                                marginBottom: "10px",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#00a0e3",
                                },
                            }}
                        >
                            Zmień kierowcę referencyjnego
                        </Button>
                    )}
                    {shouldDisplayCourseConfirmButton && status !== "frozen" && (
                        <Button
                            size="small"
                            variant="outlined"
                            style={{
                                width: "100%",
                                color: "#1DBBED",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderColor: "#1DBBED",
                                borderRadius: "0",
                                transition: "0.3s",
                                marginBottom: "10px",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#00a0e3",
                                },
                            }}
                        >
                            Potwierdź kurs
                        </Button>
                    )} */}
                </div>
            </div>
            <div
                style={{
                    position: "relative",
                    margin: "5px 0 10px",
                    padding: "5px",
                    border: "1px solid #d7d7d7",
                    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.07)",
                }}
            >
                <p
                    style={{
                        color: "#47484b",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        marginBottom: 2,
                        marginTop: 2,
                    }}
                >
                    Adres odbioru:
                </p>

                {from ? (
                    <div>
                        <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>Ulica:</p>
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                            {from.street ? from.street : ""} {from.no ? from.no : ""} {from.flat ? `/${from.flat}` : ""}
                        </div>
                        <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>Miasto:</p>
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                            {from.zipCode ? from.zipCode : ""} {from.city ? from.city : ""}
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        {worker.map((worker, index) => {
                            return (
                                <div key={index} style={{ width: "50%" }}>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Imię i nazwisko:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {`${worker.firstname ? worker.firstname : " "} ${
                                            worker.surname ? worker.surname : " "
                                        }`}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Telefon:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.phoneNumber ? worker.phoneNumber : "-"}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Ulica:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.street ? worker.street : ""} {worker.no ? worker.no : ""}{" "}
                                        {worker.flat ? `/${worker.flat}` : ""}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Miasto:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.zipCode ? worker.zipCode : ""} {worker.city ? worker.city : ""}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div
                style={{
                    position: "relative",
                    margin: "5px 0 10px",
                    padding: "5px",
                    border: "1px solid #d7d7d7",
                    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.07)",
                }}
            >
                <p
                    style={{
                        color: "#47484b",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        marginBottom: 2,
                        marginTop: 2,
                    }}
                >
                    Adres docelowy:
                </p>

                {to ? (
                    <div>
                        <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>Ulica:</p>
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                            {to.street ? to.street : ""} {to.no ? to.no : ""} {to.flat ? `/${to.flat}` : ""}
                        </div>
                        <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>Miasto:</p>
                        <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                            {to.zipCode ? to.zipCode : ""} {to.city ? to.city : ""}
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        {worker.map((worker, index) => {
                            return (
                                <div key={index} style={{ width: "50%" }}>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Imię i nazwisko:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {`${worker.firstname ? worker.firstname : " "} ${
                                            worker.surname ? worker.surname : " "
                                        }`}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Telefon:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.phoneNumber ? worker.phoneNumber : "-"}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Ulica:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.street ? worker.street : ""} {worker.no ? worker.no : ""}{" "}
                                        {worker.flat ? `/${worker.flat}` : ""}
                                    </div>
                                    <p style={{ fontWeight: 600, color: "#1dbbed", marginBottom: 2, marginTop: 7 }}>
                                        Miasto:
                                    </p>
                                    <div style={{ fontSize: 12, margin: 0, fontWeight: 600 }}>
                                        {worker.zipCode ? worker.zipCode : ""} {worker.city ? worker.city : ""}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </Box>
    );
};

export default SettlementsPanelDetails;
