import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./app/AppRouter";
import {MimicContext, NotificationContext} from "./context";
import {SnackbarProvider, useSnackbar, OptionsObject} from "notistack";
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import HttpClient from "./HttpClient";
import {usePersistedState} from "./usePersistedState";
import SocketConnection from "./socketIo";
import config from "./config";
import './App.css';

const AppInit = () => {
    const [oldToken, setOldToken] = usePersistedState('oldToken', null);
    const [roles, setRoles] = usePersistedState('roles', []);
    const [loggedUser, setLoggedUser] = usePersistedState('loggedUser', null);
    const {enqueueSnackbar} = useSnackbar();
    const isRoleType = (roleName) => {
        switch (roleName) {
            case 'OPERATOR':
                return roles.includes('ROLE_OPERATOR') && !roles.includes('ROLE_OPERATOR_CONTRACTOR')
            case 'DYSPOZYTOR':
                return roles.includes('ROLE_DYSPOZYTOR') && !roles.includes('ROLE_OPERATOR_CONTRACTOR')
            case 'OPERATOR_CONTRACTOR':
                return roles.includes('ROLE_OPERATOR_CONTRACTOR')
            case 'ADMINISTRACJA':
                return roles.includes('ROLE_ADMINISTRACJA')
            case 'ADMINISTRATOR':
                return roles.includes('ROLE_ADMINISTRATOR')
            case 'KIEROWCA':
                return roles.includes('ROLE_KIEROWCA')
            case 'OPERATOR_PARTNER':
                return roles.includes('ROLE_OPERATOR_PARTNER')
            default:
                return false;
        }
    }
    const userDispatch = () => {
        return loggedUser?.companyId
    }

    /**
     * @param {string} message
     * @param {OptionsObject?} options
     */
    const addFlash = (message, options) => {
        return enqueueSnackbar(message, options);
    }

    const setNotification = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('notifications', {}, true, false);
            const {flashes} = response.data;
            flashes.forEach(flash => addFlash(flash.message, {variant: flash.severity}));
        } catch (e) {
        }
    }

    if (typeof window.socketConnection === 'undefined' || !window.socketConnection) {
        window.socketConnection = new SocketConnection(config.socket_io_url + ':' + config.socket_io_port);
    }


    HttpClient.overrideConnectionError(() => addFlash('Serwer nie odpowiada, może to oznaczać problem z serwerem lub twoje połączenie internetowe nie pozwala na wykonanie żądania', {
        variant: 'error',
        preventDuplicate: true,
        key: 'connectionError'
    }));
    HttpClient.overrideAddFlash(addFlash);
    HttpClient.overrideSetRoles(setRoles);
    HttpClient.overrideSetOldToken(setOldToken);
    // HttpClient.overrideHttpNotification(() => setNotification());

    return (
        <Router>
            <NotificationContext.Provider
                value={{
                    setNotification,
                    addFlash,
                }}
            >
                <MimicContext.Provider value={{
                    oldToken,
                    setOldToken,
                    roles,
                    setRoles,
                    loggedUser,
                    setLoggedUser,
                    addFlash,
                    isRoleType,
                    userDispatch
                }}
                >
                    <AppRouter />
                </MimicContext.Provider>
            </NotificationContext.Provider>
        </Router>
    );
};

const App = () => {
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            ref={notistackRef}
            dense
            autoHideDuration={5000}
            action={(key) => (
                <IconButton size="small" onClick={onClickDismiss(key)}>
                    <Close fontSize="small" style={{color: 'white'}} />
                </IconButton>
            )}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            maxSnack={3}
        >
            <AppInit />
        </SnackbarProvider>
    )
}

export default App;
