import { styled, Button, IconButton, lighten } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const pallet = {
    main: "#1DBBED",
    mainDark: "#00a0e3",
    mainLight: "#9adaed",
    gray: "#B4B4B4",
    darkGray: "#777777",
    fontPrimary: "#47484B",
    fontDark: "#575B55",
    fontSecondary: "#fff",
    fontSmall: "0.75rem",
    fontSize: "1.2rem",
    warning: "#EE2A2A",
    boxShadow: "2px 2px 2px rgb(0 0 0 / 7%)",
};

const ButtonCore = styled(Button)((props) => ({
    backgroundColor: props.bg_color,
    color: pallet.fontSecondary,
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    padding: ".5rem 1rem",
    fontSize: pallet.fontSize,
    boxShadow: pallet.boxShadow,
    width: props.width ? props.width : null,
    "&:hover": {
        backgroundColor: props.bg_hover_color,
    },
    "&:disabled": {
        cursor: "not-allowed",
        color: pallet.fontPrimary,
        opacity: 0.9,
    },
    "& .MuiSvgIcon-root": {
        fontSize: "2rem",
    },
}));

const ButtonIconThemed = styled(IconButton)((props) => ({
    padding: ".25rem",
    background: pallet.main,
    color: pallet.fontSecondary,
    "&:hover": {
        backgroundColor: pallet.mainDark,
    },
    "&:disabled": {
        backgroundColor: pallet.mainLight,
        color: pallet.fontSecondary,
    },
}));

const ButtonIcon = styled(IconButton)((props) => ({
    padding: ".25rem",
    background: props.clear_bg ? "" : pallet.gray,
    color: props.clear_bg ? pallet.fontPrimary : pallet.fontSecondary,
    "&:hover": {
        backgroundColor: props.clear_bg ? pallet.gray : pallet.darkGray,
    },
    "&:disabled": {
        backgroundColor: lighten(pallet.gray, 0.5),
        color: pallet.fontSecondary,
    },
}));

const CloseMapButton = styled("button")({
    position: "absolute",
    border: "none",
    background: "transparent",
    right: "calc(15px + 0.5rem)",
    top: "calc(15px + 0.5rem)",
    zIndex: "5",
    cursor: "pointer",
    fontSize: "2rem",
});

const ToggleAddress = styled("button")({
    color: pallet.fontPrimary,
    fontSize: pallet.fontSmall,
    textDecoration: "underline",
    textAlign: "left",
    border: "none",
    position: "absolute",
    background: "transparent",
    top: "calc(100% + 0.2rem)",
    zIndex: "3",
    padding: "0",
});

const ButtonText = styled("span")({
    color: pallet.fontPrimary,
    fontSize: pallet.fontSmall,
});

const OrderTitle = styled("h3")({
    fontSize: "1.3rem",
    color: pallet.main,
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: ".5rem 0",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
});

const FlexRow = styled("div")((props) => ({
    display: "flex",
    margin: props.margin ? props.margin : null,
    flexFlow: props.flow ? props.flow : "row",
    gap: props.gap ? props.gap : ".2rem",
    alignItems: props.align ? props.align : "center",
    justifyContent: props.justify ? props.justify : "flex-start",
    flexDirection: props.direction ? props.direction : "row",
    position: "relative",

    '& .MuiAutocomplete-input': {
        maxWidth: 'calc(100% - 55px)',
        textOverflow: 'ellipsis',
    }
}));

const WorkersWrapper = styled("div")({
    boxSizing: "border-box",
    padding: ".5rem",
    marginBottom: ".5rem",
    gap: "1.25rem",
    display: "flex",
    flexFlow: "column",
    minHeight: "140px",
    minWidth: "200px",
    position: "relative",
    justifyContent: "space-between",
    border: `1px solid ${pallet.gray}`,
    boxShadow: pallet.boxShadow,
});

const FieldWrapper = styled("div")({
    border: `1px solid ${pallet.gray}`,
    boxShadow: pallet.boxShadow,
    padding: ".5rem",
    height: "100%",
});

const OrderWrapper = styled("section")({
    background: "#fff",
    border: `1px solid ${pallet.gray}`,
    borderLeft: `2px solid ${pallet.main}`,
    marginBottom: "1rem",
    padding: ".5rem 1rem",
    boxSizing: "border-box",
    maxWidth: "1045px",
    minWidth: "888px",
});

const DateWrapper = styled("div")({
    maxWidth: "245px",
});

const ContractorWrapper = styled("div")({
    minWidth: "170px",
    paddingLeft: "8px",
});

const MapWrapper = styled("div")({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "87vh",
    padding: 15,
});

const SummaryMapWrapper = styled("div")({
    height: "300px",
    width: "100%",
    gridColumn: "1/11",
});

const SummaryWrapper = styled("section")({
    background: "#fff",
    border: `1px solid ${pallet.gray}`,
    marginBottom: "1rem",
    padding: ".5rem 1rem",
    boxSizing: "border-box",
    alignSelf: "flex-start",
    minWidth: "600px",
    maxWidth: "650px",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    gap: "1rem",
    color: pallet.fontPrimary,
    alignItems: "center",
    "& .progress-bar": {
        gridColumn: "1/-1",
        width: "100%",
        backgroundColor: "#1dbbed",
        "& > * + *": {
            marginTop: "1rem",
            marginLeft: 0,
            padding: 0,
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#d4d4d4",
        },
    },

    "& .summary-location": {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        minHeight: "40px",

        "&:first-child": {
            minWidth: "10px",
        },
    },

    "& .summary-location-circle-bottom": {
        position: "relative",
        "&::after": {
            content: '""',
            position: "absolute",
            background: pallet.fontDark,
            bottom: "-1rem",
            left: "30%",
            width: "5px",
            height: "5px",
            borderRadius: "100rem",
        },
    },
    "& .summary-location-circle-top": {
        position: "relative",
        "&::before": {
            content: '""',
            position: "absolute",
            background: pallet.fontDark,
            top: "-1rem",
            left: "30%",
            width: "5px",
            height: "5px",
            borderRadius: "100rem",
        },
    },
});

const SummaryName = styled("p")({
    display: "flex",
    flexFlow: "column",
    margin: 0,

    "& .summary-street": {
        fontSize: "0.7rem",
    },
});

const SummaryPosition = styled("span")({
    minWidth: "16px",
});
const IconSummary = styled("div")({
    color: pallet.fontDark,
    "& img": {
        height: "1.5rem",
        minWidth: "2rem",
    },
});

const IconCircles = styled("div")({
    width: "10px",
    height: "10px",
    background: pallet.fontDark,
    borderRadius: "100rem",
});

const GridColumnElement = styled("div")((props) => ({
    gridColumn: props.column,
    textAlign: props.text_align ? props.text_align : "left",
    maxHeight: props.max_height ? props.max_height : "auto",
    whiteSpace: "nowrap",
}));

const LinkIconSpan = styled("span")({
    fontSize: pallet.fontSize,
    display: "flex",
    marginBottom: ".25rem",
    alignItems: "center",
    "& svg ": {
        fontSize: "3rem",
        color: pallet.gray,
    },
});

const BoldText = styled("p")({
    fontWeight: "bold",
    color: pallet.fontPrimary,
    fontSize: pallet.fontSmall,
});

const WarningText = styled("span")((props) => ({
    color: pallet.warning,
    marginTop: props.marginTop ? props.marginTop : null,
}));

const SimpleScroll = styled(SimpleBar)({
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
        {
            backgroundColor: "#b4b4b4",
        },
});

const Loader = styled("img")({
    width: "80%",
    height: "80%",
});

export {
    Loader,
    DateWrapper,
    ContractorWrapper,
    SummaryPosition,
    SimpleScroll,
    LinkIconSpan,
    SummaryName,
    IconSummary,
    BoldText,
    SummaryWrapper,
    IconCircles,
    WarningText,
    WorkersWrapper,
    CloseMapButton,
    FlexRow,
    ToggleAddress,
    MapWrapper,
    OrderTitle,
    SummaryMapWrapper,
    GridColumnElement,
    ButtonText,
    ButtonCore,
    FieldWrapper,
    ButtonIconThemed,
    ButtonIcon,
    OrderWrapper,
    pallet,
};
