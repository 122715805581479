import React from "react";

import { format } from "date-fns";

import HomeIcon from "img/dom-ciemny-s.svg";
import DirectionsTransitIcon from "img/lokomotywa-ciemny-s.svg";
import { className, isTimesDifferent, addressType, formatDistance } from "app/reusableComponents/newOrderPanel/helpers";
import { IconSummary, GridColumnElement, WarningText, IconCircles, SummaryName, SummaryPosition } from "app/reusableComponents/newOrderPanel/styled";

export const SummaryOrderRoute = ({ location, isFirst, isLast, positionNumber }) => {
    return (
        <>
            <GridColumnElement max_height="40px" column="1/7" className="summary-location">
                <SummaryPosition>{positionNumber}</SummaryPosition>
                <IconCircles className={className(isFirst, isLast)} />

                <IconSummary>
                    <img
                        src={location.type === addressType.WORK ? DirectionsTransitIcon : HomeIcon}
                        alt="location type icon"
                    />
                </IconSummary>

                <SummaryName>
                    <span> {location.name}</span>
                    {location.street && <span className="summary-street">{location.street}</span>}
                </SummaryName>
            </GridColumnElement>
            <GridColumnElement text_align="center" column="7/8">
                {location.startEstimatedDate ? (
                    <strong>{format(location.startEstimatedDate, "HH:mm")}</strong>
                ) : (
                    "--:--"
                )}
            </GridColumnElement>
            <GridColumnElement text_align="center" column="8/9">
                {isTimesDifferent(location) ? (
                    <WarningText>
                        <strong>{location.startOrderedDate && format(location.startOrderedDate, "HH:mm")}</strong>
                    </WarningText>
                ) : (
                    <> {location.startOrderedDate && format(location.startOrderedDate, "HH:mm")}</>
                )}
            </GridColumnElement>
            <GridColumnElement text_align="center" column="9/10">
                {" "}
                -{" "}
            </GridColumnElement>
            <GridColumnElement text_align="center" column="10/11">
                {formatDistance(location.distance)}
            </GridColumnElement>
        </>
    );
};