import React, {Fragment} from 'react';
import AppRoute from "../../../../AppRoute";
import AddAddress from "./AddAddress";

const AddAddressRoute = () => {
    return (
        <Fragment>
            <AppRoute
                app="operator"
                window="adresy"
                tab="dodaj"
                component={AddAddress}
            />
        </Fragment>
    );
}

export default AddAddressRoute;