/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./moreDetails.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import { format, fromUnixTime } from "date-fns";
import { pl } from "date-fns/locale";
import CourseMap from "./courseMap/CourseMap";
import SingleDriverSchedule from "../../singleDriverSchedule/SingleDriverSchedule";
import ChangeRealDriverPanel from "../../changeRealDriverPanel/ChangeRealDriverPanel";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PaidCourse from "./paidCourse/PaidCourse";
import HttpClient from "../../../../HttpClient";
import config from "../../../../config";
import _ from "lodash";
import { addressType, entityType, getAddressesCords } from "app/reusableComponents/newOrderPanel/helpers";
import { OrderMap } from "app/reusableComponents/newOrderPanel/map";
import { useLocation } from "react-router-dom";
import scss from "../../../settlements/panel/css/settlementsPanel.module.scss";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        width: "85vw",
        height: "88vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
    changeDriverButton: {
        color: "#1DBBED",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#1DBBED",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "10px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#00a0e3",
        },
    },
    timeRoot: {
        padding: 0,
        margin: 0,
        "& .MuiTimelineItem-root": {
            minHeight: "40px",
        },

        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },

    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "#1DBBED",
        padding: "3px",
    },
    timeConnector: {
        backgroundColor: "#b4b4b4",
    },
    timeContent: {
        padding: "0 15px",
        margin: "-7px 0 10px",
    },
}));
export default function MoreDetails(props) {
    const classes = useStyles();
    const { courseDetails, entity } = props;
    const { pathname } = useLocation()
    const [showSingleSchedule, setShowSingleSchedule] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [changeDriverPanel, setChangeDriverPanel] = useState(false);
    const [changeRealDriverPanel, setChangeRealDriverPanel] = useState(false);
    const [open, setOpen] = useState(true);
    const [totalTime, setTotalTime] = useState(null);
    const [totalKm, setTotalKm] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isPostedData, setIsPostedData] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [paidCoursePanel, setPaidCoursePanel] = useState(false);

    const [isLeadTimeFetching, setIsLeadTimeFetching] = useState(false);
    const [isLeadTimeFetchingError, setIsLeadTimeFetchingError] = useState(false);
    const [realStartDate, setRealStartDate] = useState(null);
    const [realEndDate, setRealEndDate] = useState(null);
    const [realTotalKm, setRealTotalKm] = useState(0);

    const [realWaypoints, setRealWaypoints] = useState([]);

    const [routeData, setRouteData] = useState([])
    const [routeWaypoints, setRouteWaypoints] = useState([])

    const getAllContractors = () => {
        const httpRequest = HttpClient.createRequest();

        return httpRequest.get("api/allKontrahents", {}, true, false, false).then((response) => response.data);
    };

    const getContractorSettings = async () => {
        const allContractors = await getAllContractors();

        const foundContractor = allContractors.find((contractor) => contractor.name === courseDetails.kontrahent.name);

        return foundContractor.contract;
    };

    useEffect(() => {
        getContractorSettings();
        handleFetchRouteDate()
    }, []);

    const handleFetchRouteDate = async () => {
        const httpRequest = HttpClient.createRequest();
        // const routeAddresses = courseDetails.from ? [courseDetails.from] : [courseDetails.to]

        // const formattedWorkers = courseDetails.worker.reduce((acc, worker) =>  {
        //     const newWorker = worker.adres.find((adres) => adres.id === worker.addressId)

        //     if(newWorker) {
        //         return [...acc, {
        //             id: worker.id,
        //             type: newWorker.type,
        //             lon: newWorker.lon,
        //             lat: newWorker.lat,
        //         }]
        //     }

        //     return [...acc, {
        //         id: worker.id,
        //         type: "STATION",
        //         lon: worker.lon,
        //         lat: worker.lat,
        //     }]
        // }  ,[])
     
        // routeAddresses.push(...formattedWorkers)

        // if(props.entity ===entityType.OPERATOR && courseDetails.driver) {
        //     const driverAddress = {...courseDetails.driver.addresses, type: addressType.HOME}
        // routeAddresses.unshift(driverAddress)
        // routeAddresses.push(driverAddress)
        // }
        // console.log(routeAddresses)
        // const routeAddresses = []

        const reducedPath =  courseDetails.path.reduce((acc, path, index) => {
            const address = path.adres
            if(props.entity === entityType.OPERATOR) {
                return [...acc, address]
            }

            if(courseDetails.driver && (index === 0 || index === courseDetails.path.length - 1)) {
                return acc
            }

            return [...acc, address] 
        }, [])

        const cords = getAddressesCords(reducedPath)
     
        const url = `${config.osrmURL}/route/v1/driving/${cords}?steps=true&geometries=geojson&overview=full`;
        const { data: osmrRoute } = await httpRequest.get(url);

        setRouteData([{
            geometry: osmrRoute.routes[0].geometry,
            id: osmrRoute.routes[0].geometry
        }])

        setRouteWaypoints(reducedPath)
        handleCounters(osmrRoute.routes[0].distance, osmrRoute.routes[0].duration);
    }

    const getFormattedRealDuration = () => {
        if (!realStartDate || !realEndDate) {
            return `--:--`;
        }

        const durationInSeconds = (realEndDate.getTime() - realStartDate.getTime()) / 1000;

        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
        const formattedDuration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

        return formattedDuration;
    };

    const realDuration = getFormattedRealDuration();

    const isLeadTimeVisible =
        !isLeadTimeFetching && !isLeadTimeFetchingError && !!(realStartDate || realEndDate || realTotalKm);

    const getRequestDates = () => {
        const destinationDate = fromUnixTime(props.courseDetails.destinationTime);
        const startDate = new Date(destinationDate.getTime() - 1000 * 60 * 60 * 24).toJSON();
        const formattedStartDate = startDate.slice(0, startDate.length - 2);
        const endDate = new Date(destinationDate.getTime() + 1000 * 60 * 60 * 24).toJSON();
        const formattedEndDate = endDate.slice(0, startDate.length - 2);

        return { startDate: formattedStartDate, endDate: formattedEndDate };
    };

    const getRealWaypoints = (stages) => {
        const { startDate, endDate } = getRequestDates();

        const fetchWaypointsPromises = stages.map((stage) => {
            const requestUrl = `${config.gpsBaseURL}/positions/by_stage?order_id=${props.courseDetails.id}&stage_no=${stage}&start=${startDate}&end=${endDate}`;
            const httpRequest = HttpClient.createRequest();

            return httpRequest.get(requestUrl, {}, true, false, false);
        });

        Promise.all(fetchWaypointsPromises).then((responses) => {
            let waypointNodes = [];
            for (const response of responses) {
                const newWaypointNodes = response.data.nodes;

                waypointNodes = [...waypointNodes, ...newWaypointNodes];
            }

            waypointNodes.sort((a, b) => new Date(b.time) - new Date(a.time));

            const waypointCoordinates = waypointNodes.map((node) => [node.lon, node.lat]);

            setRealWaypoints(waypointCoordinates);
        });
    };

    useEffect(() => {
        setIsLeadTimeFetching(true);

        const { startDate, endDate } = getRequestDates();

        const requestUrl = `${config.gpsBaseURL}/order/${props.courseDetails.id}/info?include_address=true&start=${startDate}&end=${endDate}`;
        const httpRequest = HttpClient.createRequest();
        httpRequest
            .get(requestUrl, {}, true, false, false)
            .then((response) => {
                if (!response.data?.length) {
                    setIsLeadTimeFetching(false);
                    return;
                }

                const numberOfWaypoints = response.data.length;

                const firstWaypointData = response.data[0];

                const lastWaypointIndex = numberOfWaypoints - 1;
                const lastWaypointData = response.data[lastWaypointIndex];

                let totalMeters = 0;

                response.data.forEach((waypoint) => {
                    totalMeters += waypoint.length;
                });

                const totalKm = totalMeters / 1000;
                setRealTotalKm(totalKm);

                const startDate = firstWaypointData ? new Date(firstWaypointData.starting_node_info.node.time) : null;
                const endDate = lastWaypointData ? new Date(lastWaypointData.starting_node_info.node.time) : null;

                const waypointStages = response.data.map((item) => item.stage_no);

                getRealWaypoints(waypointStages);

                setRealStartDate(startDate);
                setRealEndDate(endDate);
                setIsLeadTimeFetching(false);
            })
            .catch(() => setIsLeadTimeFetchingError(true));
    }, [props.courseDetails.id]);

    let createDate = courseDetails
        ? format(
              fromUnixTime(
                  courseDetails.createDate < courseDetails.destinationTime
                      ? courseDetails.createDate
                      : courseDetails.destinationTime - 10800
              ),
              "d LLLL yyyy, HH:mm",
              {
                  locale: pl,
              }
          )
        : "";

    const shortId = courseDetails?.shortId;
    const courseHistory = courseDetails?.history;

    const courseDetailsNotes =
        courseDetails && courseHistory && courseHistory.length > 0 && courseHistory[0].cancelNotes
            ? courseHistory[0].cancelNotes
            : courseDetails?.notes
            ? courseDetails?.notes
            : "-";

    const handleClose = () => {
        props.switchShowModal(false);
    };

    const switchRealDriver = (value) => {
        setIsPostedData(value);
        setChangeDriverPanel(false);
        props.updateData(true);
    };

    const handleCounters = (km, time) => {
        const distance = `${Math.round(km / 1000)} km`;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

        if (courseDetails.isDestinationTime) {
            setStartTime(
                format(fromUnixTime(courseDetails.destinationTime - time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
            setEndTime(format(fromUnixTime(courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
        } else {
            setStartTime(format(fromUnixTime(courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
            setEndTime(
                format(fromUnixTime(courseDetails.destinationTime + time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
        }
        setTotalKm(distance);
        setTotalTime(duration);
    };

    const handleShowHistory = async () => {
        const httpRequest = HttpClient.createRequest();
        httpRequest
            .put(
                `api/orders/${courseDetails.id}/set/disable-edit-flag`,
                {
                    disableEdit: true,
                },
                true,
                true,
                true
            )
            .then(() => {
                setIsEdited(false);
                props.onUpdateIsEdited(courseDetails.id);
            });
    };

    const checkIsOrderConnectingsChange = (currentItemHistory, previousItemHistory) => {
        if (!previousItemHistory) {
            return [];
        }

        const isOrderConnectingsChange = !_.isEqual(previousItemHistory.connectedOrders, currentItemHistory.connectedOrders);

        return isOrderConnectingsChange;
    };

    const editedData =
        courseHistory?.length > 1
            ? courseHistory.map((item, index) => {
                  const lastIndex = courseHistory.length - 1;

                  const previousChange = courseHistory[index + 1];

                  //sprawdzenie, które dane się zmieniły:
                  let orderNoEdited = "";
                  let notesEdited = "";
                  let fromEdited = "";
                  let toEdited = "";
                  let workerEdited = "";
                  let timeEdited = "";
                  let canceledOrder = "";
                  let courseEdited = false;
                  let driverEdited;
                  let partnerEdited = ""
                  const isOrderConnectingsChange = checkIsOrderConnectingsChange(item, previousChange);

                  if (courseHistory.length > 1 && index < lastIndex) {
                      canceledOrder = item.cancel || courseHistory[index + 1].cancel ? "--edited" : "";
                      orderNoEdited =
                          (item.orderNo && item.orderNo !== courseHistory[index + 1].orderNo) ||
                          (!item.orderNo && courseHistory[index + 1].orderNo)
                              ? "--edited"
                              : "";
                      notesEdited =
                          (item.notes && item.notes !== courseHistory[index + 1].notes) ||
                          (!item.notes && courseHistory[index + 1].notes)
                              ? "--edited"
                              : "";
                      fromEdited = item.from?.id !== courseHistory[index + 1].from?.id ? "--edited" : "";
                      toEdited = item.to?.id !== courseHistory[index + 1].to?.id ? "--edited" : "";
                      partnerEdited = (item.consortiumMemberName && item.consortiumMemberName !== courseHistory[index + 1].consortiumMemberName ||
                          !item.consortiumMemberName && courseHistory[index + 1].consortiumMemberName) ? '--edited' : ''
                      timeEdited =
                          (item.destinationTime && item.destinationTime !== courseHistory[index + 1].destinationTime) ||
                          (item.direction && item.direction !== courseHistory[index + 1].direction) ||
                          (item.isWorkAddress && item.isWorkAddress !== courseHistory[index + 1].isWorkAddress) ||
                          (item.isDestinationTime &&
                              item.isDestinationTime !== courseHistory[index + 1].isDestinationTime)
                              ? "--edited"
                              : "";
                      driverEdited = item.driver && item.driver !== courseHistory[index + 1].driver ||
                      !item.driver && courseHistory[index + 1].driver ? '--edited' : '';

                      if (item.worker.length === courseHistory[index + 1].worker.length) {
                          let workerId = "";
                          let addressId = "";
                          for (let i = 0; i < item.worker.length; i++) {
                              workerId = item.worker[i].id;
                              addressId = item.worker[i].addressId;
                              for (let j = 0; j < courseHistory[index + 1].worker.length; j++) {
                                  if (
                                      workerId !== courseHistory[index + 1].worker[j].id ||
                                      addressId !== courseHistory[index + 1].worker[j].addressId
                                  ) {
                                      workerEdited = "--edited";
                                  }
                              }
                          }
                      } else {
                          workerEdited = "--edited";
                      }
                  }
                  if (
                      orderNoEdited ||
                      notesEdited ||
                      fromEdited ||
                      toEdited ||
                      workerEdited ||
                      timeEdited ||
                      canceledOrder ||
                      driverEdited ||
                      partnerEdited
                  ) {
                      courseEdited = true;
                      if (!isEdited) {
                          setIsEdited(true);
                      }
                  }

                  //ustawienie czasów
                  let changeDate = format(fromUnixTime(item?.changeHistory?.changeDate), "d LLLL yyyy, HH:mm", {
                      locale: pl,
                  });
                  return (
                      <Timeline key={index} align="alternate" className={classes.timeRoot}>
                          {canceledOrder ? (
                              <TimelineItem>
                                  <TimelineSeparator>
                                      <TimelineDot className={classes.timeDot} />
                                      <TimelineConnector className={classes.timeConnector} />
                                  </TimelineSeparator>
                                  <TimelineContent className={classes.timeContent}>
                                      <div className={styles["moreDetails__inputs--historyItem"]}>
                                          <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data--history"
                                                      ]
                                                  }
                                              >
                                                  {`${changeDate} (${
                                                      item.changeAuthor.name ? item.changeAuthor.name + " " : ""
                                                  }
                                                    ${item.changeAuthor.surname ? item.changeAuthor.surname : ""})`}
                                              </p>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                      ]
                                                  }
                                              >
                                                  Zamówienie ANULOWANE
                                              </p>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                      ]
                                                  }
                                              >
                                                  {`Uwagi: ${item.cancelNotes ? item.cancelNotes : "Nie podano"}`}
                                              </p>
                                          </div>
                                      </div>
                                  </TimelineContent>
                              </TimelineItem>
                          ) : (
                              // courseEdited || (isEdited && index === lastIndex) &&
                              <TimelineItem>
                                  <TimelineSeparator>
                                      <TimelineDot className={classes.timeDot} />
                                      <TimelineConnector className={classes.timeConnector} />
                                  </TimelineSeparator>
                                  <TimelineContent className={classes.timeContent}>
                                      <div className={styles["moreDetails__inputs--historyItem"]}>
                                          <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                              {index === lastIndex && !canceledOrder ? (
                                                  <p
                                                      className={
                                                          styles[
                                                              "moreDetails__inputs--historyItem--wrapper--history--details--title--history"
                                                          ]
                                                      }
                                                  >
                                                      Pierwsza wersja
                                                  </p>
                                              ) : null}
                                              <div
                                                  className={
                                                      styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                  }
                                              >
                                                  <div
                                                      className={
                                                          styles[
                                                              "moreDetails__inputs--historyItem--wrapper--history--details"
                                                          ]
                                                      }
                                                  >
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                              ]
                                                          }
                                                      >
                                                          {index !== lastIndex ? (
                                                              <p
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data--history"
                                                                      ]
                                                                  }
                                                              >
                                                                  {`${changeDate} (${
                                                                      item.changeAuthor.name
                                                                          ? item.changeAuthor.name + " "
                                                                          : ""
                                                                  }
                                                    ${item.changeAuthor.surname ? item.changeAuthor.surname : ""})`}
                                                              </p>
                                                          ) : null}

                                                          {orderNoEdited || index === lastIndex ? (
                                                              <>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`Nr zlecenia: `}
                                                                  </p>
                                                                  <span
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  orderNoEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {item.orderNo ? item.orderNo : "-"}
                                                                  </span>
                                                              </>
                                                          ) : null}
                                                          {notesEdited || index === lastIndex ? (
                                                              <>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`Uwagi: `}
                                                                  </p>
                                                                  <span
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  notesEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {item.notes ? item.notes : "-"}
                                                                  </span>
                                                              </>
                                                          ) : null}
                                                          {driverEdited || index == lastIndex ? (
                                                              <>
                                                                  <p className={styles['communicationPanel__inputs--historyItem--wrapper--history--details--singleColumn--info']}>
                                                                      Kierowca:
                                                                  </p>
                                                                  <span className={styles['communicationPanel__inputs--historyItem--wrapper--history--details--singleColumn--data' + driverEdited]}>
                                                                      {item.driver ? `${item.driver.firstname} ${item.driver.surname}` : '-----'}
                                                                </span>
                                                              </>
                                                          ) : null}
                                                      </div>
                                                  </div>
                                              </div>

                                              {/*Adres odbioru historia*/}
                                              {isOrderConnectingsChange ||
                                              index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Połączone kursy
                                                      </p>
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details"
                                                              ]
                                                          }
                                                      >
                                                          {item.connectedOrders.length ? item.connectedOrders.join(", "): "Brak połączeń"}
                                                      </div>
                                                  </div>
                                              ) : null}
                                              {/*Adres odbioru historia*/}
                                              {fromEdited ||
                                              (workerEdited && item.direction === 1) ||
                                              index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Adres odbioru
                                                      </p>
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details"
                                                              ]
                                                          }
                                                      >
                                                          {item.from || (item.from && index === lastIndex) ? (
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                      ]
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      Adres:
                                                                  </p>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  fromEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`${item.from.street ? item.from.street : ""} ${
                                                                          item.from.no ? item.from.no : ""
                                                                      } ${item.from.flat ? `/${item.from.flat}` : ""} ${
                                                                          item.from.zipCode ? item.from.zipCode : ""
                                                                      } ${item.from.city ? item.from.city : "-"}`}
                                                                  </p>
                                                              </div>
                                                          ) : (
                                                              item.worker.map((item, index) => {
                                                                  return (
                                                                      <div
                                                                          key={index}
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                              ]
                                                                          }
                                                                      >
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Imię i nazwisko
                                                                          </p>
                                                                          <span
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${
                                                                                  item.firstname ? item.firstname : " "
                                                                              } ${item.surname ? item.surname : " "}`}
                                                                          </span>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Adres
                                                                          </p>
                                                                          <span
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${item.street ? item.street : ""} ${
                                                                                  item.no ? item.no : " "
                                                                              } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                  item.zipCode ? item.zipCode : ""
                                                                              } ${item.city ? item.city : ""}`}
                                                                          </span>
                                                                      </div>
                                                                  );
                                                              })
                                                          )}
                                                      </div>
                                                  </div>
                                              ) : null}

                                              {/*Adres docelowy*/}
                                              {toEdited ||
                                              (workerEdited && item.direction === 0) ||
                                              index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Adres docelowy
                                                      </p>
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details"
                                                              ]
                                                          }
                                                      >
                                                          {item.to || (item.to && index === lastIndex) ? (
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                      ]
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      Adres
                                                                  </p>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  toEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`${item.to.street ? item.to.street : ""} ${
                                                                          item.to.no ? item.to.no : ""
                                                                      } ${item.to.flat ? `/${item.to.flat}` : ""} ${
                                                                          item.to.zipCode ? item.to.zipCode : ""
                                                                      } ${item.to.city ? item.to.city : ""}`}
                                                                  </p>
                                                              </div>
                                                          ) : (
                                                              item.worker.map((item, index) => {
                                                                  return (
                                                                      <div
                                                                          key={index}
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                              ]
                                                                          }
                                                                      >
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Imię i nazwisko
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${
                                                                                  item.firstname ? item.firstname : ""
                                                                              } ${item.surname ? item.surname : ""}`}
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Adres
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${item.street ? item.street : ""} ${
                                                                                  item.no ? item.no : ""
                                                                              } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                  item.zipCode ? item.zipCode : ""
                                                                              } ${item.city ? item.city : ""}`}
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              })
                                                          )}
                                                      </div>
                                                  </div>
                                              ) : null}
                                              {partnerEdited || index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                                  ]
                                                          }
                                                      >
                                                          Partner
                                                      </p>
                                                      <div>
                                                          {item.consortiumMemberName ?? '-'}&nbsp;
                                                      </div>
                                                  </div>
                                              ) : null}
                                              {/*Czasy*/}
                                              {timeEdited || index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Czasy
                                                      </p>
                                                      <div
                                                          className={
                                                              styles["moreDetails__inputs--historyItem--wrapper--time"]
                                                          }
                                                      >
                                                          <div
                                                              className={
                                                                  styles[
                                                                      "moreDetails__inputs--historyItem--wrapper--time--single"
                                                                  ]
                                                              }
                                                          >
                                                              {item.from || item.isWorkAddress ? (
                                                                  <div
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--time--from--toWork" +
                                                                                  timeEdited
                                                                          ]
                                                                      }
                                                                  ></div>
                                                              ) : (
                                                                  <div
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--time--from--toHome" +
                                                                                  timeEdited
                                                                          ]
                                                                      }
                                                                  ></div>
                                                              )}

                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--data" +
                                                                              timeEdited
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.isDestinationTime
                                                                      ? "---"
                                                                      : format(
                                                                            fromUnixTime(item.destinationTime),
                                                                            "d LLLL yyyy, HH:mm",
                                                                            { locale: pl }
                                                                        )}
                                                              </div>
                                                          </div>
                                                          <div
                                                              className={
                                                                  styles[
                                                                      "moreDetails__inputs--historyItem--wrapper--time--single"
                                                                  ]
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--info"
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.to ? (
                                                                      <div
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--time--to--toWork" +
                                                                                      timeEdited
                                                                              ]
                                                                          }
                                                                      ></div>
                                                                  ) : (
                                                                      <div
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--time--to--toHome" +
                                                                                      timeEdited
                                                                              ]
                                                                          }
                                                                      ></div>
                                                                  )}
                                                              </div>
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--data" +
                                                                              timeEdited
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.isDestinationTime
                                                                      ? format(
                                                                            fromUnixTime(item.destinationTime),
                                                                            "d LLLL yyyy, HH:mm",
                                                                            { locale: pl }
                                                                        )
                                                                      : "---"}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null}
                                          </div>
                                      </div>
                                  </TimelineContent>
                              </TimelineItem>
                          )}
                      </Timeline>
                  );
              })
            : null;

    const shouldDisplayShowScheduleButton =
        (courseDetails.cancel === false && courseDetails.driver && courseDetails.status === "frozen") ||
        (entity === "operator" &&
            courseDetails.cancel === false &&
            courseDetails.driver &&
            courseDetails.status === "pending") ||
        (entity === "operator" && courseDetails.cancel === false && courseDetails.status === "deny");

    const shouldDisplayChangeDriverButton =
        courseDetails.cancel === false && entity === "operator" && props.listStatus === "active";

    const shouldDisplayCourseConfirmButton =
        (courseDetails.cancel === false && courseDetails.driver && courseDetails.status === "frozen") ||
        (entity === "operator" &&
            courseDetails.cancel === false &&
            courseDetails.driver &&
            courseDetails.status === "pending") ||
        (entity === "operator" &&
            courseDetails.cancel === false &&
            courseDetails.status === "deny" &&
            entity === "operator" &&
            props.listStatus === "active");
            // const temp  = ['ROLE_DELEGACJE_ZAPIS','ROLE_DYSPOZYTOR', 'ROLE_ADMINISTRACJA']
    const hasProhibitedRole = props.userRoles.includes('ROLE_ADMINISTRACJA') || props.userRoles.includes('ROLE_DYSPOZYTOR')
    const canViewRealWaypoints  = hasProhibitedRole ? props.listStatus === 'active' ? true : false : true
    console.log('canViewWaypoint ' + canViewRealWaypoints)
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["moreDetails__description"]}>
                            <h2 className={styles["moreDetails__description--title"]}>Szczegóły kursu</h2>
                            <span className={styles["moreDetails__description--close"]} onClick={handleClose}>
                                X
                            </span>
                        </div>

                        <div className={styles["moreDetails"]}>
                            <div className={styles["moreDetails__map"]}>
                            {routeData.length > 0 && routeWaypoints.length > 0 
                                && <OrderMap 
                                        routeData={routeData} 
                                        routeWaypoints={routeWaypoints} 
                                        zoom={6} 
                                        key="details-map" 
                                        realWaypoints={canViewRealWaypoints && realWaypoints} />
                            }
                                {/* <CourseMap
                                    courseDetails={courseDetails}
                                    handleCounters={handleCounters}
                                    entity={props.entity}
                                    realWaypoints={realWaypoints}
                                /> */}
                            </div>

                            {/*Aktualna wersja zlecenia*/}
                            <SimpleBar style={{ maxHeight: "80vh", minWidth: 520 }} className={classes.simplebar}>
                                <div className={styles["moreDetails__inputs"]}>
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--courseDetails"]}>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Nr kursu:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetails.number ? courseDetails.number : ""}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Ostatnia modyfikacja:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseHistory.length > 1
                                                        ? format(
                                                              fromUnixTime(courseHistory[1].changeHistory.changeDate),
                                                              "d LLLL yyyy, HH:mm",
                                                              {
                                                                  locale: pl,
                                                              }
                                                          )
                                                        : createDate}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Nr zlecenia:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetails.orderNo ? courseDetails.orderNo : "-"}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Uwagi:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetailsNotes}
                                                </p>

                                                {entity === "operator" ? (
                                                    <>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Kontrahent:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.kontrahent
                                                                ? courseDetails.kontrahent.name
                                                                : ""}
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles[
                                                            "moreDetails__inputs--item--courseDetails--singleColumn--buttonWrapper"
                                                        ]
                                                    }
                                                >
                                                    <Button
                                                        size="small"
                                                        className={classes.changeDriverButton}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            if (!showHistory) {
                                                                handleShowHistory();
                                                            }
                                                            setShowHistory(!showHistory);
                                                        }}
                                                    >
                                                        {showHistory ? "Ukryj historię" : "Zobacz historię"}
                                                    </Button>
                                                    {entity === "operator" &&
                                                    courseDetails?.kontrahent?.contract?.isHighwayPay ? (
                                                        <Button
                                                            size="small"
                                                            className={classes.changeDriverButton}
                                                            variant="outlined"
                                                            onClick={() => setPaidCoursePanel(!paidCoursePanel)}
                                                        >
                                                            Przejazd PŁATNY
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {paidCoursePanel ? (
                                        <div className={styles["moreDetails__inputs--item"]}>
                                            <p className={styles["moreDetails__inputs--item--title"]}>
                                                Przejazd płatny
                                            </p>
                                            <PaidCourse
                                                orderId={courseDetails.id}
                                                highwayCost={courseDetails.highwayCost}
                                            />
                                        </div>
                                    ) : null}

                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--courseDetails"]}>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Kierowca:
                                                </p>
                                                {courseDetails.driver ? (
                                                    <>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {entity === "operator"
                                                                ? `${courseDetails.driver.firstname} ${courseDetails.driver.surname}`
                                                                : courseDetails.driver.firstname}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Telefon:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.driver.workPhoneNumber
                                                                ? courseDetails.driver.workPhoneNumber
                                                                : "-"}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Tablice rejestracyjne:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.driver.regNo
                                                                ? courseDetails.driver.regNo
                                                                : "-"}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles["moreDetails__inputs--item--courseDetails--data"]
                                                        }
                                                    >
                                                        Nie przypisano kierowcy
                                                    </div>
                                                )}
                                                {props.userRoles?.indexOf('ROLE_DYSPOZYTOR') === -1 &&
                                                   <>
                                                       <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                            Kierowca referencyjny:
                                                        </p>
                                                    {courseDetails.realDriver ? (
                                                        <div
                                                        className={
                                                        styles["moreDetails__inputs--item--courseDetails--data"]
                                                        }>
                                                    {courseDetails.realDriver}
                                                        </div>
                                                        ) : (
                                                        <div
                                                        className={
                                                        styles["moreDetails__inputs--item--courseDetails--data"]
                                                    }
                                                        >
                                                        Nie przypisano kierowcy
                                                        </div>
                                                        )}
                                                   </>
                                                }
                                            </div>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles[
                                                            "moreDetails__inputs--item--courseDetails--singleColumn--buttonWrapper"
                                                        ]
                                                    }
                                                >
                                                    {shouldDisplayShowScheduleButton && (
                                                        <Button
                                                            size="small"
                                                            className={classes.changeDriverButton}
                                                            variant="outlined"
                                                            onClick={() => setShowSingleSchedule(!showSingleSchedule)}
                                                        >
                                                            Zobacz grafik
                                                        </Button>
                                                    )}

                                                    {shouldDisplayChangeDriverButton && (
                                                        <Button
                                                            size="small"
                                                            className={classes.changeDriverButton}
                                                            variant="outlined"
                                                            onClick={() => setChangeRealDriverPanel(!changeDriverPanel)}
                                                        >
                                                            Zmień kierowcę referencyjnego
                                                        </Button>
                                                    )}

                                                    {shouldDisplayCourseConfirmButton &&
                                                    courseDetails.status !== "frozen" ? (
                                                        <Button
                                                            size="small"
                                                            className={classes.changeDriverButton}
                                                            variant="outlined"
                                                            onClick={() =>
                                                                (window.location.pathname = `/zlecenie/${shortId}`)
                                                            }
                                                        >
                                                            Potwierdź kurs
                                                        </Button>
                                                    ) : null}

                                                    {props.unfillComponent && courseDetails.status === "deny" ? (
                                                        <Button
                                                            size="small"
                                                            className={classes.changeDriverButton}
                                                            variant="outlined"
                                                            onClick={() =>
                                                                (window.location.pathname = `/zlecenie/${shortId}`)
                                                            }
                                                        >
                                                            Potwierdź kurs
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showSingleSchedule ? (
                                        <div className={styles["moreDetails__inputs--item"]}>
                                            <SingleDriverSchedule
                                                driver={courseDetails.driver.id}
                                                pickupTime={courseDetails.pickupTime}
                                                entity={props.entity}
                                            />
                                        </div>
                                    ) : null}
                                    {changeRealDriverPanel ? (
                                        <div className={styles["moreDetails__inputs--item"]}>
                                            <p className={styles["moreDetails__inputs--item--title"]}>
                                                Ręczna zmiana kierowcy referencyjnego
                                            </p>
                                            <ChangeRealDriverPanel
                                                driverId={courseDetails.driver?.id}
                                                orderId={courseDetails.id}
                                                switchDriver={switchRealDriver}
                                                onDriverChange={() => {
                                                    props.onRealDriverChange();
                                                }}
                                                currentValue={courseDetails.realDriver}
                                            />
                                        </div>
                                    ) : null}

                                    {/* Adres odbioru */}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Adres odbioru</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper"]}>
                                            {courseDetails.from ? (
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--singleAddress"]
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Ulica
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${
                                                            courseDetails.from.street ? courseDetails.from.street : "-"
                                                        } ${courseDetails.from.no ? courseDetails.from.no : ""} ${
                                                            courseDetails.from.flat ? `/${courseDetails.from.flat}` : ""
                                                        }`}
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Miasto
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${
                                                            courseDetails.from.zipCode ? courseDetails.from.zipCode : ""
                                                        } ${courseDetails.from.city ? courseDetails.from.city : "-"}`}
                                                    </p>
                                                </div>
                                            ) : (
                                                courseDetails.worker.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--singleAddress"
                                                                ]
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Imię i nazwisko
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.firstname ? item.firstname : ""} ${
                                                                    item.surname ? item.surname : "-"
                                                                }`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Telefon kontaktowy
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {item.phoneNumber ? item.phoneNumber : "-"}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Ulica
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.street ? item.street : "-"} ${
                                                                    item.no ? item.no : ""
                                                                } ${item.flat ? `/${item.flat}` : ""}`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Miasto
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.zipCode ? item.zipCode : ""} ${
                                                                    item.city ? item.city : "-"
                                                                }`}
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>

                                    {/* Adres docelowy */}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Adres docelowy</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper"]}>
                                            {courseDetails.to ? (
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--singleAddress"]
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Ulica
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${courseDetails.to.street ? courseDetails.to.street : "-"} ${
                                                            courseDetails.to.no ? courseDetails.to.no : ""
                                                        } ${courseDetails.to.flat ? `/${courseDetails.to.flat}` : ""}`}
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Miasto
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${courseDetails.to.zipCode ? courseDetails.to.zipCode : ""} ${
                                                            courseDetails.to.city ? courseDetails.to.city : "-"
                                                        }`}
                                                    </p>
                                                </div>
                                            ) : (
                                                courseDetails.worker.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--singleAddress"
                                                                ]
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Imię i nazwisko
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.firstname ? item.firstname : ""} ${
                                                                    item.surname ? item.surname : ""
                                                                }`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Telefon kontaktowy
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {item.phoneNumber ? item.phoneNumber : ""}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Ulica
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.street ? item.street : "-"} ${
                                                                    item.no ? item.no : ""
                                                                } ${item.flat ? `/${item.flat}` : ""}`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Miasto
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.zipCode ? item.zipCode : ""} ${
                                                                    item.city ? item.city : "-"
                                                                }`}
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>

                                    {/*Czasy*/}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Czasy planowane</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    {courseDetails.from || courseDetails.isWorkAddress ? (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--from--toWork"
                                                                ]
                                                            }
                                                        ></div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--from--toHome"
                                                                ]
                                                            }
                                                        ></div>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {startTime}
                                                </div>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    {courseDetails.to ? (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--to--toWork"
                                                                ]
                                                            }
                                                        ></div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--to--toHome"
                                                                ]
                                                            }
                                                        ></div>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {endTime}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--totalTime"
                                                            ]
                                                        }
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {totalTime}
                                                </div>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--totalKm"
                                                            ]
                                                        }
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {totalKm}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isLeadTimeVisible && (
                                        <div className={styles["moreDetails__inputs--item"]}>
                                            <p className={styles["moreDetails__inputs--item--title"]}>
                                                Czasy realizacji
                                            </p>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--info"
                                                            ]
                                                        }
                                                    >
                                                        {courseDetails.from || courseDetails.isWorkAddress ? (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--time--from--toWork"
                                                                    ]
                                                                }
                                                            ></div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--time--from--toHome"
                                                                    ]
                                                                }
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--data"
                                                            ]
                                                        }
                                                    >
                                                        {format(realStartDate, "d LLLL yyyy, HH:mm", {
                                                            locale: pl,
                                                        })}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--info"
                                                            ]
                                                        }
                                                    >
                                                        {courseDetails.to ? (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--time--to--toWork"
                                                                    ]
                                                                }
                                                            ></div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--time--to--toHome"
                                                                    ]
                                                                }
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--data"
                                                            ]
                                                        }
                                                    >
                                                        {format(realEndDate, "d LLLL yyyy, HH:mm", {
                                                            locale: pl,
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--info"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--single--totalTime"
                                                                ]
                                                            }
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--data"
                                                            ]
                                                        }
                                                    >
                                                        {realDuration}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--info"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--single--totalKm"
                                                                ]
                                                            }
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${Math.round(realTotalKm)} km`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className={styles["moreDetails__times"]}>
                                        <div className={styles["moreDetails__times--row"]}>
                                            <div className={styles["moreDetails__times--col--title--location"]}>
                                                Lokalizacja
                                            </div>
                                            <div className={styles["moreDetails__times--col--title--time"]}>
                                                Czas zapl.
                                            </div>
                                            <div className={styles["moreDetails__times--col--title--time"]}>
                                                Czas real.
                                            </div>
                                        </div>
                                        {courseDetails.path?.map((path, index) => {
                                            return (
                                                <div className={styles["moreDetails__times--row"]} key={index}>
                                                    <div className={styles["moreDetails__times--col--location"]}>
                                                        {`${path.adres.street ? path.adres.street : ""} ${
                                                            path.adres.no ? path.adres.no : ""
                                                        }${path.adres.flat ? `/${path.adres.flat}` : ""} ${
                                                            path.adres.zipCode ? path.adres.zipCode : ""
                                                        } ${path.adres.city ? path.adres.city : ""}`}
                                                    </div>
                                                    <div className={styles["moreDetails__times--col--time"]}>
                                                        {path.time
                                                            ? format(fromUnixTime(path.time), "HH:mm", {
                                                                  locale: pl,
                                                              })
                                                            : "-"}
                                                    </div>
                                                    <div className={styles["moreDetails__times--col--time"]}>
                                                        {path.arrivedAt
                                                            ? format(fromUnixTime(path.arrivedAt), "HH:mm", {
                                                                  locale: pl,
                                                              })
                                                            : "-"}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </SimpleBar>

                            {/*Historia zlecenia*/}

                            {showHistory ? (
                                <div className={styles["moreDetails__inputs"]}>
                                    <SimpleBar
                                        style={{ maxHeight: "80vh", minWidth: 320 }}
                                        className={classes.simplebar}
                                    >
                                        {editedData}
                                        {courseHistory.length > 1 && isEdited ? (
                                            <Timeline align="alternate" className={classes.timeRoot}>
                                                <TimelineItem>
                                                    <TimelineDot className={classes.timeDot} />
                                                    <TimelineContent className={classes.timeContent}>
                                                        <div
                                                            className={
                                                                styles["moreDetails__inputs--historyItem--wrapper"]
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history"
                                                                    ]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles[
                                                                            "moreDetails__inputs--historyItem--wrapper--history--details"
                                                                        ]
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles[
                                                                                "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                            ]
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`Utworzony: `}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {createDate}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles[
                                                                                "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                            ]
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`Dyspozytor: `}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`${
                                                                                courseDetails.author.firstName
                                                                                    ? courseDetails.author.firstName
                                                                                    : ""
                                                                            } ${
                                                                                courseDetails.author.lastName
                                                                                    ? courseDetails.author.lastName
                                                                                    : "-"
                                                                            } 
                                                                            ${
                                                                                courseDetails.subAuthor
                                                                                    ? `(jako ${courseDetails.subAuthor.firstName} ${courseDetails.subAuthor.lastName})`
                                                                                    : ""
                                                                            }`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        ) : (
                                            <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--historyItem--wrapper--history--details"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                ]
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                    ]
                                                                }
                                                            >
                                                                {`Utworzony: `}
                                                            </span>
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                    ]
                                                                }
                                                            >
                                                                {createDate}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                ]
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                    ]
                                                                }
                                                            >
                                                                {`Dyspozytor: `}
                                                            </span>
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${
                                                                    courseDetails.author.firstName
                                                                        ? courseDetails.author.firstName
                                                                        : ""
                                                                } ${
                                                                    courseDetails.author.lastName
                                                                        ? courseDetails.author.lastName
                                                                        : "-"
                                                                }
                                                                           ${
                                                                               courseDetails.subAuthor
                                                                                   ? `(jako ${courseDetails.subAuthor.firstName} ${courseDetails.subAuthor.lastName})`
                                                                                   : ""
                                                                           }`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </SimpleBar>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
