export function checkConnectedOrders(order, _order, worker, _worker) {
    const endOfFirstOrder = order.direction.value ? order.destination.value?.id : worker.address.value?.id;

    const startOfSecondOrder = _order.direction.value ? _worker.address.value?.id : _order.destination.value?.id;

    const endTimeOfFirstOrder = order.direction.value ? order.timeWork.value?.getTime() : order.timeHome.value?.getTime();

    const startTimeOfSecondOrder = _order.direction.value ? _order.timeHome.value?.getTime() : _order.timeWork.value?.getTime();

    const timeCondition = !endTimeOfFirstOrder || !startTimeOfSecondOrder ? true : endTimeOfFirstOrder > startTimeOfSecondOrder;

    return worker.person.value?.driverId === _worker.person.value?.driverId &&
        (endOfFirstOrder !== startOfSecondOrder || timeCondition);
}