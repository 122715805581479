/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect} from "react";
import { useFormikContext, getIn } from "formik";
import LinkOn from "@material-ui/icons/Link";
import LinkOff from "@material-ui/icons/LinkOff";
import { v4 as uuid } from "uuid";

import { SummarySingleOrder } from ".";
import { OrderMap } from "app/reusableComponents/newOrderPanel/map";
import { statusType } from "app/reusableComponents/newOrderPanel/helpers";
import { useOrderDataContext, useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";
import {
    GridColumnElement,
    SummaryMapWrapper,
    SummaryWrapper,
    ButtonCore,
    pallet,
} from "app/reusableComponents/newOrderPanel/styled";
import { LinearProgress } from "@material-ui/core";
import {MimicContext} from "../../../../context";

export const Summary = () => {
    const { isRoleType } = useContext(MimicContext)
    const {
        orders,
        joinedRoute,
        joinOrders,
        routeWaypoints,
        routeDate,
        status: orderDataStatus,
    } = useOrderDataContext();
    const { status } = useOrderContext();
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const { isEditView } = useOrderContext();
    const isConnected = getIn(values, "isConnected");

    const helperJoin = async () => {
        const isDone = await joinOrders(values.orders);

        if (isDone) {
            setFieldValue("isConnected", !isConnected);
        }
    };

    const isEmptyDate = () => {
        let isEmptyDate = false;
        Object.keys(values).forEach((el) => {
            const item = values[el]
            if (item.length) {
                item.forEach((order) => {
                    const anyWorkerHasTimeOrigin = order.workers.find((worker) =>worker.timeOrigin !== null )

                    if (order.timeDestination === null && !anyWorkerHasTimeOrigin) {
                        return isEmptyDate = true;
                    }
                })
            }
        })

        return isEmptyDate;
    }

    useEffect(() => {
        const fetch = async () => {
            await joinOrders(values.orders);
        };
        if (isConnected) {
            fetch();
        }
    }, [values]);

    return (
        <SummaryWrapper>
            <SummaryMapWrapper>
                <OrderMap routeData={routeDate} routeWaypoints={routeWaypoints} zoom={6} key="small-map" />
            </SummaryMapWrapper>
            {orderDataStatus === statusType.LOADING && <LinearProgress className="progress-bar" />}
            {!isConnected ? (
                orders.map((order, index) => (
                    <SummarySingleOrder key={uuid()} order={order} positionPrefix={index + 1} />
                ))
            ) : (
                <SummarySingleOrder order={joinedRoute} />
            )}
            <GridColumnElement column="1/8">
                <ButtonCore
                    disabled={status === statusType.SENDING || isSubmitting || isEmptyDate()}
                    width="100%"
                    bg_color={pallet.main}
                    bg_hover_color={pallet.mainDark}
                    type="submit"
                >
                    Potwierdzam
                </ButtonCore>
            </GridColumnElement>
            <GridColumnElement column="8/11">
                <ButtonCore
                    width="100%"
                    disabled={status === statusType.SENDING || (isRoleType('DYSPOZYTOR') && isEditView)}
                    bg_color={isConnected ? pallet.main : pallet.gray}
                    bg_hover_color={isConnected ? pallet.mainDark : pallet.darkGray}
                    type="button"
                    onClick={helperJoin}
                >
                    {isConnected ? "Rozłącz" : "Połącz"}&nbsp;
                    {isConnected ? <LinkOff /> : <LinkOn />}
                </ButtonCore>
            </GridColumnElement>
        </SummaryWrapper>
    );
};
