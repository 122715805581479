import React from "react";

import { ErrorMessage, getIn, useFormikContext } from "formik";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import { WarningText, DateWrapper } from "app/reusableComponents/newOrderPanel/styled";

export const DatePicker = ({ name, disabled, baseName, userDispatch, isDriver }) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const timePickerValue = getIn(values, name);
    const workers = getIn(values, `${baseName}.workers`);

    const cleanOtherFields = (skipName) => {
        const workersFiledName = workers.map((_, index) => `${baseName}.workers[${index}].timeOrigin`);
        const listToFilter = [name, `${baseName}.timeDestination`, ...workersFiledName].filter(
            (item) => item !== skipName
        );
        listToFilter.forEach((item) => {
            setFieldValue(item, null);
        });
    };
    const isError = getIn(errors, `${baseName}.timeDestination`);
    const futureDate = (date) => {
        if (date) {
            const newDate = new Date(
                new Date(date).getTime() + (4*60*60000)
            ).toISOString()
            return newDate;
        }
        return null;
    }
    const handleChange = (date) => {
        const timestamp = Date.parse(date);
        if (isNaN(timestamp) === false) {
            cleanOtherFields(name)
            setFieldValue(name, new Date(timestamp).toISOString());
        } else {
            if(timePickerValue === null) return
            setFieldValue(name, null);
        }
    };

    return (
        <DateWrapper>
            <KeyboardDateTimePicker
                fullWidth
                disablePast
                minDateMessage="Niepoprawna data"
                autoOk
                invalidLabel="Niepoprawna data"
                autoComplete="off"
                invalidDateMessage="Niepoprawna data"
                label="Wybierz Datę*"
                value={timePickerValue ? new Date(timePickerValue).toISOString() : null}
                defaultValue={timePickerValue ? new Date(timePickerValue) : null}
                min={new Date()}
                max={userDispatch()?.length && isDriver?.hasOwnProperty('id') ? futureDate(timePickerValue) : null}
                mask="__/__/____ __:__"
                format="dd/MM/yyyy HH:mm"
                name={`${baseName}.timeDestination`}
                disabled={disabled}
                onChange={handleChange}
                onBlur={() => cleanOtherFields(name)}
                error={!!isError}
                ampm={false}
            />
            <ErrorMessage
                name={`${baseName}.timeDestination`}
                render={(message) => <WarningText>{message}</WarningText>}
            />
        </DateWrapper>
    );
};
