import React from "react";
import ActiveDashboard from "./ActiveDashboard";
import AppRoute from "../../../../AppRoute";

const ActiveDashboardRoute = () => {
    return (
        <AppRoute app="operator" window="trasy" tab="activeDashboard" component={ActiveDashboard}/>
    );
};

export default ActiveDashboardRoute;
