import React, {useState, useEffect, Fragment} from "react";
import styles from "./AddressList.module.scss"
import {makeStyles, styled} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {
    fromUnixTime,
    format,
    getUnixTime,
    startOfMonth,
    endOfMonth,
    getYear,
    getMonth,
    getDaysInMonth,
    startOfDay, endOfDay, getDate, isDate
} from "date-fns";
import ProgressLine from "../../../reusableComponents/progressLine/ProgressLine";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {pl} from "date-fns/locale";
import HttpClient from "../../../../HttpClient";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {createMuiTheme, TablePagination} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {DatePicker} from "@material-ui/pickers";
import {useHistory} from "react-router-dom";
import {convertNumberToArray} from "../../../../helper/convertNumberToArray";
import * as qs from "qs";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ClearIcon from "@material-ui/icons/Clear";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ThemeProvider} from "@material-ui/styles";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import produce from "immer";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoDialog from "../../../reusableComponents/alerts/infoDialog/InfoDialog";

const tableColumns = [
    {
        id: "city",
        label: "Miasto",
        query: true,
        sorted: true,
        order: 'ASC',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "zipCode",
        label: "Kod pocztowy",
        query: true,
        sorted: false,
        order: null,
        minWidth: 150,
        maxWidth: 150,
    },
    {
        id: "street",
        label: "Ulica",
        query: true,
        sorted: false,
        order: null,
        minWidth: 180,
        maxWidth: 180,
    },
    {
        id: "no",
        label: "Budynek",
        query: true,
        sorted: false,
        order: null,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "flat",
        label: "Mieszkanie",
        query: false,
        sorted: false,
        order: null,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "type",
        label: "Rodzaj",
        query: true,
        sorted: false,
        order: null,
        minWidth: 150,
        maxWidth: 180,
    }
];

function createData(id, city, zipCode, street, no, flat, type) {
    return {id, city, zipCode, street, no, flat, type};
}

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        padding: "0 10px",
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 15px 0 0",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTableCell-body": {
            padding: "2px 15px 2px 2px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTabs-fixed": {
            overflowX: "auto !important",
        },
    },
    content: {
        maxWidth: "180px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: 'break-word',
        display: "flex",
        flexDirection: "column"
    },
    button: {
        backgroundColor: '#ED1D1D',
        '&:hover': {
            backgroundColor: "#C01313",
        },
    },
    icon: {
        color: "white",
    },
    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px"
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    changeViewButton: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        }
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#C01313",
        }
    }
});

const SearchDatePicker = styled(DatePicker)({
    width: 120,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}

const generateStartEndDate = (newDate) => ({
    start: getUnixTime(startOfMonth(newDate)),
    end: getUnixTime(endOfMonth(newDate))
});

export default function AddressList(props) {
    const history = useHistory();
    const classes =
            useStyles(),
        source = axios.CancelToken.source(),
        url = "api/addresses",
        [invokeClearColumn, setClearColumn] = useState(false),
        [showInfoDialogCancel, setShowInfoDialogCancel] = useState(false),
        [index, setIndex] = useState(null),
        [page, setPage] = useState(0),
        [columns, setColumns] = useState(tableColumns),
        [rowsPerPage, setRowsPerPage] = useState(50),
        [searchValues, setSearchValues] = useState([{
            id: 'time',
            value: null
        }]),
        [api, setApi] = useState([]),
        [count, setCount] = useState(0),
        [isLoading, setIsLoading] = useState(true),
        handleOnEnterPress = (e) => {
            if (e.key === 'Enter') {
                getData();
            }
        }, getParams = (s) => {
            if (s.value !== null) {
                if (isDate(s.value)) {
                    s.value = getUnixTime(s.value);
                }
                return s.id + "=" + s.value + "&";
            }
        }, getData = async () => {
            setIsLoading(true);
            const httpRequest = HttpClient.createRequest();
            const _searchParams = searchValues.map((a) => ({...a}));
            const getParam = _searchParams.map(getParams).join("");
            const sortField = [...columns].filter((item) => item.sorted === true);

            try {
                const response = await httpRequest.get(
                    `${url}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}`,
                    {
                        cancelToken: source.token,
                    },
                    true
                );
                setApi(response.data['data']);
                setCount(response.data['count']);
                setIsLoading(false);
            } catch (e) {
                console.log('error', e)
            }


        }, deleteAddress = async () => {
            const addressID = api[index].id;
            const httpRequest = HttpClient.createRequest();
            try {
                const response = await httpRequest.delete(
                    `api/addresses/${addressID}`,
                    { cancelToken: source.token },
                    true,
                );
                if (response.status === 210) {
                    await getData()
                }
            } catch (e) {}
        }, {value} = searchValues.find(value => value.id === 'time');


    useEffect(() => {
        window.scrollTo(0, 0);
        getData();

        return () => {
            source.cancel();
        };
    }, [invokeClearColumn, value, rowsPerPage, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        setSearchValues([...filterObject, {id: field, value: query}]);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    }

    const handleSwitchInfoDialog = (value) => {
        setShowInfoDialogCancel(value);
    };

    const ShowArrow = ({sorted, order, classes}) => {
        if (sorted) {
            if (order === "ASC") {
                return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
            } else if (order === "DESC") {
                return <ArrowDownwardIcon fontSize="small" className={classes.headCellArrow}/>;
            }
        } else {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow}/>;
        }
    };

    const createSortHandler = (_column) => {
        columns.map((column) => {
            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
        });
        setColumns(columns);
        handleSort();
    };

    const handleSort = () => {
        setPage(0);
        getData();
    };

    const rows = api
        ? api.map((data) => {
            return createData(
                data.id,
                data.city ? <div className={classes.content}>{data.city}</div> : "-",
                data.zipCode ? <div className={classes.content}>{data.zipCode}</div> : "-",
                data.street ? <div className={classes.content}>{data.street}</div> : '-',
                data.no ? <div className={classes.content}>{data.no}</div> : '-',
                data.flat ? <div className={classes.content}>{data.flat}</div> : "-",
                data.type ? <div className={classes.content}>{data.type}</div> : "-",
            )
        })
        : null;

    return (
        <div className={styles["addressList"]}>
            <Paper className={classes.root}>
                <div className={styles["addressList__description"]}>
                    <h1 className={styles["addressList__description--title"]}>
                        Lista adresów
                    </h1>
                </div>

                {isLoading ? <ProgressLine/> :
                    <TableContainer>
                        <SimpleBar style={{
                            minHeight: props.status === "active" ? "65vh" : "60vh",
                            maxHeight: props.status === "active" ? "65vh" : "60vh"
                        }}
                                   className={classes.simplebar}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            <Fragment key={column.id}>
                                                <TableCell
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        maxWidth: column.maxWidth,
                                                    }}
                                                >
                                                    {column.query ? (
                                                        <TableSortLabel
                                                            className={classes.tableSortLabel}
                                                            hideSortIcon={true}
                                                        >
                                                            <Input
                                                                variant="outlined"
                                                                className={classes.headCell}
                                                                defaultValue={
                                                                    searchValues.find((v) => v.id === column.id)
                                                                        ?.value
                                                                }
                                                                onChange={(e) =>
                                                                    handleSearch(column.id, e.target.value)
                                                                }
                                                                onKeyDown={handleOnEnterPress}
                                                                placeholder={column.label}
                                                                endAdornment={
                                                                    <>
                                                                        <Tooltip title="Wyczyść">
                                                                                <span>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => handleClearColumn(column.id)}
                                                                                    aria-label="visible columns"
                                                                                >
                                                                                    <ClearIcon
                                                                                        className={classes.headCellArrow}
                                                                                        fontSize="small"/>
                                                                                </IconButton>
                                                                                    </span>
                                                                        </Tooltip>
                                                                        {column.sorted !== null &&
                                                                            <Tooltip title="Sortuj">
                                                                                    <span>
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            createSortHandler(column, index, rows)
                                                                                        }
                                                                                        size="small"
                                                                                        aria-label="visible columns"
                                                                                    >
                                                                                        <ShowArrow
                                                                                            sorted={column.sorted}
                                                                                            order={column.order}
                                                                                            classes={classes}
                                                                                        />
                                                                                    </IconButton>
                                                                                        </span>
                                                                            </Tooltip>}
                                                                    </>
                                                                }
                                                            />
                                                        </TableSortLabel>
                                                    ) : (
                                                        column.label
                                                    )}
                                                </TableCell>
                                            </Fragment>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {api && api.length > 0 ? (
                                    <TableBody>
                                        {rows &&
                                            rows.map((row, i) => {
                                                return (
                                                    <TableRow hover role="checkbox" key={row.id}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                           className={classes.tableCell}>
                                                                    {column.format && typeof value === "number"
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                        <TableCell className={classes.tableCell}>
                                                            <div className={styles["tableList__buttonContainer"]}>

                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                ) : <div className={styles["addressList__noData"]}>Brak danych</div>
                                }
                            </Table>
                        </SimpleBar>
                        <TablePagination
                            className={classes.pagination}
                            labelRowsPerPage={"Liczba wierszy"}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>}
            </Paper>
            {showInfoDialogCancel ? (
                <InfoDialog
                    alertReset={handleSwitchInfoDialog}
                    confirmAction={deleteAddress}
                    message="Czy na pewno chcesz usunąć ten adres?"
                />
            ) : null}
        </div>
    );
}
