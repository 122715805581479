//propsy do użycia:
//message
//alertReset = do wysłania false (zamknięcie modala), używane też przy powrocie pracownika
//confirmAction = funkcje, gdy użytkownik zaakceptuje

import React from "react";
import styles from "./infoDialog.module.scss";
import {Dialog, Button} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    root: {
        flexBasis: "100%",
        margin: "10px",
        padding: "0",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: 0,
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    title: {
        padding: 10,
        margin: 0,
    },
    backButton: {
        flexBasis: "50%",
        backgroundColor: "#575B55",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "black",
        },
    },
    confirmButton: {
        flexGrow: 1,
        marginRight: 5,
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
});

export default function InfoDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        props.alertReset(false);
    };

    const handleConfirmOrder = () => {
        props.alertReset(false);
        props.confirmAction();
    };

    return (
        <div className={styles["infoDialog"]}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <div className={styles["infoDialog__content"]}>
             <span className={styles["infoDialog__close"]} onClick={handleClose}>
            X
          </span>
                    <DialogTitle className={classes.title}>{props.message}</DialogTitle>
                    {props.notesAction && (
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Uwagi (opcjonalnie)"
                            className={classes.root}
                            onChange={props.notesAction}
                            value={props.notes}
                            multiline
                            rowsMax={3}
                            variant="outlined"
                        />
                    )}
                    <DialogActions>
                        <div className={styles["infoDialog__content--buttonWrapper"]}>
                            <Button
                                className={classes.confirmButton}
                                margin="normal"
                                onClick={handleConfirmOrder}
                            >
                                Potwierdź
                            </Button>
                            <Button
                                className={classes.backButton}
                                margin="normal"
                                onClick={handleClose}
                            >
                                Anuluj
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
