import React from "react";

import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Fill, Icon, RegularShape, Stroke, Style } from "ol/style";
import OSM from "ol/source/OSM";
import { fromLonLat, transform } from "ol/proj";
import LineString from "ol/geom/LineString";

import { NotificationContext } from "context";
import workMarker from "img/lokomotywa-pin.svg";
import driverMarker from "img/driver.svg";
import homeMarker from "img/dom-pin.svg";
import { addressType, markerColors } from "app/reusableComponents/newOrderPanel/helpers";
import config from "../../../../config";
import HttpClient from "../../../../HttpClient";

export class OrderMap extends React.Component {
    static contextType = NotificationContext;
    constructor(props) {
        super(props);
        this.state = {
            center: [19.479744, 52.068813],
            zoom: typeof props.zoom !== "undefined" ? props.zoom : 7,
            route: props.routeData,
            routeWaypoints: props.routeWaypoints,
            key: props.mapName,
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.routeData) !== JSON.stringify(this.props.routeData)) {
            if (this.props.routeData === undefined) return;
            this.map.getAllLayers().forEach((layer) => {
                if (layer.getClassName() === "ol-layer") {
                    this.map.removeLayer(layer);
                }
            });

            this.setState({
                route: this.props.routeData,
                routeWaypoints: this.props.routeWaypoints,
                start: this.props.routeWaypoints[0]?.lon
                    ? [this.props.routeWaypoints[0].lon, this.props.routeWaypoints[0].lat]
                    : null,
            });
            this.createMapContent(this.props.routeData, this.props.routeWaypoints);
        }

        if (this.props.realWaypoints?.length > 1) {
            if (this.props.realWaypoints === undefined) return;
            this.createGPSRoute(this.props.realWaypoints);
        }
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                    className: "ol-map-bg",
                }),
            ],
            target: `sm-map-${this.state.key}`,
            view: new View({
                center: fromLonLat(this.state.center),
                zoom: this.state.zoom,
            }),
        });
        this.getRoutes();
        this.createMapContent(this.props.routeData, this.props.routeWaypoints);
        if(this.props.realWaypoints?.length > 1) {
            this.createGPSRoute(this.props.realWaypoints);
        }
    }

    createGPSRoute = (waypointsArr) => {
        if (waypointsArr.length <= 1) return;
 
        this.createRoute({coordinates: waypointsArr, type: 'LineString'}, markerColors.driverColor, waypointsArr[0], 2.5)
        this.createDriverMarker(waypointsArr[0], 'driver-position')
    };

    createMapContent = (route, routeWaypoints) => {
        if (!route) return;

        if (route) {
            if (Array.isArray(route)) {
                route.forEach((innerRoute) => {
                    if (routeWaypoints.length === 0) return;

                    this.createRoute(innerRoute.geometry, markerColors.middle, [
                        routeWaypoints[0].lon,
                        routeWaypoints[0].lat,
                    ], 5);
                });
            } else {
                this.createRoute(route.geometry, markerColors.middle, [routeWaypoints[0].lon, routeWaypoints[0].lat], 5);
            }
        }

        if (routeWaypoints.length > 0) {
            const flattenArray = routeWaypoints.flat();
            this.createMarkers(flattenArray);
        }
    };

    createMarkers = (waypointsArr) => {
        const waypointFeatures = waypointsArr.map((waypoint, idx) => {
            let feature = new Feature({
                geometry: new Point(transform([waypoint.lon, waypoint.lat], "EPSG:4326", "EPSG:3857")),
            });

            let style = new Style({
                image: new Icon({
                    src: waypoint.type === addressType.WORK ? workMarker : homeMarker,
                    fill: new Fill({
                        fillColor: "#fff",
                    }),
                    anchor: [0.5, 30],
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    opacity: 0.95,
                    scale: 1.5,
                    fillColor: "#fff",
                }),
            });

            feature.setStyle(style);
            feature.setId(idx);

            return feature;
        });

        let markers = new VectorSource({
            features: waypointFeatures,
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });
        this.map.addLayer(iconVectorLayer);

        this.map.getView().fit(iconVectorLayer.getSource().getExtent());
        this.map.getView().setZoom(this.map.getView().getZoom() - 0.25);
    };

    createDriverMarker(coords, markerId) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: driverMarker,
                fill: new Fill({
                    color: '#fff',
                }),
                anchor: [0.37, 1],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 0.25,
                // width: 15,
                // height: 15,
                color: '#fff',
                fillColor: '#fff'
            }),
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });
        this.map.addLayer(iconVectorLayer);
    }


    getRoutes = async () => {
        const { courseDetails } = this.props;
        let coordsPack = "";
        for (let i = 0; i < courseDetails?.path.length; i++) {
            let prevCoords = coordsPack;
            let coords = `${i === 0 ? "" : ";"}${courseDetails.path[i].adres.lon},${courseDetails.path[i].adres.lat}`;
            coordsPack = `${prevCoords}${coords}`;
        }

        const url = `${config.osrmURL}/route/v1/driving/${coordsPack}?steps=true&geometries=geojson&overview=full`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url);
            const route = response.data.routes;
            this.props.handleCounters(route[0].distance, route[0].duration);
            const color = [42, 178, 42, 0.8]
            this.createCustomWayRoute(this.props.customRouteWaypoints, color);

        } catch (e) {}
    };

    createPoint(coords, markerId, waypointColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        const strokeStyle = new Stroke({
            color: waypointColor,
            width: 1.2,
        });

        const regularShape = new RegularShape({
            fill: new Fill({
                color: waypointColor,
            }),
            stroke: strokeStyle,
            points: 20,
            radius: 10,
        });

        let style = new Style({
            image: regularShape,
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    createLine(prevStep, singleStep, waypointColor, width) {
        let lineCoords = [prevStep, singleStep];

        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: waypointColor,
                width: width,
            }),
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);
    }

    createCustomWayRoute(waypoints, lineStyle) {

        let prevStep = this.startCoords;
        for (let j in waypoints) {
            if (j > 0) {
                prevStep = [waypoints[j - 1][0], waypoints[j - 1][1]];
                let singleStep = [waypoints[j][0], waypoints[j][1]];

                this.createLine(prevStep, singleStep, lineStyle, 3.5);
            }
        }
    }

    createRoute(routeCoordinates, waypointColor, startingPoint, width) {
        if (!routeCoordinates) return;
        routeCoordinates.coordinates.forEach((step, idx) => {
            if (idx > 0) {
                return this.createLine(routeCoordinates.coordinates[idx - 1], step, waypointColor, width);
            }
            this.createLine(startingPoint, step, waypointColor, width);
        });
    }

    render() {
        return (
            <div
                id={`sm-map-${this.state.key}`}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
