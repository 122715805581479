import React, { useState } from "react";
import styles from "./moreDetails.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { format, fromUnixTime } from "date-fns";
import { pl } from "date-fns/locale";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 10,
    },
}));

export default function MoreDetails(props) {
    const classes = useStyles();
    const { contractorDetails } = props;
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        props.switchShowModal(false);
    };

    let startTime = contractorDetails.contract?.startTime
        ? format(fromUnixTime(contractorDetails.contract?.startTime), "d.LL.yyyy, HH:mm", {
              locale: pl,
          })
        : null;
    let endTime = contractorDetails.contract?.startTime
        ? format(fromUnixTime(contractorDetails.contract?.endTime), "d.LL.yyyy, HH:mm", {
              locale: pl,
          })
        : null;

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["moreDetails__description"]}>
                            <h2 className={styles["moreDetails__description--title"]}>Szczegóły kontrahenta</h2>
                            <span className={styles["moreDetails__description--close"]} onClick={handleClose}>
                                X
                            </span>
                        </div>

                        <div className={styles["moreDetails"]}>
                            <SimpleBar style={{ maxHeight: "80vh", minWidth: 830 }} className={classes.simplebar}>
                                <div className={styles["moreDetails__inputs"]}>
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--singleColumn"]}>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Nazwa firmy:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.companyName
                                                        ? contractorDetails.contract?.companyName
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Adres:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {`${
                                                        contractorDetails.contract?.address?.street
                                                            ? contractorDetails.contract?.address?.street
                                                            : ""
                                                    } ${
                                                        contractorDetails.contract?.address?.no
                                                            ? contractorDetails.contract?.address?.no
                                                            : ""
                                                    }${
                                                        contractorDetails.contract?.address?.flat
                                                            ? `/${contractorDetails.contract?.address?.flat}`
                                                            : ""
                                                    }${
                                                        contractorDetails.contract?.address?.zipCode
                                                            ? `, ${contractorDetails.contract?.address.zipCode} `
                                                            : ""
                                                    } ${
                                                        contractorDetails.contract?.address?.city
                                                            ? contractorDetails.contract?.address?.city
                                                            : "-"
                                                    }`}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    NIP:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.NIP
                                                        ? contractorDetails.contract?.NIP
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    REGON:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.REGON
                                                        ? contractorDetails.contract?.REGON
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Osoba kontaktowa:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.contactPerson
                                                        ? contractorDetails.contract?.contactPerson
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Nr telefonu:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.contactPersonPhoneNumber
                                                        ? contractorDetails.contract?.contactPersonPhoneNumber
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Dyspozytura:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.dispatches?.length > 0
                                                        ? contractorDetails.contract?.dispatches[0]?.dispatch
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Nr telefonu:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.dispatches?.length > 0
                                                        ? contractorDetails.contract?.dispatches[0]?.dispatchPhoneNumber
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Początek umowy:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {startTime}
                                                </p>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--singleColumn--single"]}>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Koniec umowy:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {endTime}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--singleColumn"]}>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Stawka (zł)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.normalRate
                                                        ? contractorDetails.contract?.normalRate
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Rabat (%)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.isDiscount ? "TAK" : "NIE"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Limit rabatu (%)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.maxDiscount
                                                        ? contractorDetails.contract?.maxDiscount
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Postój (godz.)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.waitingRate
                                                        ? contractorDetails.contract?.waitingRate
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Liczony od: (min)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.countedFrom
                                                        ? contractorDetails.contract?.countedFrom
                                                        : "-"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Model rozliczeniowy
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.billingModel ?? "-"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info--highway"]
                                                    }
                                                >
                                                    Autostrady
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.isHighwayPay ? "TAK" : "NIE"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Autostrady z pracownikiem
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.highwayWithWorker ? "TAK" : "NIE"}
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Autostrady bez pracownika
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.highwayWithoutWorker ? "TAK" : "NIE"}
                                                </p>
                                            </div>

                                            {/*//widoczne tylko dla operatora*/}
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Kara umowna (zł)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.contractualPenalties
                                                        ? contractorDetails.contract?.contractualPenalties
                                                        : "-"}
                                                </p>
                                            </div>

                                            {/*//widoczne tylko dla operatora*/}
                                            <div
                                                className={
                                                    styles[
                                                        "moreDetails__inputs--item--singleColumn--single--settlement"
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Inne: (zł)
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.other
                                                        ? contractorDetails.contract?.other
                                                        : "-"}
                                                </p>
                                            </div>

                                            {/*//widoczne tylko dla operatora*/}
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--singleColumn--single--notes"]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--info"]
                                                    }
                                                >
                                                    Uwagi:
                                                </p>
                                                <p
                                                    className={
                                                        styles["moreDetails__inputs--item--singleColumn--single--data"]
                                                    }
                                                >
                                                    {contractorDetails.contract?.notes
                                                        ? contractorDetails.contract?.notes
                                                        : "-"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
