import React, { useState, useEffect } from "react";
import styles from "./unfillCourses.module.scss";
import { makeStyles, styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { getUnixTime, fromUnixTime, format } from "date-fns";
import ProgressLine from "../../../reusableComponents/progressLine/ProgressLine";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button } from "@material-ui/core";
import MoreDetails from "../../../reusableComponents/orders/moreDetails/MoreDetails";
import FormAlert from "../../../reusableComponents/alerts/FormAlert";
import { pl } from "date-fns/locale";
import HttpClient from "../../../../HttpClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import produce from "immer";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import _ from "lodash";
import { CombinedCourseModal } from "../../../reusableComponents/orders/laczenie/CombinedCourses";

const columnsDefinition = [
    { id: "status", label: "", minWidth: 50, sorted: null },
    { id: "time", label: "Czas", sorted: false, order: null, minWidth: 160 },
    { id: "id", label: "ID", sorted: false, order: null, minWidth: 50 },
    { id: "suggestedRelatedCourses", label: "Sugerowane powiązane kursy", sorted: null, order: null, minWidth: 100 },
    // { id: "combinedCourses", label: "Połączone kursy", sorted: null, order: null, minWidth: 100 },
    {
        id: "worker",
        label: "Pracownik",
        sorted: false,
        order: null,
        minWidth: 200,
    },
    {
        id: "pickup",
        label: "Adres odbioru",
        sorted: false,
        order: null,
        minWidth: 250,
    },
    {
        id: "destination",
        label: "Adres docelowy",
        sorted: false,
        order: null,
        minWidth: 250,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        sorted: false,
        order: null,
        minWidth: 250,
    },
    {
        id: "billingGroup",
        label: "Grupa",
        sorted: false,
        order: null,
        minWidth: 140,
    },
    {
        id: "consortiumMember",
        label: "Partner",
        sorted: false,
        order: null,
        minWidth: 140,
    },
    {
        id: "driver",
        label: "Kierowca",
        sorted: null,
        minWidth: 250,
    },
    {
        id: "details",
        label: "",
        sorted: null,
        minWidth: 80,
    },
    {
        id: "notes",
        label: "",
        sorted: null,
        minWidth: 170,
        maxWidth: 170,
    },
];

function createData(
    status,
    time,
    id,
    suggestedRelatedCourses,
    combinedCourses,
    worker,
    pickup,
    destination,
    contractor,
    consortiumMember,
    billingGroup,
    driver,
    details,
    notes
) {
    return {
        status,
        time,
        id,
        suggestedRelatedCourses,
        combinedCourses,
        worker,
        pickup,
        destination,
        contractor,
        consortiumMember,
        billingGroup,
        driver,
        details,
        notes,
    };
}

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "7px 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "bold",
            lineHeight: "revert",
        },
        "& .MuiTableCell-body": {
            padding: "5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            paddingBottom: "0",
            paddingTop: "0",
        },
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        },
    },
    confirmButton: {
        backgroundColor: "#66D01D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
    icon: {
        color: "white",
    },
    editRelatedCoursesButton: {
        padding: 0,
    },
    odrzuconeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },

    odrzuconeTitle: {
        fontWeight: "bold",
    },
    odrzuconeData: {
        fontWeight: "bold",
        color: "#ED1D1D",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 10,
        minWidth: 500,
        minHeight: 300,
    },
});

const AlertStatusIcon = styled(ErrorIcon)({
    fontSize: "35px",
    color: "#e0050d",
});

const HideStatusIcon = styled(ErrorIcon)({
    fontSize: "35px",
    color: "transparent",
});

const DriverField = styled(TextField)({
    width: 200,
    padding: 0,
    margin: "0 5px 15px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const AcceptAllButton = styled(Button)({
    backgroundColor: "#66d01d",
    marginRight: "10px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#5AB71B",
    },
});

const AutocompleteRow = ({
    data,
    drivers,
    courses,
    index,
    setCourses,
    setDriverValue,
    getBusyDrivers,
    busyDrivers,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const getData = async () => {
        if (open) {
            setLoading(true);
            try {
                await getBusyDrivers(data.id);
            } catch (e) {
            } finally {
                setLoading(false);
                setOptions(data.proposition.length > 1 ? data.proposition : drivers);
            }
        } else {
            setOptions([]);
        }
    };

    useEffect(() => {
        getData();
    }, [open]);

    const disableDriver = (option) => {
        return busyDrivers.some((driver) => driver.id === option.id);
    };

    const generateLabel = (data, showAddress = false) => {
        const address =
            data.user.adres &&
            !!data.user.adres[0] &&
            `${data.user.adres[0].street} ${data.user.adres[0].city} ${data.user.adres[0].zipCode}`;

        if (showAddress) {
            return (
                <div className={styles["unfillCourses__autoComplete"]}>
                    <span>
                        {data.name ? data.name : data.user.name ? data.user.name : "-"}{" "}
                        {data.surName ? data.surName : data.user.surName ? data.user.surName : "-"}
                    </span>
                    <span className={styles["unfillCourses__autoComplete--description"]}>
                        Stamina: {data.user.stamina ? `${data.user.stamina} godz.` : "-"}
                    </span>
                    <span className={styles["unfillCourses__autoComplete--description"]}>
                        {!!address ? address : "Brak adresu"}
                    </span>
                </div>
            );
        }
        return `${data.name ? data.name : data.user.name ? data.user.name : "-"} ${
            data.surName ? data.surName : data.user.surName ? data.user.surName : "-"
        }`;
    };

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            options={options}
            getOptionLabel={(data) => generateLabel(data)}
            renderOption={(option) => generateLabel(option, true)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionDisabled={(option) => disableDriver(option)}
            style={{ width: 250 }}
            value={data.value}
            onChange={(e, newValue) => {
                const nextCourses = produce(courses, (draftState) => {
                    draftState[index].value = newValue;
                });
                setCourses(nextCourses);
            }}
            renderInput={(params) => (
                <DriverField
                    required
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    label="Wybierz kierowcę"
                    name="taxi"
                    variant="standard"
                    margin="normal"
                    fullWidth
                />
            )}
        />
    );
};

export default function UnfillCourses() {
    const classes = useStyles();
    const [courses, setCourses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [singleCourse, setSingleCourse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [driverValue, setDriverValue] = useState([]);
    const [busyDrivers, setBusyDrivers] = useState([]);
    const [isPostedData, setIsPostedData] = useState(false);
    const [emptyDriverAlert, setEmptyDriverAlert] = useState(false);
    const [busyWorkerAlert, setBusyWorkAlert] = useState(false);
    const [columns, setColumns] = useState(columnsDefinition);
    const [selectedSuggestedCourses, setSelectedSuggestedCourses] = useState([]);
    const [isRequestPending, setIsRequestPending] = useState(false);

    const isAcceptAllButtonVisible = !!courses.find((course) => course.value);

    const [editingCourse, setEditingCourse] = useState(null);
    const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false);

    const source = axios.CancelToken.source();

    const selectSuggestedCourse = (id, selectedFromCourseId) => {
        const isSelectedCourseInList = !!selectedSuggestedCourses.find((item) => item.id === id);

        if (isSelectedCourseInList) {
            const filteredSelectedCourses = selectedSuggestedCourses.filter((item) => item.id !== id);
            setSelectedSuggestedCourses(filteredSelectedCourses);
            return;
        }

        const newSelectedCourse = {
            id,
            selectedFromCourseId,
            highlightRowEndDate: new Date(new Date().getTime() + 10000),
            isRowHighlighted: true,
        };

        setSelectedSuggestedCourses((curr) => [...curr, newSelectedCourse]);
    };

    useEffect(() => {
        const highlightCheckInterval = setInterval(() => {
            if (!selectedSuggestedCourses.length) {
                return;
            }
            const newSelectedCourses = selectedSuggestedCourses.map((item) => ({
                ...item,
                isRowHighlighted: item.highlightRowEndDate.getTime() >= new Date().getTime(),
            }));

            if (_.isEqual(newSelectedCourses, selectedSuggestedCourses)) {
                return;
            }

            setSelectedSuggestedCourses(newSelectedCourses);
        }, 1000);

        return () => clearInterval(highlightCheckInterval);
    }, [JSON.stringify(selectedSuggestedCourses)]);

    const getBusyDrivers = async (orderId) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`api/order/can-set/${orderId}`, {}, true);
            const nextBusyDrivers = [...busyDrivers, { orderId: orderId, drivers: response.data }];
            setBusyDrivers(nextBusyDrivers);
        } catch (e) {}
    };

    const ShowArrow = ({ sorted, order, classes }) => {
        if (!sorted) {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />;
        }
        if (order === "ASC") {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />;
        } else if (order === "DESC") {
            return <ArrowDownwardIcon fontSize="small" className={classes.headCellArrow} />;
        }
    };

    const createSortHandler = (_column) => {
        const newColumns = columns.map((column) => {
            if (column.sorted === null) {
                return column;
            }

            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
            return column;
        });

        setColumns(newColumns);
        handleSort(newColumns);
    };

    const sortById = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => courseA.number - courseB.number);
            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) => courseB.number - courseA.number);
        setCourses(sortedCourses);
    };

    const sortByWorker = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) =>
                courseA.worker[0].firstname.localeCompare(courseB.worker[0].firstname)
            );
            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) =>
            courseB.worker[0].firstname.localeCompare(courseA.worker[0].firstname)
        );
        setCourses(sortedCourses);
    };

    const sortByTime = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort(
                (courseA, courseB) =>
                    new Date(courseA.destinationTime * 1000) - new Date(courseB.destinationTime * 1000)
            );
            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort(
            (courseA, courseB) => new Date(courseB.destinationTime * 1000) - new Date(courseA.destinationTime * 1000)
        );
        setCourses(sortedCourses);
    };

    const sortByPickUpAddress = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => {
                const pickupAddressFromCourseA = courseA.from
                    ? `${courseA.from.street} ${courseA.from.city}`
                    : courseA.worker && courseA.worker[0]
                    ? `${courseA.worker[0].city ? courseA.worker[0].city : ""} ${
                          courseA.worker[0].street ? courseA.worker[0].street : ""
                      } ${courseA.worker[0].no ? courseA.worker[0].no : ""}${
                          courseA.worker[0].flat ? `/${courseA.worker[0].flat}` : ""
                      } ${courseA.worker[0].zipCode ? courseA.worker[0].zipCode : ""}`
                    : ``;

                const pickupAddressFromCourseB = courseB.from
                    ? `${courseB.from.street} ${courseB.from.city}`
                    : courseB.worker && courseB.worker[0]
                    ? `${courseB.worker[0].city ? courseB.worker[0].city : ""} ${
                          courseB.worker[0].street ? courseB.worker[0].street : ""
                      } ${courseB.worker[0].no ? courseB.worker[0].no : ""}${
                          courseB.worker[0].flat ? `/${courseB.worker[0].flat}` : ""
                      } ${courseB.worker[0].zipCode ? courseB.worker[0].zipCode : ""}`
                    : ``;

                return pickupAddressFromCourseA.trim().localeCompare(pickupAddressFromCourseB.trim());
            });

            setCourses(sortedCourses);
            return;
        }
        const sortedCourses = courses.sort((courseA, courseB) => {
            const pickupAddressFromCourseA = courseA.from
                ? `${courseA.from.street} ${courseA.from.city}`
                : courseA.worker && courseA.worker[0]
                ? `${courseA.worker[0].city ? courseA.worker[0].city : ""} ${
                      courseA.worker[0].street ? courseA.worker[0].street : ""
                  } ${courseA.worker[0].no ? courseA.worker[0].no : ""}${
                      courseA.worker[0].flat ? `/${courseA.worker[0].flat}` : ""
                  } ${courseA.worker[0].zipCode ? courseA.worker[0].zipCode : ""}`
                : ``;

            const pickupAddressFromCourseB = courseB.from
                ? `${courseB.from.street} ${courseB.from.city}`
                : courseB.worker && courseB.worker[0]
                ? `${courseB.worker[0].city ? courseB.worker[0].city : ""} ${
                      courseB.worker[0].street ? courseB.worker[0].street : ""
                  } ${courseB.worker[0].no ? courseB.worker[0].no : ""}${
                      courseB.worker[0].flat ? `/${courseB.worker[0].flat}` : ""
                  } ${courseB.worker[0].zipCode ? courseB.worker[0].zipCode : ""}`
                : ``;

            return pickupAddressFromCourseB.trim().localeCompare(pickupAddressFromCourseA.trim());
        });

        setCourses(sortedCourses);
    };

    const sortByDestination = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => {
                const pickupAddressFromCourseA = courseA.to
                    ? `${courseA.to.street} ${courseA.to.city}`
                    : courseA.worker && courseA.worker[0]
                    ? `${courseA.worker[0].city ? courseA.worker[0].city : ""} ${
                          courseA.worker[0].street ? courseA.worker[0].street : ""
                      } ${courseA.worker[0].no ? courseA.worker[0].no : ""}${
                          courseA.worker[0].flat ? `/${courseA.worker[0].flat}` : ""
                      } ${courseA.worker[0].zipCode ? courseA.worker[0].zipCode : ""}`
                    : ``;

                const pickupAddressFromCourseB = courseB.to
                    ? `${courseB.to.street} ${courseB.to.city}`
                    : courseB.worker && courseB.worker[0]
                    ? `${courseB.worker[0].city ? courseB.worker[0].city : ""} ${
                          courseB.worker[0].street ? courseB.worker[0].street : ""
                      } ${courseB.worker[0].no ? courseB.worker[0].no : ""}${
                          courseB.worker[0].flat ? `/${courseB.worker[0].flat}` : ""
                      } ${courseB.worker[0].zipCode ? courseB.worker[0].zipCode : ""}`
                    : ``;

                return pickupAddressFromCourseA.trim().localeCompare(pickupAddressFromCourseB.trim());
            });

            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) => {
            const pickupAddressFromCourseA = courseA.to
                ? `${courseA.to.street} ${courseA.to.city}`
                : courseA.worker && courseA.worker[0]
                ? `${courseA.worker[0].city ? courseA.worker[0].city : ""} ${
                      courseA.worker[0].street ? courseA.worker[0].street : ""
                  } ${courseA.worker[0].no ? courseA.worker[0].no : ""}${
                      courseA.worker[0].flat ? `/${courseA.worker[0].flat}` : ""
                  } ${courseA.worker[0].zipCode ? courseA.worker[0].zipCode : ""}`
                : ``;

            const pickupAddressFromCourseB = courseB.to
                ? `${courseB.to.street} ${courseB.to.city}`
                : courseB.worker && courseB.worker[0]
                ? `${courseB.worker[0].city ? courseB.worker[0].city : ""} ${
                      courseB.worker[0].street ? courseB.worker[0].street : ""
                  } ${courseB.worker[0].no ? courseB.worker[0].no : ""}${
                      courseB.worker[0].flat ? `/${courseB.worker[0].flat}` : ""
                  } ${courseB.worker[0].zipCode ? courseB.worker[0].zipCode : ""}`
                : ``;

            return pickupAddressFromCourseB.trim().localeCompare(pickupAddressFromCourseA.trim());
        });

        setCourses(sortedCourses);
    };

    const sortByContractor = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => {
                return courseA.kontrahent?.name.localeCompare(courseB.kontrahent?.name);
            });

            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) =>
            courseB.kontrahent?.name.localeCompare(courseA.kontrahent?.name)
        );

        setCourses(sortedCourses);
    };

    const sortByConsortiumMember = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => {
                if (!courseA.consortiumMemberName) {
                    return -1;
                }
                if (!courseB.consortiumMemberName) {
                    return 1;
                }

                return courseA.consortiumMemberName.localeCompare(courseB.consortiumMemberName);
            });

            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) => {
            if (!courseA.consortiumMemberName) {
                return 1;
            }

            if (!courseB.consortiumMemberName) {
                return -1;
            }

            return courseB.consortiumMemberName.localeCompare(courseA.consortiumMemberName);
        });

        setCourses(sortedCourses);
    };

    const sortByBillingGroup = (order) => {
        if (order === "ASC") {
            const sortedCourses = courses.sort((courseA, courseB) => {
                if (!courseA.driver?.billingGroup) {
                    return -1;
                }
                if (!courseB.driver?.billingGroup) {
                    return 1;
                }

                return courseA.driver.billingGroup.name.localeCompare(courseB.driver.billingGroup.name);
            });

            setCourses(sortedCourses);
            return;
        }

        const sortedCourses = courses.sort((courseA, courseB) => {
            if (!courseA.driver?.billingGroup) {
                return 1;
            }
            if (!courseB.driver?.billingGroup) {
                return -1;
            }

            return courseB.driver.billingGroup.name.localeCompare(courseA.driver.billingGroup.name);
        });

        setCourses(sortedCourses);
    };

    const sortByColumn = (column) => {
        switch (column.id) {
            case "id":
                sortById(column.order);
                break;
            case "time":
                sortByTime(column.order);
                break;
            case "worker":
                sortByWorker(column.order);
                break;
            case "pickup":
                sortByPickUpAddress(column.order);
                break;
            case "destination":
                sortByDestination(column.order);
                break;
            case "contractor":
                sortByContractor(column.order);
                break;
            case "consortiumMember":
                sortByConsortiumMember(column.order);
                break;
            case "billingGroup":
                sortByBillingGroup(column.order);
                break;
        }
    };

    const handleSort = (columns) => {
        const sortedColumn = columns.find((column) => column.sorted);

        sortByColumn(sortedColumn);
    };

    const getData = async () => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        setIsPostedData(false);

        const apiDrivers = "api/kierowca";
        const apiCourses = "api/zlecenie/unfill";

        const requestDrivers = HttpClient.createRequest();
        const requestCourses = HttpClient.createRequest();
        try {
            const driversResponse = await requestDrivers.get(apiDrivers, {}, true);
            const coursesResponse = await requestCourses.get(apiCourses, {}, true);

            for (let i = 0; i < coursesResponse.data.length; i++) {
                coursesResponse.data[i].value = null;
            }

            setDrivers(driversResponse.data.pracownicy);
            setCourses(coursesResponse.data);
            setIsLoading(false);
        } catch (e) {}
    };

    const sendData = async (data) => {
        if (isRequestPending) {
            return;
        }

        if (data.value) {
            const postData = {
                przejazdy: [
                    {
                        idKierowca: data.value.id,
                        idZlecenia: data.id,
                        notes: "ustawiono przez operatora",
                        przejazd: [],
                        status: "regular",
                    },
                ],
            };
            setIsRequestPending(true);
            const httpRequest = HttpClient.createRequest();
            try {
                await httpRequest.post("api/zlecenie/set", postData, {}, true);
                setCourses(courses.filter((course) => data.id !== course.id));
                setIsPostedData(true);
                setIsRequestPending(false);
            } catch (e) {
                setIsRequestPending(false);
            }
        } else {
            setEmptyDriverAlert(true);
            setTimeout(alertReset, 3000);
            setDriverValue([]);
        }
    };

    const acceptAllCourses = () => {
        if (isRequestPending) {
            return;
        }

        const acceptedCourses = courses.filter((course) => course.value);

        const request = {
            przejazdy: acceptedCourses.map((acceptedCourse) => ({
                idKierowca: acceptedCourse.value.id,
                idZlecenia: acceptedCourse.id,
                notes: "ustawiono przez operatora",
                przejazd: [],
                status: "regular",
            })),
        };

        const filteredCourses = courses.filter((course) => {
            const shouldBeRemoved = acceptedCourses.find((acceptedCourse) => acceptedCourse.id === course.id);
            if (!shouldBeRemoved) {
                return true;
            }
        });

        setIsRequestPending(true);

        const httpRequest = HttpClient.createRequest();
        httpRequest
            .post("api/zlecenie/set", request, {}, true)
            .then(() => {
                setCourses(filteredCourses);
            })
            .finally(() => setIsRequestPending(false));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();

        return () => {
            source.cancel();
        };
    }, []);

    const alertReset = () => {
        setShowDetails(false);
        setEmptyDriverAlert(false);
        setBusyWorkAlert(false);
    };

    const rows = courses
        ? courses.map((data, index) => {
              let currentTime = getUnixTime(new Date());
              let today = currentTime;

              const personData = data.worker.map((item, index) => {
                  return (
                      <p key={index}>
                          {item.firstname} {item.surname}
                      </p>
                  );
              });

              const personAddress = data.worker
                  ? data.worker.map((item, index) => {
                        return (
                            <p key={index}>
                                {`${item.city ? item.city : ""} ${item.street ? item.street : ""} ${
                                    item.no ? item.no : ""
                                }${item.flat ? `/${item.flat}` : ""} ${item.zipCode ? item.zipCode : ""}`}
                            </p>
                        );
                    })
                  : null;

              let destinationDate = data.destinationTime
                  ? format(fromUnixTime(data.destinationTime), "d.LL.yyyy, HH:mm", {
                        locale: pl,
                    })
                  : null;

              let iconClass1 = "";
              let iconClass2 = "";
              if (data.direction === 1) {
                  iconClass2 = "unfillCourses__table--item--time--single--toWork";
                  if (data.isWorkAddress) {
                      iconClass1 = "unfillCourses__table--item--time--single--toWork";
                  } else iconClass1 = "unfillCourses__table--item--time--single--toHome";
              } else if (data.direction === 0) {
                  iconClass1 = "unfillCourses__table--item--time--single--toWork";
                  if (data.isWorkAddress) {
                      iconClass2 = "unfillCourses__table--item--time--single--toWork";
                  } else iconClass2 = "unfillCourses__table--item--time--single--toHome";
              }

              return createData(
                  <div className={styles["unfillCourses__table--item"]}>
                      {data.notificationFailTime <= today && data.endTime >= today ? (
                          <AlertStatusIcon />
                      ) : (
                          <HideStatusIcon />
                      )}
                  </div>,
                  <div className={styles["unfillCourses__table--item"]}>
                      <div className={styles["unfillCourses__table--item--time"]}>
                          <div className={styles["unfillCourses__table--item--time--single"]}>
                              <div className={styles[iconClass1]}></div>
                              {data.isDestinationTime ? <span>---</span> : <span>{destinationDate}</span>}
                          </div>
                          <div className={styles["unfillCourses__table--item--time--single"]}>
                              <div className={styles[iconClass2]}></div>
                              {data.isDestinationTime ? <span>{destinationDate}</span> : <span>---</span>}
                          </div>
                      </div>
                  </div>,
                  <span>{data.number}</span>,
                  <span>
                      {data.relatedOrders.map((relatedOrder) => {
                          const isHighlighted = !!selectedSuggestedCourses.find(
                              (item) => item.selectedFromCourseId === data.number && item.id == relatedOrder
                          );
                          return (
                              <button
                                  onClick={() => selectSuggestedCourse(Number(relatedOrder), data.number)}
                                  style={{
                                      background: "none",
                                      border: 0,
                                      cursor: "pointer",
                                      color: isHighlighted ? "#000" : "#b4b4b4",
                                      fontSize: 12,
                                  }}
                              >
                                  {relatedOrder}
                              </button>
                          );
                      })}
                  </span>,
                  <span>
                      <Button
                          onClick={() => {
                              setIsEditCourseModalOpen(true);
                              setEditingCourse(data);
                          }}
                          className={classes.editRelatedCoursesButton}
                      >
                          Edytuj
                      </Button>
                  </span>,
                  data.worker ? (
                      <>
                          <div className={styles["active__table--item"]}>{personData}</div>
                      </>
                  ) : (
                      "-"
                  ),
                  data.from ? (
                      <div
                          className={styles["unfillCourses__table--item"]}
                      >{`${data.from.street} ${data.from.city}`}</div>
                  ) : (
                      <div className={styles["unfillCourses__table--item"]}>{personAddress}</div>
                  ),
                  data.to ? (
                      <div className={styles["unfillCourses__table--item"]}>{`${data.to.street} ${data.to.city}`}</div>
                  ) : (
                      <div className={styles["unfillCourses__table--item"]}>{personAddress}</div>
                  ),
                  data.kontrahent && (
                      <div className={styles["unfillCourses__table--item"]}>{`${data.kontrahent.name}`}</div>
                  ),
                  data.consortiumMemberName && (
                      <div className={styles["unfillCourses__table--item"]}>{`${data.consortiumMemberName}`}</div>
                  ),
                  data.driver?.billingGroup && (
                      <div className={styles["unfillCourses__table--item"]}>{`${data.driver.billingGroup.name}`}</div>
                  ),
                  <div className={styles["unfillCourses__table--item"]}>
                      <AutocompleteRow
                          data={data}
                          drivers={drivers}
                          courses={courses}
                          index={index}
                          setCourses={setCourses}
                          setDriverValue={setDriverValue}
                          getBusyDrivers={getBusyDrivers}
                          busyDrivers={busyDrivers.find((driver) => driver.orderId === data.id)?.drivers || []}
                      />
                  </div>,
                  <div className={styles["unfillCourses__table--buttonWrapper"]}>
                      <BootstrapTooltip title="Potwierdź">
                          <IconButton
                              size="small"
                              className={classes.confirmButton}
                              onClick={() => {
                                  sendData(data);
                              }}
                          >
                              <CheckIcon className={classes.icon} />
                          </IconButton>
                      </BootstrapTooltip>
                      <BootstrapTooltip title="Szczegóły">
                          <IconButton
                              size="small"
                              className={classes.iconButton}
                              type="button"
                              onClick={() => {
                                  setSingleCourse(data);
                                  setShowDetails(!showDetails);
                              }}
                          >
                              <MoreHorizIcon className={classes.icon} />
                          </IconButton>
                      </BootstrapTooltip>
                  </div>,
                  data.status === "deny" ? (
                      <div className={classes.odrzuconeContainer}>
                          <span className={classes.odrzuconeTitle}>Zlecenie odrzucone przez </span>
                          <span className={classes.odrzuconeData}>
                              {data.driver.firstname} {data.driver.surname}
                          </span>
                      </div>
                  ) : null
              );
          })
        : null;

    return (
        <>
            <div className={styles["unfillCourses"]}>
                <Paper className={classes.root}>
                    <div className={styles["unfillCourses__description"]}>
                        <h1 className={styles["unfillCourses__description--title"]}>Kursy nieobsadzone</h1>
                        <p className={styles["unfillCourses__description--subTitle"]}>
                            Wybierz kierowcę, który obsłuży zlecenie
                        </p>
                    </div>
                    {courses && courses.length > 0 ? (
                        <TableContainer>
                            <SimpleBar style={{ maxHeight: "65vh" }} className={classes.simplebar}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {column.label}
                                                        {column.sorted !== null && (
                                                            <Tooltip title="Sortuj">
                                                                <span>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            createSortHandler(column, index, rows);
                                                                        }}
                                                                        size="small"
                                                                        aria-label="visible columns"
                                                                    >
                                                                        <ShowArrow
                                                                            sorted={column.sorted}
                                                                            order={column.order}
                                                                            classes={classes}
                                                                        />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows &&
                                            rows.map((row, index) => {
                                                const rowId = row.id.props.children;
                                                const isHighlighted =
                                                    selectedSuggestedCourses.find((course) => course.id === rowId)
                                                        ?.isRowHighlighted ?? false;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        key={index}
                                                        style={isHighlighted ? { background: "#ebf3f6" } : {}}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    className={classes.tableCell}
                                                                >
                                                                    {column.format && typeof value === "number"
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </SimpleBar>
                        </TableContainer>
                    ) : isLoading ? (
                        <ProgressLine />
                    ) : (
                        <div className={styles["unfillCourses__noData"]}>Brak danych</div>
                    )}
                </Paper>
                <div className={styles["unfillCourses__footer"]}>
                    {isAcceptAllButtonVisible && (
                        <AcceptAllButton variant="contained" type="submit" onClick={acceptAllCourses}>
                            Akceptuj wszystkie
                        </AcceptAllButton>
                    )}
                </div>
                {showDetails ? (
                    <MoreDetails
                        switchShowModal={alertReset}
                        courseDetails={singleCourse}
                        courseHistory={singleCourse.history}
                        entity="operator"
                        unfillComponent={true}
                    />
                ) : null}
            </div>
            {emptyDriverAlert ? (
                <FormAlert showAlert={true} severity="error" message={"Zanim zatwierdzisz, wybierz kierowcę"} />
            ) : null}
            {busyWorkerAlert ? (
                <FormAlert showAlert={true} severity="error" message={"Ten kierowca jest już zajęty"} />
            ) : null}
            <CombinedCourseModal
                isOpen={isEditCourseModalOpen}
                onCloseClick={() => {
                    setEditingCourse(null);
                    setIsEditCourseModalOpen(false);
                }}
                drivers={drivers}
                course={editingCourse}
            />
        </>
    );
}
