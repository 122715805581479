import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Modal, List, ListItem, ListItemText, IconButton, TextField, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import styles from "./mergedOrders.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect } from "react";
import HttpClient from "../../../../HttpClient";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 10,
        minWidth: 400,
        maxWidth: 400,
        minHeight: 300,
        margin: 5,
    },
    addButton: {
        backgroundColor: "#66D01D",
        padding: 6,
        width: 36,
        height: 36,
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
        "&:disabled": {
            backgroundColor: "#66D01D",
            opacity: 0.5,
        },
    },
    addButtonIcon: {
        color: "white",
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        padding: 6,
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    deleteButtonIcon: {
        color: "white",
    },
    selectWrapper: {
        display: "flex",
        padding: `0 16px`,
    },
    select: {
        width: `100%`,
        marginRight: 10,
    },
    listText: {
        width: `100%`,
        marginRight: 10,
    },
    addMenuItem: {
        display: `block`,
    },
    addMenuItemSubtitle: {
        fontSize: `0.875rem`,
        color: `rgba(0, 0, 0, 0.54)`,
    },
    divider: {
        margin: `35px 0`,
    },
    sectionTitle: {
        fontSize: 14,
        margin: `10px 16px 0 16px`,
    },
    acceptButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end",

        marginTop: 36,
    },
    acceptButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    },
    errorMessages: {
        padding: `5px 16px`,
        color: "red",
    },
});

export const MergedOrdersModal = (props) => {
    const classes = useStyles();
    const [relatedOrdersIds, setRelatedOrdersIds] = useState([]);
    const [newOrderId, setNewOrderId] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [textFieldErrorMessage, setTextFieldErrorMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        if (!props.isOpen) {
            return;
        }
        setNewOrderId("");
        setRelatedOrdersIds(props.course.connectedOrders);
    }, [props.isOpen]);

    const onDeleteOrderButtonClick = (order) => {
        const filteredSelectedCoursesToMerge = relatedOrdersIds.filter((item) => item !== order);

        setRelatedOrdersIds(filteredSelectedCoursesToMerge);
    };

    const addOrderToSelectedToMerge = () => {
        setRelatedOrdersIds((curr) => [...curr, Number(newOrderId)]);
    };

    const onSelectAddButtonClick = () => {
        if (relatedOrdersIds.includes(Number(newOrderId))) {
            setTextFieldErrorMessage("Nie można dodać tego samego kursu");
            return;
        }

        if (Number(newOrderId) === props.course.number) {
            setTextFieldErrorMessage("Nie można powiązać kursu z samym sobą");
            return;
        }

        addOrderToSelectedToMerge();
        setNewOrderId("");
    };

    useEffect(() => {
        setErrorMessages([]);
    }, [relatedOrdersIds.length]);

    const isAddButtonDisabled = !newOrderId;

    const onAcceptButtonClick = () => {
        setIsFetching(true);

        const requestUrl = `api/connected/orders/${props.course.id}`;

        const request = HttpClient.createRequest();

        const requestData = {
            order_ids: relatedOrdersIds,
        };

        request
            .post(requestUrl, requestData, true, true)
            .then(() => {
                props.onCloseClick();
                props.onSuccess();
            })
            .catch((error) => {
                setErrorMessages(error.response.data.error);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const onTextFieldChange = (e) => {
        setTextFieldErrorMessage("");
        setNewOrderId(e.target.value);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isOpen}
            onClose={props.onCloseClick}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <div className={classes.paper}>
                <div className={styles["unfillCourses__modal__description"]}>
                    <h2 className={styles["unfillCourses__modal__description--title"]}>Edycja połączonych zleceń: {props.course?.number}</h2>
                    <span className={styles["unfillCourses__modal__description--close"]} onClick={props.onCloseClick}>
                        X
                    </span>
                </div>
                <h2 className={classes.sectionTitle}>Lista powiązanych zleceń</h2>

                <List>
                    {relatedOrdersIds.map((selectedCourseId) => {
                        return (
                            <ListItem key={selectedCourseId.orderId}>
                                <ListItemText
                                    className={classes.listText}
                                    primary={`ID Zlecenia: ${selectedCourseId}`}
                                />
                                <Tooltip title="Usuń">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        className={classes.deleteButton}
                                        onClick={() => onDeleteOrderButtonClick(selectedCourseId)}
                                    >
                                        <DeleteIcon className={classes.deleteButtonIcon} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
                <div className={classes.selectWrapper}>
                    <TextField
                        className={classes.select}
                        value={newOrderId}
                        onChange={onTextFieldChange}
                        type="number"
                        helperText={textFieldErrorMessage}
                        error={!!textFieldErrorMessage}
                    />

                    <Tooltip title="Dodaj">
                        <IconButton
                            disabled={isAddButtonDisabled}
                            edge="end"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={onSelectAddButtonClick}
                        >
                            <AddIcon className={classes.addButtonIcon} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.errorMessages}>
                    {errorMessages.map((errorMessage, index) => {
                        return (
                            <div key={index}>
                                Zlecenie {errorMessage.order_number}: {errorMessage.message}
                            </div>
                        );
                    })}
                </div>
                <div className={classes.acceptButtonWrapper}>
                    <Button
                        size="small"
                        className={classes.acceptButton}
                        variant="outlined"
                        onClick={onAcceptButtonClick}
                        disabled={isFetching || errorMessages.length}
                    >
                        {"Akceptuj"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
