import React from 'react';
import {Backdrop, Modal} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

export default function CustomModal({classes, open, onClose, children}) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={open}>
                {children}
            </Fade>
        </Modal>
    );
}