/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./tableList.module.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import EnhancedTableHead from "./EnhancedTableHead";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import WorkerDetails from "../workerDetails/WorkerDetails";
import InfoDialog from "../alerts/infoDialog/InfoDialog";
import ProgressLine from "../progressLine/ProgressLine";
import HttpClient from "../../../HttpClient";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Checkbox, FormControlLabel, styled, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// import { FlexRow } from "../newOrderPanel/styled"; //TODO add when merged with new form branch
import { StyledAutocomplete } from "../orderPanel/orderLine/OrderLine";
// import { ListBox, StyledPopper } from "../newOrderPanel/virtualization/ListBox"; //TODO add when merged with new form branch

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} children={props.children} />;
}

const CheckboxStyled = withStyles({
    root: {
        marginLeft: "10px",
        color: "#1dbbed",
        "&$checked": {
            color: "#1dbbed",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SelectWrapper = styled("div")({
    width: "240px",
    padding: "10px",
});

const FlexRowTemp = styled("div")((props) => ({
    display: "flex",
    margin: props.margin ? props.margin : null,
    flexFlow: props.flow ? props.flow : "row",
    gap: props.gap ? props.gap : ".2rem",
    alignItems: props.align ? props.align : "center",
    justifyContent: props.justify ? props.justify : "flex-start",
    flexDirection: props.direction ? props.direction : "row",
    position: "relative",

    "& .MuiAutocomplete-input": {
        maxWidth: "calc(100% - 55px)",
        textOverflow: "ellipsis",
    },
}));

const useBodyTable = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 20px 0 10px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTableCell-body": {
            padding: "2px 20px 2px 10px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
    },
    tableCell: {
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        },
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    icon: {
        color: "white",
    },
});

export default function TableList({ headCells, url, label, mode }) {
    const [rows, setRows] = useState([]);
    const history = useHistory();
    const [errorState, setError] = useState({ error: "" });
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [editedRow, setEditedRow] = useState([]);
    const [api, setApi] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [index, setIndex] = useState(null);
    const [searchValues, setSearchValues] = useState([]);
    const [count, setCount] = useState(0);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [deletedUserIndex, setDeletedUserIndex] = useState(null);
    const [allAddresses, setAllAddresses] = useState([]);
    const [showIsHired, setShowIsHired] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState({});
    const source = axios.CancelToken.source();
    const classes = useBodyTable();

    // ustawianie tablicy dla checkboxow
    const [columns, setColumns] = useState(headCells);

    const handleEditedRow = (userId) => () => {
        const user = api.find((user) => user.id === userId);

        const target = window.location.pathname;
        history.push(`${target.substring(0, target.lastIndexOf("/"))}/edytuj/${user.id}`);
    };

    const handleOnEnterPress = (e) => {
        if (e.key === "Enter") {
            getData();
        }
    };

    const switchShowModal = () => {
        setShowDetails(false);
    };

    const switchInfoDialog = (value) => {
        setShowInfoDialog(value);
    };

    const handleClearColumn = (field) => {
        handleSearch(field, null);
        setClearColumn(!invokeClearColumn);
    };

    useEffect(() => {
        const fetchAddresses = async () => {
            const httpRequest = HttpClient.createRequest();

            const allAddresses = await httpRequest.get("api/addresses-all", {}, true);
            setAllAddresses(allAddresses.data.data);
        };

        fetchAddresses();
    }, []);

    // useEffect(() => {
    //     if(!showIsHired) return
    //     const newRows = rows.reduce((acc,curr) => curr.isHired ? [...acc,curr] : acc, [])

    //     setRows(newRows)

    // }, [showIsHired])

    const degreeToRadians = (deg) => (deg * Math.PI) / 180.0;

    const sortByDistance = (a, b) => Number(a.distance.split(" ")[0]) - Number(b.distance.split(" ")[0]);

    const calculateDistance = (workerLat, workerLon) => {
        if (selectedAddress === null) return 0;
        const LATP = degreeToRadians(selectedAddress.lat);
        const LONP = degreeToRadians(selectedAddress.lon);
        const LATK = degreeToRadians(workerLat);
        const LONK = degreeToRadians(workerLon);

        const distance = Math.floor(
            Math.acos(Math.sin(LATK) * Math.sin(LATP) + Math.cos(LATK) * Math.cos(LATP) * Math.cos(LONP - LONK)) * 6371
        );

        return distance;
    };

    useEffect(() => {
        if (rows.length === 0) return;

        const newRows = rows.reduce((acc, row) => {
            const distance = calculateDistance(row.lat, row.lon);
            return [...acc, { ...row, distance: `${distance} ${distance !== 0 ? "km." : ""}` }];
        }, []);

        setRows(newRows.sort(sortByDistance));
    }, [selectedAddress]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();

        return () => {
            source.cancel();
        };
    }, [invokeClearColumn, rowsPerPage, page]);

    const getData = async () => {
        setIsLoading(true);
        const getParam = [...searchValues]
            .filter((s) => s.value !== null)
            .map((s) => s.id + "=" + s.value + "&")
            .join("");

        const sortField = columns.filter((item) => item.sorted === true);

        const httpRequest = HttpClient.createRequest();

        try {
            const driverUrl = `${url}?${getParam}orderBy=${
                sortField[0].id === "distance" ? "name" : sortField[0].id
            }&order=${sortField[0].id === "distance" ? "ASC" : sortField[0].order}`;

            const workerUrl = `${url}?${getParam}orderBy=${sortField[0].id}&order=${
                sortField[0].order
            }&limit=${rowsPerPage}&page=${page + 1}`;

            const requestUrl = mode === "worker" ? workerUrl : driverUrl;

            const response = await httpRequest.get(requestUrl, { cancelToken: source.token }, true);
            const { data } = response;

            if (response.status) {
                const userData = [];
                const rowsToEdit = [];

                if (!data.pracownicy) {
                    setApi([]);
                    setCount(data.totalCount);
                    setRows([]);
                    setIsLoading(false);
                    return;
                }

                if (mode === "driver") {
                    console.log("driver");
                    data.pracownicy.map((item, index) => {
                        const distance = item.user.adres[0]
                            ? calculateDistance(item.user.adres[0].lat, item.user.adres[0].lon)
                            : "-----";
                        const obj = {
                            name: item.user.name,
                            sur_name: item.user.surName,
                            companyName: item.companyName,
                            work_phone: item.user.workPhoneNumber,
                            private_phone_number: item.user.privatePhoneNumber,
                            email: item.user.email,
                            city: typeof item.user.adres[0] !== "undefined" ? item.user.adres[0].city : "",
                            stamina: item.user.stamina ? `${item.user.stamina} godz.` : "",
                            distance: selectedAddress.lon && selectedAddress.lat ? `${distance} km.` : 0,
                            isHired: item.user.isHired,
                            lon: typeof item.user.adres[0] !== "undefined" ? item.user.adres[0].lon : null,
                            lat: typeof item.user.adres[0] !== "undefined" ? item.user.adres[0].lat : null,
                            id: item.id,
                        };

                        if (!item.companyName) delete obj.companyName;

                        userData.push(obj);

                        rowsToEdit.push({
                            id: item.id,
                            columns: obj,
                            value: item,
                            rowIndex: index,
                        });
                    });

                    if (selectedAddress.lon && selectedAddress.lat) {
                        userData.sort(sortByDistance);
                    }

                    if (sortField[0].id === "distance") {
                        if (!selectedAddress?.lon && !selectedAddress?.lat) return;

                        if (sortField[0].order === "DESC") {
                            userData.sort(sortByDistance);
                        } else {
                            userData.sort(
                                (a, b) => Number(b.distance.split(" ")[0]) - Number(a.distance.split(" ")[0])
                            );
                        }
                    }
                } else {
                    data.pracownicy.map((item, index) => {
                        const obj = {
                            name: item.user.name,
                            sur_name: item.user.surName,
                            companyName: item.companyName,
                            work_phone: item.user.workPhoneNumber,
                            email: item.user.email,
                            city: typeof item.user.adres[0] !== "undefined" ? item.user.adres[0].city : "",
                            stamina: item.user.stamina ? `${item.user.stamina} godz.` : "",
                            isHired: true,
                            id: item.id,
                        };

                        if (!item.companyName) delete obj.companyName;

                        userData.push(obj);

                        rowsToEdit.push({
                            id: item.id,
                            columns: obj,
                            value: item,
                            rowIndex: index,
                        });
                    });
                }

                setCount(data.totalCount);
                setRows(userData);
                setApi(response.data.pracownicy);
                setIsLoading(false);
            } else {
                setError({ ...errorState, error: data.error });
            }
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    const deleteData = async () => {
        let workerId = editedRow[deletedUserIndex].value.user.id;
        const httpRequest = HttpClient.createRequest();

        try {
            await httpRequest.delete(`${url}/${workerId}`, { cancelToken: source.token }, true);
            const _rows = [...rows];
            const _editedRow = editedRow.filter((item, index) => {
                if (item.value.user.id === workerId) {
                    _rows.splice(index, 1);
                }
                return item.value.user.id !== workerId;
            });
            setRows(_rows);
            setEditedRow(_editedRow);
        } catch (e) {}
    };

    //Callback do komponentu EnhancedTableHead
    const handleSort = () => {
        setPage(0);
        getData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const emptyRows =
    //     rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        setSearchValues([...filterObject, { id: field, value: query ? query.trim() : "" }]);
    };

    const handleShowDetails = (id) => {
        console.log(api);
        console.log(id);
        const findUserIndex = api.findIndex((user) => user.id === id);
        setIndex(findUserIndex);
        setShowDetails(!showDetails);
    };

    return (
        <div className={styles["tableList"]}>
            {isLoading ? (
                <ProgressLine />
            ) : (
                <Paper className={classes.root}>
                    <FlexRowTemp justify="space-between" align="start">
                        <div>
                            <div className={styles["tableList__description"]}>
                                <h1 className={styles["tableList__description--title"]}>{label}</h1>
                                <p className={styles["tableList__description--subTitle"]}>
                                    Wyszukaj pracownika po wybranym parametrze lub przejrzyj wszystkich
                                </p>
                            </div>
                            {mode === "driver" && (
                                <FormControlLabel
                                    control={
                                        <CheckboxStyled
                                            value={showIsHired}
                                            checked={showIsHired}
                                            onChange={(e, checked) => setShowIsHired(checked)}
                                        />
                                    }
                                    label="Wyklucz nieaktywnych"
                                />
                            )}
                        </div>
                        {mode === "driver" && (
                            <SelectWrapper>
                                <StyledAutocomplete
                                    onChange={(_, newValue) => setSelectedAddress(newValue)}
                                    options={allAddresses}
                                    // id="virtualize-demo"
                                    // disableListWrap
                                    // PopperComponent={StyledPopper}
                                    // ListboxComponent={ListBox}
                                    getOptionSelected={(address, value) => address?.id === value?.id}
                                    getOptionLabel={(address) => (address.city ? `${address?.city}` : "")}
                                    value={selectedAddress}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Wybierz adres"
                                            typ="text"
                                            margin="none"
                                            autocomplete="off"
                                            fullWidth
                                        />
                                    )}
                                />
                            </SelectWrapper>
                        )}
                    </FlexRowTemp>
                    <TableContainer>
                        <SimpleBar style={{ minHeight: "60vh", maxHeight: "60vh" }} className={classes.simplebar}>
                            <Table stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    headCells={columns}
                                    handleOnEnterPress={handleOnEnterPress}
                                    handleClearColumn={handleClearColumn}
                                    rowCount={count}
                                    sortById={handleSort}
                                    rows={rows}
                                    searchHandler={handleSearch}
                                    searchValues={searchValues}
                                />

                                <TableBody>
                                    {rows
                                        .filter((item) => (showIsHired ? item.isHired : true))
                                        .map((row, rowIndex) => {
                                            const isRowGrayedOut = row.isHired === false;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={rowIndex}
                                                    style={
                                                        isRowGrayedOut
                                                            ? { opacity: 0.7, backgroundColor: "#e0e0e0" }
                                                            : {}
                                                    }
                                                >
                                                    {Object.values(row).map((value, valueIndex) => {
                                                        if (valueIndex >= Object.values(row).length - 3) return null; //nie wyświetlanie dwóch ostatnich elementów tj. lon i lat
                                                        return (
                                                            <TableCell key={valueIndex} className={classes.tableCell}>
                                                                {value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    <TableCell className={classes.tableCell}>
                                                        <div className={styles["tableList__buttonContainer"]}>
                                                            <BootstrapTooltip title="Edycja">
                                                                <IconButton
                                                                    size="small"
                                                                    className={classes.iconButton}
                                                                    onClick={handleEditedRow(row.id)}
                                                                >
                                                                    <EditIcon className={classes.icon} />
                                                                </IconButton>
                                                            </BootstrapTooltip>
                                                            <BootstrapTooltip title="Szczegóły">
                                                                <IconButton
                                                                    size="small"
                                                                    className={classes.iconButton}
                                                                    onClick={() => handleShowDetails(row.id)}
                                                                >
                                                                    <MoreHorizIcon className={classes.icon} />
                                                                </IconButton>
                                                            </BootstrapTooltip>

                                                            {/*
                                                    TODO: Na chwile obecna uzytkownik nie moze usuwac pracownikow,
                                                        aby bylo to mozliwe, trzeba zrobic softdelete na backendzie
                                                    */}

                                                            {/*<BootstrapTooltip title="Usuń">*/}
                                                            {/*    <IconButton*/}
                                                            {/*        size="small"*/}
                                                            {/*        className={classes.deleteButton}*/}
                                                            {/*        onClick={() => {*/}
                                                            {/*            setDeletedUserIndex(rowIndex);*/}
                                                            {/*            switchInfoDialog(true);*/}
                                                            {/*        }}*/}
                                                            {/*    >*/}
                                                            {/*        <ClearIcon className={classes.icon}/>*/}
                                                            {/*    </IconButton>*/}
                                                            {/*</BootstrapTooltip>*/}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </SimpleBar>
                        {mode === "worker" && (
                            <TablePagination
                                className={classes.pagination}
                                labelRowsPerPage={"Liczba wierszy"}
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        )}
                    </TableContainer>
                </Paper>
            )}
            {showDetails ? (
                <WorkerDetails
                    switchShowModal={switchShowModal}
                    workerId={api[index].id ? api[index].id : "-"}
                    workerFirstname={api[index].user.name ? api[index].user.name : "-"}
                    workerSurname={api[index].user.surName ? api[index].user.surName : "-"}
                    workerWorkPhone={api[index].user.workPhoneNumber ? api[index].user.workPhoneNumber : "-"}
                    workerPrivatePhone={api[index].user.privatePhoneNumber ? api[index].user.privatePhoneNumber : "-"}
                    workerEmail={api[index].user.workEmail ? api[index].user.workEmail : "-"}
                    workerAddresses={api[index].user.adres.length > 0 ? api[index].user.adres : ""}
                />
            ) : null}
            {showInfoDialog ? (
                <InfoDialog
                    alertReset={switchInfoDialog}
                    confirmAction={deleteData}
                    message="Czy na pewno chcesz usunąć tego pracownika?"
                />
            ) : null}
        </div>
    );
}
