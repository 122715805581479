import React, { useContext } from "react";

import TransportRoute from "./transport/TransportRoute";
import PracownicyRoute from "./pracownicy/PracownicyRoute";
import KilometrowkiRoute from "./kilometrowki/KilometrowkiRoute";
import { MimicContext } from "../../context";
import AdresyRoute from "./adresy/AdresyRoute";
import DodajRouteNew from "./transport/dodaj/DodajRouteNew";

const DyspozytorRoute = () => {
  const { roles } = useContext(MimicContext);

  const isDyspozytor = roles.includes('ROLE_DYSPOZYTOR') || roles.includes('ROLE_SUPER_ADMIN');
  const hasStatementAccess = roles.includes('ROLE_KILOMETROWKA') || roles.includes('ROLE_KILOMETROWKA_ZAPIS') || roles.includes('ROLE_SUPER_ADMIN');


  return (
    <>
      {isDyspozytor && <TransportRoute app="dyspozytor" /> }
      {isDyspozytor && <PracownicyRoute app="dyspozytor" /> }
      {isDyspozytor && <AdresyRoute />}
      {isDyspozytor && <DodajRouteNew app="dyspozytor" window="trasy" tab="dodaj" entity="dyspozytor"/>}
      {hasStatementAccess && <KilometrowkiRoute /> }
    </>
  );
};

export default DyspozytorRoute;
