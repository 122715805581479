import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import styles from "./importOrders.module.scss";
import GetAppIcon from '@material-ui/icons/GetApp';
import HttpClient from "../../../../HttpClient";
import ProgressLine from "../../../reusableComponents/progressLine/ProgressLine";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function ImportOrders() {
    const [dataError, setDataError] = useState(false);
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isPostedData, setIsPostedData] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
    const history = useHistory();

    const handleFileUpload = async (e) => {
        setIsLoading(true);
        setIsPostedData(false);
        setUploadError(false);

        const file = e.target.files[0];
        let formData = new FormData();
        formData.append("csv", file);

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.post('api/import-csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }, true);
            setUploadError(false);
            setIsPostedData(true);
            setIsLoading(false);
            setDataError(response.data.error);
            setMessage(response.data.message);
        } catch (e) {
            setIsLoading(false);
            setUploadError(true);
            setUploadErrorMessage(e.response.data.message);
        }
    }

    return (
        <div className={styles["importOrders"]}>
            <div className={styles["importOrders__description"]}>
                <h1 className={styles["importOrders__description--title"]}>
                    Import zleceń z pliku CSV
                </h1>
            </div>
            {isLoading ? null :
                <label className={styles["importOrders__upload"]}>
                    <input
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onChange={handleFileUpload}
                    />
                    <GetAppIcon size="normal" style={{marginRight: "5px"}}/>
                    Kliknij i wybierz plik ze swojego dysku.
                </label>
            }

            {isLoading ? <ProgressLine/> :
                dataError && message ?
                    <div className={styles["importOrders__response"]}>
                        {typeof (message) === "string" ?
                            <p className={styles["importOrders__response--title"]}>
                                <ErrorOutlineIcon size="normal" style={{marginRight: "5px"}}/>
                                {message}
                            </p>
                            :
                            <>
                                <p className={styles["importOrders__response--title"]}>
                                    <ErrorOutlineIcon size="normal" style={{marginRight: "5px"}}/>
                                    Popraw dane w pliku CSV i wgraj go jeszcze raz
                                </p>
                                {message.map((item, index) => {
                                        return (
                                            <p key={index}
                                               className={styles["importOrders__response--info"]}>
                                                ID {item.groupOrder}
                                                <span
                                                    className={styles["importOrders__response--data"]}>{` (${item.error})`}
                                                </span>
                                            </p>
                                        )
                                    }
                                )
                                }
                            </>
                        }
                    </div>
                    : isPostedData ?
                    <div className={styles["importOrders__response"]}>
                        <p className={styles["importOrders__response--success"]}>
                            <CheckCircleOutlineIcon size="normal" style={{marginRight: "5px"}}/>
                            Zamówienia zostały prawidłowo przesłane na serwer
                            <span className={styles["importOrders__response--success--button"]}
                                  onClick={() => history.push('/app/dyspozytor/transport/aktywne')}>
                               (Przejdź do listy kursów)
                            </span>
                        </p>
                    </div>
                    : uploadError ?
                        <div className={styles["importOrders__response"]}>
                            <p className={styles["importOrders__response--title"]}>
                                <ErrorOutlineIcon size="normal" style={{marginRight: "5px"}}/>
                                {uploadErrorMessage}
                            </p>
                        </div>
                        : null}
        </div>
    );
}
;
