import React, {Fragment} from "react";
import Urlopy from "../../../reusableComponents/urlopy/Urlopy";
import AppRoute from "../../../../AppRoute";

const UrlopyRoute = () => {
    return (

        <AppRoute
            app="operator"
            window="kierowcy"
            tab="urlopy"
            component={Urlopy}
        />

    );
};

export default UrlopyRoute;
