import styles from "./formDistance.module.scss";
import React from "react";
import {TextField} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const SingleInput = styled(TextField)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormDistance extends React.Component {

    shouldComponentUpdate(nextProps) {
        return this.props.manualKm !== nextProps.manualKm ||
            this.props.isProposition !== nextProps.isProposition ||
            this.props.mapKm.value !== nextProps.mapKm.value ||
            this.props.orderNo.value !== nextProps.orderNo.value
    }

    render() {
        return (
            <div className={styles["formDistance"]}>
                <div className={styles["formDistance__input"]}>
                    <SingleInput
                        label="Kilometry"
                        error={
                            this.props.manualKm.empty && !this.props.isProposition
                        }
                        helperText={
                            (this.props.manualKm.empty && !this.props.isProposition) ? "Uzupełnij dane" : false
                        }
                        required={
                            !this.props.isProposition
                        }
                        fullWidth
                        value={this.props.manualKm.value}
                        variant="standard"
                        onChange={(e) => {
                            const regExp = /^\d{0,10}(\.\d{0,2})?$/;
                            if(e.target.value <= 9999 && regExp.test(e.target.value)) {
                                this.props.setOrderData(
                                    this.props.groupIndex,
                                    this.props.index,
                                    "manualKm",
                                    e.target.value,
                                    this.props.handleValidate
                                );
                            }
                        }}
                    />
                    <p
                        className={styles["formDistance__input--link"]}
                        onClick={() => {
                            this.props.setOrderData(
                                this.props.groupIndex,
                                this.props.index,
                                "manualKm",
                                this.props.mapKm.value
                            );
                        }}
                    >
                        Przywróć km z mapy
                    </p>
                </div>
                <div className={styles["formDistance__input"]}>
                    <SingleInput
                        label="Nr zlecenia"
                        fullWidth
                        value={this.props.orderNo.value}
                        variant="standard"
                        onChange={(e) => {
                            this.props.setOrderData(
                                this.props.groupIndex,
                                this.props.index,
                                "orderNo",
                                e.target.value
                            );
                        }}
                    />
                </div>
            </div>
        );
    }
}
