import React from "react";
import OrderPanel from "../../../reusableComponents/newOrderPanel/OrderPanel";
import AppRoute from "../../../../AppRoute";
import {useHistory} from 'react-router-dom';

const DodajRouteNew = ({app, window, tab, entity}) => {

  const OrderPanelDispatch = (props) => <OrderPanel entity={entity} {...props} />

  return (
    <AppRoute
      entity={entity}
      app={app}
      window={window}
      tab={tab}
      component={OrderPanelDispatch}
    />
  );
};

export default DodajRouteNew;

