import React, {useContext, useState} from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import { GeneratePdf,} from "./GeneratePdf";
import {downloadFormatData, downloadPdfData} from "./downloadFormatData";
import {downloadCsv} from "./downloadCsv";
import {MimicContext} from "../../../context";

const useStyles = makeStyles({
  downloadButton: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    background: "#1DBBED",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.2s ease",
    border: "none",
    outline: "none",
  },
  downloadButtonDownloaded: {
    background: "#66D01D",
  },
  downloadButtonIcon: {
    color: "#fff",
    height: "90%",
  },
});

export const DownloadPdfButton = ({ type, delegationId, wasDownloaded, isDownloadingAll, extraDownloadHandler, startDate, endDate }) => {
  const classes = useStyles();
  const [documentData, setDocumentData] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const {addFlash} = useContext(MimicContext);


  const handleDownload = async () => {
    addFlash('Trwa pobieranie delegacji', {
      variant: 'success',
      preventDuplicate: true,
      key: 'downloadDelegation'
    })
    setIsDownloading(true);
    const formatData = await downloadFormatData(type, [delegationId]);
    if(type === 'pdf') {
      let data;
      if (!documentData) {
        try {
          data = formatData[0];
          setDocumentData(data);
        } catch {
        }
      } else {
        data = documentData;
      }
      const blob = await pdf(<GeneratePdf pdfData={data}/>).toBlob();
      saveAs(blob, `${data.worker} ${data.delegationNumber}`);
    } else if(type === 'csv') {
      downloadCsv(formatData, startDate, endDate);
    }
    extraDownloadHandler(delegationId);
    setIsDownloading(false);
  };

  return (
    <button
      onClick={handleDownload}
      type="button"
      disabled={isDownloading || isDownloadingAll}
      className={`${classes.downloadButton} ${
        wasDownloaded ? classes.downloadButtonDownloaded : ""
      }`}
    >
      <GetAppIcon className={classes.downloadButtonIcon} />
    </button>
  );
};

DownloadPdfButton.propTypes = {
  delegationId: PropTypes.string.isRequired,
  isDownloadingAll: PropTypes.bool.isRequired
};
