/* eslint-disable no-new-func */
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { v4 as uuid } from "uuid";

import {baseOrder, filterOptionTypes, orderType, getAddressesCords, baseWorker} from ".";
import config from "config";
import HttpClient from "HttpClient";

const customFilterOptions = (opt, params, type) => {
    const filter = createFilterOptions();
    let filtered = filter(opt, params);

    filtered.unshift({
        inputValue: type === filterOptionTypes.WORKER ? "add-worker" : "add-address",
        name: `DODAJ`,
        id: type === filterOptionTypes.WORKER ? "add-worker" : "add-address",
        add: true,
        [type === filterOptionTypes.WORKER ? "surName" : "city"]:
            type === filterOptionTypes.WORKER ? "PRACOWNIKA" : "DODAJ ADRES",
    });

    return filtered;
};

const orderReturn = (currentOrder, relatedOrderName, orderIndex) => {
    const orderName = `Kurs powrotny do
    zamówienia nr ${orderIndex}`;
    const updatedTimeInWorkers = currentOrder.workers.map((worker) => {
        return {
            ...worker,
            timeOrigin: worker.timeOrigin ? new Date(new Date(worker.timeOrigin).getTime() + 12 * 60 * 60000) : null,
        };
    });
    return {
        ...baseOrder,
        ...currentOrder,
        workers: updatedTimeInWorkers,
        uuid: uuid(),
        hasReturnOrderShift: false,
        hasReturnOrder: false,
        type: orderType.ORDER_RETURN,
        relatedOrderName,
        orderName,
        distance: 0,
        rideTime: 0,
        isChangedDirection: true,
        timeDestination: currentOrder.timeDestination
            ? new Date(new Date(currentOrder.timeDestination).getTime() + 12 * 60 * 60000)
            : null, // Hours * minutes * seconds * milliseconds
    };
};
const orderShiftReturn = (currentOrder, relatedOrderName, orderIndex) => {
    const orderName = `odwiezienie poprzedniej zmiany do zamówienia nr ${orderIndex}`;
    return {
        ...baseOrder,
        ...currentOrder,
        workers: [baseWorker],
        destination: currentOrder.destination,
        uuid: uuid(),
        hasReturnOrderShift: false,
        hasReturnOrder: false,
        type: orderType.ORDER_SHIFT_RETURN,
        relatedOrderName,
        orderName,
        distance: 0,
        rideTime: 0,
        isChangedDirection: true,
        timeDestination: currentOrder.timeDestination
            ? new Date(new Date(currentOrder.timeDestination).getTime() + 5 * 60000)
            : null,
    };
};

// check if destination exists and set it to beginning or end depending on isChangedDirection (DOM)
const sortAddresses = (currentOrder) => {
    const formattedAddresses = currentOrder.workers.reduce(sortAddressesReduce, []);

    if (currentOrder.destination) {
        currentOrder.isChangedDirection
            ? formattedAddresses.unshift({ ...currentOrder.destination, timeOrigin: currentOrder.timeDestination })
            : formattedAddresses.push({ ...currentOrder.destination, timeOrigin: currentOrder.timeDestination });
    }
    return formattedAddresses;
};

const sortAddressesReduce = (acc, worker) => {
    //picking correct worker address from form state
    if (worker.isWorkAddress && worker.workAddress) {
        return [...acc, { ...worker.workAddress, timeOrigin: worker.timeOrigin }];
    }

    if (!worker.isWorkAddress && worker.homeAddress) {
        return [...acc, { ...worker.homeAddress, timeOrigin: worker.timeOrigin }];
    }

    return acc;
};

const formatDataForBuilder = (addresses) => {
    // Obj with workers[] of addresses, and station object
    const reducedWorker = addresses.workers.reduce(
        (acc, curr) => [
            ...acc,
            {
                id: curr.id,
                lon: curr.lon,
                lat: curr.lat,
                time: curr.timeOrigin,
            },
        ],
        []
    );
    const worker = {
        id: addresses.station.id,
        lon: addresses.station.lon,
        lat: addresses.station.lat,
        time: addresses.station.timeOrigin,
    };
    const reducedWork = [];

    if (worker.id) {
        reducedWork.push(worker);
    }
    return {
        reducedWorker,
        reducedWork,
    };
};

const formatOrder = async (addresses, stagedAddresses) => {
    const httpRequest = HttpClient.createRequest();
    if (!stagedAddresses) return null;

    const urlWithCords = getAddressesCords(stagedAddresses);

    const url = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson&continue_straight=true`;
    const { data: osmrRoute } = await httpRequest.get(url);

    const formattedRouteDate = {
        distance: osmrRoute.routes[0].distance, //in km
        duration: osmrRoute.routes[0].duration, //in seconds
        geometry: osmrRoute.routes[0].geometry, //coordinates needed to draw route on map
        waypoints: osmrRoute.routes[0].legs, // data between two point
    };

    const helperAddresses = [...addresses];
    const extendedStagedAddress = stagedAddresses.reduce((acc, currAddress) => {
        const findCorrespondingIndex = helperAddresses.findIndex((elem) => elem.id === currAddress.address_id);
        const searchedElem = helperAddresses[findCorrespondingIndex];

        helperAddresses.splice(findCorrespondingIndex, 1);
        return [
            ...acc,
            {
                ...searchedElem,
                lat: currAddress.lat,
                lon: currAddress.lon,
                km: currAddress.km,
                time: currAddress.time,
                id: currAddress.address_id,
            },
        ];
    }, []);

    const formattedData = extendedStagedAddress.reduce(
        (acc, currentAddress, indexOfCurrentAddress) => {
            const totalDistance = stagedAddresses.reduce((acc, current) => Math.floor(current.km * 1000 + acc), 0);
            const totalTime = stagedAddresses[stagedAddresses.length - 1].time - stagedAddresses[0].time;

            return {
                ...acc,
                locations: [
                    ...acc.locations,
                    {
                        lon: currentAddress.lon,
                        lat: currentAddress.lat,
                        id: currentAddress.id,
                        name: currentAddress.city,
                        street: currentAddress.street,
                        startEstimatedDate: currentAddress.timeOrigin ? new Date(currentAddress.timeOrigin) : null,
                        startOrderedDate: new Date(stagedAddresses[indexOfCurrentAddress].time * 1000),
                        distance: stagedAddresses[indexOfCurrentAddress].km * 1000,
                        type: currentAddress.type,
                    },
                ],
                totalTime: totalTime, // in seconds
                totalaDistance: totalDistance,
            };
        },
        {
            locations: [],
            totalTime: 0,
            totalaDistance: 0,
            routeData: formattedRouteDate,
        }
    );

    return formattedData;
};

export {
    sortAddressesReduce,
    formatOrder,
    orderShiftReturn,
    orderReturn,
    customFilterOptions,
    formatDataForBuilder,
    sortAddresses,
};
