import {setSeconds, getUnixTime} from 'date-fns';

/**
 * @param {Date|number} date
 * @param {boolean} timestamp
 * @returns {Date|number}
 */
export default function clearSeconds(date, timestamp = false) {
    const newDate = setSeconds(date, 0);
    if(timestamp) return getUnixTime(newDate);
    return newDate;
}
