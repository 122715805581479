import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {Fab} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import RenderRow from "./RenderRow";

export default function RenderList(props) {
    const { classes, open, toggleOpen } = props;
    return (
        <div className={classes.root}>
            <div className={classes.asideMenu}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemSize={46}
                            itemCount={1}
                            itemData={props}
                        >
                            {RenderRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </div>

            <Fab
                className={classes.expandButton}
                variant="round"
                size="small"
                onClick={props.toggleOpen}
            >
                <ArrowBackIcon className={open ? classes.arrowRight : classes.arrowLeft} />
            </Fab>
        </div>
    );
}