import React from "react";
import styles from './css/messageInfo.module.scss'
import {format} from "date-fns";
import {pl} from "date-fns/locale";

function MessageInfo(props) {
    const date = new Date(props.data.createTime.date)

    return (
        <div className={props.systemMessage ? `${styles.systemMessage} ${styles[props.systemMessageType]}` : styles.messageInfo}>
            {/* {!props.systemMessage ?? format(date, "dd.LL, HH:mm", {locale: pl})} */}
            {format(date, "dd.LL.yyyy, HH:mm", {locale: pl})}
            <div>
                {props.systemMessage ?? props.data.author}
            </div>
        </div>
    )
}

export default MessageInfo