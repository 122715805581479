import React from "react";
import ProfileForm from "./ProfileForm";
import axios from "axios";
import {NotificationContext} from "../../../context";
import HttpClient from "../../../HttpClient";

export default class Profile extends React.Component {
    static contextType = NotificationContext;
    source = axios.CancelToken.source();

    buildEmptyProfile = () => ({
        name: "",
        surName: "",
        privatePhoneNumber: "",
        workEmail: "",
        workPhoneNumber: "",
        email: "",
    });
    state = {
        profile: this.buildEmptyProfile()
    };

    getUserData = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get('api/account/user', {cancelToken: this.source.token}, true);
            const { data } = response;
            const profile = {...this.state.profile};

            profile.name = data.name === null ? '' : data.name;
            profile.surName = data.surName === null ? '' : data.surName;
            profile.email = data.email === null ? '' : data.email;
            profile.workEmail = data.workEmail === null ? '' : data.workEmail;
            profile.privatePhoneNumber = data.privatePhoneNumber === null ? '' : data.privatePhoneNumber;
            profile.workPhoneNumber = data.workPhoneNumber === null ? '' : data.workPhoneNumber;
            this.setState({profile});
        } catch (e) {}
    };

    sendUserData = async (postData, setFieldError, handleClose) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.put('api/profile/edit', postData, {cancelToken: this.source.token}, true);
            this.setState(() => {
                handleClose();
                return { profile: postData };
            })
        } catch (e) {}
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserData();
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    render() {
        return (
            <ProfileForm
                profile={this.state.profile}
                sendUserData={this.sendUserData}
            />
        );
    }
}
