import React from "react";
import axios from "axios";
import AddKontrahentStructureForm from "./AddKontrahentStructureForm";
import HttpClient from "../../../HttpClient";

export default class AddKontrahentStructure extends React.Component {
    source = axios.CancelToken.source();

    buildEmptyContractor = () => ({
        contractorName: "",
        dispatchName: "",
        dispatchEmail: "",
        dispatchPhoneNumber: "",
    });
    state = {
        contractor: this.buildEmptyContractor(),
    };

    sendData = async (postData, resetForm) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const {data} = await httpRequest.post('api/createContractor', postData, { cancelToken: this.source.token }, true);
            this.props.setFieldValue('kontrahent', data.data);
            if(this.props.sendDataCallback) this.props.sendDataCallback(data.data);
            this.props.handleToggleModal();
        } catch (e) {}
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    render() {
        return (
            <AddKontrahentStructureForm
                contractor={this.state.contractor}
                sendData={this.sendData}
            />
        );
    }
}
