import React, { Fragment, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Collapse } from "@material-ui/core";
import List from "@material-ui/core/List";
import AppTabEntity from "../AppTabEntity/AppTabEntity";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const AppWindowEntity = (props) => {
  const { app, win, data, alone, classes } = props;
  const tabs = Object.entries(data.tabs);
  const [open, setOpen] = useState(data.defaultOpen);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const items = tabs.map(([tab, data]) => {
    return (
      <AppTabEntity
        key={tab}
        app={app}
        win={win}
        tab={tab}
        data={data}
        classes={classes}
      />
    );
  });
  if (alone) return <Fragment>{items}</Fragment>;
  else
    return (
      <Fragment>
        <ListItem
          button={true}
          onClick={toggleOpen}
          className={classes.listItem}
        >
          <ListItemIcon className={classes.nestedIcon}>
            <FiberManualRecordIcon className={classes.tabIcon} />
          </ListItemIcon>
          <ListItemText className={classes.nested} primary={data.label} style={{ marginRight: "20px" }} />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>{items}</List>
        </Collapse>
      </Fragment>
    );
};

export default AppWindowEntity;
