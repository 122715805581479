import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Bread from "./Bread";
import RenderList from "./RenderList";
import Logo from "../img/Raily_siwe_pion.png";
import {AccessGranted} from "../app/AppRouter";
import styles from "./asideNavigation.module.scss";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ActivityLog from "../activityLog/ActivityLog";
import HttpClient from "../HttpClient";
import axios from "axios";
import {MimicContext} from "../context";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from '@material-ui/icons/Refresh';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}

const drawerWidth = 280;
const logDrawerWidth = 370;
const drawerWidthClosed = 63;

const useStylesLog = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${logDrawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: logDrawerWidth,
    },
    title: {
        fontSize: "14px",
        color: "#1DBBED",
        padding: "0 20px",
        margin: 0,
        textTransform: "uppercase"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: logDrawerWidth,
        flexShrink: 0,
        display: "flex",
        justifyContent: "space-between"
    },
    drawerPaper: {
        top: 115,
        width: logDrawerWidth,
        backgroundColor: "#fafafa",
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: "0 16px 0 35px",
        backgroundColor: "#fafafa",
        justifyContent: 'space-between',
        width: logDrawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    icon: {
        color: "#1DBBED"
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        "& ul.MuiList-root.MuiList-padding": {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
        },
    },
    appBar: {
        top: 64,
        backgroundColor: "#fafafa",
        boxShadow: "none",
        width: `calc(100% - ${drawerWidthClosed - 1}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth - 1}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        height: "auto",
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: drawerWidthClosed,
    },
    toolbarTop: {
        display: "flex",
        padding: "12px 10px",
        backgroundColor: "#47484B",
        alignItems: "center",
        justifyContent: "center",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "16px"
    },
    breadToolbar: {
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    breadcrumbs: {
        fontWeight: "normal",
    },
    last: {
        color: "#1DBBED",
    },
    toolbarItem: {
        fontSize: 14,
        color: '#1DBBED',
        fontWeight: 'bold',
        margin: '0 10px'
    },
    iconButton: {
        color: '#1DBBED'
    }
}));
const useStylesMenu = makeStyles(() => ({
    asideMenu: {
        minHeight: "100vh",
        minWidth: "300px",
        paddingBottom: "10vh",
        backgroundColor: "#47484b",
    },

    appIcon: {
        width: "25px",
        margin: "10px 10px 10px 0",
    },

    nested: {
        color: "white",
    },

    nestedIcon: {
        marginLeft: "30px",
        minWidth: "20px",
    },
    tabIcon: {
        fontSize: "10px",
        color: "#1DBBED",
    },

    subNested: {
        border: "none",
        color: "white",
        borderRadius: 3,
        padding: "0 9px",
        marginTop: "0",
        marginBottom: "0",
    },

    unFillSubNested: {
        color: "red",
        border: "none",
        borderRadius: 3,
        padding: "0 9px",
        marginTop: 0,
        marginBottom: 0
    },

    unFillIcon: {
        color: "red",
        flexGrow: 1,
    },

    arrowLeft: {
        transform: 'rotate(180deg)'
    },
    arrowRight: {
        transform: 'rotate(0deg)'
    },


    title: {
        fontWeight: 800,
        fontSize: 24,
        color: "white",
        "&.MuiTypography-body1": {
            fontWeight: 800,
        }
    },

    listItem: {
        backgroundColor: "#313131",
        paddingTop: 0,
        paddingBottom: 0,
        "&:nth-of-type(1)": {
            overflow: "hidden",
        },
        "&:hover": {
            "& $subNested": {
                backgroundColor: "#1DBBED",
            },
        },
    },

    listItemTreeWrapper: {
        position: "relative",
        height: "25px",
        width: "5px",
        margin: "0 0 0 70px",
    },
    //linie przy tabs
    listItemTree: {
        position: "absolute",
        top: "-50%",
        left: 0,
        height: "100%",
        width: "100%",
        borderLeft: "1px solid white",
        borderBottom: "1px solid white",
    },

    //Gdy zakładka jest rozwinięta
    appEntityOpen: {
        backgroundColor: "#313131",
    },
    expandButton: {
        position: "fixed",
        bottom: 50,
        left: 10,
        fontSize: 12,
        fontWeight: "bold",
        zIndex: 10,
        color: "#6e6e6e",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: "#1DBBED",
            color: "white",
        },
    },
    active: {
        backgroundColor: "none",
        "& $subNested": {
            backgroundColor: "#1DBBED",
        },
    },

}));
const StyledBadge = withStyles(() => ({
    badge: {
        backgroundColor: "#ED1D1D",
        color: "white"
    },
}))(Badge);

export default function AsideNavigation() {
    const {loggedUser, roles} = useContext(MimicContext);
    const classes = useStyles();
    const classesMenu = useStylesMenu();
    const classesLog = useStylesLog();
    const source = axios.CancelToken.source();
    const [open, setOpen] = useState(true);
    const [openLog, setOpenLog] = useState(false);
    const history = useHistory();
    const [isNewActivity, setIsNewActivity] = useState(false);
    const [activityTable, setActivityTable] = useState(null);

    const getData = async () => {
        if (roles[0] === "ROLE_OPERATOR" || roles[0] === "ROLE_DYSPOZYTOR" || roles[0] === "ROLE_SUPER_ADMIN") {
            const httpRequest = HttpClient.createRequest();
            const url = "api/log";
            try {
                const response = await httpRequest.get(url, {}, true);
                setActivityTable(response.data);
            } catch (e) {
            }
        }
    };

    useEffect(() => {
        if (loggedUser && window.socketConnection) {
            const target = roles[0] === "ROLE_OPERATOR" || roles[0] === "ROLE_SUPER_ADMIN" ? "Operator" : loggedUser?.companyName;
            window.socketConnection.on(`activityLog${target}`, (data) => {
                setIsNewActivity(true);
            });
        }
    }, []);

    useEffect(() => {
        getData();
        return () => {
            source.cancel();
        };
    }, [openLog]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleDrawerOpen = () => {
        setOpenLog(true);
    };

    const handleDrawerClose = () => {
        setOpenLog(false);
    };

    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar variant="dense" position="fixed" className={classes.breadToolbar}>
                        <Typography variant="h6" noWrap>
                            <Bread classes={classes.breadcrumbs}/>
                        </Typography>
                        {openLog ? <div className={classesLog.drawerHeader}>
                                <h1 className={classesLog.title}>
                                    Ostatnia aktywność
                                </h1>
                                <div className={styles["asideNavigation__buttonWrapper"]}>
                                    <BootstrapTooltip title="Odśwież">
                            <span>
                                <IconButton onClick={() => {
                                    setIsNewActivity(false);
                                    getData()
                                }}>
                                   {isNewActivity ?
                                       <StyledBadge
                                           badgeContent={"!"}>
                                           <RefreshIcon className={classesLog.icon}/>
                                       </StyledBadge>
                                       : <RefreshIcon className={classesLog.icon}/>
                                   }
                                </IconButton>
                            </span>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title="Zwiń">
                            <span>
                                <IconButton onClick={handleDrawerClose}>
                                    <ArrowForwardIcon className={classesLog.icon}/>
                                </IconButton>
                            </span>
                                    </BootstrapTooltip>
                                </div>
                            </div> :
                            <div className={classes.toolbarItem}>
                                <BootstrapTooltip title="Dziennik aktywności">
                                <span>
                            <IconButton
                                aria-label="activityLog"
                                onClick={() => {
                                    setIsNewActivity(false);
                                    handleDrawerOpen()
                                }}>
                                {isNewActivity ?
                                    <StyledBadge
                                        badgeContent={"!"}>
                                        <NotificationsNoneIcon className={classes.iconButton}/>
                                    </StyledBadge>
                                    : <NotificationsNoneIcon className={classes.iconButton}/>
                                }
                            </IconButton>
                                    </span>
                                </BootstrapTooltip>
                            </div>
                        }


                    </Toolbar>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <div className={classes.toolbarTop}>
                            <img height="40" src={Logo} alt="logo"></img>
                        </div>
                        <RenderList
                            history={history}
                            open={open}
                            classes={classesMenu}
                            toggleOpen={toggleOpen}
                        />
                    </Drawer>
                    <main className={styles.main}>
                        <AccessGranted/>
                    </main>
                </AppBar>
                {/*Activity log*/}
                <Drawer
                    className={classesLog.drawer}
                    variant="persistent"
                    anchor="right"
                    open={openLog}
                    classes={{
                        paper: classesLog.drawerPaper,
                    }}
                >
                    <ActivityLog activityTable={activityTable}/>
                </Drawer>
            </div>
        </>
    );
}
