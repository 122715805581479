import React from "react";
import Archive from "./Archive";
import AppRoute from "../../../../AppRoute";

const ArchiveRoute = () => {
  return (
    <AppRoute
      app="kierowca"
      window="trasy"
      tab="archiwum"
      component={Archive}
    />
  );
};

export default ArchiveRoute;
