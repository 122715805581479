import React from "react";
import AppRoute from "../../../../AppRoute";
import EditWorker from './EditWorker';

const EdytujRoute = ({app}) => {
 
  const EditWorkerComponent = (props) => <EditWorker app={app} {...props} />;

  return <AppRoute
    app={app}
    window="pracownik"
    tab="edytuj/:id"
    component={EditWorkerComponent}
  />
};

export default EdytujRoute;
