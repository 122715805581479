import React, { useContext, useState, useEffect, useMemo } from "react";
import styles from "./addDriverForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, MenuItem } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import OsmMap from "../../../reusableComponents/maps/osmMap/OsmMap";
import axios from "axios";
import { debounce } from "../../../../debounce";
import {MimicContext, NotificationContext} from "../../../../context";
import HttpClient from "../../../../HttpClient";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import "simplebar/dist/simplebar.min.css";
import Cookie from "js-cookie";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import config from "../../../../config";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
}));

const addressTypes = ["Domowy", "Hotel", "Inny", "Gniazdo"];

const RailyCheckbox = withStyles({
    root: {
        color: "#1dbbed",
        "&$checked": {
            color: "#1dbbed",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function AddWorkerForm(props) {
    const createAddressField = () => ({
        addresses: [],
    });

    let actualAxiosRequest = null;
    const { setNotification } = useContext(NotificationContext);
    const { isRoleType } = useContext(MimicContext)
    const classes = useStyles();
    const [newLat, setNewLat] = useState(52.215933);
    const [newLon, setNewLon] = useState(19.134422);
    const [addressSearch, setAddressSearch] = useState([createAddressField()]);
    const [ignoreForm, setIgnoreForm] = useState(true);
    const phoneRegExp = /^([0-9-\s]{1,18})$/;
    const zipCodeRegExp = /([0-9]{2}\-[0-9]{3})|([0-9-]{1,8})/;
    const [billingGroups, setBillingGroups] = useState([]);
    const [billingModels, setBillingModels] = useState([]);

    const [regions, setRegions] = useState([]);

    const loggedUser = Cookie.get("loggedUser");

    useEffect(() => {
        const httpRequest = HttpClient.createRequest();

        httpRequest.get(`api/billing-groups`, undefined, loggedUser).then((response) => {
            const newBillingGroups = response.data.data.map((responseBillingGroup) => ({
                id: responseBillingGroup.id,
                name: responseBillingGroup.name,
            }));
            setBillingGroups(
                newBillingGroups
            );
        });

        httpRequest.get(`api/billing-models`, undefined, loggedUser).then((response) => {
            const newBillingModels = response.data.data.map((responseBillingModel) => ({
                id: responseBillingModel.id,
                name: responseBillingModel.name,
            }));

            setBillingModels(newBillingModels);
        });

        httpRequest.get(`api/regions`, undefined, loggedUser).then((response) => {
            const emptyOption = {
                id: "",
                name: "brak",
            };

            const mappedRegionOptions = response.data.data.map((responseRegion) => ({
                id: responseRegion.id,
                name: responseRegion.name,
            }));

            const newRegionOptions = [emptyOption, ...mappedRegionOptions];

            setRegions(newRegionOptions);
        });
    }, []);

    const handleIgnoreForm = () => {
        if (!ignoreForm) {
            setIgnoreForm(true);
        } else return;
    };

    const handleCreateAddress = () => {
        setAddressSearch([...addressSearch, createAddressField()]);
    };

    const handleRemoveAddress = () => {
        const _addressSearch = [...addressSearch];
        _addressSearch.pop();
        setAddressSearch(_addressSearch);
    };

    const fillAddressFields = (value, setFieldValue, index = 0) => {
        setIgnoreForm(false);
        const {address} = value;
        const addressField = {
            zipCode: address.postcode || "",
            city: address.city || address.village || address.town || address.hamlet || "",
            get street() {
                return address.road || this.city || "";
            },
            no: address.house_number || "",
            lat: value.lat,
            lon: value.lon,
        };

        for (const key in addressField) {
            setFieldValue(`address[${index}][${key}]`, addressField[key]);
        }

        setNewLat(value.lat);
        setNewLon(value.lon);
    };

    const handleAddressChange = async (value, fromMapClick = false, formikFields = null, index) => {
        setIgnoreForm(true);
        if (actualAxiosRequest) {
            actualAxiosRequest.cancel();
        }
        actualAxiosRequest = axios.CancelToken.source();
        const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {
                cancelToken: actualAxiosRequest.token,
            });
            const {data} = response;
            if (!fromMapClick) {
                const _addressSearch = [...addressSearch];
                const options = [];

                data.map((item) => {
                    options.push({
                        id: item.place_id,
                        name: item.display_name,
                        address: item.address,
                        lat: item.lat,
                        lon: item.lon,
                    });
                });
                _addressSearch[index].addresses = options;
                setAddressSearch(_addressSearch);
                fillAddressFields(_addressSearch, formikFields, index)
            } else {
                const address = {
                    id: data[0].place_id,
                    name: data[0].display_name,
                    address: data[0].address,
                    lat: data[0].lat,
                    lon: data[0].lon
                }
                fillAddressFields(address, formikFields, 0)
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                actualAxiosRequest = null;
            }
        }
    };

    const moveMarker = (value) => {
        if (value.lat !== null || value.lon !== null) {
            setIgnoreForm(false);
            setNewLat(value.lat);
            setNewLon(value.lon);
        }
    };

    const search = debounce((value, index = 0) => {
        handleAddressChange(value, false, null, index);
    }, 2000);

    const WorkerSchema = Yup.object().shape({
        name: Yup.string().required("Uzupełnij to pole"),
        surName: Yup.string().required("Uzupełnij to pole"),
        regNo: Yup.string(),
        rate: Yup.number().required("Uzupełnij to pole"),
        isTaxiDriver: Yup.boolean(),
        workPhoneNumber: Yup.string()
            .required("Uzupełnij to pole")
            .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
        workEmail: Yup.string().email("Nieprawidłowy format adresu email"),
        address: Yup.array().of(
            Yup.object().shape({
                // addressSearch: Yup.string(),
                zipCode: Yup.string()
                    .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego")
                    .required("Uzupełnij to pole"),
                city: Yup.string().required("Uzupełnij to pole"),
                street: Yup.string().required("Uzupełnij to pole"),
                no: Yup.string().required("Uzupełnij to pole"),
                flat: Yup.string(),
                type: Yup.string(),
            })
        ),
        isHired: Yup.bool().required(`Uzupełnij to pole`),
        normalRate: Yup.number().required(`Uzupełnij to pole`),
        waitingRate: Yup.number().required(`Uzupełnij to pole`),
        region: Yup.string(),
        billingGroup: Yup.string().required(`Uzupełnij to pole`),
        billingModel: Yup.string().required(`Uzupełnij to pole`),
        notes: Yup.string()
    });

    const mapClicked = async (lonlat, setFieldValue) => {
        const value = lonlat.split(',')
        handleAddressChange((value[1] + ',' + value[0]).toString(), true, setFieldValue)
    }

    return (
        <div className={styles["addDriver"]}>
            <Formik
                initialValues={props.worker}
                validationSchema={WorkerSchema}
                onSubmit={(values) => {
                    props.sendWorkerData(values, false);
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form className={styles["addDriverForm"]}>
                        <>
                            <SimpleBar style={{ maxHeight: "70vh", minWidth: 520 }} className={classes.simplebar}>
                                <div className={styles["addDriverForm__description"]}>
                                    <h1 className={styles["addDriverForm__description--title"]}>Dodaj kierowcę</h1>
                                    <p className={styles["addDriverForm__description--subTitle"]}>
                                        Wypełnij wszystkie pola i zatwierdź
                                    </p>
                                </div>
                                <div className={styles["addDriverForm__personal"]}>
                                    <div className={styles["addDriverForm__personal--description"]}>
                                        <p>Dane osobowe</p>
                                    </div>
                                    <div className={styles["addDriverForm__personal--inputs"]}>
                                        <div className={styles["addDriverForm__personal--inputs--field"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="name"
                                                helperText={!!errors.name && touched.name ? errors.name : ""}
                                                error={!!errors.name && touched.name}
                                                label="Imię*"
                                                variant="standard"
                                                style={{ width: 240 }}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                        <div className={styles["addDriverForm__personal--inputs--field"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="surName"
                                                helperText={!!errors.surName && touched.surName ? errors.surName : ""}
                                                error={!!errors.surName && touched.surName}
                                                label="Nazwisko*"
                                                variant="standard"
                                                style={{ width: 240 }}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles["addDriverForm__address"]}>
                                    <div className={styles["addDriverForm__address--description"]}>
                                        <p>Dane adresowe</p>
                                    </div>
                                    <FieldArray
                                        name="address"
                                        render={({ push, remove }) => (
                                            <>
                                                {values.address.map((value, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={styles["addDriverForm__address--inputs"]}
                                                        >
                                                            <Autocomplete
                                                                options={addressSearch[index]?.addresses ?? []}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) =>
                                                                    option.id === value.id
                                                                }
                                                                filterOptions={(options, state) => options}
                                                                noOptionsText="Nie znaleziono podanego adresu"
                                                                onOpen={() => moveMarker(value)}
                                                                onChange={(e, value) => {
                                                                    if (value)
                                                                        fillAddressFields(value, setFieldValue, index );
                                                                }}
                                                                onInputChange={(e, newValue) => {
                                                                    if (newValue.trim()) search(newValue, index);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <Field
                                                                        {...params}
                                                                        className={classes.root}
                                                                        label="Wyszukaj adres"
                                                                        variant="filled"
                                                                        margin="none"
                                                                        name="alone"
                                                                        fullWidth
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: "new-password",
                                                                        }}
                                                                        as={TextField}
                                                                    />
                                                                )}
                                                            />
                                                            <div
                                                                className={
                                                                    styles["addDriverForm__address--inputs--fieldGroup"]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles["addDriverForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        className={classes.root}
                                                                        disabled
                                                                        name={`address.${index}.street`}
                                                                        error={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.street`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.street`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.street`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.street`)
                                                                                ? getIn(
                                                                                    errors,
                                                                                    `address.${index}.street`
                                                                                )
                                                                                : ""
                                                                        }
                                                                        label="Ulica*"
                                                                        variant="standard"
                                                                        style={{
                                                                            width: 240,
                                                                        }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["addDriverForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        className={classes.root}
                                                                        name={`address.${index}.no`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.no`) &&
                                                                            !!getIn(errors, `address.${index}.no`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.no`) &&
                                                                            !!getIn(errors, `address.${index}.no`)
                                                                                ? getIn(errors, `address.${index}.no`)
                                                                                : ""
                                                                        }
                                                                        label="Nr domu*"
                                                                        variant="standard"
                                                                        style={{
                                                                            width: 115,
                                                                        }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["addDriverForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        className={classes.root}
                                                                        name={`address.${index}.flat`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.flat`) &&
                                                                            !!getIn(errors, `address.${index}.flat`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.flat`) &&
                                                                            !!getIn(errors, `address.${index}.flat`)
                                                                                ? getIn(errors, `address.${index}.flat`)
                                                                                : ""
                                                                        }
                                                                        label="Nr mieszkania"
                                                                        variant="standard"
                                                                        style={{
                                                                            width: 115,
                                                                        }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    justifyContent: "flex-start",
                                                                }}
                                                                className={
                                                                    styles["addDriverForm__address--inputs--fieldGroup"]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles["addDriverForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        className={classes.root}
                                                                        name={`address.${index}.city`}
                                                                        error={
                                                                            !!getIn(touched, `address.${index}.city`) &&
                                                                            !!getIn(errors, `address.${index}.city`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(touched, `address.${index}.city`) &&
                                                                            !!getIn(errors, `address.${index}.city`)
                                                                                ? getIn(errors, `address.${index}.city`)
                                                                                : ""
                                                                        }
                                                                        label="Miasto*"
                                                                        variant="standard"
                                                                        style={{
                                                                            width: 240,
                                                                        }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles["addDriverForm__address--inputs--field"]
                                                                    }
                                                                >
                                                                    <Field
                                                                        // disabled
                                                                        className={classes.root}
                                                                        name={`address.${index}.zipCode`}
                                                                        error={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.zipCode`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.zipCode`)
                                                                        }
                                                                        helperText={
                                                                            !!getIn(
                                                                                touched,
                                                                                `address.${index}.zipCode`
                                                                            ) &&
                                                                            !!getIn(errors, `address.${index}.zipCode`)
                                                                                ? getIn(
                                                                                    errors,
                                                                                    `address.${index}.zipCode`
                                                                                )
                                                                                : ""
                                                                        }
                                                                        label="Kod pocztowy*"
                                                                        variant="standard"
                                                                        style={{
                                                                            width: 115,
                                                                        }}
                                                                        margin="none"
                                                                        fullWidth
                                                                        as={TextField}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className={styles["addDriverForm__contact"]}>
                                    <div className={styles["addDriverForm__contact--description"]}>
                                        <p>Dane kontaktowe</p>
                                    </div>
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="workPhoneNumber"
                                                helperText={
                                                    !!errors.workPhoneNumber && touched.workPhoneNumber
                                                        ? errors.workPhoneNumber
                                                        : ""
                                                }
                                                error={!!errors.workPhoneNumber && touched.workPhoneNumber}
                                                label="Telefon*"
                                                variant="standard"
                                                style={{ width: 240 }}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                        <div className={styles["addDriverForm__contact--inputs--field"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="workEmail"
                                                helperText={
                                                    !!errors.workEmail && touched.workEmail ? errors.workEmail : ""
                                                }
                                                error={!!errors.workEmail && touched.workEmail}
                                                label="E-mail"
                                                variant="standard"
                                                style={{ width: 240 }}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field--checkbox"]}>
                                            <Field
                                                name="isTaxiDriver"
                                                label="Czy jest taxiDriver"
                                                defaultChecked
                                                color="primary"
                                                inputProps={{
                                                    "aria-label": "secondary checkbox",
                                                }}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <RailyCheckbox
                                                                {...field}
                                                                checked={field.value}
                                                                name="isTaxiDriver"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Kierowca należy do podwykonawcy"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles["addDriverForm__contact"]}>
                                    <div className={styles["addDriverForm__contact--description"]}>
                                        <p>O kierowcy</p>
                                    </div>
                                    <Field
                                        name="isHired"
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "secondary checkbox",
                                        }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <RailyCheckbox
                                                        {...field}
                                                        checked={field.value}
                                                        name="isHired"
                                                        color="primary"
                                                    />
                                                }
                                                label="Kierowca zatrudniony"
                                            />
                                        )}
                                    />
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field--checkbox"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="normalRate"
                                                type="number"
                                                helperText={
                                                    !!errors.normalRate && touched.normalRate ? errors.normalRate : ""
                                                }
                                                error={!!errors.normalRate && touched.normalRate}
                                                label="Stawka normalna*"
                                                variant="standard"
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field--checkbox"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="waitingRate"
                                                type="number"
                                                helperText={
                                                    !!errors.waitingRate && touched.waitingRate
                                                        ? errors.waitingRate
                                                        : ""
                                                }
                                                error={!!errors.waitingRate && touched.waitingRate}
                                                label="Stawka za postój*"
                                                variant="standard"
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field--checkbox"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="billingGroup"
                                                helperText={
                                                    !!errors.billingGroup && touched.billingGroup
                                                        ? errors.billingGroup
                                                        : ""
                                                }
                                                error={!!errors.billingGroup && touched.billingGroup}
                                                margin="none"
                                                fullWidth
                                                as={(field) => (
                                                    <TextField {...field} fullWidth select label="Grupa rozliczeniowa*">
                                                        {billingGroups.map((billingGroup) => (
                                                            <MenuItem key={billingGroup.id} value={billingGroup.id}>
                                                                {billingGroup.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles["addDriverForm__contact--inputs"]}>
                                        <div className={styles["addDriverForm__contact--inputs--field--checkbox"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="billingModel"
                                                helperText={
                                                    !!errors.billingModel && touched.billingModel
                                                        ? errors.billingModel
                                                        : ""
                                                }
                                                error={!!errors.billingModel && touched.billingModel}
                                                margin="none"
                                                fullWidth
                                                as={(field) => (
                                                    <TextField {...field} fullWidth select label="Model rozliczeniowy*">
                                                        {billingModels.map((billingModel) => (
                                                            <MenuItem key={billingModel.id} value={billingModel.id}>
                                                                {billingModel.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["addDriverForm__regNo"]}>
                                    <div className={styles["addDriverForm__regNo--description"]}>
                                        <p>Dane pojazdu</p>
                                    </div>
                                    <div className={styles["addDriverForm__regNo--inputs"]}>
                                        <div className={styles["addDriverForm__regNo--inputs--field"]}>
                                            <Field
                                                onKeyUp={handleIgnoreForm}
                                                className={classes.root}
                                                name="regNo"
                                                helperText={!!errors.regNo && touched.regNo ? errors.regNo : ""}
                                                error={!!errors.regNo && touched.regNo}
                                                label="Numer rejestracyjny"
                                                variant="standard"
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                </div>
                                { !isRoleType('OPERATOR_CONTRACTOR') &&
                                    <div className={styles["addDriverForm__rate"]}>
                                        <div className={styles["addDriverForm__rate--description"]}>
                                            <p>Rozliczenia</p>
                                        </div>
                                        <div className={styles["addDriverForm__rate--inputs"]}>
                                            <div className={styles["addDriverForm__rate--inputs--field"]}>
                                                <Field
                                                    onKeyUp={handleIgnoreForm}
                                                    className={classes.root}
                                                    disabled={!values.isTaxiDriver}
                                                    name="notes"
                                                    helperText={!!errors.notes && touched.notes ? errors.notes : ""}
                                                    error={!!errors.notes && touched.notes}
                                                    label="Model-Stawka NIE LOTOS"
                                                    variant="standard"
                                                    margin="none"
                                                    fullWidth
                                                    as={TextField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={styles["addDriverForm__buttonWrapper"]}>
                                    <Button className={classes.confirmButton} variant="contained" type="submit">
                                        Potwierdź
                                    </Button>
                                </div>
                            </SimpleBar>
                        </>
                        <div className={styles["addDriverForm"]}>
                            <div className={styles["addDriverForm__map"]}>
                                <div className={styles["addDriverForm__description"]}>
                                    <h1 className={styles["addDriverForm__description--title"]}>Mapa poglądowa</h1>
                                    <p className={styles["addDriverForm__description--subTitle"]}>
                                        Śledź znacznik aktualnie wpisywanego adresu
                                    </p>
                                </div>
                                <OsmMap width="100%" height="70vh" pickedLat={newLat} pickedLon={newLon} onClickOnMap={(lonlat) => mapClicked(lonlat, setFieldValue)} ignoreForm={ignoreForm}/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
