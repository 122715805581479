import * as yup from "yup";

const requiredMsg = "Te pole jest wymagane";

export const validationSchema = yup.object({
  worker: yup.string().trim().required(requiredMsg).nullable(),
  position: yup.string().trim().nullable(),
  createdAt: yup.string().required(requiredMsg).nullable(),
  startDate: yup.date().required(requiredMsg).nullable(),
  endDate: yup
    .date()
    .when(
      "startDate",
      (startDate, schema) =>
        startDate &&
        schema.min(startDate, "Data do musi być późniejsza niż data od")
    )
    .required(requiredMsg)
    .nullable(),
  statements: yup.array().of(
    yup.object().shape({
      vehicleType: yup.string(),
      capacity: yup
        .string(),
      regNo: yup
        .string(),
      name: yup
        .string(),
      pricePerKm: yup
        .number(),
      totalKm: yup.number().min(1, "Zbyt mała wartość"),
      client: yup.string().nullable(),
      departureCity: yup.string().nullable(),
      arrivalCity: yup.string(),
      departureDateTime: yup.date().nullable(),
      arrivalDateTime: yup
        .date()
        .nullable(),
    })
  ),
  paymentOnAccount: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  startedFor6: yup.number().min(0, "Zbyt mała wartość").required(requiredMsg),
  oneDayDelegation: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  longerDelegationFull: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  longerDelegationStarted: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  accomodationPrice: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  accomodationLumpSum: yup
    .number()
    .min(0, "Zbyt mała wartość")
    .required(requiredMsg),
  otherCosts: yup.number().min(0, "Zbyt mała wartość").required(requiredMsg),
});
