import React from "react";
import styles from "./formWork.module.scss";
import { TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {styled} from "@material-ui/core/styles";
import AddAddressInOrder from "../../../addAddressInOrder/AddAddressInOrder";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";

const SingleInput = styled(TextField)({
    width: 180,
    margin: "5px",
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    showAddAddressForm = () => {
        this.setState(() => {
            return {showModal: !this.state.showModal};
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.props !== nextProps || this.state !== nextState;
    }

    render() {
        const filter = createFilterOptions();
        return (
            <div className={styles["formWork"]}>
                <div className={styles["formWork__input"]}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            filtered.unshift({
                                inputValue: params.inputValue,
                                street: `+ DODAJ`,
                                city: "ADRES"
                            });
                            return filtered;
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        options={this.props.stations}
                        getOptionLabel={(address) => `${address.street} ${address.city}`}
                        value={this.props.destination.value}
                        onChange={(event, newValue) => {
                            if (
                                newValue?.street === "+ DODAJ" && newValue?.city === "ADRES"
                            ) {
                                this.showAddAddressForm();
                            } else {
                                this.props.setOrderData(
                                    this.props.groupIndex,
                                    this.props.index,
                                    "destination",
                                    newValue,
                                    this.props.handleValidate
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <SingleInput
                                {...params}
                                error={
                                    this.props.destination.empty && !this.props.data.isProposition
                                }
                                helperText={
                                    this.props.destination.empty && !this.props.data.isProposition
                                        ? "Uzupełnij dane"
                                        : false
                                }
                                required={!this.props.data.isProposition}
                                label="Praca"
                                variant="standard"
                                fullWidth
                            />
                        )}
                    />
                </div>

                {this.state.showModal ? (
                    <AddAddressInOrder
                        type="STATION"
                        index={this.props.index}
                        groupIndex={this.props.groupIndex}
                        for="work"
                        field="destination"
                        addToPropertyInOrder={this.props.addWorkAddress}
                        url="/api/addresses"
                        showAddAddressForm={this.showAddAddressForm}
                    />
                ) : null}
            </div>
        );
    }
}
