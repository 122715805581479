import React from "react";
import Profile from "./Profile";
import AppRoute from "../../../AppRoute";

const ProfileRoute = () => {
    return (
            <AppRoute
                app="user"
                window="account"
                tab="profile"
                component={Profile}
            />
    );
};

export default ProfileRoute;
