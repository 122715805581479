import HttpClient from "../../../HttpClient";
import {chunkCallable} from "../../../helper/chunk";

const getData = (type) => async (ids) => {
    const httpRequest = HttpClient.createRequest();
    const response = await httpRequest.get(
        `api/delegations/${type}`,
        {
            params: {
                ids
            }
        },
        true
    );
    return response.data.data;
}

export const downloadFormatData = (type, ids) => {
    if(!['pdf', 'csv', 'bankCsv'].includes(type)) {
        throw new Error('Given format must be on of the following: pdf, csv, bankCsv');
    }

    return chunkCallable(ids, 100, getData(type))
};
