import React from "react";
import { v4 as uuid } from "uuid";

import { SummaryOrderRoute } from ".";
import { GridColumnElement } from "app/reusableComponents/newOrderPanel/styled";
import { formatDistance, formatTime } from "app/reusableComponents/newOrderPanel/helpers";

export const SummarySingleOrder = ({ order, positionPrefix }) => {
    if (!order?.address) return null;

    return (
        <>
            <GridColumnElement column="1/11"></GridColumnElement>
            <GridColumnElement column="1/7">Przebieg trasy</GridColumnElement>
            <GridColumnElement text_align="center" column="7/8">
                Zlecona
            </GridColumnElement>
            <GridColumnElement text_align="center" column="8/9">
                Plan
            </GridColumnElement>
            <GridColumnElement text_align="center" column="9/10">
                Postój
            </GridColumnElement>
            <GridColumnElement text_align="center" column="10/11">
                Km
            </GridColumnElement>
            {order.address.locations?.map((location, index) => (
                <SummaryOrderRoute
                    key={uuid()}
                    location={location}
                    isLast={index === order.address.locations.length - 1}
                    isFirst={index === 0}
                    positionNumber={`${positionPrefix ? `${positionPrefix}.${index + 1}` : index + 1}`}
                />
            ))}
            <GridColumnElement column="1/10">
                <p>
                    Dystans: <strong>{formatDistance(order.address.totalaDistance)}</strong> Czas przejazdu:{" "}
                    <strong>{formatTime(order.address.totalTime)}</strong>
                </p>
            </GridColumnElement>
        </>
    );
};
