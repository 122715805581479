const getRouteNameString = (routeSettlements) => {
    let routeNameString = "";

    routeSettlements.forEach((routeSettlement, index) => {
        if (!routeSettlement.address?.city) {
            return;
        }

        if (index === routeSettlements.length - 1) {
            routeNameString += routeSettlement.address.city;
            return;
        }
        routeNameString += routeSettlement.address.city;
        routeNameString += " | ";
    });

    return routeNameString;
};

const checkIsNan = (value) =>   Number.isNaN(value) ? 0 : value

const settlementsPanelSettlementHelper = {
    getRouteNameString,
    checkIsNan
};

export default settlementsPanelSettlementHelper;
