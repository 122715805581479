import React from "react";
import WorkInProgress from "../../../reusableComponents/workInProgress/WorkInprogress";

export default function DokumentyOkresowe() {
  return (
    <div>
      <WorkInProgress />
    </div>
  );
}
