import React from "react";
import AppRoute from "../../../AppRoute";
import DriversMap from "./DriversMap";


const DriversMapRoute = () => {

    return (
        <AppRoute
            app="operator"
            window="Kierowcy"
            tab="Mapa"
            component={DriversMap}
        />
    );
};

export default DriversMapRoute;
