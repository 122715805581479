import { makeStyles } from "@material-ui/core/styles";

const settlementsPanelStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
        "& .simplebar-content-wrapper": {
            paddingTop: "1em"
        }
    },
    loader: {
        "& .progress-bar": {
            width: "100%",
            backgroundColor: "#1dbbed",
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#d4d4d4",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalDisconnect: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        overflowY: "auto",
        maxHeight: "97%",
        overflowX: "hidden",
        "&::-webkit-scrollbar":{
            width: "12px"
          },
          "&::-webkit-scrollbar-track":{
            margin: "10px 0"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
          }
    },
    backgroundDark: {
        "&:nth-child(odd)": {
            background:    "#f7f6f6",
            margin: '25px 0',
            padding: '10px 5px'
        }
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        width: "85vw",
        height: "88vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
    childModal: {
        outline: "0 !important",
        position: "fixed",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
    paragraph: {
        fontSize: 24,
        margin: 0,
        padding: "5px 0",
        marginRight: "40px",
    },
    closeButton: {
        backgroundColor: "#b4b4b4",
        marginRight: "10px",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        border: 'none',
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
    header: {
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    close: {
        position: "absolute",
        top: 10,
        right: 10,
        fontSize: 18,
        cursor: "pointer",
        "&:hover": {
            color: "#00a0e3",
        },
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: "bold",
        margin: 0,
        padding: "5px 0",
    },
    aboutCourseWrapper: {
        // height: 300,
    },
    selectCourseWrapper: {
        display: "flex",
        alignItems: "center",
    },
    tab: {
        minWidth: "unset",
    },
    tableCell: {
        fontSize: 12,
        padding: `0 8px`,
        borderBottomWidth: 0,
        verticalAlign: "top",
    },
    tableCellRouteAddress: {
        display: "flex",
    },
    tableCellText: {
        paddingRight: 5,
        paddingTop: 4,
        display: "flex",
    },
    tableHeadCell: {
        fontSize: 12,
        fontWeight: 600,
        padding: `0 8px`,
    },
    tableCellValueError: {
        color: "red",
    },
    timelineDot: {
        marginTop: 5,
        marginBottom: 3,
    },
    timelineContent: {
        padding: 0,
        paddingLeft: 5,
    },
    timelineItem: {
        "&:before": {
            padding: 0,
            flex: "unset",
        },
        minHeight: 35,
    },
    input: {
        fontSize: 12,
        padding: `1.5px 0`,
    },

    timeRoot: {
        padding: 0,
        margin: 0,
        "--MuiTimelineItem-root": {
            minHeight: "40px",
        },

        "--MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
            flex: "0"
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "#1DBBED",
        padding: "3px",
    },
    timeConnector: {
        backgroundColor: "#b4b4b4",
    },
    timeContent: {
        padding: "0 15px",
        margin: "-7px 0 10px",
    },

    historyItem: {
        "--wrapper": {

            "--time": {
                flexDirection: "column"
            },
            "--courseDetails": {
                flexDirection: "column"
            },
            "--wrapper": {
                flexDirection: "column"
            },

            "--history": {
                padding: 0,
                margin: 0,

                "--details": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",

                    "--title": {
                        margin: "0 0 3px 0",
                        fontSize: "13px",
                        color: "#47484b",
                        textTransform: "uppercase",
                        fontWeight: 600,

                        "--history": {
                            color: "#1dbbed"
                        }
                    },

                    "--singleColumn": {
                        marginBottom: "10px",

                        "--info": {
                            margin: 0,
                            color: "#6f6f6f",
                            fontSize: "12px",
                            fontWeight: "bold"
                        },

                        "--data": {
                            margin: "0 0 4px 0",
                            maxWidth: "250px",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            wordBreak: "break-word",

                            "--history": {
                                color: "1dbbed"
                            },

                            "--edited": {
                                margin: "0 0 2px 0"
                            }
                        }
                    }
                }
            }
        }
    },
    detailsButton: {
        color: "#1DBBED",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#1DBBED",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "10px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#00a0e3",
        }
    },
    detailsButtonOn: {
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#1DBBED",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "10px",
        width: "150px",
        backgroundColor: "#1DBBED",
        color: "#FFF",

        "&:hover": {
            color: "#1DBBED",
            backgroundColor: "#FFF"
        }
    }

    // loader: {
    //     "--progress-bar": {
    //         width: "100%",
    //         backgroundColor: "#1dbbed",
    //     },
    //     "--MuiLinearProgress-barColorPrimary": {
    //         backgroundColor: "#d4d4d4",
    //     },
    // },
}));

export default settlementsPanelStyles;
