import React from "react";
import DokumentyOkresowe from "./DokumentyOkresowe";
import AppRoute from "../../../../AppRoute";

const DokumentyOkresoweRoute = ({app}) => {
  return (
    <AppRoute
      app={app}
      window="pracownik"
      tab="dokumenty"
      component={DokumentyOkresowe}
    />
  );
};

export default DokumentyOkresoweRoute;
