import React from "react";
import {Backdrop, Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import AddWorker from "../../../../../dyspozytor/pracownicy/dodajPracownika/AddWorker";

const useStyles = makeStyles(() => ({
    modal: {display: "flex", alignItems: "center", justifyContent: "center"},
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 10,
    },
    close: {
        position: "absolute",
        top: "20px",
        right: "20px",
        color: "black",
        fontSize: "20px",
        cursor: "pointer",
        zIndex: "999",
    }
}));

export default function AddWorkerModal(props) {
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={props.open}>
                <div className={classes.paper}>
                         <span className={classes.close}
                               onClick={props.closeDriverModal}>
                             X
                         </span>
                    <AddWorker
                        contractor={props.contractor}
                        handleClose={props.closeDriverModal}
                        addDriverFromForm={props.addDriverFromForm}
                    />
                </div>
            </Fade>
        </Modal>
    );
}
