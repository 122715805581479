import React from "react";
import AppRoute from "../../../../AppRoute";
import DeleteAddress from "./DeleteAddress"

const DeleteAddressRoute = () => {
    return <AppRoute
        app="operator"
        window="adresy"
        tab="usun/:id"
        component={DeleteAddress}
    />
};

export default DeleteAddressRoute;
