import React, { useContext, useEffect, useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import styles from "./rozliczenia.module.scss";
import { makeStyles, styled } from "@material-ui/core/styles";
import { NotificationContext } from "../../../context";
import { Button, TextField, Paper } from "@material-ui/core";
import * as qs from "qs";
import HttpClient from "../../../HttpClient";
import {
    endOfDay,
    endOfMonth,
    getDate,
    getDaysInMonth,
    getMonth,
    getUnixTime,
    getYear,
    startOfDay,
    startOfMonth,
    isDate,
    fromUnixTime,
    isSameDay,
} from "date-fns";
import "simplebar/dist/simplebar.min.css";
import RozliczeniaTable from "./RozliczeniaTable";
import RozliczeniaForm from "./rozliczeniaForm/RozliczeniaForm";
import DateTabs from "../orders/Tabs";
import { convertNumberToArray } from "../../../helper/convertNumberToArray";
import { MimicContext } from "../../../context";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Autocomplete } from "@material-ui/lab";
import { generateCsvForContractor, generateCsvForDriver } from "./generateCsv";
import { transformSearchParamsForDriver, transformSortFieldsForDriver } from "./transformer";

const SingleInput = styled(TextField)({
    width: 200,
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        fontSize: 10,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
});

const useStyles = makeStyles({
    root: {
        "& .MuiPaper-elevation1": {
            boxShadow: "none",
        },
        "& .MuiTabs-fixed": {
            overflowX: "auto !important",
        },
    },
    singleInput: {
        marginRight: "30px",
    },
    addIcon: {
        backgroundColor: "#66D01D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
    icon: {
        color: "white",
    },
    zaakceptowany: {
        color: "#66D01D",
    },
    uwagi: {
        color: "#faba1f",
    },
    odpowiedz: {
        color: "#1DBBED",
    },
    nierozliczony: {
        color: "#ED1D1D",
    },
    wRealizacji: {
        color: "#66D01D",
    },
    przypisany: {
        color: "#faba1f",
    },
    zaplanowany: {
        color: "#1DBBED",
    },
    przetwarzany: {
        color: "#b4b4b4",
    },
    odrzucony: {
        color: "#ED1D1D",
    },
    anulowany: {
        color: "black",
    },
    zakonczony: {
        color: "#d7d7d7",
    },
    addNewDayOff: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        marginRight: "10px",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    },
    generateButton: {
        background: "#1DBBED",
        marginLeft: "auto",
        cursor: "pointer",
        border: "none",
        fontSize: "16px",
        padding: "8px 14px",
        color: "#fff",
        "&:hover": {
            background: "#1DBBED",
            opacity: 0.6,
            marginLeft: "auto",
            cursor: "pointer",
            border: "none",
            fontSize: "16px",
            padding: "8px 14px",
            color: "#fff",
        },
        "&:disabled": {
            filter: "grayScale(100%)",
        },
    },
});

const generateStartEndDate = (newDate) => ({
    start: getUnixTime(startOfMonth(newDate)),
    end: getUnixTime(endOfMonth(newDate)),
});

const Rozliczenia = () => {
    const { roles, isRoleType } = useContext(MimicContext);
    const haveAllPermissions = roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_OPERATOR");
    const types = useMemo(() => {
        const haveAllPermissions = roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_OPERATOR");
        if (haveAllPermissions) {
            return [
                {
                    name: "Rozliczenia kontrahentów",
                    id: 1,
                },
                {
                    name: "Kursy nierozliczone",
                    id: 2,
                },
                {
                    name: "Rozliczenia kierowców",
                    id: 3,
                },
            ];
        }

        return [
            {
                name: "Rozliczenia kontrahentów",
                id: 1,
            },
        ];
    }, [roles]);

    const classes = useStyles();

    const [addOrders, setAddOrders] = useState(false);
    const [settledOrders, setSettledOrders] = useState(true);
    const [isPostedData, setIsPostedData] = useState(false);
    const [contractors, setContractors] = useState([]);
    const [billingGroups, setBillingGroups] = useState([]);
    const [contractor, setContractor] = useState(null);
    const [searchValues, setSearchValues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsLength, setRowsLength] = useState(0);
    const [columns, setColumns] = useState(null);
    const [type, setType] = useState(() => {
        if (haveAllPermissions) {
            return types.find((item) => item.id === 2);
        }
        return types.find((item) => item.id === 1);
    });
    const { closeSnackbar } = useSnackbar();
    const { addFlash } = useContext(NotificationContext);

    const now = new Date();
    const [date, setDate] = useState(generateStartEndDate(now));
    const [year, setYear] = useState(getYear(now));
    const [month, setMonth] = useState(getMonth(now));
    const [day, setDay] = useState(null);

    //DateTabs
    const [startDate, setStartDate] = useState(fromUnixTime(date.start));
    const [endDate, setEndDate] = useState(fromUnixTime(date.end));

    const handleDateChange = (type, value) => {
        const rangeDate = { ...date };
        if (type === "start") {
            value = startOfDay(value);
            setStartDate(value);
        }
        if (type === "end") {
            value = endOfDay(value);
            setEndDate(value);
        }
        rangeDate[type] = getUnixTime(value);
        setDate(rangeDate);
    };

    const [daysInMonth, setDaysInMonth] = useState(convertNumberToArray(getDaysInMonth(new Date(year, month))));

    const handleDaysInMonthChange = (newYear, newMonth) => {
        setDaysInMonth(convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth))));
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        handleDateChange();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        handleDateChange();
    };

    const clearDate = () => {
        setYear(getYear(now));
        setMonth(getMonth(now));
        setDay(null);
    };

    const handleDayChange = (event, newValue) => {
        if (newValue === day) {
            setDay(null);
        } else {
            setDay(newValue);
        }
        handleDateChange();
    };

    const handleDatePickerDateChange = (newDate) => {
        const day = getDate(newDate);
        const month = getMonth(newDate);
        const year = getYear(newDate);
        setDay(day);
        setMonth(month);
        setYear(year);
        handleDateChange();
    };

    const getDatePickerValue = () => {
        return !isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day))
            ? null
            : new Date(year, month, day);
    };

    const handleSetStartEndDate = () => {
        let rangeDate;
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay)),
            };
        } else {
            const thisMonth = new Date(Date.UTC(year, month));
            rangeDate = {
                start: getUnixTime(startOfMonth(thisMonth)),
                end: getUnixTime(endOfMonth(thisMonth)),
            };
        }
        setDate(rangeDate);
    };

    const handleChangeContractor = (e, newValue) => {
        setContractor(newValue);
    };

    const handleChangeType = (e, newValue) => {
        setType(newValue);
    };

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const getCsvData = async () => {
        const _searchParams = searchValues
            .map((item) => transformSearchParamsForDriver(item, type.id))
            .map((a) => ({ ...a }));
        const getParam = _searchParams.map(getParams).join("");
        const URL = type.id === 1 ? "/api/front-settlement/csv" : "/api/driver-billings";

        const httpRequest = HttpClient.createRequest();
        try {
            const sortField = [...columns]
                .map((item) => transformSortFieldsForDriver(item, type.id))
                .filter((item) => item.sorted === true);
            const { data } = await httpRequest.get(
                `${URL}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}`,
                // `${URL}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}&limit=${REQUESTLIMIT}&page=1`,
                {
                    params: {
                        date,
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                },
                true
            );
            return data;
        } catch (e) {}
    };

    const handleGenerateCsv = async () => {
        setIsLoading(true);
        const flash = addFlash("Trwa pobieranie pliku csv...", { variant: "success", autoHideDuration: null });
        const data = await getCsvData();
        type.id === 1 ? generateCsvForContractor(data) : generateCsvForDriver(data);
        setIsLoading(false);
        closeSnackbar(flash);
    };

    const sendData = async (postData, resetForm) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const url = type.id === 1 ? "api/manual-settlement" : "api/driver-billings";
            await httpRequest.post(url, postData, {}, true);
            resetForm();
            setIsPostedData(!isPostedData);
        } catch (e) {}
    };

    const getContractors = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/allKontrahents", {}, true);
            const { data } = response;
            setContractors(data);
        } catch (e) {}
    };

    const getBillingGroups = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/billing-groups", {}, true);
            const { data } = response;
            setBillingGroups(data.data);
        } catch (e) {}
    };

    useEffect(() => {
        getContractors();
        getBillingGroups();
    }, []);

    useEffect(() => {
        setStartDate(fromUnixTime(date.start));
        setEndDate(fromUnixTime(date.end));
    }, [date]);

    useEffect(() => {
        setAddOrders(false);
    }, [type?.id]);

    useEffect(() => {
        handleDaysInMonthChange(year, month);
        handleSetStartEndDate();
    }, [year, month, day]);

    const getManualSettlementTittle = (type) => {
        switch (type) {
            case 1:
                return "Dodaj rozliczenie kontrahenta";
            case 3:
                return "Dodaj rozliczenie kierowcy";
        }
    };

    return (
        <div className={styles["rozliczenia"]}>
            <Paper className={classes.root}>
                <DateTabs
                    handleDayChange={handleDayChange}
                    handleMonthChange={handleMonthChange}
                    handleYearChange={handleYearChange}
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    day={day}
                    month={month}
                    year={year}
                    daysInMonth={daysInMonth}
                    style={{ overflowX: "auto !important" }}
                />
                <div className={styles["rozliczenia__description"]}>
                    <h1 className={styles["rozliczenia__description--title"]}>Rozliczenia</h1>
                    {roles.includes("ROLE_OPERATOR") && (
                        <div className={styles["rozliczenia__description--button"]}>
                            {roles.includes("ROLE_SUPER_OPERATOR") && (type.id === 1 || type.id === 3) && (
                                <Button
                                    className={classes.addNewDayOff}
                                    variant="outlined"
                                    onClick={() => setAddOrders(!addOrders)}
                                >
                                    {addOrders ? "Ukryj dodawanie" : getManualSettlementTittle(type.id)}
                                </Button>
                            )}
                        </div>
                    )}
                    <div className={styles["rozliczenia__wrapper--dayList"]}>
                        <Autocomplete
                            options={types}
                            onChange={handleChangeType}
                            disableClearable
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            value={type}
                            renderInput={(params) => (
                                <SingleInput
                                    {...params}
                                    label="Wybierz typ rozliczeń"
                                    variant="standard"
                                    margin="none"
                                    fullWidth
                                    style={{ width: 220, marginRight: 15 }}
                                />
                            )}
                        />
                        {isRoleType('OPERATOR') && !isRoleType('OPERATOR_CONTRACTOR') && (
                            <>
                                <div className={styles["rozliczenia__wrapper--dayList--description"]}>
                                    {settledOrders ? "Rozliczone kursy" : "Kursy oczekujące na rozliczenie"}
                                </div>
                                <Autocomplete
                                    options={contractors}
                                    onChange={handleChangeContractor}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    value={contractor}
                                    style={{ width: 200 }}
                                    renderInput={(params) => (
                                        <SingleInput
                                            {...params}
                                            label="Wybierz kontrahenta"
                                            variant="standard"
                                            margin="none"
                                            fullWidth
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                    {(roles.includes("ROLE_OPERATOR") || roles.includes("ROLE_ADMINISTRACJA")) &&
                    (type?.id === 1 || type?.id === 3) ? (
                        <Button
                            disabled={isLoading || !rowsLength}
                            className={classes.generateButton}
                            onClick={handleGenerateCsv}
                        >
                            Generuj CSV
                        </Button>
                    ) : null}
                </div>

                {roles.includes("ROLE_SUPER_OPERATOR") && addOrders && (type?.id === 1 || type?.id === 3) && (
                    <RozliczeniaForm
                        contractors={contractors}
                        billingGroups={billingGroups}
                        sendData={sendData}
                        type={type?.id}
                    />
                )}
                {
                    type?.id !== 2 &&
                    (
                        <span>
                            <div className={styles.legend}>
                                <div className={styles.item}>
                                    <FiberManualRecordIcon className={classes.wRealizacji} />
                                    <p className={styles.single}>
                                        Zrealizowany
                                    </p>
                                </div>
                                <div className={styles.item}>
                                    <FiberManualRecordIcon className={classes.anulowany} />
                                    <p className={styles.single}>
                                        Anulowany
                                    </p>
                                </div>
                                <div className={styles.item}>
                                    <FiberManualRecordIcon className={classes.odrzucony} />
                                    <p className={styles.single}>
                                        Odrzucony
                                    </p>
                                </div>
                            </div>
                        </span>
                    )
                }
                <div className={styles["rozliczenia__wrapper"]}>
                    <RozliczeniaTable
                        date={date}
                        type={type?.id}
                        settledOrders={settledOrders}
                        contractor={contractor}
                        setContractor={setContractor}
                        handleChangeContractor={handleChangeContractor}
                        isPostedData={isPostedData}
                        handleDatePickerDateChange={handleDatePickerDateChange}
                        clearDate={clearDate}
                        getDatePickerValue={getDatePickerValue}
                        roles={roles}
                        setParentSearchValues={setSearchValues}
                        setParentColumns={setColumns}
                        setRowsLength={setRowsLength}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default Rozliczenia;
