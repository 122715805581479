import React, {useContext, useState} from "react";
import styles from "./addKontrahentStructureForm.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Button} from "@material-ui/core";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { phoneRegExp } from "../../../helper";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
}));

export default function AddKontrahentStructureForm(props) {
    let actualAxiosRequest = null;
    const classes = useStyles();

    const ContractorSchema = Yup.object().shape({
        contractorName: Yup.string().required("Uzupełnij to pole"),
        dispatchName: Yup.string().required("Uzupełnij to pole"),
        dispatchEmail: Yup.string().required('Uzupełnij to pole').email('Nieprawidłowy format adresu email'),
        dispatchPhoneNumber: Yup.string()
            .required('Uzupełnij to pole')
            .matches(phoneRegExp, "Nieprawidłowy format numeru telefonu"),
    });

    return (
        <div className={styles["addKontrahentStructure"]}>
            <Formik
                initialValues={props.contractor}
                validationSchema={ContractorSchema}
                onSubmit={(values) => {
                    props.sendData(values);
                }}
            >
                {({values, errors, touched, setFieldValue}) => (
                    <Form className={styles["addKontrahentStructureForm"]}>
                        <div className={styles["addKontrahentStructureForm__description"]}>
                            <h1 className={styles["addKontrahentStructureForm__description--title"]}>
                                Dodaj kontrahenta
                            </h1>
                            <p className={styles["addKontrahentStructureForm__description--subTitle"]}>
                                Wypełnij wszystkie pola i zatwierdź
                            </p>
                        </div>
                        <>
                            <SimpleBar style={{maxHeight: "70vh", minWidth: 520}} className={classes.simplebar}>
                                <div className={styles["addKontrahentStructureForm__personal"]}>
                                    <div className={styles["addKontrahentStructureForm__personal--description"]}>
                                        <p>Dane firmy</p>
                                    </div>
                                    <div className={styles["addKontrahentStructureForm__personal--inputs"]}>
                                        <div className={styles["addKontrahentStructureForm__personal--inputs--field"]}>
                                            <Field
                                                className={classes.root}
                                                name="contractorName"
                                                helperText={
                                                    !!errors.contractorName && touched.contractorName ? errors.contractorName : ""
                                                }
                                                error={!!errors.contractorName && touched.contractorName}
                                                label="Nazwa kotrahenta*"
                                                variant="standard"
                                                style={{width: 240}}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                        <div className={styles["addKontrahentStructureForm__personal--inputs--field"]}>
                                            <Field
                                                className={classes.root}
                                                name="dispatchName"
                                                helperText={
                                                    !!errors.dispatchName && touched.dispatchName ? errors.dispatchName : ""
                                                }
                                                error={!!errors.dispatchName && touched.dispatchName}
                                                label="Nazwa dyspozytury"
                                                variant="standard"
                                                style={{width: 240}}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles["addKontrahentStructureForm__contact"]}>
                                    <div className={styles["addKontrahentStructureForm__contact--description"]}>
                                        <p>Dane kontaktowe</p>
                                    </div>
                                    <div className={styles["addKontrahentStructureForm__contact--inputs"]}>
                                        <div className={styles["addKontrahentStructureForm__contact--inputs--field"]}>
                                            <Field
                                                className={classes.root}
                                                name="dispatchPhoneNumber"
                                                helperText={
                                                    !!errors.dispatchPhoneNumber && touched.dispatchPhoneNumber
                                                        ? errors.dispatchPhoneNumber
                                                        : ""
                                                }
                                                error={
                                                    !!errors.dispatchPhoneNumber && touched.dispatchPhoneNumber
                                                }
                                                label="Telefon*"
                                                variant="standard"
                                                style={{width: 240}}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                        <div className={styles["addKontrahentStructureForm__contact--inputs--field"]}>
                                            <Field
                                                className={classes.root}
                                                name="dispatchEmail"
                                                helperText={
                                                    !!errors.dispatchEmail && touched.dispatchEmail
                                                        ? errors.dispatchEmail
                                                        : ""
                                                }
                                                error={!!errors.dispatchEmail && touched.dispatchEmail}
                                                label="E-mail"
                                                variant="standard"
                                                style={{width: 240}}
                                                margin="none"
                                                fullWidth
                                                as={TextField}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles["addKontrahentStructureForm__buttonWrapper"]}>
                                    <Button
                                        className={classes.confirmButton}
                                        variant="contained"
                                        type="submit"
                                    >
                                        Potwierdź
                                    </Button>
                                </div>
                            </SimpleBar>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
