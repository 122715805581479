import React from "react";
import OrderPanel from "../../../reusableComponents/newOrderPanel/OrderPanel";
import AppRoute from "../../../../AppRoute";

const EdytujRouteNew = () => {
  const EditOrderPanel = (props) => <OrderPanel {...props} entity="operator" type="edit" />
  return (
 
    <AppRoute
      app="operator"
      window="transport"
      tab="edytuj/:id"
      component={EditOrderPanel}
      />
   
  );
};

export default EdytujRouteNew;

