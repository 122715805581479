import React from "react";
import AddWorkerForm from "./AddWorkerForm";
import axios from "axios";
import {NotificationContext} from "../../../../context";
import produce from 'immer';
import HttpClient from "../../../../HttpClient";
import InfoDialog from "../../../reusableComponents/alerts/infoDialog/InfoDialog";

export default class AddWorker extends React.Component {
  static contextType = NotificationContext;
  source = axios.CancelToken.source();

  buildEmptyAddress = () => ({
    zipCode: "",
    city: "",
    street: "",
    no: "",
    flat: "",
    type: "",
    lat: null,
    lon: null,
  });

  buildEmptyWorker = () => ({
    name: "",
    surName: "",
    address: [this.buildEmptyAddress()],
    workPhoneNumber: "",
    workEmail: "",
    kontrahent: this.props.contractor ? this.props.contractor : null,
    regNo: "",
    engineCapacity: "Powyżej 900cm3",
    delegationRate: "1.15",
    position: "",
  });
  state = {
    worker: this.buildEmptyWorker(),
    openDialog: false,
    postDate: null,
    kontrahents: []
  };

  checkIfWorkerExists = async (name, surName, contractorId) => {
    const httpRequest = HttpClient.createRequest();
    try {
      const body = {name, surName, contractorId};
      const {data} = await httpRequest.get('/api/worker/check_if_exists', {
        cancelToken: this.source.token,
        params: {...body}
      }, true);
      if(data.isExists) {
        this.setState({openDialog: true});
        return data.isExists;
      }
    } catch (e) {}
  }

  addKontrahent = (data) => {
    this.setState({kontrahents: [...this.state.kontrahents, data]}, () => {
      const worker = produce(this.state.worker, draftState => {
        draftState.kontrahent = data;
      });
      this.setState({worker});
    });
  }

  getKontrahents = async () => {
    const httpRequest = HttpClient.createRequest();
    try {
      const {data} = await httpRequest.get('/api/allKontrahents', {cancelToken: this.source.token }, true);
      this.setState({kontrahents: data});
    } catch (e) {}
  }

  sendWorkerData = async (postData, accept = false) => {
    this.setState({postData});
    if(!accept) {
      const isExists = await this.checkIfWorkerExists(postData.name, postData.surName, postData.kontrahent?.id);
      if(isExists) return;
    }
    delete postData.alone;
    const httpRequest = HttpClient.createRequest();
    try {
      const response = await httpRequest.post('api/pracownik', postData, { cancelToken: this.source.token }, true);
      const worker = produce(this.state.worker, draftState => {
        draftState.kontrahent = null;
      });
      this.setState({worker});
      if (this.props.addDriverFromForm) {
        this.props.addDriverFromForm(response.data.worker);
      } else {
        setTimeout(() => window.location.reload(), 1500);
      }
      this.props.handleClose();
    } catch (e) {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getKontrahents();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  handleCloseModal = () => {
    this.setState({openDialog: false});
  }

  handleAccept = () => {
    this.setState({openModal: false}, () => {
      this.sendWorkerData(this.state.postData, true);
    })
  }

  render() {
    return (
      <>
        <AddWorkerForm
            contractor={this.props.contractor}
            addKontrahent={this.addKontrahent}
            kontrahents={this.state.kontrahents}
            worker={this.state.worker}
            buildEmptyAddress={this.buildEmptyAddress}
            sendWorkerData={this.sendWorkerData}
        />
        {this.state.openDialog && (
            <InfoDialog
                confirmAction={this.handleAccept}
                alertReset={this.handleCloseModal}
                message="Użytkownik o podanym imieniu i nazwisku został znaleziony w bazie, czy jesteś pewny, że chcesz stworzyć nowego użytkownika?"
            />
        )}
      </>
    );
  }
}
