import React from "react";

import AppRoute from "../../../AppRoute";
import { GeneratedTable } from "./GeneratedTable";


export const GeneratedRoute = () => {

  const columns = [
    {
      id: "status",
      label: "",
      sorted: false,
      query: false,
      order: null,
      minWidth: 50,
    },
    {
     id: "type",
     label: "Rodzaj",
     sorted: false,
     query: false,
     order: null,
     minWidth: 80,
    },
    {
      id: "nr",
      label: "Nr",
      sorted: false,
      order: null,
      query: true,
      minWidth: 100,
    },
    {
      id: "worker",
      label: "Pracownik",
      sorted: false,
      order: null,
      query: true,
      minWidth: 100,
    },
    {
      id: "startDate",
      label: "Data od",
      sorted: false,
      type: 'time',
      order: null,
      query: true,
      minWidth: 160,
    },
    {
      id: "endDate",
      label: "Data do",
      sorted: false,
      type: 'time',
      order: null,
      query: true,
      minWidth: 160,
    },
    {
      id: "createdAt",
      label: "Data utworzenia",
      sorted: false,
      order: null,
      query: true,
      type: 'time',
      minWidth: 160,
    },
    {
      id: "downloadDate",
      label: "Data pobrania",
      sorted: false,
      order: null,
      type: 'time',
      query: true,
      minWidth: 160,
    },
    {
      id: "totalCost",
      label: "Kwota",
      sorted: false,
      type: 'number',
      order: null,
      query: true,
      minWidth: 160,
    },
    {
      id: "totalKm",
      label: "KM",
      sorted: false,
      type: 'number',
      order: null,
      query: true,
      maxWidth: 120,
    },
    {
      id: "download",
      label: "",
      query: false,
      maxWidth: 50,
    },
    {
      id: "del",
      label: "",
      query: false,
      maxWidth: 50,
    },
  ];

  const Generated = () => (
    <GeneratedTable columns={columns} entity="dyspozytor" />
  );

  return (
    <AppRoute
      app="administracja"
      window="dokumenty"
      tab="delegacje"
      component={Generated}
    />
  );
};
