import * as Yup from "yup";
import HttpClient from 'HttpClient'
import * as qs from "qs";
import boolean from "yup/lib/boolean";

let roles = []
const OrderSchema = Yup.object().shape({
    orders: Yup.array()
        .of(
            Yup.object().shape({
                destination: Yup.object().shape({}).nullable().required("Uzupełnij dane"),
                workers: Yup.array()
                    .of(
                        Yup.object().shape({
                            person: Yup.object().shape({}).nullable().required("Uzupełnij dane"),
                            isWorkAddress: Yup.boolean(),
                            homeAddress: Yup.object()
                                .nullable()
                                .shape({})
                                .when("isWorkAddress", (isWorkAddress) => {
                                    if (!isWorkAddress)
                                        return Yup.object().shape({}).nullable().required("Uzupełnij pole");

                                    return Yup.object().shape({}).nullable();
                                }),
                            workAddress: Yup.object()
                                .nullable()
                                .shape({})
                                .when("isWorkAddress", (isWorkAddress) => {
                                    if (isWorkAddress)
                                        return Yup.object().shape({}).nullable().required("Uzupełnij pole");

                                    return Yup.object().shape({}).nullable();
                                }),
                        })
                    )
                    .test("timeDestination", "Niepoprawne pole", function () {
                        const hasPickedDate = () => {
                            const timeDestination = this.parent.timeDestination;

                            if (timeDestination && new Date(timeDestination).getTime() > new Date().getTime() ||
                            roles.indexOf('ROLE_OPERATOR') > -1)
                                return true;

                            const isWorkersTimeOrigin = this.parent.workers.findIndex((worker) => worker.timeOrigin);

                            if (
                                isWorkersTimeOrigin !== -1 &&
                                new Date(this.parent.workers[isWorkersTimeOrigin].timeOrigin).getTime() >
                                    new Date().getTime() || roles.indexOf('ROLE_OPERATOR') > -1
                            )
                                return true;

                            return false;
                        };

                        if (!hasPickedDate()) {
                            return this.createError({
                                message: "Niepoprawna data",
                                path: `${this.path.split(".")[0]}.timeDestination`,
                            });
                        }

                        return this.schema;
                    }),
            })
        )
        .test("orders", "Kalabunga", async function () {
            const workersWithOrderId = this.parent.orders.reduce((acc, curr, orderIndex) => {
                const workerWithTimeOrigin = curr.workers.find((elem) => elem.timeOrigin);

                const workerWithOrderId = curr.workers
                    .map((worker, workerIndex) => {
                        if (worker.person === null) return false;
                        //duration is in seconds
                        return {
                            orderId: curr.id,
                            workerId: worker?.person.id,
                            duration: curr.duration,
                            isChangedDirection: curr.isChangedDirection,
                            isStartTime: !!workerWithTimeOrigin,
                            timeDestination: workerWithTimeOrigin
                                ? new Date(workerWithTimeOrigin.timeOrigin)
                                : new Date(curr.timeDestination),
                            pathname: `orders[${orderIndex}].workers[${workerIndex}].person`,
                        };
                    })
                    .filter(Boolean);
                return [...acc, ...workerWithOrderId];
            }, []);

            const invalidWorkers = workersWithOrderId.reduce((acc, currWorker, workerIndex) => {
                const otherWorkers = workersWithOrderId.filter(
                    (worker, innerWorkerIndex) =>
                        worker.workerId === currWorker.workerId && workerIndex !== innerWorkerIndex
                );

                if (otherWorkers.length < 0) return acc;

                const totalTimeDuration = otherWorkers.reduce((acc, curr) => {
                    return acc + curr.duration;
                }, 0);

                const duplicates = otherWorkers.reduce((acc, curr) => {
                    const checkIfLower = Math.abs(currWorker.timeDestination.getTime() - curr.timeDestination.getTime()) / 1000
                    const ifWorkerIsInOtherOrders = !!otherWorkers.find((worker) => worker.workerId === currWorker.workerId)
                    if (checkIfLower < totalTimeDuration && !ifWorkerIsInOtherOrders) {
                        return [
                            ...acc,
                            new Yup.ValidationError("Pracownik jest zajęty", "Pracownik jest zajęty", curr.pathname),
                        ];
                    }
                    return acc;
                }, []);

                if (duplicates.length === 0) return acc;

                return [...acc, ...duplicates];
            }, []);

            if (invalidWorkers.length === 0) {
                const orders = workersWithOrderId.map((worker) => {
                    const workerTimeDestination = new Date(worker.timeDestination);

                    return {
                        checkedOrderId: worker.orderId,
                        startTime: Math.floor(
                            worker.isChangedDirection
                                ? worker.isStartTime
                                    ? new Date(workerTimeDestination.getTime() - worker.duration * 1000).getTime() /
                                      1000
                                    : workerTimeDestination.getTime() / 1000
                                : worker.isStartTime
                                ? workerTimeDestination.getTime() / 1000
                                : new Date(workerTimeDestination.getTime() - worker.duration * 1000).getTime() / 1000
                        ),
                        endTime: Math.floor(
                            worker.isChangedDirection
                                ? worker.isStartTime
                                    ? workerTimeDestination.getTime() / 1000
                                    : new Date(workerTimeDestination.getTime() + worker.duration * 1000).getTime() /
                                      1000
                                : worker.isStartTime
                                ? new Date(workerTimeDestination.getTime() + worker.duration * 1000).getTime() / 1000
                                : workerTimeDestination.getTime() / 1000
                        ),
                        worker: worker.workerId
                    };
                });

                const tmpOrderList = orders.map(order => {
                    return order.checkedOrderId
                })

                const orderList = [...new Set(tmpOrderList)]



                console.log('orderList', orderList)

                const httpRequest = HttpClient.createRequest();

                try {
                    const response = await httpRequest.get(
                        "api/isFree?",
                        {
                            params: {
                                orders,
                                orderList,
                                newOrderPanel: true
                            },
                            paramsSerializer: (params) => {
                                return qs.stringify(params);
                            },
                        },
                        true
                    );
                    // debugger;
                    if(response.data.length === 0) return true

                    const errors = response.data.map((order) => {
                        const findCorresponding = workersWithOrderId.find((worker) => worker.workerId === order.workerId)

                        return new Yup.ValidationError(["Pracownik jest zajęty"], "Pracownik jest zajęty", findCorresponding?.pathname)
                    })

                    // console.log(errors)
                    return new Yup.ValidationError(errors);
                } catch(e) {
                    console.log(e)
                    return new Yup.ValidationError(undefined)
                }
                // console.log(orders);

                // return true;
            }

            return new Yup.ValidationError(invalidWorkers);
        }),
});

const OrderSchemaSetRole = (role) => {
    roles = role
}

export { OrderSchema, OrderSchemaSetRole };
