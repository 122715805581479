import React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Button, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import HttpClient from "../../../HttpClient";

const initialValues = {
    password: '',
    newPassword: '',
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Uzupełnij to pole"),
  newPassword: Yup.string().required("Uzupełnij to pole"),
  newPasswordRepeat: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Hasła muszą być takie same")
      .required("Uzupełnij to pole"),
    otherwise: Yup.string(),
});

export default function PasswordChange({classes}) {

    const sendData = async (postData, resetForm) => {
        const httpRequest = HttpClient.createRequest();
        try {
            await httpRequest.post('api/password-change', postData, {}, true);
            resetForm();
        } catch (e) {
        }
    }

    return (
        <Accordion
            style={{
                width: 522,
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #efefef",
                boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.07)"
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{padding: "0 10px", margin: 0}}
            >
                <Typography style={{
                    margin: 0,
                    padding: "5px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "19px"
                }}>
                    Hasło
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Formik
                    initialValues={initialValues}
                    validationSchema={PasswordSchema}
                    enableReinitialize
                    onSubmit={async (values, {resetForm}) => {
                        sendData(values, resetForm);
                    }}
                >
                    {({values, errors, touched, resetForm}) => (
                        <Form>
                            <Field
                                name="password"
                                helperText={
                                    !!errors.password && touched.password
                                        ? errors.password
                                        : ""
                                }
                                error={!!errors.password && touched.password}
                                label="Obecne hasło"
                                variant="standard"
                                type="password"
                                style={{width: 240, display: 'block', marginBottom: '1rem'}}
                                margin="none"
                                fullWidth
                                as={TextField}
                            />
                            <Field
                                name="newPassword"
                                helperText={
                                    !!errors.newPassword && touched.newPassword
                                        ? errors.newPassword
                                        : ""
                                }
                                error={!!errors.newPassword && touched.newPassword}
                                label="Nowe hasło"
                                variant="standard"
                                style={{width: 240}}
                                margin="none"
                                type="password"
                                as={TextField}
                                fullWidth
                            />
                            <Field
                                name="newPasswordRepeat"
                                helperText={
                                    !!errors.newPasswordRepeat && touched.newPasswordRepeat
                                        ? errors.newPasswordRepeat
                                        : ""
                                }
                                error={!!errors.newPasswordRepeat && touched.newPasswordRepeat}
                                label="Powtórz nowe hasło"
                                variant="standard"
                                style={{width: 240, marginBottom: '1rem'}}
                                margin="none"
                                type="password"
                                as={TextField}
                                fullWidth
                            />
                            <Button
                                className={classes.confirmButton}
                                variant="contained"
                                type="submit"
                                style={{
                                    margin: "20px 0"
                                }}
                            >
                                Potwierdź
                            </Button>
                        </Form>
                    )}
                </Formik>
            </AccordionDetails>
        </Accordion>
    );
}
