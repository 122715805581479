import React, {useState, useEffect} from "react";
import ListItem from "@material-ui/core/ListItem";
import {NavLink} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import ErrorIcon from '@material-ui/icons/Error';
import HttpClient from "../../../HttpClient";
import UIfx from 'uifx';
import bellAudio from '../../../img/unfill_alert.mp3';


const AppTabEntity = (props) => {
    const {app, tab, win, data, classes} = props;
    const target = "/app/" + app + "/" + win + "/" + tab;
    const [isAlert, setIsAlert] = useState(false);

    // const bell = new UIfx(
    //     bellAudio,
    //     {
    //         volume: 0.4, // number between 0.0 ~ 1.0
    //         throttleMs: 100
    //     }
    // )

    const getData = async () => { }

    useEffect(() => {
        getData()
    }, []);

    return (
        <ListItem
            button={true}
            component={NavLink}
            className={classes.listItem}
            to={target}
            activeClassName={classes.active}
        >
            <div className={classes.listItemTreeWrapper}>
                <div className={classes.listItemTree}></div>
            </div>
            {target === "/app/operator/trasy/dopotwierdzenia" && isAlert ?
                <>
                    <ListItemText
                        className={classes.unFillSubNested}
                        primary={data.label}/>
                    <ErrorIcon className={classes.unFillIcon}/>
                </>
                :
                <ListItemText
                    className={classes.subNested}
                    primary={data.label}/>
            }
        </ListItem>
    );
};

export default AppTabEntity;
