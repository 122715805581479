import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import {Point, LineString} from "ol/geom";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Fill, Icon, Stroke, Style} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import {NotificationContext} from "../../../../../context";
import HttpClient from "../../../../../HttpClient";
import whiteMarker from "../../../../../img/marker_white.png"
import config from "../../../../../config";

export default class ConfirmationMap extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.center = [19.479744, 52.068813];
        this.startCoords = [this.props.data.from ? this.props.data.from.lon : 18.610640, this.props.data.from ? this.props.data.from.lat : 53.014740];
        this.endCoords = [this.props.data.to ? this.props.data.to.lon : 18.610640, this.props.data.to ? this.props.data.to.lat : 53.014740];
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 6.5;
        this.marker = whiteMarker;
        this.route = null;
        this.distance = null;
    }

    componentDidMount() {
        this.map = new Map({
            view: new View({
                center: fromLonLat(this.center),
                zoom: this.zoom,
            }),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: this.props.id,
        });

        let startColor = [102, 208, 29, 0.8];
        let endColor = [29, 187, 237, 1];

        this.getRoutes(endColor);
        this.createMarker(this.startCoords, "startPoint", startColor);
        this.createMarker(this.endCoords, "endPoint", endColor);
    }

    getRoutes = async (singleColor) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`${config.osrmURL}/route/v1/driving/${this.startCoords[0]},${this.startCoords[1]};${this.endCoords[0]},${this.endCoords[1]}?steps=true&geometries=geojson&overview=full`);
            this.route = response.data;
            this.distance = Math.round(this.route.routes[0].distance / 1000);
            this.createRoute(this.route, singleColor);
        } catch (e) {
        }
    }

    createMarker(coords, markerId, singleColor) {
        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this.map.addLayer(iconVectorLayer);
    }

    createLine(prevStep, singleStep, singleColor) {
        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        let feature = new Feature({
            geometry: lineString,
            name: "Line",
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: singleColor,
                width: 4,
            }),
        });

        let source = new VectorSource({
            features: [feature],
        });
        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });
        this.map.addLayer(vector);

    }

    createRoute(route, singleColor) {
        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]]
                    this.createLine(prevStep, singleStep, singleColor);
                }
            }
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            ></div>
        );
    }
}
