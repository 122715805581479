import React, {useContext, useState} from "react";
import axios from "axios";
import styles from "./addAddressInOrder.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { Modal, Button, Backdrop, Fade, TextField } from "@material-ui/core";
import { Formik, Form, Field, useField } from "formik";
import { debounce } from "../../../debounce";
import OsmMap from "../maps/osmMap/OsmMap";
import {NotificationContext} from "../../../context";
import HttpClient from "../../../HttpClient";
import config from "../../../config";

const useStyles = makeStyles((theme) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  root: {
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
      color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1DBBED",
      },
      "&:hover fieldset": {
        borderColor: "#1DBBED",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1DBBED",
      },
    },
  },
  paper: {
    outline: "0 !important",
    position: "fixed",
    height: "auto-fit",
    top: "95px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  confirmButton: {
    marginTop: "15px",
    backgroundColor: "#1DBBED",
    width: "300px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "0px",
    textTransform: "none",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#00a0e3",
    },
  },
}));

const addressTypes = ["Domowy", "Hotel", "Inny", "Gniazdo"];

const AddAddressForm = (props) => {
  const classes = useStyles();
  let actualAxiosRequest = null;
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [newLat, setNewLat] = useState(52.215933);
  const [newLon, setNewLon] = useState(19.134422);
  const [addressSearch, setAddressSearch] = useState([]);
  const [ignoreForm, setIgnoreForm] = useState(true);
  const timer = React.useRef();

  const handleClose = () => {
    props.showAddAddressForm(false);
  };

  const fillAddressFields = (value, setFieldValue) => {
    setIgnoreForm(false);
    const { address } = value;
    const addressField = {
      zipCode: address.postcode || "",
      city: address.city || address.village || address.town || address.hamlet || "",
      get street() {
        return address.road || this.city || "";
      },
      no: address.house_number || "",
      lat: value.lat,
      lon: value.lon,
    };

    for (const key in addressField) {
      setFieldValue(`${key}`, addressField[key]);
    }
    setNewLat(value.lat);
    setNewLon(value.lon);
  };

  const handleAddressChange = async (value) => {
    setIgnoreForm(true);
    if (actualAxiosRequest) {
      actualAxiosRequest.cancel();
    }
    actualAxiosRequest = axios.CancelToken.source();
    const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;
    const httpRequest = HttpClient.createRequest();
    try {
        const response = await httpRequest.get(url, { cancelToken: actualAxiosRequest.token });
      const { data } = response;
      const options = [];

      data.map((item) => {
        options.push({
          id: item.place_id,
          name: item.display_name,
          address: item.address,
          lat: item.lat,
          lon: item.lon,
        })});


      setAddressSearch(options);
    } catch (e) {
      if (axios.isCancel(e)) {
        actualAxiosRequest = null;
      }
    }
  };

  const moveMarker = (value) => {
    if (value.lat !== null || value.lon !== null) {
      setIgnoreForm(false);
      setNewLat(value.lat);
      setNewLon(value.lon);
    }
  };

  const search = debounce((value) => {
    handleAddressChange(value);
  }, 2000);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const sendData = async (postData) => {
    if (props.for === "work") {
      postData.type = "STATION";
    }

    // mozliwe ze do zmiany

    const httpRequest = HttpClient.createRequest();
    try {
        const response = await httpRequest.post(props.url, postData, {}, true);
      let data = response.data;
      if (props.for === "worker") {
        data = response.data;
        data.driverId = postData.id;
      }
      props.addToPropertyInOrder(response.data, props.field, props.groupIndex, props.index, props.formIndex);
    } catch (e) {}
  };

  const handleButtonClick = async (postData) => {
    await sendData(postData);
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 150);
      handleClose();
    }
  };

  const MyField = ({ label, ...props }) => {
    const { params, name } = props;
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";

    if (name === "type") {
      return (
        <Field
          {...params}
          label={label}
          {...field}
          disabled={props.disabled}
          className={classes.root}
          helperText={errorText}
          error={!!errorText}
          variant="standard"
          as={TextField}
          margin="none"
          fullWidth
        />
      );
    } else {
      return (
        <Field
          label={label}
          {...field}
          disabled={props.disabled}
          className={classes.root}
          helperText={errorText}
          error={!!errorText}
          variant="standard"
          as={TextField}
          margin="none"
          fullWidth
        />
      );
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={styles["addAddress__description"]}>
              <span
                className={styles["addAddress__description--close"]}
                onClick={handleClose}
              >
                {" "}
                X
              </span>
            </div>
            <div className={styles["addAddress__wrapper"]}>
              <Formik
                validateOnChange
                initialValues={{
                  id: props.pracownik ? props.pracownik.driverId : null,
                  city: "",
                  zipCode: "",
                  street: "",
                  no: "",
                  flat: "",
                  type: props.for === "work" ? "Praca" : "",
                  lat: null,
                  lon: null,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.street) {
                    errors.street = "Uzupełnij pole";
                  }
                  if (!values.no) {
                    errors.no = "Uzupełnij pole";
                  }

                  if (!values.city) {
                    errors.city = "Uzupełnij pole";
                  }

                  if (!values.type) {
                    errors.type = "Uzupełnij pole";
                  }
                  return errors;
                }}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  let dataType = "HOME";
                  switch (data.type) {
                    case "Hotel":
                      dataType = "HOTEL";
                      break;
                    case "Gniazdo":
                      dataType = "NEST";
                      break;
                    case "Inny":
                      dataType = "OTHER";
                      break;
                  }

                  handleButtonClick(data);
                  setSubmitting(false);
                  resetForm();
                }}
              >
                {({ values, isSubmitting, setFieldValue }) => (
                  <Form className={styles["addAddressForm"]} noValidate>
                    <div
                      className={
                        styles["addAddress__wrapper--form--description"]
                      }
                    >
                      <h1
                        className={
                          styles[
                            "addAddress__wrapper--form--description--title"
                          ]
                        }
                      >
                        Nowy adres
                      </h1>
                      <p
                        className={
                          styles[
                            "addAddress__wrapper--map--description--subTitle"
                          ]
                        }
                      >
                        Wyszukaj adres i uzupełnij brakujące dane
                      </p>
                    </div>
                    <div className={styles["addAddress__wrapper--form"]}>
                      <div
                        className={styles["addAddress__wrapper--form--search"]}
                      >
                        <div
                          className={
                            styles["addAddress__wrapper--form--search--field"]
                          }
                        >
                          <Autocomplete
                            options={addressSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            filterOptions={(options, state) => options}
                            noOptionsText="Nie znaleziono podanego adresu"
                            onOpen={() => moveMarker(values)}
                            onChange={(e, value) => {
                              if (value)
                                fillAddressFields(value, setFieldValue);
                            }}
                            onInputChange={(e, newValue) => {
                              if (newValue.trim()) search(newValue);
                            }}
                            renderInput={(params) => (
                              <Field
                                {...params}
                                className={classes.root}
                                label="Wyszukaj adres"
                                variant="filled"
                                margin="none"
                                name="alone"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                as={TextField}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          styles["addAddress__wrapper--form--fieldGroup"]
                        }
                      >
                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <MyField
                            disabled
                            label="Ulica"
                            name="street"
                            style={{ width: 240 }}
                          />
                        </div>

                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <MyField
                            label="Nr budynku"
                            name="no"
                            style={{ width: 90 }}
                          />
                        </div>
                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <MyField
                            label="Nr lokalu"
                            name="flat"
                            style={{ width: 90 }}
                          />{" "}
                        </div>
                      </div>
                      <div
                        className={
                          styles["addAddress__wrapper--form--fieldGroup"]
                        }
                      >
                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <MyField
                            // disabled
                            label="Kod pocztowy"
                            name="zipCode"
                            style={{ width: 90 }}
                          />
                        </div>

                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <MyField
                            label={
                              props.for === "work" ? "Nazwa stacji" : "Miasto"
                            }
                            name="city"
                            style={{ width: 150 }}
                          />
                        </div>
                        <div
                          className={
                            styles[
                              "addAddress__wrapper--form--fieldGroup--field"
                            ]
                          }
                        >
                          <Autocomplete
                            options={addressTypes}
                            getOptionLabel={(option) => option}
                            defaultValue={
                              props.for === "work" ? "Praca" : undefined
                            }
                            disabled={props.for === "work"}
                            onChange={(e, newValue) => {
                              let dataType = "HOME";
                              switch (newValue) {
                                case "Hotel":
                                  dataType = "HOTEL";
                                  break;
                                case "Gniazdo":
                                  dataType = "NEST";
                                  break;
                                case "Inny":
                                  dataType = "OTHER";
                                  break;
                              }
                              setFieldValue("type", dataType, true);
                            }}
                            renderInput={(params) => (
                              <MyField
                                params={params}
                                label="Typ adresu"
                                name="type"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <Button
                      className={classes.confirmButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Potwierdź
                    </Button>
                  </Form>
                )}
              </Formik>

              <div className={styles["addAddress__wrapper--map"]}>
                <div
                  className={styles["addAddress__wrapper--map--description"]}
                >
                  <h1
                    className={
                      styles["addAddress__wrapper--map--description--title"]
                    }
                  >
                    Mapa poglądowa
                  </h1>
                  <p
                    className={
                      styles["addAddress__wrapper--map--description--subTitle"]
                    }
                  >
                    Śledź znacznik aktualnie wpisywanego adresu
                  </p>
                </div>
                <div className={styles["addAddress__wrapper--map--canvas"]}>
                  <OsmMap
                    width="600px"
                    height="478px"
                    pickedLat={newLat}
                    pickedLon={newLon}
                    ignoreForm={ignoreForm}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default AddAddressForm;
