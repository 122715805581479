import {add, sub} from "date-fns";

export default function generateOrderTimes(order) {
    let startTime;
    let endTime;

    const hours = Math.floor(order.approximateTime.value / 3600);
    const minutes = Math.floor((order.approximateTime.value - (hours * 3600)) / 60);

    const durationObj = {
        hours,
        minutes
    };

    let approximateTime;

    if(order.direction.value) {
        approximateTime = order.timeHome.value ? add(order.timeHome.value, durationObj) : sub(order.timeWork.value, durationObj);
        startTime = order.timeHome.value ? order.timeHome.value : approximateTime;
        endTime = order.timeWork.value ? order.timeWork.value : approximateTime;
    } else {
        approximateTime = order.timeWork.value ? add(order.timeWork.value, durationObj) : sub(order.timeHome.value, durationObj);
        startTime = order.timeWork.value ? order.timeWork.value : approximateTime;
        endTime = order.timeHome.value ? order.timeHome.value : approximateTime;
    }

    return {
        startTime,
        endTime
    }
}
