import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./css/messageColumn.module.scss";
import MessageGroup from "./MessageGroup";
import HttpClient from "../../../../../HttpClient";
import { TextField } from "@material-ui/core";
import {CloudDownload, SendOutlined} from "@material-ui/icons";
import {MimicContext} from "../../../../../context";

function MessageColumn(props) {
    const {isRoleType} = useContext(MimicContext)
    const [messages, setMessages] = useState([]);
    const [messagesAreLoading, setMessagesAreLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [newMessageContent, setNewMessageContent] = useState("");
    const [messageDuringSending, setMessageDuringSending] = useState(false);
    const [messageRead, setMessageRead] = useState(1);
    const [textareaRows, setTextAreaRows] = useState(1);

    const userRoles = props.userRoles;
    const messageType = props.messageType;
    const ref = useRef(null);

    let prevAuthor = "";
    let prevDate = 9999999999999;

    const operatorDriver = (
        props.courseDetails.driver?.billingGroup.name !== "CS" &&
        props.courseDetails.driver?.billingGroup.name !== "K27"
    )

    const getColumnDescription = () => {
        switch (props.entity) {
            case "dyspozytor": {
                if (messageType === "dispatch") {
                    return (props.partner === null) ? props.courseDetails.kontrahent.name : "Cargo Speed International"
                }
                else if (messageType === "notes") return "Notatki";
                break;
            }
            case "operator": {
                if (messageType === "dispatch") {
                    if (userRoles.includes('ROLE_OPERATOR_CONTRACTOR')) {
                        return (props.partner === null) ? props.courseDetails.kontrahent.name : "Cargo Speed International";
                    }
                    else if (!userRoles.includes('ROLE_OPERATOR'))
                        return 'Cargo Speed International'
                    else
                        return `Klient: ${props.courseDetails.kontrahent.name}`
                }
                else if (messageType === "driver")
                    return props.courseDetails.driver
                        ? `Kierowca: ${props.courseDetails.driver.firstname} ${props.courseDetails.driver.surname}`
                        : "Kierowca: brak kierowcy";
                else if (messageType === "notes")
                    return "Notatki";
            }
        }
    };

    const getCommUrlRest = () => {
        switch (messageType) {
            case "dispatch":
                return "operator-dispatch";
            case "driver":
                return "operator-driver";
            case "notes":
                return "notes";
        }
    };

    const entityToMessageType = () => {
        switch (props.entity) {
            case "dyspozytor":
                return "dispatch";
            case "kierowca":
                return "driver";
            case "operator":
                return "operator";
        }
    };

    const userIsAllowed = (partner) => {
        if (isRoleType('DYSPOZYTOR') || isRoleType('ADMINISTRACJA') || (isRoleType('OPERATOR_CONTRACTOR') && partner !== null))
            return (messageType === 'dispatch' || messageType === 'notes')
        else if (isRoleType('KIEROWCA'))
            return messageType === 'driver'
        else return isRoleType('OPERATOR') || isRoleType('ADMINISTRACJA') ||
                isRoleType('ADMINISTRATOR') || isRoleType('OPERATOR_PARTNER') || (isRoleType('OPERATOR_CONTRACTOR') && partner === null);
    }
    //Operator Dyspozytor pierwsza kolumna
    const isMissingDriver = () => {
        return messageType === "driver" && !props.courseDetails.driver;
    };

    const getMessages = async () => {
        setMessagesAreLoading(true)
        const requestUrl = `api/order/comms/${getCommUrlRest()}`;
        const httpRequest = HttpClient.createRequest();

        httpRequest.get(
            requestUrl,
            {
                params: {
                    zlecenie: props.courseDetails.id,
                },
            },
            true,
            false,
            false
        ).then((response) => {
            const data = response.data.data;
            const newMessages = data.messages ?? [];
            setMessages(newMessages);
            setMessagesAreLoading(false)
        });
    };

    useEffect(() => {
        getMessages().then(() => {});
    }, [props.courseDetails.id, props.messagesRead]);

    useEffect(() => {
        if (messages.length) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.authorGroup !== entityToMessageType() && !lastMessage.readFlag) setMessageRead(0);

            if (ref.current) ref.current.scrollTo(0, ref.current.scrollHeight - ref.current.offsetHeight);
            props.onUpdateRead();
        }
    }, [messages]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setMessageRead(1);
        }, 5000);
        return () => clearTimeout(timeOut);
    }, []);

    useEffect(() => {
        if (messageSent)
            getMessages().then(() => {
                setMessageSent(false);
            });
    }, [messageSent]);

    const messageContent = (e) => {
        setNewMessageContent(e.target.value);
        if (e.target.clientHeight < e.target.scrollHeight) {
            setTextAreaRows(2);
        }
    };

    const prepareSendMessage = (e = null) => {
        if (e === null || (e.key === "Enter" && e.ctrlKey && !messageDuringSending))
            setMessageDuringSending(true);
    };

    const sendMessage = async () => {
        const httpRequest = HttpClient.createRequest();
        if (messageType && newMessageContent.length) {
            httpRequest
                .post(
                    `api/order/comms/` + getCommUrlRest(messageType),
                    {
                        message: newMessageContent,
                        zlecenie: props.courseDetails.id,
                    },
                    true,
                    true,
                    true
                )
                .then(() => {
                    setMessageDuringSending(false);
                    setMessageSent(true);
                    setMessageRead(1);
                    setNewMessageContent("");
                });
            props.onUpdateRead();
        }
    };

    useEffect(() => {
        if (messageDuringSending) sendMessage().then(() => {});
    }, [messageDuringSending]);

    if (!userIsAllowed(props.partner)) return null;

    return (
        <div className={styles.messageColumn}>
            <div>
                <h1
                    style={{
                        fontSize: 20,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                    }}
                    title={getColumnDescription()}
                >
                    {getColumnDescription()}
                </h1>

                {messageRead === 0 ? (
                    <span
                        className={styles.newMessage}
                        onClick={() => {
                            setMessageRead(1);
                        }}
                    >
                        •
                    </span>
                ) : null}
            </div>
            <div className={styles.messageWindow} ref={ref}>
                {messagesAreLoading && <p>Ładowanie wiadomości...</p>}
                {messages.length > 0 && (
                    messages.map((message) => {
                        const isNewUser = () => {
                            const date = new Date(message.createTime.date).getTime() / 1000;
                            const isNew = prevAuthor !== message.author || date - prevDate > 60;
                            prevAuthor = message.author;
                            prevDate = date;
                            return isNew;
                        };
                        return (
                            <MessageGroup
                                key={`${messageType}-message-${message.createTime.date}`}
                                message={message}
                                userRoles={userRoles}
                                newUser={isNewUser()}
                                isRoleType={isRoleType}
                            />
                        );
                    })
                )}
                {!messagesAreLoading && !messages.length && <span className={styles.messageInfo}>brak wiadomości</span>}
            </div>
            <div className={styles.inputBar}>
                <TextField
                    id="outlined-multiline-static"
                    key={`${messageType}-message-input`}
                    label={isMissingDriver() ? "BRAK PRZYPISANEGO KIEROWCY" : "Wiadomość"}
                    onChange={(e) => messageContent(e)}
                    value={newMessageContent}
                    autoComplete="off"
                    onKeyPress={(e) => prepareSendMessage(e)}
                    className={styles.inputMargin}
                    multiline
                    disabled={isMissingDriver()}
                    rows={textareaRows}
                />
                {!isMissingDriver() && (
                    <SendOutlined className={styles.sendButton} onClick={() => prepareSendMessage()} />
                )}
            </div>
        </div>
    );
}

export default MessageColumn;
