import React, {useEffect, useState} from "react";
import styles from "./driversMap.module.scss";
import DriversMapComponent from "./driversMapComponent/DriversMapComponent";
import HttpClient from "../../../HttpClient";
import axios from "axios";
import {getUnixTime} from "date-fns";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {makeStyles} from "@material-ui/core/styles";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    middlePoint: {
        color: "rgb(29, 187, 237)",
    },
})


export default function DriversMap() {
    const classes = useStyles();
    const [courses, setCourses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const source = axios.CancelToken.source();

    const getData = async () => {
        window.scrollTo(0, 0);

        let currentTime = getUnixTime(new Date());
        let startTime = currentTime - 50 * 24 * 60 * 60;
        let endTime = currentTime + 50 * 24 * 60 * 60;

        const apiDrivers = `api/zajeci-kierowcy?regNo=1&from=${startTime}&to=${endTime}`;
        const apiCourses = "api/zlecenie/unfill";

        const requestDrivers = HttpClient.createRequest();
        const requestCourses = HttpClient.createRequest();
        try {
            const driversResponse = await requestDrivers.get(apiDrivers, {}, true);
            const coursesResponse = await requestCourses.get(apiCourses, {}, true);

            for (let i = 0; i < coursesResponse.data.length; i++) {
                coursesResponse.data[i].value = null;
            }

            setCourses(coursesResponse.data);
            setDrivers(driversResponse.data);

        } catch (e) {
        }
    };

    useEffect(() => {
        getData();

        return () => {
            source.cancel();
        };
    }, []);

    return (
        <SimpleBar style={{maxHeight: "85vh"}} className={classes.simplebar}>
            <div className={styles["driversMap"]}>
                <div className={styles["driversMap__description"]}>
                    <p className={styles["driversMap__description--title"]}>Mapa wszystkich kierowców oraz
                        nieobsadzonych
                        kursów</p>
                    <p className={styles["driversMap__description--subtitle"]}>
                        Kliknij na wybrany punkt lub trasę, żeby zobaczyć szczegóły.</p>
                    <div className={styles["driversMap__description--legend"]}>
                        <div className={styles["driversMap__description--legend--legendItem"]}>
                            <div className={styles["driversMap__description--legend--legendItem--startPoint"]}></div>
                            <p className={styles["driversMap__description--legend--legendItem--title"]}>
                                Start kursu
                            </p>
                        </div>
                        <div className={styles["driversMap__description--legend--legendItem"]}>
                            <div className={styles["driversMap__description--legend--legendItem--metaPoint"]}></div>
                            <p className={styles["driversMap__description--legend--legendItem--title"]}>
                                Meta kursu
                            </p>
                        </div>
                        <div className={styles["driversMap__description--legend--legendItem"]}>
                            <FiberManualRecordIcon className={classes.middlePoint}/>
                            <p className={styles["driversMap__description--legend--legendItem--title"]}>
                                Punkty pośrednie kursu
                            </p>
                        </div>
                        <div className={styles["driversMap__description--legend--legendItem"]}>
                            <div className={styles["driversMap__description--legend--legendItem--driverPoint"]}></div>
                            <p className={styles["driversMap__description--legend--legendItem--title"]}>
                                Kierowca
                            </p>
                        </div>
                    </div>
                    <div className={styles["driversMap__map"]}>
                        <DriversMapComponent courses={courses ? courses : null} drivers={drivers ? drivers : null}/>
                    </div>
                </div>
            </div>
        </SimpleBar>
    );
}
