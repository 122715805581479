import React from "react";
import Drivers from "./Drivers";
import AppRoute from "../../../../AppRoute";

const DriversRoute = () => {
  return (
    <AppRoute
      app="operator"
      window="kierowcy"
      tab="kierowcy"
      component={Drivers}
    />
  );
};

export default DriversRoute;
