import React from "react";
import Active from "./Active";
import AppRoute from "../../../../AppRoute";

const ActiveRoute = () => {
  return (
    <AppRoute app="dyspozytor" window="transport" tab="aktywne" component={Active} />
  );
};

export default ActiveRoute;
