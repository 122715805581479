import React, {useContext, useState, useEffect, useMemo, useRef} from "react";
import styles from "./AddAddressForm.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Button, MenuItem} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {Formik, Form, Field, FieldArray, getIn} from "formik";
import * as Yup from "yup";
import OsmMap from "../../../reusableComponents/maps/osmMap/OsmMap";
import axios from "axios";
import {debounce} from "../../../../debounce";
import {NotificationContext} from "../../../../context";
import HttpClient from "../../../../HttpClient";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import "simplebar/dist/simplebar.min.css";
import Cookie from "js-cookie";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import config from "../../../../config";

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    root: {
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    confirmButton: {
        minWidth: "200px",
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "18px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
}));

const addressTypes = ['Stacja', 'Hotel', 'Inny', 'Gniazdo'];
const addressValues = {
    Stacja: 'STATION',
    Hotel: 'HOTEL',
    Inny: 'OTHER',
    Gniazdo: 'NEST'
}

const RailyCheckbox = withStyles({
    root: {
        color: "#1dbbed",
        "&$checked": {
            color: "#1dbbed",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function AddAddressForm(props) {
    const createAddressField = () => ({
        addresses: [],
    });

    let actualAxiosRequest = null;
    const {setNotification} = useContext(NotificationContext);
    const classes = useStyles();
    const [newLat, setNewLat] = useState(52.215933);
    const [newLon, setNewLon] = useState(19.134422);
    const [addressSearch, setAddressSearch] = useState([createAddressField()]);
    const [addressFromClick, setAddressFromClick] = useState({})
    const [ignoreForm, setIgnoreForm] = useState(true);
    const zipCodeRegExp = /([0-9]{2}\-[0-9]{3})|([0-9-]{1,8})/;

    const loggedUser = Cookie.get("loggedUser");

    const handleIgnoreForm = () => {
        if (!ignoreForm) {
            setIgnoreForm(true);
        } else return;
    };

    const handleCreateAddress = () => {
        setAddressSearch([...addressSearch, createAddressField()]);
    };

    const handleRemoveAddress = () => {
        const _addressSearch = [...addressSearch];
        _addressSearch.pop();
        setAddressSearch(_addressSearch);
    };

    const fillAddressFields = (value, setFieldValue) => {
        setIgnoreForm(false);
        const {address} = value;
        const addressField = {
            zipCode: address.postcode || "",
            city: address.city || address.village || address.town || address.hamlet || "",
            get street() {
                return address.road || this.city || "";
            },
            no: address.house_number || "",
            lat: value.lat,
            lon: value.lon,
        };

        for (const key in addressField) {
            setFieldValue(key, addressField[key]);
        }

        setNewLat(value.lat);
        setNewLon(value.lon);
    };

    const handleAddressChange = async (value, fromMapClick = false, formikFields = null) => {
        setIgnoreForm(true);
        if (actualAxiosRequest) {
            actualAxiosRequest.cancel();
        }
        actualAxiosRequest = axios.CancelToken.source();
        const url = `${config.railyNominatimURL}/search?q=${value}&addressdetails=1&accept-language=pl&format=json`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {
                cancelToken: actualAxiosRequest.token,
            });
            const {data} = response;
            if (!fromMapClick) {
                const options = [];

                data.map((item) => {
                    options.push({
                        id: item.place_id,
                        name: item.display_name,
                        address: item.address,
                        lat: item.lat,
                        lon: item.lon,
                    });
                });
                setAddressSearch(options);
            } else {
                const address = {
                    id: data[0].place_id,
                    name: data[0].display_name,
                    address: data[0].address,
                    lat: data[0].lat,
                    lon: data[0].lon
                }
                setAddressFromClick(address)
                fillAddressFields(address, formikFields)
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                actualAxiosRequest = null;
            }
        }
    };

    const moveMarker = (value) => {
        if (value.lat !== null || value.lon !== null) {
            setIgnoreForm(false);
            setNewLat(value.lat);
            setNewLon(value.lon);
        }
    };

    // const ref = useRef(null);

    // console.log(ref.current?.values)

    const search = debounce((value) => {
        handleAddressChange(value);
    }, 2000);

    const AddressSchema = Yup.object().shape({
        zipCode: Yup.string()
            .matches(zipCodeRegExp, "Nieprawidłowy format numeru pocztowego")
            .required("Uzupełnij to pole"),
        city: Yup.string().required("Uzupełnij to pole"),
        street: Yup.string().required("Uzupełnij to pole"),
        no: Yup.string().required("Uzupełnij to pole"),
        flat: Yup.string(),
        type: Yup.string().required('Uzupełnij to pole'),
        alone: Yup.string(),
        address: Yup.string()
    })

    const mapClicked = async (lonlat, setFieldValue) => {
        const value = lonlat.split(',')
        handleAddressChange((value[1] + ',' + value[0]).toString(), true, setFieldValue)
    }

    return (
        <div className={styles["addAddress"]}>
            <Formik
                // innerRef={ref}
                initialValues={props.address}
                validationSchema={AddressSchema}
                onSubmit={(values) => {
                    props.sendAddressData(values, false);
                }}
            >
                {({values, errors, touched, setFieldValue}) => (
                    <Form className={styles["addAddressForm"]}>
                        <>
                            <SimpleBar style={{maxHeight: "70vh", minWidth: 520, padding: 10}} className={classes.simplebar}>
                                <div className={styles["addAddressForm__description"]}>
                                    <h1 className={styles["addAddressForm__description--title"]}>Dodaj adres</h1>
                                    <p className={styles["addAddressForm__description--subTitle"]}>
                                        Wypełnij wszystkie pola i zatwierdź
                                    </p>
                                </div>
                                <div className={styles["addAddressForm__address"]}>
                                    <div className={styles["addAddressForm__address--description"]}>
                                        <p>Dane adresowe</p>
                                    </div>
                                    <FieldArray
                                        name="address"
                                        render={({push, remove}) => (
                                            <>
                                                <Autocomplete
                                                    options={addressSearch ?? []}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    noOptionsText="Nie znaleziono podanego adresu"
                                                    filterOptions={(options, state) => options}
                                                    // onOpen={() => moveMarker()}
                                                    onChange={(e, value) => {
                                                        if (value) {
                                                            fillAddressFields(
                                                                value,
                                                                setFieldValue
                                                            );
                                                        }
                                                    }}
                                                    onInputChange={(e, newValue) => {
                                                        if (newValue.trim()) search(newValue, 0);
                                                    }}
                                                    renderInput={(params) => (
                                                        <Field
                                                            {...params}
                                                            className={classes.root}
                                                            label="Wyszukaj adres"
                                                            variant="filled"
                                                            margin="none"
                                                            name="alone"
                                                            fullWidth
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                            }}
                                                            as={TextField}
                                                        />
                                                    )}
                                                />
                                                <div className={styles["addAddressForm__address--inputs--fieldGroup"]}>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            className={classes.root}
                                                            disabled
                                                            name={'street'}
                                                            error={
                                                                !!getIn(touched, 'street') &&
                                                                !!getIn(errors, 'street')
                                                            }
                                                            helperText={
                                                                !!getIn(touched, 'street') &&
                                                                !!getIn(errors, 'street')
                                                                    ? getIn(errors, 'street') : ""
                                                            }
                                                            label="Ulica*"
                                                            variant="standard"
                                                            style={{
                                                                width: 240,
                                                            }}
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            className={classes.root}
                                                            name={`no`}
                                                            error={
                                                                !!getIn(touched, `no`) &&
                                                                !!getIn(errors, `no`)
                                                            }
                                                            helperText={
                                                                !!getIn(touched, `no`) &&
                                                                !!getIn(errors, `no`)
                                                                    ? getIn(errors, `no`) : ""
                                                            }
                                                            label="Nr domu*"
                                                            variant="standard"
                                                            style={{
                                                                width: 115,
                                                            }}
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            className={classes.root}
                                                            name={`flat`}
                                                            error={
                                                                !!getIn(touched, `flat`) &&
                                                                !!getIn(errors, `flat`)
                                                            }
                                                            helperText={
                                                                !!getIn(touched, `flat`) &&
                                                                !!getIn(errors, `flat`)
                                                                    ? getIn(errors, `flat`) : ""
                                                            }
                                                            label="Nr mieszkania"
                                                            variant="standard"
                                                            style={{
                                                                width: 115,
                                                            }}
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{justifyContent: "flex-start",}} className={
                                                    styles["addAddressForm__address--inputs--fieldGroup"]
                                                }>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            className={classes.root}
                                                            name={`city`}
                                                            error={
                                                                !!getIn(touched, `city`) &&
                                                                !!getIn(errors, `city`)
                                                            }
                                                            helperText={
                                                                !!getIn(touched, `city`) &&
                                                                !!getIn(errors, `city`)
                                                                    ? getIn(errors, `city`) : ""
                                                            }
                                                            label="Miasto*"
                                                            variant="standard"
                                                            style={{
                                                                width: 240,
                                                            }}
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            // disabled
                                                            className={classes.root}
                                                            name={`zipCode`}
                                                            error={
                                                                !!getIn(touched, `zipCode`) &&
                                                                !!getIn(errors, `zipCode`)
                                                            }
                                                            helperText={
                                                                !!getIn(touched, `zipCode`) &&
                                                                !!getIn(errors, `zipCode`)
                                                                    ? getIn(errors, `zipCode`) : ""
                                                            }
                                                            label="Kod pocztowy*"
                                                            variant="standard"
                                                            style={{
                                                                width: 115,
                                                            }}
                                                            margin="none"
                                                            fullWidth
                                                            as={TextField}
                                                        />
                                                    </div>
                                                    <div className={styles["addAddressForm__address--inputs--field"]}>
                                                        <Field
                                                            onKeyUp={handleIgnoreForm}
                                                            className={classes.root}
                                                            name={`type`}
                                                            as={(field) => (
                                                                <TextField {...field} fullWidth select label="Rodzaj*"
                                                                    error={
                                                                       !!getIn(touched, `type`) &&
                                                                       !!getIn(errors, `type`)
                                                                    }
                                                                    helperText={
                                                                       !!getIn(touched, `type`) &&
                                                                       !!getIn(errors, `type`)
                                                                           ? getIn(errors, `type`) : ""
                                                                    }
                                                                    margin="none"
                                                                    variant="standard"
                                                                    as={TextField}
                                                                    style={{width: 115}}
                                                                >
                                                                    {addressTypes.map((addressType) => (
                                                                        <MenuItem key={addressType} value={addressValues[addressType]}>
                                                                            {addressType}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className={styles["addAddressForm__buttonWrapper"]}>
                                    <Button className={classes.confirmButton} variant="contained" type="submit">
                                        Potwierdź
                                    </Button>
                                </div>
                            </SimpleBar>
                        </>
                <div className={styles["addAddressForm"]}>
                    <div className={styles["addAddressForm__map"]}>
                        <div className={styles["addAddressForm__description"]}>
                            <h1 className={styles["addAddressForm__description--title"]}>Mapa poglądowa</h1>
                            <p className={styles["addAddressForm__description--subTitle"]}>
                                Śledź znacznik aktualnie wpisywanego adresu
                            </p>
                        </div>
                        <OsmMap width="100%" height="70vh" pickedLat={newLat} pickedLon={newLon} onClickOnMap={(lonlat) => mapClicked(lonlat, setFieldValue)} ignoreForm={ignoreForm}/>
                    </div>
                </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
