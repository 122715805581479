import React, { useState, useEffect, useContext } from "react";
import styles from "./confirmation.module.scss";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade, Button, TextField } from "@material-ui/core";
import ConfirmationMap from "./confirmationMap/ConfirmationMap";
import FormAlert from "../../alerts/FormAlert";
import { format, fromUnixTime } from "date-fns";
import { pl } from "date-fns/locale";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { MimicContext } from "../../../../context";

const useStyles = makeStyles(() => ({
  simplebar: {
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
      backgroundColor: "#b4b4b4",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "0 !important",
    position: "fixed",
    top: "6vh",
    width: "85vw",
    height: "88vh",
    backgroundColor: "#FFFFFF",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    padding: 15,
  },
  rejectButton: {
    color: "#1DBBED",
    textTransform: "none",
    fontWeight: "bold",
    borderColor: "#1DBBED",
    borderRadius: "0",
    transition: "0.3s",
    "&:hover": {
      color: "white",
      backgroundColor: "#1DBBED",
    },
  },
}));

const ConfirmButton = styled(Button)({
  backgroundColor: "#66D01D",
  margin: "10px 5px 10px 0",
  color: "white",
  fontWeight: "bold",
  fontFamily: "'Open Sans', sans-serif",
  borderRadius: "0px",
  textTransform: "none",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#5AB71B",
  },
});

const RejectButton = styled(Button)({
  backgroundColor: "#ED1D1D",
  margin: "10px 5px 10px 0",
  color: "white",
  fontWeight: "bold",
  fontFamily: "'Open Sans', sans-serif",
  borderRadius: "0px",
  textTransform: "none",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#C01313",
  },
});

const SingleInput = styled(TextField)({
  width: "100%",
  fontFamily: "'Open Sans', sans-serif",
  ".MuiInput-input": {
    textAlign: "center",
  },
  "& label.Mui-focused": {
    color: "#1DBBED",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#1DBBED",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "0",
      borderColor: "#C2C2C2",
    },
    "&:hover fieldset": {
      borderColor: "#1DBBED",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1DBBED",
    },
  },
});

const translates = {
  pending: "oczekująca",
  accepted: "zaakceptowana",
  rejected: "odrzucona",
  deleted: "usunięta",
};

export default function Confirmation(props) {
  const classes = useStyles();
  const { details } = props;
  const [open] = useState(true);
  const [isRejected, setIsRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [reason, setReason] = useState("");
  const [emptyNotes, setEmptyNotes] = useState(false);
  const [emptyKm, setEmptyKm] = useState(false);
  const [newDistance, setNewDistance] = useState(null);
  const [mapActive, setMapActive] = useState("");
  const [manualActive, setManualActive] = useState("");
  const [newActive, setNewActive] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isRestored, setIsRestored] = useState(false);

  useEffect(() => {}, [isRestored]);

  const handleMapActive = () => {
    setMapActive("--active");
    setManualActive("");
    setNewActive("");
    setNewDistance(details.mapKm);
    setInputValue(details.mapKm);
  };

  const handleManualActive = () => {
    setMapActive("");
    setManualActive("--active");
    setNewActive("");
    setNewDistance(details.manualKm);
    setInputValue(details.manualKm);
  };

  const handleNewActive = () => {
    setMapActive("");
    setManualActive("");
    setNewActive("--active");
  };

  const handleSendNewActive = (value) => {
    if(value >= 0 && value <= 9999) {
      setInputValue(value);
      setNewDistance(value);
    }
  };

  const handleClose = () => {
    props.switchShowModal(false);
  };

  const handleRejectRequest = () => {
    setIsRejected(true);
  };

  const handleAcceptRequest = () => {
    if (inputValue) {
      setIsAccepted(true);
    } else {
      setEmptyKm(true);
      setTimeout(alertReset, 2000);
    }
  };

  const handleSendRequest = () => {
    if (isRejected) {
      if (reason === "") {
        setEmptyNotes(true);
        setTimeout(alertReset, 2000);
      } else {
        props.handleSendRequest(
          props.index,
          "rejected",
          reason,
          difference.manualKm
        );
        setIsRejected(false);
        handleClose();
      }
    } else if (isAccepted) {
      if (reason === "") {
        setEmptyNotes(true);
        setTimeout(alertReset, 2000);
      } else {
        props.handleSendRequest(props.index, "accepted", reason, newDistance);
        setIsAccepted(false);
        handleClose();
      }
    }
  };

  const alertReset = (value) => {
    setEmptyNotes(value);
    setEmptyKm(value);
  };

  let actives =
    (details.status === "pending" && !props.isMoreDetails) ||
    (details.status === "rejected" && !props.isMoreDetails) ||
    isRestored
      ? "--showPanel"
      : "--hidePanel";

  let difference = details.manualKm - details.mapKm;

  const { roles } = useContext(MimicContext);

  const hasSaveAccess = roles.includes("ROLE_KILOMETROWKA_ZAPIS");

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={styles["confirmation__description"]}>
            <h2 className={styles["confirmation__description--title"]}>
              Szczegóły kilometrówki
            </h2>
            <span
              className={styles["confirmation__description--close"]}
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className={styles["confirmation"]}>
            <div className={styles["confirmation__map"]}>
              <ConfirmationMap id="1" lat={2} data={props.details} />
            </div>
            <SimpleBar
              style={{ maxHeight: "80vh", minWidth: 520 }}
              className={classes.simplebar}
            >
              <div className={styles["confirmation__inputs"]}>
                <div className={styles["confirmation__inputs--item"]}>
                  <div className={styles["confirmation__inputs--item--status"]}>
                    <p
                      className={
                        styles["confirmation__inputs--item--status--info"]
                      }
                    >
                      Status
                    </p>
                    <p
                      className={
                        styles["confirmation__inputs--item--status--data"]
                      }
                    >
                      {translates[details.status].toUpperCase()}
                    </p>
                    {hasSaveAccess &&
                      !props.disallowEdit &&
                      (details.status !== "pending" &&
                      props.entity === "dyspozytor" ? (
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item--status--buttonWrapper"
                            ]
                          }
                        >
                          <Button
                            className={classes.rejectButton}
                            variant="outlined"
                            onClick={() => setIsRestored(true)}
                          >
                            Zmień status
                          </Button>
                        </div>
                      ) : null)}
                  </div>
                </div>

                {/*kilometry - panel wyboru*/}
                <div className={styles["confirmation__inputs--item" + actives]}>
                  <div
                    className={
                      styles[
                        "confirmation__inputs--item" +
                          actives +
                          "--distanceWrapper"
                      ]
                    }
                  >
                    <>
                      <p
                        className={styles["confirmation__inputs--item--title"]}
                      >
                        Zaznacz / wprowadź ilość kilometrów
                      </p>
                      <div
                        className={
                          styles[
                            "confirmation__inputs--item" +
                              actives +
                              "--distanceWrapper--distances"
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--distances--single"
                            ]
                          }
                        >
                          <p
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--info"
                              ]
                            }
                          >
                            Km mapa
                          </p>
                          <div
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--data" +
                                  mapActive
                              ]
                            }
                            onClick={() => handleMapActive()}
                          >
                            <p
                              className={
                                styles[
                                  "confirmation__inputs--item" +
                                    actives +
                                    "--distanceWrapper--distances--single--data" +
                                    mapActive +
                                    "--1"
                                ]
                              }
                            >
                              {details.mapKm}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--distances--single"
                            ]
                          }
                        >
                          <p
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--info"
                              ]
                            }
                          >
                            Km wprowadzone
                          </p>
                          <div
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--data" +
                                  manualActive
                              ]
                            }
                            onClick={() => handleManualActive()}
                          >
                            <p
                              className={
                                styles[
                                  "confirmation__inputs--item" +
                                    actives +
                                    "--distanceWrapper--distances--single--data" +
                                    manualActive +
                                    "--2"
                                ]
                              }
                            >
                              {details.manualKm}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--distances--single"
                            ]
                          }
                        >
                          <p
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--info"
                              ]
                            }
                          >
                            Różnica
                          </p>
                          <div
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--data"
                              ]
                            }
                          >
                            <p
                              className={
                                styles[
                                  "confirmation__inputs--item" +
                                    actives +
                                    "--distanceWrapper--distances--single--data--3"
                                ]
                              }
                            >
                              {difference > 0 ? `+${difference}` : difference}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--distances--single"
                            ]
                          }
                        >
                          <p
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--info"
                              ]
                            }
                          >
                            Km
                          </p>
                          <div
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--distances--single--data" +
                                  newActive
                              ]
                            }
                            onClick={() => handleNewActive()}
                          >
                            <div
                              className={
                                styles[
                                  "confirmation__inputs--item" +
                                    actives +
                                    "--distanceWrapper--distances--single--data" +
                                    newActive +
                                    "--4"
                                ]
                              }
                            >
                              <SingleInput
                                label=""
                                style={{
                                  width: "80px",
                                  margin: "0",
                                  padding: "0",
                                }}
                                fullWidth
                                value={inputValue}
                                type="number"
                                inputProps={{ min: "0" }}
                                variant="standard"
                                onChange={(e) => {
                                  handleSendNewActive(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isRejected || isAccepted ? (
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--distances--reason"
                            ]
                          }
                        >
                          <SingleInput
                            label="Podaj uzasadnienie odrzucenia / zmiany ilości kilometrów"
                            fullWidth
                            value={reason}
                            variant="outlined"
                            multiline
                            rows={4}
                            onChange={(e) => {
                              setReason(e.target.value);
                            }}
                          />
                          <div
                            className={
                              styles[
                                "confirmation__inputs--item" +
                                  actives +
                                  "--distanceWrapper--buttonWrapper"
                              ]
                            }
                          >
                            {isRejected && <RejectButton onClick={handleSendRequest}>
                              Odrzuć wniosek
                            </RejectButton> }
                            {isAccepted && <ConfirmButton onClick={handleSendRequest}>
                              Zaakceptuj
                            </ConfirmButton> }
                          </div>
                        </div>
                      ) : null}
                      {!isRejected && !isAccepted ? (
                        <div
                          className={
                            styles[
                              "confirmation__inputs--item" +
                                actives +
                                "--distanceWrapper--buttonWrapper"
                            ]
                          }
                        >
                          <RejectButton onClick={handleRejectRequest}>
                            Odrzuć wniosek
                          </RejectButton>
                          <ConfirmButton onClick={handleAcceptRequest}>
                            Zaakceptuj
                          </ConfirmButton>
                        </div>
                      ) : null}
                    </>
                  </div>
                </div>

                {/*szczegóły kursu*/}
                <div className={styles["confirmation__inputs--item"]}>
                  <div
                    className={
                      styles["confirmation__inputs--item--courseDetails"]
                    }
                  >
                    <div
                      className={
                        styles[
                          "confirmation__inputs--item--courseDetails--details"
                        ]
                      }
                    >
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--courseDetails--details--info"
                          ]
                        }
                      >
                        Pracownik
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--courseDetails--details--data"
                          ]
                        }
                      >
                        {details.worker.user.name} {details.worker.user.surName}
                      </p>
                    </div>
                    <div
                      className={
                        styles[
                          "confirmation__inputs--item--courseDetails--details"
                        ]
                      }
                    >
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--courseDetails--details--info"
                          ]
                        }
                      >
                        ID Kilometrówki
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--courseDetails--details--data"
                          ]
                        }
                      >
                        {details.id}
                      </p>
                    </div>
                  </div>
                </div>

                {/*kilometry - podgląd*/}
                <div
                  className={styles["confirmation__inputs--item--distanceView"]}
                >
                  <p className={styles["confirmation__inputs--item--title"]}>
                    Szczegóły
                  </p>
                  <div
                    className={
                      styles[
                        "confirmation__inputs--item--distanceView--details"
                      ]
                    }
                  >
                    <div
                      className={
                        styles[
                          "confirmation__inputs--item--distanceView--details--singleColumn"
                        ]
                      }
                    >
                      <p
                        className={
                          styles["confirmation__inputs--item--address--info"]
                        }
                      >
                        Od:
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--data"]
                        }
                      >
                        {details.from.zipCode} {details.from.city}{" "}
                        {details.from.street}
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--info"]
                        }
                      >
                        Do:
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--data"]
                        }
                      >
                        {details.to.zipCode} {details.to.city}{" "}
                        {details.to.street}
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--info"]
                        }
                      >
                        Kontrahent
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--data"]
                        }
                      >
                        {details.kontrahent.name}
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--info"]
                        }
                      >
                        Nr karty
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--data"]
                        }
                      >
                        {details.cardNo}
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--info"]
                        }
                      >
                        Nr zlecenia
                      </p>
                      <p
                        className={
                          styles["confirmation__inputs--item--address--data"]
                        }
                      >
                        {details.orderNo ? details.orderNo : "-"}
                      </p>
                    </div>
                    <div
                      className={
                        styles[
                          "confirmation__inputs--item--distanceView--details--singleColumn"
                        ]
                      }
                    >
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--info"
                          ]
                        }
                      >
                        Km mapa:
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--data"
                          ]
                        }
                      >
                        {details.mapKm}
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--info"
                          ]
                        }
                      >
                        Km wprowadzone:
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--data"
                          ]
                        }
                      >
                        {details.manualKm ? details.manualKm : "-"}
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--info"
                          ]
                        }
                      >
                        Różnica:
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--data"
                          ]
                        }
                      >
                        {difference > 0 ? `+${difference}` : difference}
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--info"
                          ]
                        }
                      >
                        Km zatwierdzone:
                      </p>
                      <p
                        className={
                          styles[
                            "confirmation__inputs--item--distanceView--details--singleColumn--data"
                          ]
                        }
                      >
                        {details.chooseKm ? details.chooseKm : "-"}
                      </p>
                    </div>
                  </div>
                </div>

                {/*Historia edycji wniosku*/}
                <div className={styles["confirmation__inputs--item"]}>
                  <p className={styles["confirmation__inputs--item--title"]}>
                    Historia wniosku
                  </p>
                  <div
                    className={styles["confirmation__inputs--item--history"]}
                  >
                    <div
                      className={
                        styles["confirmation__inputs--item--history--reverse"]
                      }
                    >
                      {details.history?.length > 0 ? (
                        details.history?.map((item, index) => {
                          let fromUnixCreateTime = item.createdAt
                            ? fromUnixTime(item.createdAt)
                            : null;
                          let changeDate = fromUnixCreateTime
                            ? format(fromUnixCreateTime, "d LLLL yyyy, HH:mm", {
                                locale: pl,
                              })
                            : null;

                          return (
                            <div
                              key={index}
                              className={
                                styles[
                                  "confirmation__inputs--item--history--details"
                                ]
                              }
                            >
                              <div
                                className={
                                  styles[
                                    "confirmation__inputs--item--history--details--singleColumn"
                                  ]
                                }
                              >
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--info"
                                    ]
                                  }
                                >
                                  Data:
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--data"
                                    ]
                                  }
                                >
                                  {changeDate ? changeDate : "-"}
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--info"
                                    ]
                                  }
                                >
                                  Status
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--data"
                                    ]
                                  }
                                >
                                  {translates[item.status].toUpperCase()}
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--info"
                                    ]
                                  }
                                >
                                  Uzasadnienie
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--data"
                                    ]
                                  }
                                >
                                  {item.reason}
                                </p>
                              </div>
                              <div
                                className={
                                  styles[
                                    "confirmation__inputs--item--history--details--singleColumn"
                                  ]
                                }
                              >
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--info"
                                    ]
                                  }
                                >
                                  Autor:
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--data"
                                    ]
                                  }
                                >{`${item.name} ${item.surname}`}</p>

                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--info"
                                    ]
                                  }
                                >
                                  Km zatwierdzone
                                </p>
                                <p
                                  className={
                                    styles[
                                      "confirmation__inputs--item--history--details--singleColumn--data"
                                    ]
                                  }
                                >
                                  {item.chooseKm}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p
                          className={
                            styles[
                              "confirmation__inputs--item--history--noData"
                            ]
                          }
                        >
                          Brak danych
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
          {emptyNotes && (
            <FormAlert
              showAlert={true}
              severity="error"
              message={"Wpisz uzasadnienie"}
            />
          )}
          {emptyKm && (
            <FormAlert
              showAlert={true}
              severity="error"
              message="Zaznacz lub wprowadź prawidłową ilość kilometrów"
            />
          )}
        </div>
      </Fade>
    </Modal>
  );
}
