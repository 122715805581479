import React, { Fragment, useEffect, useState, useContext } from "react";
import { MimicContext } from "../../../context";
import axios from "axios";
import styles from "./orders.module.scss";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import {
    Button,
    createMuiTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Countdown from "react-countdown";
import {
    endOfDay,
    startOfToday,
    endOfToday,
    endOfMonth,
    format,
    fromUnixTime,
    getUnixTime,
    isDate,
    startOfMonth,
    getDate,
    getMonth,
    getDaysInMonth,
    getYear,
    startOfDay,
    isSameDay,
} from "date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { DatePicker } from "@material-ui/pickers";
import InfoDialog from "../alerts/infoDialog/InfoDialog";
import MoreDetails from "./moreDetails/MoreDetails";
import ProgressLine from "../progressLine/ProgressLine";
import { useHistory } from "react-router-dom";
import DateTabs from "./Tabs";
import * as qs from "qs";
import { pl, de } from "date-fns/locale";
import produce from "immer";
import HttpClient from "../../../HttpClient";
import { ThemeProvider } from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { convertNumberToArray } from "../../../helper/convertNumberToArray";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import MobileOffIcon from "@material-ui/icons/MobileOff";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { OrdersTimer } from "./timer/OrdersTimer";
import { ordersCountdownDao } from "./orders-countdown.dao";
import _, {debounce} from "lodash";
import { CombinedCourseModal } from "./laczenie/CombinedCourses";
import { MergedOrdersModal } from "./polaczone/MergedOrders";
import orderDao from "./OrderDao";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineItem from "@material-ui/lab/TimelineItem";
import {
    FlagOutlined,
    MessageTwoTone,
    PlaceOutlined,
    RefreshOutlined, RemoveCircleOutlined,
    Timer3Outlined,
    TimerOutlined
} from "@material-ui/icons";
import CommunicationPanel from "./communicationPanel/CommunicationPanel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import useIsComponentMounted from "../use-is-component-mounted";

const DriverField = styled(TextField)({
    width: 200,
    fontFamily: "'Open Sans', sans-serif",
    marginRight: "10px",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const PartnerField = styled(TextField)({
    width: 200,
    fontFamily: "'Open Sans', sans-serif",
    marginRight: "10px",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: lightBlue,
    },
});

const useStyles = makeStyles({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },

    pagination: {
        width: "fit-content",
        "& .MuiTablePagination-toolbar": {
            padding: "0 10px 5px 5px !important",
        },
    },

    root: {
        color: "#47484B",
        fontFamily: "'Open Sans', sans-serif",
        border: "none !important",
        "& .MuiTable-root": {
            width: "auto",
        },
        "& .MuiTableCell-head": {
            padding: "2px 15px 0 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTableCell-body": {
            padding: "2px 15px 0 5px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
        "& .MuiTabs-fixed": {
            overflowX: "auto !important",
        },
    },
    content: {
        maxWidth: "160px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
    },
    button: {
        backgroundColor: "#ED1D1D",
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    icon: {
        color: "white",
    },
    copyIcon: {
        color: "white",
        padding: "2px",
    },
    wRealizacji: {
        color: "#66D01D",
    },
    przypisany: {
        color: "#faba1f",
    },
    zaplanowany: {
        color: "#1DBBED",
    },
    przetwarzany: {
        color: "#b4b4b4",
    },
    odrzucony: {
        color: "#ED1D1D",
    },
    anulowany: {
        color: "black",
    },
    zakonczony: {
        color: "#d7d7d7",
    },
    alertPrzedStartem: {
        animation: `$myEffect 2000ms infinite`,
    },
    "@keyframes myEffect": {
        "0%": {
            color: "#1DBBED",
            transform: "scale(1)",
        },
        "25%": {
            color: "#ff6700",
        },
        "50%": {
            color: "#1DBBED",
            transform: "scale(1.5)",
        },
        "75%": {
            color: "#ff6700",
        },
        "100%": {
            color: "#1DBBED",
            transform: "scale(1)",
        },
    },
    "@keyframes myEffect2": {
        "0%": {
            background: "#1DBBED",
            transform: "scale(1)",
        },
        "25%": {
            background: "#ff6700",
        },
        "50%": {
            background: "#1DBBED",
            transform: "scale(1.5)",
        },
        "75%": {
            background: "#ff6700",
        },
        "100%": {
            background: "#1DBBED",
            transform: "scale(1)",
        },
    },

    tableHead: {
        backgroundColor: "rgba(243, 243, 243, 0.7)",
        boxShadow: "none",
        fontFamily: "'Open Sans', sans-serif",
        "& label.Mui-focused": {
            color: "#1DBBED",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#1DBBED",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#1DBBED",
            },
            "&:hover fieldset": {
                borderColor: "#1DBBED",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#1DBBED",
            },
        },
    },
    tableSortLabel: {
        display: "flex",
        alignItems: "center",
    },
    editRelatedCoursesButton: {
        justifyContent: "center",
        width: "100%",
        color: "#66d01d",
    },
    headCell: {
        width: 200,
        padding: 0,
        margin: 0,
        "& $headCellArrow": {
            opacity: "50%",
            transition: "0.2s",
        },
        "& input::placeholder": {
            fontSize: "12px",
        },
    },
    headCellArrow: {
        opacity: "0%",
        transition: "0.2s",
    },
    tableCell: {
        paddingBottom: "0",
        paddingTop: "0",
    },
    changeViewButton: {
        color: "#B4b4b4",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#B4b4b4",
        borderRadius: "0",
        transition: "0.3s",
        "&:hover": {
            color: "white",
            backgroundColor: "#777777",
        },
    },
    copyIconButton: {
        backgroundColor: "#66D01D",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#5baf1d",
        },
    },
    iconButton: {
        backgroundColor: "#1DBBED",
        margin: "2px",
        "&:hover": {
            backgroundColor: "#00A0E3",
        },
        "&:disabled": {
            backgroundColor: "#00A0E3",
            opacity: 0.5,
        },
    },
    deleteButton: {
        backgroundColor: "#ED1D1D",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#C01313",
        },
    },
    refreshButton: {
        backgroundColor: "#2ac0f6",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#00c0ff",
        }
    },
    refreshButtonDisabled: {
        backgroundColor: "#919191",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#919191",
        }
    },
    checkedLabelWrapper: {
        width: "60px",
        margin: "2px 5px -12px 3px",
        display: "flex",
        justifyContent: "center",
    },
    labelIcon: {
        color: "#b4b4b4",
        fontSize: "28px",
    },
    pushNotificationSentSuccessIcon: {
        color: "#66D01D",
        fontSize: "28px",
    },
    pushNotificationSentFailtureIcon: {
        color: "#FF8800",
        fontSize: "28px",
    },
    pushNotificationNoAppIcon: {
        color: "#ED1D1D",
        fontSize: "28px",
    },
    planWrapper: {
        width: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    doneAllIcon: {
        color: "#1DBBED",
        fontSize: "36px",
    },
    timerEnd: {
        color: "#ED1D1D",
    },
    timer: {
        textAlign: "center",
    },
    timeRoot: {
        padding: 0,
        margin: 0,

        "& .MuiTimelineItem-root": {
            minHeight: "42px !important",
        },

        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -1,
        padding: "5px 6px",
    },
    timeLinewRealizacji: {
        backgroundColor: "#66D01D",
    },
    timeLinePrzypisany: {
        backgroundColor: "#faba1f",
    },
    timeLineZaplanowany: {
        backgroundColor: "#1DBBED",
    },
    timeLinePrzetwarzany: {
        backgroundColor: "#b4b4b4",
    },
    timeLineOdrzucony: {
        backgroundColor: "#ED1D1D",
    },
    timeLineAnulowany: {
        backgroundColor: "black",
    },
    timeLineZakonczony: {
        backgroundColor: "#d7d7d7",
    },
    timeLinePrzedStartem: {
        animation: `$myEffect2 2000ms infinite`,
    },
    expandRowButton: {
        position: "absolute",
        background: "transparent",
        padding: 0,
        margin: 0,
        border: 0,
        zIndex: 1000,
        width: 27,
        height: 27,
        cursor: "pointer",
    },
});

const SearchDatePicker = styled(DatePicker)({
    width: 120,
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

const PlanCheckbox = withStyles({
    root: {
        color: "#1DBBED",
        "&$checked": {
            color: "#1DBBED",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const generateStartEndDate = (newDate) => {
    return {
        start: getUnixTime(startOfMonth(newDate)),
        end: getUnixTime(endOfMonth(newDate)),
    };
};

const generateStartEndDateForActive = (newDate) => {
    const nextMonthStartDate = new Date(endOfMonth(newDate).getTime() + 1000);

    const startDate = new Date(startOfMonth(newDate).getTime() - 1000 * 60 * 60 * 24);
    const endDate = endOfMonth(nextMonthStartDate);

    return {
        start: getUnixTime(startDate),
        end: getUnixTime(endDate),
    };
};

const DriverAsyncAutocomplete = ({ drivers, value, onChange, orderId, order, roles, isDriverUpdating }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [busyDrivers, setBusyDrivers] = useState([]);

    const getBusyDrivers = async () => {
        setLoading(true);
        const httpRequest = HttpClient.createRequest();
        try {
            const { data } = await httpRequest.get(`api/order/can-set/${orderId}`, {}, true);
            setBusyDrivers(data);
        } catch (e) {
        } finally {
            setLoading(false);
            setOptions(drivers);
        }
    };

    const disableDriver = (option) => {
        return busyDrivers.some((driver) => driver.id === option.id);
    };

    const generateLabel = (data, showAddress = false) => {
        if (showAddress) {
            const address =
                data.user.adres &&
                !!data.user.adres[0] &&
                `${data.user.adres[0].street} ${data.user.adres[0].city} ${data.user.adres[0].zipCode}`;

            return (
                <div className={styles["changeDriverPanel__autoComplete"]}>
                    <span>
                        {data.name ? data.name : data.user.name ? data.user.name : "-"}{" "}
                        {data.surName ? data.surName : data.user.surName ? data.user.surName : "-"}
                    </span>
                    <span className={styles["changeDriverPanel__autoComplete--description"]}>
                        Stamina: {data.user.stamina ? `${data.user.stamina} godz.` : "-"}
                    </span>
                    <span className={styles["changeDriverPanel__autoComplete--description"]}>
                        {!!address ? address : "Brak adresu"}
                    </span>
                </div>
            );
        }
        return `${data.name ? data.name : data.user.name ? data.user.name : "-"} ${
            data.surName ? data.surName : data.user.surName ? data.user.surName : "-"
        }`;
    };

    const selectInputValue = drivers.find((driver) => driver.id === value?.id);

    const isDisabled = isDriverUpdating;

    const isDriverVisible = drivers.find((driver) => {
        return driver.id === order.driver?.id
    })

    return (
        roles.includes("ROLE_OPERATOR_CONTRACTOR") && !isDriverVisible && order.driver ? "Kurs obsadzony" :
            <Autocomplete
                open={open}
                onOpen={() => {
                    getBusyDrivers();
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                options={options}
                disabled={isDisabled || (order.consortiumMemberName !== null && roles.includes("ROLE_OPERATOR_CONTRACTOR") && !roles.includes("ROLE_OPERATOR_PARTNER"))}
                getOptionLabel={(data) => generateLabel(data)}
                renderOption={(option) => generateLabel(option, true)}
                getOptionDisabled={(option) => disableDriver(option)}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectInputValue}
                defaultValue={selectInputValue}
                onChange={(e, newValue) => {
                    onChange(newValue);
                }}
                renderInput={(params) => (
                    <DriverField
                        required
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading || isDriverUpdating ?
                                        <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        label="Wybierz kierowcę"
                        name="taxi"
                        variant="standard"
                        margin="normal"
                        fullWidth
                    />
                )}
            />
    );
};

const RealDriverInput = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue(value ?? "");
    }, [value]);

    const onEnterKeyDown = () => {
        onChange(inputValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onEnterKeyDown();
        }
    };

    return (
        <DriverField
            onKeyDown={handleKeyDown}
            value={inputValue}
            onChange={(ev) => setInputValue(ev.target.value)}
            label="Wybierz kierowcę"
            variant="standard"
            margin="normal"
            fullWidth
        />
    );
};

const PartnerAsyncAutocomplete = ({ value, onChange, options, isContractorDriver, isContractorOperator}) => {
    const [open, setOpen] = useState(false);

    const isDisabled = options.length === 0;

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            options={options}
            disabled={isDisabled}
            // getOptionLabel={(data) => {
            //     return data.name;
            // }}
            // renderOption={(data) => {
            //     return data.name;
            // }}
            // getOptionSelected={(option, value) => {
            //     console.log(option);
            //     console.log(value);

            //     return option.name === value;
            // }}
            value={value}
            defaultValue={value}
            onChange={(e, newValue) => {
                if (!isContractorDriver && newValue === null && isContractorOperator)
                    HttpClient.addFlash("Nie można usunąć partnera - Kurs obsadzony kierowcą CSI - skontaktuj się z operatorem CSI", { variant: "info" });
                else if (!isContractorDriver && newValue === null && !isContractorOperator)
                    HttpClient.addFlash("Nie można usunąć partnera - Najpierw odepnij Kierowcę ze zlecenia", { variant: "info" });
                else onChange(newValue);
            }}
            renderInput={(params) => (
                <PartnerField
                    required
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                    }}
                    label="Wybierz partnera"
                    name="partner"
                    variant="standard"
                    margin="normal"
                    fullWidth
                />
            )}
        />
    );
};

export default function Orders(props) {
    const classes = useStyles();
    const isComponentMounted = useIsComponentMounted();
    const url = props.status === "active" ? "api/zlecenia/active" : "api/zlecenia/archive";
    const dateFilter = orderDao.getFilterInputDate();

    const history = useHistory();
    const now = new Date();
    const [invokeClearColumn, setClearColumn] = useState(false);
    const [page, setPage] = useState(0);
    const [columns, setColumns] = useState(props.columns);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [showDetails, setShowDetails] = useState(false);
    const [showCommsPanel, setShowCommsPanel] = useState(false);
    const [index, setIndex] = useState(null);
    const [searchValues, setSearchValues] = useState(orderDao.getFilterInputs() ?? [{ id: "time", value: null }]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [responseOrderList, setResponseOrderList] = useState([]);
    const [items, setItems] = useState([]);
    const [expandedItemIds, setExpandedItemIds] = useState([]);
    const [date, setDate] = useState(() =>
        props.status === "active" ? generateStartEndDateForActive(now) : generateStartEndDate(now)
    );
    const [count, setCount] = useState(0);
    const [notes, setNotes] = useState("");
    const [showInfoDialogCancel, setShowInfoDialogCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updateDriver, setUpdateDriver] = useState(false);
    const [year, setYear] = useState(dateFilter?.getFullYear() ?? getYear(now));
    const [month, setMonth] = useState(dateFilter?.getMonth() ?? getMonth(now));
    const [day, setDay] = useState(dateFilter?.getDate() ?? null);
    const [daysInMonth, setDaysInMonth] = useState(convertNumberToArray(getDaysInMonth(new Date(year, month))));
    const [copyClickCountdown, setCopyClickCountdown] = useState(() => ordersCountdownDao.getSavedCountdownDates());

    //DateTabs
    const [startDate, setStartDate] = useState(fromUnixTime(date.start));
    const [endDate, setEndDate] = useState(fromUnixTime(date.end));
    const [selectedSuggestedCourses, setSelectedSuggestedCourses] = useState([]);

    const [editingCourse, setEditingCourse] = useState(null);

    const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false);
    const [isEditRelatedCoursesModalOpen, setIsEditRelatedCoursesModalOpen] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [isDriversLoading, setIsDriversLoading] = useState(true);
    const [isLotosExcluded, setIsLotosExcluded] = useState(orderDao.getLotosExcluded());
    const [isImportantOrdersFilter, setIsImportantOrdersFilter] = useState(
        props.status === "archive" ? false : orderDao.getImportantOrdersFilter()
    );
    const [isUnfilledOrdersOnly, setIsUnfilledOrdersOnly] = useState(
        props.status === "active" ? false : orderDao.getUnfilledOrdersOnly()
    );
    const [lotosContractorData, setLotosContractorData] = useState(undefined);
    const [isNotEdited, setIsNotEdited] = useState([]);
    const [courseDetails, setCourseDetails] = useState(undefined);
    const [shouldCollapseAllOrders, setShouldExpandAllOrders] = useState(false);

    const [ordersWithUnreadMessages, setOrdersWithUnreadMessages] = useState([]);
    const [isDriverUpdating, setIsDriverUpdating] = useState(false);
    const [partnerOptions, setPartnerOptions] = useState([]);
    const { roles, isRoleType } = useContext(MimicContext);

    useEffect(() => {
        if (
            roles.includes("ROLE_SUPER_ADMIN") ||
            roles.includes("ROLE_OPERATOR") ||
            roles.includes("ROLE_SUPER_OPERATOR")
        ) {
            const url = "api/consortium-members";

            const request = HttpClient.createRequest();

            request.get(url, {}, true).then((response) => {
                console.log(response);
                setPartnerOptions(response.data.data.map((item) => item.name));
            });
        }
    }, []);

    const [user, setUser] = useState(undefined);

    const getUserData = async () => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get("api/account", {}, true, false);
            setUser(response.data.user);
        } catch (e) {}
    };

    useEffect(() => {
        getUserData().then(() => {});
    }, []);

    useEffect(() => {
        const apiDrivers = "api/kierowca?onlyActive=1";

        const requestDrivers = HttpClient.createRequest();

        setIsDriversLoading(true);
        requestDrivers
            .get(apiDrivers, {}, true)
            .then((driversResponse) => {
                setDrivers(driversResponse.data.pracownicy);
            })
            .finally(() => {
                setIsDriversLoading(false);
            });
    }, []);

    const selectSuggestedCourse = (id, selectedFromCourseId) => {
        const isSelectedCourseInList = !!selectedSuggestedCourses.find((item) => item.id === id);

        if (isSelectedCourseInList) {
            const filteredSelectedCourses = selectedSuggestedCourses.filter((item) => item.id !== id);
            setSelectedSuggestedCourses(filteredSelectedCourses);
            return;
        }

        const newSelectedCourse = {
            id,
            selectedFromCourseId,
            highlightRowEndDate: new Date(new Date().getTime() + 10000),
            isRowHighlighted: true,
        };

        setSelectedSuggestedCourses((curr) => [...curr, newSelectedCourse]);
    };

    const updateDriverData = (driverId, orderId, connectedOrders) => {
        let przejazdy = [
            {
                idKierowca: driverId ?? "",
                idZlecenia: orderId,
                notes: "ustawiono przez operatora",
                przejazd: [],
                status: "regular",
                isChange: true,
            },
        ];

        if (connectedOrders !== undefined || connectedOrders.length !== 0) {
            connectedOrders.forEach((item) =>
                przejazdy.push({
                    idKierowca: driverId ?? "",
                    idZlecenia: item,
                    notes: "ustawiono przez operatora",
                    przejazd: [],
                    status: "regular",
                    isChange: true,
                })
            );
        }

        const postData = {
            przejazdy: przejazdy,
        };

        const httpRequest = HttpClient.createRequest();

        httpRequest
            .post("api/zlecenie/set", postData, {}, true)
            .then((response) => {
                // let newOrders = [...api];
                // response.data.orders.forEach((order) => {
                //     const orderInApi = newOrders.find((newOrder) => newOrder.id === order.id);

                //     if (!orderInApi) {
                //         return;
                //     }
                //     const indexOfOrder = newOrders.indexOf(orderInApi);

                //     newOrders[indexOfOrder] = order;
                // });

                // setApi(newOrders);
                getData();
            })
            .finally(() => {
                setIsDriverUpdating(false);
            });
    };

    const updateRealDriverData = (driverName, orderId) => {
        const postData = {
            real_driver: driverName,
        };

        const requestUrl = `api/orders/${orderId}/set/real-driver`;
        const httpRequest = HttpClient.createRequest();
        httpRequest.put(requestUrl, postData, {}, true).then((response) => {
            HttpClient.addFlash(response.data.message, { variant: "success" });
        });
    };

    const selectSuggestedCourses = (ids, selectedFromCourseId) => {
        const newSelectedCourses = [];

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];

            const newSelectedCourse = {
                id,
                selectedFromCourseId,
                highlightRowEndDate: new Date(new Date().getTime() + 10000),
                isRowHighlighted: true,
            };

            newSelectedCourses.push(newSelectedCourse);
        }

        setSelectedSuggestedCourses((curr) => [...newSelectedCourses, ...curr]);
    };

    const onPartnerChange = (value, orderId) => {
        const postData = {
            consortium_member_name: value,
        };

        const requestUrl = `api/orders/${orderId}/partner`;

        const httpRequest = HttpClient.createRequest();
        httpRequest.put(requestUrl, postData, {}, true).then((response) => {
            HttpClient.addFlash(response.data.message, { variant: "success" });
            getData();
        });
    };

    useEffect(() => {
        const highlightCheckInterval = setInterval(() => {
            if (!selectedSuggestedCourses.length) {
                return;
            }
            const newSelectedCourses = selectedSuggestedCourses.map((item) => ({
                ...item,
                isRowHighlighted: item.highlightRowEndDate.getTime() >= new Date().getTime(),
            }));

            if (_.isEqual(newSelectedCourses, selectedSuggestedCourses)) {
                return;
            }

            setSelectedSuggestedCourses(newSelectedCourses);
        }, 1000);

        return () => clearInterval(highlightCheckInterval);
    }, [JSON.stringify(selectedSuggestedCourses)]);

    const handleDateChange = (fieldID, type, value) => {
        const rangeDate = { ...date };
        if (type === "start") {
            value = startOfDay(value);
            setStartDate(value);
        }
        if (type === "end") {
            value = endOfDay(value);
            setEndDate(value);
        }
        rangeDate[type] = getUnixTime(value);
        setDate(rangeDate);
    };

    const handleOnEnterPress = (e) => {
        if (e.key === "Enter" && props.status === 'archive') {
            getData();
        }
    };

    const handleSetStartEndDate = () => {
        let rangeDate;
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay)),
            };
        } else {
            const thisMonth = new Date(Date.UTC(year, month));
            rangeDate = {
                start: getUnixTime(startOfMonth(thisMonth)),
                end: getUnixTime(endOfMonth(thisMonth)),
            };
        }
        setDate(rangeDate);
    };

    const handleSetStartEndDateForActive = () => {
        let rangeDate;
        if (day) {
            const thisDay = new Date(Date.UTC(year, month, day));
            rangeDate = {
                start: getUnixTime(startOfDay(thisDay)),
                end: getUnixTime(endOfDay(thisDay)),
            };
        } else {
            const thisMonth = new Date(Date.UTC(year, month));
            const nextMonth = new Date(Date.UTC(year, month + 1));

            const startDate = new Date(startOfMonth(thisMonth).getTime() - 1000 * 60 * 60 * 24);

            rangeDate = {
                start: getUnixTime(startDate),
                end: getUnixTime(endOfMonth(nextMonth)),
            };
        }
        setDate(rangeDate);
    };

    const handleDaysInMonthChange = (newYear, newMonth) => {
        setDaysInMonth(convertNumberToArray(getDaysInMonth(new Date(newYear, newMonth))));
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        handleDateChange();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        handleDateChange();
    };

    const handleDayChange = (event, newValue) => {
        if (newValue === day) {
            setDay(null);
        } else {
            setDay(newValue);
        }
        handleDateChange();
    };

    const handleDatePickerDateChange = (newDate) => {
        const day = getDate(newDate);
        const month = getMonth(newDate);
        const year = getYear(newDate);
        setDay(day);
        setMonth(month);
        setYear(year);

        orderDao.setFilterInputDate(new Date(year, month, day));
        handleDateChange();
    };

    const getDatePickerValue = () => {
        return !isSameDay(startDate, new Date(year, month, day)) || !isSameDay(endDate, new Date(year, month, day))
            ? null
            : new Date(year, month, day);
    };

    useEffect(() => {
        if (props.status === "active") {
            handleSetStartEndDateForActive();
            return;
        }
        handleDaysInMonthChange(year, month);
        handleSetStartEndDate();
    }, [year, month, day]);

    const source = axios.CancelToken.source();

    const redirectToOrderPanel = (order) => {
        history.push({
            pathname: `/app/${props.entity}/transport/edytuj/${order.id}`,
            state: { order },
        });
    };

    const saveNotes = (e) => {
        setNotes(e.target.value);
    };

    const updateData = (value) => {
        setUpdateDriver(value);
    };

    const getParams = (s) => {
        if (s.value !== null) {
            if (isDate(s.value)) {
                s.value = getUnixTime(s.value);
            }
            return s.id + "=" + s.value + "&";
        }
    };

    const setLotosData = () => {
        getLotosContractorData().then((lotosDataResponse) => {
            if (lotosDataResponse) {
                setLotosContractorData(lotosDataResponse);
                orderDao.setLotosID(lotosDataResponse.id);
            }
        });
    };

    useEffect(() => {
        if (props.entity !== "operator") {
            return;
        }

        setLotosData();
    }, []);

    const getAllContractors = () => {
        const httpRequest = HttpClient.createRequest();

        const requestUrl = `/api/allKontrahents`;

        return httpRequest.get(requestUrl, undefined, true).then((response) => response.data);
    };

    const getLotosContractorData = async () => {
        const allContractors = await getAllContractors();

        return allContractors.find((item) =>
            item.contract.dispatches.find((dispatch) => dispatch.dispatchType === "LOTOS")
        );
    };

    const ExludeCheckbox = withStyles({
        root: {
            color: "#1dbbed",
            "&$checked": {
                color: "#1dbbed",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const getData = async (withoutFilters = false) => {
        checkIfReadied().then(() => {});
        const paramsToMap = withoutFilters ?
            [{ id: "time", value: null }].map((a) => ({ ...a })) :
            searchValues.map((a) => ({ ...a }))
        const getParam = paramsToMap.map(getParams).join("");
        const sortField = [...columns].filter((item) => item.sorted === true);

        setIsLoading(true);

        const httpRequest = HttpClient.createRequest();
        try {
            const paginationUrl =
                props.status !== "active" ? `&limit=${rowsPerPage ? rowsPerPage : 5}&page=${page ? page + 1 : 1}` : "";
            const wholeUrl = `${url}?${getParam}orderBy=${sortField[0].id}&order=${sortField[0].order}` + paginationUrl;
            const lotosID = lotosContractorData?.id ?? orderDao.getLotosID();
            const response = await httpRequest.get(
                wholeUrl,
                {
                    cancelToken: source.token,
                    params: {
                        date,
                        excludeContractors: isLotosExcluded ? [lotosID] : undefined,
                        importantOrders: isImportantOrdersFilter ? 1 : 0,
                        unfilledOnly: isUnfilledOrdersOnly ? 1 : 0,
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                },
                true
            );
            setResponseOrderList(response.data.items)
            setFilteredOrders(response.data.items)
            setCount(response.data.count);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const filteredResponse = (fullList) => {
        let filteredData = {};
        let filtersSet = false
        Object.keys(searchValues).forEach((filter, key) => {
            if (searchValues[key].id === 'contractor' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.kontrahent?.name.toLowerCase().includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'taxiDriver' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    const driverString =
                        item.driver ? `${item.driver.firstname.toLowerCase()} ${item.driver.surname.toLowerCase()}` : ''
                    return driverString.includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'number' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.number.toString().includes(searchValues[key].value)
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'pickup' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    const pickupString = item.from ? `${item.from.city.toLowerCase()} ${item.from.street} ${item.from.zipCode}`.toLowerCase() :
                        item.worker.map((worker) => `${worker.city} ${worker.street} ${worker.zipCode}`.toLowerCase())
                    if (Array.isArray(pickupString))
                        return pickupString.some((address) => address.trim().toLowerCase().includes(searchValues[key].value.toLowerCase()))
                    return pickupString.includes(searchValues[key].value.toLowerCase())
                })
            }
            if (searchValues[key].id === 'orderNo' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.orderNo?.toLowerCase().includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'notes' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.notes?.toLowerCase().includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'destination' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    const destinationString = item.to ? `${item.to.city} ${item.to.street} ${item.to.zipCode}`.toLowerCase() :
                        item.worker.map((worker) => `${worker.city} ${worker.street} ${worker.zipCode}`.toLowerCase())
                    if (Array.isArray(destinationString))
                        return destinationString.some((address) => address.trim().toLowerCase().includes(searchValues[key].value.toLowerCase()))
                    return destinationString.includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'worker' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    const workersAsString = item.worker.map((worker) => `${worker.firstname} ${worker.surname}`)
                    return workersAsString.some(
                        (worker) => worker.trim().toLowerCase().includes(searchValues[key].value.toLowerCase())
                    )
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'branch' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.author.branch?.toLowerCase().includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
            if (searchValues[key].id === 'consortiumMember' && searchValues[key].value?.length) {
                const toFilter = filteredData.length ? filteredData : fullList
                filteredData = toFilter.filter((item) => {
                    return item.consortiumMemberName?.toLowerCase().includes(searchValues[key].value.toLowerCase())
                })
                filtersSet = true;
            }
        })

        let addedOrders = []
        let filteredConnected = []
        if (filteredData.length) {
            filteredData.forEach((order) => {
                if (!addedOrders.includes(order.number)) {
                    filteredConnected.push(order)
                    addedOrders.push(order.number)
                }
                if (order.connectedOrders?.length) {
                    responseOrderList.forEach((connectedOrder) => {
                        if (order.connectedOrders.includes(connectedOrder.number) &&
                            !addedOrders.includes(connectedOrder.number)
                        ) {
                            filteredConnected.push(connectedOrder)
                            addedOrders.push(connectedOrder.number)
                        }
                    })
                }
            })
        }

        if (filteredConnected.length || filtersSet)
            setFilteredOrders(filteredConnected)
        else setFilteredOrders(responseOrderList)
    }

    useEffect(() => {
        filteredResponse(responseOrderList)
    }, [searchValues, responseOrderList])



    useEffect(() => {
        let items = [];

        for (let i = 0; i < filteredOrders.length; i++) {
            const item = filteredOrders[i];

            if (item.connectedOrders.length && !filteredOrders[i - 1]?.connectedOrders.includes(item.number)) {
                items.push({
                    ...item,
                    subrows: [],
                    isExpandToggleButtonVisible: true,
                    isTopTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i - 1, i),
                    isDownTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i + 1, i),
                });

                continue;
            }

            if (item.connectedOrders.length && filteredOrders[i - 1]?.connectedOrders.includes(item.number)) {
                const lastItemWithSubrows = [...items].reverse().find((item) => item.subrows);

                const indexOfLastItemWithSubrows = items.indexOf(lastItemWithSubrows);

                if (!lastItemWithSubrows) {
                    items.push({
                        ...item,
                        subrows: [],
                        isExpandToggleButtonVisible: true,
                        isTopTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i - 1, i),
                        isDownTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i + 1, i),
                    });
                    continue;
                }

                const newItem = {
                    ...item,
                    subrows: [],
                    isTopTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i - 1, i),
                    isDownTimelineConnectorVisible: checkIsOffsetOrderHasCurrentInConnected(i + 1, i),
                };

                items[indexOfLastItemWithSubrows] = {
                    ...lastItemWithSubrows,
                    subrows: lastItemWithSubrows.subrows ? [...lastItemWithSubrows.subrows, newItem] : [newItem],
                };
                continue;
            }

            items.push(item);
        }

        setItems(items);
    }, [filteredOrders]);

    useEffect(() => {
        if (isImportantOrdersFilter && shouldCollapseAllOrders) {
            setExpandedItemIds([]);
            return;
        }

        if (isImportantOrdersFilter || !shouldCollapseAllOrders) {
            const newExpandedRows = items.map((item) => item.number);

            setExpandedItemIds(newExpandedRows);
        } else {
            setExpandedItemIds([]);
        }
    }, [isImportantOrdersFilter, shouldCollapseAllOrders, items]);

    useEffect(() => {
        setPage(0);
        setStartDate(fromUnixTime(date.start));
        setEndDate(fromUnixTime(date.end));
    }, [date]);

    const { value } = searchValues.find((value) => value.id === "time");

    useEffect(() => {
        if (props.status === "active") {
            return;
        }
        window.scrollTo(0, 0);
        getData(true);

        setUpdateDriver(false);

        return () => {
            source.cancel();
        };
    }, [
        date,
        value,
        rowsPerPage,
        page,
        updateDriver,
        isLotosExcluded,
        isImportantOrdersFilter,
        isUnfilledOrdersOnly,
    ]);

    useEffect(() => {
        console.log(value);
        if (props.status === "active" && isComponentMounted) {
            window.scrollTo(0, 0);
            getData(true);

            setUpdateDriver(false);
        }

        return () => {
            source.cancel();
        };
    }, [isComponentMounted, value, isLotosExcluded, isImportantOrdersFilter, isUnfilledOrdersOnly]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0);
    };

    const handleSwitchInfoDialog = (value, type) => {
        if (!type) {
            setNotes("");
        }
        setShowInfoDialogCancel(value);
    };

    const handleSearch = (field, query) => {
        setPage(0);
        const filterObject = searchValues.filter((s) => s.id !== field);
        setSearchValues([...filterObject, { id: field, value: query }]);
        orderDao.setFilterInputs([...filterObject, { id: field, value: query }]);
        // setFilteredOrders(responseOrderList)
    };

    const handleClearColumn = (field, input) => {
        // handleSearch(field, null);
        const filterObject = searchValues.filter((s) => s.id !== field);
        const element = input.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('input');

        const newValue = Number.isInteger(element?.value) ? null : ''
        setSearchValues([...filterObject, { id: field, value: newValue }]);
        orderDao.setFilterInputs([...filterObject, { id: field, value: newValue }]);
        element.value = newValue
        // setClearColumn(!invokeClearColumn);
    };

    const clearDate = () => {
        setYear(getYear(new Date()));
        setMonth(getMonth(new Date()));
        setDay(null);
        orderDao.setFilterInputDate();
    };

    const expandRow = (orderNumber) => {
        if (expandedItemIds.includes(orderNumber)) {
            return;
        }
        setExpandedItemIds((curr) => [...curr, orderNumber]);
    };

    const collapseRow = (orderNumber) => {
        setExpandedItemIds((curr) => curr.filter((itemId) => itemId !== orderNumber));
    };

    function createData(
        id,
        edited,
        isRowGrayedOut,
        hasConnectedOrders,
        orderHasConnectedParent,
        isLastInConnectedOrders,
        suggestedRelatedCourses,
        combinedCourses,
        relatedOrders,
        status,
        checked,
        toStart,
        startEndTime,
        number,
        time,
        taxiDriver,
        taxiRealDriver,
        push,
        worker,
        pickup,
        destination,
        orderNo,
        notes,
        contractor,
        consortiumMember,
        details,
        branch,
        isEdited,
        isCanceled
    ) {
        return {
            id,
            edited,
            isRowGrayedOut,
            hasConnectedOrders,
            orderHasConnectedParent,
            isLastInConnectedOrders,
            suggestedRelatedCourses,
            combinedCourses,
            relatedOrders,
            status,
            checked,
            toStart,
            startEndTime,
            number,
            time,
            taxiDriver,
            taxiRealDriver,
            push,
            worker,
            pickup,
            destination,
            orderNo,
            notes,
            contractor,
            consortiumMember,
            details,
            branch,
            isEdited,
            isCanceled,
        };
    }

    const switchShowModal = () => {
        setShowDetails(false);
    };

    const switchCommsPanel = (orderID = null) => {
        setShowCommsPanel(!showCommsPanel);

        const i = ordersWithUnreadMessages.indexOf(orderID);
        if (i !== -1) {
            deleteFromUnread(i);
            const httpRequest = HttpClient.createRequest();
            httpRequest
                .put(
                    `api/orders/${orderID}/set/disable-edit-flag`,
                    {
                        disableEdit: true,
                    },
                    true,
                    true,
                    true
                )
                .then(() => {
                    disabledFlagOff(orderID);
                });
            httpRequest
                .put(
                    `api/order/comms/set-readied`,
                    {
                        zlecenie: orderID,
                    },
                    true,
                    true,
                    true
                )
                .then(() => {});
        }
    };

    const cancelOrder = async () => {
        const orderId = filteredOrders[index].id;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.post(
                `api/order/cancel/${orderId}`,
                { notes },
                { cancelToken: source.token },
                true
            );
            const newOrders = produce(filteredOrders, (draftState) => {
                draftState[index].cancel = true;
                draftState[index].notes = notes;
                draftState[index].history = response.data.history;
            });
            setFilteredOrders(newOrders);
        } catch (e) {}
    };

    const ShowArrow = ({ sorted, order, classes }) => {
        if (sorted) {
            if (order === "ASC") {
                return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />;
            } else if (order === "DESC") {
                return <ArrowDownwardIcon fontSize="small" className={classes.headCellArrow} />;
            }
        } else {
            return <ArrowUpwardIcon fontSize="small" className={classes.headCellArrow} />;
        }
    };

    const createSortHandler = (_column) => {
        columns.map((column) => {
            if (column.id === _column.id) {
                if (_column.order === "ASC") {
                    column.order = "DESC";
                } else {
                    column.sorted = true;
                    column.order = "ASC";
                }
            } else {
                column.sorted = false;
                column.order = null;
            }
        });
        setColumns(columns);
        handleSort();
    };

    const handleSort = () => {
        // setPage(0);
        // getData();
    };

    const handleCheck = async (status, value, index) => {
        const orderId = filteredOrders[index].id;
        const httpRequest = HttpClient.createRequest();
        try {
            httpRequest
                .post(
                    `/api/order/${orderId}/confirm-plan`,
                    {
                        plan_confirmation: `${value}`,
                    },
                    { cancelToken: source.token },
                    true
                )
                .then(() => {
                    const newOrders = produce(filteredOrders, (draftState) => {
                        draftState[index].planConfirmationStatus = status;
                    });
                    setFilteredOrders(newOrders);
                });
        } catch (e) {}
    };

    const disabledFlagOff = (id) => {
        setIsNotEdited([...isNotEdited, id]);
    };

    const deleteFromUnread = (i) => {
        let newUnread;
        if (ordersWithUnreadMessages.length === 1) newUnread = [];
        else {
            newUnread = ordersWithUnreadMessages;
            newUnread.splice(i, 1);
        }
        setOrdersWithUnreadMessages(newUnread);
    };

    const checkIsOffsetOrderHasCurrentInConnected = (index, currentIndex) => {
        return filteredOrders[index]?.connectedOrders.find((el) => {
            return el === filteredOrders[currentIndex]?.number;
        });
    };

    let d = new Date();
    let currentDate = getUnixTime(d);

    const onCopyButtonClick = (itemId) => {
        const filteredCountdownStopList = copyClickCountdown.filter((item) => item.itemId !== itemId);

        const newCountdownStopList = [
            ...filteredCountdownStopList,
            {
                itemId,
                date: new Date(),
            },
        ];

        setCopyClickCountdown(newCountdownStopList);
        ordersCountdownDao.saveCountdownDates(newCountdownStopList);
    };

    const checkIfReadied = async () => {
        const httpRequest = HttpClient.createRequest();
        httpRequest
            .get(
                `api/order/comms/has-unreaded-messages`,
                {
                    params: {
                        // date
                    },
                },
                true,
                true,
                true
            )
            .then((response) => {
                const data = response.data.data ?? [];
                setOrdersWithUnreadMessages(data);
            });
    };

    const onDriverChange = async (dataItem, driver) => {
        setIsDriverUpdating(true);
        const relatedOrders = await getRelatedOrders(dataItem.id);
        updateDriverData(driver?.id, dataItem.id, relatedOrders);
    };

    const getRelatedOrders = async (orderId) => {
        const url = "api/related-orders/by/order/" + orderId;

        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(url, {}, true);
            return response.data.data;
        } catch (e) {}
    };

    const onRealDriverChange = (dataItem, driverName) => {
        updateRealDriverData(driverName, dataItem.id);
    };

    const expandedItems = items
        .map((item) => {
            if (expandedItemIds.includes(item.number) && item.subrows) {
                return [item, ...item.subrows];
            }
            return item;
        })
        .flat();

    const translations = {
        pl: {
            machinist: "Maszynista",
            phoneNumber: "Telefon",
            pickup: "Odbiór",
            destinationAddress: "Adres docelowy",
            comments: "UWAGI"
        },
        de: {
            machinist: "Lokführer",
            phoneNumber: "Telefon",
            pickup: "Abholadresse",
            destinationAddress: "Zieladresse",
            comments: "KOMMENTARE"
        }
    }

    const contractorIDsToTranslate = [
        "Freightliner DE GmbH"
    ]

    const getLocale = (dataItem) => {
        return (contractorIDsToTranslate.indexOf(dataItem.kontrahent.name) > -1) ? de : pl
    }

    const translate = (dataItem, property) => {
        const language = (contractorIDsToTranslate.indexOf(dataItem.kontrahent.name) > -1) ? "de" : "pl"

        return translations[language][property]
    }

    const baseMessage = (dataItem) =>
        `ID: ${dataItem.number}${
            dataItem.worker &&
            dataItem.worker.map((worker) => {
                return `\r\n${translate(dataItem, "machinist")}: ${worker.firstname} ${worker.surname}, ${translate(dataItem, "phoneNumber")}: ${worker.phoneNumber}`;
            })
        }\r\n${translate(dataItem, "pickup")}:${
            dataItem.isDestinationTime === false
                ? `\r\n${format(fromUnixTime(dataItem.destinationTime), "d.LL.yyyy, HH:mm", {
                    locale: getLocale(dataItem),
                })}`
                : ``
        }${
            dataItem.from
                ? `\r\n${dataItem.from.street} ${dataItem.from.city}`
                : dataItem.worker &&
                dataItem.worker.map((worker) => {
                    return `\r\n${worker.city} ${worker.street} ${worker.no}${worker.flat ? `/${worker.flat}` : ``} ${
                        worker.zipCode
                    }`;
                })
        }\r\n${translate(dataItem, "destinationAddress")}:${
            dataItem.isDestinationTime === true
                ? `\r\n${format(fromUnixTime(dataItem.destinationTime), "d.LL.yyyy, HH:mm", {
                    locale: getLocale(dataItem),
                })}`
                : ``
        }${
            dataItem.to
                ? `\r\n${dataItem.to.street ? `${dataItem.to.street} ` : ``}${dataItem.to.city}`
                : dataItem.worker &&
                dataItem.worker.map((worker) => {
                    return `\r\n${worker.city} ${worker.street} ${worker.no}${worker.flat ? `/${worker.flat}` : ``} ${
                        worker.zipCode
                    }`;
                })
        }\r\n${translate(dataItem, "comments")}:\r\n${
            dataItem.kontrahent.id === "facd7aa1-8270-11ec-af2b-0242c0a80002"
                ? `Prośba o kontakt sms z pracownikiem 30 min i 5 min przed podjazdem`
                : dataItem.kontrahent.id === "a6ac2649-4c3c-11ec-b2cc-0242ac1d0002"
                    ? `Prośba o kontakt z pracownikiem 2h przed dojazdem`
                    : dataItem.kontrahent.name === 'Freightliner DE GmbH' 
                        ? `Bleiben Sie bitte mit dem Lokführer/TF stets in Verbindung.` 
                        : `Bądź w bieżącym kontakcie z maszynistą`
        }\n\n`;

    const rows = expandedItems.map((dataItem, index) => {
        const orderDataToCopy = () => {
            if (dataItem.connectedOrders.length > 0) {
                const connectedOrdersData = dataItem.connectedOrders.reduce((acc, orderNumber) => {
                    const findConnectedOrder = expandedItems.find((item) => item.number === orderNumber);

                    if (findConnectedOrder) {
                        return acc.concat(baseMessage(findConnectedOrder));
                    }

                    return acc;
                }, "");

                return connectedOrdersData;
            }

            return baseMessage(dataItem);
        };
        const timeInFirstOrderOnly = () => {
            if (
                filteredOrders[index - 1]?.connectedOrders.length &&
                dataItem.connectedOrders.length &&
                filteredOrders[index - 1]?.connectedOrders[0] === dataItem.connectedOrders[0]
            )
                return false;

            return true;
        };

        const destinationTime = dataItem.destinationTime;
        const allDay = currentDate + 86400;

        //wyliczanie czasu
        const pickupKey = dataItem.driver ? 1 : 0;
        const targetKey = dataItem.driver ? -2 : -1;
        const targetIndex = dataItem.path.length + targetKey;

        const pickupTime = dataItem.path[pickupKey]
            ? fromUnixTime(parseInt(dataItem.path[pickupKey].time))
            : dataItem.pickupTime
                ? fromUnixTime(parseInt(dataItem.pickupTime))
                : null;
        const targetTime = dataItem.path[targetIndex]
            ? fromUnixTime(parseInt(dataItem.path[targetIndex].time))
            : dataItem.targetTime
                ? fromUnixTime(parseInt(dataItem.targetTime))
                : null;

        const destinationDate = dataItem.destinationTime
            ? format(fromUnixTime(dataItem.destinationTime), "d.LL.yyyy, HH:mm", {
                locale: pl,
            })
            : null;
        const pickupDate = pickupTime
            ? format(pickupTime, "d.LL.yyyy, HH:mm", {
                locale: pl,
            })
            : null;
        const targetDate = targetTime
            ? format(targetTime, "d.LL.yyyy, HH:mm", {
                locale: pl,
            })
            : null;

        const creationDate = new Date(dataItem.createDate * 1000);

        const copyClickCountdownDate = copyClickCountdown.find((item) => item.itemId === dataItem.id)?.date;

        const taxiDriver = (
            <DriverAsyncAutocomplete
                value={dataItem.driver}
                drivers={drivers ?? []}
                orderId={dataItem.id}
                order={dataItem}
                roles={roles}
                onChange={(driver) => onDriverChange(dataItem, driver)}
                isDriverUpdating={isDriverUpdating}
            />
        );

        const realTaxiDriver = (
            <RealDriverInput
                value={dataItem.realDriver}
                onChange={(driverName) => onRealDriverChange(dataItem, driverName)}
            />
        );

        const personData = dataItem.worker.map((item, index) => {
            return (
                <span className={styles["active__table--item--single"]} key={index}>
                    {item.firstname} {item.surname}
                </span>
            );
        });
        const personAddress = dataItem.worker.map((item, index) => (
            <span className={styles["active__table--item--single"]} key={index}>
                {`${item.city ? item.city : ""} ${item.street ? item.street : ""} ${item.no ? item.no : ""}${
                    item.flat ? `/${item.flat}` : ""
                } ${item.zipCode ? item.zipCode : ""}`}
            </span>
        ));

        let iconClass1 = "";
        let iconClass2 = "";
        if (dataItem.direction === 1) {
            iconClass2 = "active__table--item--toWork";
            if (dataItem.isWorkAddress) {
                iconClass1 = "active__table--item--toWork";
            } else iconClass1 = "active__table--item--toHome";
        } else if (dataItem.direction === 0) {
            iconClass1 = "active__table--item--toWork";
            if (dataItem.isWorkAddress) {
                iconClass2 = "active__table--item--toWork";
            } else iconClass2 = "active__table--item--toHome";
        }

        const Completionist = () => <span>{pickupDate}</span>;

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return <Completionist />;
            } else {
                return (
                    <span>
                        {hours <= 9 ? "0" + hours : hours}:{minutes <= 9 ? "0" + minutes : minutes}:
                        {seconds <= 9 ? "0" + seconds : seconds}
                    </span>
                );
            }
        };

        const dataNotes =
            dataItem.cancel && dataItem.history.length > 0 ? dataItem.history[0].cancelNotes : dataItem.notes;

        const shouldGrayOutRow = (dateTime) => {
            const startOfTodayTime = startOfToday().getTime();
            const endOfTodayTime = endOfToday().getTime();
            const timeOffset = 1000 * 60 * 60 * 6;
            const time = dateTime * 1000;

            const timeBefore = startOfTodayTime - timeOffset;
            const timeAfter = endOfTodayTime + timeOffset;

            return time < timeBefore || time > timeAfter;
        };

        const isRowGrayedOut =
            props.status === "active" && props.entity === "operator"
                ? dataItem.isDestinationTime
                    ? shouldGrayOutRow(dataItem.targetTime)
                    : shouldGrayOutRow(dataItem.pickupTime)
                : false;

        const getDispatcherClasses = () => {
            if (dataItem.cancel) return { timeLine: classes.timeLineAnulowany, singleDot: classes.anulowany };
            if (dataItem.status === "fail" || dataItem.status === null)
                return { timeLine: classes.timeLinePrzetwarzany, singleDot: classes.przetwarzany };
            if (
                dataItem.status === "regular" ||
                dataItem.status === "pending" ||
                (dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime > currentDate)
            )
                return { timeLine: classes.timeLineZaplanowany, singleDot: classes.zaplanowany };
            if (dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime < currentDate)
                return { timeLine: classes.timeLinewRealizacji, singleDot: classes.wRealizacji };
            if (currentDate - dataItem.pickupTime <= 0)
                return { timeLine: classes.timeLineZakonczony, singleDot: classes.zakonczony };

            return { timeLine: classes.timeLinewRealizacji, singleDot: classes.wRealizacji };
        };
        const getOperatorClasses = () => {
            const currentTimeStamp = Math.floor(Date.now() / 1000);
            if (currentTimeStamp - dataItem.targetTime >= 0 && props.status !== "archive")
                return { timeLine: classes.timeLineZakonczony, singleDot: classes.zakonczony };
            if (dataItem.cancel || (props.status === "archive" && dataItem.cancel))
                return { timeLine: classes.timeLineAnulowany, singleDot: classes.anulowany };
            if (dataItem.status === "pending")
                return { timeLine: classes.timeLinePrzypisany, singleDot: classes.przypisany };
            if (
                (dataItem.status === "fail" && dataItem.notificationFailTime <= currentDate) ||
                dataItem.status === "deny"
            )
                return { timeLine: classes.timeLineOdrzucony, singleDot: classes.odrzucony };
            if (dataItem.status === "regular" || dataItem.status === "fail" || dataItem.status === "deny")
                return { timeLine: classes.timeLinePrzetwarzany, singleDot: classes.przetwarzany };
            if (
                (dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime < currentDate) ||
                dataItem.status === "archive" ||
                dataItem.status === "frozen"
            )
                return { timeLine: classes.timeLinewRealizacji, singleDot: classes.wRealizacji };
            if (dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime > currentDate)
                return { timeLine: classes.timeLineZaplanowany, singleDot: classes.zaplanowany };

            return { timeLine: classes.timeLinePrzetwarzany, singleDot: classes.przetwarzany };
        };

        const getStatusClass = (entity) => {
            switch (entity) {
                case "dyspozytor":
                    return getDispatcherClasses();
                case "operator":
                    return getOperatorClasses();
            }
        };

        // const getDrivers = async () => {
        //     const url = "api/kierowca";

        //     const httpRequest = HttpClient.createRequest();
        //     try {
        //         const response = await httpRequest.get(url, {}, true);
        //         const responseDrivers = response.data.pracownicy;
        //         for (let i = 0; i < responseDrivers.length; i++) {
        //             responseDrivers[i].value = null;
        //         }
        //         setDrivers(responseDrivers);
        //     } catch (e) {}

        //     getRelatedOrders();
        // };

        return createData(
            dataItem.id,
            dataItem.subrows?.length &&
            !expandedItemIds.includes(dataItem.number) &&
            dataItem.subrows.some((subrow) => subrow.isEdited) ? (
                <span style={{ color: "red" }}>!!!</span>
            ) : dataItem.isEdited && !isNotEdited.find((el) => el === dataItem.id) ? (
                <span style={{ color: "red" }}>!!!</span>
            ) : (
                <span style={{ color: "gray" }}>!</span>
            ),
            isRowGrayedOut,
            dataItem.connectedOrders.length > 0,
            dataItem.number > dataItem.connectedOrders[0],
            dataItem.connectedOrders.length > 0 &&
            dataItem.number === dataItem.connectedOrders[dataItem.connectedOrders.length - 1],
            <span>
                {dataItem.relatedOrders?.map((relatedOrder) => {
                    const isHighlighted = !!selectedSuggestedCourses.find(
                        (item) => item.selectedFromCourseId === dataItem.number && item.id === relatedOrder
                    );
                    return (
                        <button
                            onClick={() => {
                                selectSuggestedCourse(Number(relatedOrder), dataItem.number);
                            }}
                            style={{
                                background: "none",
                                border: 0,
                                cursor: "pointer",
                                color: isHighlighted ? "#000" : "#b4b4b4",
                                fontSize: 12,
                            }}
                        >
                            {relatedOrder}
                        </button>
                    );
                })}
            </span>,
            <span>
                <Button
                    onClick={() => {
                        setIsEditCourseModalOpen(true);
                        setEditingCourse(dataItem);
                    }}
                    className={classes.editRelatedCoursesButton}
                >
                    Edytuj
                </Button>
            </span>,
            props.entity === "operator" && props.status === "active" ? (
                <>
                    <button
                        onClick={() => {
                            selectSuggestedCourses(dataItem.connectedOrders, dataItem.number);
                        }}
                        key={index}
                        style={{
                            background: "none",
                            border: 0,
                            color: "#000",
                            cursor: "pointer",
                            fontSize: 12,
                            width: `100%`,
                        }}
                    >
                        {dataItem.connectedOrders.map((connectedOrder, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: `100%`,
                                    }}
                                >
                                    {connectedOrder}
                                </div>
                            );
                        })}
                    </button>

                    <Button
                        onClick={() => {
                            setIsEditRelatedCoursesModalOpen(true);
                            setEditingCourse(dataItem);
                        }}
                        className={classes.editRelatedCoursesButton}
                    >
                        <AddIcon />
                    </Button>
                </>
            ) : (
                <div>
                    {dataItem.connectedOrders.map((connectedOrder, index) => {
                        return (
                            <button
                                key={index}
                                style={{
                                    background: "none",
                                    border: 0,
                                    color: "#000",
                                    fontSize: 12,
                                    width: `100%`,
                                }}
                            >
                                {connectedOrder}
                            </button>
                        );
                    })}
                </div>
            ),
            <div>
                {dataItem.status === "frozen" &&
                dataItem.startTime &&
                dataItem.startTime > currentDate &&
                dataItem.startTime < currentDate + 1800 &&
                props.entity === "operator" ? (
                        // JEDYNY PRZYPADEK TAKIEJ KONSTRUKCJI, JEDYNIE DLA TYCH PRZYPADKÓW I JEDYNIE DLA OPERATORA
                        <Tooltip title="Kierowca niebawem wyruszy w drogę, sprawdź, czy wszystkie dane w aplikacji zgadzają się ze stanem faktycznym!">
                        <span>
                            {dataItem.connectedOrders.length > 0 ? (
                                <TimelineItem style={{ minHeight: 42 }}>
                                    <TimelineSeparator>
                                        {Math.trunc(filteredOrders[index - 1]?.destinationTime / 60) <=
                                        Math.trunc(dataItem.destinationTime / 60) &&
                                        filteredOrders[index - 1]?.connectedOrders.find((el) => {
                                            return el === dataItem.number;
                                        }) ? (
                                            <TimelineConnector
                                                className={`${classes.timeConnector} ${classes.timeLineZaplanowany}`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <TimelineDot
                                            className={`${classes.timeDot} ${classes.timeLineZaplanowany} ${classes.timeLinePrzedStartem}`}
                                        />
                                        {Math.trunc(filteredOrders[index + 1]?.destinationTime / 60) >=
                                        Math.trunc(dataItem.destinationTime / 60) &&
                                        filteredOrders[index + 1]?.connectedOrders.find((el) => {
                                            return el === dataItem.number;
                                        }) ? (
                                            <TimelineConnector
                                                className={`${classes.timeConnector} ${classes.timeLineZaplanowany}`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </TimelineSeparator>
                                </TimelineItem>
                            ) : (
                                <FiberManualRecordIcon
                                    className={
                                        dataItem.planConfirmationStatus === 2
                                            ? classes.zaplanowany
                                            : classes.alertPrzedStartem
                                    }
                                />
                            )}
                        </span>
                        </Tooltip>
                    ) : // WSZELKIE INNE PRZYPADKI ZAŁATWIAMY TYM, PRZEKAZUJĄC DO FUNKCJI ZWRACAJĄCEJ ODPOWIEDNIĄ KLASĘ ZMIENNĄ entity,
                    // ZALEŻNĄ OD TEGO JAKI WIDOK JEST WYŚWIETLANY
                    dataItem.connectedOrders.length > 0 ? (
                        <>
                            {dataItem.isExpandToggleButtonVisible && (
                                <>
                                    {expandedItemIds.includes(dataItem.number) ? (
                                        <button
                                            className={classes.expandRowButton}
                                            onClick={() => collapseRow(dataItem.number)}
                                        >
                                            <ExpandLessIcon />
                                        </button>
                                    ) : (
                                        <button
                                            className={classes.expandRowButton}
                                            onClick={() => expandRow(dataItem.number)}
                                        >
                                            <ExpandMoreIcon />
                                        </button>
                                    )}
                                </>
                            )}
                            <TimelineItem style={{ minHeight: 42 }}>
                                <TimelineSeparator>
                                    {dataItem.isTopTimelineConnectorVisible ? (
                                        <>
                                            <TimelineConnector className={getStatusClass(props.entity).timeLine} />
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <TimelineDot
                                        className={`${classes.timeDot} ${getStatusClass(props.entity).timeLine}`}
                                    />
                                    {dataItem.isDownTimelineConnectorVisible ? (
                                        <TimelineConnector className={getStatusClass(props.entity).timeLine} />
                                    ) : (
                                        ""
                                    )}
                                </TimelineSeparator>
                            </TimelineItem>
                        </>
                    ) : (
                        <FiberManualRecordIcon className={getStatusClass(props.entity).singleDot} />
                    )}
            </div>,
            //xxx Potwierdzenia dla kursu
            <div>
                {dataItem.status === "frozen" &&
                dataItem.startTime &&
                dataItem.startTime > currentDate &&
                dataItem.startTime < currentDate + 1800 ? (
                    dataItem.planConfirmationStatus === 2 ? (
                        <div className={classes.planWrapper}>
                            <Tooltip title="Kurs sprawdzony i potwierdzony">
                                <span>
                                    <DoneAllIcon className={classes.doneAllIcon} />
                                </span>
                            </Tooltip>
                        </div>
                    ) : (
                        <div className={classes.planWrapper}>
                            <Tooltip title="Kurs zweryfikowany przez Operatora.">
                                <span>
                                    <PlanCheckbox
                                        checked={
                                            dataItem.planConfirmationStatus === 1 ||
                                            dataItem.planConfirmationStatus === 2
                                        }
                                        onChange={() => handleCheck(1, "dispatcher", index)}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip
                                title={
                                    dataItem.planConfirmationStatus === 0
                                        ? "Najpierw zweryfikuj poprawność kursu zanim przejdziesz do tego kroku"
                                        : "Kurs potwierdzony przez Kierowcę."
                                }
                            >
                                <span>
                                    <PlanCheckbox
                                        disabled={dataItem.planConfirmationStatus === 0}
                                        checked={dataItem.planConfirmationStatus === 2}
                                        onChange={() => handleCheck(2, "driver", index)}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    )
                ) : (
                    <div className={classes.planWrapper}></div>
                )}
            </div>,

            destinationTime < allDay && timeInFirstOrderOnly() ? (
                <div className={styles["active__table--item--counterWrapper"]}>
                    <div className={styles["active__table--item--counterWrapper--single"]}>
                        <PlaceOutlined />
                        {pickupTime && !dataItem.cancel ? (
                            <div>{format(pickupTime, "dd.LL.yy HH:mm")}</div>
                        ) : (
                            "--:--:--"
                        )}
                    </div>
                    <div className={styles["active__table--item--counterWrapper--single"]}>
                        {pickupTime && !dataItem.cancel ? (
                            <Countdown
                                date={pickupTime}
                                daysInHours={true}
                                onComplete={() => {
                                    let order = filteredOrders[index];
                                    const newOrders = filteredOrders.filter((item) => {
                                        return item !== order;
                                    });
                                    setFilteredOrders(newOrders);
                                }}
                            />
                        ) : (
                            "--:--:--"
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles["active__table--item--counterWrapper"]}>
                    <div className={styles["active__table--item--counterWrapper--single"]}>
                        <PlaceOutlined />
                        <span>--:--:--</span>
                    </div>
                    <div className={styles["active__table--item--counterWrapper--single"]}>
                        <TimerOutlined />
                        <span>--:--:--</span>
                    </div>
                </div>
            ),

            //czasy dla archiwum

            <div className={styles["active__table--item--counterWrapper"]}>
                <div className={styles["active__table--item--counterWrapper--single"]}>
                    <div className={styles[iconClass1]}></div>
                    {pickupTime && !dataItem.cancel ? <span>{pickupDate}</span> : "--:--:--"}
                </div>
                <div className={styles["active__table--item--counterWrapper--single"]}>
                    <div className={styles[iconClass2]}></div>
                    {pickupTime && !dataItem.cancel ? <span>{targetDate}</span> : "--:--:--"}
                </div>
            </div>,
            dataItem.number ? `${dataItem.number}` : "brak numeru",

            //Godzina odbioru / dotarcia na miejsce
            dataItem.isDestinationTime ? (
                <>
                    <div className={styles["active__startMetaTime--single"]}>
                        <span className={styles["active__startMetaTime--single--invisible"]}>___</span>
                    </div>
                    {dataItem.destinationTime ? (
                        <div className={styles["active__startMetaTime--single"]}>
                            <span>{destinationDate}</span>
                        </div>
                    ) : (
                        <div className={styles["active__startMetaTime--single"]}>
                            <span>"-"</span>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {dataItem.destinationTime ? (
                        <div className={styles["active__startMetaTime--single"]}>
                            <span>{destinationDate}</span>
                        </div>
                    ) : (
                        <div className={styles["active__startMetaTime--single"]}>
                            <span>"-"</span>
                        </div>
                    )}
                    <div className={styles["active__startMetaTime--single"]}>
                        <span className={styles["active__startMetaTime--single--invisible"]}>___</span>
                    </div>
                </>
            ),
            props.status === "active" ? (
                dataItem.cancel ? (
                    <span className={styles["active__table--item--deny--data"]}>Zlecenie anulowane</span>
                ) : dataItem.driver && !dataItem.cancel ? (
                    dataItem.status === "deny" ? (
                        <div className={styles["active__table--item--deny"]}>
                            <span className={styles["active__table--item--deny--data"]}>{taxiDriver}</span>
                            <span className={styles["active__table--item--deny--data"]}>Zlecenie odrzucone</span>
                            <span className={styles["active__table--item--deny--alert"]}>Obsadź kurs RĘCZNIE</span>
                        </div>
                    ) : dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime > currentDate ? (
                        <div className={styles["active__table--item--frozen"]}>
                            <span className={styles["active__table--item--frozen--data"]}>{taxiDriver}</span>
                            <span className={styles["active__table--item--frozen--alert"]}>Potwierdzony</span>
                        </div>
                    ) : dataItem.status === "pending" ? (
                        <div className={styles["active__table--item--przypisany"]}>
                            <span className={styles["active__table--item--przypisany--data"]}>{taxiDriver}</span>
                            {!roles.includes("ROLE_OPERATOR_CONTRACTOR") && <span className={styles["active__table--item--przypisany--alert"]}>Powiadom kierowcę</span>}
                        </div>
                    ) : dataItem.status === "frozen" && dataItem.startTime && dataItem.startTime < currentDate ? (
                        <div className={styles["active__table--item--wTrasie"]}>
                            <span className={styles["active__table--item--wTrasie--data"]}>{taxiDriver}</span>
                            <span className={styles["active__table--item--wTrasie--alert"]}>Kierowca w trasie</span>
                        </div>
                    ) : (
                        <div className={styles["active__table--item"]}>{taxiDriver}</div>
                    )
                ) : dataItem.status === "fail" && dataItem.notificationFailTime <= currentDate ? (
                    <div className={styles["active__table--item--deny"]}>
                        {taxiDriver}
                        <span className={styles["active__table--item--deny--data"]}>Nieobsadzony</span>
                        <span className={styles["active__table--item--deny--alert"]}>Obsadź kurs RĘCZNIE</span>
                    </div>
                ) : (
                    taxiDriver
                )
            ) : dataItem.driver ? (
                `${dataItem.driver.firstname} ${dataItem.driver.surname}`
            ) : (
                `-`
            ),
            props.status === "active" ? realTaxiDriver : dataItem.realDriver ?? "-",
            dataItem.isPushNotificationSend !== null &&
            (dataItem.status === "pending" || dataItem.status === "frozen") ? (
                dataItem.isPushNotificationSend === 1 ? (
                    <div>
                        <Tooltip title="Wysłano">
                            <span>
                                <MobileFriendlyIcon className={classes.pushNotificationSentSuccessIcon} />
                            </span>
                        </Tooltip>
                    </div>
                ) : dataItem.isPushNotificationSend === 0 ? (
                    <div>
                        <Tooltip title="Nie wysłano">
                            <span>
                                <MobileOffIcon className={classes.pushNotificationSentFailtureIcon} />
                            </span>
                        </Tooltip>
                    </div>
                ) : (
                    <div>
                        <Tooltip title="Brak aplikacji">
                            <span>
                                <MobileOffIcon className={classes.pushNotificationNoAppIcon} />
                            </span>
                        </Tooltip>
                    </div>
                )
            ) : (
                "-"
            ),
            dataItem.worker ? (
                <>
                    <div className={styles["active__table--item"]}>{personData}</div>
                </>
            ) : (
                "-"
            ),
            dataItem.from ? (
                <div className={styles["active__table--item"]}>{`${dataItem.from.street} ${dataItem.from.city}`}</div>
            ) : (
                <div className={styles["active__table--item"]}>{personAddress}</div>
            ),
            dataItem.to ? (
                <div className={styles["active__table--item"]}>{`${dataItem.to.street} ${dataItem.to.city}`}</div>
            ) : (
                <div className={styles["active__table--item"]}>{personAddress}</div>
            ),
            dataItem.orderNo ? <div className={classes.content}>{dataItem.orderNo}</div> : "-",
            dataNotes ? (
                <div className={classes.content}>{`${
                    dataNotes.length >= 20 ? dataNotes.substring(0, 20) + "..." : dataNotes
                }`}</div>
            ) : (
                "-"
            ),
            dataItem.kontrahent ? `${dataItem.kontrahent.name}` : "-",
            roles.includes("ROLE_SUPER_ADMIN") ||
            roles.includes("ROLE_OPERATOR") ||
            roles.includes("ROLE_SUPER_OPERATOR") ? (
                <PartnerAsyncAutocomplete
                    value={dataItem.consortiumMemberName}
                    options={partnerOptions}
                    isContractorDriver={dataItem.driver?.billingGroup.name !== 'CS' && dataItem.driver?.billingGroup.name !== 'K27'}
                    isContractorOperator={isRoleType('OPERATOR_CONTRACTOR')}
                    onChange={(value) => onPartnerChange(value, dataItem.id)}
                />
            ) : (
                dataItem.consortiumMemberName ?? "-"
            ),
            <>
                <div className={styles["active__buttonContainer"]}>
                    {props.entity === "operator" && props.status === "active" && (
                        <Tooltip title="Kopiuj do schowka">
                            <span>
                                <CopyToClipboard text={orderDataToCopy()}>
                                    <IconButton
                                        size="small"
                                        className={classes.copyIconButton}
                                        onClick={() => onCopyButtonClick(dataItem.id)}
                                    >
                                        <CopyIcon className={classes.copyIcon} />
                                    </IconButton>
                                </CopyToClipboard>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title="Panel komunikacji">
                        <span>
                            <IconButton
                                size="small"
                                style={
                                    ordersWithUnreadMessages?.find((orderID) => {
                                        return orderID === filteredOrders[index]?.id;
                                    })
                                        ? { backgroundColor: "orange" }
                                        : null
                                }
                                className={classes.iconButton}
                                onClick={() => {
                                    setIndex(index);
                                    setCourseDetails(filteredOrders[index]);
                                    switchCommsPanel(filteredOrders[index].id);
                                }}
                            >
                                <MessageTwoTone className={classes.icon} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Szczegóły">
                        <span>
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                onClick={() => {
                                    setIndex(index);
                                    setShowDetails(!showDetails);
                                }}
                            >
                                <MoreHorizIcon className={classes.icon} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {props.status === "active" && !dataItem.cancel && (
                        <>
                            <Tooltip title={"Edycja"}>
                                <span>
                                    <IconButton
                                        size="small"
                                        className={classes.iconButton}
                                        onClick={() => redirectToOrderPanel(dataItem)}
                                    >
                                        <EditIcon className={classes.icon} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                onClick={() => {
                                    setIndex(index);
                                    const type = filteredOrders[index].cancel;
                                    handleSwitchInfoDialog(true, type);
                                }}
                                title="Anuluj"
                            >
                                <span>
                                    <IconButton
                                        className={classes.deleteButton}
                                        disabled={dataItem.cancel}
                                        size="small"
                                    >
                                        <ClearIcon className={classes.icon} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    )}
                </div>
                {props.entity === "operator" &&
                    props.status === "active" &&
                    !dataItem.cancel &&
                    (!dataItem.driver || (dataItem.status !== "frozen" && dataItem.status !== "pending")) && (
                        <div className={classes.timer}>
                            {copyClickCountdownDate && !dataItem.cancel ? (
                                <>
                                    <Tooltip title="Skopiowane">
                                        <CopyIcon style={{ marginRight: 5, width: 10, height: 10, color: `#b4b4b4` }} />
                                    </Tooltip>

                                    <OrdersTimer startDate={copyClickCountdownDate} />
                                </>
                            ) : (
                                <OrdersTimer startDate={creationDate} />
                            )}
                        </div>
                    )}
            </>,
            dataItem.author.branch ?? "-",
            dataItem.isEdited,
            dataItem.cancel
        );
    });

    const findByOrderNumber = (number) => {
        filteredOrders.map((el) => {
            if (el.number === number) {
                setCourseDetails(el);
            }
        });
    };

    const handleClearFilters = () => {
        if (isSearchFiltersActive()) {
            setSearchValues([{ id: "time", value: null }])
            orderDao.clearFilter();
            getData(true);
        }
    }

    const isSearchFiltersActive = () => {
        return Object.keys(searchValues).length > 1
    }

    return (
        <div className={`${styles["active"]} ${props.entity === "operator" && styles["operator"]}`}>
            <Paper className={classes.root}>
                {props.status === "archive" && (
                    <DateTabs
                        handleDayChange={handleDayChange}
                        handleMonthChange={handleMonthChange}
                        handleYearChange={handleYearChange}
                        handleDateChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        day={day}
                        month={month}
                        year={year}
                        daysInMonth={daysInMonth}
                    />
                )}
                <div className={styles["active__description"]}>
                    <h1 className={styles["active__description--title"]}>
                        {props.status === "active" ? "AKTYWNE KURSY" : "ZAKOŃCZONE KURSY"}
                    </h1>
                    {props.entity === "operator" ? (
                        <div className={styles["active__description--button"]}>
                            <Button
                                className={classes.changeViewButton}
                                variant="outlined"
                                onClick={() =>
                                    history.push(
                                        `/app/operator/trasy/${
                                            props.status === "archive" ? "archiveDashboard" : "activeDashboard"
                                        }`
                                    )
                                }
                            >
                                Przełącz widok
                            </Button>
                        </div>
                    ) : null}
                    {props.status === "active" ? (
                        <>
                            <div className={styles["active__description--legend"]}>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.przetwarzany} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        Przetwarzany
                                    </p>
                                </div>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.zaplanowany} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        {props.entity === "operator" ? "Potwierdzony przez kierowcę" : "Zaplanowany"}
                                    </p>
                                </div>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.wRealizacji} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        {props.entity === "operator" ? "Kierowca w trasie" : "W realizacji"}
                                    </p>
                                </div>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.anulowany} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        Anulowany
                                    </p>
                                </div>

                                {props.entity === "operator" ? (
                                    <>
                                        <div className={styles["active__description--legend--legendItem"]}>
                                            <FiberManualRecordIcon className={classes.przypisany} />
                                            <p
                                                className={
                                                    styles["active__description--legend--legendItem--singleLegend"]
                                                }
                                            >
                                                Kierowca przypisany - POINFORMUJ KIEROWCĘ
                                            </p>
                                        </div>
                                        <div className={styles["active__description--legend--legendItem"]}>
                                            <FiberManualRecordIcon className={classes.odrzucony} />
                                            <p
                                                className={
                                                    styles["active__description--legend--legendItem--singleLegend"]
                                                }
                                            >
                                                Kierowca odrzucił zlecenie / Kurs nieobsadzony
                                            </p>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles["archive__description--legend"]}>
                                <div className={styles["archive__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.wRealizacji} />
                                    <p className={styles["archive__description--legend--legendItem--singleLegend"]}>
                                        Zrealizowany
                                    </p>
                                </div>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.anulowany} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        Anulowany
                                    </p>
                                </div>
                                <div className={styles["active__description--legend--legendItem"]}>
                                    <FiberManualRecordIcon className={classes.przetwarzany} />
                                    <p className={styles["active__description--legend--legendItem--singleLegend"]}>
                                        Niezrealizowany
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Tooltip
                    style={{marginRight: "1em"}}
                    onClick={() => {
                        getData();
                    }}
                    title="Odśwież dane"
                >
                                <span>
                                    <IconButton
                                        className={classes.refreshButton}
                                        // disabled={dataItem.cancel}
                                        size="small"
                                    >
                                        <RefreshOutlined className={classes.icon} />
                                    </IconButton>
                                </span>
                </Tooltip>
                <Tooltip
                    style={{marginRight: "1em"}}
                    onClick={() => {
                        handleClearFilters()
                    }}
                    title="Wyczyść filtry"
                >
                                <span>
                                    <IconButton
                                        className={
                                            isSearchFiltersActive() ?
                                                classes.refreshButton : classes.refreshButtonDisabled
                                        }
                                        size="small">
                                        <RemoveCircleOutlined className={classes.icon} />
                                    </IconButton>
                                </span>
                </Tooltip>
                {props.entity === "operator" && (
                    <>
                        { !isRoleType('OPERATOR_CONTRACTOR') &&
                            <FormControlLabel
                                control={
                                    <ExludeCheckbox
                                        checked={isLotosExcluded}
                                        onChange={(_event, value) => {
                                            setIsLotosExcluded(value);
                                            orderDao.setExcludeLotos(value);
                                        }}
                                    />
                                }
                                label="Wyklucz Lotos"
                            />
                        }
                        {props.status !== "archive" && (
                            <FormControlLabel
                                control={
                                    <ExludeCheckbox
                                        checked={isImportantOrdersFilter}
                                        onChange={(_event, value) => {
                                            setIsImportantOrdersFilter(value);
                                            orderDao.setImportantOrdersFilter(value);
                                        }}
                                    />
                                }
                                label="Pilne zlecenia"
                            />
                        )}
                        {props.status === "archive" ? (
                            <FormControlLabel
                                control={
                                    <ExludeCheckbox
                                        checked={isUnfilledOrdersOnly}
                                        onChange={(_event, value) => {
                                            setIsUnfilledOrdersOnly(value);
                                            orderDao.setUnfilledOrdersOnly(value);
                                        }}
                                    />
                                }
                                label="Wyklucz kursy obsadzone"
                            />
                        ) : null}
                    </>
                )}
                <FormControlLabel
                    control={
                        <ExludeCheckbox
                            checked={shouldCollapseAllOrders}
                            onChange={(_event, value) => {
                                setShouldExpandAllOrders(value);
                            }}
                        />
                    }
                    label="Zwiń zlecenia"
                />

                {isLoading || isDriversLoading ? (
                    <ProgressLine />
                ) : (
                    <TableContainer>
                        <SimpleBar
                            style={{
                                minHeight: props.status === "active" ? "58vh" : "53vh",
                                maxHeight: props.status === "active" ? "undefined" : "53vh",
                            }}
                            className={classes.simplebar}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        {columns.map((column, index) =>
                                            (column.id === "relatedOrders" ||
                                                column.id === "suggestedRelatedCourses") &&
                                            user?.roles.indexOf("ROLE_OPERATOR_PARTNER") !== -1 ? null : (
                                                <Fragment key={column.id}>
                                                    <TableCell
                                                        align={column.align}
                                                        style={{
                                                            minWidth: column.minWidth,
                                                            maxWidth: column.maxWidth,
                                                        }}
                                                    >
                                                        {index === 0 && (
                                                            <p>
                                                                <b>Kursów: {count}</b>
                                                            </p>
                                                        )}
                                                        {column.query ? (
                                                            <TableSortLabel
                                                                className={classes.tableSortLabel}
                                                                hideSortIcon={true}
                                                            >
                                                                {column.id === "time" ? (
                                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                                        <Input
                                                                            className={classes.headCell}
                                                                            inputComponent={SearchDatePicker}
                                                                            inputProps={{
                                                                                style: { height: "unset" },
                                                                                onChange: (newValue) =>
                                                                                    handleDatePickerDateChange(
                                                                                        newValue
                                                                                    ),
                                                                                value: getDatePickerValue(),
                                                                                format: "d MMMM",
                                                                                disableToolbar:
                                                                                    props.status === "archive",
                                                                                InputProps: {
                                                                                    disableUnderline: true,
                                                                                    placeholder: column.label,
                                                                                },
                                                                            }}
                                                                            endAdornment={
                                                                                <>
                                                                                    <Tooltip title="Wyczyść">
                                                                                        <span>
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() =>
                                                                                                    clearDate()
                                                                                                }
                                                                                                aria-label="visible columns"
                                                                                            >
                                                                                                <ClearIcon
                                                                                                    className={
                                                                                                        classes.headCellArrow
                                                                                                    }
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            </IconButton>
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Sortuj">
                                                                                        <span>
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() =>
                                                                                                    createSortHandler(
                                                                                                        column,
                                                                                                        index,
                                                                                                        rows
                                                                                                    )
                                                                                                }
                                                                                                aria-label="visible columns"
                                                                                            >
                                                                                                <ShowArrow
                                                                                                    sorted={
                                                                                                        column.sorted
                                                                                                    }
                                                                                                    order={column.order}
                                                                                                    classes={classes}
                                                                                                />
                                                                                            </IconButton>
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </>
                                                                            }
                                                                        />
                                                                    </ThemeProvider>
                                                                ) : (
                                                                    <Input
                                                                        variant="outlined"
                                                                        className={classes.headCell}
                                                                        defaultValue={
                                                                            searchValues.find((v) => v.id === column.id)
                                                                                ?.value
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleSearch(column.id, e.target.value)
                                                                        }
                                                                        onKeyDown={handleOnEnterPress}
                                                                        placeholder={column.label}
                                                                        endAdornment={
                                                                            <>
                                                                                <Tooltip title="Wyczyść">
                                                                                    <span>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={(e) =>
                                                                                                handleClearColumn(
                                                                                                    column.id, e.target
                                                                                                )
                                                                                            }
                                                                                            aria-label="visible columns"
                                                                                        >
                                                                                            <ClearIcon
                                                                                                className={
                                                                                                    classes.headCellArrow
                                                                                                }
                                                                                                fontSize="small"
                                                                                            />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>
                                                                                {column.sorted !== null && (
                                                                                    <Tooltip title="Sortuj">
                                                                                        <span>
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    createSortHandler(
                                                                                                        column,
                                                                                                        index,
                                                                                                        rows
                                                                                                    )
                                                                                                }
                                                                                                size="small"
                                                                                                aria-label="visible columns"
                                                                                            >
                                                                                                <ShowArrow
                                                                                                    sorted={
                                                                                                        column.sorted
                                                                                                    }
                                                                                                    order={column.order}
                                                                                                    classes={classes}
                                                                                                />
                                                                                            </IconButton>
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    />
                                                                )}
                                                            </TableSortLabel>
                                                        ) : column.id === "checked" ? (
                                                            <div className={classes.checkedLabelWrapper}>
                                                                <Tooltip title="Potwierdzenia kursu przez Operatora">
                                                                    <span>
                                                                        <EventAvailableIcon
                                                                            className={classes.labelIcon}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        ) : (
                                                            column.label
                                                        )}
                                                    </TableCell>
                                                </Fragment>
                                            )
                                        )}
                                    </TableRow>
                                </TableHead>
                                {filteredOrders && filteredOrders.length > 0 ? (
                                    <TableBody>
                                        {rows &&
                                            rows.map((row) => {
                                                // JEŚLI MAMY URUCHOMIONY FILTR WAŻNYCH ZLECEŃ I ZLECENIE ANULOWANE
                                                // _NIE_ JEST EDYTOWANE, TO JE OMIJAMY. DOMYŚLNIE ZLECENIE, KTÓRE ZOSTAJE
                                                // ANULOWANE POSIADA FLAGĘ isEdited. NATOMIAST PO PRZEJRZENIU HISTORII
                                                // TAKIEGO ZLECENIA FLATA jest ustawiona na FALSE -> NIE PORZEBUJEMY
                                                // TAKIEGO ZLECENIA TUTAJ WYŚWIETLIĆ
                                                if (
                                                    row.isCanceled &&
                                                    (!row.isEdited || isNotEdited.find((el) => el === row.id)) &&
                                                    isImportantOrdersFilter
                                                )
                                                    return null;
                                                const rowId = row.number;
                                                const isHighlighted =
                                                    selectedSuggestedCourses.find(
                                                        (course) => course.id === Number(rowId)
                                                    )?.isRowHighlighted ?? false;

                                                return (
                                                    <TableRow
                                                        hoverd
                                                        role="checkbox"
                                                        key={row.id}
                                                        style={
                                                            isHighlighted
                                                                ? { background: "#ebf3f6" }
                                                                : row.isRowGrayedOut
                                                                    ? { opacity: 0.7, backgroundColor: "#e0e0e0" }
                                                                    : {}
                                                        }
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (
                                                                (column.id === "relatedOrders" ||
                                                                    column.id === "suggestedRelatedCourses") &&
                                                                user?.roles.indexOf("ROLE_OPERATOR_PARTNER") !== -1
                                                            )
                                                                return null;
                                                            else {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        className={classes.tableCell}
                                                                    >
                                                                        {column.format && typeof value === "number"
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                ) : (
                                    <div className={styles["active__noData"]}>Brak danych</div>
                                )}
                            </Table>
                        </SimpleBar>
                        {props.status !== "active" ? (
                            <TablePagination
                                className={classes.pagination}
                                labelRowsPerPage={"Liczba wierszy"}
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        ) : null}
                    </TableContainer>
                )}
            </Paper>
            {showDetails ? (
                <MoreDetails
                    onDriverChange={() => {
                        setShowDetails(false);
                    }}
                    onRealDriverChange={() => {
                        setShowDetails(false);
                    }}
                    onUpdateIsEdited={(id) => {
                        disabledFlagOff(id);
                    }}
                    switchShowModal={switchShowModal}
                    courseDetails={expandedItems[index]}
                    updateData={updateData}
                    entity={props.entity}
                    userRoles={user?.roles}
                    listStatus={props.status}
                />
            ) : null}
            {showCommsPanel ? (
                <CommunicationPanel
                    switchCommsPanel={switchCommsPanel}
                    courseDetails={courseDetails ?? filteredOrders[index]}
                    onUpdateIsEdited={(id) => {
                        disabledFlagOff(id)
                    }}
                    entity={props.entity}
                    listStatus={props.status}
                    userRoles={user?.roles}
                    onFindOrderID={(number) => {
                        findByOrderNumber(number);
                    }}
                />
            ) : null}
            {showInfoDialogCancel ? (
                <InfoDialog
                    notesAction={!filteredOrders[index].cancel && saveNotes}
                    notes={!filteredOrders[index].cancel && notes}
                    alertReset={handleSwitchInfoDialog}
                    confirmAction={cancelOrder}
                    message="Czy na pewno chcesz anulować to zlecenie?"
                />
            ) : null}
            <CombinedCourseModal
                isOpen={isEditCourseModalOpen}
                onCloseClick={() => {
                    setEditingCourse(null);
                    setIsEditCourseModalOpen(false);
                }}
                drivers={drivers}
                course={editingCourse}
            />
            <MergedOrdersModal
                isOpen={isEditRelatedCoursesModalOpen}
                onCloseClick={() => {
                    setEditingCourse(null);
                    setIsEditRelatedCoursesModalOpen(false);
                }}
                course={editingCourse}
                onSuccess={getData}
            />
        </div>
    );
}