import React, {useEffect, useState} from 'react';
import styles from './zlecenie.module.scss';
import RailyLogo from "../../../img/Raily_siwe_poziom.png";
import CargoLogo from "../../../img/logo_Cargo_poziom_png_kolor 4.png"
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from 'react-router-dom';
// import Countdown from "react-countdown";
import {format, fromUnixTime, getUnixTime, getTime} from "date-fns";
import {pl} from "date-fns/locale";
import HttpClient from "../../../HttpClient";
import axios from "axios";
import {useParams} from "react-router-dom";

const useStyles = makeStyles({
    refuseButton: {
        flexBasis: "48%",
        backgroundColor: "#b4b4b4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
    acceptButton: {
        flexBasis: "48%",
        marginLeft: 5,
        backgroundColor: "#1DBBED",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#00a0e3",
        },
    },
    goBackButton: {
        flexBasis: "100%",
        margin: "10px 30px",
        backgroundColor: "#b4b4b4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#777777",
        },
    },
});

export default function Zlecenie() {
    const {orderId} = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [accept, setAccept] = useState(false);
    const [courseDetails, setCourseDetails] = useState(null)
    const shortId = {orderId}.orderId;
    const source = axios.CancelToken.source();
    // const startAt = courseDetails ? format(fromUnixTime(courseDetails.startAt), "d.LL.yyyy, HH:mm", {
    //     locale: pl,
    // }) : "-";
    // const finishAt = courseDetails ? format(fromUnixTime(courseDetails.finishAt), "d.LL.yyyy, HH:mm", {
    //     locale: pl,
    // }) : "-";

    useEffect(() => {
        getData();
        return () => {
            source.cancel();
        };
    }, []);

    const getData = async () => {
        const url = `/api/order/${shortId}`;
        const httpRequest = HttpClient.createRequest();
       
        try {
            const response = await httpRequest.get(url, {}, true);
            setCourseDetails(response.data);
            if (response.data.status === 1) setAccept(true);
        } catch (e) {
            history.push('/')
        }
    };

    const sendData = async (value) => {
        const url = `/api/order/${shortId}/response?response=${value}`;
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.put(url, {value}, {cancelToken: source.token}, true);
            if (value === 1) {
                setAccept(true)
            }
            if (value === 0) {
                history.push('/')
            }
        } catch (e) {}
    };

    //TODO wprowadzić dynamiczną datę z API dla countera
    //TODO Komunikat z serwera -> informacja, że czas na odpowiedź się skończył
    // let currentTime = getTime(new Date());
    // let countTime = currentTime + 9000;
    // const renderer = ({hours, minutes, seconds, completed}) => {
    //     if (completed) {
    //         sendData(0);
    //     } else {
    //         return (
    //             <span>
    //       {hours <= 9 ? "0" + hours : hours}:{minutes <= 9 ? "0" + minutes : minutes}:{seconds <= 9 ? "0" + seconds : seconds}
    //     </span>
    //         );
    //     }
    // };

    return (
        <div className={styles["zlecenie"]}>
            <div className={styles["zlecenie__wrapper"]}>
                <div className={styles["zlecenie__wrapper--header"]}>
                    <div className={styles["zlecenie__wrapper--header--logo"]}>
                        <img height="30" src={RailyLogo} alt="logo"></img>
                    </div>
                    <div className={styles["zlecenie__wrapper--header--logo"]}>
                        <img height="30" src={CargoLogo} alt="logo"></img>
                    </div>
                </div>
                {courseDetails?.map((courseDetail, i) => (
                <div> 
                <div className={styles["zlecenie__wrapper--about"]}>
                    <p className={styles["zlecenie__wrapper--about--title"]}>Nr zlecenia: <span
                        className={styles["zlecenie__wrapper--about--title--data"]}>{courseDetail?.id}</span>
                    </p>
               
                    <p className={styles["zlecenie__wrapper--about--title"]}>Start kursu: <span
                        className={styles["zlecenie__wrapper--about--title--data"]}>
                            { courseDetail ? format(fromUnixTime(courseDetail.startAt), "d.LL.yyyy, HH:mm", {locale: pl,}) : "-"}
                        </span></p>
                    <p className={styles["zlecenie__wrapper--about--title"]}>Koniec kursu: <span
                        className={styles["zlecenie__wrapper--about--title--data"]}>
                            { courseDetail ? format(fromUnixTime(courseDetail.finishAt), "d.LL.yyyy, HH:mm", {locale: pl,}) : "-"}
                        </span></p>
                    <p className={styles["zlecenie__wrapper--about--title"]}>Trasa: <span
                        className={styles["zlecenie__wrapper--about--title--data"]}>{Math.round(courseDetail?.totalKm)}km</span>
                    </p>
                </div>
                <div className={styles["zlecenie__wrapper--routeParts"]}>
                    <p className={styles["zlecenie__wrapper--routeParts--description"]}>Etapy przejazu:</p>
                    {courseDetail ? courseDetail.routeParts.map((route, index) => {
                        let timeAtPlace = route.timeAtPlace ? format(fromUnixTime(route.timeAtPlace), "d.LL.yyyy, HH:mm", {
                            locale: pl,
                        }) : "-";
                        return (
                            <div key={index} className={styles["zlecenie__wrapper--routeParts--single"]}>
                                <p className={styles["zlecenie__wrapper--routeParts--single--title"]}>{index + 1}.
                                    adres: <span
                                        className={styles["zlecenie__wrapper--routeParts--single--title--data"]}>{`${route.street ? "ul." + route.street : ""} ${route.no ? route.no : ""}${route.flat ? "/" + route.flat : ""}, ${route.city ? route.city : ""}`}</span>
                                </p>
                                <p className={styles["zlecenie__wrapper--routeParts--single--title"]}>{index + 1}.
                                    czas: <span
                                        className={styles["zlecenie__wrapper--routeParts--single--title--data"]}>{timeAtPlace}</span>
                                </p>

                            </div>
                        )
                    }) : <p>Brak danych</p>
                    }
                </div>
                <hr style={{height: .1, borderColor : '#cfcfcf',width: '85%' }}/>
                </div>
                ))}
                {accept ?
                    <>
                    {courseDetails?.map((courseDetail, i) => (
                        <div className={styles["zlecenie__wrapper--panel"]}>
                            <p className={styles["zlecenie__wrapper--panel--description"]}>Dane pracowników:</p>
                            {courseDetail ? courseDetail.worker.map((worker, index) => {
                                return (
                                    <div key={index} className={styles["zlecenie__wrapper--panel--single"]}>
                                        <p className={styles["zlecenie__wrapper--panel--single--title"]}>{index + 1}.
                                            Imię i nazwisko: <span
                                                className={styles["zlecenie__wrapper--panel--single--title--data"]}>{worker.name} {worker.surname}</span>
                                        </p>
                                        <p className={styles["zlecenie__wrapper--panel--single--title"]}>{index + 1}.
                                            Telefon: <span
                                                className={styles["zlecenie__wrapper--panel--single--title--data"]}>{worker.number}</span>
                                        </p>
                                    </div>
                                )
                            }) : <p>Brak danych</p>
                            }
                        </div>
                        ))}
                        <div className={styles["zlecenie__wrapper--panel--buttonWrapper"]}>
                            < Button
                                className={classes.goBackButton}
                                margin="normal"
                                onClick={() => history.push('/app/operator/trasy/aktywne')}
                            >
                                Powrót do Aplikacji
                            </Button>
                        </div>
                    </>
                    :
                    <div className={styles["zlecenie__wrapper--panel"]}>
                        {/*<div className={styles["zlecenie__wrapper--panel--counter"]}>*/}
                        {/*    <p className={styles["zlecenie__wrapper--panel--counter--title"]}>Czas na odpowiedź:</p>*/}
                        {/*    <div className={styles["zlecenie__wrapper--panel--counter--count"]}>*/}
                        {/*        <Countdown date={countTime}*/}
                        {/*                   renderer={renderer}*/}
                        {/*                   daysInHours={true}>*/}
                        {/*        </Countdown></div>*/}
                        {/*</div>*/}
                        <div className={styles["zlecenie__wrapper--panel--buttonWrapper"]}>
                            < Button
                                className={classes.refuseButton}
                                margin="normal"
                                onClick={() => sendData(0)}
                            >
                                Odrzuć
                            </Button>
                            <Button
                                className={classes.acceptButton}
                                margin="normal"
                                onClick={() => {
                                    sendData(1);
                                }}
                            >
                                Akceptuj
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
