import {
  createMuiTheme,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  FormHelperText,
} from "@material-ui/core";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AddKontrahentForm from "../../reusableComponents/kilometrowkaPanel/formLine/formCompanyInfo/addKontrahent/AddKontrahentForm";
import AddWorkerModal from "../../reusableComponents/orderPanel/orderLine/orderWorker/AddWorkerModal/AddWorkerModal";
import config from "../../../config";
import Cookie from "js-cookie";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
});

export const GridInput = ({
  index,
  idAndName,
  label,
  gridCellSize,
  type,
  handleChange,
  addContractor,
  addWorker,
  setFieldValue,
  defaultValue,
  selectOptions,
  workers,
  contractors,
  statementsLength,
  error,
  displayError,
  setFormWasTouched,
}) => {
  const filter = createFilterOptions();
  const [value, setValue] = useState(defaultValue);
  const [openWorker, setOpenWorker] = useState(false);
  const [openContractor, setOpenContractor] = useState(false);

  const handleToggleModal = (type) => {
    if (type === "worker") {
      return setOpenWorker(!openWorker);
    }
    if (type === "contractor") {
      return setOpenContractor(!openContractor);
    }
  };

  const handleAddContractor = (contractor) => {
    addContractor(contractor);
    setValue(contractor);
  };

  const handleAddWorker = (worker) => {
    addWorker(worker);
    setValue(worker);
  };

  useEffect(() => {
    if(!type.includes('date')){
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (idAndName === "worker") {
      setFieldValue("position", value?.position ?? "");
      setFieldValue("worker", value);
      for (let i = 0; i < statementsLength; i++) {
        setFieldValue(`statements.${i}.regNo`, value?.regNo ?? "");
        setFieldValue(`statements.${i}.capacity`, value?.engineCapacity ?? "");
        setFieldValue(
          `statements.${i}.pricePerKm`,
          value?.delegationRate ?? ""
        );
      }
    }
    if (idAndName === `statements.${index}.client`) {
      setFieldValue(`statements.${index}.client`, value);
    }
  }, [value]);

  if (type.includes("datetime"))
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <DateTimePicker
            fullWidth
            name={idAndName}
            label={label}
            value={value}
            onChange={setValue}
            InputLabelProps={{ style: { fontSize: "0.8rem" } }}
            onBlur={() => {
              handleChange(idAndName, value);
              setFormWasTouched(true);
            }}
            format={"d.LLLL yyyy, HH:mm"}
            ampm={false}
            helperText={displayError && error}
            error={displayError && error}
          />
        </ThemeProvider>
      </Grid>
    );

  if (idAndName === "worker")
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Autocomplete
          options={workers}
          fullWidth
          getOptionLabel={(worker) =>
            worker.name ?? `${worker.user.name} ${worker.user.surName}`
          }
          getOptionSelected={(option, value) => option.id === value.id}
          value={value}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const user = JSON.parse(Cookie.get("loggedUser"));
            if(!config.disableAddingWorker.includes(user.companyName)) {
              filtered.unshift({
                name: "+ DODAJ PRACOWNIKA",
              });
            }
            return filtered;
          }}
          onChange={(e, newValue) => {
            if (newValue?.name === "+ DODAJ PRACOWNIKA") {
              handleToggleModal("worker");
            } else {
              setValue(newValue);
              setFieldValue(`worker`, newValue);
            }
              setFormWasTouched(true);
          }}
          renderInput={(params) => (
            <TextField
              id={idAndName}
              type={type}
              {...params}
              InputLabelProps={{ style: { fontSize: "0.8rem" } }}
              label={label}
              helperText={displayError && error}
              error={displayError && error}
            />
          )}
        />
        <AddWorkerModal
          addDriverFromForm={handleAddWorker}
          closeDriverModal={() => handleToggleModal("worker")}
          open={openWorker}
        />
      </Grid>
    );

  if (idAndName === `statements.${index}.client`)
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Autocomplete
          options={contractors}
          getOptionLabel={(contractor) => contractor.name}
          fullWidth
          value={value}
          onChange={(e, newValue) => {
            setFormWasTouched(true);
            if (newValue?.name === "+ DODAJ FIRMĘ") {
              handleToggleModal("contractor");
            } else {
              setValue(newValue);
              setFieldValue(`statements.${index}.client`, newValue);
            }
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const user = JSON.parse(Cookie.get("loggedUser"));
            if(!config.disableAddingContractor.includes(user.companyName)) {
              filtered.unshift({
                inputValue: params.inputValue,
                name: `+ DODAJ FIRMĘ`,
              });
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              id={idAndName}
              type={type}
              {...params}
              label={label}
              InputLabelProps={{ style: { fontSize: "0.8rem" } }}
              error={displayError && error}
              helperText={displayError && error}
            />
          )}
        />
        {openContractor && (
          <AddKontrahentForm
            handleToggleModal={() => handleToggleModal("contractor")}
            addKontrahent={handleAddContractor}
          />
        )}
      </Grid>
    );

  if (type === "date")
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <DatePicker
            fullWidth
            name={idAndName}
            label={label}
            value={value}
            InputLabelProps={{ style: { fontSize: "0.8rem" } }}
            onChange={(val) => {
              setFormWasTouched(true);
              setValue(val);
            }}
            helperText={displayError && error}
            error={displayError && error}
            onBlur={() => {
              handleChange(idAndName, value);
            }}
            format={"d.LLLL yyyy"}
            ampm={false}
          />
        </ThemeProvider>  
      </Grid>
    );

  if (type === "select") {
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <FormControl fullWidth error={displayError && error}>
          <InputLabel style={{ fontSize: "0.8rem" }} id={idAndName + "label"}>
            {label}
          </InputLabel>
          <Select
            id={idAndName}
            name={idAndName}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setFormWasTouched(true);
              handleChange(idAndName, e.target.value);
            }}
          >
            {selectOptions.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{displayError && error}</FormHelperText>
        </FormControl>
      </Grid>
    );
  }

  if (type === "number") {
    return (
      <Grid
        item
        xs={gridCellSize}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <TextField
          fullWidth
          id={idAndName}
          type="number"
          onWheel={(e)=>{e.target.blur()}}
          label={label}
          value={value}
          error={displayError && error}
          helperText={displayError && error}
          InputProps={{
            inputProps: {
            },
          }}
          InputLabelProps={{ style: { fontSize: "0.8rem" } }}
          onChange={(e) => {
            setValue(e.target.value);
            setFormWasTouched(true);
          }}
          onBlur={handleChange}
        />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={gridCellSize}
      style={{ display: "flex", alignItems: "flex-end" }}
    >
      <TextField
        fullWidth
        id={idAndName}
        type={type}
        helperText={displayError && error}
        error={displayError && error}
        label={label}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        value={value}
        onChange={(e) => {
          let value = e.target.value;
          if(idAndName.includes('regNo')) value = value.toUpperCase();
          setValue(value);
          setFormWasTouched(true);
        }}
        onBlur={handleChange}
      />
    </Grid>
  );
};

GridInput.propTypes = {
  idAndName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  statementsLength: PropTypes.number,
  gridCellSize: PropTypes.number,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  addWorker: PropTypes.func,
  addContractor: PropTypes.func,
  selectOptions: PropTypes.arrayOf(PropTypes.string),
  workers: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  displayError: PropTypes.bool,
  step: PropTypes.number,
  minDate: PropTypes.any,
  setFormWasTouched: PropTypes.func.isRequired,
};

GridInput.defaultProps = {
  gridCellSize: 3,
  type: "text",
  defaultValue: null,
  selectOptions: null,
  error: "",
  displayError: false,
  step: 1,
  minDate: null,
};
