import React, {useContext, useEffect, useState} from "react";
import styles from "./rozliczeniaDetails.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import { format, fromUnixTime } from "date-fns";
import { pl } from "date-fns/locale";
import CourseMap from "./courseMap/CourseMap";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Conversation from "./conversation/Conversation";
import SetRoute from "./setRoute/SetRoute";
import {MimicContext} from "../../../../context";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 0,
        },
    },
    dispatchWrapper: {
        width: "310px",
        margin: "10px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    operatorWrapper: {
        width: "310px",
        margin: "10px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before":
            {
                backgroundColor: "#b4b4b4",
            },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        position: "fixed",
        top: "6vh",
        width: "85vw",
        height: "88vh",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: 15,
    },
    changeDriverButton: {
        color: "#1DBBED",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#1DBBED",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "10px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#00a0e3",
        },
    },
    confirmButton: {
        color: "#66D01D",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#66D01D",
        borderRadius: "0",
        transition: "0.3s",
        margin: "10px 5px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#5AB71B",
        },
    },
    answearButton: {
        color: "#faba1f",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#faba1f",
        borderRadius: "0",
        transition: "0.3s",
        margin: "10px 5px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#E19205",
        },
    },

    operatorButton: {
        color: "#1DBBED",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#1DBBED",
        borderRadius: "0",
        transition: "0.3s",
        margin: "10px 5px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#00A0E3",
        },
    },
    conversationButton: {
        color: "#faba1f",
        fontWeight: "bold",
        textTransform: "none",
        borderColor: "#faba1f",
        borderRadius: "0",
        transition: "0.3s",
        marginBottom: "10px",
        width: "150px",
        "&:hover": {
            color: "white",
            backgroundColor: "#E19205",
        },
    },
    timeRoot: {
        padding: 0,
        margin: 0,
        "& .MuiTimelineItem-root": {
            minHeight: "40px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },

    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: "#1DBBED",
        padding: "3px",
    },
    timeConnector: {
        backgroundColor: "#b4b4b4",
    },
    timeContent: {
        padding: "0 15px",
        margin: "-7px 0 10px",
    },
}));
export default function RozliczeniaDetails(props) {
    const classes = useStyles();
    const { isRoleType } = useContext(MimicContext)
    const { courseDetails, courseHistory, entity, status } = props;
    const [showHistory, setShowHistory] = useState(false);
    const [showConversation, setShowConversation] = useState(false);
    const [open, setOpen] = useState(true);
    const [totalTime, setTotalTime] = useState(null);
    const [totalKm, setTotalKm] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const [customRouteWaypoints, setCustomRouteWaypoints] = useState([]);
    const [courseMapRefreshFlag, setCourseMapRefreshFlag] = useState(false);

    let createDate = courseDetails
        ? format(
              fromUnixTime(
                  courseDetails.createDate < courseDetails.destinationTime
                      ? courseDetails.createDate
                      : courseDetails.destinationTime - 10800
              ),
              "d LLLL yyyy, HH:mm",
              {
                  locale: pl,
              }
          )
        : "";

    const handleClose = () => {
        props.switchShowModal(false);
    };

    useEffect(() => {}, [showHistory, showConversation]);

    const handleCounters = (km, time) => {
        const distance = `${Math.round(km / 1000)} km`;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

        if (courseDetails.isDestinationTime) {
            setStartTime(
                format(fromUnixTime(courseDetails.destinationTime - time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
            setEndTime(format(fromUnixTime(courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
        } else {
            setStartTime(format(fromUnixTime(courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
            setEndTime(
                format(fromUnixTime(courseDetails.destinationTime + time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
        }
        setTotalKm(distance);
        setTotalTime(duration);
    };

    const editedData =
        courseHistory.length > 1
            ? courseHistory.map((item, index) => {
                  const lastIndex = courseHistory.length - 1;

                  //sprawdzenie, które dane się zmieniły:
                  let orderNoEdited = "";
                  let notesEdited = "";
                  let fromEdited = "";
                  let toEdited = "";
                  let workerEdited = "";
                  let timeEdited = "";
                  let canceledOrder = "";
                  let courseEdited = false;

                  if (courseHistory.length > 1 && index < lastIndex) {
                      canceledOrder = item.cancel || courseHistory[index + 1].cancel ? "--edited" : "";
                      orderNoEdited =
                          (item.orderNo && item.orderNo !== courseHistory[index + 1].orderNo) ||
                          (!item.orderNo && courseHistory[index + 1].orderNo)
                              ? "--edited"
                              : "";
                      notesEdited =
                          (item.notes && item.notes !== courseHistory[index + 1].notes) ||
                          (!item.notes && courseHistory[index + 1].notes)
                              ? "--edited"
                              : "";
                      fromEdited = item.from?.id !== courseHistory[index + 1].from?.id ? "--edited" : "";
                      toEdited = item.to?.id !== courseHistory[index + 1].to?.id ? "--edited" : "";
                      timeEdited =
                          (item.destinationTime && item.destinationTime !== courseHistory[index + 1].destinationTime) ||
                          (item.direction && item.direction !== courseHistory[index + 1].direction) ||
                          (item.isWorkAddress && item.isWorkAddress !== courseHistory[index + 1].isWorkAddress) ||
                          (item.isDestinationTime &&
                              item.isDestinationTime !== courseHistory[index + 1].isDestinationTime)
                              ? "--edited"
                              : "";

                      if (item.worker.length === courseHistory[index + 1].worker.length) {
                          let workerId = "";
                          let addressId = "";
                          for (let i = 0; i < item.worker.length; i++) {
                              workerId = item.worker[i].id;
                              addressId = item.worker[i].addressId;
                              for (let j = 0; j < courseHistory[index + 1].worker.length; j++) {
                                  if (
                                      workerId !== courseHistory[index + 1].worker[j].id ||
                                      addressId !== courseHistory[index + 1].worker[j].addressId
                                  ) {
                                      workerEdited = "--edited";
                                  }
                              }
                          }
                      } else {
                          workerEdited = "--edited";
                      }
                  }
                  if (
                      orderNoEdited ||
                      notesEdited ||
                      fromEdited ||
                      toEdited ||
                      workerEdited ||
                      timeEdited ||
                      canceledOrder
                  ) {
                      courseEdited = true;
                      if (!isEdited) {
                          setIsEdited(true);
                      }
                  }

                  //ustawienie czasów
                  let changeDate;
                  if (index === courseHistory.length - 1) {
                      changeDate = createDate;
                  } else if (index === 0) {
                      changeDate = format(
                          fromUnixTime(courseHistory[index + 1].changeHistory?.changeDate),
                          "d LLLL yyyy, HH:mm",
                          {
                              locale: pl,
                          }
                      );
                  } else {
                      changeDate = format(
                          courseHistory[index].changeHistory?.changeDate
                              ? fromUnixTime(courseHistory[index + 1].changeHistory?.changeDate)
                              : null,
                          "d LLLL yyyy, HH:mm",
                          {
                              locale: pl,
                          }
                      );
                  }
                  return (
                      <Timeline key={index} align="alternate" className={classes.timeRoot}>
                          {canceledOrder ? (
                              <TimelineItem>
                                  <TimelineSeparator>
                                      <TimelineDot className={classes.timeDot} />
                                      <TimelineConnector className={classes.timeConnector} />
                                  </TimelineSeparator>
                                  <TimelineContent className={classes.timeContent}>
                                      <div className={styles["moreDetails__inputs--historyItem"]}>
                                          <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data--history"
                                                      ]
                                                  }
                                              >
                                                  {`${changeDate}`}
                                              </p>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                      ]
                                                  }
                                              >
                                                  Zamówienie ANULOWANE
                                              </p>
                                              <p
                                                  className={
                                                      styles[
                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                      ]
                                                  }
                                              >
                                                  {`Uwagi: ${item.notes ? item.notes : "Nie podano"}`}
                                              </p>
                                          </div>
                                      </div>
                                  </TimelineContent>
                              </TimelineItem>
                          ) : courseEdited || (isEdited && index === lastIndex) ? (
                              <TimelineItem>
                                  <TimelineSeparator>
                                      <TimelineDot className={classes.timeDot} />
                                      <TimelineConnector className={classes.timeConnector} />
                                  </TimelineSeparator>
                                  <TimelineContent className={classes.timeContent}>
                                      <div className={styles["moreDetails__inputs--historyItem"]}>
                                          <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                              {index === lastIndex && !canceledOrder ? (
                                                  <p
                                                      className={
                                                          styles[
                                                              "moreDetails__inputs--historyItem--wrapper--history--details--title--history"
                                                          ]
                                                      }
                                                  >
                                                      Pierwsza wersja
                                                  </p>
                                              ) : null}
                                              <div
                                                  className={
                                                      styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                  }
                                              >
                                                  <div
                                                      className={
                                                          styles[
                                                              "moreDetails__inputs--historyItem--wrapper--history--details"
                                                          ]
                                                      }
                                                  >
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                              ]
                                                          }
                                                      >
                                                          {index !== lastIndex ? (
                                                              <p
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data--history"
                                                                      ]
                                                                  }
                                                              >
                                                                  {index === 0
                                                                      ? `${changeDate} (${
                                                                            item.author.firstName
                                                                                ? item.author.firstName + " "
                                                                                : ""
                                                                        }
                                                    ${item.author.lastName ? item.author.lastName : ""})`
                                                                      : `${changeDate} (${
                                                                            item.changeAuthor.name
                                                                                ? item.changeAuthor.name + " "
                                                                                : ""
                                                                        }
                                                    ${item.changeAuthor.surname ? item.changeAuthor.surname : ""})`}
                                                              </p>
                                                          ) : null}

                                                          {orderNoEdited || index === lastIndex ? (
                                                              <>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`Nr zlecenia: `}
                                                                  </p>
                                                                  <span
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  orderNoEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {item.orderNo ? item.orderNo : "-"}
                                                                  </span>
                                                              </>
                                                          ) : null}
                                                          {notesEdited || index === lastIndex ? (
                                                              <>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`Uwagi: `}
                                                                  </p>
                                                                  <span
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  notesEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {item.notes ? item.notes : "-"}
                                                                  </span>
                                                              </>
                                                          ) : null}
                                                      </div>
                                                  </div>
                                              </div>

                                              {/*Adres odbioru historia*/}
                                              {fromEdited ||
                                              (workerEdited && item.direction === 1) ||
                                              index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Adres odbioru
                                                      </p>
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details"
                                                              ]
                                                          }
                                                      >
                                                          {item.from || (item.from && index === lastIndex) ? (
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                      ]
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      Adres:
                                                                  </p>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  fromEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`${item.from.street ? item.from.street : ""} ${
                                                                          item.from.no ? item.from.no : ""
                                                                      } ${item.from.flat ? `/${item.from.flat}` : ""} ${
                                                                          item.from.zipCode ? item.from.zipCode : ""
                                                                      } ${item.from.city ? item.from.city : "-"}`}
                                                                  </p>
                                                              </div>
                                                          ) : (
                                                              item.worker.map((item, index) => {
                                                                  return (
                                                                      <div
                                                                          key={index}
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                              ]
                                                                          }
                                                                      >
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Imię i nazwisko
                                                                          </p>
                                                                          <span
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${
                                                                                  item.firstname ? item.firstname : " "
                                                                              } ${item.surname ? item.surname : " "}`}
                                                                          </span>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Adres
                                                                          </p>
                                                                          <span
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${item.street ? item.street : ""} ${
                                                                                  item.no ? item.no : " "
                                                                              } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                  item.zipCode ? item.zipCode : ""
                                                                              } ${item.city ? item.city : ""}`}
                                                                          </span>
                                                                      </div>
                                                                  );
                                                              })
                                                          )}
                                                      </div>
                                                  </div>
                                              ) : null}

                                              {/*Adres docelowy*/}
                                              {toEdited ||
                                              (workerEdited && item.direction === 0) ||
                                              index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Adres docelowy
                                                      </p>
                                                      <div
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details"
                                                              ]
                                                          }
                                                      >
                                                          {item.to || (item.to && index === lastIndex) ? (
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                      ]
                                                                  }
                                                              >
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                          ]
                                                                      }
                                                                  >
                                                                      Adres
                                                                  </p>
                                                                  <p
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data" +
                                                                                  toEdited
                                                                          ]
                                                                      }
                                                                  >
                                                                      {`${item.to.street ? item.to.street : ""} ${
                                                                          item.to.no ? item.to.no : ""
                                                                      } ${item.to.flat ? `/${item.to.flat}` : ""} ${
                                                                          item.to.zipCode ? item.to.zipCode : ""
                                                                      } ${item.to.city ? item.to.city : ""}`}
                                                                  </p>
                                                              </div>
                                                          ) : (
                                                              item.worker.map((item, index) => {
                                                                  return (
                                                                      <div
                                                                          key={index}
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                              ]
                                                                          }
                                                                      >
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Imię i nazwisko
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${
                                                                                  item.firstname ? item.firstname : ""
                                                                              } ${item.surname ? item.surname : ""}`}
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              Adres
                                                                          </p>
                                                                          <p
                                                                              className={
                                                                                  styles[
                                                                                      "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                  ]
                                                                              }
                                                                          >
                                                                              {`${item.street ? item.street : ""} ${
                                                                                  item.no ? item.no : ""
                                                                              } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                  item.zipCode ? item.zipCode : ""
                                                                              } ${item.city ? item.city : ""}`}
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              })
                                                          )}
                                                      </div>
                                                  </div>
                                              ) : null}

                                              {/*Czasy*/}
                                              {timeEdited || index === lastIndex ? (
                                                  <div
                                                      className={
                                                          styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                      }
                                                  >
                                                      <p
                                                          className={
                                                              styles[
                                                                  "moreDetails__inputs--historyItem--wrapper--history--details--title"
                                                              ]
                                                          }
                                                      >
                                                          Czasy
                                                      </p>
                                                      <div
                                                          className={
                                                              styles["moreDetails__inputs--historyItem--wrapper--time"]
                                                          }
                                                      >
                                                          <div
                                                              className={
                                                                  styles[
                                                                      "moreDetails__inputs--historyItem--wrapper--time--single"
                                                                  ]
                                                              }
                                                          >
                                                              {item.from ||
                                                              item.isWorkAddress ||
                                                              index === lastIndex ? (
                                                                  <div
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--time--from--toWork" +
                                                                                  timeEdited
                                                                          ]
                                                                      }
                                                                  ></div>
                                                              ) : (
                                                                  <div
                                                                      className={
                                                                          styles[
                                                                              "moreDetails__inputs--historyItem--wrapper--time--from--toHome" +
                                                                                  timeEdited
                                                                          ]
                                                                      }
                                                                  ></div>
                                                              )}

                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--data" +
                                                                              timeEdited
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.isDestinationTime
                                                                      ? "---"
                                                                      : format(
                                                                            fromUnixTime(item.destinationTime),
                                                                            "d LLLL yyyy, HH:mm",
                                                                            { locale: pl }
                                                                        )}
                                                              </div>
                                                          </div>
                                                          <div
                                                              className={
                                                                  styles[
                                                                      "moreDetails__inputs--historyItem--wrapper--time--single"
                                                                  ]
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--info"
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.to ? (
                                                                      <div
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--time--to--toWork" +
                                                                                      timeEdited
                                                                              ]
                                                                          }
                                                                      ></div>
                                                                  ) : (
                                                                      <div
                                                                          className={
                                                                              styles[
                                                                                  "moreDetails__inputs--historyItem--wrapper--time--to--toHome" +
                                                                                      timeEdited
                                                                              ]
                                                                          }
                                                                      ></div>
                                                                  )}
                                                              </div>
                                                              <div
                                                                  className={
                                                                      styles[
                                                                          "moreDetails__inputs--historyItem--wrapper--time--single--data" +
                                                                              timeEdited
                                                                      ]
                                                                  }
                                                              >
                                                                  {item.isDestinationTime
                                                                      ? format(
                                                                            fromUnixTime(item.destinationTime),
                                                                            "d LLLL yyyy, HH:mm",
                                                                            { locale: pl }
                                                                        )
                                                                      : "---"}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null}
                                          </div>
                                      </div>
                                  </TimelineContent>
                              </TimelineItem>
                          ) : null}
                      </Timeline>
                  );
              })
            : null;

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={styles["moreDetails__description"]}>
                            <h2 className={styles["moreDetails__description--title"]}>Szczegóły kursu</h2>
                            <span className={styles["moreDetails__description--close"]} onClick={handleClose}>
                                X
                            </span>
                        </div>

                        <div className={styles["moreDetails"]}>
                            <div className={styles["moreDetails__map"]}>
                                <CourseMap
                                    key={String(courseMapRefreshFlag)}
                                    courseDetails={courseDetails}
                                    handleCounters={handleCounters}
                                    entity={props.entity}
                                    customRouteWaypoints={customRouteWaypoints}
                                    isDispatcher={isRoleType('DYSPOZYTOR')}
                                />
                                {props.type === 1 && (
                                    <SetRoute
                                        onSelectRoute={(routes) => {
                                            setCustomRouteWaypoints(routes);
                                            setCourseMapRefreshFlag(!courseMapRefreshFlag);
                                        }}
                                    />
                                )}
                            </div>

                            {/*Aktualna wersja zlecenia*/}
                            <SimpleBar style={{ maxHeight: "80vh", minWidth: 520 }} className={classes.simplebar}>
                                <div className={styles["moreDetails__inputs"]}>
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--courseDetails"]}>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Nr kursu:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetails.number ? courseDetails.number : ""}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Ostatnia modyfikacja:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseHistory.length > 1
                                                        ? format(
                                                              fromUnixTime(courseHistory[1].changeHistory.changeDate),
                                                              "d LLLL yyyy, HH:mm",
                                                              {
                                                                  locale: pl,
                                                              }
                                                          )
                                                        : createDate}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Nr zlecenia:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetails.orderNo ? courseDetails.orderNo : "-"}
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Uwagi:
                                                </p>
                                                <p className={styles["moreDetails__inputs--item--courseDetails--data"]}>
                                                    {courseDetails.notes ? courseDetails.notes : "-"}
                                                </p>

                                                {entity === "operator" ? (
                                                    <>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Kontrahent:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.kontrahent
                                                                ? courseDetails.kontrahent.name
                                                                : ""}
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles[
                                                            "moreDetails__inputs--item--courseDetails--singleColumn--buttonWrapper"
                                                        ]
                                                    }
                                                >
                                                    <Button
                                                        size="small"
                                                        className={classes.changeDriverButton}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            showHistory ? setShowHistory(false) : setShowHistory(true);
                                                            setShowConversation(false);
                                                        }}
                                                    >
                                                        {showHistory ? "Ukryj historię" : "Zobacz historię"}
                                                    </Button>
                                                    {status !== 4 ? (
                                                        <Button
                                                            size="small"
                                                            className={classes.conversationButton}
                                                            variant="outlined"
                                                            onClick={() => {
                                                                showConversation
                                                                    ? setShowConversation(false)
                                                                    : setShowConversation(true);
                                                                setShowHistory(false);
                                                            }}
                                                        >
                                                            {showConversation ? "Ukryj uwagi" : "Uwagi"}
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <div className={styles["moreDetails__inputs--item--courseDetails"]}>
                                            <div
                                                className={
                                                    styles["moreDetails__inputs--item--courseDetails--singleColumn"]
                                                }
                                            >
                                                <p className={styles["moreDetails__inputs--item--courseDetails--info"]}>
                                                    Kierowca:
                                                </p>
                                                {(courseDetails.cancel === false &&
                                                    courseDetails.driver &&
                                                    courseDetails.status === "frozen") ||
                                                (entity === "operator" &&
                                                    courseDetails.cancel === false &&
                                                    courseDetails.driver &&
                                                    courseDetails.status === "pending") ||
                                                (entity === "operator" &&
                                                    courseDetails.cancel === false &&
                                                    courseDetails.status === "deny") ? (
                                                    <>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            {entity === "operator"
                                                                ? `${courseDetails.driver.firstname} ${courseDetails.driver.surname}`
                                                                : courseDetails.driver.firstname}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Telefon:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.driver.workPhoneNumber
                                                                ? courseDetails.driver.workPhoneNumber
                                                                : "-"}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--info"]
                                                            }
                                                        >
                                                            Tablice rejestracyjne:
                                                        </p>
                                                        <p
                                                            className={
                                                                styles["moreDetails__inputs--item--courseDetails--data"]
                                                            }
                                                        >
                                                            {courseDetails.driver.regNo
                                                                ? courseDetails.driver.regNo
                                                                : "-"}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles["moreDetails__inputs--item--courseDetails--data"]
                                                        }
                                                    >
                                                        Nie przypisano kierowcy
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Adres odbioru */}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Adres odbioru</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper"]}>
                                            {courseDetails.from ? (
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--singleAddress"]
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Ulica
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${
                                                            courseDetails.from.street ? courseDetails.from.street : "-"
                                                        } ${courseDetails.from.no ? courseDetails.from.no : ""} ${
                                                            courseDetails.from.flat ? `/${courseDetails.from.flat}` : ""
                                                        }`}
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Miasto
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${
                                                            courseDetails.from.zipCode ? courseDetails.from.zipCode : ""
                                                        } ${courseDetails.from.city ? courseDetails.from.city : "-"}`}
                                                    </p>
                                                </div>
                                            ) : (
                                                courseDetails.worker.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--singleAddress"
                                                                ]
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Imię i nazwisko
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.firstname ? item.firstname : ""} ${
                                                                    item.surname ? item.surname : "-"
                                                                }`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Telefon kontaktowy
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {item.phoneNumber ? item.phoneNumber : "-"}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Ulica
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.street ? item.street : "-"} ${
                                                                    item.no ? item.no : ""
                                                                } ${item.flat ? `/${item.flat}` : ""}`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Miasto
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.zipCode ? item.zipCode : ""} ${
                                                                    item.city ? item.city : "-"
                                                                }`}
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>

                                    {/* Adres docelowy */}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Adres docelowy</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper"]}>
                                            {courseDetails.to ? (
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--singleAddress"]
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Ulica
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${courseDetails.to.street ? courseDetails.to.street : "-"} ${
                                                            courseDetails.to.no ? courseDetails.to.no : ""
                                                        } ${courseDetails.to.flat ? `/${courseDetails.to.flat}` : ""}`}
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                            ]
                                                        }
                                                    >
                                                        Miasto
                                                    </p>
                                                    <p
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                            ]
                                                        }
                                                    >
                                                        {`${courseDetails.to.zipCode ? courseDetails.to.zipCode : ""} ${
                                                            courseDetails.to.city ? courseDetails.to.city : "-"
                                                        }`}
                                                    </p>
                                                </div>
                                            ) : (
                                                courseDetails.worker.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--singleAddress"
                                                                ]
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Imię i nazwisko
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.firstname ? item.firstname : ""} ${
                                                                    item.surname ? item.surname : ""
                                                                }`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Telefon kontaktowy
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {item.phoneNumber ? item.phoneNumber : ""}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Ulica
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.street ? item.street : "-"} ${
                                                                    item.no ? item.no : ""
                                                                } ${item.flat ? `/${item.flat}` : ""}`}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--info"
                                                                    ]
                                                                }
                                                            >
                                                                Miasto
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--item--wrapper--singleAddress--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${item.zipCode ? item.zipCode : ""} ${
                                                                    item.city ? item.city : "-"
                                                                }`}
                                                            </p>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>

                                    {/*Czasy*/}
                                    <div className={styles["moreDetails__inputs--item"]}>
                                        <p className={styles["moreDetails__inputs--item--title"]}>Czasy</p>
                                        <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    {courseDetails.from || courseDetails.isWorkAddress ? (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--from--toWork"
                                                                ]
                                                            }
                                                        ></div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--from--toHome"
                                                                ]
                                                            }
                                                        ></div>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {startTime}
                                                </div>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    {courseDetails.to ? (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--to--toWork"
                                                                ]
                                                            }
                                                        ></div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--item--wrapper--time--to--toHome"
                                                                ]
                                                            }
                                                        ></div>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {endTime}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles["moreDetails__inputs--item--wrapper--time"]}>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--totalTime"
                                                            ]
                                                        }
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {totalTime}
                                                </div>
                                            </div>
                                            <div className={styles["moreDetails__inputs--item--wrapper--time--single"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--info"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--item--wrapper--time--single--totalKm"
                                                            ]
                                                        }
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--item--wrapper--time--single--data"]
                                                    }
                                                >
                                                    {totalKm}
                                                </div>
                                            </div>
                                        </div>
                                        {props.type === 1 && (
                                            <div className={styles["moreDetails__times"]}>
                                                <div className={styles["moreDetails__times--row"]}>
                                                    <div className={styles["moreDetails__times--col--title--location"]}>
                                                        Lokalizacja
                                                    </div>
                                                    <div className={styles["moreDetails__times--col--title--time"]}>
                                                        Czas zapl.
                                                    </div>
                                                    <div className={styles["moreDetails__times--col--title--time"]}>
                                                        Czas real.
                                                    </div>
                                                </div>
                                                {props.courseDetails.path?.map((path, index) => {
                                                    return (
                                                        <div className={styles["moreDetails__times--row"]} key={index}>
                                                            <div
                                                                className={styles["moreDetails__times--col--location"]}
                                                            >
                                                                {`${path.adres.street ? path.adres.street : ""} ${
                                                                    path.adres.no ? path.adres.no : ""
                                                                }${path.adres.flat ? `/${path.adres.flat}` : ""} ${
                                                                    path.adres.zipCode ? path.adres.zipCode : ""
                                                                } ${path.adres.city ? path.adres.city : ""}`}
                                                            </div>
                                                            <div className={styles["moreDetails__times--col--time"]}>
                                                                {path.time
                                                                    ? format(fromUnixTime(path.time), "HH:mm", {
                                                                          locale: pl,
                                                                      })
                                                                    : "-"}
                                                            </div>
                                                            <div className={styles["moreDetails__times--col--time"]}>
                                                                {path.arrivedAt
                                                                    ? format(fromUnixTime(path.arrivedAt), "HH:mm", {
                                                                          locale: pl,
                                                                      })
                                                                    : "-"}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SimpleBar>

                            {/*Historia zlecenia*/}

                            {showHistory ? (
                                <div className={styles["moreDetails__inputs"]}>
                                    <SimpleBar
                                        style={{ maxHeight: "80vh", minWidth: 320 }}
                                        className={classes.simplebar}
                                    >
                                        {editedData}
                                        {courseHistory.length > 1 && isEdited ? (
                                            <Timeline align="alternate" className={classes.timeRoot}>
                                                <TimelineItem>
                                                    <TimelineDot className={classes.timeDot} />
                                                    <TimelineContent className={classes.timeContent}>
                                                        <div
                                                            className={
                                                                styles["moreDetails__inputs--historyItem--wrapper"]
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history"
                                                                    ]
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles[
                                                                            "moreDetails__inputs--historyItem--wrapper--history--details"
                                                                        ]
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles[
                                                                                "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                            ]
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`Utworzony: `}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {createDate}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles[
                                                                                "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                            ]
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`Dyspozytor: `}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                styles[
                                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                                ]
                                                                            }
                                                                        >
                                                                            {`${
                                                                                courseDetails.author.firstName
                                                                                    ? courseDetails.author.firstName
                                                                                    : ""
                                                                            } ${
                                                                                courseDetails.author.lastName
                                                                                    ? courseDetails.author.lastName
                                                                                    : "-"
                                                                            }`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        ) : (
                                            <div className={styles["moreDetails__inputs--historyItem--wrapper"]}>
                                                <div
                                                    className={
                                                        styles["moreDetails__inputs--historyItem--wrapper--history"]
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "moreDetails__inputs--historyItem--wrapper--history--details"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                ]
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                    ]
                                                                }
                                                            >
                                                                {`Utworzony: `}
                                                            </span>
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                    ]
                                                                }
                                                            >
                                                                {createDate}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles[
                                                                    "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn"
                                                                ]
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--info"
                                                                    ]
                                                                }
                                                            >
                                                                {`Dyspozytor: `}
                                                            </span>
                                                            <span
                                                                className={
                                                                    styles[
                                                                        "moreDetails__inputs--historyItem--wrapper--history--details--singleColumn--data"
                                                                    ]
                                                                }
                                                            >
                                                                {`${
                                                                    courseDetails.author.firstName
                                                                        ? courseDetails.author.firstName
                                                                        : ""
                                                                } ${
                                                                    courseDetails.author.lastName
                                                                        ? courseDetails.author.lastName
                                                                        : "-"
                                                                }`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </SimpleBar>
                                </div>
                            ) : null}
                            {showConversation && (
                                <Conversation
                                    refreshTable={props.refreshTable}
                                    settlementId={props.settlementId}
                                    classes={classes}
                                />
                            )}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
