import React from "react";
import Orders from "../../../reusableComponents/orders/Orders";

const columns = [
    {
        id: "status",
        label: "",
        query: false,
        maxWidth: 20,
    },
    {
        id: "toStart",
        label: "",
        sorted: false,
        query: false,
        order: null,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "number",
        label: "ID",
        query: true,
        sorted: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "edited",
        label: "!",
        minWidth: 50,
        maxWidth: 85,
    },
    {
        id: "suggestedRelatedCourses",
        label: "Sugerowane",
        minWidth: 100,
    },
    // { id: "combinedCourses", label: "Połączone kursy", minWidth: 100 },
    {
        id: "relatedOrders",
        label: "Połączone zlecenia",
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "time",
        label: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        minWidth: 100,
        maxWidth: 150,
    },
    {
        id: "taxiDriver",
        label: "Kierowca",
        sorted: false,
        order: null,
        query: true,
        minWidth: 120,
        maxWidth: 160,
    },
    {
        id: "taxiRealDriver",
        label: "Kierowca referencyjny",
        minWidth: 190,
        maxWidth: 190,
    },
    {
        id: "push",
        label: "Push",
        sorted: false,
        order: null,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "worker",
        label: "Pracownik",
        sorted: false,
        order: null,
        query: true,
        minWidth: 170,
        maxWidth: 170,
    },
    {
        id: "pickup",
        label: "Adres odbioru",
        sorted: false,
        order: null,
        query: true,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "destination",
        label: "Adres docelowy",
        sorted: false,
        order: null,
        query: true,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        query: true,
        sorted: false,
        minWidth: 120,
        maxWidth: 160,
    },
    {
        id: "consortiumMember",
        label: "Partner",
        query: true,
        sorted: false,
        minWidth: 120,
        maxWidth: 160,
    },
    {
        id: "notes",
        label: "Uwagi",
        query: true,
        sorted: false,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "details",
        label: "",
        query: false,
        minWidth: 100,
        maxWidth: 100,
    },
];

export default function Active() {
    return <Orders entity="operator" status="active" columns={columns} />;
}
