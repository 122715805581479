import React, { Fragment } from "react";
import AddAddressRoute from "./dodaj/AddAddressRoute";
import AddressListRoute from "./lista/AddressListRoute";

const AdresyRoute = () => {
    return (
        <Fragment>
            <AddAddressRoute />
            <AddressListRoute />
        </Fragment>
    );
};

export default AdresyRoute;
