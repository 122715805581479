import React from 'react';
import EditPerson from "../../../reusableComponents/editPerson/EditPerson";

const EditWorker = (props) => {
    return <EditPerson
        url="/api/pracownik"
        redirectURL={props.app === 'operator' ? "/app/operator/pracownik/pokaz" : "/app/dyspozytor/pracownik/pokaz"}
        label="Edytuj profil pracownika"
        entity="Pracownik"
        {...props}
    />;
}

export default EditWorker;
