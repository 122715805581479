import io from 'socket.io-client';

export default class SocketConnection {
    socket;

    constructor(ip) {
        this.socket = io(ip, {
            reconnection: false,
        });
        this.socket.connect();
        this.socket.on("connect", () => {
            this.socket.emit("login", {username: "raily_react_app", token: "bba0d8e869280cb040f016aaaaf76adb"});
        })
    }

    on(event, cb) {
        this.socket.on(event, cb);
    }

    disconnect() {
        this.socket.disconnect();
    }
}
