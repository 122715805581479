const localStorageKey = `ORDER_COUNTDOWN`;

const getSavedCountdownDates = () => {
    const data = localStorage.getItem(localStorageKey);

    if (!data) {
        return [];
    }

    const parsedData = JSON.parse(data);

    const list = parsedData.map((item) => ({ itemId: item.itemId, date: new Date(item.date) }));

    return list;
};

const saveCountdownDates = (list) => {
    localStorage.setItem(localStorageKey, JSON.stringify(list));
};

export const ordersCountdownDao = {
    getSavedCountdownDates,
    saveCountdownDates,
};
