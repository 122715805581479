import React, {useEffect, useState} from "react";
import styles from "./mapPopup.module.scss";
import {format, getUnixTime, fromUnixTime} from "date-fns";
import SingleDriverSchedule from "../../../../reusableComponents/singleDriverSchedule/SingleDriverSchedule";
import {pl} from "date-fns/locale";
import HttpClient from "../../../../../HttpClient";
import config from "../../../../../config";

export default function MapPopup(props) {
    const {drivers, course, ol_id, route, index} = props;
    const [legend, setLegend] = useState('');
    const [totalTime, setTotalTime] = useState(null);
    const [totalKm, setTotalKm] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [singleDriver, setSingleDriver] = useState(null);
    const currentTime = new Date();
    const unixCurrentTime = getUnixTime(currentTime);

    useEffect(() => {
        if (course) {
            getRoutes();
        }
    }, [drivers, course, route, index]);

    useEffect(() => {
        if (ol_id && (ol_id.includes("workerPoint") || ol_id.includes("startPoint") || ol_id.includes("endPoint"))) {
            setLegend("Szczegóły kursu")
        } else {
            setLegend("Szczegóły kierowcy");
        }
        showDriverData();
    }, [ol_id]);

    const showDriverData = () => {
        let driver = '';
        for (let i = 0; i < drivers.length; i++) {
            if (drivers[i].regNo === ol_id) {
                driver = drivers[i];
            }
        }
        setSingleDriver(driver);
    }

    const handleCounters = (km, time) => {
        const distance = `${Math.round(km / 1000)} km`;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

        if (course.isDestinationTime) {
            setStartTime(format(fromUnixTime(course.startTime), "d LLLL yyyy, HH:mm", {locale: pl}))
            setEndTime(format(fromUnixTime(course.endTime), "d LLLL yyyy, HH:mm", {locale: pl}));
        } else {
            setStartTime(format(fromUnixTime(course.startTime), "d LLLL yyyy, HH:mm", {locale: pl}))
            setEndTime(format(fromUnixTime(course.endTime), "d LLLL yyyy, HH:mm", {locale: pl}));
        }
        setTotalKm(distance);
        setTotalTime(duration);
    }

    const getRoutes = async () => {
        let startCoords;
        let coordsPack = "";
        if (course.from) {
            startCoords = [course.from.lon, course.from.lat];
        } else if (course.worker) {
            for (let j = 0; j < course.worker.length; j++) {
                let prevCoords = coordsPack;
                let coords = `;${course.worker[j].lon},${course.worker[j].lat}`;
                coordsPack = `${prevCoords}${coords}`;
            }
        }

        if (course.to) {
            startCoords = [course.to.lon, course.to.lat];
        } else if (course.worker) {
            for (let j = 0; j < course.worker.length; j++) {
                let prevCoords = coordsPack;
                let coords = `;${course.worker[j].lon},${course.worker[j].lat}`;
                coordsPack = `${prevCoords}${coords}`;
            }
        }

        if (startCoords && coordsPack) {
            const httpRequest = HttpClient.createRequest();
            const url = `${config.osrmURL}/route/v1/driving/${startCoords[0]},${startCoords[1]}${coordsPack}?steps=true&geometries=geojson&overview=full`;
            try {
                const response = await httpRequest.get(url);
                handleCounters(response.data.routes[0].distance, response.data.routes[0].duration)
            } catch (e) {
            }
        }
    }

    return (
        <div className={styles["mapPopup"]}>
            <div className={styles["mapPopup"]}>
                <div className={styles["mapPopup__description"]}>
                    <h2 className={styles["mapPopup__description--title"]}>
                        {legend}
                    </h2>
                </div>

                {/*Szczegóły kursu*/}

                {course && (ol_id && (ol_id.includes("workerPoint") || ol_id.includes("startPoint") || ol_id.includes("endPoint"))) ?
                    <div className={styles["mapPopup__inputs"]}>
                        <div className={styles["mapPopup__inputs--item"]}>
                            <div className={styles["mapPopup__inputs--item--wrapper"]}>
                                <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                        Start kursu:
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                        {startTime}
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                        Koniec kursu:
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                        {endTime}
                                    </p>

                                </div>
                                <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                        Czas:
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                        {totalTime}
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                        Dystans
                                    </p>
                                    <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                        {totalKm}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Adres odbioru */}
                        <div className={styles["mapPopup__inputs--item"]}>
                            <p className={styles["mapPopup__inputs--item--title"]}>Adres odbioru</p>
                            <div className={styles["mapPopup__inputs--item--wrapper"]}>
                                {course.from ? (
                                    <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Ulica
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${
                                                course.from.street
                                                    ? course.from.street
                                                    : "-"
                                            } ${
                                                course.from.no
                                                    ? course.from.no
                                                    : "-"
                                            } ${
                                                course.from.flat
                                                    ? `/${course.from.flat}`
                                                    : "-"
                                            }`}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Miasto
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${
                                                course.from.zipCode
                                                    ? course.from.zipCode
                                                    : "-"
                                            } ${
                                                course.from.city
                                                    ? course.from.city
                                                    : "-"
                                            }`}
                                        </p>
                                    </div>
                                ) : (
                                    course.worker.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Imię i nazwisko
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.firstname ? item.firstname : "-"} ${
                                                        item.surname ? item.surname : "-"
                                                    }`}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Telefon kontaktowy
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {item.phoneNumber ? item.phoneNumber : "-"}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Ulica
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.street ? item.street : "-"} ${
                                                        item.no ? item.no : "-"
                                                    } ${item.flat ? `/${item.flat}` : "-"}`}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Miasto
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.zipCode ? item.zipCode : "-"} ${
                                                        item.city ? item.city : "-"
                                                    }`}
                                                </p>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                        {/* Adres docelowy */}
                        <div className={styles["mapPopup__inputs--item"]}>
                            <p className={styles["mapPopup__inputs--item--title"]}>Adres docelowy</p>
                            <div className={styles["mapPopup__inputs--item--wrapper"]}>
                                {course.to ? (
                                    <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Ulica
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${
                                                course.to.street
                                                    ? course.to.street
                                                    : "-"
                                            } ${
                                                course.to.no
                                                    ? course.to.no
                                                    : "-"
                                            } ${
                                                course.to.flat
                                                    ? `/${course.to.flat}`
                                                    : "-"
                                            }`}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Miasto
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${
                                                course.to.zipCode
                                                    ? course.to.zipCode
                                                    : "-"
                                            } ${
                                                course.to.city
                                                    ? course.to.city
                                                    : "-"
                                            }`}
                                        </p>
                                    </div>
                                ) : (
                                    course.worker.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}
                                            >
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Imię i nazwisko
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.firstname ? item.firstname : "-"} ${
                                                        item.surname ? item.surname : "-"
                                                    }`}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Telefon kontaktowy
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {item.phoneNumber ? item.phoneNumber : "-"}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Ulica
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.street ? item.street : "-"} ${
                                                        item.no ? item.no : "-"
                                                    } ${item.flat ? `/${item.flat}` : "-"}`}
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                                    Miasto
                                                </p>
                                                <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                                    {`${item.zipCode ? item.zipCode : "-"} ${
                                                        item.city ? item.city : "-"
                                                    }`}
                                                </p>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                    :
                    singleDriver ? //dane kierowcy
                        <div className={styles["mapPopup__inputs"]} key={singleDriver.id}>
                            <div className={styles["mapPopup__inputs--item"]}>
                                <div className={styles["mapPopup__inputs--item--wrapper"]}>
                                    <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Imię:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {singleDriver.name ? singleDriver.name : "-"}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Nazwisko:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {singleDriver.surname ? singleDriver.surname : "-"}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Telefon:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {singleDriver.phoneNumber ? singleDriver.phoneNumber : "-"}
                                        </p>
                                    </div>
                                    <div className={styles["mapPopup__inputs--item--wrapper--singleColumn"]}>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Ulica:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${singleDriver.address[0].street ? singleDriver.address[0].street : "-"} ${
                                                singleDriver.address[0].no ? singleDriver.address[0].no : ""
                                            } ${singleDriver.address[0].flat ? `/${singleDriver.address[0].flat}` : ""}`}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Miasto:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {`${singleDriver.address[0].zipCode ? singleDriver.address[0].zipCode : ""} ${
                                                singleDriver.address[0].city ? singleDriver.address[0].city : "-"
                                            }`}
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--info"]}>
                                            Tablice rejestracyjne:
                                        </p>
                                        <p className={styles["mapPopup__inputs--item--wrapper--singleColumn--data"]}>
                                            {singleDriver.regNo ? singleDriver.regNo : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles["mapPopup__inputs--schedule"]}>
                                <p className={styles["mapPopup__inputs--schedule--title"]}>Grafik</p>
                                <div className={styles["mapPopup__inputs--schedule--wrapper"]}>
                                    <SingleDriverSchedule driver={singleDriver?.id} pickupTime={unixCurrentTime}
                                    />
                                </div>
                            </div>
                        </div> : <div>Problem z pobraniem danych</div>}
            </div>
        </div>
    )
}
