import styles from "./formNotes.module.scss";
import React from "react";
import {TextField} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const SingleInput = styled(TextField)({
    width: '100%',
    fontFamily: "'Open Sans', sans-serif",
    "& label.Mui-focused": {
        color: "#1DBBED",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1DBBED",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1DBBED",
        },
        "&:hover fieldset": {
            borderColor: "#1DBBED",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1DBBED",
        },
    },
});

export default class FormNote extends React.Component {

    shouldComponentUpdate(nextProps) {
        return this.props.note.value !== nextProps.note.value;
    }

    render() {
        return (
            <div className={styles["formNotes"]}>
                <div className={styles["formNotes__input"]}>
                    <SingleInput
                        label="Uwagi"
                        fullWidth
                        value={this.props.note.value}
                        variant="standard"
                        onChange={(e) => {
                            this.props.setOrderData(
                                this.props.groupIndex,
                                this.props.index,
                                "note",
                                e.target.value
                            );
                        }}
                    />
                </div>
            </div>
        );
    }
}
