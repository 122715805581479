import React, {Fragment} from "react";
import AppRoute from "../../../AppRoute";
import ContractorCard from "./ContractorCard";

export default function AdministrationContractorCardRoute() {
    return (
        <Fragment>
            <AppRoute
                app="administracja"
                window="dokumenty"
                tab="kartakontrahenta/:id?"
                component={ContractorCard}
            />
        </Fragment>
    );
};
