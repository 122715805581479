const entityType = {
    DYSPOZYTOR: 'dyspozytor',
    OPERATOR: 'operator'
}

const routeType = {
    TRANSPORT: 'transport',
    TRASY: 'trasy',
}

const statusType = {
    IDLE: "idle",
    ERROR: "error",
    SUCCESS: "success",
    SENDING: 'SENDING',
    LOADING: "loading",
    LOADED: "loaded",
};
//po 
const addressType = {
    WORK: "STATION",
    HOME: "HOME",
};

const filterOptionTypes = {
    WORKER: "worker",
    ADDRESS: "address",
};

const orderType = {
    ORDER: "order",
    ORDER_RETURN: "orderReturn",
    ORDER_SHIFT_RETURN: "orderShiftReturn",
};

const orderViewType = {
    EDIT: 'edit'
}

// default relation  (!isChangedDirection = DOM - PRACA))

const baseWorker = {
    timeOrigin: null,
    isWorkAddress: false,
    person: null,
    homeAddress: null,
    workAddress: null,
}

const baseOrder = {
    type: orderType.ORDER,
    orderName: "Zamówienie",
    orderNo: "",
    notes: "",
    driver: null,
    relatedOrderName: null,
    isChangedDirection: false,
    hasReturnOrderShift: false,
    hasReturnOrder: false,
    destination: null,
    distance: 0,
    duration: 0,
    timeDestination: null,
    workers: [
        {...baseWorker},
    ],
    removedWorkers: [],
    newWorkers: [],
    editedWorkers: []
};

const markerColors = {
    start: [102, 208, 29, 0.8],
    middle: [237, 29, 29, 1],
    settlements: [255,26,50, 1],
    finish:  [29, 187, 237, 1],
    driverColor: [29, 187, 237, 0.8]
}


export {
    orderType,
    filterOptionTypes,
    markerColors,
    statusType,
    addressType,
    baseOrder,
    orderViewType,
    entityType,
    baseWorker,
    routeType
};
