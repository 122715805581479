import settlementsPanelSettlementHelper from "./settlementsPanelSettlement.helper";
import HttpClient from "../../../../HttpClient";
import config from "../../../../config";
import { getAddressesCords } from "../../../../app/reusableComponents/newOrderPanel/helpers";
import cloneDeep from "lodash/cloneDeep";

const createPlannedRoute = (responsePlannedRoute) => {
    return {
        address: responsePlannedRoute.address,
        plannedDate: new Date(responsePlannedRoute.time * 1000),
        orderedDate: responsePlannedRoute.commissionedTime
            ? new Date(responsePlannedRoute.commissionedTime * 1000)
            : undefined,
        distance: Math.ceil(responsePlannedRoute.distance),
    };
};

const createPlannedRoutes = (responsePlannedRoutes) => {
    return responsePlannedRoutes.sort((a, b) => a.time - b.time).map(createPlannedRoute);
};

const mapPlannedDateToDate = ({ plannedDate, ...rest }) => ({
    ...rest,
    date: plannedDate,
});

const mapDateToPlannedDate = ({ date, ...rest }) => ({
    ...rest,
    plannedDate: date,
});

const createCompletedRoute = (responseCompletedRoute) => {
    return {
        address: responseCompletedRoute.address,
        date: new Date(responseCompletedRoute.time * 1000),
        distance: Math.ceil(responseCompletedRoute.distance),
        highwayCost: 0,
    };
};

const createCompletedRoutes = (responseCompletedRoutes) => {
    return responseCompletedRoutes.sort((a, b) => a.time - b.time).map(createCompletedRoute);
};

const createClientDataRoutesSettlements = (orderClientSettlementStages) => {
    return orderClientSettlementStages.map((clientSettlementStage) => {
        return {
            address: clientSettlementStage.address,
            date: new Date(clientSettlementStage.time * 1000),
            stopDuration: clientSettlementStage.stopTime,
            distance: Math.ceil(clientSettlementStage.distance),
        };
    });
};

const createClientData = (orderContractor, settlementDetails, order, orderIndex) => {
    const plannedRoutes = createPlannedRoutes(settlementDetails.plannedRoute);
    const routesSettlements = createClientDataRoutesSettlements(order.clientStagesSettlement);

    if (settlementDetails.clientSettlement) {
        const currentClientSettlement = settlementDetails.clientSettlement[orderIndex];

        return {
            status: currentClientSettlement.status,
            billingId: currentClientSettlement.id,
            companyName: orderContractor.name,
            billingModel: currentClientSettlement.billingModel ?? orderContractor.contract.billingModel,
            rate: currentClientSettlement.normalRate,
            stopCost: orderContractor.contract.waitingRate,
            discountPercent: currentClientSettlement.dicount ?? 0,
            maxDiscountPercent: orderContractor.contract.maxDiscount,
            highwayWithWorker: orderContractor.contract.highwayWithWorker,
            highwayWithoutWorker: orderContractor.contract.highwayWithoutWorker,
            notes: orderContractor.contract.notes,
            highwayCost: currentClientSettlement.highwayCost ?? 0,
            plannedRoutes,
            routesSettlements,
            waypointsData: null,
            settlementStatus: currentClientSettlement.settlementStatus
        };
    }

    return {
        billingId: undefined,
        companyName: orderContractor.name,
        billingModel: orderContractor.contract.billingModel,
        rate: orderContractor.contract.normalRate,
        stopCost: orderContractor.contract.waitingRate,
        discountPercent: 0,
        maxDiscountPercent: orderContractor.contract.maxDiscount,
        highwayWithWorker: orderContractor.contract.highwayWithWorker,
        highwayWithoutWorker: orderContractor.contract.highwayWithoutWorker,
        notes: orderContractor.contract.notes,
        highwayCost: settlementDetails.highwayCost ?? 0,
        plannedRoutes,
        routesSettlements,
        waypointsData: null,
        settlementStatus: 0
    };
};

const createDriverDataRoutesSettlements = (orderDriverSettlementStages) => {
    return orderDriverSettlementStages.map((driverSettlementStage) => {
        return {
            address: driverSettlementStage.address,
            date: new Date(driverSettlementStage.time * 1000),
            stopDuration: driverSettlementStage.stopTime,
            distance: Math.ceil(driverSettlementStage.distance),
        };
    });
};

const createDriverData = (orderDriver, settlementDetails, order, orderIndex) => {
    const routesSettlements = createDriverDataRoutesSettlements(order.driverStagesSettlement);
    const completedRoutes = createCompletedRoutes(settlementDetails.completedRoute);

    if (settlementDetails.driverBilling) {
        const currentDriverBilling = settlementDetails.driverBilling[orderIndex];
        return {
            billingId: currentDriverBilling.id,
            driver: currentDriverBilling
                ? {
                      id: currentDriverBilling.id,
                      firstName: currentDriverBilling.driverName,
                      lastName: currentDriverBilling.driverSurname,
                      username: currentDriverBilling.driverUsername,
                      billingGroup: currentDriverBilling?.billingGroupName ?? undefined,
                  }
                : undefined,
            billingModel: currentDriverBilling?.billingModel ?? undefined,
            rate: currentDriverBilling?.normalRate ?? undefined,
            stopCost: currentDriverBilling?.waitingRate ?? undefined,
            startAddress: currentDriverBilling?.addresses ?? undefined,
            notes: orderDriver?.notes ?? undefined,
            highwayCost: currentDriverBilling.highwayCost ?? 0,
            completedRoutes,
            routesSettlements,
        };
    }

    return {
        billingId: undefined,
        driver: orderDriver
            ? {
                  id: orderDriver.id,
                  firstName: orderDriver.firstname,
                  lastName: orderDriver.surname,
                  username: orderDriver.username,
                  billingGroup: orderDriver?.billingGroup ?? undefined,
              }
            : undefined,
        billingModel: orderDriver?.billingModel ?? undefined,
        rate: orderDriver?.normalRate ?? undefined,
        stopCost: orderDriver?.waitingRate ?? undefined,
        startAddress: orderDriver?.addresses ?? undefined,
        notes: orderDriver?.notes ?? undefined,
        highwayCost: settlementDetails.highwayCost ?? 0,
        completedRoutes,
        routesSettlements,
    };
};

const createSettlementData = (order, settlementDetails, orderIndex) => {
    const formattedSettlementDetails = sortClientSettlement(settlementDetails);
    const clientData = createClientData(order.contractor, formattedSettlementDetails, order, orderIndex);
    const driverData = createDriverData(order.driver, formattedSettlementDetails, order, orderIndex);

    return {
        orderId: order.id,
        orderNumber: order.number,
        partner: order.partner,
        selectedRoutesVersion: null, //"PLANNED" | "COMPLETED" | null
        date: new Date(order.courseDetails.destinationTime * 1000),
        clientData,
        driverData,
        workers: order.workers,
        connectedOrders: order.courseDetails.connectedOrders
    };
};

const sortClientSettlement = (res) => {
    if (res.clientSettlement) {
        const clientSettlementsCopy = cloneDeep(res.clientSettlement);
        const sortedClientSettlement = clientSettlementsCopy.reduce((acc, settlement) => {
            const ordersElemPosition = res.orders.findIndex((elem) => elem.id === settlement.billedOrder);

            if (ordersElemPosition !== -1) {
                acc.splice(ordersElemPosition, 0, settlement);
            }

            return acc;
        }, []);
        return { ...res, clientSettlement: sortedClientSettlement, driverBilling: res.driverBilling.sort((a, b) => a.number - b.number)};
    }
    return res;
};

const createSettlementsData = (settlementDetailsResponseData) => {
    return settlementDetailsResponseData.orders
        .sort((a, b) => a.number - b.number)
        .map((responseDataOrder, orderIndex) => {
            return createSettlementData(responseDataOrder, settlementDetailsResponseData, orderIndex);
        });
};

const createRequestClientSettlement = (settlementData, order, billingId) => {
    const totalDistance = settlementData.clientData.routesSettlements.reduce(
        (prev, current) => prev + current.distance,
        0
    );
    const totalHighwayCost = settlementData.clientData.highwayCost;

    const totalStopDuration = settlementData.clientData.routesSettlements.reduce(
        (prev, current) => prev + current.stopDuration,
        0
    );

    const totalStopCost = Math.ceil(totalStopDuration / 60) * settlementData.clientData.stopCost;

    const newDiscount = settlementData.clientData.discountPercent
        ? settlementData.clientData.rate * (1 - settlementData.clientData.discountPercent / 100)
        : settlementData.clientData.rate;
        
    const rateAfterDiscount = Math.round((newDiscount + Number.EPSILON) * 100) / 100;
    const totalDistanceCostBeforeDiscount = settlementData.clientData.rate * totalDistance;
    const totalDistanceCostAfterDiscount = rateAfterDiscount * totalDistance;

    const totalCost = totalDistanceCostAfterDiscount + totalHighwayCost + totalStopCost;

    const saving = totalDistanceCostBeforeDiscount - totalDistanceCostAfterDiscount;

    return {
        billingModelName: settlementData.clientData.billingModel.name,
        id: settlementData.clientData.billingId,
        connectedOrders: order.courseDetails.connectedOrders.join(),
        worker: order.courseDetails.worker.map((worker) => `${worker.firstname} ${worker.surname}`).join(", "),
        route: settlementsPanelSettlementHelper.getRouteNameString(settlementData.clientData.routesSettlements),
        contractor: order.contractor.name,
        billedOrder: order.id,
        orderNo: order.courseDetails.orderNo,
        sumKm: totalDistance,
        normalRate: settlementData.clientData.rate,
        dicount: settlementData.clientData.discountPercent, //TYPO
        rateAfterDiscount: Number(rateAfterDiscount.toFixed(2)),
        sumKmCost: Number(totalDistanceCostAfterDiscount.toFixed(2)),
        countStop: Math.ceil(totalStopDuration / 60), //CHECK ilość w godzinach np 2 lub 3
        stopCost: totalStopCost,
        highwayCost: totalHighwayCost,
        sumCost: totalCost,
        saveMoney: Number(saving.toFixed(2)),
        comments: settlementData.notes ?? null,
        settlementStatus: settlementData.clientData.settlementStatus
    };
};

const createEmptyRequestClientSettlement = (order, billingId, billingModelName) => {
    return {
        billingModelName: billingModelName,
        id: billingId,
        connectedOrders: order.courseDetails.connectedOrders.join(),
        worker: order.courseDetails.worker.map((worker) => `${worker.firstname} ${worker.surname}`).join(", "),
        route: "",
        contractor: "",
        billedOrder: order.id,
        orderNo: order.courseDetails.orderNo,
        sumKm: 0,
        normalRate: 0,
        dicount: 0, //TYPO
        rateAfterDiscount: 0,
        sumKmCost: 0,
        countStop: 0, //CHECK ilość w godzinach np 2 lub 3
        stopCost: 0,
        highwayCost: 0,
        sumCost: 0,
        saveMoney: 0,
        comments: null,
    };
};

const createRequestDriverBilling = (settlementData, order) => {
    const totalDistance = settlementData.driverData.routesSettlements.reduce(
        (prev, current) => prev + current.distance,
        0
    );
    const totalHighwayCost = settlementData.driverData.highwayCost;

    const totalStopDuration = settlementData.driverData.routesSettlements.reduce(
        (prev, current) => prev + current.stopDuration,
        0
    );

    const totalStopCost = Math.ceil(totalStopDuration / 60) * settlementData.driverData.stopCost;

    const totalDistanceCost = settlementData.driverData.rate * totalDistance ?? 0;

    const totalCost = totalDistanceCost + totalHighwayCost + totalStopCost;

    const routeString = settlementsPanelSettlementHelper.getRouteNameString(
        settlementData.driverData.routesSettlements
    );

    return {
        id: settlementData.driverData.billingId,
        orderNo: order.courseDetails.orderNo,
        route: routeString,
        status: 1,
        connectedOrders: order.courseDetails.connectedOrders.join(),
        worker: order.courseDetails.worker.map((worker) => `${worker.firstname} ${worker.surname}`).join(", "),
        driverName: settlementData.driverData.driver.firstName,
        driverSurname: settlementData.driverData.driver.lastName,
        driverUsername: settlementData.driverData.driver.username,
        contractor: order.contractor.name,
        billingGroupName:
            settlementData.driverData.driver.billingGroup?.name ?? settlementData.driverData.driver.billingGroup,
        sumKm: totalDistance,
        normalRate: settlementData.driverData.rate,
        waitingRate: settlementData.driverData.stopCost,
        waitingCost: totalStopCost,
        highwayCost: totalHighwayCost,
        sumCost: totalCost,
        accountDate: new Date(order.courseDetails.destinationTime * 1000).toJSON(),
        costKm: totalDistanceCost,
        waitingTime: totalStopDuration,
        number: order.number,
        sumKmConsortium: 0,
        billingModelName: settlementData.driverData.billingModel.name,
    };
};

const createEmptyRequestDriverBilling = (order, billingId, settlementData) => {
    return {
        id: billingId,
        orderNo: order.courseDetails.orderNo,
        route: "",
        status: 1,
        connectedOrders: order.courseDetails.connectedOrders.join(),
        worker: order.courseDetails.worker.map((worker) => `${worker.firstname} ${worker.surname}`).join(", "),
        driverName: settlementData.driverData.driver.firstName,
        driverSurname: settlementData.driverData.driver.lastName,
        driverUsername: settlementData.driverData.driver.username,
        contractor: order.contractor.name,
        billingGroupName: "",
        sumKm: 0,
        normalRate: 0,
        waitingRate: 0,
        waitingCost: 0,
        highwayCost: 0,
        sumCost: 0,
        accountDate: new Date(order.courseDetails.destinationTime * 1000).toJSON(),
        costKm: 0,
        waitingTime: 0,
        number: order.number,
        sumKmConsortium: 0,
        billingModelName: "",
    };
};

const createRequestClientStagesSettlements = (routesSettlements, orderId) => {
    return routesSettlements.map((routesSettlement, index) => {
        return {
            zlecenieId: orderId,
            addressId: routesSettlement.address.id,
            time: routesSettlement.date?.toJSON(),
            stopTime: routesSettlement.stopDuration, // w minutach
            distance: routesSettlement.distance,
            highwayCost: routesSettlement.highwayCost,
            stageIndex: index,
        };
    });
};

const createRequestDriverStagesSettlements = (routesSettlements, orderId) => {
    return routesSettlements.map((routesSettlement, index) => {
        return {
            zlecenieId: orderId,
            addressId: routesSettlement.address.id,
            time: routesSettlement.date?.toJSON(),
            stopTime: routesSettlement.stopDuration, // w minutach
            distance: routesSettlement.distance,
            highwayCost: routesSettlement.highwayCost,
            stageIndex: index,
        };
    });
};

const createSaveRequest = (settlementsData, orders, areConnectedOrdersJoin, clientSettlementId, driverBillingId) => {
    return {
        data: settlementsData.map((settlementDataItem, index) => {
            if (areConnectedOrdersJoin && index > 0) {
                const order = orders.find((order) => order.id === settlementDataItem.orderId);
                const firstOrder = settlementsData[0];

                const clientSettlement = createEmptyRequestClientSettlement(
                    order,
                    settlementDataItem.clientData.billingId,
                    settlementDataItem.clientData.billingModel?.name ?? firstOrder.clientData.billingModel.name
                );
                const driverBilling = createEmptyRequestDriverBilling(
                    order,
                    settlementDataItem.driverData.billingId,
                    settlementDataItem
                );
                const clientStagesSettlements = [];
                const driverStagesSettlements = [];

                return {
                    clientSettlement,
                    driverBilling,
                    clientStagesSettlement: clientStagesSettlements,
                    driverStagesSettlement: driverStagesSettlements,
                    connectedSettlement: areConnectedOrdersJoin,
                };
            }

            const order = orders.find((order) => order.id === settlementDataItem.orderId);
            const clientSettlement = createRequestClientSettlement(settlementDataItem, order, clientSettlementId);
            const driverBilling = createRequestDriverBilling(settlementDataItem, order, driverBillingId);
            const clientStagesSettlements = createRequestClientStagesSettlements(
                settlementDataItem.clientData.routesSettlements,
                order.id
            );
            const driverStagesSettlements = createRequestDriverStagesSettlements(
                settlementDataItem.driverData.routesSettlements,
                order.id
            );

            return {
                clientSettlement,
                driverBilling,
                clientStagesSettlement: clientStagesSettlements,
                driverStagesSettlement: driverStagesSettlements,
                connectedSettlement: areConnectedOrdersJoin,
            };
        }),
    };
};

const createDriverRoutesSettlementStagesFromCompletedRoute = (completedRoute) => {
    return {
        address: completedRoute.address,
        date: completedRoute.date,
        stopDuration: 0,
        distance: Math.ceil(completedRoute.distance),
    };
};

const createDriverSettlementStagesFromCompletedRoutes = async (completedRoutes, driverBillingModel) => {
    const sortedCompletedRoutes = [...completedRoutes].sort((a, b) => a.time - b.time);

    if (
        driverBillingModel?.name === "ABA" &&
        sortedCompletedRoutes.length > 2 &&
        sortedCompletedRoutes[0].address.id === sortedCompletedRoutes[completedRoutes.length - 1].address.id
    ) {
        const firstItem = {
            ...sortedCompletedRoutes[1],
            distance: 0,
        };

        const lastItem = {
            ...sortedCompletedRoutes[1],
            date: null,
            distance: 0,
        };

        const newCompletedRoutes = [
            firstItem,
            ...sortedCompletedRoutes.slice(2, sortedCompletedRoutes.length - 1),
            lastItem,
        ];

        const osrmRoute = await createRouteWithOSRMData(
            cloneDeep(newCompletedRoutes.map(mapDateToPlannedDate)).slice(-3)
        );

        const newRoute = [
            ...cloneDeep(newCompletedRoutes.map(mapDateToPlannedDate)).slice(0, -2),
            ...osrmRoute.slice(-2),
        ];

        return newRoute.map(mapPlannedDateToDate).map(createDriverRoutesSettlementStagesFromCompletedRoute);
    }

    return sortedCompletedRoutes.map(createDriverRoutesSettlementStagesFromCompletedRoute);
};

const createDriverRoutesSettlementStagesFromPlannedRoute = (plannedRoute) => {
    return {
        address: plannedRoute.address,
        date: plannedRoute.plannedDate,
        stopDuration: 0,
        distance: Math.ceil(plannedRoute.distance),
    };
};

const createRouteWaypoints = async (completedRoute) => {
    const routeWaypoints = completedRoute.map((route) => route.address);
    const httpRequest = HttpClient.createRequest();

    const isAnyAddressEmpty = completedRoute.find((route) => !route.address);
    if (isAnyAddressEmpty) return { routeData: [], routeWaypoints: [] };

    if (completedRoute.length <= 1) return { routeData: [], routeWaypoints };
    const urlWithCords = getAddressesCords(completedRoute.map((i) => i.address));
    const url = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson`;

    const { data: osmrRoute } = await httpRequest.get(url);
    const allCoords = getAllCoords(osmrRoute.routes[0])
    return {
        routeData: [
            {
                geometry: allCoords,
                id: allCoords,
            },
        ],
        routeWaypoints,
    };
};

const getAllCoords = (osrmRoute) => {
    const data = {
        coordinates: [],
        type: osrmRoute.geometry.type
    }

    osrmRoute.legs.forEach(leg => {
        leg.steps.forEach(step => {
            step.geometry.coordinates.forEach(coord => {
                data.coordinates.push(coord)
            })
        })
    })

    return data;
}

const findShortestRoute = (routes) => {
    let minDistance = 999999999999999;
    let idx = 0

    Object.entries(routes['routes']).forEach((route, i) => {
        if (routes['routes'][i].distance < minDistance) {
            minDistance = routes['routes'][i].distance;
            idx = i;
            console.log(routes['routes'][i].distance)
        }
    })

    return routes['routes'][idx];
}

const createRouteWithOSRMData = async (completedRoute, isAba = false) => {
    const httpRequest = HttpClient.createRequest();

    const helper = [];

    for await (const [idx, route] of completedRoute.entries()) {
        const excludeMotorway = idx === completedRoute.length-1 && isAba ? '&exclude=motorway&alternatives=true' : ''
        const excludeToll = idx === completedRoute.length-1 && isAba ? '&exclude=toll&alternatives=true' : ''
        const noExclude = idx === completedRoute.length-1 && isAba ? '&alternatives=true' : ''
        if (idx === 0) {
            helper[0] = route;
        }

        if (idx !== 0 && completedRoute[idx - 1].address && route.address) {
            const cordRoute = [completedRoute[idx - 1], route];
            const urlWithCords = getAddressesCords(cordRoute.map((item) => item.address));

            const urlExclMotorway = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson${excludeMotorway}`;
            const urlExclToll = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson${excludeToll}`;
            const urlNoExcl = `${config.osrmURL}/route/v1/driving/${urlWithCords}?steps=true&geometries=geojson${noExclude}`;
            const { data: osrmRouteExclMotorway } = await httpRequest.get(urlExclMotorway);
            const { data: osrmRouteExclToll } = await httpRequest.get(urlExclToll);
            const { data: osrmRouteNoExcl } = await httpRequest.get(urlNoExcl);

            const toShortestCheck = {
                routes:
                    osrmRouteExclMotorway['routes'].concat(
                        osrmRouteExclToll['routes'], osrmRouteNoExcl['routes']
                    )
            }
            const shortestRoute = findShortestRoute(toShortestCheck)

            const duration = Math.ceil(shortestRoute.duration);
            const newRoute = {
                ...route,
                distance: Math.ceil(shortestRoute.distance / 1000),
                duration,
            };
            helper[idx] = newRoute;
        }
    }

    const calculatedRoute = helper.reduce((routes, currentRoute, idx) => {
        const { address, plannedDate, distance, ...rest } = currentRoute;

        if (idx === 0) return [...routes, { address, plannedDate, distance, ...rest }];

        return [
            ...routes,
            {
                ...rest,
                distance,
                address,
                plannedDate: new Date(new Date(routes[idx - 1].plannedDate).getTime() + currentRoute.duration * 1000),
            },
        ];
    }, []);

    return calculatedRoute;
};

const createDriverSettlementStagesFromPlannedRoutes = async (plannedRoutes, driverBillingModel) => {
    const sortedPlannedRoutes = [...plannedRoutes].sort((a, b) => a.time - b.time);
    const isAba = driverBillingModel?.name === 'ABA'

    if (
        isAba &&
        sortedPlannedRoutes.length > 2 &&
        sortedPlannedRoutes[0].address.id === sortedPlannedRoutes[plannedRoutes.length - 1].address.id
    ) {
        const firstItem = {
            ...sortedPlannedRoutes[1],
            distance: 0,
        };

        const lastItem = {
            ...sortedPlannedRoutes[1],
            plannedDate: null,
            distance: 0,
        };

        const newCompletedRoutes = [
            firstItem,
            ...sortedPlannedRoutes.slice(2, sortedPlannedRoutes.length - 1),
            lastItem,
        ];

        const osrmRoute = await createRouteWithOSRMData(cloneDeep(newCompletedRoutes).slice(-3), isAba);
        const newRoute = [...cloneDeep(newCompletedRoutes).slice(0, -2), ...osrmRoute.slice(-2)];

        return newRoute.map(createDriverRoutesSettlementStagesFromPlannedRoute);
    }

    return sortedPlannedRoutes.map(createDriverRoutesSettlementStagesFromPlannedRoute);
};

const createClientRoutesSettlementStagesFromPlannedRoute = (plannedRoute) => {
    return {
        address: plannedRoute.address,
        date: plannedRoute.plannedDate,
        stopDuration: 0,
        distance: Math.ceil(plannedRoute.distance),
    };
};

const createClientSettlementStagesFromPlannedRoutes = async (plannedRoutes, clientBillingModel) => {
    const sortedPlannedRoutes = [...plannedRoutes].sort((a, b) => a.time - b.time);
    const isAba = clientBillingModel?.name === 'ABA';

    if (
        isAba &&
        sortedPlannedRoutes.length > 2 &&
        sortedPlannedRoutes[0].address.id === sortedPlannedRoutes[plannedRoutes.length - 1].address.id
    ) {
        const firstItem = {
            ...sortedPlannedRoutes[1],
            distance: 0,
        };

        const lastItem = {
            ...sortedPlannedRoutes[1],
            plannedDate: null,
            distance: 0,
        };

        const newCompletedRoutes = [
            firstItem,
            ...sortedPlannedRoutes.slice(2, sortedPlannedRoutes.length - 1),
            lastItem,
        ];

        const osrmRoute = await createRouteWithOSRMData(cloneDeep(newCompletedRoutes).slice(-3), isAba);
        const newRoute = [...cloneDeep(newCompletedRoutes).slice(0, -2), ...osrmRoute.slice(-2)];

        return newRoute.map(createClientRoutesSettlementStagesFromPlannedRoute);
    }

    return sortedPlannedRoutes.map(createClientRoutesSettlementStagesFromPlannedRoute);
};

const createClientRoutesSettlementStagesFromCompletedRoute = (completedRoute) => {
    return {
        address: completedRoute.address,
        date: completedRoute.date,
        stopDuration: 0,
        distance: Math.ceil(completedRoute.distance),
    };
};

const createClientSettlementStagesFromCompletedRoutes = async (completedRoutes, clientBillingModel) => {
    const sortedCompletedRoutes = [...completedRoutes].sort((a, b) => a.time - b.time);

    if (
        clientBillingModel?.name === "ABA" &&
        sortedCompletedRoutes.length > 2 &&
        sortedCompletedRoutes[0].address.id === sortedCompletedRoutes[completedRoutes.length - 1].address.id
    ) {
        const firstItem = {
            ...sortedCompletedRoutes[1],
            distance: 0,
        };

        const lastItem = {
            ...sortedCompletedRoutes[1],
            date: null,
            distance: 0,
        };

        const newCompletedRoutes = [
            firstItem,
            ...sortedCompletedRoutes.slice(2, sortedCompletedRoutes.length - 1),
            lastItem,
        ];

        const osrmRoute = await createRouteWithOSRMData(
            cloneDeep(newCompletedRoutes.map(mapDateToPlannedDate)).slice(-3)
        );

        const newRoute = [
            ...cloneDeep(newCompletedRoutes.map(mapDateToPlannedDate)).slice(0, -2),
            ...osrmRoute.slice(-2),
        ];

        return newRoute.map(mapPlannedDateToDate).map(createClientRoutesSettlementStagesFromCompletedRoute);
    }

    return sortedCompletedRoutes.map(createClientRoutesSettlementStagesFromCompletedRoute);
};

const calculateStopTime = (routes, current, idx) => {
    if (idx === 0) {
        return [...routes, current];
    }
    const totalTime = new Date(new Date(current.date).getTime() + routes[idx - 1].stopDuration * 60 * 1000);

    return [...routes, { ...current, date: totalTime }];
};

const settlementsPanelSettlementFactory = {
    createSettlementsData,
    calculateStopTime,
    createSaveRequest,
    mapPlannedDateToDate,
    mapDateToPlannedDate,
    createRouteWithOSRMData,
    createRouteWaypoints,
    createDriverSettlementStagesFromPlannedRoutes,
    createDriverSettlementStagesFromCompletedRoutes,
    createClientSettlementStagesFromPlannedRoutes,
    createClientSettlementStagesFromCompletedRoutes,
};

export default settlementsPanelSettlementFactory;
