import React, {Fragment} from "react";
import ActiveRoute from "./aktywne/ActiveRoute";
import ArchiveRoute from "./archiwum/ArchiveRoute";
import DodajRouteNew from "./dodaj/DodajRouteNew";
import EdytujRouteNew from "./edytuj/EdytujRouteNew";
import ImportOrdersRoute from "./importOrders/ImportOrdersRoute";

const TransportRoute = () => {
    return (
        <Fragment>
            <ActiveRoute/>
            <ArchiveRoute/>
            <DodajRouteNew app="dyspozytor" window="transport" tab="dodaj" entity="dyspozytor"/>
            <EdytujRouteNew />
            <ImportOrdersRoute/>
        </Fragment>
    );
};

export default TransportRoute;
