import { format } from "date-fns";
import { pl } from "date-fns/locale";

const getFormattedAddress = (address) => {
    if (!address) {
        return "-----";
    }
    let formattedAddress = "";

    const addToAddressString = (string, withSpace = true) => {
        if (formattedAddress.length && withSpace) {
            formattedAddress = `${formattedAddress} ${string}`;
            return;
        }
        formattedAddress = `${formattedAddress}${string}`;
    };

    if (address.zipCode) {
        addToAddressString(address.zipCode);
    }

    if (address.city) {
        addToAddressString(address.city);
    }

    if (address.street) {
        addToAddressString(address.street);
    }

    if (address.no) {
        addToAddressString(address.no);
    }

    if (address.flat) {
        addToAddressString(`/${address.flat}`, false);
    }

    return formattedAddress;
};

const getFormattedTime = (date) => {
    const dateToFormat = new Date(date);
    return format(dateToFormat, "HH:mm", {
        locale: pl,
    });
};

const getFormattedDriver = (driver) => {
    if (!("firstName" in driver)) return "";
    return `${driver.firstName} ${driver.lastName}`;
};

const getCalculatedTimeWithStop = (routesSettlements) => {
    const clientDataWithStopTime =
        routesSettlements.length > 0
            ? routesSettlements.reduce((routes, current, idx) => {
                  if (idx === 0) {
                      return [...routes, { ...current, addedTime: 0 }];
                  }
                  const previousStopTimes = routes
                      .slice(0, idx)
                      .reduce((totalTime, curr) => totalTime + curr.stopDuration, 0);

                  const totalTime =
                      current.date === null
                          ? null
                          : new Date(new Date(current.date).getTime() + previousStopTimes * 60 * 1000);

                  return [...routes, { ...current, date: totalTime, addedTime: previousStopTimes * 60 * 1000 }];
              }, [])
            : [];

    return clientDataWithStopTime;
};

const settlementsPanelSettlementHelper = {
    getFormattedAddress,
    getFormattedTime,
    getFormattedDriver,
    getCalculatedTimeWithStop,
};

export default settlementsPanelSettlementHelper;
