import React, { useState } from "react";

import { ErrorMessage, getIn, useFormikContext } from "formik";
import { TextField } from "@material-ui/core";

import { addressType, filterOptionTypes, customFilterOptions } from "app/reusableComponents/newOrderPanel/helpers";
import { StyledAutocomplete } from "app/reusableComponents/orderPanel/orderLine/OrderLine";
import { DatePicker } from "./DatePicker";
import { ListBox, StyledPopper } from "app/reusableComponents/newOrderPanel/virtualization/ListBox";
import { FlexRow, ToggleAddress, WarningText, WorkersWrapper } from "app/reusableComponents/newOrderPanel/styled";
import AddAddressInOrder from "app/reusableComponents/addAddressInOrder/AddAddressInOrder";
import { useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";

export const Address = ({
    fieldName,
    label,
    name,
    options = [],
    type,
    value,
    timePickerName = "null",
    toggleAddressName,
    coreName,
    disabledAddresses = [],
    onAddressTypeChange,
    orderDetails,
    userDispatch
}) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const { addNewWorkAddress } = useOrderContext();
    const [showModal, setShowModal] = useState(false);
    const isWorkAddress = getIn(values, toggleAddressName);
    const isError = getIn(errors, fieldName);
    const currentWorker = getIn(values, coreName);

    const handleChangeAddress = () => {
        onAddressTypeChange()
        setFieldValue(`${toggleAddressName}`, !isWorkAddress);
        setFieldValue(`${toggleAddressName}`, !isWorkAddress);
    };

    const setAddress = (newAddress) => {
        if (type === addressType.WORK) {
            addNewWorkAddress(newAddress.data);
        } else {
            setFieldValue(
                `${coreName}.person.adres`,
                type !== addressType.WORK && [...currentWorker.person.adres, newAddress]
            );
        }
        setFieldValue(fieldName, type === addressType.WORK ? newAddress.data : newAddress);
    };

    const canChangeDate = () => {
        return !!(orderOverTime() && orderDetails.driver && userDispatch());
    }

    const orderOverTime = () => {
        return (Date.parse(orderDetails.timeDestination) < new Date())
    }

    return (
        <>
            <WorkersWrapper>
                <StyledAutocomplete
                    options={options}
                    id="virtualize-demo"
                    onChange={(_, newValue) => {
                        if (newValue?.add) {
                            setShowModal(true);
                            return;
                        }
                        setFieldValue(fieldName, newValue);
                    }}
                    disableListWrap
                    PopperComponent={StyledPopper}
                    ListboxComponent={ListBox}
                    getOptionSelected={(address, value) => address?.id === value?.id}
                    getOptionLabel={(address) => (`${address?.city}${address.street ? ', ul. ' : ''} ${address?.street}`)}
                    getOptionDisabled={(option) =>  option.add
                        ? false
                        : disabledAddresses.some((address) => option?.id === address?.id)}
                    value={value}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                        <FlexRow direction="column" align="flexStart" gap="0">
                            <TextField
                                {...params}
                                name={fieldName}
                                label={label}
                                error={!!isError}
                                variant="standard"
                                margin="none"
                                fullWidth
                            />
                            <ErrorMessage name={fieldName} render={(message) => <WarningText>{message}</WarningText>} />
                            {toggleAddressName && (
                                <ToggleAddress onClick={handleChangeAddress} type="button">
                                    Zmień adres na {isWorkAddress ? "Dom" : "Praca"}
                                </ToggleAddress>
                            )}
                        </FlexRow>
                    )}
                />
                {showModal ? (
                    <AddAddressInOrder
                        type={type}
                        index={0}
                        groupIndex={1}
                        for={type === addressType.WORK ? "work" : "worker"}
                        field={type === addressType.WORK ? "destination" : "worker"}
                        addToPropertyInOrder={(a) => setAddress(a)}
                        url={type === addressType.WORK ? "/api/addresses" : "/api/pracownik/address"}
                        pracownik={type === addressType.HOME && { driverId: currentWorker.person?.id }}
                        showAddAddressForm={() => setShowModal(false)}
                    />
                ) : null}
                <DatePicker name={timePickerName} disabled={type === addressType.HOME || canChangeDate()} baseName={name} userDispatch={userDispatch} isDriver={orderDetails.driver} />
            </WorkersWrapper>
        </>
    );
};
