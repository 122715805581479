import React, { Fragment } from "react";
import PokazRoute from "./pokaz/PokazRoute";
import EdytujRoute from "./edytuj/EdytujRoute";
import DokumentyOkresoweRoute from "./dokumentyOkresowe/DokumentyOkresoweRoute";
import DodajPracownikaRoute from "./dodajPracownika/DodajPracownikaRoute";

const TransportRoute = ({app}) => {
  return (
    <Fragment>
      <DodajPracownikaRoute app={app} />
      <PokazRoute app={app} />
      <EdytujRoute app={app} />
      <DokumentyOkresoweRoute app={app} />
    </Fragment>
  );
};

export default TransportRoute;
