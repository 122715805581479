import React, { useState, useEffect, useContext } from "react";
import { Backdrop, Fade, Modal, Grid, Tab, Tabs, Switch, Button, LinearProgress } from "@material-ui/core";
import settlementsPanelStyles from "./settlementsPanelStyles";
import SettlementsPanelDetails from "./details/settlementsPanelDetails";
import SettlementsPanelChat from "./chat/settlementsPanelChat";
import SettlementsPanelClientSettlement from "./client-settlement/settlementsPanelClientSettlement";
import SettlementsPanelDriverSettlement from "./driver-settlement/settlementsPanelDriverSettlement";
import SettlementsPanelSum from "./sum/settlementsPanelSum";
import settlementsApiService from "./common/api/settlementsApi.service";
import settlementsPanelSettlementFactory from "./common/settlementsPanelSettlement.factory";
import cloneDeep from "lodash/cloneDeep";
import HttpClient from "HttpClient";
import { MimicContext } from "../../../context";
import CourseMap from "../../reusableComponents/orders/moreDetails/courseMap/CourseMap";
import { OrderMap } from "app/reusableComponents/newOrderPanel/map";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineContent from "@material-ui/lab/TimelineContent";
import SimpleBar from "simplebar-react";
import {format, fromUnixTime} from "date-fns";
import {pl} from "date-fns/locale";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import scss from "./css/settlementsPanel.module.scss"
import styles from "../../reusableComponents/rozliczenia/rozliczeniaDetails/rozliczeniaDetails.module.scss";
import SetRoute from "../../reusableComponents/rozliczenia/rozliczeniaDetails/setRoute/SetRoute";

const SettlementsPanel = (props) => {
    const styles = settlementsPanelStyles();

    const { loggedUser } = useContext(MimicContext);
    const [settlementsData, setSettlementsData] = useState([]);
    const [defaultSettlementsData, setDefaultSettlementsData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [areOrdersFetching, setAreOrdersFetching] = useState(false);
    const [areConnectedOrdersJoin, setAreConnectedOrdersJoin] = useState(true);
    const [selectedOrderNumber, setSelectedOrderNumber] = useState(undefined);
    const [clientSettlementId, setClientSettlementId] = useState(undefined);
    const [driverBillingId, setDriverBillingId] = useState(undefined);
    const [realWaypoints, setRealWaypoints] = useState([]);
    const [disablePlannedRouteEdit, setDisablePlannedRouteEdit] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [selectedSettlementCandidate, setSelectedSettlementCandidate] = useState({});
    const [disconnectOrderModal, setDisconnectOrderModal] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [customRouteWaypoints, setCustomRouteWaypoints] = useState([]);
    const [courseMapRefreshFlag, setCourseMapRefreshFlag] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [totalTime, setTotalTime] = useState(null);
    const [totalKm, setTotalKm] = useState(null);

    useEffect(() => {
        setAreOrdersFetching(true);
        settlementsApiService
            .fetchSettlementsDetails({ orderId: props.orderId })
            .then((response) => {
                const ordersWithUserContractors = {
                    ...response.data.data,
                    orders: response.data.data.orders.reduce(
                        (acc, curr) =>
                            loggedUser.contractorId
                                ? curr.contractor.id === loggedUser.contractorId
                                    ? [...acc, curr]
                                    : acc
                                : [...acc, curr],
                        []
                    ),
                };
                const settlementCandidates =
                    settlementsPanelSettlementFactory.createSettlementsData(ordersWithUserContractors);
                setAreConnectedOrdersJoin(response.data.data.connectedSettlement ?? true);
                setSettlementsData(settlementCandidates);
                setDefaultSettlementsData(cloneDeep(settlementCandidates));
                setOrders(response.data.data.orders);

                setClientSettlementId(response.data.data.clientSettlement?.id); //old
                setDriverBillingId(response.data.data.driverBilling?.id); //old

                if (response.data.data.clientSettlement) {
                    createRouteWaypointsForSettlements(settlementCandidates);
                }
                setSelectedOrderNumber(settlementCandidates[0].orderNumber);
            })
            .finally(() => {
                setAreOrdersFetching(false);
            });
    }, [props.orderId]);

    useEffect(() => {
        updateSelectedSettlementCandidate();
    }, [selectedOrderNumber, props.orderId]);

    const updateSelectedSettlementCandidate = (data) => {
        //optional
        const helper = data ?? settlementsData;
        const selectedSettlementCandidate = areConnectedOrdersJoin
            ? helper[0]
            : helper.find((item) => item.orderNumber === selectedOrderNumber);

        setSelectedSettlementCandidate(selectedSettlementCandidate);
    };

    const handleUpdateWaypoints = (newWaypoints) => {
        setRealWaypoints(newWaypoints);
    };

    const handleClose = () => {
        props.onCloseClick();
    };

    const onTabClick = (orderNumber, idx) => {
        setDisablePlannedRouteEdit(idx === 0 || !areConnectedOrdersJoin ? false : true);
        setSelectedOrderNumber(orderNumber);
    };

    const getSelectedSettlementCandidate = () => {
        return settlementsData.find((item) => item.orderNumber === selectedOrderNumber);
    };

    const createRouteWaypointsForSettlements = async (settlementCandidates) => {
        let newSettlementCandidates = [...settlementCandidates];

        for await (const [index, settlement] of newSettlementCandidates.entries()) {
            const waypointsData = await settlementsPanelSettlementFactory.createRouteWaypoints(
                settlement.clientData.routesSettlements
            );

            newSettlementCandidates[index].clientData.waypointsData = waypointsData;
        }
        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementRateChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.rate = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementStopCostChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.stopCost = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementBillingModelChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }
        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.billingModel = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementDiscountChange = (value) => {
        if (value > 100) {
            return;
        }

        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }
        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.discountPercent = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementStageAddressChange = async (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        //set today date for first stop if date doesnt exists
        if(stageIndex === 0 && newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[0].date === null) {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[0].date = new Date()
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[stageIndex].address =
            value;

        const data = await settlementsPanelSettlementFactory.createRouteWithOSRMData(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.map(
                settlementsPanelSettlementFactory.mapDateToPlannedDate
            )
        );

        const waypointsData = await settlementsPanelSettlementFactory.createRouteWaypoints(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.waypointsData = waypointsData;

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements = data.map(
            settlementsPanelSettlementFactory.mapPlannedDateToDate
        );

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementStageTimeChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }
        const currentTime =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[
                stageIndex
            ].date?.getTime() ?? value.getTime();

        const timeDifference =
            currentTime > value.getTime()
                ? `- ${Math.abs(currentTime - value.getTime())}`
                : `+ ${Math.abs(currentTime - value.getTime())}`;

        function evil(string) {
            return new Function("return " + string)();
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.map(
                (route, idx) => {
                    if (idx === stageIndex) return { ...route, date: value };

                    if (idx <= stageIndex) return route;

                    return {
                        ...route,
                        date: new Date(evil(`${new Date(route.date).getTime()}${timeDifference}`)),
                    };
                }
            );

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementStageStopTimeChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[
            stageIndex
        ].stopDuration = value;

        // newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements =
        //     defaultClientRoutes[indexOfSelectedSettlementCandidate].reduce(
        //         settlementsPanelSettlementFactory.calculateStopTime,
        //         []
        //     );
        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementStageDistanceChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const valueAsNumber = Number(value);

        if (Number.isNaN(valueAsNumber)) {
            return;
        }

        if (valueAsNumber > 10000) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[stageIndex].distance =
            valueAsNumber;

        setSettlementsData(newSettlementCandidates);
    };

    const onClientSettlementHighwayCostChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const valueAsNumber = Number(value);

        if (Number.isNaN(valueAsNumber)) {
            return;
        }

        if (valueAsNumber > 10000) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.highwayCost = valueAsNumber;

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementDriverChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const { billingModel, rate, waitingRate, startAddress, ...rest } = value;

        if (areConnectedOrdersJoin) {
            newSettlementCandidates = newSettlementCandidates.map((candidate) => ({
                ...candidate,
                driverData: {
                    ...candidate.driverData,
                    driver: rest,
                    stopCost: waitingRate,
                    billingModel,
                    rate,
                    startAddress,
                },
            }));
        } else {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.driver = rest;
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.billingModel = billingModel;
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.rate = rate;
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.stopCost = waitingRate;
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.startAddress = startAddress;
        }

        setSettlementsData(newSettlementCandidates);
        updateSelectedSettlementCandidate(newSettlementCandidates);
    };

    const onDriverSettlementBillingModelChange = (value, forceUpdate = false) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }
        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.billingModel = value;

        if (areConnectedOrdersJoin || forceUpdate) {
            newSettlementCandidates = newSettlementCandidates.map((settlement) => ({
                ...settlement,
                billingModel: (settlement.driverData.billingModel = value),
            }));
        }

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementRateChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.rate = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementStopCostChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.stopCost = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementStageAddressChange = async (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

          //set today date for first stop if date doesnt exists
        if(stageIndex === 0 && newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[0].date === null) {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[0].date = new Date()
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[stageIndex].address =
            value;

        const data = await settlementsPanelSettlementFactory.createRouteWithOSRMData(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.map(
                settlementsPanelSettlementFactory.mapDateToPlannedDate
            )
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements = data.map(
            settlementsPanelSettlementFactory.mapPlannedDateToDate
        );

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementStageTimeChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const currentDate =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[
                stageIndex
            ].date?.getTime() ?? value.getTime();

        const timeDifference =
            currentDate > value.getTime()
                ? `- ${Math.abs(currentDate - value.getTime())}`
                : `+ ${Math.abs(currentDate - value.getTime())}`;

        function evil(string) {
            return new Function("return " + string.replace(/[^-()\d/*+.]/g, ""))();
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.map(
                (route, idx) => {
                    if (idx === stageIndex) return { ...route, date: value };

                    if (idx <= stageIndex) return route;

                    return {
                        ...route,
                        date: new Date(evil(`${new Date(route.date).getTime()}${timeDifference}`)),
                    };
                }
            );

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementStageStopTimeChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[
            stageIndex
        ].stopDuration = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementStageDistanceChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const valueAsNumber = Number(value);

        if (Number.isNaN(valueAsNumber)) {
            return;
        }

        if (valueAsNumber > 10000) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[stageIndex].distance =
            valueAsNumber;

        setSettlementsData(newSettlementCandidates);
    };

    const onDriverSettlementHighwayCostChange = (value, stageIndex) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const valueAsNumber = Number(value);

        if (Number.isNaN(valueAsNumber)) {
            return;
        }

        if (valueAsNumber > 1000000000) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.highwayCost = valueAsNumber;

        setSettlementsData(newSettlementCandidates);
    };

    const selectedOrder = orders.find((item) => item.number === selectedOrderNumber);

    const shouldShowOrdersSplittingSwitch = settlementsData.length > 1;

    const addNewClientSettlementStage = () => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const newEmptyStage = {
            address: undefined,
            date: null,
            stopDuration: 0,
            distance: 0,
            highwayCost: 0,
        };

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.push(newEmptyStage);

        setSettlementsData(newSettlementCandidates);
    };

    const deleteClientSettlementStage = async (index) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        if (
            index === 0 &&
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.length > 1
        ) {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements[1].distance = 0;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.filter(
                (_stage, stageIndex) => stageIndex !== index
            );

        const data = await settlementsPanelSettlementFactory.createRouteWithOSRMData(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements.map(
                settlementsPanelSettlementFactory.mapDateToPlannedDate
            )
        );

        const waypointsData = await settlementsPanelSettlementFactory.createRouteWaypoints(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.waypointsData = waypointsData;

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements = data.map(
            settlementsPanelSettlementFactory.mapPlannedDateToDate
        );

        setSettlementsData(newSettlementCandidates);
    };

    const addNewDriverSettlementStage = () => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        const newEmptyStage = {
            address: undefined,
            date: null,
            stopDuration: 0,
            distance: 0,
            highwayCost: 0,
        };

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.push(newEmptyStage);

        setSettlementsData(newSettlementCandidates);
    };

    const deleteDriverSettlementStage = async (index) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        if (
            index === 0 &&
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.length > 1
        ) {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements[1].distance = 0;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements =
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.filter(
                (_stage, stageIndex) => stageIndex !== index
            );

        const data = await settlementsPanelSettlementFactory.createRouteWithOSRMData(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements.map(
                settlementsPanelSettlementFactory.mapDateToPlannedDate
            )
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements = data.map(
            settlementsPanelSettlementFactory.mapPlannedDateToDate
        );

        setSettlementsData(newSettlementCandidates);
    };

    const validateForm = () => {

        if (areConnectedOrdersJoin) {
            const isBillingModelPicked =  settlementsData[0].clientData.billingModel?.id
            if(!isBillingModelPicked) {
                HttpClient.addFlash("Nie wybrano modelu rozliczeniowego", {
                    variant: "error",
                });
                return false;
            }

            const clientAddressIsPicked = settlementsData[0].clientData.routesSettlements.find(
                (route) => !route.address
            );
            const driverAddressIsPicked = settlementsData[0].driverData.routesSettlements.find(
                (route) => !route.address
            );

            if (
                (!!clientAddressIsPicked && settlementsData[0].clientData.routesSettlements.length >= 1) ||
                (!!driverAddressIsPicked && settlementsData[0].driverData.routesSettlements.length >= 1)
            ) {
                HttpClient.addFlash(`Nie wybrano adresu dla zlecenia: ${settlementsData[0].orderNumber}`, {
                    variant: "error",
                });
                return false;
            }

            const routeClientSettlementHasPickedData = settlementsData[0].clientData.routesSettlements.find(
                (route) => !route.date
            );
            const routeDriverSettlementHasPickedData = settlementsData[0].driverData.routesSettlements.find(
                (route) => !route.date
            );

            if (
                (!!routeClientSettlementHasPickedData && settlementsData[0].clientData.routesSettlements.length >= 1) ||
                (!!routeDriverSettlementHasPickedData && settlementsData[0].driverData.routesSettlements.length >= 1)
            ) {
                HttpClient.addFlash(`Nie wybrano godziny dla zlecenia: ${settlementsData[0].orderNumber}`, {
                    variant: "error",
                });
                return false;
            }

            const isDriverFilled = settlementsData[0].driverData.driver;
            if (!isDriverFilled) {
                HttpClient.addFlash("Nie wybrano kierowcy", {
                    variant: "error",
                });
                return false;
            }

            return true;
        }
        const clientAddressIsPicked = settlementsData.find((settlement) =>
            settlement.clientData.routesSettlements.length >= 1
                ? settlement.clientData.routesSettlements.find((route) => !route.address)
                : false
        );
        const driverAddressIsPicked = settlementsData.find((settlement) =>
            settlement.driverData.routesSettlements.length >= 1
                ? settlement.driverData.routesSettlements.find((route) => !route.address)
                : false
        );

        const isBillingModelPicked = settlementsData.find((settlement) => !settlement.clientData.billingModel?.id)

        if(!!isBillingModelPicked) {
            HttpClient.addFlash("Nie wybrano modelu rozliczeniowego", {
                variant: "error",
            });
            return false;
        }

        if (clientAddressIsPicked || driverAddressIsPicked) {
            HttpClient.addFlash(
                `Nie wybrano adresu dla zlecenia: ${
                    clientAddressIsPicked?.orderNumber ?? driverAddressIsPicked?.orderNumber
                }`,
                {
                    variant: "error",
                }
            );
            return false;
        }

        const routeClientSettlementHasPickedData = settlementsData.find((settlement) =>
            settlement.clientData.routesSettlements.length >= 1
                ? settlement.clientData.routesSettlements.find((route) => !route.date)
                : false
        );
        const routeDriverSettlementHasPickedData = settlementsData.find((settlement) =>
            settlement.driverData.routesSettlements.length >= 1
                ? settlement.driverData.routesSettlements.find((route) => !route.date)
                : false
        );

        if (routeClientSettlementHasPickedData || routeDriverSettlementHasPickedData) {
            HttpClient.addFlash(
                `Nie wybrano godziny dla zlecenia: ${
                    routeClientSettlementHasPickedData?.orderNumber ?? routeDriverSettlementHasPickedData?.orderNumber
                }`,
                {
                    variant: "error",
                }
            );
            return false;
        }
        return true;
    };

    const onSubmitButtonClick = () => {
        const isFormValid = validateForm();
        if (!isFormValid) {
            return;
        }

        const requestBody = settlementsPanelSettlementFactory.createSaveRequest(
            settlementsData,
            orders,
            areConnectedOrdersJoin,
            clientSettlementId,
            driverBillingId
        );
        setInProgress(true)
        settlementsApiService.saveSettlement(requestBody).then(() => props.onSettlementSaveSuccess());
    };

    const requestBody = () => {
        return settlementsPanelSettlementFactory.createSaveRequest(
            settlementsData,
            orders,
            areConnectedOrdersJoin,
            clientSettlementId,
            driverBillingId
        );
    }

    const copySettlements = () => {
        const newSettlementsData = [...settlementsData];

        const currentSelectedSettlementData = settlementsData.find((item) => item.orderNumber === selectedOrderNumber);

        const indexOfCurrentSelectedSettlementData = newSettlementsData.indexOf(currentSelectedSettlementData);

        newSettlementsData[indexOfCurrentSelectedSettlementData].driverData.routesSettlements = cloneDeep(
            newSettlementsData[indexOfCurrentSelectedSettlementData].clientData.routesSettlements
        );

        setSettlementsData(newSettlementsData);
    };

    const copyPlannedRoutes = async () => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].selectedRoutesVersion = "PLANNED";

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements =
            await settlementsPanelSettlementFactory.createClientSettlementStagesFromPlannedRoutes(
                selectedSettlementCandidate.clientData.plannedRoutes,
                selectedSettlementCandidate.clientData.billingModel
            );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements =
            await settlementsPanelSettlementFactory.createDriverSettlementStagesFromPlannedRoutes(
                selectedSettlementCandidate.clientData.plannedRoutes,
                selectedSettlementCandidate.driverData.billingModel
            );

        const waypointsData = await settlementsPanelSettlementFactory.createRouteWaypoints(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.waypointsData = waypointsData;

        updateSelectedSettlementCandidate(newSettlementCandidates);
        setSettlementsData(newSettlementCandidates);
    };

    const copyCompletedRoutes = async () => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].selectedRoutesVersion = "COMPLETED";

        if (selectedSettlementCandidate.clientData.settlementStatus !== 1) {
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements =
                await settlementsPanelSettlementFactory.createClientSettlementStagesFromCompletedRoutes(
                    selectedSettlementCandidate.driverData.completedRoutes,
                    selectedSettlementCandidate.clientData.billingModel
                );
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].driverData.routesSettlements =
            await settlementsPanelSettlementFactory.createDriverSettlementStagesFromCompletedRoutes(
                selectedSettlementCandidate.driverData.completedRoutes,
                selectedSettlementCandidate.driverData.billingModel
            );

        const waypointsData = await settlementsPanelSettlementFactory.createRouteWaypoints(
            newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.routesSettlements
        );

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.waypointsData = waypointsData;

        updateSelectedSettlementCandidate(newSettlementCandidates);
        setSettlementsData(newSettlementCandidates);
    };

    const onHighwayWithWorkerChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.highwayWithWorker = value;

        setSettlementsData(newSettlementCandidates);
    };

    const onHighwayWithoutWorkerChange = (value) => {
        let newSettlementCandidates = [...settlementsData];

        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const indexOfSelectedSettlementCandidate = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (indexOfSelectedSettlementCandidate === -1) {
            return;
        }

        newSettlementCandidates[indexOfSelectedSettlementCandidate].clientData.highwayWithoutWorker = value;

        setSettlementsData(newSettlementCandidates);
    };

    const toggleShowMap = () => {
        setShowMap((prev) => !prev);
    };
    const handleChildModalClose = () => {
        setDisconnectOrderModal(false);
    };

    const handleChildModalOpen = () => {
        if (areConnectedOrdersJoin) {
            setDisconnectOrderModal(true);
            return;
        }
        onDriverSettlementBillingModelChange(settlementsData[0].driverData.billingModel, true);
        onTabClick(settlementsData[0].orderNumber, 0);
        setAreConnectedOrdersJoin(true);
    };

    const handleShowHistory = async () => {
        const httpRequest = HttpClient.createRequest();
        httpRequest.put(`api/orders/${selectedOrder?.courseDetails.id}/set/disable-edit-flag`, {
            disableEdit: true
        }, true, true, true).then(() => { });
    }

    const updateSettlementFromModal = () => {
        let newSettlementCandidates = [...settlementsData];
        const selectedSettlementCandidate = getSelectedSettlementCandidate();

        const idx = newSettlementCandidates.indexOf(selectedSettlementCandidate);

        if (idx === -1) {
            return;
        }

        const newSettlementCandidatesCleared = newSettlementCandidates.map((settlement, index) => {
            return {
                ...settlement,
                clientData: {
                    ...settlement.clientData,
                    rate: defaultSettlementsData[0].clientData.rate,
                    highwayWithoutWorker: defaultSettlementsData[0].clientData.highwayWithoutWorker,
                    highwayWithWorker: defaultSettlementsData[0].clientData.highwayWithWorker,
                    routesSettlements: [],
                    highwayCost: defaultSettlementsData[0].clientData.highwayCost,
                    waypointsData: null,
                },
                driverData: {
                    ...settlement.driverData,
                    ...newSettlementCandidates[0].driverData,
                    billingId: settlement.driverData.billingId,
                    highwayCost: defaultSettlementsData[0].driverData.highwayCost,
                    routesSettlements: []
                },
            };
        });

        setSettlementsData(newSettlementCandidatesCleared);
        onTabClick(newSettlementCandidatesCleared[0].orderNumber, 0);
        updateSelectedSettlementCandidate(newSettlementCandidatesCleared);
    };

    const handleChildModalAccept = () => {
        setDisablePlannedRouteEdit(false);
        setAreConnectedOrdersJoin(false);
        updateSettlementFromModal();
        handleChildModalClose();
    };

    const isSettlementsNotDefaultState = () => {
        return (settlementsData.filter((settlement) => {
            return settlement.clientData.settlementStatus !== 0;
        })).length > 0
    }

    const courseHistory = selectedOrder?.courseDetails?.history
    const createDate = selectedOrder?.courseDetails
        ? format(
            fromUnixTime(
                selectedOrder?.courseDetails.createDate < selectedOrder?.courseDetails.destinationTime
                    ? selectedOrder?.courseDetails.createDate
                    : selectedOrder?.courseDetails.destinationTime - 10800
            ),
            "d LLLL yyyy, HH:mm",
            {
                locale: pl,
            }
        )
        : "";

    const handleCounters = (km, time) => {
        const distance = `${Math.round(km / 1000)} km`;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const duration = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

        if (selectedOrder?.courseDetails.isDestinationTime) {
            setStartTime(
                format(fromUnixTime(selectedOrder?.courseDetails.destinationTime - time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
            setEndTime(format(fromUnixTime(selectedOrder?.courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
        } else {
            setStartTime(format(fromUnixTime(selectedOrder?.courseDetails.destinationTime), "d LLLL yyyy, HH:mm", { locale: pl }));
            setEndTime(
                format(fromUnixTime(selectedOrder?.courseDetails.destinationTime + time), "d LLLL yyyy, HH:mm", { locale: pl })
            );
        }
        setTotalKm(distance);
        setTotalTime(duration);
    };

    const editedData =
        courseHistory?.length > 1
            ? courseHistory.map((item, index) => {
                const lastIndex = courseHistory.length - 1;

                //sprawdzenie, które dane się zmieniły:
                let orderNoEdited = "";
                let notesEdited = "";
                let fromEdited = "";
                let toEdited = "";
                let workerEdited = "";
                let timeEdited = "";
                let canceledOrder = "";
                let courseEdited = false;
                let partnerEdited = ""

                if (courseHistory.length > 1 && index < lastIndex) {
                    canceledOrder = item.cancel || courseHistory[index + 1].cancel ? "--edited" : "";
                    orderNoEdited =
                        (item.orderNo && item.orderNo !== courseHistory[index + 1].orderNo) ||
                        (!item.orderNo && courseHistory[index + 1].orderNo)
                            ? "--edited"
                            : "";
                    notesEdited =
                        (item.notes && item.notes !== courseHistory[index + 1].notes) ||
                        (!item.notes && courseHistory[index + 1].notes)
                            ? "--edited"
                            : "";
                    partnerEdited = (item.consortiumMemberName && item.consortiumMemberName !== courseHistory[index + 1].consortiumMemberName ||
                        !item.consortiumMemberName && courseHistory[index + 1].consortiumMemberName) ? '--edited' : ''
                    fromEdited = item.from?.id !== courseHistory[index + 1].from?.id ? "--edited" : "";
                    toEdited = item.to?.id !== courseHistory[index + 1].to?.id ? "--edited" : "";
                    timeEdited =
                        (item.destinationTime && item.destinationTime !== courseHistory[index + 1].destinationTime) ||
                        (item.direction && item.direction !== courseHistory[index + 1].direction) ||
                        (item.isWorkAddress && item.isWorkAddress !== courseHistory[index + 1].isWorkAddress) ||
                        (item.isDestinationTime &&
                            item.isDestinationTime !== courseHistory[index + 1].isDestinationTime)
                            ? "--edited"
                            : "";

                    if (item.worker.length === courseHistory[index + 1].worker.length) {
                        let workerId = "";
                        let addressId = "";
                        for (let i = 0; i < item.worker.length; i++) {
                            workerId = item.worker[i].id;
                            addressId = item.worker[i].addressId;
                            for (let j = 0; j < courseHistory[index + 1].worker.length; j++) {
                                if (
                                    workerId !== courseHistory[index + 1].worker[j].id ||
                                    addressId !== courseHistory[index + 1].worker[j].addressId
                                ) {
                                    workerEdited = "--edited";
                                }
                            }
                        }
                    } else {
                        workerEdited = "--edited";
                    }
                }
                if (
                    orderNoEdited ||
                    notesEdited ||
                    fromEdited ||
                    toEdited ||
                    workerEdited ||
                    timeEdited ||
                    canceledOrder ||
                    partnerEdited
                ) {
                    courseEdited = true;
                    if (!isEdited) {
                        setIsEdited(true);
                    }
                }

                //ustawienie czasów
                let changeDate = format(fromUnixTime(item?.changeHistory?.changeDate), "d LLLL yyyy, HH:mm", {
                    locale: pl,
                });
                return (
                    <Timeline key={index} align="alternate" className={styles.timeRoot}>
                        {canceledOrder ? (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className={styles.timeDot} />
                                    <TimelineConnector className={styles.timeConnector} />
                                </TimelineSeparator>
                                <TimelineContent className={styles.timeContent}>
                                    <div className={scss['historyItem']}>
                                        <div className={scss['historyItem--wrapper']}>
                                            <p className={scss['historyItem--wrapper--history--details--singleColumn--data--history']}>
                                                {`${changeDate} (${
                                                    item.changeAuthor.name ? item.changeAuthor.name + " " : ""
                                                }
                                                    ${item.changeAuthor.surname ? item.changeAuthor.surname : ""})`}
                                            </p>
                                            <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                Zamówienie ANULOWANE
                                            </p>
                                            <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                {`Uwagi: ${item.cancelNotes ? item.cancelNotes : "Nie podano"}`}
                                            </p>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        ) : (
                            // courseEdited || (isEdited && index === lastIndex) &&
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className={styles.timeDot} />
                                    <TimelineConnector className={styles.timeConnector} />
                                </TimelineSeparator>
                                <TimelineContent className={styles.timeContent}>
                                    <div className={scss['historyItem']}>
                                        <div className={scss['historyItem--wrapper']}>
                                            {index === lastIndex && !canceledOrder ? (
                                                <p className={scss['historyItem--wrapper--history--details--title--history']} >
                                                    Pierwsza wersja
                                                </p>
                                            ) : null}
                                            <div className={scss['historyItem--wrapper--history']} >
                                                <div className={scss['historyItem--wrapper--history--details']} >
                                                    <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                        {index !== lastIndex ? (
                                                            <p className={scss['historyItem--wrapper--history--details--singleColumn--data--history']} >
                                                                {`${changeDate} (${
                                                                    item.changeAuthor.name
                                                                        ? item.changeAuthor.name + " "
                                                                        : ""
                                                                }
                                                    ${item.changeAuthor.surname ? item.changeAuthor.surname : ""})`}
                                                            </p>
                                                        ) : null}

                                                        {orderNoEdited || index === lastIndex ? (
                                                            <>
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                    {`Nr zlecenia: `}
                                                                </p>
                                                                <span className={scss['historyItem--wrapper--history--details--singleColumn--data' +
                                                                        orderNoEdited]} >
                                                                      {item.orderNo ? item.orderNo : "-"}
                                                                  </span>
                                                            </>
                                                        ) : null}
                                                        {notesEdited || index === lastIndex ? (
                                                            <>
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                    {`Uwagi: `}
                                                                </p>
                                                                <span className={scss['historyItem--wrapper--history--details--singleColumn--data' +
                                                                        notesEdited]} >
                                                                      {item.notes ? item.notes : "-"}
                                                                  </span>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Adres odbioru historia*/}
                                            {fromEdited ||
                                            (workerEdited && item.direction === 1) ||
                                            index === lastIndex ? (
                                                <div className={scss['historyItem--wrapper--history']}>
                                                    <p className={scss['historyItem--wrapper--history--details--title']} >
                                                        Adres odbioru
                                                    </p>
                                                    <div className={scss['historyItem--wrapper--history--details']} >
                                                        {item.from || (item.from && index === lastIndex) ? (
                                                            <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                    Adres:
                                                                </p>
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--data' +
                                                                        fromEdited]} >
                                                                    {`${item.from.street ? item.from.street : ""} ${
                                                                        item.from.no ? item.from.no : ""
                                                                    } ${item.from.flat ? `/${item.from.flat}` : ""} ${
                                                                        item.from.zipCode ? item.from.zipCode : ""
                                                                    } ${item.from.city ? item.from.city : "-"}`}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            item.worker.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            Imię i nazwisko
                                                                        </p>
                                                                        <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                              {`${
                                                                                  item.firstname ? item.firstname : " "
                                                                              } ${item.surname ? item.surname : " "}`}
                                                                          </span>
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            Adres
                                                                        </p>
                                                                        <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                              {`${item.street ? item.street : ""} ${
                                                                                  item.no ? item.no : " "
                                                                              } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                  item.zipCode ? item.zipCode : ""
                                                                              } ${item.city ? item.city : ""}`}
                                                                          </span>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}

                                            {/*Adres docelowy*/}
                                            {toEdited ||
                                            (workerEdited && item.direction === 0) ||
                                            index === lastIndex ? (
                                                <div className={scss['historyItem--wrapper--history']} >
                                                    <p className={scss['historyItem--wrapper--history--details--title']} >
                                                        Adres docelowy
                                                    </p>
                                                    <div className={scss['historyItem--wrapper--history--details']} >
                                                        {item.to || (item.to && index === lastIndex) ? (
                                                            <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                    Adres
                                                                </p>
                                                                <p className={scss['historyItem--wrapper--history--details--singleColumn--data' +
                                                                        toEdited]} >
                                                                    {`${item.to.street ? item.to.street : ""} ${
                                                                        item.to.no ? item.to.no : ""
                                                                    } ${item.to.flat ? `/${item.to.flat}` : ""} ${
                                                                        item.to.zipCode ? item.to.zipCode : ""
                                                                    } ${item.to.city ? item.to.city : ""}`}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            item.worker.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            Imię i nazwisko
                                                                        </p>
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                            {`${
                                                                                item.firstname ? item.firstname : ""
                                                                            } ${item.surname ? item.surname : ""}`}
                                                                        </p>
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            Adres
                                                                        </p>
                                                                        <p className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                            {`${item.street ? item.street : ""} ${
                                                                                item.no ? item.no : ""
                                                                            } ${item.flat ? `/${item.flat}` : ""} ${
                                                                                item.zipCode ? item.zipCode : ""
                                                                            } ${item.city ? item.city : ""}`}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {partnerEdited || index === lastIndex ? (
                                                <div className={scss['historyItem--wrapper--history']} >
                                                    <p className={scss['historyItem--wrapper--history--details--title']} >
                                                        Partner
                                                    </p>
                                                    <div>
                                                        {item.consortiumMemberName ?? '-'}&nbsp;
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/*Czasy*/}
                                            {timeEdited || index === lastIndex ? (
                                                <div className={scss['historyItem--wrapper--history']} >
                                                    <p className={scss['historyItem--wrapper--history--details--title']} >
                                                        Czasy
                                                    </p>
                                                    <div className={scss['historyItem--wrapper--time']} >
                                                        <div className={scss['historyItem--wrapper--time--single']} >
                                                            {item.from || item.isWorkAddress ? (
                                                                <div className={scss['historyItem--wrapper--time--from--toWork' +
                                                                        timeEdited]} >
                                                                </div>
                                                            ) : (
                                                                <div className={scss['historyItem--wrapper--time--from--toHome' +
                                                                        timeEdited]} >
                                                                </div>
                                                            )}

                                                            <div className={scss['historyItem--wrapper--time--single--data' +
                                                                    timeEdited]} >
                                                                {item.isDestinationTime
                                                                    ? "---"
                                                                    : format(
                                                                        fromUnixTime(item.destinationTime),
                                                                        "d LLLL yyyy, HH:mm",
                                                                        { locale: pl }
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className={scss['historyItem--wrapper--time--single']} >
                                                            <div className={scss['historyItem--wrapper--time--single--info']} >
                                                                {item.to ? (
                                                                    <div className={scss['historyItem--wrapper--time--to--toWork' +
                                                                            timeEdited]} >
                                                                    </div>
                                                                ) : (
                                                                    <div className={scss['historyItem--wrapper--time--to--toHome' +
                                                                            timeEdited]} >
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className={scss['historyItem--wrapper--time--single--data' +
                                                                    timeEdited]} >
                                                                {item.isDestinationTime
                                                                    ? format(
                                                                        fromUnixTime(item.destinationTime),
                                                                        "d LLLL yyyy, HH:mm",
                                                                        { locale: pl }
                                                                    )
                                                                    : "---"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        )}
                    </Timeline>
                );
            })
            : null;

    // console.log('SELECTED___ORDER___', selectedOrder)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modal}
            open={props.isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.isOpen}>
                <div className={styles.paper}>
                    <div className={styles.header}>
                        <h2 className={styles.headerTitle}>Panel rozliczeń</h2>
                        <span className={styles.close} onClick={handleClose}>
                            X
                        </span>
                    </div>
                    {areOrdersFetching ? (
                        <div className={styles.loader}>
                            <LinearProgress className="progress-bar" />
                        </div>
                    ) : (
                        <div className={styles.modalContent}>
                            <Grid container spacing={1} className={styles.aboutCourseWrapper}>
                                <Grid item xs={4}>
                                    <Button
                                        size="small"
                                        className={styles.detailsButton}
                                        onClick={() => toggleShowMap()}
                                        variant="outlined"
                                    >
                                        {showMap ? "CZAT" : "MAPA"}
                                    </Button>
                                    <Button
                                        size="small"
                                        className={showHistory ? styles.detailsButtonOn : styles.detailsButton}
                                        variant="outlined"
                                        onClick={() => {
                                            setShowHistory(!showHistory)
                                        }}
                                    >
                                        HISTORIA
                                    </Button>
                                    <SettlementsPanelDetails
                                        order={selectedOrder}
                                        handleUpdateWaypoints={handleUpdateWaypoints}
                                    />
                                </Grid>
                                {
                                    showHistory &&
                                        <Grid item xs={2}><SimpleBar
                                            style={{ maxHeight: "80vh", minWidth: 320, marginTop: "1em", float: "left" }}
                                            className={styles.simplebar}
                                        >
                                            {editedData}
                                            {courseHistory.length > 1 && isEdited ? (
                                                <Timeline align="left" className={styles.timeRoot}>
                                                    <TimelineItem style={{content: "none !important"}}>
                                                        <TimelineDot className={styles.timeDot} />
                                                        <TimelineContent className={styles.timeContent}>
                                                            <div className={scss['historyItem--wrapper']} >
                                                                <div className={scss['historyItem--wrapper--history']} >
                                                                    <div className={scss['historyItem--wrapper--history--details']} >
                                                                        <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                        <span className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            {`Utworzony: `}
                                                                        </span>
                                                                            <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                            {createDate}
                                                                        </span>
                                                                        </div>
                                                                        <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                                        <span className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                            {`Dyspozytor: `}
                                                                        </span>
                                                                            <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                            {`${
                                                                                selectedOrder?.courseDetails.author.firstName
                                                                                    ? selectedOrder?.courseDetails.author.firstName
                                                                                    : ""
                                                                            } ${
                                                                                selectedOrder?.courseDetails.author.lastName
                                                                                    ? selectedOrder?.courseDetails.author.lastName
                                                                                    : "-"
                                                                            } 
                                                                            ${
                                                                                selectedOrder?.courseDetails.subAuthor
                                                                                    ? `(jako ${selectedOrder?.courseDetails.subAuthor.firstName} ${selectedOrder?.courseDetails.subAuthor.lastName})`
                                                                                    : ""
                                                                            }`}
                                                                        </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </Timeline>
                                            ) : (
                                                <div className={scss['historyItem--wrapper']} >
                                                    <div className={scss['historyItem--wrapper--history']} >
                                                        <div className={scss['historyItem--wrapper--history--details']} >
                                                            <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                            <span className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                {`Utworzony: `}
                                                            </span>
                                                                <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                {createDate}
                                                            </span>
                                                            </div>
                                                            <div className={scss['historyItem--wrapper--history--details--singleColumn']} >
                                                            <span className={scss['historyItem--wrapper--history--details--singleColumn--info']} >
                                                                {`Dyspozytor: `}
                                                            </span>
                                                                <span className={scss['historyItem--wrapper--history--details--singleColumn--data']} >
                                                                {`${
                                                                    selectedOrder?.courseDetails.author.firstName
                                                                        ? selectedOrder?.courseDetails.author.firstName
                                                                        : ""
                                                                } ${
                                                                    selectedOrder?.courseDetails.author.lastName
                                                                        ? selectedOrder?.courseDetails.author.lastName
                                                                        : "-"
                                                                }
                                                                           ${
                                                                    selectedOrder?.courseDetails.subAuthor
                                                                        ? `(jako ${selectedOrder?.courseDetails.subAuthor.firstName} ${selectedOrder?.courseDetails.subAuthor.lastName})`
                                                                        : ""
                                                                }`}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </SimpleBar>
                                        </Grid>
                                }
                                <Grid item xs={showHistory ? 6 : 8}>
                                    {showMap ? (
                                            <div className={scss['mapContainer']}>
                                                {<>
                                                    <SetRoute
                                                        parent={"settlements"}
                                                        onSelectRoute={(routes) => {
                                                            setCustomRouteWaypoints(routes);
                                                            setCourseMapRefreshFlag(!courseMapRefreshFlag);
                                                        }}
                                                    />
                                                    <OrderMap
                                                        parent={"settlements"}
                                                        routeData={
                                                            selectedSettlementCandidate?.clientData?.waypointsData
                                                                ?.routeData ?? []
                                                        }
                                                        routeWaypoints={
                                                            selectedSettlementCandidate?.clientData?.waypointsData
                                                                ?.routeWaypoints ?? []
                                                        }
                                                        realWaypoints={!props.isDispatcher && !props.isContractorOperator && realWaypoints}
                                                        zoom={6}
                                                        key={String(courseMapRefreshFlag)}
                                                        customRouteWaypoints={customRouteWaypoints}
                                                        courseDetails={selectedOrder?.courseDetails}
                                                        handleCounters={handleCounters}
                                                    />
                                                </>}
                                            </div>
                                    ) : (
                                        <SettlementsPanelChat order={selectedOrder} isDispatcher={props.isDispatcher} isContractorOperator={props.isContractorOperator} />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={styles.selectCourseWrapper}>
                                        Zlecenia połączone:
                                        <Tabs
                                            aria-label="basic tabs example"
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            value={selectedOrderNumber}
                                        >
                                            {settlementsData.map((settlementCandidate, idx) => {
                                                return (
                                                    <Tab
                                                        className={styles.tab}
                                                        value={settlementCandidate.orderNumber}
                                                        onClick={() => onTabClick(settlementCandidate.orderNumber, idx)}
                                                        label={`${settlementCandidate.orderNumber}`}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                        {shouldShowOrdersSplittingSwitch && (
                                            <Switch
                                                disabled={props.isDispatcher || props.isContractorOperator || isSettlementsNotDefaultState()}
                                                checked={areConnectedOrdersJoin}
                                                onChange={(_e, checked) => handleChildModalOpen(checked)}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            {!!selectedSettlementCandidate && (
                                <>
                                    <Grid container spacing={1} className={styles.backgroundDark}>
                                        <Grid item xs={12}>
                                            <SettlementsPanelClientSettlement
                                                orderId={props.orderId}
                                                connectedSettlements={areConnectedOrdersJoin}
                                                isFieldsDisabled={disablePlannedRouteEdit}
                                                isDispatcher={props.isDispatcher}
                                                isContractorOperator={props.isContractorOperator}
                                                isEdited={props.isEdited}
                                                selectedSettlement={selectedSettlementCandidate}
                                                selectedOrderNumber={selectedOrderNumber}
                                                onBillingModelChange={onClientSettlementBillingModelChange}
                                                onRateChange={onClientSettlementRateChange}
                                                onStopCostChange={onClientSettlementStopCostChange}
                                                onDiscountChange={onClientSettlementDiscountChange}
                                                onStageAddressChange={onClientSettlementStageAddressChange}
                                                onStageTimeChange={onClientSettlementStageTimeChange}
                                                onStageStopDurationChange={onClientSettlementStageStopTimeChange}
                                                onStageDistanceChange={onClientSettlementStageDistanceChange}
                                                onHighwayCostChange={onClientSettlementHighwayCostChange}
                                                addNewStage={addNewClientSettlementStage}
                                                deleteStage={deleteClientSettlementStage}
                                                onCopyRoutesButtonClick={copyPlannedRoutes}
                                                onSetSettlement={(settlement) => setSelectedSettlementCandidate(settlement)}
                                                requestData={requestBody}
                                                areRoutesCopied={
                                                    selectedSettlementCandidate.selectedRoutesVersion === "PLANNED"
                                                }
                                                onHighwayWithWorkerChange={onHighwayWithWorkerChange}
                                                onHighwayWithoutWorkerChange={onHighwayWithoutWorkerChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    {!props.isDispatcher && (
                                        <Grid container spacing={1} className={styles.backgroundDark}>
                                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                                <SettlementsPanelDriverSettlement
                                                    orderId={props.orderId}
                                                    selectedSettlement={selectedSettlementCandidate}
                                                    isDispatcher={props.isDispatcher}
                                                    isContractorOperator={props.isContractorOperator}
                                                    isFieldsDisabled={disablePlannedRouteEdit}
                                                    onDriverChange={onDriverSettlementDriverChange}
                                                    onBillingModelChange={onDriverSettlementBillingModelChange}
                                                    onRateChange={onDriverSettlementRateChange}
                                                    onStopCostChange={onDriverSettlementStopCostChange}
                                                    onStageAddressChange={onDriverSettlementStageAddressChange}
                                                    onStageTimeChange={onDriverSettlementStageTimeChange}
                                                    onStageStopDurationChange={onDriverSettlementStageStopTimeChange}
                                                    onStageDistanceChange={onDriverSettlementStageDistanceChange}
                                                    onHighwayCostChange={onDriverSettlementHighwayCostChange}
                                                    addNewStage={addNewDriverSettlementStage}
                                                    deleteStage={deleteDriverSettlementStage}
                                                    onCopySettlementsButtonClick={copySettlements}
                                                    onCopyRoutesButtonClick={copyCompletedRoutes}
                                                    areRoutesCopied={
                                                        selectedSettlementCandidate.selectedRoutesVersion ===
                                                        "COMPLETED"
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <SettlementsPanelSum
                                                isJoinedOrders={
                                                    shouldShowOrdersSplittingSwitch && areConnectedOrdersJoin
                                                }
                                                settlementCandidates={
                                                    shouldShowOrdersSplittingSwitch && !areConnectedOrdersJoin
                                                        ? [selectedSettlementCandidate]
                                                        : settlementsData
                                                }
                                                isContractorOperator={props.isContractorOperator}
                                                isDispatcher={!props.isDispatcher}
                                                areConnectedOrdersJoin={areConnectedOrdersJoin}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            marginTop: 25,
                                            marginRight: 5,
                                        }}
                                    >
                                        {((!props.isDispatcher && !props.isContractorOperator) || (props.isContractorOperator && selectedSettlementCandidate.partner === null)) && (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    width: 300,
                                                    background: "#1DBBED",
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    borderColor: "#1DBBED",
                                                    borderRadius: "0",
                                                    transition: "0.3s",
                                                    marginBottom: "10px",
                                                    "&:hover": {
                                                        color: "white",
                                                        backgroundColor: "#00a0e3",
                                                    },
                                                }}
                                                disabled={inProgress}
                                                onClick={onSubmitButtonClick}
                                            >
                                                Rozlicz kurs
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <Modal
                        open={disconnectOrderModal}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={styles.modalDisconnect}
                        onClose={handleChildModalClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{ timeout: 500 }}
                    >
                        <Fade in={disconnectOrderModal}>
                            <div className={styles.childModal}>
                                <div className={styles.header}>
                                    <p className={styles.paragraph}>Uwaga rozłączenie spowoduje wyzerowanie danych</p>
                                    <span className={styles.close} onClick={handleChildModalClose}>
                                        X
                                    </span>
                                </div>
                                <Grid container spacing={1} justifyContent="flex-end" style={{ marginTop: "10px" }}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        className={styles.closeButton}
                                        onClick={handleChildModalClose}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        style={{
                                            width: 300,
                                            background: "#1DBBED",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            borderColor: "#1DBBED",
                                            borderRadius: "0",
                                            transition: "0.3s",
                                            fontSize: "18px",
                                            "&:hover": {
                                                color: "white",
                                                backgroundColor: "#00a0e3",
                                            },
                                        }}
                                        onClick={handleChildModalAccept}
                                    >
                                        Potwierdzam
                                    </Button>
                                </Grid>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </Fade>
        </Modal>
    );
};

export default SettlementsPanel;
