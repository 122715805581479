import React from "react";

import { useFormikContext } from "formik";

import { addressType } from "app/reusableComponents/newOrderPanel/helpers";
import { Address } from "app/reusableComponents/newOrderPanel/formComponents";
import { useOrderContext } from "app/reusableComponents/newOrderPanel/hooks";

export const SelectAddress = ({ baseName, orderIndex, name, removeReturnOrder, userDispatch }) => {
    const { values } = useFormikContext();
    const { workAddresses } = useOrderContext();
    const currentOrder = values.orders[orderIndex];
    const disabledWorkAddresses = []

    const disabledSrcAddresses = currentOrder.workers.reduce((acc,worker) => {
        if(worker.isWorkAddress && worker.workAddress !== null) return ([...acc, worker.workAddress])

        return acc
    }, [])

    if(currentOrder.destination) {
        disabledWorkAddresses.push(currentOrder.destination)
    }


    if (baseName.includes("destination")) {
        return (
            <Address
                fieldName={baseName}
                label="Praca*"
                timePickerName={`${name}.timeDestination`}
                name={name}
                options={workAddresses}
                orderIndex={orderIndex}
                type={addressType.WORK}
                orderDetails={currentOrder}
                value={currentOrder?.destination}
                disabledAddresses={disabledSrcAddresses}
                onAddressTypeChange={() => removeReturnOrder()}
                userDispatch={userDispatch}
            />
        );
    }

    return (
        <div>
            {currentOrder.workers.map((worker, workerIndex) => {
                const key = worker.person?.id ? worker.person.id : `worker-address-${workerIndex}`;
                const coreName = `${name}.workers[${workerIndex}]`;
                if (worker.isWorkAddress)
                    return (
                        <Address
                            key={key}
                            fieldName={`${coreName}.workAddress`}
                            label="Praca*"
                            timePickerName={`${coreName}.timeOrigin`}
                            name={name}
                            options={workAddresses}
                            orderIndex={orderIndex}
                            toggleAddressName={`${coreName}.isWorkAddress`}
                            type={addressType.WORK}
                            value={worker.workAddress}
                            orderDetails={currentOrder}
                            disabledAddresses={disabledWorkAddresses}
                            onAddressTypeChange={() => removeReturnOrder(orderIndex)}
                            userDispatch={userDispatch}
                        />
                    );

                return (
                    <Address
                        key={key}
                        fieldName={`${coreName}.homeAddress`}
                        label="Dom*"
                        name={name}
                        options={worker.person?.adres}
                        orderIndex={orderIndex}
                        type={addressType.HOME}
                        value={worker.homeAddress}
                        orderDetails={currentOrder}
                        toggleAddressName={`${coreName}.isWorkAddress`}
                        coreName={coreName}
                        onAddressTypeChange={() => removeReturnOrder(orderIndex)}
                        userDispatch={userDispatch}
                    />
                );
            })}
        </div>
    );
};