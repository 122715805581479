import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TextField,
    MenuItem,
    Tooltip, Button,
} from "@material-ui/core";
import {
    TimelineItem,
    TimelineDot,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    Autocomplete,
} from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import settlementsPanelSettlementHelper from "../common/api/settlementsPanelSettlement.helper";
import settlementsPanelStyles from "./../settlementsPanelStyles";
import settlementsApiService from "../common/api/settlementsApi.service";
import SettlementsPanelPriceInput from "../common/settlementsPanelPriceInput";
import SettlementsPanelDistanceInput from "../common/settlementsPanelDistanceInput";
import { Add, Delete, FileCopy } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {disable} from "ol/rotationconstraint";
import HttpClient from "../../../../HttpClient";
import {MimicContext} from "../../../../context";

const SettlementsPanelClientSettlement = (props) => {
    const { isRoleType } = useContext(MimicContext)
    const styles = settlementsPanelStyles();
    const [billingModels, setBillingModels] = useState([]);
    const [placeAddresses, setPlaceAddresses] = useState([]);
    const [highwayWithWorkerOptions, setHighwayWithWorkerOptions] = useState([
        {
            label: "Tak",
            value: true,
        },
        {
            label: "Nie",
            value: false,
        },
    ]);
    const [highwayWithoutWorkerOptions, setHighwayWithoutWorkerOptions] = useState([
        {
            label: "Tak",
            value: true,
        },
        {
            label: "Nie",
            value: false,
        },
    ]);

    const acceptedValues = () => {
        return props.selectedSettlement.clientData.settlementStatus === 1 ? 1 : 0
    }

    const rejectedValues = () => {
        return props.selectedSettlement.clientData.settlementStatus === 2
    }

    const setSelectedSettlement = (settlement) => {
        props.onSetSettlement(settlement)
    }

    const [accepted, setAccepted] = useState(acceptedValues)
    const [rejected, setRejected] = useState(rejectedValues)

    const { clientData } = props.selectedSettlement;

    useEffect(() => {
        setAccepted(acceptedValues)
        setRejected(rejectedValues)
    }, [props.selectedSettlement])


    useEffect(() => {
        settlementsApiService.fetchBillingModels().then((response) => {
            const newBillingModels = response.data.data.map((responseBillingModel) => ({
                id: responseBillingModel.id,
                name: responseBillingModel.name,
            }));

            setBillingModels(newBillingModels);
        });
    }, []);

    useEffect(() => {
        if (!props.orderId) {
            return;
        }

        settlementsApiService.fetchPlaceAddresses({ orderId: props.orderId }).then((response) => {
            const newPlaceAddresses = response.data.data;

            setPlaceAddresses(newPlaceAddresses);
        });
    }, [props.orderId]);

    const onBillingModelChange = (value) => {
        const billingModel = billingModels.find((billingModel) => billingModel.id === value);
        props.onBillingModelChange(billingModel);
    };

    const onHighwayWithWorkerChange = (value) => {
        props.onHighwayWithWorkerChange(value);
    };

    const onHighwayWithoutWorkerChange = (value) => {
        props.onHighwayWithoutWorkerChange(value);
    };

    const onDiscountPercentChange = (e) => {
        const value = Number(e.target.value);

        if (value > clientData.maxDiscountPercent) {
            return;
        }

        const priceValidationPattern = /^\d{0,8}$/;

        if (priceValidationPattern.test(value)) {
            props.onDiscountChange(value);
        }
    };

    const computeRoutesSettlementsTotalTime = (routesSettlements) => {
        if (!routesSettlements?.length) {
            return undefined;
        }

        if (!routesSettlements.every((item) => item.date)) {
            return undefined;
        }

        const lastStageIndex = routesSettlements.length - 1;
        const startTime = routesSettlements[0].date.setSeconds(0);
        const endTime = routesSettlements[lastStageIndex].date.getTime() + (routesSettlements[lastStageIndex].stopDuration  * 60 * 1000);

        const routeDurationTime = endTime - startTime;

        if (routeDurationTime < 0) {
            return undefined;
        }

        return routeDurationTime;
    };

    const computeRoutesPlanTotalTime = (plannedRoutes) => {
        if (!plannedRoutes?.length) {
            return undefined;
        }

        if (!plannedRoutes.every((item) => item.plannedDate)) {
            return undefined;
        }

        const lastStageIndex = plannedRoutes.length - 1;
        const startTime = new Date(plannedRoutes[0].plannedDate.setSeconds(0,0)).getTime();
        const endTime = new Date(plannedRoutes[lastStageIndex].plannedDate.setSeconds(0,0)).getTime();

        const routeDurationTime = endTime - startTime;

        if (routeDurationTime < 0) {
            return undefined;
        }

        return routeDurationTime;
    };

    const getFormattedTotalTime = (duration) => {
        const withZero = (number) => {
            return number < 10 ? `0${number}` : number;
        };

        const seconds = duration / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;

        const formattedHours = Math.floor(hours);
        const formattedMinutes = withZero(Math.floor(minutes % 60));

        return `${formattedHours}:${formattedMinutes} h`;
    };

    const totalRoutesPlanTime = computeRoutesPlanTotalTime(clientData.plannedRoutes);

    const formattedRoutesPlanTotalTime = totalRoutesPlanTime ? getFormattedTotalTime(totalRoutesPlanTime) : "-----";

    const sumOfAllPlanRoutesDistance = Math.ceil(
        clientData.plannedRoutes.reduce((prev, current) => prev + current.distance, 0)
    );

    const clientDataWithStopTime = settlementsPanelSettlementHelper.getCalculatedTimeWithStop(clientData.routesSettlements)

    const totalRoutesSettlementTime = computeRoutesSettlementsTotalTime(clientDataWithStopTime);

    const formattedRoutesSettlementTotalTime = totalRoutesSettlementTime
        ? getFormattedTotalTime(totalRoutesSettlementTime)
        : "-----";

    const totalRoutesSettlementStopDuration = clientData.routesSettlements.reduce(
        (prev, current) => prev + current.stopDuration,
        0
    );

    const sumOfAllSettlementRoutesDistance = Math.ceil(
        clientData.routesSettlements.reduce((prev, current) => prev + current.distance, 0)
    );

    const shouldShowPlannedRoutesSum = !!clientData.plannedRoutes.length;

    const onStageStopDurationChange = (value, index) => {
        const priceValidationPattern = /^\d{0,8}$/;

        if (priceValidationPattern.test(value)) {
            props.onStageStopDurationChange(value, index);
        }
    };

    const onAddButtonClick = () => {
        props.addNewStage();
    };

    const onDeleteButtonClick = (index) => {
        props.deleteStage(index);
    };

    const setStatus = (status) => {
        const httpRequest = HttpClient.createRequest();
        httpRequest.put(`api/settlements/set/status`, {
            status: status,
            orderID: props.selectedSettlement.orderId,
            connected: props.connectedSettlements,
        }, true, true, true).then((response) => {
            if (response.status === 204) {
                HttpClient.addFlash('Rozliczenie zostało usunięte', {variant: 'error', preventDuplicate: true, key: 'internalServerError'});
                return;
            }
            const updatedSettlement = props.selectedSettlement
            updatedSettlement.clientData.settlementStatus = status
            setSelectedSettlement(updatedSettlement)
            if (status === 1 || status === 0)
                setAccepted(!accepted)
            else if (status === 2) {
                setAccepted(0)
                setRejected(true)
            }
            if (status === 1)
                setRejected(0)
        });
    }

    const notFirstSettlement = () => {
        if (!props.selectedSettlement.connectedOrders.length)
            return false

        return props.selectedSettlement.orderNumber !== props.selectedOrderNumber
    }

    const areFieldsDisabled = props.isFieldsDisabled || props.isDispatcher || accepted
    return (
        <Box>
            <Grid container spacing={1} style={{
                justifyContent: "flex-end"
            }}>
                {!props.isDispatcher && (<>
                <Grid item xs={4}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="55%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>
                                        {!!clientData.plannedRoutes.length && (
                                            <IconButton
                                                size="small"
                                                style={{
                                                    background: props.areRoutesCopied ? "#66D01D" : "#1DBBED",
                                                    color: "#fff",
                                                    width: 20,
                                                    height: 20,
                                                    marginRight: 5,
                                                }}
                                                disabled={areFieldsDisabled}
                                                onClick={() => props.onCopyRoutesButtonClick()}
                                            >
                                                <Tooltip title="Kopiuj">
                                                    <FileCopy
                                                        fontSize="small"
                                                        style={{ color: "white", fontSize: `0.65rem` }}
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                        Planowana trasa
                                    </TableCell>
                                    <TableCell className={styles.tableHeadCell}>Zlecona</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Plan</TableCell>
                                    <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientData.plannedRoutes.map((plannedRoute, index) => {
                                    const stageNo = index + 1;
                                    const address = settlementsPanelSettlementHelper.getFormattedAddress(
                                        plannedRoute.address
                                    );
                                    const orderedDate = plannedRoute.orderedDate
                                        ? settlementsPanelSettlementHelper.getFormattedTime(plannedRoute.orderedDate)
                                        : "-----";
                                    const planDate = plannedRoute.plannedDate
                                        ? settlementsPanelSettlementHelper.getFormattedTime(plannedRoute.plannedDate)
                                        : "-----";

                                    const isOrderedTimeNotAchievedOnTime =
                                        plannedRoute.orderedDate &&
                                        plannedRoute.plannedDate &&
                                        plannedRoute.orderedDate.getTime() !== plannedRoute.plannedDate.getTime();

                                    const isLastPath = !clientData.plannedRoutes[index + 1];

                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellRouteAddress}>
                                                    <div className={styles.tableCellText}>{stageNo}</div>
                                                    <TimelineItem className={styles.timelineItem}>
                                                        <TimelineSeparator>
                                                            <TimelineDot className={styles.timelineDot} />
                                                            {!isLastPath && <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        <TimelineContent className={styles.timelineContent}>
                                                            <div className={styles.tableCellText}>{address}</div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>{orderedDate}</div>
                                            </TableCell>
                                            <TableCell
                                                className={[
                                                    styles.tableCell,
                                                    isOrderedTimeNotAchievedOnTime && styles.tableCellValueError,
                                                ]}
                                            >
                                                <div className={styles.tableCellText}> {planDate}</div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>{plannedRoute.distance} km</div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {shouldShowPlannedRoutesSum && (
                                    <TableRow>
                                        <TableCell className={styles.tableCell}></TableCell>
                                        <TableCell className={styles.tableCell}></TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>{formattedRoutesPlanTotalTime}</div>
                                        </TableCell>
                                        <TableCell className={styles.tableCell}>
                                            <div className={styles.tableCellText}>
                                                {Math.ceil(sumOfAllPlanRoutesDistance)} km
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="45%" />
                                <col width="55%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>Dane klienta</TableCell>
                                    <TableCell className={styles.tableHeadCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Nazwa</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>{clientData.companyName}</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Model rozliczeniowy</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <TextField
                                                fullWidth
                                                select
                                                disabled={areFieldsDisabled}
                                                value={clientData.billingModel.id}
                                                onChange={(e) => onBillingModelChange(e.target.value)}
                                                inputProps={{ className: styles.input, style: { width: 50 } }}
                                            >
                                                {billingModels.map((billingModel) => (
                                                    <MenuItem key={billingModel.id} value={billingModel.id}>
                                                        {billingModel.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Stawka</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <SettlementsPanelPriceInput
                                                fullWidth
                                                disabled={areFieldsDisabled}
                                                value={clientData.rate}
                                                onChange={props.onRateChange}
                                                inputProps={{ className: styles.input }}
                                            />
                                            zł
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Stawka postoju</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                           <SettlementsPanelPriceInput
                                                fullWidth
                                                disabled={true}
                                                value={clientData.stopCost}
                                                onChange={props.onStopCostChange}
                                                inputProps={{ className: styles.input }}
                                            />
                                            zł
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            Rabat (max:{clientData.maxDiscountPercent}%)
                                        </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <TextField
                                                fullWidth
                                                disabled={areFieldsDisabled}
                                                value={clientData.discountPercent}
                                                onChange={onDiscountPercentChange}
                                                inputProps={{ className: styles.input }}
                                            />
                                            %
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Autostrady z pracownikiem</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <TextField
                                                fullWidth
                                                select
                                                disabled={true}
                                                value={clientData.highwayWithWorker}
                                                onChange={(e) => onHighwayWithWorkerChange(e.target.value)}
                                                inputProps={{ className: styles.input, style: { width: 50 } }}
                                            >
                                                {highwayWithWorkerOptions.map((option) => (
                                                    <MenuItem key={option.label} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Autostrady bez pracownika</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <TextField
                                                fullWidth
                                                select
                                                disabled={true }
                                                value={clientData.highwayWithoutWorker}
                                                onChange={(e) => onHighwayWithoutWorkerChange(e.target.value)}
                                                inputProps={{ className: styles.input, style: { width: 50 } }}
                                            >
                                                {highwayWithoutWorkerOptions.map((option) => (
                                                    <MenuItem key={option.label} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>Uwagi</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>{clientData.notes ?? "-----"}</div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                </>)}
                <Grid item xs={5}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <colgroup>
                                <col width="48%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeadCell}>Rozliczenie klienta</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Godz.</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Postój</TableCell>
                                    <TableCell className={styles.tableHeadCell}>KM</TableCell>
                                    <TableCell className={styles.tableHeadCell}>Autostrada</TableCell>
                                    <TableCell className={styles.tableHeadCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientDataWithStopTime.map((routesSettlement, index) => {
                                    const stageNo = index + 1;
                                    const isLastPath = !clientDataWithStopTime[index + 1];
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellRouteAddress}>
                                                    <div className={styles.tableCellText}>{stageNo}</div>
                                                    <TimelineItem
                                                        className={styles.timelineItem}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <TimelineSeparator>
                                                            <TimelineDot className={styles.timelineDot} />
                                                            {!isLastPath && <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        <TimelineContent
                                                            className={styles.timelineContent}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className={styles.tableCellText}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    fullWidth
                                                                    options={placeAddresses}
                                                                    noOptionsText="Nie znaleziono adresów"
                                                                    renderOption={(option) =>
                                                                        settlementsPanelSettlementHelper.getFormattedAddress(
                                                                            option
                                                                        )
                                                                    }
                                                                    getOptionLabel={(option) =>
                                                                        settlementsPanelSettlementHelper.getFormattedAddress(
                                                                            option
                                                                        )
                                                                    }
                                                                    value={routesSettlement.address}
                                                                    key={routesSettlement.address?.id}
                                                                    onChange={(_event, value) => {
                                                                        props.onStageAddressChange(value, index);
                                                                    }}
                                                                    disabled={areFieldsDisabled}
                                                                    style={{ width: "100%" }}
                                                                    renderInput={(params) => {
                                                                        return (
                                                                            <TextField
                                                                                {...params}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    onChange: (event) =>  {
                                                                                        let e = event;
                                                                                        e.target.value = event.target.value.trimStart()
                                                                                        params.inputProps.onChange(e)
                                                                                    },
                                                                                    className: styles.input,
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                    ListboxProps={{ style: { fontSize: 12 } }}
                                                                />
                                                            </div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <DateTimePicker
                                                        disabled={areFieldsDisabled}
                                                        autoOk={false}
                                                        format={"HH:mm"}
                                                        ampm={false}
                                                        value={routesSettlement.date}
                                                        inputProps={{ className: styles.input }}
                                                        onChange={(value) => props.onStageTimeChange(new Date(value.getTime() - routesSettlement.addedTime), index)}
                                                        fullWidth
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <div className={styles.tableCellText}>
                                                    <TextField
                                                        disabled={areFieldsDisabled}
                                                        fullWidth
                                                        value={routesSettlement.stopDuration}
                                                        onChange={(e) =>
                                                            onStageStopDurationChange(Number(e.target.value), index)
                                                        }
                                                        inputProps={{ className: styles.input }}
                                                    />
                                                    min
                                                </div>
                                            </TableCell>
                                            <TableCell className={[styles.tableCell]}>
                                                <div className={styles.tableCellText}>
                                                    <SettlementsPanelDistanceInput
                                                        value={routesSettlement.distance}
                                                        disabled={areFieldsDisabled}
                                                        onChange={(value) => props.onStageDistanceChange(value, index)}
                                                        inputProps={{ className: styles.input }}
                                                        fullWidth
                                                    />
                                                    km
                                                </div>
                                            </TableCell>
                                            <TableCell className={styles.tableCell}></TableCell>
                                            <TableCell className={styles.tableCell}>
                                                <TableCell className={styles.tableCell}>
                                                    <IconButton
                                                        size="small"
                                                        style={{
                                                            background: "#bdbdbd",
                                                            width: 16,
                                                            height: 16,
                                                            marginTop: 5,
                                                        }}
                                                        disabled={areFieldsDisabled}
                                                        onClick={() => onDeleteButtonClick(index)}
                                                    >
                                                        <Delete
                                                            fontSize="small"
                                                            style={{ color: "white", fontSize: `0.75rem` }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <div style={{ marginLeft: 20 }}>
                                    {!clientData.routesSettlements.length && (
                                        <span
                                            style={{
                                                marginRight: 5,
                                                paddingTop: 1,
                                                display: "block",
                                                float: "left",
                                            }}
                                        >
                                            Dodaj
                                        </span>
                                    )}
                                    <IconButton
                                        size="small"
                                        style={{ background: "#bdbdbd", width: 12, height: 12 }}
                                        onClick={onAddButtonClick}
                                        disabled={areFieldsDisabled || props.isDispatcher}
                                    >
                                        <Add fontSize="small" style={{ color: "white", fontSize: `0.75rem` }} />
                                    </IconButton>
                                </div>

                                <TableRow>
                                    <TableCell className={styles.tableCell}></TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>{formattedRoutesSettlementTotalTime}</div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {totalRoutesSettlementStopDuration} min{" "}
                                        </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            {sumOfAllSettlementRoutesDistance} km{" "}
                                        </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        <div className={styles.tableCellText}>
                                            <SettlementsPanelPriceInput
                                                fullWidth
                                                disabled={areFieldsDisabled || props.isDispatcher}
                                                value={clientData.highwayCost}
                                                onChange={(value) => props.onHighwayCostChange(value)}
                                                inputProps={{ className: styles.input }}
                                            />{" "}
                                            zł
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                { (isRoleType('DYSPOZYTOR') || isRoleType('OPERATOR_CONTRACTOR')) && props.selectedSettlement.clientData.billingId && (
                    <>
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={notFirstSettlement() || rejected}
                            style={{
                                width: 300,
                                height: "2em",
                                background: !rejected ? "#d11f3a" : "#888",
                                color: "#fff",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderColor: "#540d18",
                                borderRadius: "0",
                                transition: "0.3s",
                                marginBottom: "10px",
                                float: "right",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#eb2341",
                                },
                            }}
                            onClick={() => setStatus(2)}
                        >
                            Odrzuć rozliczenie
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={notFirstSettlement() || rejected}
                            style={{
                                width: 300,
                                height: "2em",
                                background: !accepted && !rejected ? "#5ab71b" : !rejected ? "#1DBBED" : "#888",
                                color: "#fff",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderColor: "none",
                                borderRadius: "0",
                                transition: "0.3s",
                                marginBottom: "10px",
                                float: "right",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#00a0e3",
                                },
                            }}
                            onClick={() => setStatus(accepted ? 0 : 1)}
                        >
                            {accepted ? "Odblokuj rozliczenie" : "Zaakceptuj rozliczenie"}
                        </Button>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default SettlementsPanelClientSettlement;
