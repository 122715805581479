import React, {memo} from "react";
import * as styles from "./orderLine.module.scss";
import OrderNotes from "./orderNotes/OrderNotes";
import AddIcon from "@material-ui/icons/Add";
import OrderWork from "./orderWork/OrderWork";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import {makeStyles, styled} from "@material-ui/core/styles";
import OrderHome from "./orderHome/OrderHome";
import OrderTrain from "./orderTrain/OrderTrain";
import OrderWorker from "./orderWorker/OrderWorker";
import CheckIcon from "@material-ui/icons/Check";
import RepeatIcon from "@material-ui/icons/Repeat";
import IconButton from "@material-ui/core/IconButton";
import {Autocomplete} from "@material-ui/lab";
import Tooltip from '@material-ui/core/Tooltip';
import OrderDistance from "./orderDistance/OrderDistance";
import OrderContractor from "./orderContractor/OrderContractor";
import OrderDispatcher from "./orderDispatcher/OrderDispatcher";

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const ArrowFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const AddFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const ConfirmFab = styled(IconButton)({
    backgroundColor: "#66D01D",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#5AB71B",
    },
});

const RemoveFab = styled(IconButton)({
    backgroundColor: "#575b55",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "black",
    },
});

const PreviousShiftFab = styled(IconButton)({
    backgroundColor: "#b4b4b4",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#777777",
    },
});
const ReturnFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

export const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        paddingRight: "0px !important"
    }
});

const Direction = (props) => {
    if (props.direction) {
        return <OrderHome {...props} />
    } else {
        return <OrderWork {...props} />
    }
}

const OrderLine = (props) => {
    let classes =
        props.data.isProposition && props.data.isReturn
            ? "--isReturn"
            : !props.data.isProposition && props.data.isReturn
            ? "--subOrder"
            : props.data.isProposition && props.data.isPropositionFromDestination
                ? "--propositionFromDestination"
                : !props.data.isProposition && props.data.isPropositionFromDestination
                    ? "--subOrder"
                    : "";

    const legend = props.data.isReturn
        ? "Kurs powrotny"
        : props.data.isPropositionFromDestination
            ? "Odwiezienie poprzedniej zmiany"
            : "Zamówienie";

    const OrderConfirm = (props) => {
        return (
            <div
                className={styles["orderLine__buttonWrapper" + classes + "--confirmButtonWrapper"]}
            >
                <ConfirmFab
                    size="small"
                    className={styles["orderLine__buttonWrapper" + classes + "--confirmButtonWrapper--confirmButton"]}
                    onClick={props.confirmOrder}
                    data-id={`confirmOrderButton-groupIndex_${props.groupIndex}`}
                >
                    <CheckIcon/>
                </ConfirmFab>
                <p
                    className={styles["orderLine__buttonWrapper" + classes + "--confirmButtonWrapper--description"]}
                >
                    Zatwierdź zamówienie
                </p>
            </div>
        );
    };

    const OrderAccept = (props) => {
        return (
            <div
                className={styles["orderLine__buttonWrapper" + classes + "--acceptButtonWrapper"]}
            >
                <AddFab
                    size="small"
                    className={styles["orderLine__buttonWrapper" + classes + "--acceptButtonWrapper--acceptButton"]}
                    onClick={() => props.acceptOrder()}
                    data-id={`addOrderButton-groupIndex_${props.groupIndex}`}
                >
                    <AddIcon/>
                </AddFab>
                <p
                    className={styles["orderLine__buttonWrapper" + classes + "--acceptButtonWrapper--description"]}
                >
                    Dodaj zamówienie
                </p>
            </div>
        );
    };

    const OrderRemove = (props) => {
        const legend = props.isReturn
            ? "Usuń Kurs powrotny"
            : props.isPropositionFromDestination
                ? "Usuń Odwiezienie poprzedniej zmiany"
                : "Usuń Zamówienie";
        return (
            <div
                className={styles["orderLine__buttonWrapper" + classes + "--removeButtonWrapper"]}
            >
                <RemoveFab
                    size="small"
                    disabled={
                        !props.isProposition &&
                        !props.isReturn &&
                        !props.isPropositionFromDestination &&
                        props.groups.length === 1
                    }
                    className={styles["orderLine__buttonWrapper" + classes + "--removeButtonWrapper--removeButton"]}
                    onClick={() => {
                        props.removeOrder(props.groupIndex, props.index);
                    }}
                    data-id={`removeOrderButton-groupIndex_${props.groupIndex}`}
                >
                    <ClearIcon/>
                </RemoveFab>
                <p
                    className={styles["orderLine__buttonWrapper" + classes + "--removeButtonWrapper--description"]}
                >
                    {legend}
                </p>
            </div>
        );
    };

    const defaultOrder = props.type === 'edit' && props.index === 0 ? '--default' : '';

    return (
        <fieldset className={styles["orderLine__wrapper" + classes + defaultOrder]}>
            <div className={styles["orderLine__wrapper" + classes + "--legend"]}>
                <div className={styles["orderLine__wrapper" + classes + "--legend--item"]}>
                    <p className={styles["orderLine__wrapper" + classes + "--legend--item--title"]}>
                        {legend}
                    </p>
                    {props.type !== 'edit' && (
                        <>
                            <OrderConfirm confirmOrder={() => props.confirmOrder(props.groupIndex, props.index)}/>
                            <OrderAccept acceptOrder={props.acceptOrderGroup}/>
                            <OrderRemove
                                index={props.index}
                                groups={props.groups}
                                groupIndex={props.groupIndex}
                                removeOrder={props.removeOrderGroup}
                                isProposition={props.data.isProposition}
                                isReturn={props.data.isReturn}
                                isPropositionFromDestination={
                                    props.data.isPropositionFromDestination
                                }
                            />
                        </>
                    )}
                </div>
                <div className={styles["orderLine__wrapper" + classes + "--legend--item"]}>
                    <OrderDistance
                        setOrderData={props.setOrderData}
                        data={props.data}
                        groupIndex={props.groupIndex}
                        orderIndex={props.index}
                    />
                </div>

            </div>

            <div className={styles['orderLine__wrapper--operator']}>
                {props.entity === 'operator' && (
                    <>
                        <div className={styles['orderLine__wrapper--operator--item']}>
                            <OrderContractor
                                getDataDispatchers={props.getDataDispatchers}
                                setOrderData={props.setOrderData}
                                order={props.data}
                                contractors={props.contractors}
                                groupIndex={props.groupIndex}
                                orderIndex={props.index}
                            />
                        </div>
                        <div className={styles['orderLine__wrapper--operator--item']}>
                            <OrderDispatcher
                                dispatchers={props.dispatchers}
                                setOrderData={props.setOrderData}
                                groupIndex={props.groupIndex}
                                orderIndex={props.index}
                                getDataDispatchers={props.getDataDispatchers}
                                order={props.data}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className={styles["orderLine__wrapper--form"]}>
                <OrderWorker
                    handleValidate={props.handleValidate}
                    index={props.index}
                    type={props.type}
                    contractors={props.contractors}
                    undoChanges={props.undoChanges}
                    disableWorkers={props.disableWorkers}
                    addNewWorker={props.addNewWorker}
                    groupIndex={props.groupIndex}
                    pickup={props.data.pickup}
                    options={props.data.driver ? props.data.driver.user.adres : []}
                    setOrderData={props.setOrderData}
                    lastLine={props.lastLine}
                    worker={props.data.driver}
                    workers={props.workers}
                    driversUpdate={props.driversUpdate}
                    setWorkerData={props.setWorkerData}
                    data={props.data}
                    drivers={props.drivers}
                    removePerson={props.removePerson}
                    addPerson={props.addPerson}
                    errorWorker={props.errorWorker}
                />

                <Direction
                    addWorkerAddress={props.addWorkerAddress}
                    addWorkAddress={props.addWorkAddress}
                    handleValidate={props.handleValidate}
                    validateDate={props.validateDate}
                    index={props.index}
                    groupIndex={props.groupIndex}
                    stations={props.stations}
                    pickup={props.data.pickup}
                    options={props.data.driver ? props.data.driver.user.adres : []}
                    setOrderData={props.setOrderData}
                    direction={props.data.direction.value}
                    destination={props.data.destination}
                    lastLine={props.lastLine}
                    type={props.type}
                    worker={props.data.driver}
                    undoChanges={props.undoChanges}
                    workers={props.workers}
                    setWorkerData={props.setWorkerData}
                    data={props.data}
                    drivers={props.drivers}
                    removePerson={props.removePerson}
                    addPerson={props.addPerson}
                    errorWorker={props.errorWorker}
                    timeWork={props.data.timeWork}
                    trainDestination={props.data.trainDestination}
                    trainId={props.data.trainId}
                />
                <div className={styles["orderLine__buttonWrapper--direction"]}>
                    <BootstrapTooltip title="Zmień kierunek" data-id={`changeDirectionButton-groupIndex_${props.groupIndex}`} >
                        <ArrowFab
                            size="small"
                            disabled={props.data.isProposition || (props.data.isPropositionFromDestination || props.data.isReturn)}
                            onClick={() =>
                                props.setOrderData(
                                    props.groupIndex,
                                    props.index,
                                    "direction",
                                    !props.data.direction.value,
                                    props.handleValidate
                                )
                            }
                        >
                            <SwapHorizIcon/>
                        </ArrowFab>
                    </BootstrapTooltip>
                </div>
                <Direction
                    addWorkerAddress={props.addWorkerAddress}
                    addWorkAddress={props.addWorkAddress}
                    handleValidate={props.handleValidate}
                    validateDate={props.validateDate}
                    index={props.index}
                    groupIndex={props.groupIndex}
                    stations={props.stations}
                    pickup={props.data.pickup}
                    type={props.type}
                    undoChanges={props.undoChanges}
                    direction={!props.data.direction.value}
                    options={props.data.driver ? props.data.driver.user.adres : []}
                    setOrderData={props.setOrderData}
                    destination={props.data.destination}
                    lastLine={props.lastLine}
                    worker={props.data.driver}
                    workers={props.workers}
                    setWorkerData={props.setWorkerData}
                    data={props.data}
                    drivers={props.drivers}
                    removePerson={props.removePerson}
                    addPerson={props.addPerson}
                    errorWorker={props.errorWorker}
                    timeWork={props.data.timeWork}
                    trainDestination={props.data.trainDestination}
                    trainId={props.data.trainId}
                />

                <OrderTrain
                    handleValidate={props.handleValidate}
                    index={props.index}
                    type={props.type}
                    undoChanges={props.undoChanges}
                    groupIndex={props.groupIndex}
                    setOrderData={props.setOrderData}
                    stations={props.stations}
                    destination={props.data.destination}
                    time={props.data.time}
                    trainDestination={props.data.trainDestination}
                    trainId={props.data.trainId}
                    lastLine={props.lastLine}
                />
                <OrderNotes
                    groupIndex={props.groupIndex}
                    inputs={props.inputs}
                    type={props.type}
                    undoChanges={props.undoChanges}
                    handleValidate={props.handleValidate}
                    index={props.index}
                    notes={props.data.notes}
                    orderNo={props.data.orderNo}
                    setOrderData={props.setOrderData}
                    lastLine={props.lastLine}
                />
            </div>

            {props.type !== 'edit' && (
                <div className={styles["orderLine__buttonWrapper"]}>
                    {props.index === 0 && (
                        <div className={styles["orderLine__buttonWrapper--returnButtonWrapper"]}>
                            <ReturnFab
                                size="small"
                                className={styles["orderLine__buttonWrapper--returnButtonWrapper--returnButton"]}
                                onClick={() => {
                                    props.addReturnOrder(props.groupIndex);
                                }}
                            >
                                <SwapHorizIcon/>
                            </ReturnFab>
                            <p className={styles["orderLine__buttonWrapper--returnButtonWrapper--description"]}>
                                Zamów kurs powrotny
                            </p>
                        </div>
                    )}
                    <div className={styles["orderLine__buttonWrapper--previousShiftButtonWrapper"]}>
                        <PreviousShiftFab
                            size="small"
                            className={styles["orderLine__buttonWrapper--previousShiftButtonWrapper--previousShiftButton"]}
                            onClick={() => {
                                props.addProposedOrderLine(props.groupIndex);
                            }}
                        >
                            <RepeatIcon/>
                        </PreviousShiftFab>
                        <p className={styles["orderLine__buttonWrapper--previousShiftButtonWrapper--description"]}>
                            Zamów Odwiezienie poprzedniej zmiany
                        </p>
                    </div>
                </div>
            )}
        </fieldset>
    );
};

export default memo(OrderLine, (prevProps, nextProps) => {
    return prevProps.data === nextProps.data &&
        prevProps.stations === nextProps.stations &&
        prevProps.drivers === nextProps.drivers &&
        prevProps.errorWorker === nextProps.errorWorker &&
        prevProps.contractors.length === nextProps.contractors.length;
});

