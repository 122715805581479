import React, {useState} from "react";
import styles from "./activityLog.module.scss";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {makeStyles} from "@material-ui/core/styles";
import {format, fromUnixTime} from "date-fns";
import {pl} from "date-fns/locale";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HttpClient from "../HttpClient";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip children={props.children} arrow classes={classes} {...props} />;
}

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    timeRoot: {
        padding: "6px 0",
        margin: 0,
        "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
        },
    },
    timeDot: {
        marginTop: 0,
        marginBottom: 0,
        borderColor: "#1DBBED",
        borderWidth: "3px"
    },
    timeConnector: {
        backgroundColor: "#1DBBED",
    },
    timeLineItem: {
        paddingLeft: "20px"
    },
    //Accordion
    root: {
        width: '100%',
        "& .MuiAccordionSummary-root": {
            padding: "0 5px 0 16px"
        },
        "& .MuiAccordionSummary-content": {
            flexDirection: "column",
        },
        "& .MuiAccordion-rounded": {
            borderRadius: 0,
        },
        "& .MuiAccordionSummary-expandIcon": {
            alignSelf: "flex-end"
        },
        "& .MuiAccordionDetails-root": {
            display: "block"
        }
    },
    heading: {
        fontSize: "14px",

    },
    secondaryHeading: {
        fontSize: "14px",
    },
    icon: {
        color: "#1DBBED",
        "&:hover": {
            color: "#00A0E3"
        }
    },
    iconButton: {
        padding: "8px",
    }
}));

export default function ActivityLog(props) {
    const classes = useStyles();
    const {activityTable} = props;
    const currentDay = new Date().getDay();
    const [expanded, setExpanded] = useState(false);
    const [singleCourseDetails, setSingleCourseDetails] = useState(null);
    const [singleWorkerDetails, setSingleWorkerDetails] = useState(null);
    const [singleHolidayDetails, setSingleHolidayDetails] = useState(null);
    const [singleConversationDetails, setSingleConversationDetails] = useState(null);

    const [singleEntity, setSingleEntity] = useState(null);

    const handleExpand = (panel, id, entity) => async (event, isExpanded) => {
        const singleId = id
        const request = HttpClient.createRequest();
        const apiWorker = `api/pracownik/${singleId}`;
        const apiCourse = `api/zlecenie/details/${singleId}`;
        const apiDayoff = `/api/day-off/${singleId}`;
        const apiConversation = `/api/message/${singleId}`

        if (entity.includes('Zlecenie')) {
            setSingleEntity('zlecenie')
        } else if (entity.includes("Holiday")) {
            setSingleEntity('urlop')
        } else if (entity.includes("Conversation")) {
            setSingleEntity('conversation')
        } else if (entity.includes("Pracownik")) {
            setSingleEntity('pracownik')
        } else if (entity.includes("Settlement")) {
            setSingleEntity('rozliczenia')
        } else setSingleEntity('')

        try {
            const response = await request.get(entity.includes('Zlecenie') ? apiCourse : entity.includes('Holiday') ? apiDayoff : entity.includes('Pracownik') ? apiWorker : entity.includes('Conversation') ? apiConversation : '', {}, true);

            entity.includes('Zlecenie') ? setSingleCourseDetails(response.data)
                : entity.includes('Pracownik') ? setSingleWorkerDetails(response.data.worker)
                : entity.includes('Holiday') ? setSingleHolidayDetails(response.data)
                    : entity.includes('Conversation') && setSingleConversationDetails(response.data)

        } catch (e) {
        }
        setExpanded(isExpanded ? panel : false);
    };

    return (

        <div className={styles["activityLog"]}>
            <SimpleBar style={{maxHeight: "85vh"}}
                       className={classes.simplebar}>
                <Timeline align="left" className={classes.timeRoot}>
                    {activityTable ? activityTable.map((item, index) => {
                        let itemDay = fromUnixTime(item.data).getDay();
                        let type;
                        let status;
                        let entity = '';

                        switch (item.type) {
                            case 'edit':
                                type = "Edycja";
                                break;
                            case 'create':
                                item.entity.includes('Conversation') ? type = "Uwagi do rozliczeń" : type = "Utworzenie"
                                break;
                            case 'cancel':
                                type = "Anulacja";
                                break;
                            case 'delete':
                                type = "Usunięcie";
                                break;
                            case 'status change':
                                type = "Zmiana statusu";
                                break;
                            default:
                                type = "Wprowadzono zmiany"
                        }

                        if (item.entity.includes('Zlecenie')) {
                            entity = "zlecenia";
                        } else if (item.entity.includes('Pracownik')) {
                            entity = "pracownika";
                        } else if (item.entity.includes("Holiday")) {
                            entity = "urlopu"
                        } else if (item.entity.includes("Kilometrowka")) {
                            entity = "kilometrówki"
                        } else if (item.entity.includes("Settlement")) {
                            entity = "rozliczenia"
                        }

                        if (item.type === 'status change') {
                            switch (item.status) {
                                case 'frozen':
                                    status = "Kurs potwierdzony";
                                    break;
                                case 'pending':
                                    status = "Kurs do potwierdzenia";
                                    break;
                                case 'deny':
                                    status = "Kurs odrzucony";
                                    break;
                                default:
                                    status = "-"
                            }
                        }

                        const content = (
                            <>
                                <p className={styles["activityLog__wrapper--item--date"]}>
                                    {`${currentDay === itemDay ? `dzisiaj ${format(fromUnixTime(item.data), "HH:mm", {locale: pl})}` :
                                        `${format(fromUnixTime(item.data), "d LLLL yyyy, HH:mm", {locale: pl})}`}`}</p>
                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                    Autor:
                                </p>
                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                    {`${item.user.name} ${item.user.surname}`}</p>
                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                    Firma:
                                </p>
                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                    {item.user.contractor}
                                </p>

                                <div className={styles["activityLog__wrapper--item--content"]}>
                                    <p>{`${type} ${entity}`}</p>
                                </div>
                                {entity === "zlecenia" ?
                                    <>
                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                            ID:
                                        </p>
                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                            {item.ownId}
                                        </p>
                                    </>

                                    : entity === "pracownika" ?
                                        <>
                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                Pracownik:
                                            </p>
                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                {`${item.worker?.name} ${item.worker?.surname}`}
                                            </p>
                                        </>
                                        : null}

                                {entity === "zlecenia" && item.type === 'status change' ?
                                    <>
                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                            Status:
                                        </p>
                                        <p
                                            className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}
                                            style={{color: "#FF650F", fontWeight: "bold", fontSize: "14px"}}>
                                            {status}
                                        </p>
                                    </>
                                    : null}

                            </>
                        )

                        return (
                            <div key={index} className={styles["activityLog__wrapper"]}>
                                <TimelineItem key={index} className={classes.timeLineItem}>
                                    <TimelineSeparator>
                                        <TimelineDot className={classes.timeDot} variant="outlined"/>
                                        <TimelineConnector className={classes.timeConnector}/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <div className={classes.root}>
                                            <Accordion expanded={expanded === `panel${index}`}
                                                       onChange={handleExpand(`panel${index}`, item.id, item.entity)}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <BootstrapTooltip title="Szczegóły">
                                                        <span>
                                                            <IconButton className={classes.iconButton}>
                                                                <ExpandMoreIcon className={classes.icon}/>
                                                             </IconButton>
                                                        </span>
                                                        </BootstrapTooltip>
                                                    }
                                                    aria-controls={`panel${index}`}
                                                    id={`panel${index}`}
                                                >
                                                    {content}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {singleCourseDetails && singleEntity === "zlecenie" ?
                                                        <>
                                                            <div
                                                                className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--title"]}>Adres
                                                                    odbioru</p>
                                                                <div
                                                                    className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                    {singleCourseDetails.from ? (
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                Adres:
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                {`${singleCourseDetails.from.street ? singleCourseDetails.from.street : ""} ${
                                                                                    singleCourseDetails.from.no ? singleCourseDetails.from.no : ""} ${
                                                                                    singleCourseDetails.from.flat ? `/${singleCourseDetails.from.flat}` : ""
                                                                                } ${
                                                                                    singleCourseDetails.from.zipCode
                                                                                        ? singleCourseDetails.from.zipCode
                                                                                        : ""
                                                                                } ${
                                                                                    singleCourseDetails.from.city
                                                                                        ? singleCourseDetails.from.city
                                                                                        : "-"
                                                                                }`}
                                                                            </p>
                                                                        </div>
                                                                    ) : singleCourseDetails.worker.map((item, index) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                    Imię i nazwisko
                                                                                </p>
                                                                                <span
                                                                                    className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                            {`${item.firstname ? item.firstname : " "} ${
                                                                                item.surname ? item.surname : " "
                                                                            }`}
                                                                        </span>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                    Adres
                                                                                </p>
                                                                                <span
                                                                                    className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                            {`${item.street ? item.street : ""} ${
                                                                                item.no ? item.no : " "
                                                                            } ${item.flat ? `/${item.flat}` : ""} ${item.zipCode ? item.zipCode : ""} ${
                                                                                item.city ? item.city : ""
                                                                            }`}
                                                                        </span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--title"]}>Adres
                                                                    docelowy</p>
                                                                <div
                                                                    className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                    {singleCourseDetails.to ? (
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                Adres
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                {`${
                                                                                    singleCourseDetails.to.street
                                                                                        ? singleCourseDetails.to.street
                                                                                        : ""
                                                                                } ${
                                                                                    singleCourseDetails.to.no
                                                                                        ? singleCourseDetails.to.no
                                                                                        : ""
                                                                                } ${
                                                                                    singleCourseDetails.to.flat
                                                                                        ? `/${singleCourseDetails.to.flat}`
                                                                                        : ""
                                                                                } ${
                                                                                    singleCourseDetails.to.zipCode
                                                                                        ? singleCourseDetails.to.zipCode
                                                                                        : ""
                                                                                } ${
                                                                                    singleCourseDetails.to.city
                                                                                        ? singleCourseDetails.to.city
                                                                                        : ""
                                                                                }`}
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        singleCourseDetails.worker.map((item, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}
                                                                                >
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                        Imię i nazwisko
                                                                                    </p>
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                        {`${item.firstname ? item.firstname : ""} ${
                                                                                            item.surname ? item.surname : ""
                                                                                        }`}
                                                                                    </p>
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                        Adres
                                                                                    </p>
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                        {`${item.street ? item.street : ""} ${
                                                                                            item.no ? item.no : ""
                                                                                        } ${item.flat ? `/${item.flat}` : ""} ${item.zipCode ? item.zipCode : ""} ${
                                                                                            item.city ? item.city : ""
                                                                                        }`}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--title"]}>Czasy</p>
                                                                <div
                                                                    className={styles["activityLog--historyItem--wrapper--time"]}>
                                                                    <div
                                                                        className={styles["activityLog--historyItem--wrapper--time--single"]}>
                                                                        {singleCourseDetails.from || singleCourseDetails.isWorkAddress ? (
                                                                            <div
                                                                                className={styles["activityLog--historyItem--wrapper--time--from--toWork"]}></div>
                                                                        ) : (
                                                                            <div
                                                                                className={styles["activityLog--historyItem--wrapper--time--from--toHome"]}></div>
                                                                        )}

                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--time--single--data"]}>
                                                                            {singleCourseDetails.isDestinationTime ? "---" : format(fromUnixTime(singleCourseDetails.destinationTime), "d LLLL yyyy, HH:mm", {locale: pl})}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={styles["activityLog--historyItem--wrapper--time--single"]}>
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--time--single--info"]}>
                                                                            {singleCourseDetails.to ? (
                                                                                <div
                                                                                    className={styles["activityLog--historyItem--wrapper--time--to--toWork"]}></div>
                                                                            ) : (
                                                                                <div
                                                                                    className={styles["activityLog--historyItem--wrapper--time--to--toHome"]}></div>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--time--single--data"]}>
                                                                            {singleCourseDetails.isDestinationTime ? format(fromUnixTime(singleCourseDetails.destinationTime), "d LLLL yyyy, HH:mm", {locale: pl}) : "---"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : singleWorkerDetails && singleEntity === "pracownik" ?
                                                            <div
                                                                className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                <div
                                                                    className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                    <div
                                                                        className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                            ID pracownika:
                                                                        </p>
                                                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                            {singleWorkerDetails.id ? singleWorkerDetails.id : ""}
                                                                        </p>
                                                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                            Telefon:
                                                                        </p>
                                                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                            {singleWorkerDetails.user.privatePhoneNumber ? singleWorkerDetails.user.privatePhoneNumber : ""}
                                                                        </p>
                                                                        <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                            Adresy:
                                                                        </p>
                                                                        {singleWorkerDetails.user.adres.map((item, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}
                                                                                >
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                        {`${item.street ? item.street : ""} ${
                                                                                            item.no ? item.no : ""
                                                                                        } ${item.flat ? `/${item.flat}` : ""} ${item.zipCode ? item.zipCode : ""} ${
                                                                                            item.city ? item.city : ""
                                                                                        }`}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : singleHolidayDetails && singleHolidayDetails.error !== 1 && singleEntity === "urlop" ?
                                                                <div
                                                                    className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                    <div
                                                                        className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                Pracownik:
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                {`${singleHolidayDetails.user?.name ? singleHolidayDetails.user.name : ""} ${
                                                                                    singleHolidayDetails.user?.surName ? singleHolidayDetails.user.surName : ""
                                                                                }`}
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                Początek urlopu:
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                {singleHolidayDetails.startAt ? format(fromUnixTime(singleHolidayDetails.startAt), "d LLLL yyyy, HH:mm", {locale: pl}) : ""}
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                Koniec urlopu:
                                                                            </p>
                                                                            <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                {singleHolidayDetails.finishAt ? format(fromUnixTime(singleHolidayDetails.finishAt), "d LLLL yyyy, HH:mm", {locale: pl}) : ""}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : singleConversationDetails && singleEntity === "conversation" ?
                                                                    <div
                                                                        className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                            <div
                                                                                className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                    ID rozliczenia:
                                                                                </p>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                    {singleConversationDetails.orderId ? singleConversationDetails.orderId : ""}
                                                                                </p>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                    Treść:
                                                                                </p>
                                                                                <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                    {singleConversationDetails.message ? singleConversationDetails.message : ""}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : singleEntity === "rozliczenia" ?
                                                                        <div
                                                                            className={styles["activityLog--historyItem--wrapper--history"]}>
                                                                            <div
                                                                                className={styles["activityLog--historyItem--wrapper--history--details"]}>
                                                                                <div
                                                                                    className={styles["activityLog--historyItem--wrapper--history--details--singleColumn"]}>
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--info"]}>
                                                                                        ID rozliczenia:
                                                                                    </p>
                                                                                    <p className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                                        {item.ownId ? item.ownId : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : <Typography>
                                                                        <span
                                                                            className={styles["activityLog--historyItem--wrapper--history--details--singleColumn--data"]}>
                                                                            Brak szczegółów.
                                                                        </span>
                                                                        </Typography>}
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>
                            </div>
                        )
                    }) : null}
                </Timeline>
            </SimpleBar>
        </div>


    );
}
