import React, {memo} from "react";
import styles from "./formLine.module.scss";
import {Fab, IconButton} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import {makeStyles, styled} from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import FormWork from "./formWork/FormWork";
import FormHome from "./formHome/FormHome";
import FormWorker from "./formWorker/FormWorker";
import FormDistance from "./formDistance/FormDistance";
import FormMap from "./formMap/FormMap";
import FormCompanyInfo from "./formCompanyInfo/FormCompanyInfo";
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import FormNote from "./formNotes/FormNotes";

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "#313131",
    },
    tooltip: {
        backgroundColor: "#313131",
        color: "white",
        fontSize: "13px",
        letterSpacing: "1px",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const ArrowFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const ConfirmFab = styled(IconButton)({
    backgroundColor: "#66D01D",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#5AB71B",
    },
});
const AddFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const RemoveFab = styled(IconButton)({
    backgroundColor: "#575b55",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "black",
    },
});

const ReturnFab = styled(IconButton)({
    backgroundColor: "#1DBBED",
    color: "white",
    transition: "0.3s",
    "&:hover": {
        backgroundColor: "#00a0e3",
    },
});

const Direction = (props) => {
    if (props.direction) {
        return <FormHome {...props} />
    } else {
        return <FormWork {...props} />
    }
}

const FormLine = (props) => {
    let classes =
        props.data.isProposition && props.data.isReturn
            ? "__return"
            : !props.data.isProposition && props.data.isReturn
            ? "__subOrder" : props.data.isProposition && !props.data.isReturn
                ? "__proposition" : "__order";

    const legend = props.data.isReturn
        ? "Kurs powrotny"
        : props.data.isProposition
            ? "Wpisz kolejną kilometrówkę"
            : "Wpisz kilometrówkę";

    const OrderConfirm = (props) => {
        return (
            <div className={styles["formLine" + classes + "--formWrapper--form--buttonWrapper--buttonGroup--confirmButton"]}>
                <BootstrapTooltip title="Potwierdź">
                    <ConfirmFab
                        size="small"
                        onClick={props.confirmOrder}
                    >
                        <CheckIcon/>
                    </ConfirmFab>
                </BootstrapTooltip>
            </div>
        );
    };

    const OrderAccept = (props) => {
        return (
            <div className={styles["formLine" +classes + "--formWrapper--form--buttonWrapper--buttonGroup--acceptButton"]}>
                <BootstrapTooltip title="Dodaj">
                    <AddFab
                        size="small"
                        onClick={() => props.acceptOrder()}
                    >
                        <AddIcon/>
                    </AddFab>
                </BootstrapTooltip>
            </div>
        );
    };

    const OrderRemove = (props) => {
        return (
            <div className={styles["formLine" + classes + "--formWrapper--form--buttonWrapper--buttonGroup--removeButton"]}>
                <BootstrapTooltip title="Usuń">
                    <RemoveFab
                        size="small"
                        disabled={
                            !props.isProposition &&
                            !props.isReturn &&
                            !props.isPropositionFromDestination &&
                            props.groups.length === 1
                        }

                        onClick={() => {
                            props.removeOrder(props.groupIndex, props.index);
                        }}
                    >
                        <ClearIcon/>
                    </RemoveFab>
                </BootstrapTooltip>
            </div>
        );
    };

    return (
        <fieldset className={styles["formLine" + classes]}>
            <div className={styles["formLine" + classes + "--formWrapper"]}>
                <div className={styles["formLine" + classes + "--formWrapper--form"]}>
                    <p className={styles["formLine" + classes + "--formWrapper--form--title"]}>
                        {legend}
                    </p>
                    <div className={styles["formLine" + classes + "--formWrapper--form--single"]}>
                        <FormWorker
                            entity={props.entity}
                            handleValidate={props.handleValidate}
                            index={props.index}
                            addNewWorker={props.addNewWorker}
                            groupIndex={props.groupIndex}
                            pickup={props.data.pickup}
                            options={props.data.driver ? props.data.driver.user.adres : []}
                            setOrderData={props.setOrderData}
                            lastLine={props.lastLine}
                            worker={props.data.driver}
                            workers={props.workers}
                            driversUpdate={props.driversUpdate}
                            setWorkerData={props.setWorkerData}
                            data={props.data}
                            drivers={props.drivers}
                            removePerson={props.removePerson}
                            addPerson={props.addPerson}
                            errorWorker={props.errorWorker}
                            addWorkAddress={props.addWorkAddress}
                            stations={props.stations}
                            destination={props.data.destination}
                            timeWork={props.data.timeWork}
                            trainDestination={props.data.trainDestination}
                            trainId={props.data.trainId}
                        />
                    </div>
                    <div className={styles["formLine" + classes + "--formWrapper--form--single"]}>
                        <Direction
                            addWorkerAddress={props.addWorkerAddress}
                            addWorkAddress={props.addWorkAddress}
                            handleValidate={props.handleValidate}
                            validateDate={props.validateDate}
                            index={props.index}
                            groupIndex={props.groupIndex}
                            stations={props.stations}
                            pickup={props.data.pickup}
                            options={props.data.driver ? props.data.driver.user.adres : []}
                            setOrderData={props.setOrderData}
                            direction={props.data.direction.value}
                            destination={props.data.destination}
                            lastLine={props.lastLine}
                            worker={props.data.driver}
                            workers={props.workers}
                            setWorkerData={props.setWorkerData}
                            data={props.data}
                            drivers={props.drivers}
                            removePerson={props.removePerson}
                            addPerson={props.addPerson}
                            errorWorker={props.errorWorker}
                            timeWork={props.data.timeWork}
                            trainDestination={props.data.trainDestination}
                            trainId={props.data.trainId}
                        />
                        <div
                            className={styles["formLine" + classes + "--formWrapper--form--single--direction"]}>
                            <BootstrapTooltip title="Zmień kierunek">
                                <ArrowFab
                                    size="small"
                                    onClick={() =>
                                        props.setOrderData(
                                            props.groupIndex,
                                            props.index,
                                            "direction",
                                            !props.data.direction.value,
                                            props.handleValidate
                                        )
                                    }
                                >
                                    <SwapHorizIcon/>
                                </ArrowFab>
                            </BootstrapTooltip>
                        </div>
                        <Direction
                            addWorkerAddress={props.addWorkerAddress}
                            addWorkAddress={props.addWorkAddress}
                            handleValidate={props.handleValidate}
                            validateDate={props.validateDate}
                            index={props.index}
                            groupIndex={props.groupIndex}
                            stations={props.stations}
                            pickup={props.data.pickup}
                            direction={!props.data.direction.value}
                            options={props.data.driver ? props.data.driver.user.adres : []}
                            setOrderData={props.setOrderData}
                            destination={props.data.destination}
                            lastLine={props.lastLine}
                            worker={props.data.driver}
                            workers={props.workers}
                            setWorkerData={props.setWorkerData}
                            data={props.data}
                            drivers={props.drivers}
                            removePerson={props.removePerson}
                            addPerson={props.addPerson}
                            errorWorker={props.errorWorker}
                            timeWork={props.data.timeWork}
                            trainDestination={props.data.trainDestination}
                            trainId={props.data.trainId}
                        />
                    </div>
                    <div className={styles["formLine" + classes + "--formWrapper--form--single"]}>
                        <FormCompanyInfo
                            addKontrahent={props.addKontrahent}
                            kontrahents={props.kontrahents}
                            isProposition={props.data.isProposition}
                            kontrahent={props.data.kontrahent}
                            cardNo={props.data.cardNo}
                            handleValidate={props.handleValidate}
                            index={props.index}
                            groupIndex={props.groupIndex}
                            setOrderData={props.setOrderData}
                        />
                    </div>
                    <div className={styles["formLine" + classes + "--formWrapper--form--single"]}>
                        <FormDistance
                            handleValidate={props.handleValidate}
                            index={props.index}
                            isProposition={props.data.isProposition}
                            orderNo={props.data.orderNo}
                            manualKm={props.data.manualKm}
                            mapKm={props.data.mapKm}
                            groupIndex={props.groupIndex}
                            setOrderData={props.setOrderData}
                            stations={props.stations}
                            destination={props.data.destination}
                            time={props.data.time}
                            trainDestination={props.data.trainDestination}
                            trainId={props.data.trainId}
                            lastLine={props.lastLine}
                        />
                    </div>
                    <div className={styles["formLine" + classes + "--formWrapper--form--single"]}>
                        <FormNote
                            note={props.data.note}
                            handleValidate={props.handleValidate}
                            index={props.index}
                            isProposition={props.data.isProposition}
                            groupIndex={props.groupIndex}
                            setOrderData={props.setOrderData}
                            lastLine={props.lastLine}
                        />
                    </div>
                    <div className={styles["formLine" +classes + "--formWrapper--form--buttonWrapper"]}>
                        <div className={styles["formLine" + classes + "--formWrapper--form--buttonWrapper--returnButtonWrapper"]}>
                            <ReturnFab
                                disabled={(!props.data.direction.value && !props.data.isWorkAddress.value) || props.index !== props.orders.length - 1}
                                size="small"
                                className={styles["formLine" + classes + "--formWrapper--form--buttonWrapper--returnButtonWrapper--returnButton"]}
                                onClick={() => {
                                    props.addReturnOrder(props.groupIndex);
                                }}
                            >
                                <KeyboardReturnIcon/>
                            </ReturnFab>
                            <p className={styles["formLine" + classes + "--formWrapper--form--buttonWrapper--returnButtonWrapper--description"]}>
                                Zamów kurs powrotny
                            </p>
                        </div>
                        <div className={styles["formLine"  + classes + "--formWrapper--form--buttonWrapper--buttonGroup"]}>
                            <OrderRemove
                                index={props.index}
                                groups={props.groups}
                                groupIndex={props.groupIndex}
                                removeOrder={props.removeOrderGroup}
                                isProposition={props.data.isProposition}
                                isReturn={props.data.isReturn}
                                isPropositionFromDestination={
                                    props.data.isPropositionFromDestination
                                }
                            />
                            <OrderConfirm confirmOrder={() => props.confirmOrder(props.groupIndex, props.index)}/>
                            <OrderAccept acceptOrder={props.acceptOrderGroup}/>
                        </div>
                    </div>
                </div>
                <div className={styles["formLine" + classes + "--formWrapper--formMap"]}>
                    <p className={styles["formLine" + classes + "--formWrapper--formMap--title"]}>
                        Mapa poglądowa
                    </p>
                    <FormMap
                        id={`grupa: ${props.groupIndex}, podgrupa:${props.index}`}
                        data={props.data}
                        handleValidate={props.handleValidate}
                        index={props.index}
                        orderNo={props.data.orderNo}
                        km={props.data.km}
                        groupIndex={props.groupIndex}
                        setOrderData={props.setOrderData}
                        stations={props.stations}
                        destination={props.data.destination}
                        time={props.data.time}
                        trainDestination={props.data.trainDestination}
                        trainId={props.data.trainId}
                        lastLine={props.lastLine}
                    />

                </div>
            </div>
        </fieldset>
    );
};

export default memo(FormLine, (prevProps, nextProps) => {
    return prevProps.data === nextProps.data &&
        prevProps.stations === nextProps.stations &&
        prevProps.drivers === nextProps.drivers &&
        prevProps.kontrahents === nextProps.kontrahents &&
        prevProps.index === nextProps.index &&
        prevProps.orders.length === nextProps.orders.length;
});
