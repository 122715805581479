import React from "react";
import "ol/ol.css";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import Point from "ol/geom/Point";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import {Icon, Style, Stroke, Fill} from "ol/style";
import OSM from "ol/source/OSM";
import {fromLonLat, transform} from "ol/proj";
import LineString from "ol/geom/LineString";
import {NotificationContext} from "../../../../../context";
import HttpClient from "../../../../../HttpClient";
import whiteMarker from "../../../../../img/marker_white.png"
import config from "../../../../../config";


export default class FormMap extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.center = [19.479744, 52.068813];
        this.startCoords = null;
        this.endCoords = null;
        this.zoom = typeof this.props.zoom !== "undefined" ? this.props.zoom : 5.5;
        this.marker = whiteMarker;
        this.startPoint = null;
        this.endPoint = null;
        this.lineLayer = null;
        this.route = null;
        this.distance = null;
        this.startColor = [102, 208, 29, 0.8];
        this.endColor = [29, 187, 237, 1];
    }

    componentDidMount() {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: this.props.id,
            view: new View({
                center: fromLonLat(this.startCoords ? this.startCoords : this.endCoords ? this.endCoords : this.center),
                zoom: this.zoom,
                maxZoom: 15
            }),
        });


        this.startCoords = this.props.data.destination.value ? [this.props.data.destination.value.lon, this.props.data.destination.value.lat] : null;
        this.endCoords = this.props.data.worker[0].address.value ? [this.props.data.worker[0].address.value.lon, this.props.data.worker[0].address.value.lat] : null;

        if (this.startCoords) {
            this.moveMarker(this.startCoords, "startPoint", this.endColor)
        }
        if (this.endCoords) {
            this.moveMarker(this.endCoords, "endPoint", this.startColor)
        }
        if (this.startCoords && this.endCoords) {
            this.getRoutes(this.endColor);
        }
    }

    shouldComponentUpdate(nextProps) {
        return this.props.zoom !== nextProps.zoom ||
            this.props.id !== nextProps.id ||
            this.props.data.destination.value !== this.props.data.destination.value ||
            this.props.data.destination.value?.lon !== nextProps.data.destination.value?.lon ||
            this.props.data.destination.value?.lat !== nextProps.data.destination.value?.lat ||
            this.props.data.worker[0].address?.value !== nextProps.data.worker[0].address?.value ||
            this.props.data.direction.value !== nextProps.data.direction.value
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.data.destination.value !== this.props.data.destination.value ||
            prevProps.data.worker[0].address.value !== this.props.data.worker[0].address.value ||
            prevProps.data.direction.value !== this.props.data.direction.value) {
            const prevStartCoords = prevProps.data.destination.value;
            const prevEndCoords = prevProps.data.worker[0].address.value;
            this.startCoords = this.props.data.destination.value ? [this.props.data.destination.value.lon, this.props.data.destination.value.lat] : null;
            this.endCoords = this.props.data.worker[0].address.value ? [this.props.data.worker[0].address.value.lon, this.props.data.worker[0].address.value.lat] : null;

            if (this.startCoords && this.startCoords !== prevStartCoords) {
                this.moveMarker(this.startCoords, "startPoint", this.props.data.direction.value ? this.endColor : this.startColor)
            }
            if (this.endCoords && this.endCoords !== prevEndCoords) {
                this.moveMarker(this.endCoords, "endPoint", this.props.data.direction.value ? this.startColor : this.endColor)
            }
            if (this.startCoords && this.endCoords) {
                this.getRoutes(true, this.endColor);
            }
        }

    }

    moveMarker(coords, markerId, singleColor) {
        if (!this[markerId]) {
            this.createMarker(coords, markerId, singleColor);
        }

        let feature = this.map
            .getLayers()
            .item(this[markerId])
            .getSource()
            .getFeatureById(markerId)


        if (feature !== null && markerId === "startPoint") {
            feature.setGeometry(
                new Point(transform(coords, "EPSG:4326", "EPSG:3857"))
            );
        } else if (feature !== null && markerId === "endPoint") {
            feature.setGeometry(
                new Point(transform(coords, "EPSG:4326", "EPSG:3857"))
            );
        }

        let view = this.map.getView();
        view.animate({
            center: transform(coords, "EPSG:4326", "EPSG:3857"),
            duration: 500,
        });
        view.animate({zoom: this.zoom});
    }

    getRoutes = async (validate = false, singleColor) => {
        const httpRequest = HttpClient.createRequest();
        try {
            const response = await httpRequest.get(`${config.osrmURL}/route/v1/driving/${this.startCoords[0]},${this.startCoords[1]};${this.endCoords[0]},${this.endCoords[1]}?steps=true&alternatives=false&geometries=geojson&overview=full`);
            this.route = response.data;
            this.distance = Math.round(this.route.routes[0].distance / 1000);
            this.props.setOrderData(
                this.props.groupIndex,
                this.props.index,
                "mapKm",
                this.distance,
                () => {
                    this.props.setOrderData(this.props.groupIndex, this.props.index, 'approximateTime', response.data.routes[0].duration, validate ? this.props.handleValidate : null);
                }
            );
            this.createRoute(this.route, singleColor);
        } catch (e) {}
    }

    createMarker(coords, markerId, singleColor) {

        let feature = new Feature({
            geometry: new Point(transform(coords, "EPSG:4326", "EPSG:3857")),
        });

        let style = new Style({
            image: new Icon({
                src: this.marker,
                fill: new Fill({
                    color: singleColor,
                }),
                anchor: [0.5, 46],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                opacity: 0.95,
                scale: 1,
                color: singleColor,
                fillColor: singleColor,
            })
        });

        feature.setStyle(style);
        feature.setId(markerId);

        let markers = new VectorSource({
            features: [feature],
        });

        let iconVectorLayer = new VectorLayer({
            source: markers,
        });

        this[markerId] = this.map.getLayers().getLength();
        this.map.addLayer(iconVectorLayer);
    }

    createLine = (prevStep, singleStep) => {

        let lineCoords = [prevStep, singleStep];
        let lineString = new LineString(lineCoords);
        lineString.transform("EPSG:4326", "EPSG:3857");

        return new Feature({
            geometry: lineString,
            name: "Line",
        });


    }

    createRoute(route, singleColor) {

        if (this.lineLayer) {
            this.map.removeLayer(this.map.getLayers().item(this.lineLayer));
        }

        let features = [];

        let prevStep = this.startCoords;
        for (let i = 0; i < route.routes.length; i++) {
            for (let j in route.routes[i].geometry.coordinates) {
                if (j > 0) {
                    prevStep = [route.routes[i].geometry.coordinates[j - 1][0], route.routes[i].geometry.coordinates[j - 1][1]];
                    let singleStep = [route.routes[i].geometry.coordinates[j][0], route.routes[i].geometry.coordinates[j][1]];
                    features.push(this.createLine(prevStep, singleStep));
                }
            }
        }

        let source = new VectorSource({
            features: features,
        });

        let lineStyle = new Style({
            stroke: new Stroke({
                color: [29, 187, 237, 1],
                width: 4,
            }),
        });

        let vector = new VectorLayer({
            source: source,
            style: [lineStyle],
        });

        this.lineLayer = this.map.getLayers().getLength();
        this.map.addLayer(vector);
    }

    render() {
        return (
            <div
                 id={this.props.id}
                 style={{
                     width: "550px",
                     height: "445px",
                 }}
            ></div>
        );
    }
}
