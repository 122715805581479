export const chunk = (arr, len) => {
    const chunks = []
    let i = 0

    while (i < arr.length) {
        chunks.push(arr.slice(i, i += len));
    }

    return chunks;
}

export const chunkCallable = async (arr, len, cb) => {
    const chunks = []
    let i = 0

    while (i < arr.length) {
        const chunk_data = arr.slice(i, i += len);
        const data = await cb(chunk_data);
        chunks.push(...data);
    }

    return chunks;
}

