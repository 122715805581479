import React from "react";
import AppRoute from "../../../../AppRoute";
import UnfillCourses from "./UnfillCourses";

const UnfillCoursesRoute = () => {
  return (
    <AppRoute
      app="operator"
      window="trasy"
      tab="dopotwierdzenia"
      component={UnfillCourses}
    />
  );
};

export default UnfillCoursesRoute;
