export const getColumns = (type, roles) => {
    switch (type) {
        case 1:
            return getColumnsForSettlement(roles);
        case 2:
            return nonsettlementColumns;
        case 3:
            return driverColumns;
    }
};

const getColumnsForSettlement = (roles) => {
    const columns = settlementColumns;

    const enableColumnEdit = (columnId) => {
        const column = columns.find((column) => column.id === columnId);
        const columnIndex = columns.indexOf(column);

        columns[columnIndex] = { ...column, edit: true };
    };

    if (roles.includes("ROLE_SUPER_ADMIN") || roles.includes("ROLE_OPERATOR")) {
        enableColumnEdit("orderNo");
        // enableColumnEdit("worker");
        // enableColumnEdit("route");
        // enableColumnEdit("km");
        // enableColumnEdit("rate");
        // enableColumnEdit("discount");
        // enableColumnEdit("stopCount");
        // enableColumnEdit("highway");
        // enableColumnEdit("contractor");
        // enableColumnEdit("rateAfterDiscount");
        // enableColumnEdit("kmCost");
        // enableColumnEdit("stopCost");
        // enableColumnEdit("saveMoney");
        // enableColumnEdit("sumCost");
    }

    if (roles.includes("ROLE_ADMINISTRACJA_EDYCJA")) {
        enableColumnEdit("orderNo");
    }

    return columns;
};

const settlementColumns = [
    {
        id: "status",
        label: "",
        tooltip: "",
        query: false,
        maxWidth: 10,
    },
    {
        id: "time",
        label: "Data",
        tooltip: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "worker",
        label: "Pracownik",
        tooltip: "Pracownik",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "route",
        label: "Trasa",
        tooltip: "Trasa",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 220,
        maxWidth: 220,
    },
    {
        id: "km",
        label: "Km",
        tooltip: "Km",
        sorted: false,
        order: null,
        edit: false,
        type: "number",
        query: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "rate",
        label: "Stawka",
        tooltip: "Stawka",
        sorted: false,
        order: null,
        query: true,
        type: "number",
        subtype: "currency",
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "discount",
        label: "Rabat",
        tooltip: "Rabat",
        query: true,
        type: "number",
        hide: false,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
        roles: [
            "ROLE_OPERATOR"
        ]
    },
    {
        id: "rateAfterDiscount",
        label: "Po rabacie",
        tooltip: "Stawka po rabacie",
        query: true,
        hide: false,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
        roles: [
            "ROLE_OPERATOR"
        ]
    },
    {
        id: "kmCost",
        label: "Koszt km",
        tooltip: "Koszt km",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "stopCount",
        label: "Postój",
        tooltip: "Postój",
        query: true,
        type: "number",
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "stopCost",
        label: "Koszt post.",
        tooltip: "Koszt postoju",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "highway",
        label: "Autostr.",
        tooltip: "Autostrady",
        query: true,
        type: "number",
        subtype: "currency",
        edit: false,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        id: "sumCost",
        label: "Łącznie",
        tooltip: "Łącznie",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "saveMoney",
        label: "Oszcz.",
        tooltip: "Oszczędność",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "number",
        label: "ID zlec.",
        tooltip: "ID zlecenia",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "orderNo",
        label: "Nr zlec.",
        tooltip: "Numer zlecenia",
        query: true,
        type: "text",
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "connectedOrders",
        label: "Połączone zlecenia.",
        tooltip: "Połączone zlecenia",
        type: "text",
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        tooltip: "Kontrahent",
        query: true,
        type: "text",
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "partner",
        label: "Partner",
        tooltip: "Partner",
        query: true,
        type: "text",
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "branch",
        label: "Oddział",
        tooltip: "Oddział",
        query: true,
        type: "text",
        edit: false,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "settlementStatus",
        label: "Status",
        tooltip: "Status rozliczenia",
        query: true,
        type: "text",
        edit: false,
        minWidth: 160,
        maxWidth: 160,
    },
    {
        id: "actions",
        label: "",
        tooltip: "",
        query: false,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
];

const nonsettlementColumns = [
    {
        id: "status",
        label: "",
        tooltip: "",
        query: false,
        maxWidth: 10,
    },
    {
        id: "time",
        label: "Data",
        tooltip: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "number",
        label: "ID zlec.",
        tooltip: "ID zlecenia",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "connectedOrders",
        label: "Połączone zlecenia",
        tooltip: "Połączone zlecenia",
        type: "text",
        edit: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "worker",
        label: "Pracownik",
        tooltip: "Pracownik",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 130,
        maxWidth: 200,
    },
    {
        id: "route",
        label: "Trasa",
        tooltip: "Trasa",
        sorted: false,
        order: null,
        type: "text",
        query: false,
        edit: false,
        minWidth: 220,
        maxWidth: 220,
    },
    {
        id: "billingModel",
        label: "Model",
        tooltip: "Model rozliczeniowy",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "km",
        label: "Km",
        tooltip: "Km",
        sorted: false,
        order: null,
        edit: false,
        type: "number",
        query: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "rate",
        label: "Stawka",
        tooltip: "Stawka",
        sorted: false,
        order: null,
        query: true,
        type: "number",
        subtype: "currency",
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "discount",
        label: "Rabat",
        tooltip: "Rabat",
        query: true,
        type: "number",
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "rateAfterDiscount",
        label: "Po rabacie",
        tooltip: "Stawka po rabacie",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "kmCost",
        label: "Koszt km",
        tooltip: "Koszt km",
        query: true,
        type: "number",
        subtype: "currency",
        hide: true,
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "stopCount",
        label: "Postój",
        tooltip: "Postój",
        query: false,
        type: "number",
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "stopCost",
        label: "Koszt post.",
        tooltip: "Koszt postoju",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "highway",
        label: "Autostr.",
        tooltip: "Autostrady",
        query: false,
        type: "number",
        subtype: "currency",
        edit: false,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        id: "sumCost",
        label: "Łącznie",
        tooltip: "Łącznie",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "saveMoney",
        label: "Oszcz.",
        tooltip: "Oszczędność",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        hide: true,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        tooltip: "Kontrahent",
        query: true,
        type: "text",
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverFullName",
        label: "Kierowca",
        tooltip: "Kierowca",
        query: true,
        type: "text",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverNormalRate",
        label: "Stawka",
        tooltip: "Stawka / km",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverRoute",
        label: "Trasa",
        tooltip: "Trasa",
        sorted: false,
        order: null,
        type: "text",
        driver: true,
        query: false,
        edit: false,
        minWidth: 150,
        maxWidth: 220,
    },
    {
        id: "billingModelName",
        label: "Model",
        tooltip: "Model rozliczeniowy",
        sorted: false,
        order: null,
        type: "text",
        driver: true,
        query: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "notes",
        label: "Model-Stawka NIE LOTOS",
        tooltip: "Model-Stawka NIE LOTOS",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverSumKm",
        label: "Km",
        tooltip: "Km",
        query: false,
        type: "number",
        driver: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverWaitingRate",
        label: "Stawka/1h postoju",
        tooltip: "Stawka/1h postoju",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverKmCost",
        label: "Koszt km",
        tooltip: "Koszt km",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverWaitingTime",
        label: "Postój",
        tooltip: "Postój",
        query: false,
        type: "number",
        driver: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverWaitingCost",
        label: "Koszt post.",
        tooltip: "Całkowity koszt postoju",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverHighwayCost",
        label: "Autostrad.",
        tooltip: "Koszt autostrad",
        query: false,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        id: "driverSumCost",
        label: "Łącznie",
        tooltip: "Łącznie",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverBillingGroupName",
        label: "Grupa rozliczeniowa",
        tooltip: "Grupa rozliczeniowa",
        query: true,
        type: "text",
        driver: true,
        edit: false,
        disabled: true,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "actions",
        label: "",
        tooltip: "",
        query: false,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
];

const driverColumns = [
    {
        id: "status",
        label: "",
        tooltip: "",
        query: false,
        maxWidth: 10,
    },
    {
        id: "time",
        label: "Data",
        tooltip: "Data",
        sorted: true,
        order: "ASC",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "number",
        label: "ID zlec.",
        tooltip: "ID zlecenia",
        query: true,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        id: "connectedOrders",
        label: "Połączone zlecenia",
        tooltip: "Połączone zlecenia",
        type: "text",
        edit: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        id: "route",
        label: "Trasa",
        tooltip: "Trasa",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 220,
        maxWidth: 220,
    },
    {
        id: "worker",
        label: "Pracownik",
        tooltip: "Pracownik",
        sorted: false,
        order: null,
        type: "text",
        query: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "contractor",
        label: "Kontrahent",
        tooltip: "Nazwa kontrahenta",
        query: true,
        type: "text",
        driver: false,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "billingModelName",
        label: "Model",
        tooltip: "Model rozliczeniowy",
        query: true,
        type: "text",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverNormalRate",
        label: "Stawka",
        tooltip: "Stawka/km",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        hide: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverSumKm",
        label: "Km",
        tooltip: "Km",
        query: true,
        type: "number",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "costKm",
        label: "Koszt km",
        tooltip: "Koszt km",
        query: true,
        type: "number",
        subtype: "currency",
        disabled: true,
        edit: false,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverWaitingRate",
        label: "Stawka/1h postoju",
        tooltip: "Stawka/1h postoju",
        query: true,
        hide: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverWaitingTime",
        label: "Postój",
        tooltip: "Postój",
        query: true,
        type: "number",
        edit: false,
        driver: true,
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "driverWaitingCost",
        label: "Koszt post.",
        tooltip: "Całkowity koszt postoju",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        disabled: true,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverHighwayCost",
        label: "Autostr.",
        tooltip: "Koszt autostrad",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverSumCost",
        label: "Łącznie",
        tooltip: "Łącznie",
        query: true,
        type: "number",
        subtype: "currency",
        driver: true,
        edit: false,
        disabled: true,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "driverFullName",
        label: "Kierowca",
        tooltip: "Kierowca",
        query: true,
        type: "text",
        driver: true,
        edit: false,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        id: "driverBillingGroupName",
        label: "Grupa rozliczeniowa",
        tooltip: "Grupa rozliczeniowa",
        query: true,
        type: "text",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "consortiumMember",
        label: "Partner",
        tooltip: "Partner",
        query: false,
        type: "text",
        driver: true,
        edit: false,
        minWidth: 130,
        maxWidth: 130,
    },
    {
        id: "actions",
        label: "",
        tooltip: "",
        query: false,
        edit: false,
        minWidth: 80,
        maxWidth: 80,
    },
];
