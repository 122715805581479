import React from "react";
import styles from "./formSummary.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import SummaryCourseMap from "./courseMap/SummaryCourseMap";
import {
    Backdrop,
    Button,
    Fade,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {format} from "date-fns";
import {pl} from "date-fns/locale";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const useStyles = makeStyles(() => ({
    simplebar: {
        "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before, .simplebar-track.simplebar-vertical .simplebar-scrollbar:before": {
            backgroundColor: "#b4b4b4",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        outline: "0 !important",
        top: "6vh",
        width: "85vw",
        maxWidth: "1600px",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        fontFamily: "'Open Sans', sans-serif",
        "& .MuiTableCell-head": {
            padding: "5px 3px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "bold",
        },
        "& .MuiTableCell-body": {
            padding: "3px",
            fontSize: "12px",
            fontFamily: "'Open Sans', sans-serif",
        },
    },

    backButton: {
        marginLeft: 5,
        minWidth: "150px",
        backgroundColor: "#575B55",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "black",
        },
    },
    confirmButton: {
        minWidth: "150px",
        backgroundColor: "#66D01D",
        color: "white",
        fontWeight: "bold",
        fontFamily: "'Open Sans', sans-serif",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "#5AB71B",
        },
    },
}));

// Tabela z podsumowaniem zamówienia
const columns = [
    {id: "driver", label: "Pracownik", minWidth: 200, align: "left"},
    {id: "pickup", label: "Adres odbioru", minWidth: 200, align: "left"},
    {
        id: "destination",
        label: "Adres docelowy",
        minWidth: 200,
        align: "left",
    },
    {
        id: "time",
        label: "Data",
        minWidth: 200,
        align: "left",
    },
    {
        id: "manualKm",
        label: "KM",
        minWidth: 100,
        align: "left",
    },
    {
        id: "cardNo",
        label: "Numer karty",
        minWidth: 200,
        align: "left",
    },
    {
        id: "orderNo",
        label: "Nr zlecenia",
        minWidth: 180,
        align: "left",
    },
];

function StickyHeadTable(props) {
    const classes = useStyles();

    function createData(
        index,
        driver,
        pickup,
        destination,
        time,
        cardNo,
        kontrahent,
        manualKm,
        orderNo,
    ) {
        return {
            index,
            driver,
            pickup,
            destination,
            time,
            cardNo,
            kontrahent,
            manualKm,
            orderNo,
        };
    }

    const rows = props.orderData.map((data, index) => {
        let time = format(data.time, "d.LLLL yyyy, HH:mm", {locale: pl});
        const personData = data.fullWorkerAddressData.map((item, index) => {
            return (
                <div key={index} className={styles["formSummary__singleData"]}>
                    {item.person.name} {item.person.surName}
                </div>
            );
        });

        return createData(
            `${index + 1}`,
            personData,
            `${data.pickup.street} ${data.pickup.city}`,
            `${data.destination.street} ${data.destination.city}`,
            `${time}`,
            data.cardNo ? `${data.cardNo}` : "nie wprowadzono",
            data.kontrahent
                ? data.kontrahent
                : "nie wprowadzono",
            data.manualKm ? `${data.manualKm}` : "nie wprowadzono",
            data.orderNo ? `${data.orderNo}` : "nie wprowadzono",
        );
    });

    return (
        <Paper className={classes.root}>
            <TableContainer>
                <SimpleBar style={{maxHeight: "250px"}} className={classes.simplebar}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </SimpleBar>
            </TableContainer>
        </Paper>
    );
}

// Główna funkcja podsumowująca zamówienie
const FormSummary = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const timer = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        props.sendData();
    };

    const handleClose = () => {
        props.switchShowOrderSummary(false);
    };

    const errorMessage = props.error ? (
        <div style={{background: "red"}}>
            Błąd:
            <br/>
            {React.createElement("div", {
                dangerouslySetInnerHTML: {__html: props.error},
            })}
            <br/>
            Może za chwilę :)
        </div>
    ) : (
        ""
    );

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <SimpleBar style={{maxHeight: "88vh"}} className={classes.simplebar}>
                            <div className={styles["formSummary"]}>
                                <div className={styles["formSummary__description"]}>
                                    <h1 className={styles["formSummary__description--title"]}>
                                        Podsumowanie
                                    </h1>
                                    <p className={styles["formSummary__description--subtitle"]}>
                                        Przeczytaj uważnie i potwierdź wysłanie kilometrówki
                                    </p>
                                    <span
                                        className={styles["formSummary__description--close"]}
                                        onClick={handleClose}
                                    >X</span>
                                </div>
                                {errorMessage}
                                <StickyHeadTable orderData={props.orderData}/>
                                <div className={styles["formSummary__map"]}>
                                    <SummaryCourseMap courseDetails={props.orderData}/>
                                </div>
                                <div className={styles["formSummary__buttonWrapper"]}>
                                    <Button
                                        className={classes.confirmButton}
                                        onClick={handleButtonClick}
                                        variant="contained"
                                        type="submit"
                                    >
                                        Potwierdź
                                    </Button>
                                    <Button
                                        className={classes.backButton}
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        Popraw
                                    </Button>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default FormSummary;
