import React from "react";
import styles from "./addWorkerForm.module.scss";
import {Backdrop, Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import AddWorker from "../../../../../dyspozytor/pracownicy/dodajPracownika/AddWorker";

const useStyles = makeStyles((theme) => ({
    modal: {display: "flex", alignItems: "center", justifyContent: "center"},
    paper: {
        outline: "0 !important",
        position: "absolute",
        width: "400px",
        height: "auto-fit",
        top: "95px",
        backgroundColor: "#f3f3f3",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function AddWorkerForm(props) {
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={props.open}>
                <div className={styles["addWorkerForm__wrapper"]}>
                    <div className={styles["addWorkerForm__description"]}>
                        <h2 className={styles["addWorkerForm__description--title"]}>
                            Szczegóły kursu
                        </h2>
                        <span
                            className={styles["addWorkerForm__description--close"]}
                            onClick={props.closeDriverModal}
                        >X</span>
                    </div>
                    <AddWorker addDriverFromForm={props.addDriverFromForm}/>
                </div>
            </Fade>
        </Modal>
    );
}
